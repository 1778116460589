import React, { FC, useEffect, useRef, useState } from 'react';
import Loading from '../../components/loading/loading';

const PrivacyPolicy: FC = () => {
  const privacyPolicyRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (process.env.REACT_APP_PARTNER_MODE === 'false') {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://cdn.iubenda.com/iubenda.js';
      script.onload = () => {
        setLoading(false);
      };

      if (privacyPolicyRef.current) {
        privacyPolicyRef.current.appendChild(script);
      }
    }
  }, [privacyPolicyRef, privacyPolicyRef.current]);

  return (
    <div ref={privacyPolicyRef}>
      {loading ? (
        <Loading />
      ) : process.env.REACT_APP_PARTNER_MODE === 'false' ? (
        <a
          href="https://www.iubenda.com/privacy-policy/96106497"
          className="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed"
          title="Privacy Policy"
        >
          Privacy Policy
        </a>
      ) : null}
    </div>
  );
};

export default PrivacyPolicy;
