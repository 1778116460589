import React, { FC, useState } from 'react';
import { RenderBodyField, RenderInputField } from './template-builder';
import { Button, FormInstance } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

interface IListPickerTemplateProps {
  form: FormInstance<any>;
}

const ListPickerTemplate: FC<IListPickerTemplateProps> = ({ form }) => {
  const [items, setItems] = useState<number[]>([0]);

  const handleAddItem = () => {
    setItems((prevItems) => [...prevItems, prevItems.length]);
  };

  const handleRemoveButton = (index: number) => {
    if (items.length > 1) {
      setItems((prevItems) => prevItems.filter((button) => button !== index));
    }
  };

  return (
    <>
      {RenderBodyField(form)}
      <br />
      {RenderInputField(
        form,
        'button',
        'List button text',
        'Type list button text.'
      )}
      {items.map((itemsIndex, index) => {
        return (
          <div className="item-group" key={`group_item_${itemsIndex}`}>
            <b>List item:</b>
            <React.Fragment key={`item_${itemsIndex}_fragment`}>
              {items.length > 1 && (
                <Button
                  key={`item_${itemsIndex}_delete`}
                  type="text"
                  icon={<DeleteOutlined />}
                  className="template-delete-group-button"
                  onClick={() => {
                    handleRemoveButton(itemsIndex);
                  }}
                />
              )}
              {RenderInputField(
                form,
                `item_${itemsIndex}`,
                'Item name:',
                `Item name ${index + 1}`,
                true,
                true,
                24
              )}
              {RenderInputField(
                form,
                `item_id_${itemsIndex}`,
                'Item id:',
                `Item id ${index + 1}`,
                true,
                true,
                200
              )}
              {RenderInputField(
                form,
                `item_description_${itemsIndex}`,
                'Item description:',
                `Item description ${index + 1}`,
                true,
                true,
                72
              )}
            </React.Fragment>
          </div>
        );
      })}
      <Button
        key={'addNewItem'}
        onClick={handleAddItem}
        disabled={items.length === 10}
      >
        Add list item
      </Button>
    </>
  );
};

export default ListPickerTemplate;
