import { ContactRecordType } from '../IContact';

export interface IPhoneRecord {
  type: ContactRecordType.PHONE;
  phoneNumber: string;
  label: PhoneLabel;
  customLabel?: string;
}

export enum PhoneLabel {
  MOBILE = 'mobile',
  HOME = 'home',
  WORK = 'work',
  OTHER = 'other',
}
