import { FC } from 'react';
import IDefaultPluginFieldProps from './IDefaultPluginField';
import { Form, Select } from 'antd';
import { V_REQUIRED_FIELD } from '../../../../shared/constants';

const BooleanFormItem: FC<IDefaultPluginFieldProps> = ({
  displayName,
  keyBase,
  index,
  isRequired,
  description,
  convertValue,
  placeholder,
}) => {
  const value = convertValue ? 'YES' : 'NO';

  return (
    <Form.Item
      label={displayName}
      name={keyBase + '{{BOOLEAN}}'}
      key={index}
      rules={[
        {
          required: isRequired,
          message: V_REQUIRED_FIELD,
        },
      ]}
      tooltip={description}
      initialValue={value}
    >
      <Select placeholder={placeholder ?? displayName} value={value}>
        {['YES', 'NO'].map((option, index) => (
          <Select.Option key={index} value={option}>
            {option}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default BooleanFormItem;
