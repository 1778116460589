import { IConversationMessageItem } from '../../../types/IConversationMessage';
import { ConversationMessageTypes } from '../../../shared/constants';
import { formatDateFromISO8601 } from '../../../shared/helpers';
import { formatDistanceToNowStrict } from 'date-fns';

export const getConversationMessageItemText = (
  messageItem: IConversationMessageItem
) => {
  switch (messageItem.$type) {
    case ConversationMessageTypes.TEXT:
      return messageItem.text;
    case ConversationMessageTypes.COMMAND:
      return '⚡ ' + messageItem.name;
    case ConversationMessageTypes.HANDSOFF:
      return '🤚 ' + messageItem.message;
    case ConversationMessageTypes.HANDSON:
      return '👌 ' + messageItem.message;
    case ConversationMessageTypes.OPTOUT:
      return '🔕 ' + messageItem.message;
    case ConversationMessageTypes.ERROR:
      return '🚫 ' + messageItem.message;
    case ConversationMessageTypes.FILE:
      return '📄 ' + messageItem.fileName?.slice(24);
    default:
      return '';
  }
};

export const getFormattedMessageDate = (createAt: string) => {
  const currentDate = new Date();
  const messageDate = new Date(createAt);
  const diff = currentDate.getTime() - messageDate.getTime();
  const diffInHours = diff / (1000 * 60 * 60);
  return diffInHours > 24
    ? formatDateFromISO8601(createAt)
    : formatDistanceToNowStrict(createAt);
};
