import React, { FC, useEffect, useState } from 'react';
import { message, Modal } from 'antd';
import { aiAPI } from '../../../services/ai-service';
import { showErrorMessage } from '../../../shared/helpers';
import Loading from '../../loading/loading';

interface IConversationFilePreviewModalProps {
  projectId: string;
  chatId: string;
  fileName: string;
  setFileName: React.Dispatch<React.SetStateAction<string>>;
}

const ConversationFilePreviewModal: FC<IConversationFilePreviewModalProps> = ({
  projectId,
  chatId,
  fileName,
  setFileName,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [url, setUrl] = useState<string | null>(null);
  const [downloadFile, { isLoading }] =
    aiAPI.useLazyDownloadAttachedFileQuery();

  const handleFileDownload = async () => {
    if (chatId && projectId && fileName) {
      const res = await downloadFile({ projectId, chatId, fileName });
      if ('error' in res && res.error) {
        showErrorMessage(messageApi, res.error);
      } else if ('data' in res && res.data) {
        setUrl(res.data.downloadUrl);
      }
    }
  };

  useEffect(() => {
    if (projectId && chatId && fileName) {
      setIsOpen(true);
      handleFileDownload();
    } else if (!fileName) {
      if (url) {
        window.URL.revokeObjectURL(url);
        setUrl(null);
      }
      setIsOpen(false);
    }
  }, [projectId, chatId, fileName]);

  const handleCancel = () => {
    setFileName('');
  };

  const handleOk = async () => {
    setFileName('');
  };

  const getContent = () => {
    if (isLoading) {
      return <Loading height={'300px'} />;
    }

    if (url) {
      return (
        <img
          src={url}
          alt={fileName}
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
      );
    }

    return null;
  };

  return (
    <Modal
      wrapClassName={'modal'}
      title="File preview"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered={true}
    >
      {contextHolder}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {getContent()}
      </div>
    </Modal>
  );
};

export default ConversationFilePreviewModal;
