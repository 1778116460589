import React, { FC } from 'react';
import { Button } from 'antd';
import { ReactComponent as GoogleIcon } from '../../assets/icons/google-icon.svg';
import './google-login-button.scss';

const GoogleLoginButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Button onClick={onClick} className={'google-login-button'}>
      <GoogleIcon className={'google-login-button-icon'} />
      Sign in with Google
    </Button>
  );
};

export default GoogleLoginButton;
