import React, { FC } from 'react';
import { Checkbox, Form, FormInstance, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import BubbleSettingsField from './bubble-settings-field/bubble-settings-field';

interface IBubbleSettingsFormProps {
  bubbleSettingsForm: FormInstance;
  handleChange: () => void;
  setShowBubble: React.Dispatch<React.SetStateAction<boolean>>;
  setBubbleMessage: React.Dispatch<React.SetStateAction<string>>;
}

const BubbleSettingsForm: FC<IBubbleSettingsFormProps> = ({
  bubbleSettingsForm,
  handleChange,
  setShowBubble,
  setBubbleMessage,
}) => {
  const bubbleSettingsCheckbox = () => {
    return (
      <Form.Item name="isEnabled" valuePropName="checked" noStyle>
        <Checkbox onChange={(e) => setShowBubble(e.target.checked)}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '4px, 4px, 4px, 0',
            }}
          >
            Speech bubbles is enabled
            <Tooltip
              title={
                'A speech bubble is a small pop-up message that appears in a mini-chat.'
              }
            >
              <span
                style={{
                  marginTop: 3,
                  marginLeft: 4,
                  color: 'rgba(60, 66, 87, 0.45)',
                  cursor: 'help',
                }}
              >
                <QuestionCircleOutlined />
              </span>
            </Tooltip>
          </div>
        </Checkbox>
      </Form.Item>
    );
  };

  const bubbleSettingsFormComponent = () => (
    <Form
      form={bubbleSettingsForm}
      layout="vertical"
      className="settings-form appearance-settings-form"
      onChange={handleChange}
      requiredMark={false}
    >
      <div className="settings-form-part">
        {bubbleSettingsCheckbox()}
        <BubbleSettingsField
          handleChange={handleChange}
          bubbleSettingsForm={bubbleSettingsForm}
          setBubbleMessage={setBubbleMessage}
        />
      </div>
    </Form>
  );

  return bubbleSettingsFormComponent();
};

export default BubbleSettingsForm;
