const languageOptions = [
  { name: 'Afrikaans', key: 'af' },
  { name: 'Arabic', key: 'ar' },
  { name: 'Catalan', key: 'ca' },
  { name: 'Chinese', key: 'zh' },
  { name: 'Chinese ', key: 'zh_HK' },
  { name: 'Croatian', key: 'hr' },
  { name: 'Czech', key: 'cs' },
  { name: 'Danish', key: 'da' },
  { name: 'Dutch', key: 'nl' },
  { name: 'English', key: 'en' },
  { name: 'English (British)', key: 'en_GB' },
  { name: 'Estonian', key: 'et' },
  { name: 'Finnish', key: 'fi' },
  { name: 'French', key: 'fr' },
  { name: 'German', key: 'de' },
  { name: 'Greek', key: 'el' },
  { name: 'Hebrew', key: 'he' },
  { name: 'Hindi', key: 'hi' },
  { name: 'Hungarian', key: 'hu' },
  { name: 'Indonesian', key: 'id' },
  { name: 'Italian', key: 'it' },
  { name: 'Japanese', key: 'ja' },
  { name: 'Kannada', key: 'kn' },
  { name: 'Korean', key: 'ko' },
  { name: 'Lithuanian', key: 'lt' },
  { name: 'Malay', key: 'ms' },
  { name: 'Marathi', key: 'mr' },
  { name: 'Norwegian', key: 'nb' },
  { name: 'Polish', key: 'pl' },
  { name: 'Portuguese (Brazilian)', key: 'pt_BR' },
  { name: 'Portuguese', key: 'pt' },
  { name: 'Romanian', key: 'ro' },
  { name: 'Russian', key: 'ru' },
  { name: 'Slovak', key: 'sk' },
  { name: 'Spanish', key: 'es' },
  { name: 'Swedish', key: 'sv' },
  { name: 'Tagalog', key: 'tl' },
  { name: 'Telugu', key: 'te' },
  { name: 'Thai', key: 'th' },
  { name: 'Turkish', key: 'tr' },
  { name: 'Ukrainian', key: 'uk' },
  { name: 'Vietnamese', key: 'vi' },
];

export default languageOptions;
