import { apiService } from './api-service';
import ITableRecords from '../types/ITableRecords';
import ITable from '../types/ITable';
import ITableRecordsData from '../types/ITableRecordsData';
import { X_ORG_ID_HEADER } from '../shared/constants';

export const endUserRecordsAPI = apiService.injectEndpoints({
  endpoints: (build) => ({
    getTables: build.query<
      ITable[],
      { projectId: string; orgId: string | undefined }
    >({
      query: ({ projectId, orgId }) => ({
        url: `/projects/${projectId}/end-user-records/tables`,
        method: 'GET',
        headers: orgId ? { [X_ORG_ID_HEADER]: orgId } : {},
      }),
    }),
    getTableRecords: build.query<
      ITableRecords,
      {
        projectId: string;
        table: string;
        params: { from: string; to: string; offset: number; limit: number };
      }
    >({
      query: ({ projectId, table, params }) => ({
        url: `/projects/${projectId}/end-user-records/${table}/records?from=${params.from}&to=${params.to}&offset=${params.offset}&limit=${params.limit}`,
        // : `/projects/${projectId}/end-user-records/${table}/records`,
        method: 'GET',
      }),
    }),
    getAllTableRecords: build.query<
      string[],
      { projectId: string; table: string; params: { from: string; to: string } }
    >({
      query: ({ projectId, table, params }) => ({
        url: `/projects/${projectId}/end-user-records/${table}/all?from=${params.from}&to=${params.to}`,
        method: 'GET',
      }),
    }),
    getTableRecordsByIds: build.query<
      ITableRecordsData[],
      { projectId: string; table: string; recordIds: string[] }
    >({
      query: ({ projectId, table, recordIds }) => ({
        url: `/projects/${projectId}/end-user-records/${table}/list`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify({ recordIds }),
      }),
    }),
    markRecordsAsRead: build.mutation<
      void,
      { projectId: string; recordIds: string[] }
    >({
      query: ({ projectId, recordIds }) => ({
        url: `/projects/${projectId}/end-user-records/mark-as-read`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify({ recordIds }),
      }),
    }),
    markDelete: build.mutation<
      void,
      { projectId: string; recordIds: string[] }
    >({
      query: ({ projectId, recordIds }) => ({
        url: `/projects/${projectId}/end-user-records/delete`,
        method: 'DELETE',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify({ recordIds }),
      }),
    }),
  }),
});
