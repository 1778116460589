import React, { FC, useState } from 'react';
import { Divider } from 'antd';
import DateFormItem from './form-items/date-form-item';
import CheckboxFormItem from './form-items/checkbox-form-item';
import { DateLabel } from '../../../../types/contacts/contact-records/IDateRecord';
import FormUtils from '../../../../shared/utils/form-utils';
import LabelFormItem from './form-items/label-form-item';
import IInitialLabelContactRecordValue from '../../../../types/contacts/IInitialLabelContactRecordValue';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

interface IDateRecordFormElementProps {
  initialLabelValue: IInitialLabelContactRecordValue;
  useYearInitialValue: boolean;
}

const DateRecordFormElement: FC<IDateRecordFormElementProps> = ({
  initialLabelValue,
  useYearInitialValue,
}) => {
  const [useYear, setUseYear] = useState<boolean>(useYearInitialValue);

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    setUseYear(e.target.checked);
  };

  return (
    <>
      <Divider orientation="left" className={'contact-form-divider'}>
        Date
      </Divider>
      <CheckboxFormItem
        label={'Use Year'}
        name={'useYear'}
        handleCheckboxChange={handleCheckboxChange}
      />
      <DateFormItem label={'Date'} name={'date'} useYear={useYear} />
      <div style={{ display: 'flex', gap: 24 }}>
        <LabelFormItem
          label={'Label'}
          name={'label'}
          options={FormUtils.createSelectOptionsFromEnum(DateLabel)}
          initialValue={initialLabelValue}
        />
      </div>
    </>
  );
};

export default DateRecordFormElement;
