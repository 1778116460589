import React, { FC, useEffect, useState } from 'react';
import './plan-card.scss';
import { CheckOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../hooks/redux-hooks';
import { RouteNames } from '../../router/router';
import { Button } from 'antd';
import IPlan from '../../types/IPlan';
import {
  BillingPlansTypes,
  ProfileSettingsType,
  SubscriptionPlanTypes,
} from '../../shared/constants';
import {
  formatNumber,
  getGASubscriptionPurchaseDetails,
  planFeaturesToStringArray,
} from '../../shared/helpers';
import { suuid } from '../../utils/suuid';
import { GA_SUBSCRIPTION_PURCHASE } from '../../shared/google-analytics';
import { subscriptionAPI } from '../../services/subscription-service';

const PlanCard: FC<{
  plan: IPlan;
  currentBillingPlansType: BillingPlansTypes;
  index: number;
  subscribeCb?: (type: string) => void;
  subscribeDisabled?: boolean;
  landing?: boolean;
}> = ({
  plan,
  currentBillingPlansType,
  index,
  subscribeCb,
  subscribeDisabled,
  landing,
}) => {
  const { contactEmail } = useAppSelector((state) => state.domainsReducer);
  const { isAuth } = useAppSelector((state) => state.userReducer);
  const { currentUserSubscription } = useAppSelector(
    (state) => state.subscriptionReducer
  );
  const { plans } = useAppSelector((state) => state.plansReducer);
  const [planFeatures, setPlanFeatures] = useState<string[]>([]);
  const location = useLocation();
  const {
    data: enterprisePaymentURL,
    isLoading: enterprisePaymentURLLoading,
    error: enterprisePaymentURLError,
  } = subscriptionAPI.useGetEnterprisePaymentURLQuery();

  useEffect(() => {
    if (!plan) return;
    const features = planFeaturesToStringArray(plan, plans);
    setPlanFeatures(features);
  }, [plan, plans]);

  const handleSubscribe = (type: string) => {
    if (type === SubscriptionPlanTypes.ENTERPRISE) {
      if (enterprisePaymentURL) {
        window.location.href = enterprisePaymentURL;
        return;
      }

      window.location.href = `mailto:${contactEmail}`;
      const purchaseDetails = getGASubscriptionPurchaseDetails(
        plans,
        type,
        currentBillingPlansType
      );
      GA_SUBSCRIPTION_PURCHASE(purchaseDetails);
      return;
    }

    if (subscribeCb) {
      subscribeCb(type);
    }
  };

  const getPlanCredits = (plan: IPlan) => {
    const creditsComponent = (credits: string) => (
      <div className="plan-card-features-list-item">
        <CheckOutlined className="plan-card-features-list-item-icon" />{' '}
        {credits} credits
      </div>
    );

    if (plan.credits > 0) {
      return creditsComponent(formatNumber(plan.credits));
    }

    if (plan.credits === -1) {
      return creditsComponent('More');
    }

    return null;
  };

  return (
    <div
      className={`plan-card ${
        plan.type === 'premium' ? 'plan-card--active' : ''
      }`}
    >
      <div className="plan-card-content">
        <div className="plan-card-title">{plan.name}</div>
        <div
          className={`plan-card-price ${
            plan.monthlyPriceAnualBilling === -1 ? 'hidden' : ''
          }`}
        >
          $
          {currentBillingPlansType === BillingPlansTypes.YEARLY
            ? plan.monthlyPriceAnualBilling.toFixed(2)
            : plan.monthlyPrice.toFixed(2)}
        </div>
        <div
          className={`plan-card-subtitle ${
            plan.monthlyPriceAnualBilling === -1 ? 'hidden' : ''
          }`}
        >
          {plan.monthlyPriceAnualBilling === 0
            ? 'Forever'
            : currentBillingPlansType === BillingPlansTypes.YEARLY
            ? 'Per month'
            : 'Per month'}
        </div>
        <div className="plan-card-features-list">
          {getPlanCredits(plan)}
          {planFeatures.map((feature, i) => (
            <div className="plan-card-features-list-item" key={i}>
              <CheckOutlined className="plan-card-features-list-item-icon" />{' '}
              {feature}
            </div>
          ))}
        </div>
      </div>
      <Link
        to={
          plan.monthlyPriceAnualBilling === 0
            ? RouteNames.USER_PROJECTS
            : plan.monthlyPriceAnualBilling === -1
            ? location.pathname
            : `${RouteNames.PROFILE_SETTINGS_NO_PARAMS}/${ProfileSettingsType.PLAN}`
        }
        state={{
          upgrade: location.pathname !== RouteNames.LANDING,
        }}
      >
        <Button
          className={`plan-card-btn ${
            (currentUserSubscription !== null &&
              currentUserSubscription.currentPlan.id === plan.id &&
              (currentUserSubscription.billingType ===
                currentBillingPlansType ||
                currentUserSubscription.billingType === null) &&
              !landing) ||
            (isAuth &&
              !currentUserSubscription &&
              plan.monthlyPriceAnualBilling === 0 &&
              !landing)
              ? 'plan-card-btn--disabled'
              : ''
          } ${subscribeDisabled ? 'plan-card-btn--disabled' : ''}`}
          onClick={() => handleSubscribe(plan.type)}
          disabled={
            landing
              ? false
              : enterprisePaymentURL &&
                plan.type === SubscriptionPlanTypes.ENTERPRISE
              ? false
              : (currentUserSubscription !== null &&
                  currentUserSubscription.currentPlan.id === plan.id &&
                  (currentUserSubscription.billingType ===
                    currentBillingPlansType ||
                    currentUserSubscription.billingType === null)) ||
                (isAuth &&
                  currentUserSubscription !== null &&
                  currentUserSubscription.currentPlan.id === plan.id &&
                  plan.monthlyPriceAnualBilling === 0 &&
                  !landing)
              ? true
              : subscribeDisabled !== undefined
              ? subscribeDisabled
              : false
          }
        >
          {enterprisePaymentURL &&
          plan.type === SubscriptionPlanTypes.ENTERPRISE
            ? 'Payment'
            : (currentUserSubscription !== null &&
                currentUserSubscription.currentPlan.id === plan.id &&
                (currentUserSubscription.billingType ===
                  currentBillingPlansType ||
                  currentUserSubscription.billingType === null) &&
                !landing) ||
              (isAuth &&
                currentUserSubscription !== null &&
                currentUserSubscription.currentPlan.id === plan.id &&
                plan.monthlyPriceAnualBilling === 0 &&
                !landing)
            ? 'Your plan'
            : // : plan.yearlyPrice === 0
            // ? 'Get started'
            // : 'Coming soon'}
            plan.monthlyPriceAnualBilling === 0
            ? 'Get started'
            : plan.monthlyPriceAnualBilling === -1
            ? 'Contact us'
            : 'Subscribe'}
        </Button>
      </Link>
    </div>
  );
};

export default PlanCard;
