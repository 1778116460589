import React, { FC } from 'react';
import { Slider } from 'antd';

interface ILogSliderProps {
  value: number;
  min: number;
  max: number;
  marks: number[];
  stepsInRange: number;
  onChange: (value: number) => void;
}

// const prettyInt = (x: number) =>
//   Math.round(x)
//     .toString()
//     .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const prettyInt = (x: number) => {
  if (x >= 1e6) {
    return (x / 1e6).toFixed(1).replace(/\.0$/, '') + 'M';
  } else if (x >= 1e3) {
    return (x / 1e3).toFixed(1).replace(/\.0$/, '') + 'k';
  } else {
    return Math.round(x).toString();
  }
};

export const sliderCurve = Math.exp;
export const inverseCurve = Math.log;

const makeMarks = (a: number[]) =>
  a.reduce((acc, cur) => {
    acc[inverseCurve(cur)] = prettyInt(cur);
    return acc;
  }, {} as { [key: number]: string });

const LogSlider: FC<ILogSliderProps> = ({
  value,
  min,
  max,
  marks,
  stepsInRange,
  onChange,
}) => {
  return (
    <div>
      <Slider
        min={inverseCurve(min)}
        max={inverseCurve(max)}
        marks={makeMarks(marks)}
        step={(inverseCurve(max) - inverseCurve(min)) / stepsInRange}
        // tipFormatter={(value) => prettyInt(sliderCurve(value || 0))}
        tooltip={{
          formatter: (value) => prettyInt(sliderCurve(value || 0)),
        }}
        onChange={onChange}
        value={inverseCurve(value)}
      />
    </div>
  );
};

export default LogSlider;
