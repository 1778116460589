import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $getSelection,
  $isRangeSelection,
  $setSelection,
  EditorState,
} from 'lexical';
import React, { FC, useEffect, useState } from 'react';

import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import LexicalKeywordsPlugin from './lexical-keywords-plugin';
import { LexicalKeywordNode } from './lexical-keyword-node';
import './lexical-field.scss';
import { LexicalCommandNode } from './lexical-command-node';
import ComponentPickerMenuPlugin from './component-picker-menu-plugin';
import LexicalCommandsPlugin from './lexical-commands-plugin';
import {
  ILexicalCommandModalFormItemProps,
  ISetLexicalCommandModalProps,
} from './lexical-command-props-modal';
import IProjectPromptsCommandsTemplates from '../../types/IProjectPromptsCommandsTemplates';

interface ILexicalFieldProps {
  initText: string;
  editable: boolean;
  fieldName: string;
  handleLexicalFieldChange: (fieldName: string, value: string) => void;
  modalProps: { title: string; options: ILexicalCommandModalFormItemProps[] };
  setModalProps: ISetLexicalCommandModalProps;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  promptsCommandsTemplates: IProjectPromptsCommandsTemplates | undefined;
}

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error: any) {
  console.error(error);
}

function prepopulatedRichText(text: string) {
  const root = $getRoot();
  if (root.getFirstChild() === null) {
    const paragraph = $createParagraphNode();
    paragraph.append($createTextNode(text));
    root.append(paragraph);
  }
}

const LexicalField: FC<ILexicalFieldProps> = ({
  initText,
  editable,
  fieldName,
  handleLexicalFieldChange,
  modalProps,
  setModalProps,
  setModalIsOpen,
  promptsCommandsTemplates,
}) => {
  const initialConfig = {
    namespace: 'MyEditor',
    onError,
    nodes: [LexicalKeywordNode, LexicalCommandNode],
    editorState: () => prepopulatedRichText(initText),
    editable: editable,
  };

  const [editorState, setEditorState] = useState<EditorState | null>(null);

  function onChange(editorState: EditorState, editor: any) {
    if (!editable) return;

    setEditorState(editorState);

    // console.log(editorState);

    // editor.update(() => {
    //   const selection = $getSelection();
    //   if ($isRangeSelection(selection)) {
    //     const selectedNodeKey = selection.focus.key;
    //     const selectedNode = editorState._nodeMap.get(selectedNodeKey);
    //
    //     if (!selectedNode) return;
    //
    //     if (
    //       selectedNode.__type === 'command' ||
    //       selectedNode.__type === 'keyword'
    //     ) {
    //       if (
    //         selection.focus.offset !== selectedNode.getTextContentSize() ||
    //         selection.anchor.offset !== selectedNode.getTextContentSize()
    //       ) {
    //         selection.focus.offset = selectedNode.getTextContentSize();
    //         selection.anchor.offset = selectedNode.getTextContentSize();
    //         $setSelection(selection);
    //       }
    //     }
    //   }
    // });
  }

  useEffect(() => {
    if (!editorState) return;
    // console.log('editorState', editorState)
    const root = editorState._nodeMap.get('root');
    if (!root) return;
    const cachedText = root.__cachedText;
    handleLexicalFieldChange(fieldName, cachedText);
  }, [editorState]);

  return (
    <div
      className={`lexical-container ${
        editable ? '' : 'lexical-container--disabled'
      }`}
    >
      <LexicalComposer initialConfig={initialConfig}>
        <PlainTextPlugin
          contentEditable={<ContentEditable />}
          placeholder={null}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <HistoryPlugin />
        <OnChangePlugin onChange={onChange} />
        <LexicalKeywordsPlugin />
        <LexicalCommandsPlugin
          promptsCommandsTemplates={promptsCommandsTemplates}
        />
        <ComponentPickerMenuPlugin
          modalProps={modalProps}
          setModalProps={setModalProps}
          setModalIsOpen={setModalIsOpen}
          promptsCommandsTemplates={promptsCommandsTemplates}
        />
      </LexicalComposer>
    </div>
  );
};

export default LexicalField;
