import { useEffect, useState } from 'react';
import IProjectPlugin from '../types/IProjectPlugin';
import { pluginsAPI } from '../services/plugins-service';
import { useParams } from 'react-router-dom';

export function useGetProjectPluginsList(projectId: string): IProjectPlugin[] {
  const { orgId } = useParams();
  const [lazyGetProjectPluginsQuery] =
    pluginsAPI.useLazyGetProjectPluginsQuery();
  const [currentPluginsData, setCurrentPluginsData] = useState<
    IProjectPlugin[]
  >([]);

  useEffect(() => {
    const fetchProjectPlugins = async () => {
      const result = await lazyGetProjectPluginsQuery({ id: projectId, orgId });
      if (result.data) {
        setCurrentPluginsData(result.data);
      } else if ('error' in result) {
        console.error('Error fetching project plugins:', result.error);
      }
    };

    fetchProjectPlugins();
  }, []);

  return currentPluginsData;
}
