import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IOrganization from '../../types/IOrganization';
import { organizationsAPI } from '../../services/organizations-service';
import { usersAPI } from '../../services/users-service';
import IAuthResponse from '../../types/IAuthResponse';
import { LS_TOKEN_KEY } from '../../shared/constants';
import IUser from '../../types/IUser';

interface IOrganizationsState {
  currentOrganization: IOrganization | null;
  currentOrganizationLoading: boolean; // allOrganizationsLoading
  // allOrganizations: string[];
  allOrganizationsInfo: IOrganization[];
}

const initialState: IOrganizationsState = {
  currentOrganization: null,
  currentOrganizationLoading: true,
  // allOrganizations: [],
  allOrganizationsInfo: [],
};

export const organizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    setInitialOrganizationsState: (state) => {
      state.currentOrganization = null;
      state.currentOrganizationLoading = true;
      // state.allOrganizations = [];
      state.allOrganizationsInfo = [];
    },
    setCurrentOrganization: (
      state,
      action: PayloadAction<IOrganization | null>
    ) => {
      state.currentOrganization = action.payload;
    },
    setCurrentOrganizationLoading: (state, action: PayloadAction<boolean>) => {
      state.currentOrganizationLoading = action.payload;
    },
    setAllOrganizationsInfo: (
      state,
      action: PayloadAction<IOrganization[]>
    ) => {
      state.allOrganizationsInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        organizationsAPI.endpoints.getAllOrganizations.matchFulfilled,
        (state, action: PayloadAction<IOrganization[]>) => {
          state.allOrganizationsInfo = action.payload || [];
        }
      )
      // TODO: currentOrganizationLoading ?
      .addMatcher(
        organizationsAPI.endpoints.getOrganizations.matchFulfilled,
        (state, action: PayloadAction<IOrganization>) => {
          state.currentOrganizationLoading = false;
          //  state.currentOrganization = action.payload;
        }
      )
      .addMatcher(
        organizationsAPI.endpoints.getOrganizations.matchPending,
        (state) => {
          state.currentOrganizationLoading = true;
        }
      )
      .addMatcher(
        organizationsAPI.endpoints.getOrganizations.matchRejected,
        (state) => {
          state.currentOrganizationLoading = false;
        }
      )
      .addMatcher(
        organizationsAPI.endpoints.createOrganization.matchFulfilled,
        (state, action: PayloadAction<IOrganization>) => {
          state.allOrganizationsInfo.push(action.payload);
        }
      );
  },
});

export default organizationsSlice.reducer;
