import React, { FC, useState } from 'react';
import { Button, Dropdown, MenuProps } from 'antd';
import { DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import HeaderCellProps from './header-cell-props/header-cell-props';
import './header-cell-more-menu.scss';
import {
  IEditorTableColumn,
  ITableColumnT,
} from '../../../../../types/tables-service/ITableT';
import { ITableFilterValues } from '../../../whats-app-outbound/filters/filters-helpers';
import HeaderCellFilter from './header-cell-filter/header-cell-filter';

interface IHeaderCellMoreMenuProps {
  isReadOnly: boolean;
  column: IEditorTableColumn;
  onDeleteColumn: (columnKey: string) => void;
  onChangeColumn: (columnProps: ITableColumnT) => void;
  setFilterValues: React.Dispatch<React.SetStateAction<ITableFilterValues[]>>;
  openHeaderCellMoreMenu: string;
  setOpenHeaderCellMoreMenu: React.Dispatch<React.SetStateAction<string>>;
}

const HeaderCellMoreMenu: FC<IHeaderCellMoreMenuProps> = ({
  isReadOnly,
  column,
  onDeleteColumn,
  onChangeColumn,
  setFilterValues,
  openHeaderCellMoreMenu,
  setOpenHeaderCellMoreMenu,
}) => {
  const onFilterClick = () => {
    setMenuItems(filterMenuItems);
  };

  const defaultMenuItems: MenuProps['items'] = [
    {
      key: 'Properties',
      label: (
        <HeaderCellProps
          isReadOnly={isReadOnly}
          column={column}
          onChangeColumn={onChangeColumn}
          onFilterClick={onFilterClick}
          setOpenHeaderCellMoreMenu={setOpenHeaderCellMoreMenu}
        />
      ),
      className: 'header-cell-more-menu__properties',
    },
    {
      type: 'divider',
    },
    {
      key: 'Delete',
      label: (
        <a
          onClick={() => {
            setOpenHeaderCellMoreMenu('');
            onDeleteColumn(column.key);
          }}
        >
          <DeleteOutlined style={{ marginRight: 6 }} /> Delete
        </a>
      ),
      className: 'header-cell-more-menu__custom-danger',
      danger: true,
      disabled: isReadOnly,
    },
  ];

  const onBackClick = () => {
    setMenuItems(defaultMenuItems);
  };

  const filterMenuItems = [
    {
      key: 'Filter',
      label: (
        <HeaderCellFilter
          setFilterValues={setFilterValues}
          columnName={column.name as string}
          columnKey={column.key}
          onBackClick={onBackClick}
          setOpenHeaderCellMoreMenu={setOpenHeaderCellMoreMenu}
        />
      ),
      className: 'header-cell-more-menu__properties',
    },
  ];

  const [menuItems, setMenuItems] =
    useState<MenuProps['items']>(defaultMenuItems);

  return (
    <div>
      <Dropdown
        menu={{ items: menuItems }}
        placement="bottomRight"
        arrow={{ pointAtCenter: true }}
        trigger={['click']}
        overlayClassName={'header-cell-more-menu__dropdown'}
        open={openHeaderCellMoreMenu === column.key}
        onOpenChange={(open) => {
          if (!open) {
            setOpenHeaderCellMoreMenu('');
          } else {
            setMenuItems(defaultMenuItems);
            setOpenHeaderCellMoreMenu(column.key);
          }
        }}
      >
        <Button
          type="text"
          icon={<MoreOutlined />}
          size={'small'}
          style={{ padding: '0 4px' }}
        />
      </Dropdown>
    </div>
  );
};

export default HeaderCellMoreMenu;
