import React, { FC, useState } from 'react';
import { Button, Divider, Select } from 'antd';
import FormUtils from '../../../../shared/utils/form-utils';
import { ContactRecordType } from '../../../../types/contacts/IContact';

interface IAddRecordTypeElementProps {
  setAddRecordType: React.Dispatch<
    React.SetStateAction<ContactRecordType | null>
  >;
}

const AddRecordTypeElement: FC<IAddRecordTypeElementProps> = ({
  setAddRecordType,
}) => {
  const [recordTypeValue, setRecordTypeValue] = useState<ContactRecordType>(
    ContactRecordType.EMAIL
  );

  return (
    <>
      <Divider orientation="left" className={'contact-form-divider'}></Divider>
      <div style={{ display: 'flex', gap: 24 }}>
        <Select
          options={FormUtils.createSelectOptionsFromEnum(ContactRecordType)}
          onChange={(value) => setRecordTypeValue(value)}
          popupClassName={'popup-select'}
          style={{ width: '100%' }}
          value={recordTypeValue}
        />
        <Button
          onClick={() => setAddRecordType(recordTypeValue as ContactRecordType)}
        >
          Add record
        </Button>
      </div>
      <Divider orientation="left" className={'contact-form-divider'}></Divider>
    </>
  );
};

export default AddRecordTypeElement;
