import { FC, useEffect, useState } from 'react';
import { Button, Result } from 'antd';
import { subscriptionAPI } from '../../services/subscription-service';

const SubscriptionLock: FC = () => {
  const {
    data: enterprisePaymentURL,
    isLoading: enterprisePaymentURLLoading,
    error: enterprisePaymentURLError,
  } = subscriptionAPI.useGetEnterprisePaymentURLQuery();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleClick = () => {
    setIsButtonDisabled(true);

    if (enterprisePaymentURL) {
      window.location.href = enterprisePaymentURL;
    }
  };

  return (
    <div className="no-header-container">
      <Result
        status="404"
        title={
          <div style={{ fontSize: '16px' }}>
            Unfortunately, your trial period has ended.
            <br />
            To continue using the service, please proceed with payment using the
            button below:
          </div>
        }
        subTitle=""
        extra={
          <Button
            style={{ width: '240px' }}
            type="primary"
            onClick={handleClick}
            disabled={isButtonDisabled}
          >
            Pay
          </Button>
        }
      />
    </div>
  );
};

export default SubscriptionLock;
