import React, { FC } from 'react';

const AttachButton: FC = () => {
  return (
    <div>
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.0695 11.7964L11.5369 16.329C9.36478 18.5012 5.84301 18.5012 3.67085 16.329C1.4987 14.1569 1.4987 10.6351 3.67085 8.46296L9.77208 2.36173C11.2213 0.912519 13.5709 0.912518 15.0201 2.36173C16.4693 3.81093 16.4693 6.16056 15.0201 7.60977L9.43379 13.1961C8.7529 13.877 7.64896 13.877 6.96807 13.1961C6.28718 12.5152 6.28718 11.4113 6.96807 10.7304L11.6929 6.00558C11.9328 5.76564 11.9328 5.37662 11.6929 5.13667C11.4529 4.89673 11.0639 4.89673 10.824 5.13667L6.09917 9.86148C4.93839 11.0223 4.93839 12.9042 6.09917 14.065C7.25994 15.2258 9.14193 15.2258 10.3027 14.065L15.889 8.47868C17.8181 6.54959 17.8181 3.42191 15.889 1.49282C13.9599 -0.436274 10.8323 -0.436273 8.90318 1.49282L2.80195 7.59405C0.149905 10.2461 0.149905 14.5459 2.80195 17.1979C5.45399 19.85 9.7538 19.85 12.4058 17.1979L16.9384 12.6653C17.1784 12.4254 17.1784 12.0364 16.9384 11.7964C16.6985 11.5565 16.3095 11.5565 16.0695 11.7964Z"
          fill="#7F7F7F"
        />
      </svg>
    </div>
  );
};

export default AttachButton;
