import { FC, useEffect, useState } from 'react';
import {
  ProjectSettingsType,
  TableRecordStatus,
  WhatsAppOutboundStatus,
} from '../../../../shared/constants';
import { Button, message, Result, Skeleton, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import CampaignColumnFilter from '../filters/campaign-column-filter';
import './campaigns.scss';
import ICampaignsFilter from '../../../../types/filters/ICampaignsFilter';
import CampaignFilter from '../filters/campaign-filter';
import { whatsAppOutboundApi } from '../../../../services/what-app-outbound-service';
import CreateCampaignModal from '../../../modals/twilio/create-campaign-modal';
import { RouteNames } from '../../../../router/router';
import { useParams } from 'react-router-dom';
import {
  formatDateFromISO8601ToShortDate,
  toFixedTrimTrailingZeroes,
} from '../../../../shared/helpers';
import { useNavigate } from 'react-router-dom';
import IWhatsAppOutboundAnalytics from '../../../../types/whats-app-outbound-service/IWhatsAppOutboundAnalytics';
import CampaignMoreMenu from './campaign-more-menu';
import WhatsAppOutbound, {
  WHATS_APP_RECORDS_PER_PAGE,
} from './whats-app-outbound';

const RECORDS_PER_PAGE = 10;

const getTagColor = (status: string) => {
  switch (status) {
    case WhatsAppOutboundStatus.RUNNING:
      return 'yellow';
    case WhatsAppOutboundStatus.FINISHED:
      return 'green';
    case WhatsAppOutboundStatus.CANCELED:
      return 'default';
    case WhatsAppOutboundStatus.READY:
      return 'blue';
  }
};

interface ICampaignsTableColumns {
  key: string;
  name: string;
  status: string;
  startedAt: string | null;
  estimatedFinishTime: string | null;
  finishedAt: string | null;
  analytics: IWhatsAppOutboundAnalytics | null;
}

const Campaigns: FC<{
  projectId: string;
}> = ({ projectId }) => {
  const { orgId, subtab } = useParams();

  const [
    lazyGetCampaigns,
    { data: campaignsData, isLoading: campaignsLoading, error: campaignsError },
  ] = whatsAppOutboundApi.useLazyGetCampaignsQuery();
  const [currentTableData, setCurrentTableData] = useState<
    ICampaignsTableColumns[]
  >([]);
  const [filters, setFilters] = useState<ICampaignsFilter[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState<number>(
    WHATS_APP_RECORDS_PER_PAGE
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsTotalCount, setItemsTotalCount] = useState<number>(0);
  const [messageApi, contextHolder] = message.useMessage();
  const [campaignModalIsOpen, setCampaignModalIsOpen] =
    useState<boolean>(false);

  const [refreshToggle, setRefreshToggle] = useState(false);
  const navigate = useNavigate();

  const refreshParent = () => {
    setRefreshToggle((prevToggle) => !prevToggle);
  };

  useEffect(() => {
    if (campaignsData && campaignsData.data) {
      const newData = campaignsData.data.map((item) => {
        return {
          key: item.id,
          name: item.name,
          status: item.status,
          startedAt: item.startedAt,
          estimatedFinishTime: item.estimatedFinishTime,
          finishedAt: item.finishedAt,
          analytics: item.analytics,
        };
      });

      setCurrentTableData(newData);
    }
  }, [campaignsData]);

  const getCampaigns = async (
    offset: number = 0,
    limit: number = WHATS_APP_RECORDS_PER_PAGE
  ) => {
    const res = await lazyGetCampaigns({
      projectId,
      orgId,
      params: { offset, limit, filterValues: filters },
    });

    if (res.data) {
      setItemsTotalCount(res.data.totalCount);
    }
  };

  useEffect(() => {
    getCampaigns((currentPage - 1) * itemsPerPage, itemsPerPage);
  }, [currentPage, itemsPerPage, projectId, filters, refreshToggle]);

  const onChangePage = async (page: number) => {
    setCurrentPage(page);
  };

  const onShowSizeChange = async (current: number, pageSize: number) => {
    setCurrentPage(current);
    setItemsPerPage(pageSize);
  };

  const renderCampaignsTable = () => {
    const columns: ColumnsType<ICampaignsTableColumns> = [
      {
        title: (
          <div className={'campaigns-table-filter-column'}>
            Campaign
            <CampaignColumnFilter
              columnName={'name'}
              currentFilters={filters}
              setCurrentFilters={setFilters}
              placement={'bottom'}
              overlayClassName={''}
            />
          </div>
        ),
        dataIndex: 'name',
        key: 'name',
        render: (name, record) => {
          if (!name) return null;
          const maxNameLength = 60;
          let maxName = name.slice(0, maxNameLength);

          if (name.length > maxNameLength) {
            maxName += '...';
          }

          return <>{maxName}</>;
        },
      },
      {
        title: (
          <div className={'campaigns-table-filter-column'}>
            Status
            <CampaignColumnFilter
              columnName={'status'}
              currentFilters={filters}
              setCurrentFilters={setFilters}
              placement={'bottomRight'}
              overlayClassName={'campaign-status-filter-popover'}
            />
          </div>
        ),
        dataIndex: 'status',
        key: 'status',
        render: (status) => (
          <Tag
            color={getTagColor(status)}
            style={{ textTransform: 'capitalize' }}
          >
            {status}
          </Tag>
        ),
        width: 120,
        //align: 'center',
      },
      {
        title: <div>Open Rate %</div>,
        dataIndex: 'openRate',
        key: 'openRate',
        render: (_, record) =>
          record.analytics
            ? toFixedTrimTrailingZeroes(record.analytics.readPercent)
            : null,
        width: 75,
      },
      {
        title: <div>Reply Rate %</div>,
        dataIndex: 'replyRate',
        key: 'replyRate',
        render: (_, record) =>
          record.analytics
            ? toFixedTrimTrailingZeroes(record.analytics.repliedPercent)
            : null,
        width: 75,
      },
      {
        title: <div>Started At</div>,
        dataIndex: 'startedAt',
        key: 'startedAt',
        render: (startedAt) =>
          startedAt ? (
            <div>{formatDateFromISO8601ToShortDate(startedAt)}</div>
          ) : null,
        width: 120,
      },
      {
        title: <div>Estimated Finish Time</div>,
        dataIndex: 'estimatedFinishTime',
        key: 'estimatedFinishTime',
        render: (estimatedFinishTime) =>
          estimatedFinishTime ? (
            <div>{formatDateFromISO8601ToShortDate(estimatedFinishTime)}</div>
          ) : null,
        width: 120,
      },
      {
        title: <div>Finished At</div>,
        dataIndex: 'finishedAt',
        key: 'finishedAt',
        render: (finishedAt) =>
          finishedAt ? (
            <div>{formatDateFromISO8601ToShortDate(finishedAt)}</div>
          ) : null,
        width: 120,
      },
      {
        render: (record) => {
          return (
            <CampaignMoreMenu
              campaignId={record.key}
              projectId={projectId}
              status={record.status}
              refreshParent={refreshParent}
            />
          );
          // return record.status === 'ready' || record.status === 'running' ? (
          //   <Button
          //     danger
          //     icon={<DeleteOutlined />}
          //     onClick={(e) => {
          //       e.stopPropagation();
          //       setSelectForDeleting(record.key);
          //       setDeleteModalIsOpen(true);
          //     }}
          //     disabled={record.status != 'ready'}
          //     className={'danger-zone__button'}
          //   ></Button>
          // ) : (
          //   <Button
          //     style={{ paddingTop: 6 }}
          //     danger
          //     icon={<FiArchive />}
          //     onClick={(e) => {
          //       e.stopPropagation();
          //       setSelectForDeleting(record.key);
          //       setArchiveModalIsOpen(true);
          //     }}
          //     // disabled={record.status != 'ready'}
          //     className={'danger-zone__button'}
          //   ></Button>
          // );
        },
        width: 40,
      },
    ];

    return (
      <Table
        columns={columns}
        rowClassName={(record, index) =>
          record.status === TableRecordStatus.NEW ? 'row-new-record' : ''
        }
        className={`records-table records-table-hover campaigns-table`}
        dataSource={currentTableData}
        locale={{
          emptyText: (
            <>
              No campaigns.{' '}
              <span
                className={'create-campaign-link'}
                onClick={() => setCampaignModalIsOpen(true)}
                style={{ color: '#0000EE' }}
              >
                Create a new campaign to start.
              </span>
            </>
          ),
        }}
        onRow={(record: ICampaignsTableColumns) => ({
          onClick: () => {
            navigate(
              `${RouteNames.USER_PROJECTS}/${orgId}${RouteNames.PROJECT_SETTINGS_TAB_NAME}/${projectId}/${ProjectSettingsType.TWILIO_OUTBOUND}/${record.key}`
            );
          },
        })}
        pagination={{
          defaultPageSize: RECORDS_PER_PAGE,
          hideOnSinglePage: true,
          onChange: (page) => onChangePage(page),
          current: currentPage,
          total: itemsTotalCount,
          showSizeChanger: true,
          onShowSizeChange: (current, size) => onShowSizeChange(current, size),
          pageSizeOptions: [RECORDS_PER_PAGE.toString(), '20', '50', '100'],
        }}
        scroll={{ x: true }}
      />
    );
  };

  const renderComponent = () => {
    if (subtab) {
      return (
        <WhatsAppOutbound
          projectId={projectId}
          campaignId={subtab}
          orgId={orgId}
        />
      );
    }

    if (campaignsLoading) {
      return <Skeleton active />;
    }

    if (campaignsError) {
      return (
        <Result
          status="error"
          title="Ooops, something went wrong."
          subTitle={"Can't load campaigns. Try again later."}
        />
      );
    }

    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Button
            onClick={() => {
              setCampaignModalIsOpen(true);
            }}
          >
            Create Campaign
          </Button>
          <CampaignFilter setCurrentFilters={setFilters} />
        </div>
        {renderCampaignsTable()}
      </>
    );
  };

  return (
    <div style={{ marginBottom: 50 }}>
      {contextHolder}
      {renderComponent()}
      <CreateCampaignModal
        projectId={projectId}
        orgId={orgId}
        isOpen={campaignModalIsOpen}
        setIsOpen={setCampaignModalIsOpen}
        messageApi={messageApi}
      />
    </div>
  );
};

export default Campaigns;
