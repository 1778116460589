import React, { FC } from 'react';
import './whats-app-outbound-overview.scss';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { IoCheckmarkOutline } from 'react-icons/io5';
import { IoCheckmarkDoneOutline } from 'react-icons/io5';
import { IoEyeOutline } from 'react-icons/io5';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { HiRectangleStack } from 'react-icons/hi2';
import { IconType } from 'react-icons';
import { RiMailCloseLine } from 'react-icons/ri';
import { LuReply } from 'react-icons/lu';
import { FaRegWindowClose, FaUserCheck } from 'react-icons/fa';
import IWhatsAppOutboundAnalytics from '../../../../types/whats-app-outbound-service/IWhatsAppOutboundAnalytics';
import { toFixedTrimTrailingZeroes } from '../../../../shared/helpers';

interface IWhatsAppOutboundOverviewProps {
  statusData: IWhatsAppOutboundAnalytics | undefined | null;
}

interface IMessageStatusValues {
  [key: string]: {
    name: string;
    description: string;
    icon: IconType;
  };
}

// https://www.twilio.com/docs/messaging/api/message-resource#message-status-values
const MessageStatusValues: IMessageStatusValues = {
  queued: {
    name: 'queued',
    description:
      'The API request to send an outbound message was successful and the message is queued to be sent out by a specific From sender. For messages sent without a Messaging Service this is the initial Status value of the Message resource.',
    icon: HiRectangleStack,
  },
  sent: {
    name: 'sent',
    description: 'The nearest upstream carrier accepted the outbound message.',
    icon: IoCheckmarkOutline,
  },
  failed: {
    name: 'failed',
    description:
      'The outbound message failed to send. This can happen for various reasons including queue overflows, Account suspensions and media errors. Twilio does not charge you for failed messages.',
    icon: IoCloseCircleOutline,
  },
  delivered: {
    name: 'delivered',
    description:
      'Twilio has received confirmation of outbound message delivery from the upstream carrier, and, where available, the destination handset.',
    icon: IoCheckmarkDoneOutline,
  },
  undelivered: {
    name: 'undelivered',
    description:
      'Twilio received a delivery receipt indicating that the outbound message was not delivered. This can happen for many reasons including carrier content filtering and the availability of the destination handset.',
    icon: RiMailCloseLine,
  },
  read: {
    name: 'read',
    description:
      'WhatsApp only: The recipient opened the outbound message. Recipient must have read receipts enabled.',
    icon: IoEyeOutline,
  },
  optOut: {
    name: 'opt-out',
    description: '',
    icon: FaRegWindowClose,
  },
  replied: {
    name: 'replied',
    description: '',
    icon: LuReply,
  },
  lead: {
    name: 'lead',
    description: '',
    icon: FaUserCheck,
  },
};

const WhatsAppOutboundOverview: FC<IWhatsAppOutboundOverviewProps> = ({
  statusData,
}) => {
  const overviewComponent = (
    data: IWhatsAppOutboundAnalytics | null | undefined
  ) => {
    return (
      <div>
        <div style={{ fontWeight: 500 }}>Overview</div>
        <div className={'whats-app-outbound-overview-items-container'}>
          {Object.keys(MessageStatusValues).map((key, i) => {
            const statusInfo = MessageStatusValues[key];
            const count = data
              ? data[key as keyof IWhatsAppOutboundAnalytics]
              : 0;
            const percent = data
              ? toFixedTrimTrailingZeroes(
                  data[`${key}Percent` as keyof IWhatsAppOutboundAnalytics]
                )
              : 0;

            const additionalClassName =
              i > 3 ? 'whats-app-outbound-overview-item--small' : '';

            return (
              <div
                className={`whats-app-outbound-overview-item ${additionalClassName}`}
                key={i}
              >
                <div className={'whats-app-outbound-overview-item--count'}>
                  <span>
                    {count}{' '}
                    <span
                      style={{ fontSize: 12, color: 'rgba(60, 66, 87, 0.45)' }}
                    >
                      ({percent}%)
                    </span>
                  </span>
                  {<statusInfo.icon />}
                </div>
                <div className={'whats-app-outbound-overview-item--status'}>
                  {statusInfo.name}{' '}
                  {statusInfo.description && (
                    <Tooltip title={statusInfo.description}>
                      <span
                        style={{
                          color: 'rgba(60, 66, 87, 0.45)',
                          cursor: 'help',
                        }}
                      >
                        <QuestionCircleOutlined />
                      </span>
                    </Tooltip>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderComponent = () => {
    // if (!statusData) return null;

    return overviewComponent(statusData);
  };

  return (
    <div className={'whats-app-outbound-overview'}>{renderComponent()}</div>
  );
};

export default WhatsAppOutboundOverview;
