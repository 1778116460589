import React, { FC } from 'react';
import { Checkbox, Form } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

interface ICheckboxFormItemProps {
  label: string;
  name: string;
  handleCheckboxChange: (e: CheckboxChangeEvent) => void;
}

const CheckboxFormItem: FC<ICheckboxFormItemProps> = ({
  label,
  name,
  handleCheckboxChange,
}) => {
  return (
    <Form.Item name={name} valuePropName="checked">
      <Checkbox onChange={handleCheckboxChange}>{label}</Checkbox>
    </Form.Item>
  );
};

export default CheckboxFormItem;
