import { FC, useEffect, useState } from 'react';
import './analytics.scss';
import MonthPicker from '../../charts/month-picker';
import { IChartsSettings } from '../../charts/chart-data';
import {
  WidgetAnalyticsEventsData,
  IAnalyticsEventsData,
} from './analytics-data';
import PerDayLineChart from './per-day-line-chart';
import AnalyticsLegend from './analytics-legend';
import { FaChartColumn, FaChartLine } from 'react-icons/fa6';
import PerDayBarChart from './per-day-bar-chart';
import { Radio } from 'antd';
import { useGetAnalyticsList } from '../../../hooks/analytics-hooks/useGetAnalyticsList';
import { useGetAnalyticsData } from '../../../hooks/analytics-hooks/useGetAnalyticsData';
import { useGetProjectPluginsList } from '../../../hooks/useGetProjectPluginsList';

export const ignoredEventsByDefault = ['widgetLoaded', 'uniqueVisitor'];

const Analytics: FC<{ projectId: string }> = ({ projectId }) => {
  const initialUsageSettings = { month: new Date() };
  const [chartSettings, setChartSettings] =
    useState<IChartsSettings>(initialUsageSettings);
  const [analyticsList, setAnalyticsList] = useState<IAnalyticsEventsData[]>(
    WidgetAnalyticsEventsData
  );
  const [activeAnalytics, setActiveAnalytics] = useState<ChartTypes>(
    ChartTypes.Line
  );

  const currentPluginsData = useGetProjectPluginsList(projectId);
  const [dynamicAnalyticsList, setDynamicAnalyticsList] = useGetAnalyticsList(
    projectId,
    currentPluginsData
  );
  const [queryResult, isLoading] = useGetAnalyticsData(
    projectId,
    chartSettings.month.toISOString(),
    analyticsList,
    currentPluginsData
  );

  useEffect(() => {
    if (queryResult) {
      const segmented: IAnalyticsEventsData[] = [];

      const qResponse = JSON.parse(queryResult.queryResponse);
      for (let t = 0; t < qResponse.tables.length; t++) {
        const table = qResponse.tables[t];
        for (let i = 0; i < table.rows.length; i++) {
          const row = table.rows[i];
          const name = row[1];
          const segment = row[2];
          if (!segment) continue;
          const segmentedEventName = `${name}_(${segment})`;
          const event = analyticsList.find((e) => e.eventName === name);
          const existingSegmentedEvent = segmented.find(
            (e) => e.eventName === segmentedEventName
          );
          if (!existingSegmentedEvent) {
            const title = (event?.title || '') + ` (${segment})`;
            const newEvent = {
              eventName: segmentedEventName,
              chartName: `${event?.chartName || name} (${segment})`,
              title: title,
            };
            segmented.push(newEvent);
          }
        }
      }

      const newSegmented = [];

      for (let i = 0; i < segmented.length; i++) {
        const seg = segmented[i];
        if (!analyticsList.find((e) => e.eventName === seg.eventName)) {
          newSegmented.push(seg);
        }
      }

      if (newSegmented.length) {
        setDynamicAnalyticsList([...dynamicAnalyticsList, ...newSegmented]);
      }
    }
  }, [queryResult]);

  useEffect(() => {
    setAnalyticsList(
      dynamicAnalyticsList.filter(
        (d) => !ignoredEventsByDefault.includes(d.eventName)
      )
    );
  }, [dynamicAnalyticsList]);

  useEffect(() => {
    if (activeAnalytics === ChartTypes.Bar) {
      setAnalyticsList(dynamicAnalyticsList);
    } else {
      setAnalyticsList(
        dynamicAnalyticsList.filter(
          (d) => !ignoredEventsByDefault.includes(d.eventName)
        )
      );
    }
  }, [activeAnalytics]);

  const onMonthSelected = (date: Date) => {
    setChartSettings({ month: date });
  };

  const handleLine = (payload: IAnalyticsEventsData) => {
    setAnalyticsList((prev) => {
      const exists = prev.find((item) => item.eventName === payload.eventName);

      if (exists) {
        return prev.filter((item) => item.eventName !== payload.eventName);
      } else {
        return [...prev, payload];
      }
    });
  };

  const renderLineCharts = () => {
    return (
      <>
        <PerDayLineChart
          data={analyticsList}
          dynamicAnalyticsList={dynamicAnalyticsList}
          queryResult={queryResult}
          isLoading={isLoading}
        ></PerDayLineChart>
        <AnalyticsLegend
          data={dynamicAnalyticsList}
          analyticsList={analyticsList}
          onClick={(payload) => handleLine(payload)}
        />
      </>
    );
  };

  const renderBarCharts = () => {
    return analyticsList.map((d, i) => (
      <PerDayBarChart
        data={d}
        key={`bar_${i}`}
        queryResult={queryResult}
        isLoading={isLoading}
      ></PerDayBarChart>
    ));
  };

  const renderSwitcher = () => {
    const chartOptions = [
      {
        label: <FaChartLine style={{ marginTop: 7 }} />,
        value: ChartTypes.Line,
      },
      {
        label: <FaChartColumn style={{ marginTop: 7 }} />,
        value: ChartTypes.Bar,
      },
    ];

    return (
      <div className="analytics__switcher">
        <Radio.Group
          options={chartOptions}
          onChange={(e) => setActiveAnalytics(e.target.value)}
          value={activeAnalytics}
          optionType="button"
        />
      </div>
    );
  };

  return (
    <div className={'analytics'}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <MonthPicker
          onDateChange={onMonthSelected}
          minDate={new Date('2024-01-01')}
          maxDate={new Date(Date.now())}
        />
        {renderSwitcher()}
      </div>
      {activeAnalytics === ChartTypes.Line && renderLineCharts()}
      {activeAnalytics === ChartTypes.Bar && renderBarCharts()}
    </div>
  );
};

export default Analytics;

enum ChartTypes {
  Bar,
  Line,
}
