import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import {
  LockOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import '../auth.scss';
import IAuthForm from '../../../types/IAuthForm';
import { usersAPI } from '../../../services/users-service';
import { RouteNames } from '../../../router/router';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getDomain, showErrorMessage } from '../../../shared/helpers';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { GA_LOGIN, GA_REGISTER_USER } from '../../../shared/google-analytics';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { emailRules, passwordRules } from '../../../shared/rules';
import LogoIcon from '../../../components/logo/logo-icon';
import { LogoMode } from '../../../shared/constants';
import LogoText from '../../../components/logo/logo-text';

const Register = () => {
  const { isAuth } = useAppSelector((state) => state.userReducer);
  const [register, { isLoading }] = usersAPI.useRegisterMutation();
  const [login, {}] = usersAPI.useAuthMutation();
  const [googleLogin] = usersAPI.useGoogleAuthMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [registerDisabled, setRegisterDisabled] = useState(true);
  const [formIsTouched, setFormIsTouched] = useState(false);

  useEffect(() => {
    if (isAuth) {
      navigate(
        (location && location.state && location.state.from) ||
          RouteNames.USER_PROJECTS
      );
    }
  }, [isAuth]);

  const handleChange = async () => {
    try {
      await form.validateFields();
      setRegisterDisabled(false);
    } catch (errors) {
      setRegisterDisabled(true);
    }
  };

  const handleGoogleRegister = async (credentialResponse: any) => {
    let email = '';

    try {
      const decoded: { email: string } = jwtDecode(
        credentialResponse.credential
      );
      email = decoded.email;
    } catch (e) {}

    const result = await googleLogin({ token: credentialResponse.credential });

    if ('error' in result) {
      showErrorMessage(messageApi, result.error);
    } else {
      GA_LOGIN();
      navigate(RouteNames.VERIFY_EMAIL, { state: { email: email } });
    }
  };

  const handleGoogleFail = async () => {
    messageApi.error('Sign Up With Google Failed.');
  };

  const handleRegister = async (formData: IAuthForm) => {
    setRegisterDisabled(true);
    // navigate(RouteNames.VERIFY_EMAIL, {state: {email: formData.email}});
    // return;
    const result = await register(formData);

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
    } else {
      GA_REGISTER_USER();
      // navigate(RouteNames.VERIFY_EMAIL, { state: { email: formData.email } });

      const result = await login(formData);

      if ('error' in result) {
        showErrorMessage(messageApi, result.error);
      } else {
        GA_LOGIN();
        navigate(RouteNames.VERIFY_EMAIL, { state: { email: formData.email } });
      }
    }

    setRegisterDisabled(false);
  };

  return (
    <div className="register">
      {contextHolder}
      <div className="container">
        <div className="login__inner">
          <div
            className="login__logo"
            onClick={() => (window.location.href = 'https://' + getDomain())}
          >
            <LogoIcon mode={LogoMode.DARK} />
            <LogoText mode={LogoMode.DARK} />
          </div>
          <div className={'auth-subtitle'}>Create your account</div>

          <Form
            name="login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={handleRegister}
            onChange={handleChange}
            form={form}
            layout={'vertical'}
          >
            <Form.Item label={'E-mail'} name="email" rules={emailRules}>
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="E-mail"
                onChange={() => setFormIsTouched(true)}
              />
            </Form.Item>
            <Form.Item label={'Password'} name="password" rules={passwordRules}>
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type={formIsTouched ? 'password' : 'text'}
                placeholder="Password"
                onChange={() => setFormIsTouched(true)}
              />
            </Form.Item>
            <Form.Item label={'First name'} name="firstName">
              <Input
                prefix={<UserSwitchOutlined className="site-form-item-icon" />}
                placeholder="Enter first name"
              />
            </Form.Item>
            <Form.Item label={'Last name'} name="lastName">
              <Input
                prefix={<UserSwitchOutlined className="site-form-item-icon" />}
                placeholder="Enter last name"
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button login-btn register-btn"
                loading={isLoading}
                disabled={registerDisabled}
              >
                Register
              </Button>
            </Form.Item>

            <div>
              <GoogleLogin
                context={'signup'}
                onSuccess={handleGoogleRegister}
                onError={handleGoogleFail}
                size={'medium'}
                locale={'en_GB'}
                width={315}
                text={'signup_with'}
              />
            </div>

            <div style={{ marginTop: 10 }}>
              Or{' '}
              <Link to={RouteNames.LOGIN} className="login-link">
                Log in now!
              </Link>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Register;
