import React, { FC, useEffect, useState } from 'react';
import { Button, message, Spin } from 'antd';
import '../verify-confirm-email.scss';
import { CloseCircleFilled } from '@ant-design/icons';
import { getDomainForImage, showErrorMessage } from '../../../shared/helpers';
import { usersAPI } from '../../../services/users-service';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RouteNames } from '../../../router/router';
import Loading from '../../../components/loading/loading';
import PageNotExist from '../../../components/page-not-exist/page-not-exist';
import IConfirmEmail from '../../../types/IConfirmEmail';
import { delay, LogoMode } from '../../../shared/constants';
import { useAppSelector } from '../../../hooks/redux-hooks';

const emailConfirmedTextError = 'Email is already confirmed.';

const ConfirmEmail: FC = () => {
  const { name, filePath } = useAppSelector((state) => state.domainsReducer);
  const [resendVerificationEmail, { isLoading }] =
    usersAPI.useResendVerificationEmailMutation();
  const [confirmEmail, { data, isLoading: confirmLoading, error }] =
    usersAPI.useConfirmEmailMutation();
  const [resendDisabled, setResendDisabled] = useState(false);
  // const [email, setEmail] = useState<string>('');
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [userData, setUserData] = useState<IConfirmEmail | null>({
    email: '',
    token: '',
  });
  const [errorMessage, setErrorMessage] = useState<string>(
    'The confirmation link that was sent to your email has expired.'
  );
  const [timestamp, setTimestamp] = useState(Date.now());

  const loadingComponent = () => <Spin size="large" tip="Loading..." />;
  const [component, setComponent] = useState<JSX.Element>(loadingComponent);

  useEffect(() => {
    const email = searchParams.get('email');
    const token = searchParams.get('token');

    if (email && token) {
      const emailReplace = email.replaceAll(' ', '+');
      const tokenReplace = token.replaceAll(' ', '+');
      setUserData({ email: emailReplace, token: tokenReplace });
    } else {
      setUserData(null);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!userData) return;
    if (!userData.email || !userData.token) return;
    confirmEmailHandler();
  }, [userData]);

  const confirmEmailHandler = async () => {
    if (!userData) return;

    const result = await confirmEmail(userData);

    if ('error' in result) {
      setComponent(confirmEmailError);
    } else {
      // Place the CompleteRegistration FB Pixel event tracking code here
      if ((window as any).fbq != null) {
        (window as any).fbq('track', 'CompleteRegistration', {
          content_name: 'email-confirmation',
        });
      }

      setComponent(confirmEmailSuccess);
      await delay(5000);
      navigate(RouteNames.LOGIN);
    }
  };

  const resendEmail = async () => {
    const email = searchParams.get('email');

    if (!email) return;
    setResendDisabled(true);

    const result = await resendVerificationEmail(email);

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
    } else {
      await messageApi.success('The verification email has been resent.');
    }

    setResendDisabled(false);
  };

  const confirmEmailSuccess = () => (
    <>
      <div className="welcome-container">
        <div className="verify-logo-container">
          <img
            src={`https://static.${getDomainForImage()}/static/${filePath}logo-${
              LogoMode.DARK
            }.svg?timestamp=${timestamp}`}
            alt={name}
            className="verify-logo"
            onError={(e) =>
              ((e.target as HTMLImageElement).style.display = 'none')
            }
          />
        </div>
        <div className="welcome-text">
          <div className="welcome-title">Welcome to {name}</div>
          <div className="welcome-subtitle">We are glad you are here.</div>
          <Button
            className="welcome-button"
            type="primary"
            onClick={() => navigate(RouteNames.USER_PROJECTS)}
          >
            Go to Dashboard
          </Button>
        </div>
      </div>
    </>
  );

  useEffect(() => {
    if (
      error &&
      'data' in error &&
      error.data &&
      'message' in (error.data as { message: string })
    ) {
      setErrorMessage((error.data as { message: string }).message);
    }
  }, [error]);

  useEffect(() => {
    if (error) {
      setComponent(confirmEmailError);
    }
  }, [error, resendDisabled, isLoading, errorMessage]);

  const confirmEmailError = () => (
    <>
      {contextHolder}
      <CloseCircleFilled className="verify-email-icon confirm-email-icon confirm-email-icon--error" />
      <div className="verify-email-title">
        Oh no!
        <br />
        Something went wrong.
      </div>
      <div>
        {errorMessage}
        {/*:<br/>*/}
        {/*<span className='verify-email--user-email'>email here</span>*/}
      </div>

      {errorMessage === emailConfirmedTextError ? null : (
        <>
          <div>Submit a new link? No problem.</div>

          <Button
            type="primary"
            onClick={resendEmail}
            loading={isLoading}
            disabled={resendDisabled}
          >
            Resend Verification Email
          </Button>
        </>
      )}

      <Button onClick={() => navigate(RouteNames.LOGIN)}>Go to login</Button>
    </>
  );

  return (
    <div className="verify-confirm__inner">
      <div className="verify-email">
        {userData ? (
          component
        ) : !searchParams.get('email') || searchParams.get('token') ? (
          <PageNotExist />
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default ConfirmEmail;
