const facebookAppId =
  process.env.NODE_ENV === 'development'
    ? '1064215901239741'
    : '840042214166119';

export function initFacebookSdk() {
  return new Promise<void>((resolve) => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: facebookAppId,
        cookie: true,
        xfbml: true,
        version: 'v18.0',
      });

      // auto authenticate with the api if already logged in with facebook
      // window.FB.getLoginStatus(({ authResponse }) => {
      //   if (authResponse) {
      //   } else {
      //     resolve();
      //   }
      // });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      (js as HTMLScriptElement).src =
        'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode?.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  });
}
