import { apiService } from './api-service';
import { X_ORG_ID_HEADER } from '../shared/constants';

interface IInsightsQueryResult {
  queryResponse: string;
  from: string;
  to: string;
  granularity: string;
}

export const analyticsAPI = apiService.injectEndpoints({
  endpoints: (build) => ({
    getWidgetPerDay: build.query<
      IInsightsQueryResult,
      { date: string; orgId: string | undefined; projectId: string }
    >({
      query: ({ date, orgId, projectId }) => ({
        url: `/analytics/project/${projectId}/widget-per-day/?selectedMonth=${date}`,
        method: 'GET',
        headers: orgId ? { [X_ORG_ID_HEADER]: orgId } : {},
      }),
    }),
    getLeadsPerDay: build.query<
      IInsightsQueryResult,
      { date: string; orgId: string | undefined; projectId: string }
    >({
      query: ({ date, orgId, projectId }) => ({
        url: `/analytics/project/${projectId}/leads-per-day/?selectedMonth=${date}`,
        method: 'GET',
        headers: orgId ? { [X_ORG_ID_HEADER]: orgId } : {},
      }),
    }),
    getWhatsAppOutboundPerDay: build.query<
      IInsightsQueryResult,
      { date: string; orgId: string | undefined; projectId: string }
    >({
      query: ({ date, orgId, projectId }) => ({
        url: `/analytics/project/${projectId}/waoutbound-per-day/?selectedMonth=${date}`,
        method: 'GET',
        headers: orgId ? { [X_ORG_ID_HEADER]: orgId } : {},
      }),
    }),
  }),
});
