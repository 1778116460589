import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Button, Modal } from 'antd';
import './limits-exceeded-modal.scss';
import { CheckOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { RouteNames } from '../../../router/router';
import { ProfileSettingsType } from '../../../shared/constants';
import IPlan from '../../../types/IPlan';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { planFeaturesToStringArray } from '../../../shared/helpers';

interface ILimitsExceeded {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const LimitsExceededModal: FC<ILimitsExceeded> = ({ isOpen, setIsOpen }) => {
  const { plans } = useAppSelector((state) => state.plansReducer);
  const [plan, setPlan] = useState<IPlan | null>(null);
  const [planFeatures, setPlanFeatures] = useState<string[]>([]);

  useEffect(() => {
    if (!plan) return;
    // const features = planFeaturesToStringArray(plan);
    // setPlanFeatures(features);
  }, [plan]);

  useEffect(() => {
    const premium = plans.find((plan) => plan.type === 'Premium');
    if (!premium) return;
    setPlan(premium);
  }, [plans]);

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <>
      {plan && (
        <Modal
          open={isOpen}
          onCancel={handleCancel}
          footer={null}
          className="limits-exceeded-modal"
          centered={true}
        >
          <div className="limits-exceeded-title">YOUR RUN OUT OF TOKENS</div>
          <div className="limits-exceeded-content">
            <div className="limits-exceeded--plan-title">{plan.name}</div>
            <div
              className={`limits-exceeded--plan-price ${
                plan.monthlyPriceAnualBilling === -1 ? 'hidden' : ''
              }`}
            >
              ${plan.monthlyPrice}
            </div>
            <div
              className={`limits-exceeded--plan-subtitle ${
                plan.monthlyPriceAnualBilling === -1 ? 'hidden' : ''
              }`}
            >
              Per month
            </div>
            <div className="limits-exceeded--plan-features-list">
              {planFeatures.map((feature, i) => (
                <div
                  className="limits-exceeded--plan-features-list-item"
                  key={i}
                >
                  <CheckOutlined className="limits-exceeded--plan-features-list-item-icon" />{' '}
                  {feature}
                </div>
              ))}
            </div>
            <Link
              to={`${RouteNames.PROFILE_SETTINGS_NO_PARAMS}/${ProfileSettingsType.PLAN}`}
            >
              <Button className="limits-exceeded--upgrade-btn">Upgrade</Button>
            </Link>
            <Link
              to={`${RouteNames.PROFILE_SETTINGS_NO_PARAMS}/${ProfileSettingsType.PLAN}`}
            >
              <Button type="link" className="limits-exceeded--choose-plan">
                Upgrade plan
              </Button>
            </Link>
          </div>
        </Modal>
      )}
    </>
  );
};

export default LimitsExceededModal;
