import React from 'react';
import { Button, Result } from 'antd';
import { RouteNames } from '../../router/router';
import { useAppSelector } from '../../hooks/redux-hooks';
import { useNavigate } from 'react-router-dom';

const RootErrorBoundary = () => {
  const { isAuth } = useAppSelector((state) => state.userReducer);
  const navigate = useNavigate();

  return (
    <div className="no-header-container">
      <Result
        status="500"
        title="Ooops!"
        subTitle="Looks like something went wrong."
        extra={
          <Button
            type="primary"
            onClick={() =>
              isAuth
                ? navigate(RouteNames.USER_PROJECTS)
                : navigate(RouteNames.LOGIN)
            }
          >
            Back Home
          </Button>
        }
      />
    </div>
  );
};

export default RootErrorBoundary;
