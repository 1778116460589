import React, { FC, useEffect, useState } from 'react';
import { Button, message } from 'antd';
import '../verify-confirm-email.scss';
import { ReactComponent as ZapprEmail } from '../../../assets/icons/zappr-email.svg';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { usersAPI } from '../../../services/users-service';
import { getDomain, showErrorMessage } from '../../../shared/helpers';
import { RouteNames } from '../../../router/router';
import Loading from '../../../components/loading/loading';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { userSlice } from '../../../store/reducers/user-slice';
import { projectsSlice } from '../../../store/reducers/projects-slice';
import {
  LS_CURRENT_ORGANIZATION_ID,
  LS_TOKEN_KEY,
} from '../../../shared/constants';
import { LogoutOutlined } from '@ant-design/icons';
import { subscriptionSlice } from '../../../store/reducers/subscription-slice';
import { organizationsSlice } from '../../../store/reducers/organizations-slice';
import { pluginsSlice } from '../../../store/reducers/plugins-slice';

const RESET_TIMER_AFTER_MS = 60000;

const VerifyEmail: FC = () => {
  const { isAuth, user } = useAppSelector((state) => state.userReducer);
  const { state } = useLocation();
  const [resendVerificationEmail, { isLoading }] =
    usersAPI.useResendVerificationEmailMutation();
  const [resendDisabled, setResendDisabled] = useState(true);
  const [email, setEmail] = useState<string>((state && state.email) || '');
  const [messageApi, contextHolder] = message.useMessage();
  const { setIsAuth, setUser } = userSlice.actions;
  const { setUserProjects } = projectsSlice.actions;
  const { setInitialOrganizationsState } = organizationsSlice.actions;
  const { setInitialSubscriptionState } = subscriptionSlice.actions;
  const { setPlugins } = pluginsSlice.actions;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [logoutDisabled, setLogoutDisabled] = useState(false);
  // TODO: refactor => + forgot password page
  const [timerLock, setTimerLock] = useState<boolean>(false);
  const [timeToUnlock, setTimeToUnlock] =
    useState<number>(RESET_TIMER_AFTER_MS);
  const [timerInterval, setTimerInterval] = useState<number | null>(null);

  useEffect(() => {
    setTimeout(() => {
      startTimer();
    }, 1000);
  }, []);

  const startTimer = () => {
    if (timerInterval !== null) {
      window.clearInterval(timerInterval);
    }

    setTimerLock(true);
    setTimeToUnlock(RESET_TIMER_AFTER_MS);

    const currentTime = new Date().getTime();

    const interval = window.setInterval(checkTime, 1000, currentTime);
    setTimerInterval(interval);
  };

  const checkTime = (savedTime: number) => {
    if (!savedTime) {
      return;
    }
    const timeLeft = RESET_TIMER_AFTER_MS - (new Date().getTime() - savedTime);
    setTimeToUnlock(timeLeft);
  };

  const resetTimerLock = () => {
    setTimerLock(false);
    setResendDisabled(false);
    if (timerInterval !== null) {
      window.clearInterval(timerInterval);
      setTimerInterval(null);
    }
  };

  useEffect(() => {
    if (timeToUnlock <= 0) {
      resetTimerLock();
    } else {
      if (timerInterval === null) {
        const interval = window.setInterval(checkTime, 1000);
        setTimerInterval(interval);
      }
    }
  }, [timeToUnlock]);

  const logout = () => {
    setLogoutDisabled(true);
    localStorage.removeItem(LS_TOKEN_KEY);
    localStorage.removeItem(LS_CURRENT_ORGANIZATION_ID);
    dispatch(setIsAuth(false));
    dispatch(setUser(null));
    dispatch(setUserProjects([]));
    dispatch(setPlugins([]));
    dispatch(setInitialOrganizationsState());
    dispatch(setInitialSubscriptionState());
    navigate(RouteNames.LANDING);
    setLogoutDisabled(false);
  };

  useEffect(() => {
    if (state && state.email) {
      setEmail(state.email);
    }
  }, [state]);

  const resendEmail = async () => {
    if (!email) return;
    setResendDisabled(true);

    const result = await resendVerificationEmail(email);

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
    } else {
      await messageApi.success('The verification email has been resent.', 1);
      startTimer();
    }

    setResendDisabled(false);
  };

  return (
    <div className="verify-confirm__inner">
      {isAuth && !user ? (
        <Loading />
      ) : email && user && !user.emailConfirmed ? (
        <div className="verify-email">
          {contextHolder}
          <ZapprEmail className="verify-email-icon" />
          <div className="verify-email-title">Please verify your email</div>
          <div>
            You’re almost there! We sent an email to
            <br />
            <span className="verify-email--user-email">{email}</span>
          </div>
          <div>
            Just click on the link in that email to complete your signup. If you
            don’t see it, you may need to check your spam folder.
          </div>
          <div>Still can’t find the email? No problem.</div>
          {timerLock && timeToUnlock > 0 && (
            <div className={'reset-timer-info reset-timer-info--verify'}>
              You can request the email again after{' '}
              {Math.ceil(timeToUnlock / 1000)}s.
            </div>
          )}
          <Button
            type="primary"
            onClick={resendEmail}
            loading={isLoading}
            disabled={resendDisabled || timerLock}
          >
            Resend Verification Email
          </Button>
          <a href={`https://${getDomain()}`} className="verify-link">
            Go to main page
          </a>
          {isAuth ? (
            <Button
              className="verify-link"
              onClick={logout}
              disabled={logoutDisabled}
            >
              <LogoutOutlined /> Logout
            </Button>
          ) : null}
        </div>
      ) : (
        <Navigate to={RouteNames.USER_PROJECTS} />
      )}
    </div>
  );
};

export default VerifyEmail;
