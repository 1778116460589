import React, { FC, useEffect, useState } from 'react';
import { hexToRgb } from '../../../../../../../../../shared/color-helpers';
import { IGeneratedWidgetStyle } from '../../../fake-widget-helpers';
import IProjectStartInfo from '../../../../../../../../../types/IProjectStartInfo';

interface IBotMessageProps {
  generatedWidgetStyle: IGeneratedWidgetStyle;
  projectStartInfo: IProjectStartInfo;
  message: string;
  senderName: string;
}

const getBgColors = (generatedWidgetStyle: IGeneratedWidgetStyle) => {
  const rgbSecondaryColor = hexToRgb(generatedWidgetStyle.secondaryColor);
  const bgColorDefault = `rgba(${rgbSecondaryColor.r}, ${rgbSecondaryColor.g}, ${rgbSecondaryColor.b}, ${generatedWidgetStyle.secondaryColorOpacity})`;
  const bgColorHover = `rgba(${rgbSecondaryColor.r}, ${rgbSecondaryColor.g}, ${
    rgbSecondaryColor.b
  }, ${generatedWidgetStyle.secondaryColorOpacity + 0.1})`;

  return {
    bgColorDefault,
    bgColorHover,
  };
};

const BotMessage: FC<IBotMessageProps> = ({
  generatedWidgetStyle,
  projectStartInfo,
  message,
  senderName,
}) => {
  const defaultSenderName = senderName || projectStartInfo.name || 'Agent';
  const [bgColors, setBgColors] = useState<{
    bgColorDefault: string;
    bgColorHover: string;
  }>(getBgColors(generatedWidgetStyle));
  const [bgColor, setBgColor] = useState<string>(bgColors.bgColorDefault);

  useEffect(() => {
    setBgColors(getBgColors(generatedWidgetStyle));
  }, [generatedWidgetStyle]);

  useEffect(() => {
    setBgColor(bgColors.bgColorDefault);
  }, [bgColors]);

  return (
    <div>
      <div
        className={'message-header'}
        style={{
          color: generatedWidgetStyle.secondaryColor,
        }}
      >
        <div className={`message-sender`}>
          {senderName || defaultSenderName}
        </div>
        <div className={'message-info'}>
          <div className="msg-time">10:33 PM</div>
        </div>
      </div>
      <div className={'bot-message-container--show-reactions'}>
        <div
          className="message-text"
          style={{
            color: generatedWidgetStyle.messageTextColor.bot,
            backgroundColor: bgColor,
          }}
        >
          {message}
        </div>
      </div>
    </div>
  );
};

export default BotMessage;
