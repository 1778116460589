import { Dispatch, FC, SetStateAction, useState } from 'react';
import { message, Modal } from 'antd';

interface IDeleteRecordModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm: (confirmed: boolean) => void;
}

const DeleteRecordModal: FC<IDeleteRecordModal> = ({
  isOpen,
  setIsOpen,
  onConfirm,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [deleteDisabled, setDeleteDisabled] = useState(false);

  const handleCancel = () => {
    onConfirm(false);
    setIsOpen(false);
  };

  const handleOk = () => {
    onConfirm(true);
    setIsOpen(false);
    setDeleteDisabled(false);
  };

  return (
    <Modal
      wrapClassName={'modal'}
      title="Delete selected records"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ disabled: deleteDisabled, danger: true }}
      centered={true}
    >
      {contextHolder}
      <p>
        Please confirm the deletion of these records. Note that this will
        permanently erase all associated information. This action cannot be
        undone.
      </p>
    </Modal>
  );
};

export default DeleteRecordModal;
