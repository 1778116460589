import IUserSubscription from '../types/IUserSubscription';
import IPlan from '../types/IPlan';
import { BillingPlansTypes } from './constants';

export const GA_CREATE_BOT = (botType: string) => {
  if (process.env.REACT_APP_PARTNER_MODE === 'true') return;
  gtag('event', 'create_bot', {
    bot_type: botType,
  });
};

export const GA_DELETE_BOT = (botType: string) => {
  if (process.env.REACT_APP_PARTNER_MODE === 'true') return;
  gtag('event', 'delete_bot', {
    bot_type: botType,
  });
};

export const GA_REGISTER_USER = () => {
  if (process.env.REACT_APP_PARTNER_MODE === 'true') return;
  gtag('event', 'register_user');
};

export const GA_LOGIN = () => {
  if (process.env.REACT_APP_PARTNER_MODE === 'true') return;
  gtag('event', 'login');
};

export const GA_UPLOAD_FILE = (extension: string) => {
  if (process.env.REACT_APP_PARTNER_MODE === 'true') return;
  gtag('event', 'upload_file', {
    file_extension: extension,
  });
};

export const GA_UPLOAD_QA = (ingest = false) => {
  if (process.env.REACT_APP_PARTNER_MODE === 'true') return;
  const eventName = ingest ? 'upload_ingest_qa' : 'upload_qa';
  gtag('event', eventName);
};

export const GA_DELETE_FILE = (extension: string) => {
  if (process.env.REACT_APP_PARTNER_MODE === 'true') return;
  gtag('event', 'delete_file', {
    file_extension: extension,
  });
};

//sharingType => GABotSharingTypes
export const GA_BOT_SHARE = (botType: string, sharingType: string) => {
  if (process.env.REACT_APP_PARTNER_MODE === 'true') return;
  gtag('event', 'bot_share', {
    bot_type: botType,
    sharing_type: sharingType,
  });
};

// //source => GASendBotMessageSources
// export const GA_SEND_BOT_MESSAGE = (botType: string, source: string) => {
// if (process.env.REACT_APP_PARTNER_MODE === 'true') return;
//   gtag('event', 'send_bot_message', {
//     bot_type: botType,
//     source: source,
//   });
// };

export const GA_SEND_BOT_MESSAGE_DEMO = (botType: string) => {
  if (process.env.REACT_APP_PARTNER_MODE === 'true') return;
  gtag('event', 'send_bot_message_demo', {
    bot_type: botType,
  });
};

export const GA_SUBSCRIPTION_PURCHASE = (purchaseDetails: {
  transactionId: string;
  value: number;
  type: string;
  billingPlanType: BillingPlansTypes;
  subscription: IPlan | string;
}) => {
  if (process.env.REACT_APP_PARTNER_MODE === 'true') return;
  gtag('event', 'purchase', {
    transaction_id: purchaseDetails.transactionId,
    value: purchaseDetails.value,
    type: purchaseDetails.type,
    billing_plan_type: purchaseDetails.billingPlanType,
    items: [purchaseDetails.subscription],
  });
};

export const GA_SUBSCRIPTION_CANCELED = (
  subscription: IUserSubscription | null
) => {
  if (process.env.REACT_APP_PARTNER_MODE === 'true') return;
  gtag('event', 'subscription_canceled', {
    subscription: subscription,
  });
};

export const GA_SUBSCRIPTION_CLOSURE_CANCELED = (
  subscription: IUserSubscription | null
) => {
  if (process.env.REACT_APP_PARTNER_MODE === 'true') return;
  gtag('event', 'subscription_closure_canceled', {
    subscription: subscription,
  });
};
