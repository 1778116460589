import React, { FC } from 'react';
import { Button, Tag } from 'antd';
import {
  CalendarOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import IRecordItemProps from '../IRecordItemProps';
import IDateRecord, {
  DateLabel,
} from '../../../../../types/contacts/contact-records/IDateRecord';
import dayjs from 'dayjs';

const DateRecordItem: FC<IRecordItemProps> = ({
  record,
  handleEditRecord,
  handleDeleteRecord,
}) => {
  const getTagColor = (label: DateLabel) => {
    switch (label) {
      case DateLabel.BIRTHDAY:
        return 'green';
      case DateLabel.ANNIVERSARY:
        return 'blue';
      case DateLabel.OTHER:
        return 'purple';
      default:
        return 'default';
    }
  };

  const getComponent = () => {
    const recordData = record.record as IDateRecord;
    const formattedDate = recordData.useYear
      ? recordData.date
      : dayjs(recordData.date).format('MM-DD');

    return (
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 10,
          }}
        >
          <div style={{ display: 'flex' }}>
            <Tag color={getTagColor(recordData.label)}>{recordData.label}</Tag>
            {recordData.customLabel && (
              <Tag color="default">{recordData.customLabel}</Tag>
            )}
          </div>
          <div style={{ display: 'flex', gap: 5 }}>
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={() => handleEditRecord(record)}
              size={'small'}
            ></Button>
            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={() => handleDeleteRecord(record)}
              danger
              size={'small'}
            ></Button>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
          <CalendarOutlined /> {formattedDate}
        </div>
      </div>
    );
  };

  return getComponent();
};

export default DateRecordItem;
