import { FC } from 'react';
import {
  QuickReplyButtons,
  RenderBodyField,
  RenderInputField,
} from './template-builder';
import { FormInstance } from 'antd';

interface IQuickReplyTemplateProps {
  form: FormInstance<any>;
}

const QuickReplyTemplate: FC<IQuickReplyTemplateProps> = ({ form }) => {
  return (
    <>
      {RenderBodyField(form, 1024)}
      <br />
      <QuickReplyButtons form={form} />
    </>
  );
};

export default QuickReplyTemplate;
