import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Form, Input, message, Modal, Skeleton } from 'antd';
import UploadImageItem from './upload-image-item';
import { domainsAPI } from '../../../services/domains-service';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { getDomainForImage, showErrorMessage } from '../../../shared/helpers';
import { LogoMode, V_REQUIRED_FIELD } from '../../../shared/constants';

interface IEditDomainModal {
  editDomainId: string;
  setEditDomainId: Dispatch<SetStateAction<string>>;
}

const EditDomainModal: FC<IEditDomainModal> = ({
  editDomainId,
  setEditDomainId,
}) => {
  const [
    lazyGetDomainById,
    { data: domainData, isLoading: domainInfoLoading },
  ] = domainsAPI.useLazyGetDomainByIdQuery();
  const [isOpen, seIsOpen] = useState(false);
  const [okDisabled, setOkDisabled] = useState(true);
  const [dataForm] = Form.useForm();
  const [imageForm] = Form.useForm();
  const [updateDomain] = domainsAPI.useUpdateDomainMutation();
  const [updateDomainLogoIconLight] =
    domainsAPI.useUpdateDomainLogoIconLightMutation();
  const [updateDomainLogoTextLight] =
    domainsAPI.useUpdateDomainLogoTextLightMutation();
  const [updateDomainLogoIconDark] =
    domainsAPI.useUpdateDomainLogoIconDarkMutation();
  const [updateDomainLogoTextDark] =
    domainsAPI.useUpdateDomainLogoTextDarkMutation();
  const [updateDomainFavicon] = domainsAPI.useUpdateDomainFaviconMutation();
  const [messageApi, contextHolder] = message.useMessage();
  const [domainDataAllowSave, setDomainDataAllowSave] = useState(false);
  const [imageDataAllowSave, setImageDataAllowSave] = useState(false);
  const [timestamp, setTimestamp] = useState(Date.now());

  useEffect(() => {
    if (editDomainId) {
      lazyGetDomainById({ id: editDomainId }).then((res) => {
        if ('data' in res && res.data) {
          dataForm.setFieldsValue(res.data);
          setDomainDataAllowSave(false);
        }
      });
      seIsOpen(true);
    } else {
      dataForm.resetFields();
      imageForm.resetFields();
      seIsOpen(false);
    }
  }, [editDomainId]);

  useEffect(() => {
    if (domainDataAllowSave || imageDataAllowSave) {
      setOkDisabled(false);
    } else {
      setOkDisabled(true);
    }
  }, [domainDataAllowSave, imageDataAllowSave]);

  const handleCancel = () => {
    setEditDomainId('');
  };

  const handleDataFormChange = async () => {
    if (!domainData) return;
    const values = dataForm.getFieldsValue();

    try {
      await dataForm.validateFields();
    } catch (errors) {
      setDomainDataAllowSave(false);
      return;
    }

    for (let key in values) {
      if (
        values[key] &&
        values[key] !== domainData[key as keyof typeof domainData]
      ) {
        setDomainDataAllowSave(true);
        return;
      }
    }

    setDomainDataAllowSave(false);
  };

  const handleImageFormChange = async () => {
    const values = imageForm.getFieldsValue();

    for (let key in values) {
      if (values[key] && values[key].length) {
        setImageDataAllowSave(true);
        return;
      }
    }

    setImageDataAllowSave(false);
  };

  const handleDomainDataSave = async (hasErrors: boolean) => {
    const values = dataForm.getFieldsValue();
    const result = await updateDomain({ id: editDomainId, body: values });

    if ('error' in result) {
      hasErrors = true;
      await showErrorMessage(messageApi, result.error);
    } else {
      await messageApi.success('The domain has been updated.', 1);
      dataForm.resetFields();
      imageForm.resetFields();
    }
  };

  const handleImageFormSave = async (hasErrors: boolean) => {
    const values = imageForm.getFieldsValue();

    let errors = false;

    for (let key in values) {
      if (values[key] && values[key].length) {
        const formData = new FormData();
        formData.append('file', values[key][0].originFileObj);

        const showResMessage = async (
          res:
            | { data: void }
            | { error: FetchBaseQueryError | SerializedError },
          successMsg: string
        ) => {
          if ('error' in res) {
            errors = true;
            hasErrors = true;
            await showErrorMessage(messageApi, res.error);
          } else {
            messageApi.success(successMsg);
          }
        };

        switch (key) {
          case 'logoIconLight':
            const res1 = await updateDomainLogoIconLight({
              id: editDomainId,
              body: formData,
            });
            showResMessage(res1, 'Logo icon light has been updated.');
            break;
          case 'logoTextLight':
            const res2 = await updateDomainLogoTextLight({
              id: editDomainId,
              body: formData,
            });
            showResMessage(res2, 'Logo text light has been updated.');
            break;
          case 'logoIconDark':
            const res3 = await updateDomainLogoIconDark({
              id: editDomainId,
              body: formData,
            });
            showResMessage(res3, 'Logo icon dark has been updated.');
            break;
          case 'logoTextDark':
            const res4 = await updateDomainLogoTextDark({
              id: editDomainId,
              body: formData,
            });
            showResMessage(res4, 'Logo text dark has been updated.');
            break;
          case 'favicon':
            const res5 = await updateDomainFavicon({
              id: editDomainId,
              body: formData,
            });
            showResMessage(res5, 'Favicon has been updated.');
            break;
        }
      }

      setTimestamp(Date.now());
    }

    if (!errors) {
      imageForm.resetFields();
    }
  };

  const handleOk = async () => {
    setOkDisabled(true);

    let hasErrors = false;

    if (domainDataAllowSave) {
      await handleDomainDataSave(hasErrors);
    }

    if (imageDataAllowSave) {
      await handleImageFormSave(hasErrors);
    }

    setOkDisabled(false);

    if (!hasErrors) {
      setEditDomainId('');
    }
  };

  const inputFormItem = (
    name: string,
    label: string,
    placeholder: string,
    tooltip?: string
  ) => (
    <Form.Item
      name={name}
      label={label}
      tooltip={tooltip}
      rules={[
        {
          required: true,
          message: V_REQUIRED_FIELD,
        },
      ]}
    >
      <Input placeholder={placeholder} />
    </Form.Item>
  );

  const dataFormComponent = () => (
    <Form
      form={dataForm}
      onChange={handleDataFormChange}
      className="data-source-upload-modal-form"
      layout="vertical"
      // initialValues={domainData}
      requiredMark={false}
    >
      {inputFormItem('brandName', 'Brand name:', 'Brand Name')}
      {inputFormItem(
        'email',
        'Email:',
        'noreply@domain.com',
        'Email address to send notifications to your users, e.g. noreply@domain.com.'
      )}
      {inputFormItem(
        'emailApiKey',
        'SendGrid API Key:',
        'API Key',
        'API key associated with your SendGrid service.'
      )}
    </Form>
  );

  const imageFormItem = (
    label: string,
    name: string,
    accept: string,
    mode: LogoMode,
    tooltip?: string
  ) => (
    <UploadImageItem
      label={label}
      name={name}
      form={imageForm}
      handleChange={handleImageFormChange}
      accept={accept}
      mode={mode}
      tooltip={tooltip}
    />
  );

  const previewImage = (src: string, mode: LogoMode) => {
    const bg = mode === LogoMode.LIGHT ? '#000' : '#fff';

    return (
      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
      >
        <img
          style={{
            maxWidth: '100%',
            maxHeight: 62,
            backgroundColor: bg,
            borderRadius: 8,
            padding: 8,
          }}
          src={src}
        />
      </div>
    );
  };

  const imageFormComponent = () => {
    if (!domainData) return null;
    const logoIconLightSrc = `https://static.${getDomainForImage()}/static/domains/${
      domainData.id
    }/logo-${LogoMode.LIGHT}.svg?timestamp=${timestamp}`;
    const logoTextLightSrc = `https://static.${getDomainForImage()}/static/domains/${
      domainData.id
    }/logo-text-${LogoMode.LIGHT}.svg?timestamp=${timestamp}`;
    const logoIconDarkSrc = `https://static.${getDomainForImage()}/static/domains/${
      domainData.id
    }/logo-${LogoMode.DARK}.svg?timestamp=${timestamp}`;
    const logoTextDarkSrc = `https://static.${getDomainForImage()}/static/domains/${
      domainData.id
    }/logo-text-${LogoMode.DARK}.svg?timestamp=${timestamp}`;
    const faviconSrc = `https://static.${getDomainForImage()}/static/domains/${
      domainData.id
    }/favicon.ico?${timestamp}`;

    const item = (
      formItem: {
        label: string;
        name: string;
        accept: string;
        tooltip?: string;
      },
      previewSrc: string,
      mode: LogoMode
    ) => {
      return (
        <div
          style={{ display: 'flex', gap: 20, justifyContent: 'space-between' }}
        >
          {imageFormItem(
            formItem.label,
            formItem.name,
            formItem.accept,
            mode,
            formItem.tooltip
          )}
          {previewImage(previewSrc, mode)}
        </div>
      );
    };

    return (
      <Form
        form={imageForm}
        onChange={handleImageFormChange}
        className="data-source-upload-modal-form"
        layout="vertical"
      >
        {item(
          {
            label: 'Brand Logo (light)',
            name: 'logoIconLight',
            accept: 'image/svg+xml',
            tooltip:
              'SVG image (256x256) optimized for pages with dark background.',
          },
          logoIconLightSrc,
          LogoMode.LIGHT
        )}
        {item(
          {
            label: 'Brand Name Logo (light)',
            name: 'logoTextLight',
            accept: 'image/svg+xml',
            tooltip: 'SVG image optimized for pages with dark background.',
          },
          logoTextLightSrc,
          LogoMode.LIGHT
        )}
        {item(
          {
            label: 'Brand Logo (dark)',
            name: 'logoIconDark',
            accept: 'image/svg+xml',
            tooltip:
              'SVG image (256x256) optimized for pages with light background.',
          },
          logoIconDarkSrc,
          LogoMode.DARK
        )}
        {item(
          {
            label: 'Brand Name Logo (dark)',
            name: 'logoTextDark',
            accept: 'image/svg+xml',
            tooltip: 'SVG image optimized for pages with light background.',
          },
          logoTextDarkSrc,
          LogoMode.DARK
        )}
        {item(
          {
            label: 'Favicon',
            name: 'favicon',
            accept: 'image/x-icon',
          },
          faviconSrc,
          LogoMode.DARK
        )}
      </Form>
    );
  };

  const getComponent = () => {
    if (domainInfoLoading) {
      return <Skeleton active />;
    }

    if (domainData) {
      return (
        <>
          {dataFormComponent()}
          {imageFormComponent()}
        </>
      );
    }

    return null;
  };

  return (
    <Modal
      wrapClassName={'modal'}
      title="Edit domain"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ disabled: okDisabled }}
    >
      {contextHolder}
      {getComponent()}
    </Modal>
  );
};

export default EditDomainModal;
