import TablesUtils from '../../components/shared/tables/tables-utils';
import { showErrorMessage } from '../../shared/helpers';
import { tablesApi } from '../../services/tables-service';
import {
  IEditorTableColumn,
  ITableRecordsT,
  ITableT,
} from '../../types/tables-service/ITableT';
import { MessageInstance } from 'antd/es/message/interface';

interface IUseUpdateTableDataParams {
  projectId: string;
  tableData: ITableT | undefined;
  tableRecordsData: ITableRecordsT | undefined;
  tableColumns: IEditorTableColumn[];
  tableName: string | null;
  messageApi: MessageInstance;
  cb: () => void;
}

export default function useUpdateTableData(
  params: IUseUpdateTableDataParams
): [() => void, boolean] {
  const [updateTable, { isLoading: updateTableLoading }] =
    tablesApi.useUpdateTableTMutation();

  const {
    projectId,
    tableData,
    tableRecordsData,
    tableColumns,
    tableName,
    messageApi,
    cb,
  } = params;

  const update = async () => {
    if (!tableData || !tableRecordsData || tableName === null) return;

    const initialCols = tableData.columns || [];
    const changedColumns = TablesUtils.getChangedColumns(
      tableColumns,
      initialCols
    );

    if (
      changedColumns.length ||
      TablesUtils.nameIsChanged(tableData.name, tableName)
    ) {
      const cols = TablesUtils.transformEditorColumnsToPOST(tableColumns);
      const res = await updateTable({
        projectId,
        id: tableData.id,
        name: tableName,
        columns: cols,
      });

      if ('error' in res) {
        showErrorMessage(messageApi, res.error);
      } else {
        cb();
      }
    }
  };

  return [update, updateTableLoading];
}
