import React, { FC } from 'react';
import { Divider } from 'antd';
import NoteRecordItem from './note-record-item';
import IRecordViewProps from '../IRecordViewProps';

const NoteRecordsView: FC<IRecordViewProps> = ({
  records,
  handleEditRecord,
  handleDeleteRecord,
}) => {
  return (
    <div>
      <div>
        <Divider orientation="left" className={'contact-form-divider'}>
          Notes
        </Divider>
        {records.map((record, index) => (
          <div key={index}>
            <NoteRecordItem
              record={record}
              handleEditRecord={handleEditRecord}
              handleDeleteRecord={handleDeleteRecord}
            />
            {index !== records.length - 1 && (
              <Divider
                orientation="left"
                className={'contact-form-divider'}
                dashed={true}
              ></Divider>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NoteRecordsView;
