import { FC, useEffect, useState } from 'react';
import { whatsAppOutboundApi } from '../../../../services/what-app-outbound-service';
import {
  ITwilioContentTemplate,
  ITwilioTemplateBodyContent,
} from '../../../../types/whats-app-outbound-service/ICampaignsOptions';
import { getFormattedMessageDate } from '../../conversations/conversation-helpers';
import { Button, Modal, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import TwilioTemplatePreview from './twilio-template-preview';
import './twilio-template.scss';
import Loading from '../../../loading/loading';
import CreatingTemplateModal, { contentTypes } from './creating-template-modal';
import languageOptions from './language-options';
import { CiTextAlignJustify } from 'react-icons/ci';
import { MdOutlinePermMedia } from 'react-icons/md';
import TemplateMoreMenu from './template-more-menu';

const TwilioTemplates: FC<{
  projectId: string;
}> = ({ projectId }) => {
  const [
    lazyGetCampaignsSetUpOptions,
    { data: campaignsOptions, isLoading: campaignsOptionsLoading },
  ] = whatsAppOutboundApi.useLazyGetCampaignsSetUpOptionsQuery();
  const [templates, setTemplates] = useState<ITwilioContentTemplate[]>([]);
  const [columns, setColums] = useState<ColumnsType<ITwilioContentTemplate>>(
    []
  );
  const [rows, setRows] = useState<ITwilioContentTemplate[]>([]);
  const [selectedRow, setSelectedRow] =
    useState<ITwilioTemplateBodyContent | null>(null);
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [creatingModalIsOpen, setCreatingModalIsOpen] =
    useState<boolean>(false);
  const [refreshToggle, setRefreshToggle] = useState(false);

  const refresh = () => {
    setRefreshToggle((prevToggle) => !prevToggle);
  };

  const getCampaigns = async () => {
    await lazyGetCampaignsSetUpOptions({ projectId });
  };
  useEffect(() => {
    getCampaigns();
  }, [projectId, refreshToggle]);

  useEffect(() => {
    if (campaignsOptions) {
      setTemplates(campaignsOptions.contentTemplates);
    }
  }, [campaignsOptions]);

  useEffect(() => {
    const statusColors: { [key: string]: string } = {
      approved: 'green',
      rejected: 'red',
    };

    const cols: ColumnsType<ITwilioContentTemplate> = [
      { title: 'Name', dataIndex: 'name', key: 'name' },
      { title: 'Language', dataIndex: 'language', key: 'language', width: 135 },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        width: 150,
        render: (text: string) => {
          const formattedType = text
            .replace('twilio/', '')
            .replace(/-/g, ' ')
            .replace(/\b\w/g, (char) => char.toUpperCase());

          const IconComponent = contentTypes.find(
            (c) => c.name.toLowerCase() === formattedType.toLowerCase()
          )?.icon;

          return (
            <span>
              {IconComponent && <IconComponent style={{ marginRight: 8 }} />}
              {formattedType}
            </span>
          );
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 150,
        render: (text: string) => {
          const color = statusColors[text] || '#e0e0e0';

          return (
            <Tag color={color} style={{ textTransform: 'capitalize' }}>
              {text}
            </Tag>
          );
        },
      },
      {
        title: 'Date Created',
        dataIndex: 'dateCreated',
        key: 'dateCreated',
        width: 130,
      },
      {
        render: (record) => {
          return (
            <TemplateMoreMenu
              templateId={record.id}
              projectId={projectId}
              status={record.status}
              refreshParent={refresh}
            />
          );
        },
        width: 20,
      },
    ];

    setColums(cols);

    const data = templates.map((template) => {
      const result: ITwilioContentTemplate = {
        ...template,
        dateCreated: getFormattedMessageDate(template.dateCreated).replace(
          /:\d{2}(?=\s[APM]{2})/,
          ''
        ),
        language: languageOptions.filter((l) => l.key === template.language)[0]
          ?.name,
      };

      return result;
    });

    setRows(data);
  }, [templates]);

  const closeModal = () => {
    setSelectedRow(null);
    setSelectedType(null);
  };

  const renderTemplatePreviewModal = () => {
    return (
      <Modal
        open={!!selectedRow}
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={closeModal}
        onOk={closeModal}
        title="Template preview:"
        className="template-preview"
      >
        <TwilioTemplatePreview content={selectedRow} type={selectedType} />
      </Modal>
    );
  };

  const handleRowClick = (record: ITwilioContentTemplate) => {
    setSelectedRow(record.content);
    setSelectedType(record.type);
  };

  const renderTable = () => {
    return (
      <Table
        pagination={false}
        className={'rdg-light'}
        columns={columns}
        dataSource={rows}
        rowKey="id"
        onRow={(record) => {
          return {
            className:
              record.status === 'unsubmitted' ? 'disabled-row' : 'enabled-row',
            onClick: () => handleRowClick(record),
          };
        }}
        style={{
          height: '100%',
          borderBottom: 0,
          borderRight: 0,
        }}
      />
    );
  };

  const renderCreateButton = () => {
    return (
      <Button
        className="template-create-button"
        onClick={() => {
          setCreatingModalIsOpen(true);
        }}
      >
        Create template
      </Button>
    );
  };

  if (campaignsOptionsLoading) {
    return <Loading height={'300px'} />;
  }

  return (
    <>
      {renderCreateButton()}
      {renderTable()}
      {renderTemplatePreviewModal()}
      <CreatingTemplateModal
        isOpen={creatingModalIsOpen}
        setIsOpen={setCreatingModalIsOpen}
        projectId={projectId}
        onRefresh={refresh}
      />
    </>
  );
};

export default TwilioTemplates;
