import React, { FC, useState, useEffect } from 'react';
import { Alert } from 'antd';
import LocalStorageService from '../../../../services/browser/local-storage-service';

interface IRecordFailedInfo {
  countError: number;
  tableId: string;
}

const RecordFailedInfo: FC<IRecordFailedInfo> = ({ countError, tableId }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const storageData = LocalStorageService.getData(
      LocalStorageService.OUTBOUND
    );

    if (!storageData || !storageData[tableId]) return;

    if (
      storageData[tableId].wasClosed &&
      storageData[tableId].countError == countError
    ) {
      setVisible(false);
    }
  }, [tableId]);

  const generateSelectedInfo = () => {
    if (!countError || !visible) return null;

    const renderText = () => {
      const plural = countError === 1 ? 'message' : 'messages';

      return (
        <div>
          Failed to send {countError} {plural}. To learn more, please check the
          corresponding conversation or{' '}
          <span className={'records-selected-info-btn'} style={{ padding: 0 }}>
            <a
              href="https://console.twilio.com/us1/monitor/logs/debugger/errors"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twilio Console Logs.
            </a>
          </span>
        </div>
      );
    };

    return renderText();
  };

  const close = () => {
    setVisible(false);

    const storageData =
      LocalStorageService.getData(LocalStorageService.OUTBOUND) || {};

    storageData[tableId] = {
      wasClosed: true,
      countError: countError,
    };

    LocalStorageService.saveData(LocalStorageService.OUTBOUND, storageData);
  };

  return countError > 0 && visible ? (
    <Alert
      message={generateSelectedInfo()}
      type="error"
      style={{ textAlign: 'center', marginTop: 24, marginBottom: 24 }}
      closable
      onClose={close}
    />
  ) : null;
};

export default RecordFailedInfo;
