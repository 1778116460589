import { apiService, APITagTypes } from './api-service';
import IDomain from '../types/domains-service/IDomain';
import IDomainInfo from '../types/domains-service/IDomainInfo';

export const domainsAPI = apiService.injectEndpoints({
  endpoints: (build) => ({
    getCurrentDomainInfo: build.query<
      { name: string; filePath: string; email: string | null },
      void
    >({
      query: () => ({
        url: `/domains/me`,
        method: 'GET',
      }),
    }),
    getDomains: build.query<IDomain[], void>({
      query: () => ({
        url: `/domains`,
        method: 'GET',
      }),
      providesTags: () => [APITagTypes.DOMAINS],
    }),
    getDomainById: build.query<IDomainInfo, { id: string }>({
      query: ({ id }) => ({
        url: `/domains/${id}`,
        method: 'GET',
      }),
    }),
    addDomain: build.mutation<
      IDomain,
      {
        body: {
          domain: string;
          brandName: string;
          email: string;
          emailApiKey: string;
        };
      }
    >({
      query: ({ body }) => ({
        url: `/domains`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(body),
      }),
      invalidatesTags: () => [APITagTypes.DOMAINS],
    }),
    updateDomain: build.mutation<
      IDomain,
      {
        id: string;
        body: {
          brandName: string;
          email: string;
          emailApiKey: string;
        };
      }
    >({
      query: ({ id, body }) => ({
        url: `/domains/${id}`,
        method: 'PUT',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(body),
      }),
      invalidatesTags: () => [APITagTypes.DOMAINS],
    }),
    updateDomainLogoIconLight: build.mutation<
      void,
      { id: string; body: FormData }
    >({
      query: ({ id, body }) => ({
        url: `/domains/${id}/logo/light`,
        method: 'POST',
        headers: {
          'content-type': undefined,
        },
        body: body,
      }),
      invalidatesTags: () => [APITagTypes.DOMAINS],
    }),
    updateDomainLogoTextLight: build.mutation<
      void,
      { id: string; body: FormData }
    >({
      query: ({ id, body }) => ({
        url: `/domains/${id}/logo/text-light`,
        method: 'POST',
        headers: {
          'content-type': undefined,
        },
        body: body,
      }),
      invalidatesTags: () => [APITagTypes.DOMAINS],
    }),
    updateDomainLogoIconDark: build.mutation<
      void,
      { id: string; body: FormData }
    >({
      query: ({ id, body }) => ({
        url: `/domains/${id}/logo/dark`,
        method: 'POST',
        headers: {
          'content-type': undefined,
        },
        body: body,
      }),
      invalidatesTags: () => [APITagTypes.DOMAINS],
    }),
    updateDomainLogoTextDark: build.mutation<
      void,
      { id: string; body: FormData }
    >({
      query: ({ id, body }) => ({
        url: `/domains/${id}/logo/text-dark`,
        method: 'POST',
        headers: {
          'content-type': undefined,
        },
        body: body,
      }),
      invalidatesTags: () => [APITagTypes.DOMAINS],
    }),
    updateDomainFavicon: build.mutation<void, { id: string; body: FormData }>({
      query: ({ id, body }) => ({
        url: `/domains/${id}/logo/icon`,
        method: 'POST',
        headers: {
          'content-type': undefined,
        },
        body: body,
      }),
      invalidatesTags: () => [APITagTypes.DOMAINS],
    }),
  }),
});
