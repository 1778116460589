import { FC } from 'react';
import BuyExtraTokens from '../usage-setting/buy-extra-tokens';
import { GoInfo } from 'react-icons/go';
import { useAppSelector } from '../../../hooks/redux-hooks';

const BuyCreditsSetting: FC = () => {
  const { userPersonalSubscription } = useAppSelector(
    (state) => state.subscriptionReducer
  );

  const renderText = () => {
    if (userPersonalSubscription?.discount) return null;

    return (
      <div className={'extra-credits-zone_tip'}>
        <GoInfo style={{ fontSize: 18 }} />
        <div style={{ marginLeft: 10 }}>
          You can purchase any amount of non-expiring credits, but subscribing
          offers a lower price.
        </div>
      </div>
    );
  };

  return (
    <div className="purchase-container">
      <div
        className="profile-setting-content-container"
        style={{ maxWidth: 430 }}
      >
        <BuyExtraTokens />
      </div>
      {renderText()}
    </div>
  );
};

export default BuyCreditsSetting;
