import NotificationSaveChangesDescription from '../../notifications/notification-save-changes-description/notification-save-changes-description';
import React, { useEffect } from 'react';
import { NotificationInstance } from 'antd/es/notification/interface';
import { CloseOutlined } from '@ant-design/icons';

interface IOpenSaveChanges {
  (
    notificationAPI: NotificationInstance,
    key: string,
    saveChangesHandler: () => void,
    saveLoading: boolean,
    saveDisabled: boolean,
    className: string,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  ): void;
}

export const openSaveChanges: IOpenSaveChanges = (
  notificationAPI,
  key,
  saveChangesHandler,
  saveLoading,
  saveDisabled,
  className,
  setIsOpen
) => {
  notificationAPI.open({
    key: key,
    message: '',
    closeIcon: <CloseOutlined />,
    description: (
      <NotificationSaveChangesDescription
        saveChangesHandler={() => {
          notificationAPI.destroy(key);
          saveChangesHandler();
        }}
        saveLoading={saveLoading}
        saveDisabled={saveDisabled}
        cb={() => updateSaveChangesPosition(className)}
      />
    ),
    placement: 'top',
    duration: 0,
    className: `notification-save-changes ${className}`,
    onClose: () => setIsOpen(false),
  });
};

const updateSaveChangesPosition = (className: string) => {
  const notification = document.querySelector('.' + className);
  const scrollY = window.scrollY;

  let top = 49;

  const header = document.querySelector('.dashboard-header');
  if (header) {
    const headerHeight = header.clientHeight;
    top = headerHeight - 23;
  }

  if (!notification) return;

  if (scrollY > 72) {
    // @ts-ignore
    notification.style.top = `-25px`;
  } else if (scrollY <= 72) {
    const t = top - scrollY;
    // @ts-ignore
    notification.style.top = `${t}px`;
  } else {
    // @ts-ignore
    notification.style.top = `${top}px`;
  }
};

export const useScrollSaveChangesSubscription = (
  notificationIsOpen: boolean,
  className: string
) => {
  useEffect(() => {
    const updateNotificationPosition = () => {
      updateSaveChangesPosition(className);
    };

    if (notificationIsOpen) {
      window.addEventListener('scroll', updateNotificationPosition);
    } else {
      window.removeEventListener('scroll', updateNotificationPosition);
    }

    return () => {
      window.removeEventListener('scroll', updateNotificationPosition);
    };
  }, [notificationIsOpen, updateSaveChangesPosition]);
};
