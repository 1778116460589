import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { message, Modal } from 'antd';
import { projectsAPI } from '../../../services/projects-service';
import { showErrorMessage } from '../../../shared/helpers';
import { RouteNames } from '../../../router/router';
import { useNavigate } from 'react-router-dom';
import IProject from '../../../types/IProject';
import { GA_DELETE_BOT } from '../../../shared/google-analytics';
import { LS_CURRENT_ORGANIZATION_ID } from '../../../shared/constants';

interface IDeleteProjectModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  projectId: string;
  projectInfo: IProject | undefined;
}

const DeleteProjectModal: FC<IDeleteProjectModal> = ({
  isOpen,
  setIsOpen,
  projectId,
  projectInfo,
}) => {
  const [deleteProject, { isLoading }] = projectsAPI.useDeleteProjectMutation();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const [deleteDisabled, setDeleteDisabled] = useState(false);

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleOk = async () => {
    setDeleteDisabled(true);
    const result = await deleteProject(projectId);

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
      setIsOpen(false);
    } else {
      GA_DELETE_BOT(projectInfo?.type || 'N/A');
      const orgId = localStorage.getItem(LS_CURRENT_ORGANIZATION_ID);
      const navigateTo = orgId
        ? RouteNames.USER_PROJECTS + '/' + orgId
        : RouteNames.USER_PROJECTS;
      navigate(navigateTo);
      await messageApi.success('The project has been deleted.', 1);
      setIsOpen(false);
    }

    setDeleteDisabled(false);
  };

  return (
    <Modal
      wrapClassName={'modal'}
      title="Delete project"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={isLoading}
      okButtonProps={{ disabled: deleteDisabled, danger: true }}
    >
      {contextHolder}
      <p>
        Please confirm the deletion of this project. Note that this will
        permanently erase all associated information, knowledge, and message
        history. This action cannot be undone
      </p>
    </Modal>
  );
};

export default DeleteProjectModal;
