import { FC, useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import IDefaultPluginFieldProps from '../IDefaultPluginField';
import IProjectPlugin from '../../../../../types/IProjectPlugin';
import { projectPluginsAPI } from '../../../../../services/project-plugins-service';
import { V_REQUIRED_FIELD } from '../../../../../shared/constants';

interface JiraEnumProps extends IDefaultPluginFieldProps {
  values: { key: string; name: string }[];
  plugin: IProjectPlugin;
  projectId: string;
}

const JiraEnum: FC<JiraEnumProps> = ({
  displayName,
  keyBase,
  index,
  isRequired,
  description,
  convertValue,
  placeholder,
  values,
  plugin,
  projectId,
}) => {
  const [list, setList] = useState<{ key: string; name: string }[]>(values);

  const [lazyGetJiraProjects] = projectPluginsAPI.useLazyGetJiraProjectsQuery();

  useEffect(() => {
    if (projectId && plugin.id) {
      lazyGetJiraProjects({
        projectId,
        pluginId: plugin.id,
        projectPluginId: plugin.id,
      })
        .then(({ data }) => {
          if (data) {
            setList(data);
          }
        })
        .catch((error) => {
          console.error('Failed to fetch Jira projects:', error);
        });
    }
  }, [projectId, plugin, lazyGetJiraProjects]);

  const renderSelect = () => {
    return (
      <Form.Item
        label={displayName}
        name={keyBase}
        key={index}
        rules={[
          {
            required: isRequired,
            message: V_REQUIRED_FIELD,
          },
        ]}
        tooltip={description}
        initialValue={convertValue}
      >
        <Select placeholder={placeholder ?? displayName} value={convertValue}>
          {list.map((option) => (
            <Select.Option key={option.key} value={option.key}>
              {option.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  };

  return <>{renderSelect()}</>;
};

export default JiraEnum;
