import { FC } from 'react';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import { IAnalyticsEventsData } from './analytics-data';
import { chartColors } from '../../charts/chart-data';

const AnalyticsLegend: FC<{
  data: IAnalyticsEventsData[];
  analyticsList: IAnalyticsEventsData[];
  onClick: (payload: IAnalyticsEventsData) => void;
}> = ({ data, analyticsList, onClick }) => {
  const isPayloadSelected = (payload: IAnalyticsEventsData) =>
    analyticsList.includes(payload);

  const renderLegends = () => {
    return (
      <div className="analytics__legend-container">
        {data.map((payload, i) => (
          <div
            key={'legend_' + payload.eventName}
            style={{
              color: isPayloadSelected(payload)
                ? chartColors[i % chartColors.length]
                : '#ccc',
            }}
            className="analytics__legend"
            onClick={() => onClick(payload)}
          >
            {isPayloadSelected(payload) ? (
              <ImCheckboxChecked />
            ) : (
              <ImCheckboxUnchecked />
            )}
            <span style={{ marginLeft: '5px' }}>{payload.chartName}</span>
          </div>
        ))}
      </div>
    );
  };

  return <>{renderLegends()}</>;
};

export default AnalyticsLegend;
