import React, { FC, useEffect, useState } from 'react';
import { Button, Form, FormInstance, Input } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { V_REQUIRED_FIELD } from '../../../../../../shared/constants';
import BubbleTimeFormItem from './bubble-time-form-item';
import IProject from '../../../../../../types/IProject';
import { BUBBLE_DEFAULT_MESSAGE } from '../fake-widget/fake-widget-components/bubble/bubble';

interface IBubbleTimeProps {
  name: string;
  label: string;
  tooltip: string;
  placeholder: string;
}

const bubbleTimeProps: IBubbleTimeProps[] = [
  {
    name: 'startDelay',
    label: 'Start delay',
    tooltip:
      'The time in seconds after the widget is loaded before the speech bubble is shown.',
    placeholder: '3',
  },
  {
    name: 'duration',
    label: 'Duration',
    tooltip: 'The time in seconds the speech bubble is shown.',
    placeholder: '12', // 11.8 is the default value in widget config
  },
];

interface IBubbleSettingsFieldProps {
  handleChange: () => void;
  bubbleSettingsForm: FormInstance;
  setBubbleMessage: React.Dispatch<React.SetStateAction<string>>;
}

const BubbleSettingsField: FC<IBubbleSettingsFieldProps> = ({
  handleChange,
  bubbleSettingsForm,
  setBubbleMessage,
}) => {
  const [calculateMinDelaysTrigger, setCalculateMinDelaysTrigger] =
    useState<number>(Date.now());

  const values = Form.useWatch([], bubbleSettingsForm);

  useEffect(() => {
    bubbleSettingsForm
      .validateFields()
      .then(() => {
        handleChange();
      })
      .catch((e) => {
        if ((e as { outOfDate: boolean }).outOfDate) {
          console.log('E: out of date (ignored)', e);
          return;
        }
        console.error(e);
      });

    if (values && values.items) {
      const firstItem = values.items[0];
      setBubbleMessage(firstItem.text || BUBBLE_DEFAULT_MESSAGE);
    }
  }, [values]);

  const messageInputFormItem = (
    restField: { fieldKey?: number | undefined },
    name: number,
    index: number
  ) => {
    return (
      <Form.Item
        {...restField}
        name={[name, 'text']}
        style={{ width: '100%', marginBottom: 10 }}
        rules={[
          {
            required: true,
            message: V_REQUIRED_FIELD,
          },
        ]}
        label={index === 0 ? 'Message' : undefined}
      >
        <Input placeholder={BUBBLE_DEFAULT_MESSAGE} />
      </Form.Item>
    );
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 5,
          marginBottom: 8,
          marginTop: 24,
        }}
      >
        Speech bubbles
      </div>
      <Form.List name={'items'}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <div style={{ display: 'flex', gap: 8 }} key={key}>
                {messageInputFormItem(restField, name, index)}
                {bubbleTimeProps.map((props) => (
                  <BubbleTimeFormItem
                    restField={restField}
                    name={name}
                    index={index}
                    props={props}
                    bubbleSettingsForm={bubbleSettingsForm}
                    calculateMinDelaysTrigger={calculateMinDelaysTrigger}
                    setCalculateMinDelaysTrigger={setCalculateMinDelaysTrigger}
                  />
                ))}
                {fields.length > 1 && (
                  <MinusCircleOutlined
                    onClick={() => {
                      remove(name);
                      handleChange();
                    }}
                    style={{
                      alignItems: 'flex-start',
                      marginTop: index === 0 ? 39 : 9,
                    }}
                  />
                )}
              </div>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => {
                  add();
                  handleChange();
                }}
                block
                icon={<PlusOutlined />}
                style={{ marginTop: fields.length > 0 ? 8 : 0 }}
              >
                Add speech bubble
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};

export default BubbleSettingsField;
