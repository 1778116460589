import React, { FC } from 'react';
import { Button, Divider } from 'antd';
import IContact from '../../../../types/contacts/IContact';
import { EditOutlined } from '@ant-design/icons';

interface IBasicInfoViewProps {
  contactData: IContact;
  handleEditBasicInfo: () => void;
}

const BasicInfoView: FC<IBasicInfoViewProps> = ({
  contactData,
  handleEditBasicInfo,
}) => {
  const { basicInfo } = contactData;

  return (
    <div>
      <Divider orientation="left" className={'contact-form-divider'}>
        Basic Info
      </Divider>
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 10,
          }}
        >
          <div className={'contact__title'}>{basicInfo.title}</div>
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => handleEditBasicInfo()}
            size={'small'}
          ></Button>
        </div>
        <div style={{ marginBottom: 10 }}>
          {basicInfo.firstName} {basicInfo.middleName} {basicInfo.lastName}
        </div>
        <div className={'contact__nickname'}>{basicInfo.nickname}</div>
      </div>
    </div>
  );
};

export default BasicInfoView;
