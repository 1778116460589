import React, { FC } from 'react';
import { getSvgPath } from 'figma-squircle';
import { getGlowFeColorMatrices, getShadowGlowFilter } from './svg-filters';
import { hexToRgb } from '../../shared/color-helpers';

interface ISuperellipseProps {
  width: number;
  height: number;
  radius: number;
  smoothing: number;
  color?: string;
  border?: {
    width: number;
    color: string;
    gradient?: boolean;
  };
  shadowEnabled?: boolean;
  glowEnabled?: boolean;
  glowColors?: string[];
}

const Superellipse: FC<ISuperellipseProps> = ({
  width,
  height,
  radius = 0,
  smoothing = 0.5,
  color = '#000000',
  border = { width: 0, color: '#000000', gradient: false },
  shadowEnabled = false,
  glowEnabled = false,
  glowColors = [],
}) => {
  const borderGradientColor = hexToRgb(border.color);
  const glowColorMatrices = getGlowFeColorMatrices(glowColors);
  const filter = getShadowGlowFilter(
    shadowEnabled,
    glowEnabled,
    glowColorMatrices
  );

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      style={{ verticalAlign: 'top' }}
      overflow="visible"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        {filter.defs}

        <linearGradient
          id="defaultBorderGradient"
          x1="0%"
          y1="0%"
          x2="35%"
          y2="110%"
        >
          <stop
            offset="0%"
            style={{
              stopColor: `rgba(${borderGradientColor.r}, ${borderGradientColor.g}, ${borderGradientColor.b}, 1)`,
            }}
          />
          <stop
            offset="100%"
            style={{
              stopColor: `rgba(${borderGradientColor.r}, ${borderGradientColor.g}, ${borderGradientColor.b}, 0)`,
            }}
          />
        </linearGradient>
        <linearGradient
          id="defaultBgGradient"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop
            offset="0%"
            style={{
              stopColor: `rgba(${borderGradientColor.r}, ${borderGradientColor.g}, ${borderGradientColor.b}, 0.1)`,
            }}
          />
          <stop
            offset="100%"
            style={{
              stopColor: `rgba(${borderGradientColor.r}, ${borderGradientColor.g}, ${borderGradientColor.b}, 0)`,
            }}
          />
        </linearGradient>
      </defs>

      <g>
        {/* glow container */}
        <path
          fill={color}
          filter={`url(#${filter.name})`}
          d={getSvgPath({
            width: width - border.width * 2,
            height: height - border.width * 2,
            cornerRadius: radius,
            cornerSmoothing: smoothing,
          }).replace(/--/g, '-')} // TODO: WHAT?
          transform={`translate(${border.width}, ${border.width})`}
        ></path>

        {/* border */}
        {border.width > 0 && (
          <path
            fill={
              border.gradient ? 'url(#defaultBorderGradient)' : border.color
            }
            d={getSvgPath({
              width: width,
              height: height,
              cornerRadius: radius + border.width, // not sure if this is correct
              cornerSmoothing: smoothing,
            }).replace(/--/g, '-')} // TODO: WHAT?
            // shapeRendering="geometricPrecision"
          ></path>
        )}

        {/* main bg */}
        <path
          fill={color}
          d={getSvgPath({
            width: width - border.width * 2,
            height: height - border.width * 2,
            cornerRadius: radius,
            cornerSmoothing: smoothing,
          }).replace(/--/g, '-')}
          transform={`translate(${border.width}, ${border.width})`}
        ></path>

        {/* bg overlay */}
        <path
          fill={'url(#defaultBgGradient)'}
          d={getSvgPath({
            width: width - border.width * 2,
            height: height - border.width * 2,
            cornerRadius: radius,
            cornerSmoothing: smoothing,
          }).replace(/--/g, '-')}
          transform={` translate(${border.width}, ${border.width})`}
        ></path>
      </g>
    </svg>
  );
};

export default Superellipse;
