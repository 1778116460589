import React, { FC, useEffect, useState } from 'react';
import './normalize.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import './shared/styles.scss';
import './app.scss';
import DashboardHeader from './components/dashboard-header/dashboard-header';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { RouteNames } from './router/router';
import { ConfigProvider } from 'antd';
import {
  LS_CURRENT_ORGANIZATION_ID,
  LS_TOKEN_KEY,
  UserRoles,
} from './shared/constants';
import { useAppDispatch, useAppSelector } from './hooks/redux-hooks';
import { userSlice } from './store/reducers/user-slice';
import { usersAPI } from './services/users-service';
import { plansAPI } from './services/plans-service';
import { subscriptionAPI } from './services/subscription-service';
import { organizationsAPI } from './services/organizations-service';
import { organizationsSlice } from './store/reducers/organizations-slice';
import { projectsAPI } from './services/projects-service';
import { initFacebookSdk } from './components/project-settings/plugins/fb-plugin/init-facebook';
import { subscriptionSlice } from './store/reducers/subscription-slice';
import { jwtDecode } from 'jwt-decode';
import IOrganization from './types/IOrganization';
import './shared/client-styles.scss';
import './shared/partner-styles.scss';
import Loading from './components/loading/loading';
import { useTranslation } from 'react-i18next';
import { domainsAPI } from './services/domains-service';
import { getDomainForImage } from './shared/helpers';
import { domainsSlice } from './store/reducers/domains-slice';
import 'react-data-grid/lib/styles.css';

const DISPLAY_NAME = process.env.REACT_APP_DISPLAY_NAME;
console.log('<<<<<<<<<< ', DISPLAY_NAME, '>>>>>>>>>>');

const noDashboardHeaderRoutes = [
  RouteNames.LANDING,
  RouteNames.LOGIN,
  RouteNames.REGISTER,
  RouteNames.CONFIRM_EMAIL,
  RouteNames.ACCEPT_INVITATION,
  RouteNames.NEW_USER_INVITATION,
  RouteNames.VERIFY_EMAIL,
  RouteNames.FORGOT_PASSWORD,
  RouteNames.RESET_PASSWORD,
  RouteNames.PAGE_NOT_EXIST,
  RouteNames.UNSUBSCRIBE_EMAILS,
];

const noDashboardHeaderDynamicRoutes = [
  RouteNames.WIDGET_PRO_DEMO_NO_PARAMS,
  RouteNames.WIDGET_PRO_SHARE_NO_PARAMS,
  RouteNames.WIDGET_PRO_SHOWCASE_NO_PARAMS,
];

const App: FC = () => {
  const { i18n } = useTranslation();
  const { orgId } = useParams();
  const [skip, setSkip] = useState<boolean>(true);
  const { data: domainInfo, isLoading: domainLoading } =
    domainsAPI.useGetCurrentDomainInfoQuery();
  const {
    data: userData,
    isLoading,
    error,
  } = usersAPI.useGetUserInfoQuery(undefined, { skip });
  const {} = projectsAPI.useGetProjectOptionsQuery(undefined, { skip });
  const {} = projectsAPI.useGetEngineOptionsQuery();
  const {} = projectsAPI.useGetVectorStoresOptionsQuery();
  const {} = plansAPI.useGetPlansQuery();
  const [lazyGetUserInfo] = usersAPI.useLazyGetUserInfoQuery();
  const [lazyGetUserSubscription] =
    subscriptionAPI.useLazyGetUserSubscriptionQuery();
  const [lazyGetSubscriptionIsActive] =
    subscriptionAPI.useLazyGetSubscriptionIsActiveQuery();
  const [lazyGetAllOrganizations] =
    organizationsAPI.useLazyGetAllOrganizationsQuery();
  const [lazyGetUserProjects] = projectsAPI.useLazyGetUserProjectsQuery();
  const { name: domainName, filePath } = useAppSelector(
    (state) => state.domainsReducer
  );
  const {
    currentOrganization,
    currentOrganizationLoading,
    allOrganizationsInfo,
  } = useAppSelector((state) => state.organizationsReducer);
  const { isAuth, user, isDomainTenantMember, userLoading } = useAppSelector(
    (state) => state.userReducer
  );
  const { setGlobalTimestamp } = domainsSlice.actions;
  const { setIsAuth, setUserRole, setIsDomainTenantMember, setUserLoading } =
    userSlice.actions;
  const { setCurrentOrganizationLoading, setCurrentOrganization } =
    organizationsSlice.actions;
  const { setUserPersonalSubscription } = subscriptionSlice.actions;
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [appAdditionalClassName, setAppAdditionalClassName] =
    useState<string>('');
  const [timestamp, setTimestamp] = useState(Date.now());

  //ignore ResizeObserver loop completed with undelivered notifications error
  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (
        e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        if (process.env.NODE_ENV === 'development') {
          console.log(
            'Ignore ResizeObserver loop completed with undelivered notifications.'
          );
        }
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  useEffect(() => {
    if (
      location.pathname.includes(RouteNames.ACCEPT_INVITATION) ||
      location.pathname.includes(RouteNames.NEW_USER_INVITATION)
    ) {
      localStorage.removeItem(LS_TOKEN_KEY);
      localStorage.removeItem(LS_CURRENT_ORGANIZATION_ID);
    }
  }, [location]);

  useEffect(() => {
    const body = document.querySelector('body');
    if (body && process.env.REACT_APP_PARTNER_MODE === 'true') {
      body.classList.add('partner-styles');
    }

    dispatch(setGlobalTimestamp(Date.now()));
  }, []);

  // TODO: to separate file
  const connectFavicon = (href: string) => {
    const link = document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/x-icon';
    link.href = href;
    link.id = 'favicon';
    document.head.appendChild(link);
  };

  const connectCustomScripts = (href: string) => {
    const script = document.createElement('script');
    script.async = true;
    script.id = 'custom-script';
    script.src = href;
    document.head.appendChild(script);
  };

  const connectFontAwesome = (href: string) => {
    const script = document.createElement('script');
    script.async = true;
    script.id = 'font-awesome';
    script.src = href;
    document.head.appendChild(script);
  };

  useEffect(() => {
    if (
      !filePath &&
      (window.location.hostname === 'localhost' ||
        window.location.hostname === 'dashboard.localhost')
    ) {
      const faviconHref = `https://static.${getDomainForImage()}/static/favicon.ico?timestamp=${timestamp}`;
      connectFavicon(faviconHref);
      document.title = 'Zappr.AI';
    }

    if (filePath) {
      const favicon = document.getElementById('favicon');
      if (!favicon) {
        const faviconHref = `https://static.${getDomainForImage()}/static/${filePath}favicon.ico?timestamp=${timestamp}`;
        connectFavicon(faviconHref);
      }
    }

    if (domainName) {
      document.title = domainName;
    }

    const customScript = document.getElementById('custom-script');
    if (!customScript) {
      const customScriptHref = `https://static.${getDomainForImage()}/static/scripts/custom-scripts.js`;
      connectCustomScripts(customScriptHref);
    }

    const fontAwesome = document.getElementById('font-awesome');
    if (!fontAwesome) {
      const fontAwesomeHref = `https://static.${getDomainForImage()}/static/font-awesome/fontawesome-pro-5.11.2-web/js/all.min.js`;
      connectFontAwesome(fontAwesomeHref);
    }
  }, [filePath, domainName]);

  useEffect(() => {
    i18n.changeLanguage(process.env.REACT_APP_SDK_NAME);
  }, []);

  useEffect(() => {
    setLSOrg();
  }, [orgId, user, location, allOrganizationsInfo]);

  useEffect(() => {
    initFacebookSdk();
  }, []);

  useEffect(() => {
    if (user) {
      lazyGetAllOrganizations();
    }
  }, [user]);

  const setLSOrg = () => {
    const profileSettingsPage = location.pathname.includes(
      RouteNames.PROFILE_SETTINGS_NO_PARAMS
    );
    if (profileSettingsPage) return;

    if (orgId && user && allOrganizationsInfo) {
      const orgIds = allOrganizationsInfo.map((org) => org.id);

      if (orgIds.includes(orgId)) {
        // lazyGetUserProjects({ orgId });
        const currentOrgInfo = allOrganizationsInfo.find(
          (org) => org.id === orgId
        );
        if (currentOrgInfo) {
          dispatch(setCurrentOrganization(currentOrgInfo));
        }
        dispatch(setCurrentOrganizationLoading(false));
      }
    }
  };

  useEffect(() => {
    const f = () => {
      if (document.visibilityState === 'visible') {
        setLSOrg();
        getCurrentOrganization();
      }
    };

    window.addEventListener('visibilitychange', f);
    window.addEventListener('focus', setLSOrg);
    setLSOrg();
    return () => {
      window.removeEventListener('visibilitychange', f);
      window.removeEventListener('focus', setLSOrg);
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem(LS_TOKEN_KEY)) {
      setSkip(false);
    } else {
      dispatch(setUserLoading(false));
    }
  }, []);

  const getCurrentOrganization = () => {
    const personalOrg = allOrganizationsInfo.find((org) => org.isPersonal);
    const currentOrgInfo = allOrganizationsInfo.find((org) => org.id === orgId);

    const setOrg = (organization: IOrganization) => {
      dispatch(setCurrentOrganization(organization));
      localStorage.setItem(LS_CURRENT_ORGANIZATION_ID, organization.id);
      navigate(`${RouteNames.USER_PROJECTS}/${organization.id}`);
    };

    const lsCurrentOrganizationId = localStorage.getItem(
      LS_CURRENT_ORGANIZATION_ID
    );

    if (currentOrgInfo) {
      dispatch(setCurrentOrganization(currentOrgInfo));
      dispatch(setCurrentOrganizationLoading(false));
      localStorage.setItem(LS_CURRENT_ORGANIZATION_ID, currentOrgInfo.id);
      return;
    }

    if (lsCurrentOrganizationId) {
      const savedOrgInfo = allOrganizationsInfo.find(
        (org) => org.id === lsCurrentOrganizationId
      );

      if (savedOrgInfo) {
        dispatch(setCurrentOrganization(savedOrgInfo));
        localStorage.setItem(LS_CURRENT_ORGANIZATION_ID, savedOrgInfo.id);
      } else if (personalOrg) {
        setOrg(personalOrg);
      } else if (allOrganizationsInfo.length) {
        setOrg(allOrganizationsInfo[0]);
      }
    } else if (personalOrg) {
      setOrg(personalOrg);
    } else if (allOrganizationsInfo.length) {
      setOrg(allOrganizationsInfo[0]);
    }

    dispatch(setCurrentOrganizationLoading(false));
  };

  useEffect(() => {
    if (user && allOrganizationsInfo) {
      getCurrentOrganization();
    }
  }, [user, allOrganizationsInfo]);

  useEffect(() => {
    if (user) {
      const getUserSubscription = async () => {
        const res = await lazyGetUserSubscription(false);
        if (res && res.data) {
          dispatch(setUserPersonalSubscription(res.data));
        }
        lazyGetUserSubscription();
        lazyGetSubscriptionIsActive();
      };

      const getUserRole = () => {
        const token = localStorage.getItem(LS_TOKEN_KEY);
        if (token) {
          try {
            const decoded: { role: string | undefined } = jwtDecode(token);
            if (decoded.role) {
              dispatch(setUserRole(decoded.role as UserRoles));
              if (decoded.role === UserRoles.CLIENT) {
                dispatch(setIsDomainTenantMember(true));
              }
            } else {
              dispatch(setUserRole(null));
            }
          } catch (e) {
            dispatch(setUserRole(null));
          }
        }
      };

      getUserSubscription();
      getUserRole();
      dispatch(setUserLoading(false));
    }
  }, [user]);

  useEffect(() => {
    if (isDomainTenantMember) {
      setAppAdditionalClassName('client-styles');
    } else {
      setAppAdditionalClassName('');
    }
  }, [isDomainTenantMember]);

  useEffect(() => {
    if (!isAuth || !orgId) return;
    const lsCurrentOrgValue = orgId;
    const userHasOrg = allOrganizationsInfo.some((org) => org.id === orgId);
    if (
      ((currentOrganization && !currentOrganizationLoading) ||
        !lsCurrentOrgValue) &&
      userHasOrg
    ) {
      lazyGetUserProjects({ orgId });
    }
  }, [
    isAuth,
    currentOrganization,
    currentOrganizationLoading,
    orgId,
    allOrganizationsInfo,
    user,
  ]);

  useEffect(() => {
    if (isAuth) {
      lazyGetUserInfo();
    }
  }, [isAuth]);

  useEffect(() => {
    if (userData) {
      dispatch(setIsAuth(true));
    }
    if (error) {
      //TODO: delete
      // console.log(error)
      dispatch(setIsAuth(false));
    }
  }, [userData, error]);

  const shouldHideHeaderForDynamicRoutes = (pathname: string) => {
    return noDashboardHeaderDynamicRoutes.some((route) =>
      pathname.includes(route)
    );
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary:
            process.env.REACT_APP_PARTNER_MODE === 'true'
              ? '#49545c'
              : '#00B2FF',
          fontFamily: 'Rubik',
          colorTextBase: '#3c4257',
        },
      }}
    >
      <div className={`app ${appAdditionalClassName}`}>
        {noDashboardHeaderRoutes.includes(location.pathname as RouteNames) ||
        shouldHideHeaderForDynamicRoutes(location.pathname) ? null : (
          <DashboardHeader />
        )}
        {userLoading || domainLoading ? <Loading /> : <Outlet />}
      </div>
    </ConfigProvider>
  );
};

export default App;
