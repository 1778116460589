import { apiService, APITagTypes } from './api-service';
import { X_ORG_ID_HEADER } from '../shared/constants';

export interface IInsightsQueryResult {
  queryResponse: string;
  from: string;
  to: string;
  granularity: string;
}

export interface IInsightsQueryResponseRaw {
  tables: {
    columns: {
      name: string;
      type: string;
    }[];
    name: string;
    rows: any[][];
  }[];
}

export interface InsightsQueryData {
  tables: { [name: string]: any[] };
}

// TODO: how to convert date type from string?

export const usageAPI = apiService.injectEndpoints({
  endpoints: (build) => ({
    getProjectMessagesPerDay: build.query<
      IInsightsQueryResult,
      { date: string; orgId: string | undefined; projectId: string }
    >({
      query: ({ date, orgId, projectId }) => ({
        url: `/usage/project/${projectId}/messages-per-day/?selectedMonth=${date}`,
        method: 'GET',
        headers: orgId ? { [X_ORG_ID_HEADER]: orgId } : {},
        providesTags: () => [APITagTypes.INSIGHTS],
      }),
    }),
    getProjectUsagePerDay: build.query<
      IInsightsQueryResult,
      { date: string; orgId: string | undefined; projectId: string }
    >({
      query: ({ date, orgId, projectId }) => ({
        url: `/usage/project/${projectId}/usage-per-day/?selectedMonth=${date}`,
        method: 'GET',
        headers: orgId ? { [X_ORG_ID_HEADER]: orgId } : {},
        providesTags: () => [APITagTypes.INSIGHTS],
      }),
    }),

    getUsagePerDay: build.query<
      IInsightsQueryResult,
      { date: string; organizationId?: string }
    >({
      query: ({ date, organizationId }) => ({
        url: `/usage/usage-per-day?selectedMonth=${date}`,
        method: 'GET',
        headers: organizationId ? { [X_ORG_ID_HEADER]: organizationId } : {},
        providesTags: () => [APITagTypes.INSIGHTS],
      }),
    }),

    getUsagePerProject: build.query<
      IInsightsQueryResult,
      { date: string; organizationId?: string }
    >({
      query: ({ date, organizationId }) => ({
        url: `/usage/usage-per-day?selectedMonth=${date}`,
        method: 'GET',
        headers: organizationId ? { [X_ORG_ID_HEADER]: organizationId } : {},
        providesTags: () => [APITagTypes.INSIGHTS],
      }),
    }),
  }),
});
