import { apiService, APITagTypes } from './api-service';
import IOrganization from '../types/IOrganization';
import IUser from '../types/IUser';
import { OrganizationMemberRole } from '../shared/constants';
import IUpdateBranding from '../types/IUpdateBranding';
import IAcceptInvitation from '../types/IAcceptInvitation';
import IOrganizationMember from '../types/IOrganizationMember';

export const organizationsAPI = apiService.injectEndpoints({
  endpoints: (build) => ({
    getAllOrganizations: build.query<IOrganization[], void>({
      query: () => ({
        url: `/tenants?sideLoadOptions=owner`,
        method: 'GET',
      }),
      providesTags: () => [APITagTypes.ORGANIZATIONS],
    }),
    getOrganizations: build.query<IOrganization, string>({
      query: (id) => ({
        url: `/tenants?id=${id}`,
        method: 'GET',
      }),
    }),
    createOrganization: build.mutation<
      IOrganization,
      { name: string; displayName: string }
    >({
      query: (body) => ({
        url: `/tenants`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(body),
      }),
      invalidatesTags: () => [APITagTypes.ORGANIZATIONS],
    }),
    updateOrganization: build.mutation<
      IOrganization,
      { orgId: string; body: { domain: string } }
    >({
      query: ({ orgId, body }) => ({
        url: `/tenants/${orgId}`,
        method: 'PUT',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(body),
      }),
      invalidatesTags: () => [APITagTypes.ORGANIZATIONS],
    }),
    getAllOrganizationMembers: build.query<
      IOrganizationMember[],
      { orgId: string }
    >({
      query: ({ orgId }) => ({
        url: `/tenants/${orgId}/get-members?sideLoadOptions=user`,
        method: 'GET',
      }),
    }),
    getOrganizationMember: build.query<
      IUser,
      { orgId: string; memberId: string }
    >({
      query: ({ orgId, memberId }) => ({
        url: `/tenants/${orgId}/get-member/${memberId}`,
        method: 'GET',
      }),
    }),
    addOrganizationMember: build.mutation<
      IOrganization,
      { orgId: string; body: { email: string; role: OrganizationMemberRole } }
    >({
      query: ({ orgId, body }) => ({
        url: `/tenants/${orgId}/add-member`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(body),
      }),
    }),
    updateOrganizationMemberRole: build.mutation<
      IOrganization,
      { orgId: string; body: { email: string; role: OrganizationMemberRole } }
    >({
      query: ({ orgId, body }) => ({
        url: `/tenants/${orgId}/update-member-role`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(body),
      }),
    }),
    // TODO: mutation get =/
    deleteOrganizationMember: build.mutation<
      IOrganization,
      { orgId: string; memberId: string }
    >({
      query: ({ orgId, memberId }) => ({
        url: `/tenants/${orgId}/remove-member/${memberId}`,
        method: 'GET',
      }),
    }),
    setRemovingBranding: build.mutation<
      void,
      { data: IUpdateBranding; orgId: string }
    >({
      query: ({ data, orgId }) => ({
        url: `/tenants/${orgId}/update-branding`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(data),
      }),
    }),
    deleteOrganization: build.mutation<void, string>({
      query: (id) => ({
        url: `/tenants/${id}`,
        method: 'DELETE',
      }),
    }),
    acceptInvitation: build.mutation<undefined, IAcceptInvitation>({
      query: (data) => ({
        url: `/tenants/accept-invitation`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
          authorization: 'Bearer ' + data.token,
        },
        body: JSON.stringify(data),
      }),
      invalidatesTags: () => [APITagTypes.USER],
    }),
    resendInvitation: build.mutation<
      IOrganization,
      { orgId: string; memberId: string }
    >({
      query: ({ orgId, memberId }) => ({
        url: `/tenants/resend-invitation`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify({ tenantId: orgId, memberId }),
      }),
    }),
    uploadBrandLogo: build.mutation<void, { orgId: string; body: FormData }>({
      query: ({ orgId, body }) => ({
        url: `/tenants/${orgId}/branding-image`,
        method: 'POST',
        headers: {
          'content-type': undefined,
        },
        body: body,
      }),
    }),
    deleteBrandLogo: build.mutation<void, string>({
      query: (id) => ({
        url: `/tenants/${id}/branding-image`,
        method: 'DELETE',
      }),
    }),
    transferOrganization: build.mutation<
      void,
      { orgId: string; domainId: string; email: string }
    >({
      query: ({ orgId, domainId, email }) => ({
        url: `/tenants/${orgId}/transfer`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify({ domainId, email }),
      }),
    }),
  }),
});
