import { apiService, APITagTypes } from './api-service';
import IAuthForm from '../types/IAuthForm';
import IUser from '../types/IUser';
import IDefaultSuccessResponse from '../types/IDefaultSuccessResponse';
import IAuthResponse from '../types/IAuthResponse';
import IUpdateUserInfo from '../types/IUpdateUserInfo';
import IUpdatePassword from '../types/IUpdatePassword';
import IConfirmEmail from '../types/IConfirmEmail';
import IResetPassword from '../types/IResetPassword';
import IAcceptRegistration from '../types/IAcceptRegistration';
import { X_ORG_ID_HEADER } from '../shared/constants';

export const usersAPI = apiService.injectEndpoints({
  endpoints: (build) => ({
    register: build.mutation<
      {
        message: string;
        email: string;
        userName: string;
        firstName: string;
        lastName: string;
      },
      IAuthForm
    >({
      query: (formData) => ({
        url: `/users/register`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(formData),
      }),
    }),
    acceptRegistration: build.mutation<
      {
        message: string;
        email: string;
        userName: string;
        firstName: string;
        lastName: string;
      },
      IAcceptRegistration
    >({
      query: (formData) => ({
        url: `/users/accept-registration`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(formData),
      }),
    }),
    googleAuth: build.mutation<IAuthResponse, { token: string }>({
      query: (data) => ({
        url: `/users/login/google`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(data),
      }),
      invalidatesTags: () => [
        // APITagTypes.USER,
        // APITagTypes.USER_PROJECTS,
        // APITagTypes.CURRENT_USER_SUBSCRIPTION,
        // APITagTypes.USER_USAGE_INFO,
        // APITagTypes.USER_USAGE,
      ],
    }),
    auth: build.mutation<IAuthResponse, IAuthForm>({
      query: (formData) => ({
        url: `/users/login`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(formData),
      }),
      invalidatesTags: () => [
        // APITagTypes.USER,
        // APITagTypes.USER_PROJECTS,
        // APITagTypes.CURRENT_USER_SUBSCRIPTION,
        // APITagTypes.USER_USAGE_INFO,
        // APITagTypes.USER_USAGE,
      ],
    }),
    confirmEmail: build.mutation<undefined, IConfirmEmail>({
      query: (data) => ({
        url: `/users/confirm-email`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(data),
      }),
      invalidatesTags: () => [APITagTypes.USER],
    }),
    resendVerificationEmail: build.mutation<undefined, string>({
      query: (data) => ({
        url: `/users/resend-confirmation-email`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(data),
      }),
    }),
    unsubscribeEmails: build.mutation<undefined, { email: string }>({
      query: (data) => ({
        url: `/users/unsubscribe`,
        method: 'PUT',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(data),
      }),
    }),
    forgotPassword: build.mutation<undefined, string>({
      query: (data) => ({
        url: `/users/forgot-password`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(data),
      }),
    }),
    resetPassword: build.mutation<undefined, IResetPassword>({
      query: (data) => ({
        url: `/users/reset-password`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(data),
      }),
    }),
    getUserInfo: build.query<IUser, string | void>({
      query: (token) => ({
        url: `/users/me`,
        method: 'GET',
        headers: token ? { authorization: `Bearer ${token}` } : {},
      }),
      providesTags: () => [APITagTypes.USER],
    }),
    getUserAPIKey: build.query<string, boolean | void>({
      query: (needOrgHeaders = true) => ({
        url: `/users/me/api-key`,
        method: 'GET',
        headers: needOrgHeaders ? {} : { [X_ORG_ID_HEADER]: '' },
      }),
    }),
    getOrganizationAPIKey: build.query<string, string>({
      query: (orgId) => ({
        url: `/users/me/api-key`,
        method: 'GET',
        headers: { [X_ORG_ID_HEADER]: orgId },
      }),
    }),
    updateUserInfo: build.mutation<IUser, IUpdateUserInfo>({
      query: (data) => ({
        url: `/users/me/profile`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: () => [APITagTypes.USER],
    }),
    updateUserPassword: build.mutation<void, IUpdatePassword>({
      query: (data) => ({
        url: `/users/me/change-password`,
        method: 'PUT',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(data),
      }),
    }),
    updateUserAvatar: build.mutation<void, FormData>({
      query: (formData) => ({
        url: `/users/me/avatar`,
        method: 'PUT',
        headers: {
          'content-type': undefined,
        },
        body: formData,
      }),
    }),
    getStripeDashboardURL: build.query<string, void>({
      query: () => ({
        url: `/users/stripe-dashboard`,
        method: 'GET',
      }),
    }),
  }),
});
