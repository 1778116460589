import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { message, Modal } from 'antd';
import { showErrorMessage } from '../../../shared/helpers';
import { RouteNames } from '../../../router/router';
import { organizationsAPI } from '../../../services/organizations-service';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { organizationsSlice } from '../../../store/reducers/organizations-slice';

interface IDeleteOrganizationModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  organizationId: string;
}

const DeleteOrganizationModal: FC<IDeleteOrganizationModal> = ({
  isOpen,
  setIsOpen,
  organizationId,
}) => {
  const { allOrganizationsInfo } = useAppSelector(
    (state) => state.organizationsReducer
  );
  const { user } = useAppSelector((state) => state.userReducer);
  const [deleteOrganization, { isLoading }] =
    organizationsAPI.useDeleteOrganizationMutation();
  const [messageApi, contextHolder] = message.useMessage();
  const [deleteDisabled, setDeleteDisabled] = useState(false);
  const dispatch = useAppDispatch();

  const { setCurrentOrganization } = organizationsSlice.actions;

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleOk = async () => {
    setDeleteDisabled(true);
    const result = await deleteOrganization(organizationId);

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
      setIsOpen(false);
    } else {
      const personalOrgId =
        allOrganizationsInfo.find(
          (org) => org.isPersonal && org.owner?.id === user?.id
        )?.id || '';

      dispatch(setCurrentOrganization(null));
      const navigateTo = RouteNames.USER_PROJECTS + '/' + personalOrgId;
      await messageApi.success('The organization has been deleted.', 1);
      setIsOpen(false);
      window.location.href = navigateTo;
    }

    setDeleteDisabled(false);
  };

  return (
    <Modal
      wrapClassName={'modal'}
      title="Delete organization"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={isLoading}
      okButtonProps={{ disabled: deleteDisabled, danger: true }}
    >
      {contextHolder}
      <p>
        Please confirm the deletion of this organization. Note that this will
        permanently erase all associated information, knowledge, and message
        history. This action cannot be undone
      </p>
    </Modal>
  );
};

export default DeleteOrganizationModal;
