import { FC, useEffect, useState } from 'react';
import { Button, Result } from 'antd';
import { subscriptionAPI } from '../../services/subscription-service';
import { useAppSelector } from '../../hooks/redux-hooks';
import { useNavigate } from 'react-router-dom';
import { RouteNames } from '../../router/router';

const Purchase: FC = () => {
  const { isDomainTenantMember } = useAppSelector((state) => state.userReducer);
  const { data: enterprisePaymentURL } =
    subscriptionAPI.useGetEnterprisePaymentURLQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (isDomainTenantMember) {
      navigate(RouteNames.PAGE_NOT_EXIST);
    }
  }, [isDomainTenantMember]);

  useEffect(() => {
    if (enterprisePaymentURL) {
      window.location.href = enterprisePaymentURL;
    }
  });

  return <div></div>;
};

export default Purchase;
