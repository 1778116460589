import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Button, Modal, Result, Skeleton } from 'antd';
import { projectsAPI } from '../../../services/projects-service';
import TableById from '../../project-settings/tables/table-by-id';

interface TableUpdateModalProps {
  isOpen: boolean;
  closeModal: () => void;
  projectId: string;
  knowledgeId: string;
  setTableUpdateKnowledgeId: Dispatch<SetStateAction<string>>;
  cb: () => void;
}

const TableUpdateModal: FC<TableUpdateModalProps> = ({
  isOpen,
  closeModal,
  projectId,
  knowledgeId,
  setTableUpdateKnowledgeId,
  cb,
}) => {
  const [
    lazyGetTableByKnowledgeId,
    { data: tableData, isLoading: tableLoading, error: tableError },
  ] = projectsAPI.useLazyGetTableByKnowledgeIdQuery();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
    if (projectId && knowledgeId) {
      const f = async () => {
        const res = await lazyGetTableByKnowledgeId({ projectId, knowledgeId });

        // if (res.data) {
        //   getRecords(0, itemsPerPage, res.data.id);
        // } else {
        //   setIsLoading(false);
        // }
        setIsLoading(false);
      };

      f();
    }
  }, [projectId, knowledgeId, isOpen]);

  const handleCancel = () => {
    closeModal();
    // setCurrentTableRecords(null);
    setTableUpdateKnowledgeId('');
  };

  const getContent = () => {
    if (tableLoading || isLoading) {
      return <Skeleton active />;
    }

    if (tableError) {
      return (
        <Result
          status="error"
          title="Ooops, something went wrong."
          subTitle={"Can't load table data."}
        />
      );
    }

    if (tableData) {
      return (
        <>
          <TableById projectId={projectId} tableId={tableData.id} />
        </>
      );
    }

    return null;
  };

  return (
    <Modal
      title="Update Table"
      open={isOpen}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
      ]}
      className={'text-source-modal'}
      wrapClassName={'modal'}
      maskStyle={{ zIndex: 1100 }}
      forceRender={true}
    >
      {getContent()}
    </Modal>
  );
};

export default TableUpdateModal;
