import React, { FC } from 'react';
import { Form } from 'antd';
import { Rule } from 'antd/es/form';
import TextArea from 'antd/es/input/TextArea';

interface ITextAreaFormItemProps {
  label: string;
  name: string;
  placeholder: string;
  rules?: Rule[] | undefined;
}

const TextAreaFormItem: FC<ITextAreaFormItemProps> = ({
  label,
  name,
  placeholder,
  rules,
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={rules}
      style={{ width: '100%' }}
    >
      <TextArea rows={4} placeholder={placeholder} />
    </Form.Item>
  );
};

export default TextAreaFormItem;
