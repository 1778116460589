import { MessageInstance } from 'antd/es/message/interface';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import Resizer from 'react-image-file-resizer';
import IPlan from '../types/IPlan';
import {
  BillingPlansTypes,
  KnowledgeDataSourcesFileTypes,
  SubscriptionPlanTypes,
} from './constants';
import { suuid } from '../utils/suuid';
import IPluginItem from '../types/IPluginItem';
import IPluginProperties, { IPluginProperty } from '../types/IPluginProperties';
import React from 'react';

export const showErrorMessage = async (
  messageApi: MessageInstance,
  error: FetchBaseQueryError | SerializedError | { data: any },
  duration?: number
) => {
  try {
    if (error && 'data' in error && error.data && 'message' in error.data) {
      if ('errors' in error.data && error.data.errors) {
        let errors = '';
        for (const key in error.data.errors) {
          if (error.data.errors.hasOwnProperty(key)) {
            const element = error.data.errors[key];
            errors += `${key}: ${element}\n`;
          }
        }

        await messageApi.error(
          `Error: ${error.data.message}\n${errors}`,
          duration ? duration : 3
        );
      } else {
        await messageApi.error(
          `Error: ${error.data.message}`,
          duration ? duration : 3
        );
      }
    } else if (
      error &&
      'data' in error &&
      error.data &&
      'error' in error.data
    ) {
      await messageApi.error(
        `Error: ${error.data.error}`,
        duration ? duration : 3
      );
    } else if (
      error &&
      'data' in error &&
      error.data &&
      'title' in error.data
    ) {
      await messageApi.error(
        `Error: ${error.data.title}`,
        duration ? duration : 3
      );
    } else if (error && 'data' in error) {
      await messageApi.error(`Error: ${error.data}`, duration ? duration : 3);
    } else {
      await messageApi.error(`Error: ${error}`, duration ? duration : 3);
    }
  } catch (e) {
    await messageApi.error(
      `Error: Ooops, something went wrong.`,
      duration ? duration : 3
    );
  }
};

export const checkStandardMessageInErrorDataExists = (
  error: FetchBaseQueryError | SerializedError | undefined
) => {
  return (
    error &&
    'data' in error &&
    error.data &&
    'message' in (error.data as { message: string })
  );
};

export const getStandardMessageFromErrorData = (
  error: FetchBaseQueryError | SerializedError | undefined
) => {
  return checkStandardMessageInErrorDataExists(error)
    ? // @ts-ignore
      (error.data as { message: string }).message
    : '';
};

export const connectWidgetV2Script = (
  setWidgetV2ScriptLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const localTimestamp = Date.now();

  const widgetScriptV2 = document.createElement('script');
  widgetScriptV2.id = 'widgetScript';
  widgetScriptV2.defer = true;
  widgetScriptV2.src =
    process.env.NODE_ENV === 'development'
      ? `https://static.${process.env.REACT_APP_DOMAIN}/static/widgetV2/v0/dev/embed-v1.0.0.min.js?timestamp=${localTimestamp}`
      : `https://static.${getDomain()}/static/widget-pro/embed-v1.0.0.min.js`;

  setWidgetV2ScriptLoading(true);
  document.body.appendChild(widgetScriptV2);
  widgetScriptV2.onload = () => {
    setWidgetV2ScriptLoading(false);
  };

  return widgetScriptV2;
};

export const getRandomElements = (arr: unknown[], num: number) => {
  const shuffled = [...arr].sort(() => 0.5 - Math.random());

  return shuffled.slice(0, num);
};

export const sendHeightToParent = (type: string, dfrom: string) => {
  return;
  // const height = document.body.scrollHeight;
  // window.parent.postMessage({height: height, type: type, dfrom: dfrom}, "*");
};

export const formatNumber = (num: number) => {
  return Number(num).toLocaleString('en-US');
};

export const resizeFileTo256PNG = (file: Blob) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      256,
      256,
      'PNG',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'blob',
      256,
      256
    );
  });

const featuresText = {
  embed_widget: 'Embed Widget',
  email_support: 'Email Support',
  api_access: 'API Access',
  remove_branding: 'Remove Branding',
  priority_email_support: 'Priority Email Support',
  priority_video_support: 'Priority Video Support',
  custom_plugins_development: 'Custom Plugins Development',
  plugin_access: 'Plugin Access',
  gpt_4: 'GPT4',
  basic_prompt_editing: 'Basic Prompt Editing',
  advanced_prompt_editing: 'Advanced Prompt Editing',
};

const limitsText = {
  messages: 'GPT-3 messages',
  'messages-gpt-3': 'GPT-3 messages',
  'messages-gpt-4': 'GPT-4 messages',
  a4Pages: 'A4 pages',
  asks: 'questions',
};

export const planFeaturesToStringArray = (plan: IPlan, allPlans: IPlan[]) => {
  const limits = [];
  const features = [];

  if (plan.averageLimits) {
    for (const [key, value] of Object.entries(plan.averageLimits)) {
      if (value) {
        limits.push(
          `Up to ${formatNumber(value)} ${
            limitsText[key as keyof typeof limitsText]
          }`
        );
      }
    }
  } else if (plan.type === SubscriptionPlanTypes.ENTERPRISE) {
    const planIndex = allPlans.findIndex((p) => p.id === plan.id);
    let prevPlan = null;
    if (planIndex > 0) {
      prevPlan = allPlans[planIndex - 1];
    }
    if (prevPlan && prevPlan.averageLimits) {
      for (const [key, value] of Object.entries(prevPlan.averageLimits)) {
        if (value) {
          limits.push(`More ${limitsText[key as keyof typeof limitsText]}`);
        }
      }
    }
  }

  for (const [key, value] of Object.entries(plan.features)) {
    if (value) {
      features.push(featuresText[key as keyof typeof featuresText]);
    }
  }

  return [...limits, ...features];
};

export const getGASubscriptionPurchaseDetails = (
  allPlans: IPlan[],
  type: string,
  currentBillingPlansType: BillingPlansTypes
) => {
  const subscriptionDetails = allPlans.find((plan) => plan.type === type);
  let value = 0;
  if (subscriptionDetails) {
    value =
      currentBillingPlansType === BillingPlansTypes.YEARLY
        ? subscriptionDetails.monthlyPriceAnualBilling * 12
        : subscriptionDetails.monthlyPrice;
    if (value < 0) value = 0;
  }

  const transactionId = suuid();
  const subscription = subscriptionDetails || 'N/A';

  return {
    transactionId,
    value: +value.toFixed(2),
    type,
    billingPlanType: currentBillingPlansType,
    subscription,
  };
};

export const formatDateFromISO8601 = (isoDate: string): string => {
  const date = new Date(isoDate);

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  };

  return date.toLocaleString('en-US', options);
};

export const formatDateFromISO8601ToShortDate = (isoDate: string): string => {
  const date = new Date(isoDate);

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC',
  };

  return date.toLocaleString('en-US', options);
};

// export const getStaticDomain = (): string => {
//   if (process.env.NODE_ENV === 'development') {
//     return 'zappr.ai';
//   }
//
//   const origin = window.location.hostname;
//   const staticDomain = origin.replace(`${Subdomains.DASHBOARD}.`, '');
//   return staticDomain;
// };

// export const isDefaultDomain = () => {
//   return (
//     window.location.hostname === 'localhost' ||
//     window.location.hostname === '127.0.0.1' ||
//     window.location.hostname === 'zappr.ai'
//   );
// };

export const getAllowedKnowledgeFileTypesString = (): string => {
  let result = '';

  for (const value in KnowledgeDataSourcesFileTypes) {
    result += `,.${
      KnowledgeDataSourcesFileTypes[
        value as keyof typeof KnowledgeDataSourcesFileTypes
      ]
    }`;
  }

  result = result.slice(1);

  return result;
};

export const getErrorMessage = (
  error: FetchBaseQueryError | SerializedError | undefined
) => {
  try {
    if (
      error &&
      'data' in error &&
      error.data &&
      'message' in (error.data as { message: string }) &&
      (error.data as { message: string }).message
    ) {
      return (error.data as { message: string }).message;
    }
  } catch (e) {
    return 'Something went wrong';
  }
  return 'Something went wrong';
};

export const getAllPluginProps = (plugin: IPluginItem): IPluginProperties[] => {
  const allProps: IPluginProperties[] = [];

  for (const prop in plugin?.allProperties) {
    allProps.push({
      [prop]: plugin.allProperties[
        prop as keyof IPluginItem['allProperties']
      ] as IPluginProperty,
    });
  }

  return allProps;
};

export const isValidEmail = (value: string) => {
  const pattern = new RegExp(
    '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'
  );
  return pattern.test(value);
};

export function abbreviateNumber(num: number): string {
  const abbreviations = ['', 'k', 'm', 'b', 't'];
  const sign = Math.sign(num);
  const absNum = Math.abs(num);
  const numLength = absNum.toString().length;

  if (numLength <= 3) {
    return num.toString();
  }

  const index = Math.floor((numLength - 1) / 3);
  const divisor = Math.pow(10, index * 3);
  let abbreviatedNum = absNum / divisor;

  if (abbreviatedNum >= 1000) {
    abbreviatedNum = Math.floor(abbreviatedNum);
  } else {
    abbreviatedNum = Math.floor(abbreviatedNum * 10) / 10;
  }

  let formattedNum = abbreviatedNum.toString();
  if (formattedNum.endsWith('.0')) {
    formattedNum = formattedNum.slice(0, -2);
  }

  if (formattedNum.includes('.')) {
    formattedNum = formattedNum.replace('.', ',');
  }

  return (sign === -1 ? '-' : '') + formattedNum + abbreviations[index];
}

export function toFixedTrimTrailingZeroes(
  number: number,
  precision: number = 1
) {
  const fixed = number.toFixed(precision);
  return fixed.replace(/\.?0*$/, '');
}

export function getRandomInt(min: number, max: number) {
  const minCeil = Math.ceil(min);
  const maxFloor = Math.floor(max);
  return Math.floor(Math.random() * (maxFloor - minCeil + 1) + minCeil);
}

//TODO: move to utils/domainUtils
export const getDomain = () => {
  return window.location.hostname.replace(/^.*?dashboard\./, '');
};

//TODO: move to utils/domainUtils
export const isClientDomain = () => {
  const domain = getDomain();
  if (
    domain === 'localhost' ||
    domain === '127.0.0.1' ||
    domain === process.env.REACT_APP_DOMAIN
  ) {
    return false;
  }

  return true;
};

//TODO: move to utils/domainUtils
export const getDomainForImage = () => {
  return process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_DOMAIN
    : getDomain();
};
