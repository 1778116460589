import React, { FC, useEffect, useState } from 'react';
import { Descriptions, message, Modal, Result } from 'antd';
import { contactsAPI } from '../../../../services/contacts-service';
import Loading from '../../../loading/loading';
import IContact, {
  ContactRecordType,
  IContactRecord,
  IPostContactRecord,
  TContactRecord,
} from '../../../../types/contacts/IContact';
import BasicInfoView from '../basic-info/basic-info-view';
import EditBasicInfoModal from '../edit-basic-info-modal/edit-basic-info-modal';
import AddRecordTypeElement from '../forms/add-record-type-element';
import ContactsHelpers from '../contacts-helpers';
import AddRecordModal from '../add-record-modal/add-record-modal';
import DeleteContactRecordModal from '../delete-contact-record-modal/delete-contact-record-modal';
import EditContactRecordModal from '../edit-contact-record-modal/edit-contact-record-modal';
import { showErrorMessage } from '../../../../shared/helpers';

interface IEditContactModalProps {
  projectId: string;
  selectedContactId: string;
  setSelectedContactId: React.Dispatch<React.SetStateAction<string>>;
}

const ContactCardModal: FC<IEditContactModalProps> = ({
  projectId,
  selectedContactId,
  setSelectedContactId,
}) => {
  const [getContactById, { error: contactDataError }] =
    contactsAPI.useLazyGetContactByIdQuery();
  const [postContactRecords] = contactsAPI.usePostContactRecordsMutation();
  const [deleteContactRecordM] = contactsAPI.useDeleteContactRecordMutation();
  const [updateContactRecord] = contactsAPI.useUpdateContactRecordMutation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [contactDataLoading, setContactDataLoading] = useState<boolean>(false);
  const [contactData, setContactData] = useState<IContact | null>(null);
  const [deleteContactRecord, setDeleteContactRecord] =
    useState<IContactRecord | null>(null);
  const [editContactRecord, setEditContactRecord] =
    useState<IContactRecord | null>(null);
  const [editBasicInfoModalIsOpen, setEditBasicInfoModalIsOpen] =
    useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [addRecordType, setAddRecordType] = useState<ContactRecordType | null>(
    null
  );

  useEffect(() => {
    if (selectedContactId) {
      setIsOpen(true);
      getContactData();
    } else {
      setIsOpen(false);
      setContactData(null);
    }
  }, [selectedContactId]);

  const getContactData = async () => {
    setContactDataLoading(true);
    const res = await getContactById({
      projectId,
      contactId: selectedContactId,
    });
    if ('data' in res && res.data) {
      setContactData(res.data);
    }
    setContactDataLoading(false);
  };

  const handleEditBasicInfo = () => {
    setEditBasicInfoModalIsOpen(true);
  };

  const handleCancel = () => {
    setSelectedContactId('');
  };

  const updateCb = () => {
    getContactData();
  };

  const onAddContactRecord = async (record: IPostContactRecord) => {
    const res = await postContactRecords({
      projectId,
      contactId: selectedContactId,
      records: [record],
    });
    if ('error' in res && res.error) {
      showErrorMessage(messageApi, res.error);
    } else {
      messageApi.success('Record added successfully.');
      getContactData();
    }
  };

  const handleEditRecord = (record: IContactRecord) => {
    setEditContactRecord(record);
  };

  const handleDeleteRecord = (record: IContactRecord) => {
    setDeleteContactRecord(record);
  };

  const onDeleteConfirm = async () => {
    if (!deleteContactRecord) return;
    const res = await deleteContactRecordM({
      projectId,
      contactId: deleteContactRecord.contactId,
      recordId: deleteContactRecord.id,
    });
    if ('error' in res && res.error) {
      showErrorMessage(messageApi, res.error);
    } else {
      messageApi.success('Record deleted successfully.');
      setDeleteContactRecord(null);
      getContactData();
    }
  };

  const onEditConfirm = async (newValues: TContactRecord) => {
    if (!editContactRecord) return;
    const res = await updateContactRecord({
      projectId,
      contactId: editContactRecord.contactId,
      recordId: editContactRecord.id,
      record: newValues,
    });
    if ('error' in res && res.error) {
      showErrorMessage(messageApi, res.error);
    } else {
      messageApi.success('Record updated successfully.');
      setEditContactRecord(null);
      getContactData();
    }
  };

  const getComponent = () => {
    if (contactDataLoading) {
      return <Loading height={'300px'} />;
    }

    if (contactData) {
      return (
        <div style={{ wordBreak: 'break-word' }}>
          <BasicInfoView
            contactData={contactData}
            handleEditBasicInfo={handleEditBasicInfo}
          />
          {Object.values(ContactRecordType).map((type) =>
            ContactsHelpers.getRecordView(
              ContactsHelpers.transformTServerContactRecordsToIContactRecords(
                contactData.records
              ),
              type,
              handleEditRecord,
              handleDeleteRecord
            )
          )}
          <AddRecordTypeElement setAddRecordType={setAddRecordType} />
          {/*TODO: check! post*/}
          <AddRecordModal
            addRecordType={addRecordType}
            setAddRecordType={setAddRecordType}
            onAddContactRecord={onAddContactRecord}
          />
          <DeleteContactRecordModal
            deleteContactRecord={deleteContactRecord}
            setDeleteContactRecord={setDeleteContactRecord}
            onDeleteConfirm={onDeleteConfirm}
          />
          <EditContactRecordModal
            editRecord={editContactRecord}
            setEditRecord={setEditContactRecord}
            onEditConfirm={onEditConfirm}
          />
          <EditBasicInfoModal
            isOpen={editBasicInfoModalIsOpen}
            setIsOpen={setEditBasicInfoModalIsOpen}
            projectId={projectId}
            contactData={contactData}
            updateCb={updateCb}
            messageApi={messageApi}
          />
        </div>
      );
    }

    if (!contactData && !contactDataLoading && contactDataError) {
      return (
        <Result
          status="error"
          title="Ooops, something went wrong."
          subTitle={"Can't load contact data."}
        />
      );
    }
  };

  return (
    <Modal
      title="Contact"
      open={isOpen}
      onCancel={handleCancel}
      centered={true}
      wrapClassName={'modal'}
      maskStyle={{ zIndex: 1100 }}
      footer={null}
    >
      {contextHolder}
      {getComponent()}
    </Modal>
  );
};

export default ContactCardModal;
