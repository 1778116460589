import React, { FC, useEffect, useState } from 'react';
import { Button, Result, Table } from 'antd';
import {
  DeleteOutlined,
  MailOutlined,
  PhoneOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import './contacts.scss';
import { contactsAPI } from '../../../services/contacts-service';
import IContact, {
  ContactRecordType,
  IContactRecordDefaultFields,
  TServerContactRecord,
} from '../../../types/contacts/IContact';
import { useAppSelector } from '../../../hooks/redux-hooks';
import DeleteContactModal from './delete-contact-modal/delete-contact-modal';
import AddContactModal from './add-contact-modal/add-contact-modal';
import usePagination from '../../../hooks/usePagination';
import IEmailRecord from '../../../types/contacts/contact-records/IEmailRecord';
import { IPhoneRecord } from '../../../types/contacts/contact-records/IPhoneRecord';
import ContactCardModal from './contact-card-modal/contact-card-modal';
import Loading from '../../loading/loading';
import { ICompanyRecord } from '../../../types/contacts/contact-records/ICompanyRecord';

export interface IContactsProps {
  projectId: string;
}

const Contacts: FC<IContactsProps> = ({ projectId }) => {
  const { subscriptionIsActive } = useAppSelector(
    (state) => state.subscriptionReducer
  );
  const [
    getContacts,
    { isLoading: contactsLoading, data: contactsData, error: contactsError },
  ] = contactsAPI.useLazyGetContactsQuery();
  const [selectedContactId, setSelectedContactId] = useState<string>('');
  const [deleteContactId, setDeleteContactId] = useState<string>('');
  const [addContactModalIsOpen, setAddContactModalIsOpen] =
    useState<boolean>(false);

  const [
    paginationComponent,
    itemsPerPage,
    currentPage,
    setCurrentPage,
    itemsTotalCount,
    setItemsTotalCount,
  ] = usePagination();

  useEffect(() => {
    if (contactsData) {
      setItemsTotalCount(contactsData.totalCount);
    }

    if (contactsData && contactsData.data.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [contactsData]);

  useEffect(() => {
    const offset = (currentPage - 1) * itemsPerPage;
    const limit = itemsPerPage;
    getContacts({ projectId, params: { offset, limit } });
  }, [projectId, currentPage, itemsPerPage]);

  const columns = [
    {
      title: 'Contact Name',
      dataIndex: 'contactName',
      key: 'contactName',
      render: (_: unknown, record: IContact) => (
        <div>
          <div>
            {record.basicInfo.firstName} {record.basicInfo.lastName}
          </div>
          {record.basicInfo.nickname && (
            <div className={'contact__nickname'}>
              {/*aka <span className={'contact__nickname'}>{basicInfo.nickname}</span>*/}
              {record.basicInfo.nickname}
            </div>
          )}
        </div>
      ),
    },
    {
      title: 'Contact',
      dataIndex: 'contact',
      key: 'contact',
      render: (_: unknown, record: IContact) => {
        const isEmailRecord = (
          record: TServerContactRecord
        ): record is IContactRecordDefaultFields & IEmailRecord => {
          return record.type === ContactRecordType.EMAIL;
        };

        const isPhoneRecord = (
          record: TServerContactRecord
        ): record is IContactRecordDefaultFields & IPhoneRecord => {
          return record.type === ContactRecordType.PHONE;
        };

        const emailRecord = record.records.find(isEmailRecord);
        const phoneRecord = record.records.find(isPhoneRecord);

        const emailAddress = emailRecord?.emailAddress;
        const phoneNumber = phoneRecord?.phoneNumber;

        return (
          <div>
            {emailAddress && (
              <div className={'contact__table-item-element'}>
                <MailOutlined />{' '}
                <a
                  href={`mailto:${emailAddress}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  {emailAddress}
                </a>
              </div>
            )}
            {phoneNumber && (
              <div className={'contact__table-item-element'}>
                <PhoneOutlined />{' '}
                <a
                  href={`tel:${phoneNumber}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  {phoneNumber}
                </a>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      render: (_: unknown, record: IContact) => {
        const isCompanyRecord = (
          record: TServerContactRecord
        ): record is IContactRecordDefaultFields & ICompanyRecord => {
          return record.type === ContactRecordType.COMPANY;
        };

        const company = record.records.find(isCompanyRecord);

        const jobTitle = company?.jobTitle;
        const companyName = company?.companyName;

        return (
          <div>
            {jobTitle && <div className={'contact__nickname'}>{jobTitle}</div>}
            {companyName && <div>{companyName}</div>}
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'delete',
      key: 'delete',
      render: (_: unknown, record: IContact) => {
        return (
          <Button
            type="text"
            icon={<DeleteOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              setDeleteContactId(record.id);
            }}
            disabled={!subscriptionIsActive}
          ></Button>
        );
      },
      width: 48,
    },
  ];

  const transformContacts = (contacts: IContact[]) => {
    return contacts.map((contact) => {
      return {
        key: contact.id,
        ...contact,
      };
    });
  };

  const getComponent = () => {
    if (contactsLoading) {
      return <Loading height={'300px'} />;
    }

    if (contactsData) {
      return (
        <>
          <div className={'contacts__header'}>
            <Button
              onClick={() => setAddContactModalIsOpen(true)}
              icon={<PlusOutlined />}
              disabled={!subscriptionIsActive}
            >
              Add contact
            </Button>
          </div>
          <Table
            columns={columns}
            dataSource={transformContacts(contactsData?.data || [])}
            className={'contacts__table'}
            onRow={(record) => {
              return {
                onClick: () => {
                  setSelectedContactId(record.id);
                },
              };
            }}
            pagination={false}
          />
          {paginationComponent}
          <AddContactModal
            isOpen={addContactModalIsOpen}
            setIsOpen={setAddContactModalIsOpen}
            projectId={projectId}
          />
          <ContactCardModal
            projectId={projectId}
            selectedContactId={selectedContactId}
            setSelectedContactId={setSelectedContactId}
          />
          <DeleteContactModal
            projectId={projectId}
            deleteContactId={deleteContactId}
            setDeleteContactId={setDeleteContactId}
          />
        </>
      );
    }

    if (!contactsData && !contactsLoading && contactsError) {
      return (
        <Result
          status="error"
          title="Ooops, something went wrong."
          subTitle={"Can't load contacts data."}
        />
      );
    }
  };

  return <div className={'contacts'}>{getComponent()}</div>;
};

export default Contacts;
