import React, { FC } from 'react';
import { DatePicker, Form } from 'antd';

interface IDateFormItemProps {
  label: string;
  name: string;
  useYear: boolean;
}

const DateFormItem: FC<IDateFormItemProps> = ({ label, name, useYear }) => {
  return (
    <Form.Item label={label} name={name}>
      <DatePicker
        format={useYear ? 'YYYY-MM-DD' : 'MM-DD'}
        popupStyle={{ zIndex: 2000 }}
        style={{ width: '100%' }}
        popupClassName={useYear ? '' : 'date-picker__no-year'}
      />
    </Form.Item>
  );
};

export default DateFormItem;
