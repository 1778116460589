import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Form, Input, message, Modal, Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { showErrorMessage } from '../../../shared/helpers';
import { GA_UPLOAD_QA } from '../../../shared/google-analytics';
import { projectsAPI } from '../../../services/projects-service';
import { V_REQUIRED_FIELD } from '../../../shared/constants';

interface ITextUpdateModal {
  isOpen: boolean;
  closeModal: () => void;
  projectId: string;
  knowledgeId: string;
  setTextUpdateKnowledgeId: Dispatch<SetStateAction<string>>;
  cb: () => void;
}

const TextUpdateModal: FC<ITextUpdateModal> = ({
  isOpen,
  closeModal,
  projectId,
  knowledgeId,
  setTextUpdateKnowledgeId,
  cb,
}) => {
  const [lazyGetProjectKnowledge, { data: knowledgeDataQ, isLoading, error }] =
    projectsAPI.useLazyGetProjectKnowledgeInfoQuery();
  const [editKnowledge, { isLoading: updateLoading }] =
    projectsAPI.useEditProjectKnowledgeMutation();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [okDisabled, setOkDisabled] = useState<boolean>(true);
  const [knowledgeData, setKnowledgeData] = useState<{
    id: string;
    name: string;
    content: string;
  } | null>(null);
  const [startLoading, setStartLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!isOpen) {
      setKnowledgeData(null);
      setStartLoading(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      lazyGetProjectKnowledge({ projectId, knowledgeId })
        .unwrap()
        .then((res) => {
          setKnowledgeData(res);
        })
        .finally(() => {
          setStartLoading(false);
        });
    }
  }, [isOpen, projectId, knowledgeId]);

  useEffect(() => {
    if (form) {
      form.setFieldsValue({
        name: knowledgeData?.name || '',
        text: knowledgeData?.content || '',
      });
    }
  }, [knowledgeData, form, isOpen]);

  const handleOk = async () => {
    setOkDisabled(true);

    const values = form.getFieldsValue();

    const result = await editKnowledge({
      projectId: projectId,
      knowledgeId: knowledgeId,
      body: {
        name: values.name,
        text: values.text,
      },
    });

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
    } else {
      GA_UPLOAD_QA();
      await messageApi.success(`Text have been updated.`, 1);
      cb();
      setTextUpdateKnowledgeId('');
    }

    setOkDisabled(false);
  };

  const handleCancel = () => {
    closeModal();
    setTextUpdateKnowledgeId('');
  };

  const handleChange = async () => {
    const name = form.getFieldValue('name');
    const text = form.getFieldValue('text');

    if (!text || !name) {
      setOkDisabled(true);
      return;
    }

    if (
      knowledgeData &&
      name === knowledgeData.name &&
      text === knowledgeData.content
    ) {
      setOkDisabled(true);
      return;
    }

    try {
      await form.validateFields();
      setOkDisabled(false);
    } catch (errors) {
      setOkDisabled(true);
    }
  };

  return (
    <Modal
      title="Update Text"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered={true}
      okButtonProps={{
        disabled: okDisabled || !!error,
        loading: updateLoading,
      }}
      className={'text-source-modal'}
      okText={'Update'}
      wrapClassName={'modal'}
      maskStyle={{ zIndex: 1100 }}
      forceRender={true}
    >
      {contextHolder}

      <Form
        form={form}
        onChange={handleChange}
        className="data-source-upload-modal-form"
        layout="vertical"
      >
        {isLoading || startLoading ? (
          <Spin
            tip="Loading..."
            className="loading__spin"
            style={{ width: '100%' }}
          />
        ) : error ? (
          <div>Can't get knowledge data.</div>
        ) : knowledgeData ? (
          <>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: V_REQUIRED_FIELD,
                },
                {
                  min: 4,
                  message: 'Name must be at least 4 characters.',
                },
              ]}
            >
              <Input autoComplete={'off'} placeholder="Enter name" />
            </Form.Item>
            <Form.Item
              name="text"
              rules={[
                {
                  required: true,
                  message: V_REQUIRED_FIELD,
                },
              ]}
              className={'text-source-modal__text'}
            >
              <TextArea
                rows={15}
                showCount
                maxLength={1024 * 1024}
                placeholder={`Add your text here`}
              />
            </Form.Item>
          </>
        ) : (
          <div>Can't get knowledge data.</div>
        )}
      </Form>
    </Modal>
  );
};

export default TextUpdateModal;
