import { FC, useEffect, useState } from 'react';
import { ITwilioTemplateBodyContent } from '../../../../types/whats-app-outbound-service/ICampaignsOptions';
import './twilio-template.scss';
import TwilioTemplateHelper from './twilio-template-helper';

const TwilioTemplatePreview: FC<{
  content: ITwilioTemplateBodyContent | null;
  type: string | null;
}> = ({ content, type }) => {
  if (content === null || type === null) return null;

  const contentData = JSON.parse(content.data);
  const renderText = () => {
    return TwilioTemplateHelper.getText(content.body);
  };

  const renderButtons = (data: any) => {
    if (!data) return null;

    return (
      <>
        {data.map((action: { title: string; type: string }, index: number) =>
          TwilioTemplateHelper.getButton(action.title, index, action.type)
        )}
      </>
    );
  };

  const renderMedia = (data: any) => {
    if (!data) return null;

    return <>{data.map((url: string) => TwilioTemplateHelper.getMedia(url))}</>;
  };

  const renderMediaTemplate = () => {
    const { media } = contentData;

    return (
      <>
        {renderMedia(media)}
        {renderText()}
      </>
    );
  };

  const renderListPickerTemplate = () => {
    const { button, items } = contentData;

    return (
      <>
        {renderText()}
        {TwilioTemplateHelper.getCollapsedList(button, items)}
      </>
    );
  };

  const renderCallToActionTemplate = () => {
    const { actions } = contentData;

    return (
      <>
        {renderText()}
        {renderButtons(actions)}
      </>
    );
  };

  const renderQuickReplyTemplate = () => {
    const { actions } = contentData;

    return (
      <>
        {renderText()}
        {renderButtons(actions)}
      </>
    );
  };

  const renderCardTemplate = () => {
    const { actions, media, subtitle, title } = contentData;

    return (
      <>
        {renderMedia(media)}
        {TwilioTemplateHelper.getText(title)}
        <div style={{ color: 'rgba(0, 0, 0, 0.4)' }}>
          {' '}
          {TwilioTemplateHelper.getText(subtitle)}
        </div>
        {renderButtons(actions)}
      </>
    );
  };

  const convertToCamelCase = (input: string): string => {
    return input.replace(/-./g, (match) => match.charAt(1).toUpperCase());
  };

  const render = () => {
    const typeCameled = convertToCamelCase(type);

    switch (typeCameled) {
      case TemplateType.TEXT:
        return renderText();

      case TemplateType.MEDIA:
        return renderMediaTemplate();

      case TemplateType.LIST_PICKER:
        return renderListPickerTemplate();

      case TemplateType.CALL_TO_ACTION:
        return renderCallToActionTemplate();

      case TemplateType.QUICK_REPLY:
        return renderQuickReplyTemplate();

      case TemplateType.CARD:
        return renderCardTemplate();

      default:
        return (
          <>
            Doesn't support template preview at the current time for this type
            of template.
          </>
        );
    }
  };

  return <div className="template-preview-content">{render()}</div>;
};

export default TwilioTemplatePreview;

export enum TemplateType {
  TEXT = 'twilio/text',
  MEDIA = 'twilio/media',
  LIST_PICKER = 'twilio/listPicker',
  CALL_TO_ACTION = 'twilio/callToAction',
  QUICK_REPLY = 'twilio/quickReply',
  CARD = 'twilio/card',
}
