import { analyticsAPI } from '../../../services/analytics-service';
import { PluginNames } from '../../../shared/constants';

export interface IAnalyticsEventsData {
  eventName: string;
  chartName: string;
  title: string;
}

export const WidgetAnalyticsEventsData: IAnalyticsEventsData[] = [
  {
    eventName: 'widgetLoaded',
    chartName: 'loaded',
    title: 'Total widget loaded',
  },
  {
    eventName: 'uniqueVisitor',
    chartName: 'unique_visitors',
    title: 'Total unique visitors',
  },
  {
    eventName: 'conversationStarted',
    chartName: 'conversation',
    title: 'Total widget conversation',
  },
  {
    eventName: 'widgetOpened',
    chartName: 'opened',
    title: 'Total widget opened',
  },
  {
    eventName: 'linkClickedInWidget',
    chartName: 'click',
    title: 'Total links clicked in widget',
  },
];

export const ADFPluginAnalyticsEventsData: IAnalyticsEventsData[] = [
  {
    eventName: 'leadAdded',
    chartName: 'leads',
    title: 'Total leads added',
  },
];

export const WAOutboundPluginAnalyticsEventsData: IAnalyticsEventsData[] = [
  {
    eventName: 'waoutboundSent',
    chartName: 'wa_sent',
    title: 'Total sent',
  },
  {
    eventName: 'waoutboundFailed',
    chartName: 'wa_failed',
    title: 'Total messages failed',
  },
  {
    eventName: 'waoutboundDelivered',
    chartName: 'wa_delivered',
    title: 'Total messages delivered',
  },
  {
    eventName: 'waoutboundUndelivered',
    chartName: 'wa_undelivered',
    title: 'Total messages Undelivered',
  },
  {
    eventName: 'waoutboundRead',
    chartName: 'wa_read',
    title: 'Total users have read',
  },
  {
    eventName: 'waoutboundOptOut',
    chartName: 'wa_opt-out',
    title: 'Total opt-outs',
  },
  {
    eventName: 'waoutboundReplied',
    chartName: 'wa_replied',
    title: 'Total users have replied',
  },
  {
    eventName: 'waoutboundLeadAdded',
    chartName: 'wa_lead',
    title: 'Total lead added',
  },
];

export interface IAnalyticsData {
  eventsData: IAnalyticsEventsData[];
  getData: any;
}

export const WIDGET_ANALYTICS_DATA_KEY = 'widget';

export const AnalyticsData: { [key: string]: IAnalyticsData } = {
  [WIDGET_ANALYTICS_DATA_KEY]: {
    eventsData: WidgetAnalyticsEventsData,
    getData: analyticsAPI.useLazyGetWidgetPerDayQuery,
  },
  [PluginNames.ADF_PLUGIN]: {
    eventsData: ADFPluginAnalyticsEventsData,
    getData: analyticsAPI.useLazyGetLeadsPerDayQuery,
  },
  [PluginNames.TWILIO]: {
    eventsData: WAOutboundPluginAnalyticsEventsData,
    getData: analyticsAPI.useLazyGetWhatsAppOutboundPerDayQuery,
  },
};
