import { FC, useEffect, useState } from 'react';
import IProjectPlugin from '../../../types/IProjectPlugin';
import { Button, Result, Tabs, TabsProps } from 'antd';
import Campaigns from './campaigns/campaigns';
import TwilioTemplates from './twilio-templates/twilio-templates';
import {
  findInstalledProjectPlugin,
  findPlugin,
} from '../../../pages/project-settings/project-settings-helpers';
import { PluginNames, ProjectSettingsType } from '../../../shared/constants';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteNames } from '../../../router/router';

interface ITwilioOutboundProps {
  projectId: string;
  projectPlugins: IProjectPlugin[] | undefined;
}

const TwilioOutbound: FC<ITwilioOutboundProps> = ({
  projectId,
  projectPlugins,
}) => {
  const { orgId, subtab } = useParams();

  const [activeTab, setActiveTab] = useState<string | undefined>(
    twilioTabs.campaigns.key
  );
  const { plugins } = useAppSelector((state) => state.pluginsReducer);
  const [twilioPluginIsInstalled, setTwilioPluginIsInstalled] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (projectPlugins) {
      const twilioPlugin = findPlugin(PluginNames.TWILIO, plugins);

      if (twilioPlugin) {
        const twilioPluginInstalled = findInstalledProjectPlugin(
          twilioPlugin,
          projectPlugins
        );
        if (twilioPluginInstalled) {
          setTwilioPluginIsInstalled(true);
        } else {
          setTwilioPluginIsInstalled(false);
        }
      }
    }
  }, [plugins, projectPlugins]);

  const items: TabsProps['items'] = [
    {
      key: twilioTabs.campaigns.key,
      label: 'Campaigns',
      children: <Campaigns projectId={projectId} />,
    },
    {
      key: twilioTabs.templates.key,
      label: 'Templates',
      children: <TwilioTemplates projectId={projectId} />,
    },
  ];

  const onChange = (key: string) => {
    setActiveTab(key);
  };

  if (!twilioPluginIsInstalled) {
    const redirectBtn = () => (
      <Button
        type={'link'}
        onClick={() => {
          navigate(
            `${RouteNames.USER_PROJECTS}/${orgId}${RouteNames.PROJECT_SETTINGS_TAB_NAME}/${projectId}/${ProjectSettingsType.PLUGINS}`
          );
        }}
        className={'btn--inline'}
      >
        Install the Twilio plugin
      </Button>
    );

    return (
      <Result
        status="warning"
        title="Twilio plugin is not installed."
        subTitle={<>{redirectBtn()} to use outbound feature.</>}
      />
    );
  }

  return (
    <>
      <Tabs activeKey={activeTab} items={items} onChange={onChange} />
    </>
  );
};

export default TwilioOutbound;

const twilioTabs = {
  campaigns: {
    key: 'campaigns',
  },
  templates: {
    key: 'templates',
  },
};
