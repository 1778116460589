import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { domainsAPI } from '../../services/domains-service';
import { isClientDomain } from '../../shared/helpers';

interface IDomainsState {
  name: string;
  filePath: string;
  contactEmail: string;
  globalTimestamp: number;
}

const initialState: IDomainsState = {
  name: '',
  filePath: '',
  contactEmail: '',
  globalTimestamp: 0,
};

export const domainsSlice = createSlice({
  name: 'domains',
  initialState,
  reducers: {
    setGlobalTimestamp: (state, action: PayloadAction<number>) => {
      state.globalTimestamp = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      domainsAPI.endpoints.getCurrentDomainInfo.matchFulfilled,
      (
        state,
        action: PayloadAction<{
          name: string;
          filePath: string;
          email: string | null;
        }>
      ) => {
        if (!isClientDomain()) {
          state.name = process.env.REACT_APP_DISPLAY_NAME || '';
          state.filePath = 'globals/';
          state.contactEmail = process.env.REACT_APP_CONTACT_EMAIL || '';
          return;
        }

        state.name = action.payload.name;
        state.filePath = action.payload.filePath;
        state.contactEmail = action.payload.email || '';
      }
    );
  },
});

export default domainsSlice.reducer;
