import React, { FC, useRef } from 'react';
import './bubble.scss';
import Tail from './tail';
import { WidgetModes } from '../../fake-widget-helpers';

interface IBubbleProps {
  widgetMode: WidgetModes;
  showBubble: boolean;
  bubbleMessage: string;
}

export const BUBBLE_DEFAULT_MESSAGE = 'Hi, how may I help you?';

const bubbleHiddenClassName = 'bubble--hidden';

const Bubble: FC<IBubbleProps> = ({
  widgetMode,
  showBubble,
  bubbleMessage,
}) => {
  const bubbleRef = useRef<HTMLDivElement | null>(null);

  // const bottom = config.miniChat.showImage ? '140px' : '70px';
  const getStyle = () => {
    return widgetMode === WidgetModes.FLOAT_BUTTON
      ? {
          bottom: 22,
          left: 140,
        }
      : {
          bottom: 140,
          left: 100,
        };
  };

  const getVisibility = () => {
    return widgetMode !== WidgetModes.FULL && showBubble
      ? ''
      : bubbleHiddenClassName;
  };

  return (
    <div
      ref={bubbleRef}
      className={`bubble ${getVisibility()} bubble--left`}
      style={getStyle()}
    >
      <div className={'bubble-content'} style={{ maxWidth: '145px' }}>
        {bubbleMessage}
        <div className={'bubble-tail'}>
          <Tail />
        </div>
      </div>
    </div>
  );
};

export default Bubble;
