import React, { FC, useEffect, useReducer, useState } from 'react';
import { tablesApi } from '../../../services/tables-service';
import Loading from '../../loading/loading';
import { message, Result } from 'antd';
import TablesUtils from '../../shared/tables/tables-utils';
import { SelectColumn, textEditor } from 'react-data-grid';
import {
  IEditorTableColumn,
  ITableColumnT,
  ITableRowT,
} from '../../../types/tables-service/ITableT';
import EditableHeaderCell from './table-components/editable-header-cell';
import TableEditable from './table-components/table-editable';
import { ITableFilterValues } from '../whats-app-outbound/filters/filters-helpers';
import usePagination from '../../../hooks/usePagination';
import DeleteColumnTModal from '../../modals/project-settings/tables/delete-columnT-modal';
import useDebounce from '../../../hooks/table-hooks/useDebounce';
import useUpdateTableData from '../../../hooks/table-hooks/useUpdateTableData';
import useOnTableChange from '../../../hooks/table-hooks/useOnTableChange';
import useSelectCell from '../../../hooks/table-hooks/useSelectCell';
import useTableSavingDataIndicator from '../../../hooks/table-hooks/useTableSavingDataIndicator';

interface ITableByIdProps {
  projectId: string;
  tableId: string;
}

// TODO: Delete TableEditSchemaModal someday, not now

const TableById: FC<ITableByIdProps> = ({ projectId, tableId }) => {
  const [
    lazyGetTable,
    { data: tableData, isLoading: tableLoading, error: tableError },
  ] = tablesApi.useLazyGetTableDataTQuery();
  const [
    getTableRecords,
    {
      data: tableRecordsData,
      isLoading: tableRecordsLoading,
      error: tableRecordsError,
    },
  ] = tablesApi.useLazyGetTableRecordsTQuery();
  const [tableName, setTableName] = useState<string | null>(null);
  const [tableColumns, setTableColumns] = useState<IEditorTableColumn[]>([]);
  const [tableRows, setTableRows] = useState<ITableRowT[]>([]);
  const [filterValues, setFilterValues] = useState<ITableFilterValues[]>([]);
  const [allowUpdate, setAllowUpdate] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [deleteColumnKey, setDeleteColumnKey] = useState<string>('');
  const [openHeaderCellMoreMenu, setOpenHeaderCellMoreMenu] =
    useState<string>('');
  const [startLoading, setStartLoading] = useState(true);
  const [deleteColumnLoading, setDeleteColumnLoading] = useState(false);
  const [deleteRowLoading, setDeleteRowLoading] = useState(false);
  const debouncedNameValue = useDebounce(tableName, 500);
  const [
    paginationComponent,
    itemsPerPage,
    currentPage,
    setCurrentPage,
    itemsTotalCount,
    setItemsTotalCount,
  ] = usePagination();
  const [gridRef, editingHeaderCellKey, setEditingHeaderCellKey] =
    useSelectCell(tableColumns, tableRows);
  const [updateTableData, updateTableLoading] = useUpdateTableData({
    projectId,
    tableData,
    tableRecordsData,
    tableColumns,
    tableName,
    messageApi,
    cb: () => {
      lazyGetTable({ projectId, tableId });
    },
  });
  const [
    onAddColumn,
    onAddRow,
    onRowsChange,
    addColumnLoading,
    addRowLoading,
    upsertRecordsLoading,
    handleHeaderChange,
  ] = useOnTableChange({
    gridRef,
    projectId,
    tableData,
    tableColumns,
    setTableColumns,
    tableRows,
    setTableRows,
    onAddColumnCb: (projectId: string, tableId: string) =>
      onAddColumnCb(projectId, tableId),
    pagination: {
      currentPage,
      setCurrentPage,
      itemsPerPage,
      itemsTotalCount,
    },
    getRecords: (offset: number, limit: number) =>
      hookGetRecords(offset, limit),
    messageApi,
  });
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    if (tableData) {
      setIsReadOnly(tableData.isReadOnly);
    }
  }, [tableData]);

  async function onAddColumnCb(projectId: string, tableId: string) {
    await lazyGetTable({ projectId, tableId });
  }

  async function hookGetRecords(offset: number, limit: number) {
    // await getRecords((currentPage - 1) * itemsPerPage, itemsPerPage);
    await getRecords(offset, limit);
  }

  const [savingData] = useTableSavingDataIndicator({
    startLoading,
    updateTableLoading,
    upsertRecordsLoading,
    addColumnLoading,
    addRowLoading,
    deleteColumnLoading,
    deleteRowLoading,
  });

  useEffect(() => {
    lazyGetTable({ projectId, tableId })
      .then(() => {
        setAllowUpdate(true);
      })
      .finally(() => setStartLoading(false));
  }, []);

  useEffect(() => {
    if (debouncedNameValue) {
      updateTableData();
    }
  }, [debouncedNameValue]);

  useEffect(() => {
    if (allowUpdate) {
      updateTableData();
    }
  }, [tableColumns]);

  useEffect(() => {
    if (tableData) {
      setTableColumns(getColumnsT(tableData.columns));
    }
  }, [tableData, editingHeaderCellKey, openHeaderCellMoreMenu, isReadOnly]);

  useEffect(() => {
    if (tableRecordsData && tableData) {
      setTableRows(TablesUtils.getTableRows(tableRecordsData, tableData));
    }
  }, [tableRecordsData, tableData]);

  useEffect(() => {
    getRecords((currentPage - 1) * itemsPerPage, itemsPerPage);
  }, [currentPage, itemsPerPage, tableData, filterValues]);

  const getRecords = async (offset: number = 0, limit: number = 10) => {
    if (!tableData) return;
    const res = await getTableRecords({
      projectId,
      id: tableData.id,
      params: { offset: offset, limit: limit, filterValues },
    });
    if (res.data) {
      // setCurrentTableRecords(res.data);
      setItemsTotalCount(res.data.totalCount);
      return res.data;
    }
  };

  const onDeleteColumn = (columnKey: string) => {
    setDeleteColumnKey(columnKey);
  };

  function getColumnsT(tColumns: ITableColumnT[]): IEditorTableColumn[] {
    const columns: IEditorTableColumn[] = [SelectColumn];

    tColumns.forEach((col) => {
      let width: string | number = 'auto';

      if (tableRecordsData) {
        width = TablesUtils.getColWidth(col, tableRecordsData);
      } else {
        const currentCol = tableColumns.find((c) => c.key === col.key);
        if (currentCol) {
          width = currentCol.width || '';
        }
      }

      columns.push({
        ...col,
        renderEditCell: textEditor,
        width: width,
        resizable: true,
        headerCellClass: `colKey--${col.key}`,
        renderHeaderCell: (props) => {
          return (
            <EditableHeaderCell
              isReadOnly={isReadOnly}
              column={props.column}
              onHeaderChange={(newName) =>
                handleHeaderChange(props.column.idx, newName)
              }
              onDeleteColumn={onDeleteColumn}
              setTableColumns={setTableColumns}
              setFilterValues={setFilterValues}
              editingHeaderCellKey={editingHeaderCellKey}
              setEditingHeaderCellKey={setEditingHeaderCellKey}
              openHeaderCellMoreMenu={openHeaderCellMoreMenu}
              setOpenHeaderCellMoreMenu={setOpenHeaderCellMoreMenu}
            />
          );
        },
      });
    });

    return columns;
  }

  const deleteColumnCb = () => {
    setDeleteLoading(true);
    lazyGetTable({ projectId, tableId }).then(() => {
      setDeleteLoading(false);
    });
  };

  const getComponent = () => {
    if (tableLoading || tableRecordsLoading || startLoading || deleteLoading) {
      return <Loading height={'300px'} />;
    }

    if (!tableData && !tableRecordsData && (tableError || tableRecordsError)) {
      return (
        <Result
          status="error"
          title="Error"
          subTitle={"Can't get table data."}
        />
      );
    }

    return (
      <div>
        <TableEditable
          projectId={projectId}
          tableName={tableName}
          setTableName={setTableName}
          tableData={tableData}
          isReadOnly={isReadOnly}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          itemsTotalCount={itemsTotalCount}
          tableColumns={tableColumns}
          onAddColumn={onAddColumn}
          addColumnLoading={addColumnLoading}
          tableRows={tableRows}
          onAddRow={onAddRow}
          addRowLoading={addRowLoading}
          onRowsChange={onRowsChange}
          tableRecordsData={tableRecordsData}
          getRecordsCb={() => {
            return getRecords((currentPage - 1) * itemsPerPage, itemsPerPage);
          }}
          messageApi={messageApi}
          editingHeaderCellKey={editingHeaderCellKey}
          setEditingHeaderCellKey={setEditingHeaderCellKey}
          gridRef={gridRef}
          savingData={savingData}
          setDeleteRowLoading={setDeleteRowLoading}
          renderImportAction={null}
        />
        {paginationComponent}
        <DeleteColumnTModal
          projectId={projectId}
          tableData={tableData}
          deleteColumnKey={deleteColumnKey}
          setDeleteColumnKey={setDeleteColumnKey}
          setTableColumns={setTableColumns}
          setTableRows={setTableRows}
          setDeleteColumnLoading={setDeleteColumnLoading}
          deleteColumnCb={deleteColumnCb}
        />
      </div>
    );
  };

  return (
    <div>
      {contextHolder}
      {getComponent()}
    </div>
  );
};

export default TableById;
