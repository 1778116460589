import React, { FC, useEffect, useState } from 'react';
import IOrganization from '../../../../types/IOrganization';
import { Button, Input, Skeleton } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { usersAPI } from '../../../../services/users-service';

interface IOrgIntegrationsProps {
  currentOrgInfo: IOrganization | null;
}

const OrgIntegrations: FC<IOrgIntegrationsProps> = ({ currentOrgInfo }) => {
  const [
    lazyGetOrganizationAPIKey,
    { data: apiKeyData, isLoading: apiKeyLoading, error: apiKeyError },
  ] = usersAPI.useLazyGetOrganizationAPIKeyQuery();
  const [apiKeyVisible, setApiKeyVisible] = useState(false);

  useEffect(() => {
    if (!currentOrgInfo) return;
    lazyGetOrganizationAPIKey(currentOrgInfo.id);
    setApiKeyVisible(false);
  }, [currentOrgInfo]);

  const renderApiKey = () => {
    if (apiKeyLoading) {
      return (
        <Skeleton active className={'organization-usage-skeleton-loading'} />
      );
    }

    if (apiKeyError) {
      return (
        <>
          <div className="profile-setting-title organization-usage-title org-settings-title">
            API key
          </div>
          <div className="profile-setting-description">
            {apiKeyError &&
            'data' in apiKeyError &&
            (apiKeyError.data as { message: string }) &&
            (apiKeyError.data as { message: string }).message
              ? (apiKeyError.data as { message: string }).message
              : `Can't get organization API key.`}
          </div>
        </>
      );
    }

    if (apiKeyData) {
      return (
        <>
          <div className="profile-setting-title organization-usage-title org-settings-title">
            API key
          </div>
          <div className="profile-setting-description">
            Keep organization API key in secret.
          </div>
          <div className="api-key-input-label">Organization API key</div>
          <div className="api-key-container">
            <Input.Password
              value={apiKeyData}
              visibilityToggle={{
                visible: apiKeyVisible,
                onVisibleChange: setApiKeyVisible,
              }}
              className="api-key-input"
            />
            <Button
              onClick={() => navigator.clipboard.writeText(apiKeyData)}
              className="api-key-btn"
            >
              <CopyOutlined />
            </Button>
          </div>
        </>
      );
    }

    return null;
  };

  return <>{renderApiKey()}</>;
};

export default OrgIntegrations;
