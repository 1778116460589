import { FC } from 'react';
import { RenderBodyField, RenderInputField } from './template-builder';
import { FormInstance } from 'antd';

interface IMediaTemplateProps {
  form: FormInstance<any>;
}

const MediaTemplate: FC<IMediaTemplateProps> = ({ form }) => {
  return (
    <>
      {RenderBodyField(form)}
      <br></br>
      {RenderInputField(
        form,
        'media',
        'Media URL',
        'Supports images, audio, documents, and video.',
        true,
        true,
        1600
      )}
    </>
  );
};

export default MediaTemplate;
