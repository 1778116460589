import { Pagination } from 'antd';
import React, { useState } from 'react';

const DEFAULT_RECORDS_PER_PAGE = 10;

export default function usePagination(): [
  JSX.Element,
  number,
  number,
  React.Dispatch<React.SetStateAction<number>>,
  number,
  React.Dispatch<React.SetStateAction<number>>
] {
  const [itemsPerPage, setItemsPerPage] = useState<number>(
    DEFAULT_RECORDS_PER_PAGE
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsTotalCount, setItemsTotalCount] = useState<number>(0);

  const onChangePage = async (page: number) => {
    setCurrentPage(page);
  };

  const onShowSizeChange = async (current: number, pageSize: number) => {
    setCurrentPage(current);
    setItemsPerPage(pageSize);
  };
  const paginationComponent = () => (
    <div className={'whats-app-outbound-table-pagination-container'}>
      <Pagination
        defaultPageSize={itemsPerPage}
        hideOnSinglePage={true}
        showSizeChanger
        onShowSizeChange={onShowSizeChange}
        onChange={onChangePage}
        current={currentPage}
        defaultCurrent={1}
        total={itemsTotalCount}
        pageSizeOptions={[
          DEFAULT_RECORDS_PER_PAGE.toString(),
          '20',
          '50',
          '100',
        ]}
      />
    </div>
  );

  return [
    paginationComponent(),
    itemsPerPage,
    currentPage,
    setCurrentPage,
    itemsTotalCount,
    setItemsTotalCount,
  ];
}
