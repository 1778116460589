import React, { FC, useState } from 'react';
import { Checkbox, Form, Input, message, Modal } from 'antd';
import { projectsAPI } from '../../../services/projects-service';
import { showErrorMessage } from '../../../shared/helpers';
import { V_REQUIRED_FIELD } from '../../../shared/constants';

interface ISiteSourceModal {
  isOpen: boolean;
  closeModal: () => void;
  projectId: string;
  cb: () => void;
}

const WebsiteSourceModal: FC<ISiteSourceModal> = ({
  isOpen,
  closeModal,
  projectId,
  cb,
}) => {
  const [addProjectKnowledgeWebsite, { isLoading }] =
    projectsAPI.useAddProjectKnowledgeWebsiteMutation();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [okDisabled, setOkDisabled] = useState<boolean>(true);

  const handleOk = async () => {
    if (!projectId) return;
    setOkDisabled(true);

    const values = form.getFieldsValue();

    const result = await addProjectKnowledgeWebsite({
      projectId: projectId,
      url: values.url,
      crawlAllWebsite: values.crawlAllWebsite,
    });

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
    } else {
      //TODO: add GA event
      // GA_UPLOAD_FILE(fileExtension || 'N/A');
      await messageApi.success(`Site has been submitted.`, 1);
    }
    form.resetFields();
    cb();
    setOkDisabled(false);
    closeModal();
  };

  const handleChange = async () => {
    const url = form.getFieldValue('url');

    if (!url) {
      setOkDisabled(true);
    } else {
      try {
        await form.validateFields();
        setOkDisabled(false);
      } catch (errors) {
        setOkDisabled(true);
      }
    }
  };

  return (
    <Modal
      title="Add knowledge from website"
      open={isOpen}
      onOk={handleOk}
      onCancel={closeModal}
      className="web-source-modal"
      centered={true}
      okButtonProps={{ disabled: okDisabled, loading: isLoading }}
    >
      {contextHolder}
      <Form
        form={form}
        onChange={handleChange}
        className="data-source-upload-modal-form"
        layout="vertical"
      >
        <Form.Item
          name="url"
          style={{ marginBottom: 14 }}
          rules={[
            {
              required: true,
              message: V_REQUIRED_FIELD,
            },
            {
              min: 3,
              message: 'Domain must be at least 19 characters.',
            },
            {
              max: 512,
              message: 'The maximum length of a domain URL is 512 characters.',
            },
          ]}
        >
          <Input placeholder={'google.com'} />
        </Form.Item>
        <Form.Item name="crawlAllWebsite" valuePropName="checked">
          <Checkbox>Crawl all website</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default WebsiteSourceModal;
