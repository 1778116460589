import React, { FC, useEffect, useState } from 'react';
import { Menu, MenuProps } from 'antd';
import { ProjectSettingsType } from '../../shared/constants';
import { RouteNames } from '../../router/router';
import { Link, useParams } from 'react-router-dom';

export interface IMenuOption {
  label: string;
  value: ProjectSettingsType;
  key: ProjectSettingsType;
  icon: any;
}

const getMenuItems = (
  currentItemKey: string,
  options: IMenuOption[],
  orgId: string,
  projectId: string
) => {
  const currentKey = currentItemKey.replace('--active', '');
  const currentItem =
    options.find((option) => option.value === currentKey) || options[0];

  return [
    {
      label: (
        <>
          <span style={{ display: 'inline-block', width: '147px' }}>
            {currentItem.label}
          </span>{' '}
          <span
            style={{
              display: 'inline-block',
              transform: 'rotate(90deg)',
              fontSize: 16,
            }}
          >
            ›
          </span>
        </>
      ),
      key: currentItemKey,
      icon: <currentItem.icon />,
      style: { width: '100%' },
      children: options.map((option) => ({
        label: (
          <Link
            to={`${RouteNames.USER_PROJECTS}/${orgId}${RouteNames.PROJECT_SETTINGS_TAB_NAME}/${projectId}/${option.value}`}
          >
            {option.label}
          </Link>
        ),
        key: option.key,
        icon: <option.icon />,
      })),
    },
  ];
};

interface IProjectSettingsMenuProps {
  options: IMenuOption[];
}

const ProjectSettingsMenu: FC<IProjectSettingsMenuProps> = ({ options }) => {
  const { orgId, id, tab, subtab } = useParams();
  const [current, setCurrent] = useState(
    (tab || ProjectSettingsType.KNOWLEDGE) + '--active'
  );
  const [menuItems, setMenuItems] = useState<MenuProps['items']>(
    getMenuItems(current, options, orgId || '', id || '')
  );

  useEffect(() => {
    setMenuItems(getMenuItems(current, options, orgId || '', id || ''));
  }, [current, orgId, id, options]);

  useEffect(() => {
    setCurrent((tab || ProjectSettingsType.KNOWLEDGE) + '--active');
  }, [tab]);

  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key + '--active');
  };

  return (
    <Menu
      onClick={onClick}
      selectedKeys={[current]}
      mode="horizontal"
      items={menuItems}
      disabledOverflow={true}
      className="project-settings-menu"
    />
  );
};

export default ProjectSettingsMenu;
