import React, { FC, useEffect, useState } from 'react';
import { getSvgPath } from 'figma-squircle';

export interface ISuperellipseMaskImageProps {
  src: string;
  x?: string;
  y?: string;
  width?: string;
  height?: string;
  preserveAspectRatio?: string;
}

interface ISuperellipseMaskProps {
  maskId: string;
  width: number;
  height: number;
  bgColor: string;
  borderColor: string;
  imageProps: ISuperellipseMaskImageProps;
  radius: number;
  smoothing: number;
}

const SuperellipseMask: FC<ISuperellipseMaskProps> = ({
  maskId,
  width,
  height,
  bgColor,
  borderColor,
  imageProps,
  radius,
  smoothing,
}) => {
  const borderWidth = 2;
  const [imgSrc, setImgSrc] = useState(imageProps.src);

  useEffect(() => {
    setImgSrc(imageProps.src);
  }, [imageProps]);

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      style={{ verticalAlign: 'top' }}
      xmlns="http://www.w3.org/2000/svg"
    >
      {/*/!* border *!/*/}
      <path
        fill={borderColor}
        d={getSvgPath({
          width: width,
          height: height,
          cornerRadius: radius + borderWidth,
          cornerSmoothing: smoothing,
        }).replace(/--/g, '-')}
      ></path>

      {/* main bg */}
      <path
        fill={bgColor}
        // opacity={0.2}
        d={getSvgPath({
          width: width - borderWidth * 2,
          height: height - borderWidth * 2,
          cornerRadius: radius,
          cornerSmoothing: smoothing,
        }).replace(/--/g, '-')}
        transform={`translate(${borderWidth}, ${borderWidth})`}
      ></path>

      {/* mask */}
      <mask id={`superellipseMask-${maskId}`}>
        <path
          fill="white"
          d={getSvgPath({
            width: width - borderWidth * 2,
            height: height - borderWidth * 2,
            cornerRadius: radius,
            cornerSmoothing: smoothing,
          }).replace(/--/g, '-')}
          transform={`translate(${borderWidth}, ${borderWidth})`}
        ></path>
      </mask>

      <image
        x={imageProps.x || '0'}
        y={imageProps.y || '0'}
        width={imageProps.width || '100%'}
        height={imageProps.height || '100%'}
        xlinkHref={imgSrc}
        mask={`url(#superellipseMask-${maskId})`}
        preserveAspectRatio={imageProps.preserveAspectRatio || 'xMidYMid'}
        onError={() => setImgSrc('')}
      ></image>
    </svg>
  );
};

export default SuperellipseMask;
