import React, { FC } from 'react';
import { Divider } from 'antd';
import TextAreaFormItem from './form-items/text-area-form-item';

const NoteRecordFormElement: FC = () => {
  return (
    <>
      <Divider orientation="left" className={'contact-form-divider'}>
        Note
      </Divider>
      <TextAreaFormItem label={'Note'} name={'note'} placeholder={'Note'} />
    </>
  );
};

export default NoteRecordFormElement;
