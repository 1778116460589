import React, { FC } from 'react';
import { Divider } from 'antd';
import TextInputFormItem from './form-items/text-input-form-item';
import { AddressLabel } from '../../../../types/contacts/contact-records/IAddressRecord';
import FormUtils from '../../../../shared/utils/form-utils';
import LabelFormItem from './form-items/label-form-item';
import IInitialLabelContactRecordValue from '../../../../types/contacts/IInitialLabelContactRecordValue';

interface IAddressRecordFormElementProps {
  initialLabelValue: IInitialLabelContactRecordValue;
}

const AddressRecordFormElement: FC<IAddressRecordFormElementProps> = ({
  initialLabelValue,
}) => {
  return (
    <>
      <Divider orientation="left" className={'contact-form-divider'}>
        Address
      </Divider>
      <TextInputFormItem
        label={'Street'}
        name={'street'}
        placeholder={'Street'}
      />
      <div style={{ display: 'flex', gap: 24 }}>
        <TextInputFormItem label={'City'} name={'city'} placeholder={'City'} />
        <TextInputFormItem
          label={'State'}
          name={'state'}
          placeholder={'State'}
        />
      </div>
      <div style={{ display: 'flex', gap: 24 }}>
        <TextInputFormItem
          label={'Country'}
          name={'country'}
          placeholder={'Country'}
        />
        <TextInputFormItem
          label={'Postal Code'}
          name={'postalCode'}
          placeholder={'Postal Code'}
        />
      </div>
      <div style={{ display: 'flex', gap: 24 }}>
        <LabelFormItem
          label={'Label'}
          name={'label'}
          options={FormUtils.createSelectOptionsFromEnum(AddressLabel)}
          initialValue={initialLabelValue}
        />
      </div>
    </>
  );
};

export default AddressRecordFormElement;
