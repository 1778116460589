import React, { FC, useEffect, useRef, useState } from 'react';
import './demo.scss';
import { useParams } from 'react-router-dom';
import { projectsAPI } from '../../services/projects-service';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  BotTypes,
  delay,
  GABotSharingTypes,
  ON_SDK_LOADED,
  SDK_NAME,
  WidgetVersions,
} from '../../shared/constants';
import { useAppSelector } from '../../hooks/redux-hooks';
import {
  Button,
  message,
  Radio,
  RadioChangeEvent,
  Result,
  Tooltip,
} from 'antd';
import { config } from '../../shared/config';
import { LinkOutlined } from '@ant-design/icons';
import Loading from '../../components/loading/loading';
import { GA_BOT_SHARE } from '../../shared/google-analytics';
import { ReactComponent as EmbedIcon } from '../../assets/icons/union.svg';
import EmbedModal from '../../components/modals/embed-modal/embed-modal';
import CheckAuth from '../../components/check-auth/check-auth';
import BreadcrumbNav from '../../components/breadcrumb-nav/breadcrumb-nav';
import IProjectStartInfo from '../../types/IProjectStartInfo';
import { RouteNames } from '../../router/router';
import { getDomain } from '../../shared/helpers';

const versionOptions = [
  { label: 'Classic', value: WidgetVersions.V1 },
  { label: 'Widget Pro (Preview)', value: WidgetVersions.V2 },
];

const Demo: FC = () => {
  const { orgId, id } = useParams();
  const {
    data: project,
    isLoading,
    error,
  } = projectsAPI.useGetProjectStartInfoQuery(id ?? skipToken);
  const [lazyGetProjectStartInfo] =
    projectsAPI.useLazyGetProjectStartInfoQuery();
  const { userProjects } = useAppSelector((state) => state.projectReducer);
  const { currentUserSubscription } = useAppSelector(
    (state) => state.subscriptionReducer
  );
  const { currentOrganization, currentOrganizationLoading } = useAppSelector(
    (state) => state.organizationsReducer
  );
  const [embedModalIsOpen, setEmbedModalIsOpen] = useState<boolean>(false);
  const widgetRef = useRef<HTMLIFrameElement>(null);
  const [widgetLoading, setWidgetLoading] = useState<boolean>(true);
  const [
    lazyGetUserProjectById,
    { error: lazyProjectError, isLoading: lazyUserProjectLoading },
  ] = projectsAPI.useLazyGetUserProjectByIdQuery();
  const [version, setVersion] = useState<string>(WidgetVersions.V2);
  const embedWidgetV2Ref = useRef<HTMLDivElement>(null);
  const localTimestamp = Date.now();
  const [widgetV2ScriptLoading, setWidgetV2ScriptLoading] =
    useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [sdkLoaded, setSdkLoaded] = useState<boolean>(false);
  const sdkLoadedRef = useRef<boolean>(false);
  const [fullScreenMode, setFullScreenMode] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      // TODO: check errors
      // console.log('bumdan');
      // console.log(orgId);
      lazyGetUserProjectById({ id, orgId });
    }
  }, [orgId, id]);

  useEffect(() => {
    // TODO: check errors
    // console.log(projectError);
    // console.log(lazyProjectError);
  }, [lazyProjectError]);

  useEffect(() => {
    if (id) {
      lazyGetProjectStartInfo(id);
    }
  }, [id, userProjects]);

  // connect V1 widget script
  useEffect(() => {
    const script = document.createElement('script');

    // script.src = process.env.PUBLIC_URL + '/for_test/widget.js';
    script.src =
      process.env.NODE_ENV === 'development'
        ? `https://static.${process.env.REACT_APP_DOMAIN}/static/scripts/embed.min.js`
        : `https://static.${getDomain()}/static/scripts/embed.min.js`;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      removeWidgetV2Script();
    };
  }, []);

  useEffect(() => {
    sdkLoadedRef.current = sdkLoaded;
  }, [sdkLoaded]);

  useEffect(() => {
    if (!sdkLoadedRef.current) return;
    if (fullScreenMode) {
      //@ts-ignore
      window[SDK_NAME].setSize('100%', '75%');
    } else {
      //@ts-ignore
      window[SDK_NAME].setSize('393px', '636px');
    }
  }, [fullScreenMode]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Backquote' && sdkLoadedRef.current) {
        setFullScreenMode((prev) => !prev);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // connect V2 widget config
  useEffect(() => {
    //@ts-ignore
    window.widgetConfigs = [
      {
        id: id,
        showMessageTime: false,
        rememberState: 'never',
        followPages: false,
        // closeButton: true,
        // babyChat: {
        //   enabled: true,
        // }
      },
    ];

    //@ts-ignore
    window[ON_SDK_LOADED] = () => {
      setSdkLoaded(true);
    };
  }, [id]);

  const widgetScriptV2 = document.createElement('script');
  widgetScriptV2.id = 'widgetScript';
  widgetScriptV2.defer = true;
  widgetScriptV2.src =
    process.env.NODE_ENV === 'development'
      ? `https://static.${process.env.REACT_APP_DOMAIN}/static/widgetV2/v0/dev/embed-v1.0.0.min.js?timestamp=${localTimestamp}`
      : `https://static.${getDomain()}/static/widget-pro/embed-v1.0.0.min.js`;

  // connect V2 widget script
  useEffect(() => {
    if (version === WidgetVersions.V2) {
      setWidgetV2ScriptLoading(true);

      const f = () => {
        const widgetElement = document.getElementById('embed-widget');
        if (widgetElement) {
          const script = document.getElementById('widgetScript');
          if (script) {
            return;
          }
          document.body.appendChild(widgetScriptV2);
          widgetScriptV2.onload = () => {
            setWidgetV2ScriptLoading(false);
          };
          widgetScriptV2.onerror = () => {
            setWidgetV2ScriptLoading(false);
          };
        } else {
          window.setTimeout(f, 1000);
        }
      };

      f();
    } else {
      removeWidgetV2Script();
    }
  }, [version]);

  const removeWidgetV2Script = () => {
    const script = document.getElementById('widgetScript');
    if (script) {
      document.body.removeChild(script);
    }
  };

  const handleChangeVersion = ({ target: { value } }: RadioChangeEvent) => {
    setVersion(value);
  };

  const getWidgetV2CopyUrl = () => {
    const port =
      process.env.NODE_ENV === 'development' ? `:${window.location.port}` : '';
    return (
      'https://' +
      window.location.hostname +
      port +
      RouteNames.WIDGET_PRO_SHARE_NO_PARAMS
    );
  };

  const demoPage = () => (
    <div className="demo">
      {contextHolder}
      <div className="container">
        <BreadcrumbNav />
        {/*<Radio.Group*/}
        {/*  value={version}*/}
        {/*  onChange={(e) => handleChangeVersion(e)}*/}
        {/*  className={'demo__version'}*/}
        {/*>*/}
        {/*  {versionOptions.map((option, i) => (*/}
        {/*    <Radio.Button value={option.value} key={i} style={{ width: 166 }}>*/}
        {/*      {option.label}*/}
        {/*    </Radio.Button>*/}
        {/*  ))}*/}
        {/*</Radio.Group>*/}
        <div
          className={`demo__inner ${
            fullScreenMode ? 'demo__inner--fullscreen' : ''
          }`}
          style={{ marginTop: 40 }}
        >
          {isLoading || lazyUserProjectLoading ? (
            <Loading height={'400px'} />
          ) : error &&
            'data' in error &&
            error.data &&
            'error' in (error.data as { error: string }) ? (
            <Result
              status="error"
              title="Ooops, something went wrong."
              subTitle={(error.data as { error: string })['error']}
            />
          ) : lazyProjectError ? (
            <Result status="error" title="Ooops, something went wrong." />
          ) : (
            id && project && getWidget(version)
          )}
          {(widgetLoading && version === WidgetVersions.V1) ||
          isLoading ||
          widgetV2ScriptLoading ||
          currentOrganizationLoading
            ? null
            : id &&
              project && (
                <div
                  className={`copy-link-btn-container ${
                    project && project.type === BotTypes.ASKBAR
                      ? 'copy-link-btn-container--askbar'
                      : 'copy-link-btn-container--chat'
                  } ${
                    version === WidgetVersions.V2
                      ? 'copy-link-btn-container--v2'
                      : 'copy-link-btn-container--default'
                  }`}
                >
                  {version === WidgetVersions.V2
                    ? copyLinkButton(project, getWidgetV2CopyUrl())
                    : currentOrganization &&
                      currentOrganization.customBranding &&
                      currentOrganization.customDomain &&
                      currentOrganization.domain
                    ? copyLinkButton(
                        project,
                        'https://widget.' + currentOrganization.domain
                      )
                    : copyLinkButton(project, config.widgetURL)}

                  {currentUserSubscription &&
                  !currentUserSubscription?.currentPlan?.features
                    ?.embed_widget ? (
                    <Tooltip
                      placement="top"
                      title={'To open it, upgrade your plan'}
                      color={'#f3f3f3'}
                      overlayInnerStyle={{ color: '#3c4257' }}
                    >
                      <span>{embedButton()}</span>
                    </Tooltip>
                  ) : (
                    embedButton()
                  )}
                </div>
              )}
        </div>
        {project ? (
          <EmbedModal
            version={version}
            isOpen={embedModalIsOpen}
            setIsOpen={setEmbedModalIsOpen}
            project={project}
          />
        ) : null}
      </div>
    </div>
  );

  const getWidget = (version: string) => {
    if (version === WidgetVersions.V1) {
      return widgetV1();
    } else {
      return widgetV2();
    }
  };

  const widgetV1 = () => (
    <>
      <iframe
        ref={widgetRef}
        style={{
          display: 'none',
        }}
        onLoad={(e) => setWidgetLoading(false)}
        id={`default`}
        src={`${config.widgetURL}/?id=${id}&embed=true`}
      />
      {widgetLoading ? <Loading height={'400px'} /> : null}
    </>
  );

  const widgetV2 = () => (
    <>
      {widgetV2ScriptLoading && <Loading height={'400px'} />}
      <div
        id="embed-widget"
        ref={embedWidgetV2Ref}
        style={{ position: 'relative', zIndex: 100 }}
      ></div>
    </>
  );

  const embedButton = () => (
    <Button
      className={`btn--default-custom ${
        version === WidgetVersions.V2 ? 'embed-btn--v2' : ''
      }`}
      icon={<EmbedIcon className="btn--default-custom-icon" />}
      onClick={() => {
        setEmbedModalIsOpen(true);
      }}
      disabled={
        !currentUserSubscription ||
        (currentUserSubscription &&
          !currentUserSubscription?.currentPlan?.features?.embed_widget)
      }
    >
      Embed
    </Button>
  );

  const copyLinkButton = (project: IProjectStartInfo, widgetURL: string) => (
    <Button
      icon={<LinkOutlined />}
      onClick={() => {
        GA_BOT_SHARE(project.type, GABotSharingTypes.COPY_LINK);
        navigator.clipboard.writeText(`${widgetURL}/${id}`);
        messageApi.info('Link copied to clipboard.');
      }}
    >
      Share Link
    </Button>
  );

  return (
    <CheckAuth component={demoPage()} allowedForDomainTenantMember={false} />
  );
};

export default Demo;
