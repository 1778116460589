import { MessageInstance } from 'antd/es/message/interface';
import { tablesApi } from '../../services/tables-service';
import { useState } from 'react';
import {
  ITableNewColumnT,
  ITableRowT,
} from '../../types/tables-service/ITableT';
import { showErrorMessage } from '../../shared/helpers';
import { TableColumnTypes } from '../../shared/constants';

export default function useCreateEmptyTable(
  projectId: string,
  messageApi: MessageInstance
): [() => Promise<string>, boolean] {
  const [createTableT] = tablesApi.useCreateTableTMutation();
  const [createTableRecordsT] = tablesApi.useCreateTableRecordsTMutation();
  const [isLoading, setIsLoading] = useState(false);

  const createTable = async () => {
    let tableId: string = '';

    setIsLoading(true);

    const cols: ITableNewColumnT[] = [];

    for (let i = 0; i < 2; i++) {
      cols.push({
        name: `col_${i}`,
        type: TableColumnTypes.STRING,
        isSearchable: true,
        isUnique: false,
      });
    }

    const res = await createTableT({
      projectId: projectId,
      name: 'My table',
      columns: cols,
    });

    if ('error' in res) {
      showErrorMessage(messageApi, res.error);
    } else if (res.data) {
      tableId = res.data.id;

      const rows: { items: ITableRowT }[] = [];
      for (let i = 0; i < 2; i++) {
        const emptyRow = Object.fromEntries(
          res.data.columns.map((col) => [col.key, ''])
        );
        rows.push({
          items: emptyRow,
        });
      }

      const res2 = await createTableRecordsT({
        projectId: projectId,
        id: res.data.id,
        records: rows,
      });

      if ('error' in res2) {
        showErrorMessage(messageApi, res2.error);
      } else {
        messageApi.success('Table created successfully.');
      }
    }

    setIsLoading(false);
    return tableId;
  };

  return [createTable, isLoading];
}
