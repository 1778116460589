import React, { FC, useState } from 'react';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../../../hooks/redux-hooks';
import DeleteProjectModal from '../../../modals/delete-project-modal/delete-project-modal';
import IProject from '../../../../types/IProject';
import CloneProjectModal from '../clone-project-modal';
import { MessageInstance } from 'antd/es/message/interface';

interface IAdvancedSettingsProps {
  userProject: IProject;
  messageApi: MessageInstance;
}

const AdvancedSettings: FC<IAdvancedSettingsProps> = ({
  userProject,
  messageApi,
}) => {
  const { subscriptionIsActive } = useAppSelector(
    (state) => state.subscriptionReducer
  );
  const [cloneProjectModalIsOpen, setCloneProjectModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const cloneProjectItem = () => {
    return (
      <div style={{ marginBottom: 24 }}>
        <div style={{ paddingBottom: 8 }}>Clone project</div>
        <Button
          disabled={!subscriptionIsActive}
          onClick={() => setCloneProjectModalIsOpen(true)}
        >
          Clone Project
        </Button>
      </div>
    );
  };

  const dangerZone = () => {
    return (
      <div className={'danger-zone'} style={{ marginTop: 0 }}>
        <div className={'danger-zone__title'}>Danger Zone</div>
        <div className={'danger-zone__content'}>
          <div>Careful, these actions are not reversible!</div>
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => setDeleteModalIsOpen(true)}
            disabled={!subscriptionIsActive}
            className={'danger-zone__button'}
          >
            Delete Project
          </Button>
        </div>
      </div>
    );
  };

  const modals = () => {
    return (
      <>
        <CloneProjectModal
          isOpen={cloneProjectModalIsOpen}
          setIsOpen={setCloneProjectModalIsOpen}
          projectId={userProject.id}
          projectName={userProject.name}
          messageApi={messageApi}
        />
        <DeleteProjectModal
          isOpen={deleteModalIsOpen}
          setIsOpen={setDeleteModalIsOpen}
          projectId={userProject.id}
          projectInfo={userProject}
        />
      </>
    );
  };

  return (
    <>
      {cloneProjectItem()}
      {dangerZone()}
      {modals()}
    </>
  );
};

export default AdvancedSettings;
