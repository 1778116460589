import React, { FC } from 'react';
import { Button, Tag } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons';
import IRecordItemProps from '../IRecordItemProps';
import IAddressRecord, {
  AddressLabel,
} from '../../../../../types/contacts/contact-records/IAddressRecord';

const AddressRecordItem: FC<IRecordItemProps> = ({
  record,
  handleEditRecord,
  handleDeleteRecord,
}) => {
  const getTagColor = (label: AddressLabel) => {
    switch (label) {
      case AddressLabel.HOME:
        return 'green';
      case AddressLabel.WORK:
        return 'blue';
      case AddressLabel.OTHER:
        return 'purple';
      default:
        return 'default';
    }
  };

  const getComponent = () => {
    const recordData = record.record as IAddressRecord;
    const address = [
      recordData.street,
      recordData.city,
      recordData.state,
      recordData.country,
      recordData.postalCode,
    ]
      .filter(Boolean)
      .join(', ');

    return (
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 10,
          }}
        >
          <div style={{ display: 'flex' }}>
            <Tag color={getTagColor(recordData.label)}>{recordData.label}</Tag>
            {recordData.customLabel && (
              <Tag color="default">{recordData.customLabel}</Tag>
            )}
          </div>
          <div style={{ display: 'flex', gap: 5 }}>
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={() => handleEditRecord(record)}
              size={'small'}
            ></Button>
            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={() => handleDeleteRecord(record)}
              danger
              size={'small'}
            ></Button>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
          <EnvironmentOutlined />
          <span>{address}</span>
        </div>
      </div>
    );
  };

  return getComponent();
};

export default AddressRecordItem;
