import React, { FC, useEffect, useState } from 'react';
import { Form, Modal } from 'antd';
import {
  IContactRecord,
  TContactRecord,
} from '../../../../types/contacts/IContact';
import ContactsHelpers from '../contacts-helpers';
import dayjs from 'dayjs';
import IDateRecord from '../../../../types/contacts/contact-records/IDateRecord';

interface IEditContactRecordModalProps {
  editRecord: IContactRecord | null;
  setEditRecord: React.Dispatch<React.SetStateAction<IContactRecord | null>>;
  onEditConfirm: (newValues: TContactRecord) => Promise<void>;
}

const EditContactRecordModal: FC<IEditContactRecordModalProps> = ({
  editRecord,
  setEditRecord,
  onEditConfirm,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [okDisabled, setOkDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (editRecord) {
      setIsOpen(true);
      const values = JSON.parse(JSON.stringify(editRecord.record));
      values.date = values.date ? dayjs(values.date) : dayjs();
      form.setFieldsValue(values);
      onValuesChange();
    } else {
      setIsOpen(false);
      form.resetFields();
      setOkDisabled(true);
    }
  }, [editRecord]);

  const handleCancel = () => {
    setEditRecord(null);
  };

  const onValuesChange = () => {
    let changed = false;
    const formValues = form.getFieldsValue();
    for (let key in formValues) {
      const formValue = formValues[key];
      const initialValue = editRecord
        ? (editRecord.record[key as keyof TContactRecord] as unknown)
        : null;
      let formValueToCompare = formValue;
      let initialValueToCompare = initialValue;
      if (formValue) {
        formValueToCompare =
          typeof formValue === 'string' ? formValue : JSON.stringify(formValue);
      }
      if (initialValue) {
        initialValueToCompare =
          typeof initialValue === 'string'
            ? initialValue
            : JSON.stringify(initialValue);
      }

      if (formValueToCompare !== initialValueToCompare) {
        changed = true;
        break;
      }
    }
    setOkDisabled(!changed);
  };

  const handleOk = async () => {
    setOkDisabled(true);
    const formValues = form.getFieldsValue();
    formValues.date = formValues.date
      ? formValues.date.format('YYYY-MM-DD')
      : null;
    await onEditConfirm(formValues);
    setOkDisabled(false);
  };

  const getComponent = (editRecord: IContactRecord | null) => {
    if (!editRecord) {
      return null;
    }

    const initialLabelValue = {
      label: '',
      customLabel: '',
    };

    if ('label' in editRecord.record) {
      initialLabelValue.label = editRecord.record.label || '';
      initialLabelValue.customLabel = editRecord.record.customLabel || '';
    }

    let useYear = true;

    if ('date' in editRecord.record) {
      useYear = editRecord.record.useYear;
    }

    return ContactsHelpers.getFormElement(
      editRecord.type,
      initialLabelValue,
      useYear
    );
  };

  const getDateInitialValue = (record: IDateRecord | undefined) => {
    if (!record || !record.date) {
      return { date: dayjs() };
    }

    const useYear = record.useYear;
    const date = useYear
      ? dayjs(record.date, 'YYYY-MM-DD')
      : dayjs(record.date).format('MM-DD');

    return {
      date: date,
    };
  };

  return (
    <Modal
      title="Edit contact record"
      open={isOpen}
      onCancel={handleCancel}
      centered={true}
      wrapClassName={'modal'}
      maskStyle={{ zIndex: 1100 }}
      okButtonProps={{ disabled: okDisabled }}
      onOk={handleOk}
      width={600}
    >
      <Form
        form={form}
        layout="vertical"
        onValuesChange={() => onValuesChange()}
        requiredMark={false}
        initialValues={{
          ...editRecord?.record,
          ...getDateInitialValue(editRecord?.record as IDateRecord),
        }}
      >
        {getComponent(editRecord)}
      </Form>
    </Modal>
  );
};

export default EditContactRecordModal;
