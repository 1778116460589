import React, { FC, useEffect, useState } from 'react';
import {
  ConversationFilterParamKeys,
  ConversationReviewStatusParamOptions,
  ConversationThreadControlParamOptions,
  getConversationFiltersParams,
  getThreadControlByReviewStatus,
} from './conversation-filters-helpers';
import { FormInstance, Radio, RadioChangeEvent } from 'antd';
import { useSearchParams } from 'react-router-dom';

const getShortcutDefaultValue = (
  searchParams: URLSearchParams,
  itemsPerPage: number
) => {
  const filterParams = getConversationFiltersParams(searchParams, itemsPerPage);
  const reviewStatus = filterParams.reviewStatus?.value;
  return reviewStatus || ConversationReviewStatusParamOptions.ALL;
};

interface IConversationReviewStatusShortcutsProps {
  filterForm: FormInstance;
  itemsPerPage: number;
}

const ConversationReviewStatusShortcuts: FC<
  IConversationReviewStatusShortcutsProps
> = ({ filterForm, itemsPerPage }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [shortcutValue, setShortcutValue] =
    useState<ConversationReviewStatusParamOptions>(
      getShortcutDefaultValue(searchParams, itemsPerPage)
    );

  useEffect(() => {
    const reviewStatus = searchParams.get(
      ConversationFilterParamKeys.REVIEW_STATUS
    );

    if (reviewStatus) {
      setShortcutValue(reviewStatus as ConversationReviewStatusParamOptions);
    } else {
      setShortcutValue(ConversationReviewStatusParamOptions.ALL);
    }
  }, [searchParams]);

  const getFilterParamsByShortcut = (
    value: ConversationReviewStatusParamOptions
  ) => {
    const threadDefValue =
      filterForm.getFieldValue('threadControl') ||
      ConversationThreadControlParamOptions.ALL;

    const currentThreadControlValue =
      value !== ConversationReviewStatusParamOptions.ALL
        ? threadDefValue
        : ConversationThreadControlParamOptions.ALL;

    const params: {
      reviewStatus: { key: string; value: string };
      threadControl: {
        key: ConversationFilterParamKeys.THREAD_CONTROL;
        value: ConversationThreadControlParamOptions;
      };
    } = {
      reviewStatus: { key: ConversationFilterParamKeys.REVIEW_STATUS, value },
      threadControl: getThreadControlByReviewStatus(
        value,
        currentThreadControlValue
      ),
    };

    return params;
  };

  const handleChange = (e: RadioChangeEvent) => {
    const value = e.target.value;
    const params = getFilterParamsByShortcut(value);
    const newSearchParams: { [key: string]: string } = {};

    for (const key in params) {
      const param = params[key as keyof typeof params];
      newSearchParams[param.key] = param.value;
    }

    setSearchParams(newSearchParams);

    filterForm.setFieldValue('reviewStatus', value);
    filterForm.setFieldValue('threadControl', params.threadControl?.value);
  };

  const options = [
    {
      value: ConversationReviewStatusParamOptions.ALL,
      label: 'All',
    },
    {
      value: ConversationReviewStatusParamOptions.FOR_REVIEW,
      label: 'For Review',
    },
    {
      value: ConversationReviewStatusParamOptions.UNREAD,
      label: 'Unread',
    },
  ];

  return (
    <div
      style={{
        marginBottom: 20,
      }}
    >
      <Radio.Group
        options={options}
        onChange={handleChange}
        optionType="button"
        value={shortcutValue}
      />
    </div>
  );
};

export default ConversationReviewStatusShortcuts;
