import React, { FC, useEffect, useState } from 'react';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { pluginsAPI } from '../../../services/plugins-service';
import { useParams } from 'react-router-dom';

interface ICommandsHelperProps {
  projectId: string;
  children: (
    commandList: ICommandList[],
    getCommandLabel: IGetCommandLabel
  ) => React.ReactElement;
}

export interface ICommandList {
  command: string;
  pluginName: string;
  commandName: string;
}

export interface IGetCommandLabel {
  (pluginName: string, commandName: string): string;
}

const CommandsHelper: FC<ICommandsHelperProps> = ({ children, projectId }) => {
  const { orgId } = useParams();
  const { plugins } = useAppSelector((state) => state.pluginsReducer);
  const { data: projectPlugins } = pluginsAPI.useGetProjectPluginsQuery({
    id: projectId,
    orgId,
  });
  const [commandList, setCommandList] = useState<ICommandList[]>([]);

  useEffect(() => {
    if (!projectPlugins) return;
    const updatedCommandsList: ICommandList[] = [];

    for (let i = 0; i < projectPlugins.length; i++) {
      const pluginId = projectPlugins[i].pluginId;
      const plugin = plugins.find((plugin) => plugin.id === pluginId);
      if (plugin) {
        const commands = plugin.commands;
        if (!commands) continue;
        commands.forEach((command) => {
          const commandType = command.split('(')[0];
          const commandInfo = commandType.split('.');
          const pluginName = commandInfo[0]?.replaceAll('_', ' ') || '';
          const commandName = commandInfo[1]?.replaceAll('_', ' ') || '';
          updatedCommandsList.push({
            command: commandType,
            pluginName,
            commandName,
          });
        });
      }
    }

    setCommandList(updatedCommandsList);
  }, [projectPlugins, plugins]);

  const getCommandLabel: IGetCommandLabel = (pluginName, commandName) => {
    let result = '';

    if (pluginName) {
      result += pluginName;
    }
    if (commandName) {
      result += ` / ${commandName}`;
    }

    return result;
  };

  return children(commandList, getCommandLabel);
};

export default CommandsHelper;
