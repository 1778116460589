import React, { Dispatch, FC, SetStateAction } from 'react';
import { Modal } from 'antd';
import { CreateProjectDescriptionModalTypes } from '../../../shared/constants';
import { useAppSelector } from '../../../hooks/redux-hooks';

interface IRolePersonalityProjectDescriptionModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  type: CreateProjectDescriptionModalTypes;
}

const RolePersonalityProjectDescriptionModal: FC<
  IRolePersonalityProjectDescriptionModal
> = ({ isOpen, setIsOpen, type }) => {
  const { options: projectOptions } = useAppSelector(
    (state) => state.projectReducer
  );

  const handleOk = () => {
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      width={640}
      title={
        type === CreateProjectDescriptionModalTypes.ROLES
          ? 'Roles of chatbot'
          : 'Personalities of chatbot'
      }
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      {projectOptions ? (
        <>
          {type === CreateProjectDescriptionModalTypes.ROLES
            ? projectOptions.roles.map((role) => (
                <div key={role.id}>
                  <b>{role.name}:</b> {role.description}
                </div>
              ))
            : projectOptions.personalities.map((personality) => (
                <div key={personality.id}>
                  <b>{personality.name}:</b> {personality.description}
                </div>
              ))}
        </>
      ) : (
        "Can't get project options, try again later."
      )}
    </Modal>
  );
};

export default RolePersonalityProjectDescriptionModal;
