import { getDomain } from '../../shared/helpers';
const salt = `__${getDomain()}`;

class LocalStorageService {
  static OUTBOUND = `zpr_outbound`;

  static saveData(key: string, data: any) {
    localStorage.setItem(`${salt}_${key}`, JSON.stringify(data));
  }

  static getData(key: string) {
    const data = localStorage.getItem(`${salt}_${key}`);
    return data ? JSON.parse(data) : null;
  }

  static clearAll() {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(salt)) {
        localStorage.removeItem(key);
      }
    });
  }
}

export default LocalStorageService;
