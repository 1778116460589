import React, { FC } from 'react';
import { Button, Tooltip } from 'antd';
import { DislikeOutlined, LikeOutlined, ReadOutlined } from '@ant-design/icons';
import {
  ConversationMessageTypes,
  MessageRatingTypes,
} from '../../../shared/constants';
import IConversationMessage, {
  IConversationMessageItem,
} from '../../../types/IConversationMessage';
import {
  getConversationMessageItemText,
  getFormattedMessageDate,
} from './conversation-helpers';
import { IInsightMessage } from '../../../types/IInsight';
import JsonView from '@uiw/react-json-view';
// @ts-ignore
import { githubDarkTheme } from '@uiw/react-json-view/githubDark';
import ConversationAvatar from '../../conversation-avatar/conversation-avatar';

interface IConversationAiMessageProps {
  messages: IConversationMessage[];
  handleTeachModel: (item: IConversationMessageItem) => void;
  shareMode?: boolean;
}

const ConversationAiMessage: FC<IConversationAiMessageProps> = ({
  messages,
  handleTeachModel,
  shareMode,
}) => {
  const getMsgChainClassNameForItem = (
    currentItem: IConversationMessage,
    index: number
  ) => {
    const typeOfCurrentItem = currentItem.items[0]?.$type;
    if (
      typeOfCurrentItem === ConversationMessageTypes.COMMAND ||
      typeOfCurrentItem === ConversationMessageTypes.ERROR ||
      typeOfCurrentItem === ConversationMessageTypes.HANDSOFF ||
      typeOfCurrentItem === ConversationMessageTypes.HANDSON ||
      typeOfCurrentItem === ConversationMessageTypes.OPTOUT
    ) {
      return 'conversation--command-msg';
    }

    const findFirstMsgIndex = () => {
      const firstMsg = messages.find(
        (item) => item.items[0]?.$type === ConversationMessageTypes.TEXT
      );
      if (firstMsg) {
        return messages.indexOf(firstMsg);
      }

      return null;
    };

    const findLastMsgIndex = () => {
      const lastMsg = messages
        .slice()
        .reverse()
        .find((item) => item.items[0]?.$type === ConversationMessageTypes.TEXT);
      if (lastMsg) {
        return messages.indexOf(lastMsg);
      }

      return null;
    };

    const isFirstMsg = findFirstMsgIndex() === index;
    const isLastMsg = findLastMsgIndex() === index;

    if (isFirstMsg && isLastMsg) {
      return 'conversation-message-chain--single-item';
    }

    if (isFirstMsg) {
      return 'conversation-message-chain--first-item';
    } else if (isLastMsg) {
      return 'conversation-message-chain--last-item';
    } else {
      return 'conversation-message-chain--middle-item';
    }
  };

  return (
    <>
      {messages.map((item, index) => {
        return (
          <ConversationAiMessageItem
            key={index}
            index={index}
            message={item}
            totalMessages={messages.length}
            itemMsgChainClassName={getMsgChainClassNameForItem(item, index)}
            handleTeachModel={handleTeachModel}
            shareMode={shareMode}
          />
        );
      })}
    </>
  );
};

interface IConversationAiMessageItemProps {
  message: IConversationMessage | IInsightMessage;
  index: number;
  totalMessages: number;
  itemMsgChainClassName: string;
  handleTeachModel: (item: IConversationMessageItem) => void;
  shareMode?: boolean;
}

export const ConversationAiMessageItem: FC<IConversationAiMessageItemProps> = ({
  message,
  index,
  totalMessages,
  itemMsgChainClassName,
  handleTeachModel,
  shareMode,
}) => {
  const getMsgChainClassName = (index: number, totalMessages: number) => {
    if (index === 0 && totalMessages !== 1) {
      return 'conversation-message-chain--first';
    } else if (index === totalMessages - 1) {
      return 'conversation-message-chain--last';
    } else {
      return 'conversation-message-chain--middle';
    }
  };

  return (
    <div
      className={`insight-ai-message-container ${getMsgChainClassName(
        index,
        totalMessages
      )}`}
    >
      <ConversationAvatar />

      {message.items.map((item, index) => {
        const isCommand = item.$type === ConversationMessageTypes.COMMAND;

        const messageItem = () => (
          <div className={'conversation-message-container'} key={index}>
            <div
              className={`insight-ai-message ${itemMsgChainClassName} conversation-message-text`}
            >
              {getConversationMessageItemText(item)}
            </div>
            {message.createAt && (
              <div className={'insight-date conversation-message-date-time'}>
                {getFormattedMessageDate(message.createAt)}
              </div>
            )}
          </div>
        );

        if (isCommand) {
          const commandResult = item.result;
          let resultData = null;
          let extraInformationToAssistant = null;

          if (commandResult) {
            let resultJson = JSON.parse(commandResult);
            // console.log(resultJson)

            //TODO: Delete at some point in the summer.
            //START
            if (resultJson.data) {
              resultData = resultJson.data;
              if (resultData && resultData.extra_information_to_assistant) {
                delete resultData.extra_information_to_assistant;
              }
            }
            //END
            else if (resultJson.result) {
              try {
                resultData = JSON.parse(resultJson.result);
              } catch {
                resultData = resultJson.result;
              }
            } else {
              resultData = resultJson.error;
            }

            if (resultJson.extraInformationToAssistant) {
              extraInformationToAssistant =
                resultJson.extraInformationToAssistant;
            }
          }

          let args = null;

          if (item.args) {
            try {
              args = JSON.parse(item.args);
            } catch (e) {
              args = item.args;
            }
          }

          let rawResponse = null;

          if (commandResult) {
            try {
              rawResponse = JSON.parse(commandResult);
            } catch (e) {
              rawResponse = commandResult;
            }
          }

          //const args = item.args != null ? JSON.parse(item.args) : null;

          const info: any = {
            name: item.name,
            args: args,
            resultData: resultData,
            rawResponse: item,
          };

          if (extraInformationToAssistant) {
            info.extraInformationToAssistant = extraInformationToAssistant;
          }

          return (
            <div
              style={{ display: 'flex', gap: 10, flexWrap: 'wrap', rowGap: 16 }}
              key={index}
            >
              <Tooltip
                placement="topLeft"
                overlayStyle={{
                  marginLeft: 20,
                  maxWidth: 500,
                  width: 'auto',
                  marginRight: 10,
                  zIndex: 1500,
                }}
                title={
                  <>
                    <div>
                      Name:{' '}
                      <span style={{ color: '#79c0ff' }}>{item.name}</span>
                    </div>
                    {/*<div>Args: {JSON.stringify(args)}</div>*/}
                    <div style={{ marginTop: 10 }}>Args:</div>
                    <JsonView
                      value={args}
                      style={{ ...githubDarkTheme, backgroundColor: '#000000' }}
                      displayDataTypes={false}
                      enableClipboard={false}
                    />
                    <div style={{ marginTop: 10 }}>Response:</div>
                    <JsonView
                      // value={info}
                      value={rawResponse}
                      style={{ ...githubDarkTheme, backgroundColor: '#000000' }}
                      displayDataTypes={false}
                      enableClipboard={false}
                    />
                  </>
                }
                color={'#000000'}
                overlayInnerStyle={{
                  color: '#e8e8e8',
                  fontSize: 12,
                  fontFamily: 'monospace',
                }}
                key={index}
              >
                {messageItem()}
              </Tooltip>
              {shareMode ? null : (
                <Button
                  icon={<ReadOutlined />}
                  type="dashed"
                  onClick={() => handleTeachModel(item)}
                >
                  Teach Model
                </Button>
              )}
            </div>
          );
        }

        return messageItem();
      })}
      <div className="conversation-msg-react">
        {message.react === MessageRatingTypes.POSITIVE ? (
          <LikeOutlined />
        ) : message.react === MessageRatingTypes.NEGATIVE ? (
          <DislikeOutlined />
        ) : null}
      </div>
    </div>
  );
};

export default ConversationAiMessage;
