import React, { FC, useEffect } from 'react';
import {
  Button,
  Form,
  FormInstance,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import './upload-image-item.scss';
import { LogoMode } from '../../../shared/constants';

interface IUploadImageItem {
  label: string;
  name: string;
  form: FormInstance;
  handleChange: () => void;
  accept: string;
  mode: LogoMode;
  tooltip?: string;
}

const UploadImageItem: FC<IUploadImageItem> = ({
  label,
  name,
  form,
  handleChange,
  accept,
  mode,
  tooltip,
}) => {
  const [image, setImage] = React.useState<UploadFile | null>(null);

  useEffect(() => {
    form.setFieldValue(name, image ? [image] : []);
    handleChange();
  }, [image]);

  const props: UploadProps = {
    onRemove: (file) => {
      setImage(null);
    },
    beforeUpload: (file) => {
      setImage(file);

      return false;
    },
    accept: accept,
    fileList: image ? [image] : [],
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e.slice(-1);
    }
    return e?.fileList ? e.fileList.slice(-1) : [];
  };

  return (
    <Form.Item
      label={label}
      name={name}
      tooltip={tooltip}
      valuePropName="fileList"
      getValueFromEvent={normFile}
      style={{ width: '100%' }}
    >
      <Upload
        {...props}
        name="logo"
        listType="picture"
        className={mode === LogoMode.LIGHT ? 'upload-logo-preview--light' : ''}
      >
        <Button icon={<UploadOutlined />}>Click to upload</Button>
      </Upload>
    </Form.Item>
  );
};

export default UploadImageItem;
