import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import './usage-setting.scss';
import { Button, Spin } from 'antd';
import { subscriptionAPI } from '../../../services/subscription-service';
import { useAppSelector } from '../../../hooks/redux-hooks';
import {
  ProfileSettingsType,
  SubscriptionPlanTypes,
} from '../../../shared/constants';
import { formatNumber } from '../../../shared/helpers';
import { IChartsSettings } from '../../charts/chart-data';
import MonthPicker from '../../charts/month-picker';
import UsagePerDayChart from './usage-per-day-chart';
import UsagePerProjectChart from './usage-per-project-chart';

interface IUsageSetting {
  setSettingsType: Dispatch<SetStateAction<ProfileSettingsType>>;
}

const UsageSetting: FC<IUsageSetting> = ({ setSettingsType }) => {
  const { data, error, isLoading } = subscriptionAPI.useGetUsageQuery(false);
  const { currentUserSubscription } = useAppSelector(
    (state) => state.subscriptionReducer
  );
  const initialUsageSettings = { month: new Date() };
  const [chartSettings, setChartSettings] =
    useState<IChartsSettings>(initialUsageSettings);
  const { allOrganizationsInfo } = useAppSelector(
    (state) => state.organizationsReducer
  );
  const { user } = useAppSelector((state) => state.userReducer);

  const getPersonalUsage = () => {
    const personalOrg = allOrganizationsInfo.find(
      (org) => org.isPersonal && org?.owner?.id === user?.id
    );

    if (personalOrg) {
      return (
        <>
          <UsagePerDayChart
            settings={chartSettings}
            organizationId={personalOrg.id}
          />
          <UsagePerProjectChart
            settings={chartSettings}
            organizationId={personalOrg.id}
          />
        </>
      );
    }

    return null;
  };

  const usage = () => {
    if (!data) return null;

    if (data.totalTokens <= 0) {
      return (
        <div
          className="usage-setting-content__info-item"
          hidden={false}
          style={{
            fontSize: '16px',
          }}
        >
          <div className="usage-setting-content__info-item-title">
            Credits available:
          </div>
          <div
            className="usage-setting-content__info-progress-stats"
            style={{
              fontSize: '16px',
            }}
          >
            {formatNumber(data.extraCredits)}
          </div>
        </div>
      );
    }

    return (
      <>
        {
          <>
            <div className="usage-setting-content">
              <div className="usage-setting-content__info">
                <div className="usage-setting-content__info-item">
                  <div className="usage-setting-content__info-item-title">
                    Total credits:
                  </div>
                  <div className="usage-setting-content__info-progress-stats">
                    {formatNumber(data.totalTokens)}
                  </div>
                </div>
                <div className="usage-setting-content__info-item">
                  <div className="usage-setting-content__info-item-title">
                    Credits left:
                  </div>
                  <div className="usage-setting-content__info-progress-stats">
                    {formatNumber(data.creditsLeft)}
                  </div>
                </div>
                {data.extraCredits !== 0 && (
                  <div
                    className="usage-setting-content__info-item"
                    hidden={false}
                  >
                    <div className="usage-setting-content__info-item-title">
                      Extra credits:
                    </div>
                    <div className="usage-setting-content__info-progress-stats">
                      {formatNumber(data.extraCredits)}
                    </div>
                  </div>
                )}
                <div className="usage-setting-content__info-item">
                  <div className="usage-setting-content__info-item-title">
                    Days until renewal:
                  </div>
                  <div className="usage-setting-content__info-progress-stats">
                    {formatNumber(data.daysUntilRenewal)}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="profile-settings-form-btn-container"
              style={{ justifyContent: 'flex-start' }}
            >
              {currentUserSubscription &&
              currentUserSubscription?.currentPlan.type ===
                SubscriptionPlanTypes.ENTERPRISE ? null : (
                <Button
                  type="primary"
                  onClick={() => setSettingsType(ProfileSettingsType.PLAN)}
                >
                  Upgrade Plan
                </Button>
              )}
            </div>
          </>
        }
      </>
    );
  };

  const renderHeader = () => {
    if (data && data.totalTokens <= 0) {
      return null;
    }

    return (
      <div className="usage-setting-title">You have used in your plan</div>
    );
  };

  const onMonthSelected = (date: Date) => {
    setChartSettings({ month: date });
  };

  return (
    <div style={{ width: '100%', height: '600px' }}>
      <div className="usage-setting profile-setting-content-container">
        {renderHeader()}
        {isLoading ? (
          <div className="usage-setting-content">
            <Spin size="large" tip="Loading..." />
          </div>
        ) : error ? (
          <div>Data is not available now.</div>
        ) : data ? (
          <>{usage()}</>
        ) : (
          <div>Data is not available now.</div>
        )}
      </div>
      <div className={'usage'} style={{ marginTop: 40 }}>
        <MonthPicker
          onDateChange={onMonthSelected}
          minDate={new Date('2024-01-01')}
          maxDate={new Date(Date.now())}
        />
        {getPersonalUsage()}
      </div>
    </div>
  );
};

export default UsageSetting;
