import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Form,
  FormInstance,
  Input,
  Popconfirm,
  Radio,
  Select,
  UploadFile,
} from 'antd';
import {
  widgetColorOptions,
  WidgetThemeModes,
  widgetThemeOptions,
} from '../../../../../shared/constants';
import { useAppSelector } from '../../../../../hooks/redux-hooks';
import FakeWidget from './fake-widget/fake-widget';
import {
  generateWidgetStyleByTheme,
  IGeneratedWidgetStyle,
  WidgetModes,
} from './fake-widget/fake-widget-helpers';
import IProjectStartInfo from '../../../../../types/IProjectStartInfo';
import IProject from '../../../../../types/IProject';
import './appearance-settings.scss';
import BubbleSettingsForm from './bubble-settings-form';
import { DeleteOutlined } from '@ant-design/icons';
import UploadProjectImg from '../../../../upload-project-img/upload-project-img';
import { showErrorMessage } from '../../../../../shared/helpers';
import { projectsAPI } from '../../../../../services/projects-service';
import { MessageInstance } from 'antd/es/message/interface';
import { BUBBLE_DEFAULT_MESSAGE } from './fake-widget/fake-widget-components/bubble/bubble';

interface IAppearanceSettingsProps {
  userProject: IProject;
  projectStartInfo: IProjectStartInfo;
  appearanceSettingsForm: FormInstance;
  bubbleSettingsForm: FormInstance;
  handleChange: () => void;
  messageApi: MessageInstance;
  image: UploadFile | null;
  setImage: React.Dispatch<React.SetStateAction<UploadFile | null>>;
}

const AppearanceSettings: FC<IAppearanceSettingsProps> = ({
  userProject,
  projectStartInfo,
  appearanceSettingsForm,
  bubbleSettingsForm,
  handleChange,
  messageApi,
  image,
  setImage,
}) => {
  const { subscriptionIsActive } = useAppSelector(
    (state) => state.subscriptionReducer
  );
  const [deleteProjectImage, {}] = projectsAPI.useDeleteProjectImageMutation();
  const [theme, setTheme] = useState<WidgetThemeModes>(
    projectStartInfo.widgetSettings.theme || WidgetThemeModes.DARK
  );
  const [color, setColor] = useState<string>(
    projectStartInfo.widgetSettings.color || '#0a84ff'
  );
  const [allowMicrophone, setAllowMicrophone] = useState(
    projectStartInfo.widgetSettings.enableMicrophone || false
  );
  const [widgetMode, setWidgetMode] = useState<WidgetModes>(WidgetModes.FULL);
  const [generatedWidgetStyle, setGeneratedWidgetStyle] =
    React.useState<IGeneratedWidgetStyle>(
      generateWidgetStyleByTheme(
        {
          mode: projectStartInfo.widgetSettings.theme || WidgetThemeModes.DARK,
          color: projectStartInfo.widgetSettings.color || '#0a84ff',
        },
        1
      )
    );
  const [agentName, setAgentName] = useState<string>(
    projectStartInfo.widgetSettings.agentName || ''
  );
  const [userLabel, setUserLabel] = useState<string>(
    projectStartInfo.widgetSettings.userLabel || ''
  );
  const [placeholder, setPlaceholder] = useState<string>(
    projectStartInfo.widgetSettings.placeHolder || ''
  );
  const [subTitle, setSubTitle] = useState<string>(
    projectStartInfo.subTitle || ''
  );
  const [title, setTitle] = useState<string>(
    projectStartInfo.widgetSettings.title || projectStartInfo.name
  );
  const [allowFileAttachments, setAllowFileAttachments] = useState<boolean>(
    projectStartInfo.widgetSettings.fileAttachmentEnabled || false
  );
  const shouldShowBubbleByDefault = () => {
    if (!projectStartInfo.widgetSettings.speechBubble) return true;
    return projectStartInfo.widgetSettings.speechBubble.isEnabled;
  };
  const [showBubble, setShowBubble] = useState(shouldShowBubbleByDefault());
  const getDefaultBubbleMessage = () => {
    if (!projectStartInfo.widgetSettings.speechBubble)
      return BUBBLE_DEFAULT_MESSAGE;
    const bubbleItems = projectStartInfo.widgetSettings.speechBubble.items;
    if (!bubbleItems || bubbleItems.length === 0) return BUBBLE_DEFAULT_MESSAGE;
    return bubbleItems[0].text || BUBBLE_DEFAULT_MESSAGE;
  };
  const [bubbleMessage, setBubbleMessage] = useState(getDefaultBubbleMessage());

  useEffect(() => {
    const generatedStyle = generateWidgetStyleByTheme(
      {
        mode: theme,
        color: color,
      },
      1
    );

    setGeneratedWidgetStyle(generatedStyle);
  }, [theme, color]);

  const onThemeChange = (value: string) => {
    setTheme(value as WidgetThemeModes);
    handleChange();
  };

  const onColorChange = (value: string) => {
    setColor(value);
    handleChange();
  };

  const themeFormItem = () => {
    return (
      <Form.Item label="Theme" name="theme" style={{ paddingTop: 3 }}>
        <Select
          disabled={!subscriptionIsActive}
          options={widgetThemeOptions.map((theme) => ({
            value: theme.value,
            label: theme.label,
          }))}
          onChange={onThemeChange}
        />
      </Form.Item>
    );
  };

  const colorFormItem = () => {
    return (
      <Form.Item label="Color" name="color">
        <Select
          disabled={!subscriptionIsActive}
          options={widgetColorOptions.map((color) => ({
            value: color.value,
            label: (
              <div className={'color-select-label'}>
                <div
                  style={{
                    width: 10,
                    height: 10,
                    backgroundColor: color.value,
                    borderRadius: 2,
                  }}
                ></div>
                {color.label}
              </div>
            ),
          }))}
          onChange={onColorChange}
        />
      </Form.Item>
    );
  };

  const textInputFormItem = (
    label: string,
    placeholder: string,
    fieldName: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  ) => {
    return (
      <Form.Item label={label} name={fieldName}>
        <Input
          placeholder={placeholder}
          disabled={!subscriptionIsActive}
          onChange={onChange}
        />
      </Form.Item>
    );
  };

  const allowMicrophoneFormItem = () => {
    return (
      <Form.Item name={'enableMicrophone'} valuePropName="checked">
        <Checkbox onChange={(e) => setAllowMicrophone(e.target.checked)}>
          Allow microphone
        </Checkbox>
      </Form.Item>
    );
  };

  const allowFileAttachmentsFormItem = () => {
    return (
      <Form.Item name={'fileAttachmentEnabled'} valuePropName="checked">
        <Checkbox onChange={(e) => setAllowFileAttachments(e.target.checked)}>
          Allow file attachments
        </Checkbox>
      </Form.Item>
    );
  };

  const confirmDeleteImage = async () => {
    messageApi.info('Deleting...', 1);

    const result = await deleteProjectImage(userProject.id);

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
    } else {
      messageApi.success('Project image has been deleted.');
    }
  };

  const botImageFormItem = () => {
    return (
      <div style={{ display: 'flex', gap: 10 }}>
        <Form.Item label="Bot Image (optional)" name="file">
          <div style={{ display: 'flex', gap: 10 }}>
            <UploadProjectImg
              image={image}
              setImage={setImage}
              handleChange={handleChange}
            />
            {userProject.logoFileName && (
              <Popconfirm
                title="Delete the image"
                description="Are you sure you want to delete the project image?"
                onConfirm={confirmDeleteImage}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  disabled={!subscriptionIsActive}
                >
                  Remove Image
                </Button>
              </Popconfirm>
            )}
          </div>
        </Form.Item>
      </div>
    );
  };

  const getAppearanceSettingsForm = () => {
    return (
      <div className={'appearance-settings-form-container'}>
        <div className={'appearance-settings-form-container__item'}>
          <Form
            form={appearanceSettingsForm}
            layout="vertical"
            className="settings-form appearance-settings-form"
            onChange={handleChange}
            requiredMark={false}
          >
            <div className="settings-form-part">
              {themeFormItem()}
              {colorFormItem()}
              {textInputFormItem(
                'Title',
                projectStartInfo.name,
                'title',
                (e) => {
                  const title = e.target.value || projectStartInfo.name;
                  setTitle(title);
                }
              )}
              {textInputFormItem(
                'Subtitle (optional)',
                'Enter subtitle',
                'subTitle',
                (e) => {
                  setSubTitle(e.target.value);
                }
              )}
              {textInputFormItem(
                'Agent name',
                userProject.name,
                'agentName',
                (e) => {
                  setAgentName(e.target.value);
                }
              )}
              {textInputFormItem('User label', 'You', 'userLabel', (e) => {
                setUserLabel(e.target.value);
              })}
              {textInputFormItem(
                'Placeholder',
                'Ask me...',
                'placeHolder',
                (e) => {
                  setPlaceholder(e.target.value);
                }
              )}
              {botImageFormItem()}
              {allowMicrophoneFormItem()}
              {allowFileAttachmentsFormItem()}
            </div>
          </Form>
          <BubbleSettingsForm
            bubbleSettingsForm={bubbleSettingsForm}
            handleChange={handleChange}
            setShowBubble={setShowBubble}
            setBubbleMessage={setBubbleMessage}
          />
        </div>

        <div
          className={
            'appearance-settings-form-container__item appearance-settings-form-container__item--widget-preview'
          }
        >
          <div style={{ textAlign: 'center', marginBottom: 24 }}>
            <Radio.Group
              defaultValue={WidgetModes.FULL}
              size="small"
              onChange={(e) => setWidgetMode(e.target.value)}
            >
              <Radio.Button value={WidgetModes.FULL} style={{ width: 80 }}>
                Full
              </Radio.Button>
              <Radio.Button value={WidgetModes.MINI_CHAT} style={{ width: 80 }}>
                Mini-chat
              </Radio.Button>
              <Radio.Button
                value={WidgetModes.FLOAT_BUTTON}
                style={{ width: 80 }}
              >
                Button
              </Radio.Button>
            </Radio.Group>
          </div>
          <FakeWidget
            projectStartInfo={projectStartInfo}
            generatedWidgetStyle={generatedWidgetStyle}
            title={title}
            subTitle={subTitle}
            agentName={agentName}
            userLabel={userLabel}
            placeholder={placeholder}
            image={image}
            allowMicrophone={allowMicrophone}
            widgetMode={widgetMode}
            showBubble={showBubble}
            bubbleMessage={bubbleMessage}
            allowFileAttachments={allowFileAttachments}
          />
        </div>
      </div>
    );
  };

  return <div>{getAppearanceSettingsForm()}</div>;
};

export default AppearanceSettings;
