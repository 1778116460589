import React, { FC } from 'react';
import { Divider } from 'antd';
import TextInputFormItem from './form-items/text-input-form-item';

const CompanyRecordFormElement: FC = () => {
  return (
    <>
      <Divider orientation="left" className={'contact-form-divider'}>
        Company
      </Divider>
      <TextInputFormItem
        label={'Company Name'}
        name={'companyName'}
        placeholder={'Company Name'}
      />
      <TextInputFormItem
        label={'Job Title'}
        name={'jobTitle'}
        placeholder={'Job Title'}
      />
    </>
  );
};

export default CompanyRecordFormElement;
