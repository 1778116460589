import React, { FC, useEffect } from 'react';
import {
  ApiOutlined,
  CreditCardOutlined,
  PieChartOutlined,
  UnlockOutlined,
  UserOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import './profile-settings-menu.scss';
import {
  DEFAULT_ZAPPR_DOMAIN,
  ProfileSettingsType,
} from '../../shared/constants';
import { useNavigate } from 'react-router-dom';
import { RouteNames } from '../../router/router';
import { useAppSelector } from '../../hooks/redux-hooks';
import { MdDomain } from 'react-icons/md';

interface IMenuItem {
  icon: JSX.Element | null;
  title: string | JSX.Element;
  type: ProfileSettingsType;
}

const domainTenantMemberMenuItems: IMenuItem[] = [
  {
    icon: <UserOutlined />,
    title: 'Account',
    type: ProfileSettingsType.ACCOUNT,
  },
  {
    icon: <UnlockOutlined />,
    title: 'Password',
    type: ProfileSettingsType.PASSWORD,
  },
];

const defaultMenuItems: IMenuItem[] = [
  ...domainTenantMemberMenuItems,
  {
    icon: <PieChartOutlined />,
    title: 'Usage',
    type: ProfileSettingsType.USAGE,
  },
  {
    icon: <WalletOutlined />,
    title: 'Purchase Credits',
    type: ProfileSettingsType.BUY_CREDITS,
  },
  {
    icon: <CreditCardOutlined />,
    title: 'Plan',
    type: ProfileSettingsType.PLAN,
  },
  {
    icon: <ApiOutlined />,
    title: 'API Key',
    type: ProfileSettingsType.API_KEY,
  },
];

const additionalMenuItems: IMenuItem[] = [
  {
    icon: <MdDomain />,
    title: 'Domains',
    type: ProfileSettingsType.DOMAINS,
  },
];

const ProfileSettingsMenu: FC<{
  settingsType: ProfileSettingsType;
}> = ({ settingsType }) => {
  const { isDomainTenantMember } = useAppSelector((state) => state.userReducer);
  const navigate = useNavigate();
  const [menuItems, setMenuItems] = React.useState<IMenuItem[]>(
    domainTenantMemberMenuItems
  );

  useEffect(() => {
    if (isDomainTenantMember) {
      setMenuItems(domainTenantMemberMenuItems);
    } else {
      const items =
        process.env.REACT_APP_DOMAIN === DEFAULT_ZAPPR_DOMAIN
          ? [...defaultMenuItems, ...additionalMenuItems]
          : [...defaultMenuItems];
      setMenuItems(items);
    }
  }, [isDomainTenantMember]);

  return (
    <div className="profile-settings-menu profile-settings-menu--ps">
      {menuItems.map(({ icon, title, type }, i) => (
        <div
          className={`profile-settings-menu__item ${
            settingsType === type ? 'profile-settings-menu__item--active' : ''
          }`}
          key={i}
          onClick={() => {
            navigate(`${RouteNames.PROFILE_SETTINGS_NO_PARAMS}/${type}`);
          }}
        >
          {icon ? (
            <div className="profile-settings-menu-icon">{icon}</div>
          ) : null}
          <div className="profile-settings-menu-title">{title}</div>
        </div>
      ))}
    </div>
  );
};

export default ProfileSettingsMenu;
