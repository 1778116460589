import IAddressRecord from './contact-records/IAddressRecord';
import { ICompanyRecord } from './contact-records/ICompanyRecord';
import ICustomFieldRecord from './contact-records/ICustomFieldRecord';
import IDateRecord from './contact-records/IDateRecord';
import IEmailRecord from './contact-records/IEmailRecord';
import IGeolocationRecord from './contact-records/IGeolocationRecord';
import ILanguageRecord from './contact-records/ILanguageRecord';
import ILinkedContactRecord from './contact-records/ILinkedContactRecord';
import INoteRecord from './contact-records/INoteRecord';
import { IPhoneRecord } from './contact-records/IPhoneRecord';
import ISocialRecord from './contact-records/ISocialRecord';
import ISystemInfoRecord from './contact-records/ISystemInfoRecord';
import ITagRecord from './contact-records/ITagRecord';

export default interface IContact {
  id: string;
  type: ContactType;
  source?: string;
  basicInfo: IContactBasicInfo;
  records: TServerContactRecord[];
}

export interface IPostContact {
  type: ContactType;
  source?: string;
  basicInfo: IContactBasicInfo;
  records: IPostContactRecord[];
}

export enum ContactType {
  USER = 'user',
  AGENT = 'agent',
}

export interface IContactBasicInfo {
  firstName?: null | string;
  lastName?: null | string;
  nickname?: null | string;
  middleName?: null | string;
  title?: null | string; // Mr., Mrs., etc.
}

export interface IContactRecordDefaultFields {
  id: string;
  contactId: string;
  type: ContactRecordType;
}

export interface IPostContactRecord {
  type: ContactRecordType;
  record: TContactRecord;
}

export interface IContactRecord
  extends IContactRecordDefaultFields,
    IPostContactRecord {}

export enum ContactRecordType {
  EMAIL = 'email',
  PHONE = 'phone',
  SOCIAL = 'social',
  NOTE = 'note',
  ADDRESS = 'address',
  COMPANY = 'company',
  GEOLOCATION = 'geoLocation',
  // SYSTEM_INFO = 'systemInfo',
  CUSTOM_FIELD = 'customField',
  // LINKED_CONTACT = 'linkedContact',
  DATE = 'date',
  TAG = 'tag',
  LANGUAGE = 'language',
}

export type TContactRecord =
  | IAddressRecord
  | ICompanyRecord
  | ICustomFieldRecord
  | IDateRecord
  | IEmailRecord
  | IGeolocationRecord
  | ILanguageRecord
  // | ILinkedContactRecord
  | INoteRecord
  | IPhoneRecord
  | ISocialRecord
  // | ISystemInfoRecord
  | ITagRecord;
export type TServerContactRecord = IContactRecordDefaultFields &
  (
    | IAddressRecord
    | ICompanyRecord
    | ICustomFieldRecord
    | IDateRecord
    | IEmailRecord
    | IGeolocationRecord
    | ILanguageRecord
    // | ILinkedContactRecord
    | INoteRecord
    | IPhoneRecord
    | ISocialRecord
    // | ISystemInfoRecord
    | ITagRecord
  );
