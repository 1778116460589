import React, { FC, useEffect, useState } from 'react';
import { Button, message, Modal, Result, Skeleton } from 'antd';
import useCreateEmptyTable from '../../../hooks/table-hooks/useCreateEmptyTable';
import TableById from '../../project-settings/tables/table-by-id';

interface ITableCreateModalProps {
  isOpen: boolean;
  closeModal: () => void;
  projectId: string;
}

const TableCreateModal: FC<ITableCreateModalProps> = ({
  isOpen,
  closeModal,
  projectId,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [createEmptyTable, createEmptyTableLoading] = useCreateEmptyTable(
    projectId,
    messageApi
  );
  const [tableId, setTableId] = useState<string>('');
  const [startLoading, setStartLoading] = useState<boolean>(true);
  const [errorCreateTable, setErrorCreateTable] = useState<string>('');

  useEffect(() => {
    if (isOpen) {
      handleCreate();
    } else {
      setTableId('');
      setErrorCreateTable('');
      setStartLoading(true);
    }
  }, [isOpen]);

  const handleCreate = async () => {
    const res = await createEmptyTable();
    if (res) {
      setTableId(res);
    } else {
      messageApi.error('Failed to create table.');
      setErrorCreateTable('Failed to create table.');
    }

    setStartLoading(false);
  };

  const handleCancel = () => {
    closeModal();
  };

  const getComponent = () => {
    if (startLoading || createEmptyTableLoading) {
      return <Skeleton active />;
    }

    if (errorCreateTable) {
      return <Result status="error" subTitle={errorCreateTable} />;
    }

    if (tableId) {
      return <TableById projectId={projectId} tableId={tableId} />;
    }
  };

  return (
    <Modal
      title={`Create table`}
      open={isOpen}
      onCancel={handleCancel}
      footer={null}
      centered={true}
      wrapClassName={'modal'}
      maskStyle={{ zIndex: 1100 }}
      width={896}
    >
      {contextHolder}
      <div style={{ margin: '0 auto' }}>{getComponent()}</div>
    </Modal>
  );
};

export default TableCreateModal;
