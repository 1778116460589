import { FC, useEffect, useState } from 'react';

interface IHighlightPreviewTextProps {
  content: string;
  variables: TemplateVariables[];
}

export interface TemplateVariables {
  id: string;
  displayName: string;
}

const HighlightPreviewText: FC<IHighlightPreviewTextProps> = ({
  content,
  variables,
}) => {
  const [html, setHtml] = useState<string>('');

  useEffect(() => {
    let highlightedHtml = content;

    variables.forEach((d) => {
      highlightedHtml = content.replaceAll(
        `${d.displayName}`,
        `<span style="color: red;">${d.displayName}</span>`
      );
    });

    setHtml(highlightedHtml);
  }, [content, variables]);

  return (
    <div
      style={{
        minHeight: '100px',
        border: '1px solid #ccc',
        padding: '8px',
        color: '#000',
        overflowY: 'auto',
      }}
      contentEditable={false}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

export default HighlightPreviewText;
