import {
  IInsightsQueryResponseRaw,
  IInsightsQueryResult,
  InsightsQueryData,
} from '../../../services/usage-service';
import IProject from '../../../types/IProject';
import { BarData, BarExpenseType } from '../../charts/chart-data';

export default class AnalyticsUtils {
  public static generateBarsDataByProject(
    uniqueProjects: string[],
    userProjects: IProject[] | undefined,
    expenseType: BarExpenseType
  ): BarData[] {
    const barsData: BarData[] = [];

    for (let i = 0; i < uniqueProjects.length; i++) {
      const projectId = uniqueProjects[i];
      const projectName = AnalyticsUtils.getProjectName(
        userProjects,
        projectId
      );

      barsData.push({
        name: projectName,
        dataKey: projectId,
        type: expenseType,
        labelKey:
          i === uniqueProjects.length - 1 ? expenseType + 'Sum' : undefined,
      });
    }
    return barsData;
  }

  public static getProjectName(
    userProjects: IProject[] | undefined,
    projectId: string
  ) {
    const userProject = userProjects?.find((x) => x.id === projectId);
    const projectName = userProject ? userProject.name : projectId;

    return projectName;
  }

  public static generateBarsDataBySource(
    uniqueSources: string[],
    expenseType: BarExpenseType
  ): BarData[] {
    const barsData: BarData[] = [];

    for (let i = 0; i < uniqueSources.length; i++) {
      const src = uniqueSources[i];

      barsData.push({
        name: src,
        dataKey: src,
        type: expenseType,
        labelKey:
          i === uniqueSources.length - 1 ? expenseType + 'Sum' : undefined,
      });
    }

    return barsData;
  }

  public static getTableData(queryResult: IInsightsQueryResult): any[] {
    const queryResponse: IInsightsQueryResponseRaw = JSON.parse(
      queryResult.queryResponse
    );
    const tables: any = {};

    for (let i = 0; i < queryResponse.tables.length; i++) {
      const queryTable = queryResponse.tables[i];

      tables[queryTable.name] = queryTable.rows.map((row) => {
        const obj: any = {};

        queryTable.columns.forEach((col, index) => {
          obj[col.name] = row[index];
        });

        return obj;
      });
    }

    return tables['PrimaryResult'];
  }

  public static getExpenses(tableData: any[]): BarExpenseType[] {
    const hasCost = tableData.some((x) => x['totalCost'] != undefined);
    const expenses = hasCost
      ? [BarExpenseType.Price, BarExpenseType.Cost]
      : [BarExpenseType.Price];

    return expenses;
  }

  public static handleUsageDataByBarsData(
    tableData: any[],
    barsData: BarData[],
    queryResult: IInsightsQueryResult,
    expenseType: BarExpenseType,
    newChartData: any[],
    propertyName: string
  ) {
    const fromDate = new Date(queryResult.from);
    const toDate = new Date(queryResult.to);
    let currentDate = fromDate;

    while (currentDate <= toDate) {
      const day = currentDate.getUTCDate().toString();

      const sumProp = expenseType + 'Sum';
      const expenseEntry: any = { day: day };
      expenseEntry[sumProp] = 0;

      for (let i = 0; i < barsData.length; i++) {
        const barData = barsData[i];
        const record = tableData.find((x) => {
          const utcDate = new Date(
            Date.UTC(
              currentDate.getUTCFullYear(),
              currentDate.getUTCMonth(),
              currentDate.getUTCDate()
            )
          );

          return (
            new Date(x['timestamp']).getTime() == utcDate.getTime() &&
            x[propertyName] === barData.dataKey
          );
        });

        if (record) {
          const value = AnalyticsUtils.getValueByExpenseType(
            expenseType,
            record
          );

          expenseEntry[sumProp] += value;
          expenseEntry[barData.dataKey] = +value.toFixed(2);
          expenseEntry[barData.name] = +value.toFixed(2);
        }
      }

      expenseEntry[sumProp] = +expenseEntry[sumProp].toFixed(2);
      newChartData.push(expenseEntry);

      currentDate.setDate(currentDate.getDate() + 1);
    }
  }

  public static getValueByExpenseType(
    expenseType: BarExpenseType,
    record: any
  ): number {
    let value = 0;

    switch (expenseType) {
      case BarExpenseType.Price:
        value = record['totalPrice'];
        break;
      case BarExpenseType.Cost:
        value = record['totalCost'];
        break;
    }

    return value;
  }

  public static formatDate(date: Date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}.${day}.${year}`;
  }
}
