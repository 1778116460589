import React, { FC } from 'react';
import IProject from '../../types/IProject';
import { Card } from 'antd';
import './user-project-card.scss';
import { PlayCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteNames } from '../../router/router';
import { LogoMode, ProjectSettingsType } from '../../shared/constants';
import SuperellipseMask from '../superellipse/superellipse-mask';
import LogoIcon from '../logo/logo-icon';
import { useAppSelector } from '../../hooks/redux-hooks';
import StorageUtils from '../../shared/utils/storage-utils';

const UserProjectCard: FC<{ project: IProject }> = ({ project }) => {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const { globalTimestamp } = useAppSelector((state) => state.domainsReducer);
  const { filePath } = useAppSelector((state) => state.domainsReducer);
  const imageURL = StorageUtils.getProjectLogo(
    project.id,
    project.logoFileName,
    filePath,
    globalTimestamp
  );

  const getImageProps = () => {
    const imageProps = {
      src: imageURL,
    };

    if (!project.logoFileName) {
      return {
        x: '10%',
        y: '10%',
        width: '80%',
        height: '80%',
        ...imageProps,
      };
    }

    return imageProps;
  };

  return (
    <div className="grid-item-container">
      <Card
        // className={`user-project-card ${!project.isActive ? 'user-project-card--inactive' : ''}`}
        className={`user-project-card`}
      >
        <div className="user-project-card__header">
          <div className="user-project-card__logo">
            <LogoIcon mode={LogoMode.LIGHT} />
          </div>
          <div className="user-project-card__icons">
            <PlayCircleOutlined
              className="user-project-card__icon user-project-card__icon--try-it-out"
              onClick={() =>
                navigate(
                  `${RouteNames.USER_PROJECTS}/${orgId}${RouteNames.DEMO_TAB_NAME}/${project.id}`
                )
              }
            />
            <SettingOutlined
              className="user-project-card__icon"
              onClick={() =>
                navigate(
                  `${RouteNames.USER_PROJECTS}/${orgId}${RouteNames.PROJECT_SETTINGS_TAB_NAME}/${project.id}/${ProjectSettingsType.KNOWLEDGE}`
                )
              }
            />
          </div>
        </div>
        <div className="user-project-card__content">
          <div
            style={{ marginBottom: 24, cursor: 'pointer' }}
            onClick={() =>
              navigate(
                `${RouteNames.USER_PROJECTS}/${orgId}${RouteNames.PROJECT_SETTINGS_TAB_NAME}/${project.id}/${ProjectSettingsType.KNOWLEDGE}`
              )
            }
          >
            <SuperellipseMask
              maskId={'mask1'}
              width={128}
              height={128}
              bgColor={'#000000'}
              borderColor={project.widgetSettings.color || '#ffffff'}
              imageProps={getImageProps()}
              radius={40}
              smoothing={1}
            />
          </div>
          <div className="user-project-card__name">
            <div className="user-project-card__subtitle">
              {project.subTitle || 'Ask'}
            </div>
            <div className="user-project-card__title">{project.name}</div>
          </div>
        </div>
        {/*{project.status === ProjectStatus.CLONING && (*/}
        {!project.isActive && (
          <div className={'project__cloning'}>
            {/*<div className={'project__cloning-info'}>*/}
            {/*  Cloning the project. Please wait...*/}
            {/*</div>*/}
          </div>
        )}
      </Card>
    </div>
  );
};

export default UserProjectCard;
