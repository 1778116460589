import { useEffect, useState } from 'react';
import { LS_CURRENT_ORGANIZATION_ID } from '../shared/constants';
import { useAppSelector } from './redux-hooks';

export default function useHasAccessToOrg(orgId: string | undefined) {
  const { user } = useAppSelector((state) => state.userReducer);
  const [noAccess, setNoAccess] = useState(false);
  const { allOrganizationsInfo } = useAppSelector(
    (state) => state.organizationsReducer
  );

  useEffect(() => {
    if (!orgId || !user || !allOrganizationsInfo.length) return;

    const orgIds = allOrganizationsInfo.map((org) => org.id);

    if (orgIds.includes(orgId)) {
      localStorage.setItem(LS_CURRENT_ORGANIZATION_ID, orgId);
      setNoAccess(false);
    } else {
      setNoAccess(true);
      return;
    }
  }, [orgId, user, allOrganizationsInfo]);

  return !noAccess;
}
