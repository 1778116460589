import { Button, Form, Input, message, Modal, Radio, Select } from 'antd';
import { FC, useState } from 'react';
import { V_REQUIRED_FIELD } from '../../../../shared/constants';
import languageOptions from './language-options';
import { TemplateType } from './twilio-template-preview';
import { CiTextAlignJustify, CiBoxList } from 'react-icons/ci';
import { MdOutlinePermMedia } from 'react-icons/md';
import { BsReplyAll, BsCreditCard2Front } from 'react-icons/bs';
import { VscGithubAction } from 'react-icons/vsc';
import TextTemplate from './templates/text-template';
import { whatsAppOutboundApi } from '../../../../services/what-app-outbound-service';
import MediaTemplate from './templates/media-template';
import TwilioTemplateHelper from './twilio-template-helper';
import QuickReplyTemplate from './templates/quick-reply-template';
import ListPickerTemplate from './templates/list-picker-template';
import CTATemplate from './templates/call-to-action-template';
import CardTemplate from './templates/card-template';
import { showErrorMessage } from '../../../../shared/helpers';

interface ICreatingTemplateModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  projectId: string;
  onRefresh: () => void;
}

const CreatingTemplateModal: FC<ICreatingTemplateModalProps> = ({
  isOpen,
  setIsOpen,
  projectId,
  onRefresh,
}) => {
  const [createTwilioTemplate] =
    whatsAppOutboundApi.useCreateTwilioTemplateMutation();
  const [nextIsOpen, setNextIsOpen] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>('Next');
  const [closeText, setCloseText] = useState<string>('Cancel');
  const [type, setType] = useState<string | undefined>();
  const [name, setName] = useState<string>();
  const [language, setLanguage] = useState<string>();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(false);

  const backText = 'Back';

  const close = () => {
    form.resetFields();
    setNextIsOpen(false);
    setButtonText('Next');
    setCloseText('Close');
    setType(undefined);
    setIsOpen(false);
  };

  const handleCancel = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) => {
    const target = e.target as HTMLElement;

    if (target.innerHTML.includes(backText)) {
      setNextIsOpen(false);
      setButtonText('Next');
      setCloseText('Close');
      setType(undefined);
    } else {
      close();
    }
  };

  const renderNameField = () => {
    return (
      <Form.Item
        name="name"
        label={'Template name:'}
        key={'name'}
        rules={[
          {
            required: true,
            message: V_REQUIRED_FIELD,
          },
          {
            pattern: /^[a-z0-9_]+$/,
            message:
              'Template names can only contain lowercase alphanumeric characters and underscores. No other characters or spaces are allowed.',
          },
        ]}
      >
        <Input maxLength={450} />
      </Form.Item>
    );
  };

  const renderLanguageField = () => {
    return (
      <Form.Item
        name="language"
        label="Language:"
        key={'language'}
        rules={[
          {
            required: true,
            message: 'Language is required',
          },
        ]}
      >
        <Select
          placeholder="Select a language"
          dropdownStyle={{ zIndex: 10050 }}
        >
          {languageOptions.map((option) => (
            <Select.Option key={option.key} value={option.key}>
              {option.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  };

  const renderTypeField = () => {
    return (
      <Form.Item
        name="contentType"
        label="Select Content Type"
        rules={[{ required: true, message: 'Please select a content type' }]}
      >
        <Radio.Group
          className="custom-radio-group"
          buttonStyle="solid"
          style={{ width: '100%' }}
        >
          {contentTypes.map((type) => (
            <Radio.Button
              key={type.value}
              value={type.value}
              className="custom-radio-button"
            >
              <type.icon className="radio-icon" />
              {type.name}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
    );
  };

  const renderComponent = () => {
    return (
      <>
        {renderNameField()}
        {renderLanguageField()}
        {renderTypeField()}
      </>
    );
  };

  const renderBodyTemplate = () => {
    switch (type) {
      case TemplateType.TEXT:
        return <TextTemplate form={form} />;

      case TemplateType.MEDIA:
        return <MediaTemplate form={form} />;

      case TemplateType.QUICK_REPLY:
        return <QuickReplyTemplate form={form} />;

      case TemplateType.LIST_PICKER:
        return <ListPickerTemplate form={form} />;

      case TemplateType.CALL_TO_ACTION:
        return <CTATemplate form={form} />;

      case TemplateType.CARD:
        return <CardTemplate form={form} />;

      default:
        return <>Doesn't support this type of template at the current time.</>;
    }
  };

  const createTemplate = () => {
    setIsDisabledButton(true);

    form.validateFields().then(async (values) => {
      const body = {
        name: name,
        language: language,
        type: type?.replace('twilio/', ''),
        body: values.body,
        data: TwilioTemplateHelper.getFilteredData(values),
        variables: TwilioTemplateHelper.getVariablesFromBody(values.body),
      };

      var res = await createTwilioTemplate({ projectId, body });

      if ('error' in res) {
        showErrorMessage(messageApi, res.error);
        setIsDisabledButton(false);
      } else {
        await messageApi.success('The template has been created.', 1);
        setIsDisabledButton(false);
        setIsOpen(false);
        onRefresh();
      }
    });
  };

  const handleOk = () => {
    if (type) {
      createTemplate();
      return;
    }

    form
      .validateFields()
      .then((values) => {
        setType(values['contentType']);
        setName(values['name']);
        setLanguage(values['language']);
        setNextIsOpen(true);
        setButtonText('Submit for WhatsApp approval');
        setCloseText(backText);
      })
      .catch(() => {});
  };

  return (
    <Modal
      title="Create Messaging Template"
      open={isOpen}
      onCancel={() => {
        close();
      }}
      destroyOnClose={true}
      centered={true}
      className={'text-source-modal'}
      wrapClassName={'modal'}
      maskStyle={{ zIndex: 1100 }}
      style={{ marginTop: 20, marginBottom: 20, maxWidth: 600 }}
      footer={
        <div style={{ textAlign: 'right', marginTop: '50px' }}>
          <Button onClick={handleCancel} style={{ marginRight: 8 }}>
            {closeText}
          </Button>
          <Button type="primary" onClick={handleOk} disabled={isDisabledButton}>
            {buttonText}
          </Button>
        </div>
      }
    >
      <Form layout="vertical" form={form} initialValues={{ language: 'en' }}>
        {nextIsOpen ? renderBodyTemplate() : renderComponent()}
      </Form>
      {contextHolder}
    </Modal>
  );
};

export default CreatingTemplateModal;

export const contentTypes = [
  { name: 'Text', value: TemplateType.TEXT, icon: CiTextAlignJustify },
  { name: 'Media', value: TemplateType.MEDIA, icon: MdOutlinePermMedia },
  { name: 'Quick reply', value: TemplateType.QUICK_REPLY, icon: BsReplyAll },
  {
    name: 'Call to action',
    value: TemplateType.CALL_TO_ACTION,
    icon: VscGithubAction,
  },
  { name: 'List picker', value: TemplateType.LIST_PICKER, icon: CiBoxList },
  { name: 'Card', value: TemplateType.CARD, icon: BsCreditCard2Front },
];
