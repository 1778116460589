import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteNames } from '../../../../router/router';
import {
  OrganizationMemberRole,
  OrganizationSettingsType,
  ProfileSettingsType,
} from '../../../../shared/constants';
import { Avatar, Button, Select, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, UserOutlined } from '@ant-design/icons';
import OrgMemberInviteElement from './org-member-invite-element';
import { IMemberData, IRoleOption } from './organization';
import {
  getDomainForImage,
  showErrorMessage,
} from '../../../../shared/helpers';
import { organizationsAPI } from '../../../../services/organizations-service';
import { usersAPI } from '../../../../services/users-service';
import InviteOrganizationMemberModal from '../../../modals/invite-organization-member-modal/invite-organization-member-modal';
import IOrganization from '../../../../types/IOrganization';
import { MessageInstance } from 'antd/es/message/interface';
import { useAppSelector } from '../../../../hooks/redux-hooks';
import StorageUtils from '../../../../shared/utils/storage-utils';

interface IOrgMembersProps {
  currentOrgInfo: IOrganization | null;
  membersInfo: IMemberData[];
  messageApi: MessageInstance;
  roleOptions: IRoleOption[];
  currentUserRoleOption: IRoleOption | null;
  setDeleteModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getOrgMembersInfo: () => void;
}

const OrgMembers: FC<IOrgMembersProps> = ({
  currentOrgInfo,
  membersInfo,
  messageApi,
  roleOptions,
  currentUserRoleOption,
  setDeleteModalIsOpen,
  getOrgMembersInfo,
}) => {
  const { orgId, tab, subtab } = useParams();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.userReducer);
  const [updateOrganizationMemberRole, { isLoading }] =
    organizationsAPI.useUpdateOrganizationMemberRoleMutation();
  const [deleteOrganizationMember, {}] =
    organizationsAPI.useDeleteOrganizationMemberMutation();
  const [lazyGetUserInfo] = usersAPI.useLazyGetUserInfoQuery();
  const [deleteMemberDisabledList, setDeleteMemberDisabledList] = useState<
    string[]
  >([]);
  const [inviteMemberModalIsOpen, setInviteMemberModalIsOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (tab === ProfileSettingsType.ORGANIZATION && !subtab) {
      navigate(
        `${RouteNames.PROFILE_SETTINGS_NO_PARAMS}/${ProfileSettingsType.ORGANIZATION}/${orgId}/${OrganizationSettingsType.MEMBERS}`
      );
    }
  }, [subtab]);

  const columns: ColumnsType<IMemberData> = [
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (_, record) => {
        return record.hasAvatar ? (
          <Avatar size={32} src={StorageUtils.getAvatar(record.id)} />
        ) : (
          <Avatar size={32} icon={<UserOutlined />} />
        );
      },
      width: 64,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => {
        const name = `${record.firstName || ''} ${
          record.lastName || ''
        }`.trim();
        return (
          <div
            style={{
              color: record.isAccepted
                ? 'rgba(60, 66, 87, 0.88)'
                : 'rgba(60, 66, 87, 0.25)',
            }}
          >
            {name ? <div>{name}</div> : null}
            <div>{record.email}</div>
          </div>
        );
      },
    },
    {
      title: 'Invitation',
      dataIndex: 'invitation',
      key: 'invitation',
      render: (_, record) => {
        return (
          <OrgMemberInviteElement
            record={record}
            currentOrgId={currentOrgInfo?.id || ''}
            messageApi={messageApi}
          />
        );
      },
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (_, record) => {
        const role = membersInfo?.find(
          (member) => member.id === record.id
        )?.role;
        const defValue = role
          ? roleOptions.find((r) => r.value === role)
          : null;
        let disabled = false;
        let options = roleOptions.filter((r) => r.numValue !== 0);

        if (user && defValue && currentUserRoleOption) {
          disabled =
            currentUserRoleOption.numValue > defValue.numValue ||
            currentUserRoleOption.value === OrganizationMemberRole.MEMBER ||
            defValue.value === OrganizationMemberRole.OWNER;
          options = options.filter(
            (r) =>
              r.numValue >= currentUserRoleOption.numValue &&
              r.value !== OrganizationMemberRole.CLIENT
          );
        }

        return (
          <Select
            disabled={
              disabled ||
              deleteMemberDisabledList.includes(record.additionalId) ||
              defValue?.value === OrganizationMemberRole.CLIENT
            }
            className={'org-member-role-select'}
            defaultValue={defValue}
            onChange={(value) => onChangeRole(record.email, value)}
            options={options}
          />
        );
      },
    },
    {
      title: 'Delete',
      dataIndex: 'delete',
      key: 'delete',
      render: (_, record) => {
        const role = membersInfo?.find(
          (member) => member.id === record.id
        )?.role;

        const defValue = role
          ? roleOptions.find((r) => r.value === role)
          : null;

        let disabled =
          (defValue && defValue.value === OrganizationMemberRole.OWNER) ||
          false;

        if (user && defValue && currentUserRoleOption && !disabled) {
          disabled =
            currentUserRoleOption.numValue > defValue.numValue ||
            currentUserRoleOption.value === OrganizationMemberRole.MEMBER;
        }

        if (user && user.userName === record.userName) {
          disabled = false;
        }

        if (
          (membersInfo?.length || 0) > 1 &&
          defValue?.value === OrganizationMemberRole.OWNER
        ) {
          disabled = true;
        }

        if (
          defValue?.value === OrganizationMemberRole.OWNER &&
          currentOrgInfo?.isPersonal
        ) {
          disabled = true;
        }

        const ownerId = membersInfo?.find(
          (member) => member.role === OrganizationMemberRole.OWNER
        )?.id;

        return (
          <div style={{ textAlign: 'end' }}>
            <Button
              type="text"
              icon={<DeleteOutlined />}
              disabled={
                disabled ||
                deleteMemberDisabledList.includes(record.additionalId)
              }
              onClick={() => {
                if (currentOrgInfo && ownerId === record.id) {
                  setDeleteModalIsOpen(true);
                } else {
                  return handleDeleteMember(record.id, record.additionalId);
                }
              }}
            />
          </div>
        );
      },
      width: 48,
    },
  ];

  const onChangeRole = async (email: string, newRole: IRoleOption) => {
    if (!currentOrgInfo) return;

    const result = await updateOrganizationMemberRole({
      orgId: currentOrgInfo.id,
      body: { email, role: newRole as unknown as OrganizationMemberRole },
    });

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
    } else {
      messageApi.success(`Role has been updated.`, 1);
      getOrgMembersInfo();
    }
  };

  const handleDeleteMember = async (memberId: string, additionalId: string) => {
    if (!currentOrgInfo) return;
    setDeleteMemberDisabledList([...deleteMemberDisabledList, additionalId]);

    const result = await deleteOrganizationMember({
      orgId: currentOrgInfo.id,
      memberId,
    });

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
      const updList = deleteMemberDisabledList.filter(
        (id) => id !== additionalId
      );
      setDeleteMemberDisabledList(updList);
    } else {
      messageApi.success(`Member has been deleted.`, 1);
      getOrgMembersInfo();
      if (user) {
        if (memberId === user.id) {
          lazyGetUserInfo();
          navigate(
            `${RouteNames.PROFILE_SETTINGS_NO_PARAMS}/${ProfileSettingsType.ORGANIZATION}`
          );
        }
      }
    }
  };

  const renderHeaderOrganization = () => {
    const inviteButton = () => {
      if (currentUserRoleOption && currentUserRoleOption.numValue > 1) {
        return (
          <Tooltip
            placement="bottom"
            title={
              'You do not have sufficient permissions to add a member to the organization.'
            }
            color={'#f3f3f3'}
            overlayInnerStyle={{ color: '#3c4257' }}
          >
            <span>
              <Button type="primary" htmlType="submit" disabled={true}>
                Invite members
              </Button>
            </span>
          </Tooltip>
        );
      }

      return (
        <Button
          type="primary"
          htmlType="submit"
          onClick={() => setInviteMemberModalIsOpen(true)}
        >
          Invite members
        </Button>
      );
    };
    return (
      <div className={'organization-header'}>
        <div className="profile-setting-title">{/*Members*/}</div>
        {inviteButton()}
      </div>
    );
  };

  const renderMembersTable = () => {
    return (
      <Table
        dataSource={membersInfo.map((m) => {
          return {
            ...m,
            key: m.id,
          };
        })}
        columns={columns}
        pagination={{
          defaultPageSize: 6,
          hideOnSinglePage: true,
        }}
        scroll={{ x: true }}
        showHeader={false}
        className={'org-members-table'}
      />
    );
  };

  return (
    <>
      {renderHeaderOrganization()}
      {renderMembersTable()}
      <InviteOrganizationMemberModal
        isOpen={inviteMemberModalIsOpen}
        setIsOpen={setInviteMemberModalIsOpen}
        currentOrgInfo={currentOrgInfo}
        currentUserRoleOption={currentUserRoleOption}
        allRoleOptions={roleOptions}
        getOrgMembersInfo={getOrgMembersInfo}
      />
    </>
  );
};

export default OrgMembers;
