import React, { FC } from 'react';
import { Divider } from 'antd';
import TextInputFormItem from './form-items/text-input-form-item';
import { V_REQUIRED_FIELD } from '../../../../shared/constants';

const BasicInfoFormElement: FC = () => {
  return (
    <>
      <Divider orientation="left" className={'contact-form-divider'}>
        Basic Info
      </Divider>
      <div style={{ display: 'flex', gap: 24 }}>
        <TextInputFormItem
          label={'First Name'}
          name={'firstName'}
          placeholder={'First Name'}
          rules={[
            {
              required: true,
              message: V_REQUIRED_FIELD,
            },
          ]}
        />
        <TextInputFormItem
          label={'Middle Name'}
          name={'middleName'}
          placeholder={'Middle Name'}
        />
      </div>
      <TextInputFormItem
        label={'Last Name'}
        name={'lastName'}
        placeholder={'Last Name'}
      />
      <div style={{ display: 'flex', gap: 24 }}>
        <TextInputFormItem
          label={'Nickname'}
          name={'nickname'}
          placeholder={'Nickname'}
        />
        <TextInputFormItem
          label={'Title'}
          name={'title'}
          placeholder={'Title'}
        />
      </div>
    </>
  );
};

export default BasicInfoFormElement;
