import React, { FC } from 'react';
import Superellipse from '../../../../../../../superellipse/superellipse';
import './chat-body.scss';
import {
  IGeneratedWidgetStyle,
  WidgetModes,
  WidgetThemeModes,
} from '../../fake-widget-helpers';

const defaultSuperellipseProps = {
  radius: 32,
  smoothing: 1,
  borderWidth: 2,
  allowFilters: true,
};

interface IChatBodyProps {
  width: number;
  height: number;
  generatedWidgetStyle: IGeneratedWidgetStyle;
  children: React.ReactNode;
  widgetMode: WidgetModes;
}

const ChatBody: FC<IChatBodyProps> = ({
  width,
  height,
  generatedWidgetStyle,
  children,
  widgetMode,
}) => {
  const getDefaultTextColor = () => {
    return generatedWidgetStyle.theme.mode === WidgetThemeModes.DARK
      ? '#ffffff'
      : '#000000';
  };

  return (
    <div className={'chat-body'}>
      <div className={'chat-body-bg'}>
        <Superellipse
          width={width}
          height={height}
          radius={defaultSuperellipseProps.radius}
          smoothing={defaultSuperellipseProps.smoothing}
          color={generatedWidgetStyle.primaryColor}
          border={{
            width: defaultSuperellipseProps.borderWidth,
            color: generatedWidgetStyle.secondaryColor,
            gradient: true,
          }}
          shadowEnabled={true}
          glowEnabled={false}
          glowColors={['#8F00FF', '#0085FF']}
        />
      </div>
      <div
        style={{
          maxWidth: '100%',
          color: getDefaultTextColor(),
          // width: maxBodyWidthHeight.maxWidth,
        }}
        className={'chat-body-content'}
      >
        {children}
      </div>
    </div>
  );
};

export default ChatBody;
