import React, { FC } from 'react';
import './message-container.scss';
import { IGeneratedWidgetStyle } from '../../fake-widget-helpers';
import Thinking from './thinking/thinking';
import UserMessage from './user-message/user-message';
import BotMessage from './bot-message/bot-message';
import IProjectStartInfo from '../../../../../../../../types/IProjectStartInfo';

interface IMessageContainerProps {
  generatedWidgetStyle: IGeneratedWidgetStyle;
  projectStartInfo: IProjectStartInfo;
  agentName: string;
  userLabel: string;
}

const MessageContainer: FC<IMessageContainerProps> = ({
  generatedWidgetStyle,
  projectStartInfo,
  agentName,
  userLabel,
}) => {
  return (
    <div className={`message-container`}>
      <BotMessage
        generatedWidgetStyle={generatedWidgetStyle}
        projectStartInfo={projectStartInfo}
        message={'Hi, how can I help you?'}
        senderName={agentName}
      />
      <UserMessage
        generatedWidgetStyle={generatedWidgetStyle}
        message={'Could you provide some information about your pricing plans?'}
        userLabel={userLabel}
      />
      <Thinking generatedWidgetStyle={generatedWidgetStyle} />
    </div>
  );
};

export default MessageContainer;
