import { LogoMode } from '../constants';
import { getDomainForImage } from '../helpers';

const isDev = process.env.NODE_ENV === 'development';
const baseUrl = isDev
  ? 'https://zapprstoragedev.blob.core.windows.net'
  : `https://static.${getDomainForImage()}`;

class StorageUtils {
  static getAvatar(id: string): string {
    return `${baseUrl}/static/users/${id}/avatar.png`;
  }

  static getProjectLogo(
    id: string,
    fileName: string | null,
    filePath: string,
    globalTimestamp: number
  ): string {
    if (fileName) {
      return `${baseUrl}/static/projects/${id}/${fileName}?timestamp=${Date.now()}`;
    }

    return `${baseUrl}/static/${filePath}logo-${LogoMode.LIGHT}.svg?timestamp=${globalTimestamp}`;
  }

  static getBrandLogo(id: string | null): string {
    return `${baseUrl}/static/organizations/${id}/branding_image.svg?timestamp=${Date.now()}`;
  }

  static getPluginIcon(name: string): string {
    return `${baseUrl}/static/plugins/${name}/icon.png`;
  }
}

export default StorageUtils;
