import React, { FC, useState } from 'react';
import {
  Button,
  DatePicker,
  Form,
  FormInstance,
  Popover,
  Select,
  TimeRangePickerProps,
} from 'antd';
import { IoFilter } from 'react-icons/io5';
import dayjs, { Dayjs } from 'dayjs';
import {
  ConversationReviewStatusParamOptions,
  ConversationSearchParams,
  ConversationThreadControlParamOptions,
  convsFilterActionOptions,
  convsFilterReviewStatusOptions,
  convsFilterThreadControlOptions,
  getConversationFiltersParams,
  getReviewStatusByThreadControl,
  getThreadControlByReviewStatus,
} from './conversation-filters-helpers';
import IConversationsParam from '../../../types/IConversationsParams';
import {
  getDatesRange,
  getRangePresets,
} from '../../../shared/date-picker-helpers';
import { useSearchParams } from 'react-router-dom';

const FILTER_FORMAT_TEMPLATE = 'YYYY-M-D';

const getFromToQueryParams = (datePickerValue: Dayjs[]) => {
  const from = datePickerValue[0].format(FILTER_FORMAT_TEMPLATE);
  const to = datePickerValue[1].format(FILTER_FORMAT_TEMPLATE);

  return getDatesRange(from, to);
};

const getDatePickerDefaultValue = (
  searchParams: URLSearchParams,
  itemsPerPage: number
): Dayjs[] => {
  const filterParams = getConversationFiltersParams(searchParams, itemsPerPage);
  const from = filterParams.from?.value;
  const to = filterParams.to?.value;
  if (from && to) {
    return [dayjs(from), dayjs(to)];
  }
  return [];
};

interface IConversationFilterProps {
  filterForm: FormInstance;
  itemsPerPage: number;
}

const ConversationFilter: FC<IConversationFilterProps> = ({
  filterForm,
  itemsPerPage,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const rangePresets: TimeRangePickerProps['presets'] = getRangePresets();
  const [datePickerValue, setDatePickerValue] = useState<Dayjs[]>(
    getDatePickerDefaultValue(searchParams, itemsPerPage)
  );
  const [openFilter, setOpenFilter] = useState(false);

  const handleChangeRangeDate = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      if (dates[0] === null || dates[1] === null) return;
      setDatePickerValue(dates as [Dayjs, Dayjs]);
    } else {
      setDatePickerValue([]);
    }
  };

  const handleFilterOpenChange = (newOpen: boolean) => {
    setOpenFilter(newOpen);
  };

  const applyFilter = async (datePickerValues?: Dayjs[], reset?: boolean) => {
    const values = await filterForm.getFieldsValue();
    const { action, threadControl, reviewStatus } = values;

    let from: IConversationsParam | null = null;
    let to: IConversationsParam | null = null;

    const dpValues = datePickerValues || datePickerValue;

    if (dpValues.length) {
      from = {
        key: 'from',
        value: getFromToQueryParams(dpValues).start,
      };
      to = {
        key: 'to',
        value: getFromToQueryParams(dpValues).end,
      };
    }

    const newSearchParams: { [key: string]: string } = {};

    searchParams.forEach((value, key) => {
      newSearchParams[key] = value;
    });

    const handleParam = (key: string, value: string | number | undefined) => {
      if (value) {
        newSearchParams[key] = value.toString();
      } else {
        if (newSearchParams[key]) {
          delete newSearchParams[key];
        }
      }
    };

    handleParam(ConversationSearchParams.FROM, from?.value);
    handleParam(ConversationSearchParams.TO, to?.value);
    handleParam(ConversationSearchParams.ACTION, action);
    handleParam(ConversationSearchParams.THREAD_CONTROL, threadControl);
    handleParam(ConversationSearchParams.REVIEW_STATUS, reviewStatus);

    if (reset) {
      if (newSearchParams[ConversationSearchParams.PAGE]) {
        delete newSearchParams[ConversationSearchParams.PAGE];
      }
    }

    setSearchParams(newSearchParams);

    hideMoreOptionsPopup();
  };

  const hideMoreOptionsPopup = () => {
    setOpenFilter(false);
  };

  const resetFilters = () => {
    filterForm.setFieldsValue({
      action: undefined,
      threadControl: undefined,
      reviewStatus: undefined,
    });

    setDatePickerValue([]);

    applyFilter([], true);
  };

  const filterActions = () => (
    <div className={'table-filter-actions-container'}>
      <Button onClick={resetFilters}>Reset</Button>
      <Button onClick={hideMoreOptionsPopup}>Cancel</Button>
      <Button type={'primary'} onClick={() => applyFilter()}>
        Apply
      </Button>
    </div>
  );

  const filterLabel = (text: string) => (
    <div style={{ fontSize: 14, width: 100 }}>{text}</div>
  );

  const onChangeThreadControl = (
    value: ConversationThreadControlParamOptions
  ) => {
    const currentReviewStatusValue = filterForm.getFieldValue('reviewStatus');
    const reviewStatusValueByThreadControl = getReviewStatusByThreadControl(
      value,
      currentReviewStatusValue
    );
    const availableReviewStatusValues = [
      reviewStatusValueByThreadControl.value,
      ConversationReviewStatusParamOptions.ALL,
      undefined,
    ];

    if (!currentReviewStatusValue) {
      filterForm.setFieldsValue({
        reviewStatus: reviewStatusValueByThreadControl.value,
      });
      return;
    }

    if (!availableReviewStatusValues.includes(currentReviewStatusValue)) {
      filterForm.setFieldsValue({
        reviewStatus: undefined,
      });
    }
  };

  const onChangeReviewStatus = (
    value: ConversationReviewStatusParamOptions
  ) => {
    const currentThreadControlValue = filterForm.getFieldValue('threadControl');
    const threadControlValueByReviewStatus = getThreadControlByReviewStatus(
      value,
      currentThreadControlValue
    );
    const availableThreadControlValues = [
      threadControlValueByReviewStatus.value,
      ConversationThreadControlParamOptions.ALL,
      undefined,
    ];

    if (!currentThreadControlValue) {
      filterForm.setFieldsValue({
        threadControl: threadControlValueByReviewStatus.value,
      });
      return;
    }

    if (!availableThreadControlValues.includes(currentThreadControlValue)) {
      filterForm.setFieldsValue({
        threadControl: undefined,
      });
    }
  };

  const filterFormContent = () => {
    const initialParams = getConversationFiltersParams(
      searchParams,
      itemsPerPage
    );
    const initialValues = {
      action: initialParams.action?.value,
      threadControl: initialParams.threadControl?.value,
      reviewStatus: initialParams.reviewStatus?.value,
    };

    return (
      <div>
        <Form
          form={filterForm}
          layout={'horizontal'}
          requiredMark={false}
          style={{
            gap: 16,
          }}
          initialValues={initialValues}
        >
          <Form.Item name="action" label={filterLabel('Action')}>
            <Select
              options={convsFilterActionOptions}
              dropdownStyle={{ zIndex: 2000 }}
              getPopupContainer={(trigger) => trigger.parentElement}
            />
          </Form.Item>
          <Form.Item name="threadControl" label={filterLabel('Thread Control')}>
            <Select
              options={convsFilterThreadControlOptions}
              dropdownStyle={{ zIndex: 2000 }}
              onChange={onChangeThreadControl}
              getPopupContainer={(trigger) => trigger.parentElement}
            />
          </Form.Item>
          <Form.Item name="reviewStatus" label={filterLabel('Review Status')}>
            <Select
              options={convsFilterReviewStatusOptions}
              dropdownStyle={{ zIndex: 2000 }}
              onChange={onChangeReviewStatus}
              getPopupContainer={(trigger) => trigger.parentElement}
            />
          </Form.Item>
        </Form>
        {filterActions()}
      </div>
    );
  };

  const filterContent = () => {
    return (
      <div>
        <DatePicker.RangePicker
          style={{ marginBottom: 20 }}
          presets={rangePresets}
          onChange={handleChangeRangeDate}
          popupClassName={'range-picker-adaptive'}
          value={datePickerValue as [Dayjs, Dayjs]}
          placement={'bottomRight'}
          getPopupContainer={(trigger) =>
            trigger.parentElement || document.body
          }
        />
        {filterFormContent()}
      </div>
    );
  };

  return (
    <Popover
      content={filterContent()}
      trigger="click"
      open={openFilter}
      onOpenChange={handleFilterOpenChange}
      placement={'bottomRight'}
    >
      <Button style={{ display: 'flex', alignItems: 'center' }}>
        <IoFilter />
      </Button>
    </Popover>
  );
};

export default ConversationFilter;
