import React, { FC, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Checkbox,
  DatePicker,
  message,
  Modal,
  Result,
  Select,
  Skeleton,
  Table,
  TimeRangePickerProps,
  Tooltip,
} from 'antd';
import './records.scss';
import { endUserRecordsAPI } from '../../../services/end-user-records';
import { projectsAPI } from '../../../services/projects-service';
import Loading from '../../loading/loading';
import { ColumnsType } from 'antd/es/table';
import ITableRecordsData from '../../../types/ITableRecordsData';
import {
  formatDateFromISO8601,
  showErrorMessage,
} from '../../../shared/helpers';
import { TableRecordStatus } from '../../../shared/constants';
import {
  IColumnsDataType,
  IColumnsType,
  IParsedTableRecordsData,
} from './records-interfaces';
import exportToCSV from './export-to-csv';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { InfoCircleOutlined } from '@ant-design/icons';
import { HiOutlineChatAlt2 } from 'react-icons/hi';
import { AiOutlineShareAlt } from 'react-icons/ai';
import dayjs, { Dayjs } from 'dayjs';
import {
  getDatesRange,
  getRangePresets,
} from '../../../shared/date-picker-helpers';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../hooks/redux-hooks';

const RECORDS_PER_PAGE = 6;
const datePickerDefaultValue = [dayjs(), dayjs()];
const FILTER_FORMAT_TEMPLATE = 'YYYY-M-D';

const Records: FC<{ projectId: string }> = ({ projectId }) => {
  const { orgId } = useParams();
  const { isDomainTenantMember } = useAppSelector((state) => state.userReducer);
  const {
    data: tables,
    isLoading: tablesLoading,
    error: tablesError,
    refetch,
  } = endUserRecordsAPI.useGetTablesQuery({ projectId, orgId });
  const [
    lazyGetTableRecords,
    {
      data: tableRecordsData,
      isLoading: tableRecordsLoading,
      error: tableRecordsError,
    },
  ] = endUserRecordsAPI.useLazyGetTableRecordsQuery();

  const [lazyGetAllTableRecords] =
    endUserRecordsAPI.useLazyGetAllTableRecordsQuery();

  const [lazyGetShareLink] = projectsAPI.useLazyGetShareLinkQuery();

  const [lazyGetTableRecordsByIds] =
    endUserRecordsAPI.useLazyGetTableRecordsByIdsQuery();

  const [markRecordsAsRead, {}] =
    endUserRecordsAPI.useMarkRecordsAsReadMutation();

  const [markDelete, {}] = endUserRecordsAPI.useMarkDeleteMutation();

  const [currentTable, setCurrentTable] = useState<string>('');
  const [tableOptions, setTableOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [tableColumns, setTableColumns] = useState<
    ColumnsType<IColumnsDataType>
  >([]);
  const [currentTableData, setCurrentTableData] = useState<IColumnsType[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [messageApi, contextHolder] = message.useMessage();

  const [itemsPerPage, setItemsPerPage] = useState<number>(RECORDS_PER_PAGE);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsTotalCount, setItemsTotalCount] = useState<number>(0);
  const [exportWithShareLink, setExportWithShareLink] = useState(false);
  const [datePickerValue, setDatePickerValue] = useState<Dayjs[]>(
    datePickerDefaultValue
  );
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);

  useEffect(() => {
    if (tableOptions.length === 0 && tables && tables.length > 0) {
      setTableOptions(
        tables.map((table) => ({ value: table.name, label: table.name }))
      );
      setCurrentTable(tables[0].name);
    }
  }, [tables]);

  const handleChangeTable = (value: string) => {
    setCurrentTable(value);
  };

  const transformTableRecords = (tableData: ITableRecordsData[]) => {
    const data: IParsedTableRecordsData[] = tableData.map(
      (record: ITableRecordsData) => {
        const columnsValues = record.data;
        let parsedColumnsValues: IColumnsType = {};
        try {
          parsedColumnsValues = JSON.parse(columnsValues);
        } catch (e) {
          //   TODO: mb should do smth
        }

        return {
          ...record,
          parsedData: parsedColumnsValues,
        };
      }
    );

    setTableColumns(getColumns(currentTable, data));
    setCurrentTableData(
      data.map((record) => {
        return {
          key: record.id,
          chatId: record.chatId,
          createdAt: formatDateFromISO8601(record.createdAt),
          status: record.status,
          ...record.parsedData,
        };
      })
    );
  };

  const getFromToQueryParams = () => {
    const from = datePickerValue[0].format(FILTER_FORMAT_TEMPLATE);
    const to = datePickerValue[1].format(FILTER_FORMAT_TEMPLATE);

    return getDatesRange(from, to);
  };

  const getTableRecords = async (
    offset: number = 0,
    limit: number = RECORDS_PER_PAGE
  ) => {
    if (!currentTable) return;

    const fromTo = getFromToQueryParams();

    const result = await lazyGetTableRecords({
      projectId,
      table: currentTable,
      params: {
        from: fromTo.start,
        to: fromTo.end,
        offset,
        limit,
      },
    });

    if (!result.data) return;

    if (result.data) {
      const records = result.data.data;
      // if (records.length === 0) {
      //   setTableOptions([]);
      //   refetch();
      //   return;
      // }
      transformTableRecords(records);
      setItemsTotalCount(result.data.totalCount);
      setSelectedRowKeys([]);
    }
  };

  useEffect(() => {
    if (currentTable) {
      getTableRecords();
    }
  }, [currentTable]);

  useEffect(() => {
    getTableRecords((currentPage - 1) * itemsPerPage, itemsPerPage);
  }, [currentPage, itemsPerPage, datePickerValue]);

  // TODO: refactor? knowledge.tsx table
  const loadingColumns = [
    {
      title: 'Loading...',
      dataIndex: 'loading',
      key: 'loading',
      render: () => {
        return <Skeleton active />;
      },
      width: '100%',
    },
  ];

  const loadingColumnsData = [
    {
      key: 'loading',
      name: 'loading',
      columns: [],
    },
  ];

  const getColumns = (
    tableName: string,
    tableParsedData: IParsedTableRecordsData[]
  ): ColumnsType<IColumnsDataType> => {
    if (!tables) return [];
    const table = tables.find((t) => t.name === tableName);
    if (!table) return [];
    const columns = table.columns.map((column) => {
      return {
        title: column.charAt(0).toUpperCase() + column.slice(1),
        dataIndex: column,
        key: column,
      };
    });

    return [
      {
        title: 'Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: '210px',
      },
      ...columns,
    ];
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleSelectAll = async () => {
    const fromTo = getFromToQueryParams();

    const result = await lazyGetAllTableRecords({
      projectId,
      table: currentTable,
      params: {
        from: fromTo.start,
        to: fromTo.end,
      },
    });

    if (result.data) {
      const data = result.data as unknown as [];
      setSelectedRowKeys(data);
    }
  };

  const handleMarkAsRead = async () => {
    const result = await markRecordsAsRead({
      projectId,
      recordIds: selectedRowKeys as string[],
    });

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
    } else {
      finishHandle('Records marked as read.');
    }
  };

  const showModalDelete = () => {
    setIsModalDeleteVisible(true);
  };

  const handleOkDelete = async () => {
    setIsModalDeleteVisible(false);

    const result = await markDelete({
      projectId,
      recordIds: selectedRowKeys as string[],
    });

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
    } else {
      finishHandle('Records were deleted.');
    }
  };

  const handleCancelDelete = () => {
    setIsModalDeleteVisible(false);
  };

  const handleExport = async () => {
    const ids = selectedRowKeys.map((key) => String(key));

    const tablesData = await lazyGetTableRecordsByIds({
      projectId,
      table: currentTable,
      recordIds: ids,
    });

    const updatedData = await Promise.all(
      tablesData.data?.map(async (d) => {
        const chatId = d.chatId;

        const filteredData = JSON.parse(JSON.stringify(d));
        delete filteredData.chatId;

        if (
          chatId &&
          chatId != 'AAAAAAAAAAAAAAAAAAAAAA' &&
          exportWithShareLink
        ) {
          const result = await lazyGetShareLink({
            projectId: projectId,
            chatId: d.chatId,
          });

          if (result.data) {
            return { ...filteredData, shareLink: result.data };
          }
        }

        return filteredData;
      }) || []
    );

    const result = exportToCSV(updatedData, currentTable);

    if ('error' in result) {
      await showErrorMessage(messageApi, { data: { error: result.error } });
      return;
    }

    finishHandle('Records were exported.');
  };

  const finishHandle = (message: string) => {
    messageApi.success(message);
    setSelectedRowKeys([]);
    getTableRecords();
  };

  const onChangePage = async (page: number) => {
    setCurrentPage(page);
  };

  const onShowSizeChange = async (current: number, pageSize: number) => {
    setCurrentPage(current);
    setItemsPerPage(pageSize);
  };

  const onCheckExportWithShareLinkChanged = (e: CheckboxChangeEvent) => {
    setExportWithShareLink(e.target.checked);
  };

  const recordsInfoMessage = (message: string, title = '') => {
    return (
      <div className="insight-stats-no-rating">
        {title && <div className="insight-stats-no-rating__title">{title}</div>}
        <div className="insight-stats-no-rating__subtitle">{message}</div>
      </div>
    );
  };

  // const rangePresets: TimeRangePickerProps['presets'] = getRangePresets(tableRecordsData?.data);
  const rangePresets: TimeRangePickerProps['presets'] = getRangePresets();

  const handleChangeRangeDate = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      if (dates[0] === null || dates[1] === null) return;
      setDatePickerValue(dates as [Dayjs, Dayjs]);
    }
  };

  // TODO: should create shared component for DatePicker (records, convs)
  const renderHeader = () => {
    return (
      <>
        <div className={'conversations-header'}>
          <DatePicker.RangePicker
            style={{ marginBottom: 20 }}
            presets={rangePresets}
            onChange={handleChangeRangeDate}
            popupClassName={'range-picker-adaptive'}
            value={datePickerValue as [Dayjs, Dayjs]}
          />

          <div style={{ fontSize: 14, marginBottom: 20 }}>
            Records: {currentTableData.length}
          </div>
        </div>
        <div className="records-header">
          <div className="records-table-select">
            <div className={'table-select-title'}>Table</div>
            <Select
              className={'table-select'}
              defaultValue={tableOptions[0]?.value}
              style={{ width: 120 }}
              onChange={handleChangeTable}
              options={tableOptions}
              value={currentTable}
            />
          </div>

          {!isDomainTenantMember && (
            <>
              <Button
                disabled={selectedRowKeys.length <= 0}
                onClick={handleMarkAsRead}
              >
                Mark as read
              </Button>

              <Button
                disabled={selectedRowKeys.length <= 0}
                onClick={showModalDelete}
              >
                Delete selected
              </Button>
            </>
          )}

          <Modal
            title="Confirm Deletion"
            open={isModalDeleteVisible}
            onOk={handleOkDelete}
            onCancel={handleCancelDelete}
          >
            <p>Are you sure you want to delete the selected records?</p>
          </Modal>

          <Button disabled={selectedRowKeys.length <= 0} onClick={handleExport}>
            Export as CSV
          </Button>
        </div>
        <div style={{ marginLeft: 8 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginTop: 20,
              marginLeft: 8,
            }}
          >
            <span style={{ marginRight: 8 }}>Export with share link </span>
            <Checkbox
              style={{ marginRight: 15 }}
              checked={exportWithShareLink}
              onChange={onCheckExportWithShareLinkChanged}
            />
            <Tooltip
              title={`If enabled then share conversation link will be added to the exported CSV file.`}
            >
              <div style={{ marginBottom: 25, position: 'absolute' }}>
                <InfoCircleOutlined />
              </div>
            </Tooltip>
          </div>
        </div>
      </>
    );
  };

  const handleChatButtonClick = async (chatId: string) => {
    const result = await lazyGetShareLink({
      projectId: projectId,
      chatId: chatId,
    });

    if (!result.data) {
      await showErrorMessage(messageApi, { data: { error: result.error } });
    } else {
      window.open(result.data, '_blank');
    }
  };

  const handleShareButtonClick = async (chatId: string) => {
    const result = await lazyGetShareLink({
      projectId: projectId,
      chatId: chatId,
    });

    if (!result.data) {
      await showErrorMessage(messageApi, { data: { error: result.error } });
    } else {
      const url = result.data;

      navigator.clipboard.writeText(url).then(async () => {
        await messageApi.success('URL was copied successfully!');
      });
    }
  };

  const renderTable = () => {
    const actionColumn = {
      title: '',
      key: 'action',
      render: (record: IColumnsType) => {
        const chatId = record.chatId;

        if (chatId && chatId != 'AAAAAAAAAAAAAAAAAAAAAA')
          return (
            <div style={{ float: 'right', gap: 20, display: 'flex' }}>
              <HiOutlineChatAlt2
                className="chat-icon"
                onClick={() => handleChatButtonClick(chatId)}
              />
              <AiOutlineShareAlt
                className="chat-icon"
                onClick={() => handleShareButtonClick(chatId)}
              />
            </div>
          );
      },
    };

    const tableColumnsWithAction = [
      ...(tablesLoading || tableRecordsLoading
        ? loadingColumns
        : [...(tableColumns as ColumnsType<IColumnsType>), actionColumn]),
    ];

    return (
      <Table
        rowSelection={rowSelection}
        columns={tableColumnsWithAction}
        rowClassName={(record, index) =>
          record.status === TableRecordStatus.NEW ? 'row-new-record' : ''
        }
        className={`records-table`}
        dataSource={
          tablesLoading || tableRecordsLoading
            ? (loadingColumnsData as unknown as IColumnsType[])
            : currentTableData
        }
        locale={{
          emptyText:
            'Looks like there were no records on this date. Try selecting another date or check back later.',
        }}
        pagination={{
          defaultPageSize: itemsPerPage,
          hideOnSinglePage: true,
          onChange: (page) => onChangePage(page),
          current: currentPage,
          total: itemsTotalCount,
          showSizeChanger: true,
          onShowSizeChange: (current, size) => onShowSizeChange(current, size),
          pageSizeOptions: [
            RECORDS_PER_PAGE.toString(),
            '10',
            '20',
            '50',
            '100',
          ],
        }}
        scroll={{ x: true }}
      />
    );
  };

  const renderSelectedInfo = () => {
    if (!itemsTotalCount) return null;
    const renderText = () => {
      if (selectedRowKeys.length === itemsTotalCount) {
        return (
          <div>
            {selectedRowKeys.length} records are selected.{' '}
            <span
              className={'records-selected-info-btn'}
              onClick={() => setSelectedRowKeys([])}
            >
              Clear selection
            </span>
          </div>
        );
      } else {
        return (
          <div>
            {selectedRowKeys.length} records are selected.{' '}
            <span
              className={'records-selected-info-btn'}
              onClick={handleSelectAll}
            >
              Select all {itemsTotalCount} records
            </span>
          </div>
        );
      }
    };

    return (
      <Alert
        message={renderText()}
        type="info"
        style={{ textAlign: 'center', marginTop: 10 }}
      />
    );
  };

  // TODO: better error handling
  return (
    <div className="records">
      {contextHolder}
      <div className="project-setting-content-container">
        {tablesLoading ? (
          <Loading />
        ) : tablesError ? (
          <Result status="error" title="Can't load tables." />
        ) : tables && tables.length > 0 ? (
          <>
            {renderHeader()}
            {renderSelectedInfo()}
            {renderTable()}
          </>
        ) : (
          <>{recordsInfoMessage('Looks like there are no records yet.')}</>
        )}
      </div>
    </div>
  );
};

export default Records;
