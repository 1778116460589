import { useEffect, useState } from 'react';

interface IUseTableSavingDataIndicatorParams {
  startLoading: boolean;
  updateTableLoading: boolean;
  upsertRecordsLoading: boolean;
  addColumnLoading: boolean;
  addRowLoading: boolean;
  deleteColumnLoading: boolean;
  deleteRowLoading: boolean;
}

export default function useTableSavingDataIndicator(
  params: IUseTableSavingDataIndicatorParams
): [boolean] {
  const [savingData, setSavingData] = useState(false);

  const {
    startLoading,
    updateTableLoading,
    upsertRecordsLoading,
    addColumnLoading,
    addRowLoading,
    deleteColumnLoading,
    deleteRowLoading,
  } = params;

  useEffect(() => {
    if (
      startLoading ||
      updateTableLoading ||
      upsertRecordsLoading ||
      addColumnLoading ||
      addRowLoading ||
      deleteColumnLoading ||
      deleteRowLoading
    ) {
      setSavingData(true);
    } else {
      setSavingData(false);
    }
  }, [
    startLoading,
    updateTableLoading,
    upsertRecordsLoading,
    addColumnLoading,
    addRowLoading,
    deleteColumnLoading,
    deleteRowLoading,
  ]);

  return [savingData];
}
