import {
  IEditorTableColumn,
  ITableColumnT,
  ITableRowT,
  ITableT,
} from '../../types/tables-service/ITableT';
import TablesUtils from '../../components/shared/tables/tables-utils';
import {
  delay,
  SELECT_ROW_KEY,
  TableColumnTypes,
} from '../../shared/constants';
import { showErrorMessage } from '../../shared/helpers';
import React, { useState } from 'react';
import { tablesApi } from '../../services/tables-service';
import { MessageInstance } from 'antd/es/message/interface';
import { DataGridHandle } from 'react-data-grid';

interface IUseOnTableChangeParams {
  gridRef: React.RefObject<DataGridHandle>;
  projectId: string;
  tableData: ITableT | undefined;
  tableColumns: IEditorTableColumn[];
  setTableColumns: React.Dispatch<React.SetStateAction<IEditorTableColumn[]>>;
  tableRows: ITableRowT[];
  setTableRows: React.Dispatch<React.SetStateAction<ITableRowT[]>>;
  onAddColumnCb: (projectId: string, tableId: string) => Promise<void>;
  pagination: {
    currentPage: number;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    itemsPerPage: number;
    itemsTotalCount: number;
  };
  getRecords: (offset: number, limit: number) => Promise<void>;
  messageApi: MessageInstance;
}

export default function useOnTableChange(
  params: IUseOnTableChangeParams
): [
  () => void,
  () => void,
  (rows: ITableRowT[]) => void,
  boolean,
  boolean,
  boolean,
  (index: number, newName: string) => void
] {
  const {
    gridRef,
    projectId,
    tableData,
    tableColumns,
    setTableColumns,
    tableRows,
    setTableRows,
    onAddColumnCb,
    pagination,
    getRecords,
    messageApi,
  } = params;

  const { currentPage, setCurrentPage, itemsPerPage, itemsTotalCount } =
    pagination;

  const [upsertRecords, { isLoading: upsertRecordsLoading }] =
    tablesApi.useUpsertTableRecordsTMutation();
  const [addColumn] = tablesApi.useAddColumnTMutation();

  const [addColumnLoading, setAddColumnLoading] = useState(false);
  const [addRowLoading, setAddRowLoading] = useState(false);

  const onRowsChange = (rows: ITableRowT[]) => {
    const changedRows = TablesUtils.getChangedRows(rows, tableRows);
    setTableRows(rows);
    if (changedRows.length) {
      postChangedRecords(changedRows);
    }
  };

  const onAddColumn = async () => {
    if (!tableData) return;
    setAddColumnLoading(true);

    let colName = `col_${tableColumns.length - 1}`;

    if (tableColumns.some((col) => col.name === colName)) {
      colName = colName + '_' + Date.now();
    }

    const newColumn = {
      name: colName,
      type: TableColumnTypes.STRING,
      isSearchable: true,
      isUnique: false,
    } as ITableColumnT;

    const res = await addColumn({
      projectId,
      tableId: tableData.id,
      column: newColumn,
    });

    if ('error' in res) {
      showErrorMessage(messageApi, res.error);
      // setAddColumnLoading(false);
    } else {
      await onAddColumnCb(projectId, tableData.id);
      // lazyGetTable({ projectId, tableId: tableData.id }).finally(() => {
      //   setAddColumnLoading(false);
      // });
    }
    setAddColumnLoading(false);
  };

  const onAddRow = async () => {
    const getSelectedCells = () => {
      const gridCells = document.querySelectorAll('div[role="gridcell"]');
      const selectedCells = Array.from(gridCells).filter(
        (cell) => cell.getAttribute('aria-selected') === 'true'
      );
      return selectedCells;
    };

    setAddRowLoading(true);
    const emptyRow = TablesUtils.createEmptyRow(tableColumns);
    const res = await postChangedRecords([emptyRow]);
    if (res && 'error' in res) {
      await getRecords((currentPage - 1) * itemsPerPage, itemsPerPage);
    } else {
      const totalItems = itemsTotalCount + 1;
      const lastPage = Math.ceil(totalItems / itemsPerPage);
      setCurrentPage(lastPage);
      const offset = (lastPage - 1) * itemsPerPage;
      await getRecords(offset, itemsPerPage);
      if (gridRef.current) {
        const rowIndex = totalItems - offset - 1;
        const currentSelectedCells = getSelectedCells();
        if (currentSelectedCells.length) {
          const cell = currentSelectedCells[
            currentSelectedCells.length - 1
          ] as HTMLElement;
          cell.setAttribute('aria-selected', 'false');
          cell.blur();
        }
        await delay(50);
        gridRef.current.selectCell({ idx: 1, rowIdx: rowIndex });
        await delay(50);
        const selectedCells = getSelectedCells();
        if (selectedCells.length) {
          const cell = selectedCells[selectedCells.length - 1] as HTMLElement;
          cell.click();
          await delay(50);
          const keydownEvent = new KeyboardEvent('keydown', {
            key: 'Enter',
            code: 'Enter',
            keyCode: 13,
            bubbles: true,
            cancelable: true,
          });
          cell.dispatchEvent(keydownEvent);
        }
      }
    }
    // await getRecords((currentPage - 1) * itemsPerPage, itemsPerPage);
    setAddRowLoading(false);
  };

  const postChangedRecords = async (changedRows: ITableRowT[]) => {
    if (!tableData) return;

    const rowsToPost = changedRows.map((row) => {
      if (row[SELECT_ROW_KEY]) {
        delete row[SELECT_ROW_KEY];
      }

      if (row.key.includes('new-row')) {
        delete row.key;
        return {
          items: row,
        };
      }

      const noKeyRow = JSON.parse(JSON.stringify(row));
      delete noKeyRow.key;

      return {
        id: row.key,
        items: noKeyRow,
      };
    });

    const res = await upsertRecords({
      projectId,
      id: tableData.id,
      changedRecords: rowsToPost,
    });

    if ('error' in res) {
      showErrorMessage(messageApi, res.error);
    }

    return res;
  };

  const handleHeaderChange = (index: number, newName: string) => {
    setTableColumns((prev) => {
      const newColumns = prev.map((col, i) =>
        i === index ? { ...col, name: newName } : col
      ) as ITableColumnT[];
      // return getColumnsT(newColumns, true);
      return newColumns;
    });
  };

  return [
    onAddColumn,
    onAddRow,
    onRowsChange,
    addColumnLoading,
    addRowLoading,
    upsertRecordsLoading,
    handleHeaderChange,
  ];
}
