import React, { Dispatch, FC, SetStateAction } from 'react';
import './billing-plan-switcher.scss';
import { TagFilled } from '@ant-design/icons';
import { BillingPlansTypes } from '../../shared/constants';

const BillingPlanSwitcher: FC<{
  currentBillingPlansType: BillingPlansTypes;
  setCurrentBillingPlansType: Dispatch<SetStateAction<BillingPlansTypes>>;
}> = ({ currentBillingPlansType, setCurrentBillingPlansType }) => {
  return (
    <div className="billing-plans-switcher">
      <div
        className={`billing-plan-switcher ${
          currentBillingPlansType === BillingPlansTypes.MONTHLY
            ? 'billing-plan-switcher--active billing-plan-switcher--active-left'
            : ''
        }`}
        onClick={() => setCurrentBillingPlansType(BillingPlansTypes.MONTHLY)}
      >
        Monthly billing
      </div>
      <div
        className={`billing-plan-switcher ${
          currentBillingPlansType === BillingPlansTypes.YEARLY
            ? 'billing-plan-switcher--active billing-plan-switcher--active-right'
            : ''
        }`}
        onClick={() => setCurrentBillingPlansType(BillingPlansTypes.YEARLY)}
      >
        Yearly <TagFilled className="yearly-billing-icon" /> Get one month free
      </div>
    </div>
  );
};

export default BillingPlanSwitcher;
