import React, { FC, useState } from 'react';
import './usage.scss';

import MonthPicker from '../../charts/month-picker';
import { IChartsSettings } from '../../charts/chart-data';
import ProjectUsagePerDayChart from './usage-per-day-chart';
import ProjectMessagesPerDayChart from './messages-per-day-chart';

const Usage: FC<{ projectId: string }> = ({ projectId }) => {
  const initialUsageSettings = { month: new Date() };
  const [chartSettings, setChartSettings] =
    useState<IChartsSettings>(initialUsageSettings);

  const onMonthSelected = (date: Date) => {
    setChartSettings({ month: date });
  };

  return (
    <div className={'usage'}>
      <MonthPicker
        onDateChange={onMonthSelected}
        minDate={new Date('2024-01-01')}
        maxDate={new Date(Date.now())}
      />
      <ProjectUsagePerDayChart projectId={projectId} settings={chartSettings} />
      <ProjectMessagesPerDayChart
        projectId={projectId}
        settings={chartSettings}
      />
    </div>
  );
};

export default Usage;
