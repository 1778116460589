import React, { RefObject, useEffect, useRef, useState } from 'react';
import { DataGridHandle } from 'react-data-grid';
import {
  IEditorTableColumn,
  ITableRowT,
} from '../../types/tables-service/ITableT';

export default function useSelectCell(
  tableColumns: IEditorTableColumn[],
  tableRows: ITableRowT[]
): [
  RefObject<DataGridHandle>,
  string,
  React.Dispatch<React.SetStateAction<string>>
] {
  const gridRef = useRef<DataGridHandle>(null);
  const lastEditedHeaderCellColKeyRef = useRef('');
  const [editingHeaderCellKey, setEditingHeaderCellKey] = useState<string>('');

  useEffect(() => {
    if (editingHeaderCellKey) {
      lastEditedHeaderCellColKeyRef.current = editingHeaderCellKey;
    } else {
      if (gridRef.current) {
        const index = tableColumns.findIndex(
          (col) => col.key === lastEditedHeaderCellColKeyRef.current
        );
        if (index !== -1) {
          const rowExist = tableRows.length > 0;
          if (rowExist) {
            (gridRef.current as any).selectCell({ idx: index, rowIdx: 0 });
          } else {
            const nextColExist = tableColumns.length > index + 1;
            if (nextColExist) {
              (gridRef.current as any).selectCell({
                idx: index + 1,
                rowIdx: -1,
              });
            } else {
              (gridRef.current as any).selectCell({ idx: 0, rowIdx: -1 });
            }
          }
        }
      }
    }
  }, [editingHeaderCellKey]);

  return [gridRef, editingHeaderCellKey, setEditingHeaderCellKey];
}
