import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteNames } from '../../router/router';
import { IMenuOption } from './project-settings-menu';

interface IProjectSettingsMenuProps {
  options: IMenuOption[];
}

const ProjectSettingsSideMenu: FC<IProjectSettingsMenuProps> = ({
  options,
}) => {
  const { orgId, id, tab } = useParams();
  const navigate = useNavigate();

  return (
    <div
      className="profile-settings-menu project-settings-side-menu"
      style={{ fontSize: 14 }}
    >
      {options.map((option, i) => (
        <div
          className={`profile-settings-menu__item ${
            tab === option.value ? 'profile-settings-menu__item--active' : ''
          }`}
          key={i}
          onClick={() => {
            navigate(
              `${RouteNames.USER_PROJECTS}/${orgId}${RouteNames.PROJECT_SETTINGS_TAB_NAME}/${id}/${option.value}`
            );
          }}
        >
          {option.icon ? (
            <div className="profile-settings-menu-icon">
              <option.icon />
            </div>
          ) : null}
          <div className="profile-settings-menu-title">{option.label}</div>
        </div>
      ))}
    </div>
  );
};

export default ProjectSettingsSideMenu;
