import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { usersAPI } from '../../services/users-service';
import { LS_TOKEN_KEY, UserRoles } from '../../shared/constants';
import IUser from '../../types/IUser';
import IAuthResponse from '../../types/IAuthResponse';

interface IUserState {
  isAuth: boolean;
  user: IUser | null;
  userRole: UserRoles | null;
  apiKey: string;
  redirectToUserProjectsOnce: boolean;
  isDomainTenantMember: boolean;
  userLoading: boolean;
}

const initialState: IUserState = {
  isAuth: false,
  user: null,
  userRole: null,
  apiKey: '',
  redirectToUserProjectsOnce: true,
  isDomainTenantMember: false,
  userLoading: true,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsAuth(state, action: PayloadAction<boolean>) {
      state.isAuth = action.payload;
      state.redirectToUserProjectsOnce = false;
    },
    setUser(state, action: PayloadAction<IUser | null>) {
      state.user = action.payload;
    },
    setRedirectToUserProjectsOnce(state, action: PayloadAction<boolean>) {
      state.redirectToUserProjectsOnce = action.payload;
    },
    setUserRole(state, action: PayloadAction<UserRoles | null>) {
      state.userRole = action.payload;
    },
    setIsDomainTenantMember(state, action: PayloadAction<boolean>) {
      state.isDomainTenantMember = action.payload;
    },
    setUserLoading(state, action: PayloadAction<boolean>) {
      state.userLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        usersAPI.endpoints.auth.matchFulfilled,
        (state, action: PayloadAction<IAuthResponse>) => {
          localStorage.setItem(LS_TOKEN_KEY, action.payload.token);
          state.isAuth = true;
          state.redirectToUserProjectsOnce = false;
        }
      )
      .addMatcher(
        usersAPI.endpoints.googleAuth.matchFulfilled,
        (state, action: PayloadAction<IAuthResponse>) => {
          localStorage.setItem(LS_TOKEN_KEY, action.payload.token);
          state.isAuth = true;
          state.redirectToUserProjectsOnce = false;
        }
      )
      .addMatcher(
        usersAPI.endpoints.getUserInfo.matchFulfilled,
        (state, action: PayloadAction<IUser>) => {
          state.user = action.payload;
          state.isAuth = true;
        }
      )
      .addMatcher(usersAPI.endpoints.getUserInfo.matchRejected, (state) => {
        state.userLoading = false;
      })
      .addMatcher(
        usersAPI.endpoints.getUserAPIKey.matchFulfilled,
        (state, action: PayloadAction<string>) => {
          state.apiKey = action.payload;
        }
      )
      .addMatcher(usersAPI.endpoints.getUserAPIKey.matchRejected, (state) => {
        state.apiKey = '';
      });
    // .addMatcher(usersAPI.endpoints.getUserInfo.matchRejected,
    //   (state) => {
    //     state.user = null;
    //     state.isAuth = false;
    //   }
    // );
  },
});

export default userSlice.reducer;
