import { FC, useEffect, useState } from 'react';
import { Bar, Cell, LabelList } from 'recharts';
import { usageAPI } from '../../../services/usage-service';
import {
  BarExpenseType,
  chartColors,
  getChartLabelPostfix,
  IChartsSettings,
} from '../../charts/chart-data';
import { Skeleton } from 'antd';
import { projectsAPI } from '../../../services/projects-service';
import AnalyticsUtils from '../../shared/analytics/analytics-utils';
import { ChartContainer } from '../../shared/analytics/analytics-container-component';

const UsagePerProjectChart: FC<{
  settings: IChartsSettings;
  organizationId: string;
}> = ({ settings, organizationId }) => {
  const initialChartsData = { charts: [] };
  const [chartsData, setChartsData] = useState<{
    charts: { expenseType: BarExpenseType; data: any[] }[];
  }>(initialChartsData);

  const [lazyGetData, { data: queryResult, isLoading }] =
    usageAPI.useLazyGetUsagePerProjectQuery();

  const { data: userProjects } = projectsAPI.useGetUserProjectsQuery({
    orgId: organizationId,
  });
  // const { userProjects } = useAppSelector((state) => state.projectReducer);

  useEffect(() => {
    const now = new Date();
    const nowString = now.toISOString();
    lazyGetData({ date: nowString, organizationId });
  }, [lazyGetData]);

  useEffect(() => {
    if (settings) {
      const dateString = settings.month.toISOString();
      lazyGetData({ date: dateString, organizationId });
    }
  }, [settings]);

  useEffect(() => {
    if (queryResult) {
      const tableData = AnalyticsUtils.getTableData(queryResult);
      const expenses = AnalyticsUtils.getExpenses(tableData);

      const chartsData: {
        charts: { expenseType: BarExpenseType; data: any[] }[];
      } = {
        charts: [],
      };

      for (const expenseType of expenses) {
        const newChartData: any[] = [];

        for (const record of tableData) {
          const expense = AnalyticsUtils.getValueByExpenseType(
            expenseType,
            record
          );
          const projectName = AnalyticsUtils.getProjectName(
            userProjects,
            record['projectId']
          );

          const existingProject = newChartData.find(
            (x) => x.id === record['projectId']
          );
          if (existingProject) {
            existingProject.expense += expense;
            continue;
          }

          newChartData.push({
            name: projectName,
            expense,
            id: record['projectId'],
          });
        }

        for (const chartData of newChartData) {
          chartData.expense = +chartData.expense.toFixed(2);
        }

        chartsData.charts.push({ expenseType, data: newChartData });
      }

      setChartsData(chartsData);
    }
  }, [queryResult, userProjects]);

  const renderTitle = (expenseType: BarExpenseType) => {
    return (
      <>
        <div className={'usage__chart-title'}>
          Usage per project {getChartLabelPostfix(expenseType)}
        </div>
      </>
    );
  };

  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          <div style={{ width: '100%', height: '100%' }}>
            {chartsData.charts.map((chartData, iChart) => (
              <ChartContainer
                key={'bar' + iChart}
                chartData={chartData.data}
                xAxisKey="name"
                headerContent={renderTitle(chartData.expenseType)}
              >
                <Bar key={'bar'} dataKey="expense" fill={chartColors[0]}>
                  {chartData.data.map((entry, i) => (
                    <Cell
                      key={`cell-${i}`}
                      fill={chartColors[i % chartColors.length]}
                    />
                  ))}
                  <LabelList
                    dataKey="expense"
                    position="top"
                    formatter={(label: string) => (label == '0' ? null : label)}
                  />
                </Bar>
              </ChartContainer>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default UsagePerProjectChart;
