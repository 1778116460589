import React, { FC } from 'react';
import './microphone.scss';

const MicrophoneIconOutline: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="22"
      viewBox="0 0 17 22"
      fill="none"
      style={{ verticalAlign: 'top' }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.22222 0C10.6768 0 12.6667 1.98985 12.6667 4.44444V10.6667C12.6667 13.1213 10.6768 15.1111 8.22222 15.1111C5.76762 15.1111 3.77777 13.1213 3.77777 10.6667V4.44444C3.77777 1.98985 5.76762 0 8.22222 0ZM15.7778 10C16.146 10 16.4444 10.2985 16.4444 10.6667C16.4444 14.7581 13.1113 18.0981 8.88971 18.4192L8.88889 21.3333C8.88889 21.7015 8.59041 22 8.22222 22C7.88472 22 7.60579 21.7492 7.56164 21.4238L7.55556 21.3333L7.55562 18.4192C3.33363 18.0985 0 14.7584 0 10.6667C0 10.2985 0.298477 10 0.666667 10C1.03486 10 1.33333 10.2985 1.33333 10.6667C1.33333 14.2165 4.40886 17.1111 8.22222 17.1111C12.0356 17.1111 15.1111 14.2165 15.1111 10.6667C15.1111 10.2985 15.4096 10 15.7778 10ZM5.11111 4.44444C5.11111 2.72623 6.50401 1.33333 8.22223 1.33333C9.94044 1.33333 11.3333 2.72623 11.3333 4.44444V10.6667C11.3333 12.3849 9.94044 13.7778 8.22223 13.7778C6.50401 13.7778 5.11111 12.3849 5.11111 10.6667V4.44444Z"
        fill="#7F7F7F"
      />
    </svg>
  );
};

export default MicrophoneIconOutline;
