import Papa from 'papaparse';

const exportToCSV = async (data: any, currentTable: string) => {
  try {
    if (!data) {
      return;
    }

    const selectedData = data.slice();
    const parsedData: any[] = [];

    selectedData.forEach((d: any) => {
      const entry: any = {};

      const parsed = JSON.parse(d.data);

      for (const key in parsed) {
        entry[key] = parsed[key];
      }

      for (const key in d) {
        if (key !== 'data') {
          entry[key] = d[key];
        }
      }

      parsedData.push(entry);
    });

    const uniqueFieldNames = Array.from(
      new Set(parsedData.flatMap(Object.keys))
    );

    const completeData = parsedData.map((entry) => {
      const completeEntry: any = {};

      uniqueFieldNames.forEach((fieldName) => {
        completeEntry[fieldName] = entry[fieldName] || '';
      });

      return completeEntry;
    });

    const csv = Papa.unparse(completeData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', `${currentTable}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    return { success: true };
  } catch (error) {
    return { error: { data: { error: 'An error occurred during export' } } };
  }
};

export default exportToCSV;
