import React, { ReactNode, FC } from 'react';
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  LineChart,
  Legend,
  Line,
} from 'recharts';

interface ChartContainerProps {
  chartData: any[];
  xAxisKey: string;
  children: ReactNode;
  headerContent: ReactNode;
}

export const LineChartContainer: FC<ChartContainerProps> = ({
  chartData,
  xAxisKey,
  children,
  headerContent,
}) => {
  if (chartData.length === 0) {
    return <></>;
  }

  return (
    <>
      <div className="analytics__line-chart-container">
        {headerContent}
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={500}
            data={chartData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={xAxisKey} />
            <YAxis />
            <Tooltip />
            {children}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};
