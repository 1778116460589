import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteNames } from '../../../../router/router';
import {
  OrganizationMemberRole,
  OrganizationSettingsType,
  ProfileSettingsType,
} from '../../../../shared/constants';
import IOrganization from '../../../../types/IOrganization';
import { useAppSelector } from '../../../../hooks/redux-hooks';
import IUser from '../../../../types/IUser';
import { organizationsAPI } from '../../../../services/organizations-service';
import { Empty, message, Skeleton } from 'antd';
import './organization.scss';
import { suuid } from '../../../../utils/suuid';
import DeleteOrganizationModal from '../../../modals/delete-organization-modal/delete-organization-modal';
import OrgTabs from './org-tabs';
import OrgMembers from './org-members';
import OrgUsage from './org-usage';
import OrgSettings from './org-settings';
import OrgIntegrations from './org-integrations';
import OrgBranding from './org-branding';
import IOrganizationMember from '../../../../types/IOrganizationMember';

export interface IRoleOption {
  value: OrganizationMemberRole;
  label: string;
  numValue: number;
}

const roleOptions: IRoleOption[] = [
  {
    value: OrganizationMemberRole.OWNER,
    label: 'Owner',
    numValue: 0,
  },
  {
    value: OrganizationMemberRole.ADMIN,
    label: 'Admin',
    numValue: 1,
  },
  {
    value: OrganizationMemberRole.MEMBER,
    label: 'Member',
    numValue: 2,
  },
  {
    value: OrganizationMemberRole.CLIENT,
    label: 'Client',
    numValue: 3,
  },
];

export interface IMemberData extends IUser {
  additionalId: string;
  isAccepted: boolean;
  role: OrganizationMemberRole;
}

const Organization = () => {
  const { tab, orgId, subtab } = useParams();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.userReducer);
  const { allOrganizationsInfo } = useAppSelector(
    (state) => state.organizationsReducer
  );
  const [currentOrgInfo, setCurrentOrgInfo] = useState<IOrganization | null>(
    null
  );
  const [membersInfo, setMembersInfo] = useState<IMemberData[]>([]);
  const [lazyGeAllOrganizationMembers] =
    organizationsAPI.useLazyGetAllOrganizationMembersQuery();
  const [membersLoading, setMembersLoading] = useState<boolean>(false);
  const [currentUserRoleOption, setCurrentUserRoleOption] =
    useState<IRoleOption | null>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  useEffect(() => {
    if (tab === ProfileSettingsType.ORGANIZATION && !orgId) {
      navigate(
        `${RouteNames.PROFILE_SETTINGS_NO_PARAMS}/${ProfileSettingsType.ADD_NEW_ORGANIZATION}`
      );
    }
  }, [orgId]);

  useEffect(() => {
    if (orgId && allOrganizationsInfo.length) {
      const org = allOrganizationsInfo.find((org) => org.id === orgId);
      if (org) {
        setCurrentOrgInfo(org);
      }
    }
  }, [orgId, allOrganizationsInfo]);

  const getOrgMembersInfo = async () => {
    if (!currentOrgInfo) return;
    setMembersLoading(true);

    let members: IOrganizationMember[] = [];

    const res = await lazyGeAllOrganizationMembers({
      orgId: currentOrgInfo.id,
    });
    if (res.data) {
      members = res.data;
    }

    const allMembersInfo: IMemberData[] = [];

    for (let i = 0; i < members.length; i++) {
      const member = members[i];
      if (member.user) {
        const isAccepted = member.isAccepted;
        const role = member.role;
        allMembersInfo.push({
          ...member.user,
          additionalId: suuid(),
          isAccepted,
          role,
        });
      }
    }

    setMembersInfo(allMembersInfo);
    setMembersLoading(false);
  };

  useEffect(() => {
    getOrgMembersInfo();
  }, [currentOrgInfo]);

  useEffect(() => {
    if (!currentOrgInfo || !user) return;
    const currentUserRole = membersInfo.find(
      (member) => member.id === user.id
    )?.role;

    if (currentUserRole) {
      const userRoleOption =
        roleOptions.find((r) => r.value === currentUserRole) || null;
      setCurrentUserRoleOption(userRoleOption);
    }
  }, [currentOrgInfo, user, membersInfo]);

  const renderOrganizationForm = () => {
    return (
      <div className="profile-setting-content-container">
        {contextHolder}
        {renderOrganizationContent()}
      </div>
    );
  };

  const getSettingsComponent = () => {
    switch (subtab) {
      case OrganizationSettingsType.MEMBERS:
        return (
          <OrgMembers
            currentOrgInfo={currentOrgInfo}
            membersInfo={membersInfo}
            currentUserRoleOption={currentUserRoleOption}
            roleOptions={roleOptions}
            setDeleteModalIsOpen={setDeleteModalIsOpen}
            messageApi={messageApi}
            getOrgMembersInfo={getOrgMembersInfo}
          />
        );
      case OrganizationSettingsType.USAGE:
        return <OrgUsage />;
      case OrganizationSettingsType.SETTINGS:
        return (
          <OrgSettings
            currentOrgInfo={currentOrgInfo}
            currentUserRoleOption={currentUserRoleOption}
            setDeleteModalIsOpen={setDeleteModalIsOpen}
            messageApi={messageApi}
          />
        );
      case OrganizationSettingsType.INTEGRATIONS:
        return <OrgIntegrations currentOrgInfo={currentOrgInfo} />;
      case OrganizationSettingsType.BRANDING:
        return (
          <OrgBranding
            currentOrgInfo={currentOrgInfo}
            currentUserRoleOption={currentUserRoleOption}
          />
        );
      default:
        return (
          <OrgMembers
            currentOrgInfo={currentOrgInfo}
            membersInfo={membersInfo}
            currentUserRoleOption={currentUserRoleOption}
            roleOptions={roleOptions}
            setDeleteModalIsOpen={setDeleteModalIsOpen}
            messageApi={messageApi}
            getOrgMembersInfo={getOrgMembersInfo}
          />
        );
    }
  };

  const renderOrganizationContent = () => {
    if (membersLoading) {
      return <Skeleton active />;
    }

    if (currentOrgInfo) {
      return (
        <>
          <OrgTabs />
          {getSettingsComponent()}
          <DeleteOrganizationModal
            isOpen={deleteModalIsOpen}
            setIsOpen={setDeleteModalIsOpen}
            organizationId={currentOrgInfo.id}
          />
        </>
      );
    }

    return (
      <div>
        <Empty description={'Organization not found.'} />
      </div>
    );
  };

  return renderOrganizationForm();
};

export default Organization;
