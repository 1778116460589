import React, { FC } from 'react';
import './thinking.scss';
import { IGeneratedWidgetStyle } from '../../../fake-widget-helpers';
import { hexToRgb } from '../../../../../../../../../shared/color-helpers';

interface IThinkingProps {
  generatedWidgetStyle: IGeneratedWidgetStyle;
}

const Thinking: FC<IThinkingProps> = ({ generatedWidgetStyle }) => {
  const rgbSecondaryColor = hexToRgb(generatedWidgetStyle.secondaryColor);
  const bgColor = `rgba(${rgbSecondaryColor.r}, ${rgbSecondaryColor.g}, ${rgbSecondaryColor.b}, ${generatedWidgetStyle.secondaryColorOpacity})`;
  return (
    <div className="thinking" style={{ backgroundColor: bgColor }}>
      <div
        className="thinking-dot"
        style={{ backgroundColor: generatedWidgetStyle.messageTextColor.bot }}
      ></div>
      <div
        className="thinking-dot"
        style={{ backgroundColor: generatedWidgetStyle.messageTextColor.bot }}
      ></div>
      <div
        className="thinking-dot"
        style={{ backgroundColor: generatedWidgetStyle.messageTextColor.bot }}
      ></div>
    </div>
  );
};

export default Thinking;
