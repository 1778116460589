import { useState, useEffect, FC } from 'react';
import { Bar, LabelList } from 'recharts';
import { usageAPI } from '../../../services/usage-service';
import { chartColors, IChartsSettings } from '../../charts/chart-data';
import { Skeleton } from 'antd';
import AnalyticsUtils from '../../shared/analytics/analytics-utils';
import { ChartContainer } from '../../shared/analytics/analytics-container-component';
import { useParams } from 'react-router-dom';

const ProjectMessagesPerDayChart: FC<{
  projectId: string;
  settings: IChartsSettings;
}> = ({ projectId, settings }) => {
  const { orgId } = useParams();
  const initialChartData: any[] = [];
  const [chartData, setChartData] = useState(initialChartData);
  const [totalMessages, setTotalMessages] = useState(0);

  const [lazyGetData, { data: queryResult, isLoading }] =
    usageAPI.useLazyGetProjectMessagesPerDayQuery();

  useEffect(() => {
    const now = new Date();
    const nowString = now.toISOString();
    lazyGetData({ projectId, orgId, date: nowString });
  }, [lazyGetData]);

  useEffect(() => {
    if (settings) {
      const dateString = settings.month.toISOString();
      lazyGetData({ projectId, orgId, date: dateString });
    }
  }, [settings]);

  useEffect(() => {
    if (queryResult) {
      const tableData = AnalyticsUtils.getTableData(queryResult);
      const fromDate = new Date(queryResult.from);
      const toDate = new Date(queryResult.to);
      const newChartData: any[] = [];
      let currentDate = fromDate;

      while (currentDate <= toDate) {
        const day = currentDate.getUTCDate().toString();
        const entry: any = { name: day };

        const record = tableData.find((x) => {
          const utcDate = new Date(
            Date.UTC(
              currentDate.getUTCFullYear(),
              currentDate.getUTCMonth(),
              currentDate.getUTCDate()
            )
          );

          return new Date(x['timestamp']).getTime() == utcDate.getTime();
        });
        if (record) {
          entry['messages'] = record['count'];
        } else {
          entry['messages'] = 0;
        }

        newChartData.push(entry);

        currentDate.setDate(currentDate.getDate() + 1);
      }

      setChartData(newChartData);

      let total = 0;
      for (const record of tableData) {
        total += +record['count'];
      }
      setTotalMessages(total);
    }
  }, [queryResult]);

  const renderTitle = (total: number) => {
    return (
      <>
        <div className={'usage__chart-title'}>Total Messages: {total}</div>
      </>
    );
  };

  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <ChartContainer
          chartData={chartData}
          xAxisKey="name"
          headerContent={renderTitle(totalMessages)}
        >
          <Bar key={'bar'} dataKey="messages" fill={chartColors[0]}>
            <LabelList
              dataKey="messages"
              position="top"
              formatter={(label: string) => (label == '0' ? null : label)}
            />
          </Bar>
        </ChartContainer>
      )}
    </>
  );
};

export default ProjectMessagesPerDayChart;
