import { apiService, APITagTypes } from './api-service';
import {
  ITableT,
  ITableColumnT,
  ITableRecordsT,
  ITableDataT,
  ITableNewColumnT,
  ITableRowT,
} from '../types/tables-service/ITableT';
import { TableImportOptions } from '../shared/constants';
import { ITableFilterValues } from '../components/project-settings/whats-app-outbound/filters/filters-helpers';

const getTableFilterParamsString = (filterValues: ITableFilterValues[]) => {
  if (!filterValues.length) {
    return '';
  }

  let result = '';

  for (let i = 0; i < filterValues.length; i++) {
    const filterValue = filterValues[i];
    for (const key in filterValue) {
      const filter = `filter[${i}]`;
      result += `${filter}.${key}=${encodeURIComponent(
        filterValue[key as keyof ITableFilterValues]
      )}&`;
    }
  }

  return result;
};

const getTableRecordsIdsParamsString = (recordsIds: string[]) => {
  if (!recordsIds.length) {
    return '';
  }

  let result = '';

  for (let i = 0; i < recordsIds.length; i++) {
    result += `recordIds=${recordsIds[i]}&`;
  }

  return result;
};

export const tablesApi = apiService.injectEndpoints({
  endpoints: (build) => ({
    getProjectTablesT: build.query<
      {
        offset: number;
        limit: number;
        totalCount: number;
        totalPages: number;
        currentPage: number;
        data: ITableT[];
      },
      { projectId: string; params: { offset: number; limit: number } }
    >({
      query: ({ projectId, params }) => ({
        url: `/projects/${projectId}/tables?offset=${params.offset}&limit=${params.limit}`,
        method: 'GET',
      }),
      providesTags: () => [APITagTypes.PROJECT_TABLES],
    }),
    getTableDataT: build.query<ITableT, { projectId: string; tableId: string }>(
      {
        query: ({ projectId, tableId }) => ({
          url: `/projects/${projectId}/tables/${tableId}`,
          method: 'GET',
        }),
      }
    ),
    importTableT: build.mutation<
      ITableT,
      { projectId: string; importOption: TableImportOptions; body: FormData }
    >({
      query: ({ projectId, importOption, body }) => ({
        url: `/projects/${projectId}/tables/import?import-options=${importOption}`,
        method: 'POST',
        headers: {
          'content-type': undefined,
        },
        body: body,
      }),
      invalidatesTags: () => [APITagTypes.PROJECT_TABLES],
    }),
    exportTableT: build.query<
      any,
      {
        projectId: string;
        tableId: string;
        filterValues: ITableFilterValues[];
      }
    >({
      query: ({ projectId, tableId, filterValues }) => ({
        url: `/projects/${projectId}/tables/${tableId}/export?${getTableFilterParamsString(
          filterValues
        )}`,
        method: 'GET',
        responseHandler: async (response) => {
          const contentType = response.headers.get('content-type');
          return {
            downloadUrl: window.URL.createObjectURL(await response.blob()),
            contentType: contentType,
          };
        },
        cache: 'no-cache',
      }),
    }),
    createTableT: build.mutation<
      ITableT,
      { projectId: string; name: string; columns: ITableNewColumnT[] }
    >({
      query: ({ projectId, name, columns }) => ({
        url: `/projects/${projectId}/tables`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify({
          name,
          columns,
        }),
      }),
      invalidatesTags: () => [APITagTypes.PROJECT_TABLES],
    }),
    updateTableT: build.mutation<
      ITableT,
      { projectId: string; id: string; name: string; columns: ITableColumnT[] }
    >({
      query: ({ projectId, id, name, columns }) => ({
        url: `/projects/${projectId}/tables/${id}`,
        method: 'PUT',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify({
          name,
          columns,
        }),
      }),
    }),
    deleteTableT: build.mutation<void, { projectId: string; id: string }>({
      query: ({ projectId, id }) => ({
        url: `/projects/${projectId}/tables/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [APITagTypes.PROJECT_TABLES],
    }),
    getTableRecordsT: build.query<
      ITableRecordsT,
      {
        projectId: string;
        id: string;
        params: {
          offset: number;
          limit: number;
          filterValues: ITableFilterValues[];
        };
      }
    >({
      query: ({ projectId, id, params }) => ({
        url:
          `/projects/${projectId}/tables/${id}/records?offset=${params.offset}&limit=${params.limit}&` +
          getTableFilterParamsString(params.filterValues),
        method: 'GET',
      }),
    }),
    getTableRecordsByIdsT: build.query<
      ITableDataT[],
      {
        projectId: string;
        tableId: string;
        recordsIds: string[];
      }
    >({
      query: ({ projectId, tableId, recordsIds }) => ({
        url:
          `/projects/${projectId}/tables/${tableId}/records/ids?` +
          getTableRecordsIdsParamsString(recordsIds),
        method: 'GET',
      }),
    }),
    getAllTableRecordsIdsT: build.query<
      string[],
      {
        projectId: string;
        id: string;
        filterValues: ITableFilterValues[];
      }
    >({
      query: ({ projectId, id, filterValues }) => ({
        url:
          `/projects/${projectId}/tables/${id}/records/all?` +
          getTableFilterParamsString(filterValues),
        method: 'GET',
      }),
    }),
    createTableRecordsT: build.mutation<
      ITableDataT[],
      {
        projectId: string;
        id: string;
        records: { items: { [key: string]: string } }[];
      }
    >({
      query: ({ projectId, id, records }) => ({
        url: `/projects/${projectId}/tables/${id}/records`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(records),
      }),
    }),
    updateTableRecordsT: build.mutation<
      ITableDataT[],
      {
        projectId: string;
        id: string;
        changedRecords: { id: string; items: { [key: string]: string } }[];
      }
    >({
      query: ({ projectId, id, changedRecords }) => ({
        url: `/projects/${projectId}/tables/${id}/records`,
        method: 'PUT',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(changedRecords),
      }),
    }),
    upsertTableRecordsT: build.mutation<
      ITableDataT[],
      {
        projectId: string;
        id: string;
        changedRecords: (
          | { id: string; items: { [key: string]: string } }
          | { items: { [key: string]: string } }
        )[];
      }
    >({
      query: ({ projectId, id, changedRecords }) => ({
        url: `/projects/${projectId}/tables/${id}/records/upsert`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(changedRecords),
      }),
    }),
    deleteTableRecordsT: build.mutation<
      void,
      {
        projectId: string;
        id: string;
        recordsToDelete: string[];
      }
    >({
      query: ({ projectId, id, recordsToDelete }) => ({
        url: `/projects/${projectId}/tables/${id}/records`,
        method: 'DELETE',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(recordsToDelete),
      }),
    }),
    deleteColumnT: build.mutation<
      void,
      {
        projectId: string;
        tableId: string;
        columnKey: string;
      }
    >({
      query: ({ projectId, tableId, columnKey }) => ({
        url: `/projects/${projectId}/tables/${tableId}/columns/${columnKey}`,
        method: 'DELETE',
      }),
    }),
    addColumnT: build.mutation<
      ITableT,
      {
        projectId: string;
        tableId: string;
        column: ITableNewColumnT;
      }
    >({
      query: ({ projectId, tableId, column }) => ({
        url: `/projects/${projectId}/tables/${tableId}/columns`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(column),
      }),
    }),
  }),
});
