import { apiService, APITagTypes } from './api-service';
import IUserSubscription from '../types/IUserSubscription';
import { BillingPlansTypes, X_ORG_ID_HEADER } from '../shared/constants';
import IUsage from '../types/IUsage';
import IExtraCredits from '../types/IExtraCredits';
import IExtraCreditsData from '../types/IExtraCreditsData';

export const subscriptionAPI = apiService.injectEndpoints({
  endpoints: (build) => ({
    getUserSubscription: build.query<IUserSubscription, boolean | void>({
      query: (needOrgHeaders = true) => ({
        url: `/subscription/me`,
        method: 'GET',
        headers: needOrgHeaders ? {} : { [X_ORG_ID_HEADER]: '' },
      }),
      providesTags: () => [APITagTypes.CURRENT_USER_SUBSCRIPTION],
    }),
    subscribe: build.mutation<
      string | null,
      { billingType: BillingPlansTypes; type: string }
    >({
      query: ({ billingType, type }) => ({
        url: `/subscription/payment`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify({ billingType: billingType, type: type }),
      }),
      invalidatesTags: () => [
        APITagTypes.CURRENT_USER_SUBSCRIPTION,
        APITagTypes.SUBSCRIPTION_IS_ACTIVE,
        APITagTypes.USER_USAGE_INFO,
        APITagTypes.USER_USAGE,
      ],
    }),
    //TODO: mutation GET
    cancelSubscription: build.mutation<undefined, void>({
      query: () => ({
        url: `/subscription/cancel`,
        method: 'GET',
      }),
      invalidatesTags: () => [
        APITagTypes.CURRENT_USER_SUBSCRIPTION,
        APITagTypes.SUBSCRIPTION_IS_ACTIVE,
      ],
    }),
    //TODO: mutation GET
    cancelSubscriptionClosure: build.mutation<undefined, void>({
      query: () => ({
        url: `/subscription/cancel-closure`,
        method: 'GET',
      }),
      invalidatesTags: () => [
        APITagTypes.CURRENT_USER_SUBSCRIPTION,
        APITagTypes.SUBSCRIPTION_IS_ACTIVE,
      ],
    }),
    getSubscriptionIsActive: build.query<boolean, void>({
      query: () => ({
        url: `/subscription/is-active`,
        method: 'GET',
      }),
      providesTags: () => [APITagTypes.SUBSCRIPTION_IS_ACTIVE],
    }),
    getUsage: build.query<IUsage, boolean | void>({
      query: (needOrgHeaders = true) => ({
        url: `/subscription/usage`,
        method: 'GET',
        headers: needOrgHeaders ? {} : { [X_ORG_ID_HEADER]: '' },
      }),
      providesTags: () => [APITagTypes.USER_USAGE],
    }),
    getOrganizationUsage: build.query<IUsage, string>({
      query: (orgId) => ({
        url: `/subscription/usage-tenant/${orgId}`,
        method: 'GET',
      }),
    }),
    getEnterprisePaymentURL: build.query<string, void>({
      query: () => ({
        url: `/subscription/has-enterprise`,
        method: 'GET',
      }),
    }),
    calculateExtraPrice: build.query<IExtraCreditsData, number>({
      query: (amount) => ({
        url: `/subscription/calculate-extra-price/${amount}`,
        method: 'GET',
      }),
    }),
    buyExtraCredits: build.mutation<string, IExtraCredits>({
      query: (formData) => ({
        url: `/subscription/buy-extra-credits`,
        method: `POST`,
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(formData),
      }),
    }),
  }),
});
