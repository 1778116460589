import React, { FC, useEffect, useState } from 'react';
import {
  DataExposureLevel,
  PluginPropTypes,
  V_REQUIRED_FIELD,
} from '../../../../shared/constants';
import { Alert, Button, Form, Input } from 'antd';
import { CopyOutlined, WarningOutlined } from '@ant-design/icons';
import IPluginItem from '../../../../types/IPluginItem';
import {
  getAllPluginProps,
  showErrorMessage,
} from '../../../../shared/helpers';
import { MessageInstance } from 'antd/es/message/interface';
import { pluginsAPI } from '../../../../services/plugins-service';
import FacebookLogo from '../../../../assets/icons/facebook-logo.png';
import './fb-plugin-form.scss';
import FbPluginConfirmPageModal from '../../../modals/fb-plugin-confirm-page-modal/fb-plugin-confirm-page-modal';
import IPluginProperties from '../../../../types/IPluginProperties';
import FbPluginIsPageTosAcceptedModal from '../../../modals/fb-plugin-is-page-tos-accepted-modal/fb-plugin-is-page-tos-accepted-modal';

export interface IFbPage {
  id: string;
  access_token: string;
  name: string;
  tasks: string[];
  isBusinessPage: boolean;
}

interface IBusinessOrBusinessPage {
  id: string;
  name: string;
}

const requiredTasks = [
  'ADVERTISE',
  'ANALYZE',
  'CREATE_CONTENT',
  'MESSAGING',
  'MODERATE',
  'MANAGE',
];

interface IFbPluginFormProps {
  projectId: string;
  plugin: IPluginItem;
  messageApi: MessageInstance;
}

interface IFbPluginFormDBProps {
  account: string;
  page: string;
  adJson: string;
}

export enum AdJsonTypes {
  BUTTONS = 'buttons',
  QUICK_REPLIES = 'quickReplies',
}

// TODO: refactor readOnlyProp, defaultProp => check plugin.tsx, render issue ?
const FbPluginForm: FC<IFbPluginFormProps> = ({
  projectId,
  plugin,
  messageApi,
}) => {
  const [
    loginFbMessengerPlugin,
    {
      data: loginFbMessengerPluginData,
      isLoading: loginFbMessengerPluginLoading,
      error: loginFbMessengerPluginError,
    },
  ] = pluginsAPI.useLoginFbMessengerPluginMutation();
  const [
    logoutFbMessengerPlugin,
    { isLoading: logoutFbMessengerPluginLoading },
  ] = pluginsAPI.useLogoutFbMessengerPluginMutation();
  const [updatePluginSettings, {}] =
    pluginsAPI.useUpdatePluginSettingsMutation();
  const [
    checkIsPageTosAccepted,
    {
      data: isPageTosAcceptedData,
      isLoading: isPageTosAcceptedLoading,
      error: isPageTosAcceptedError,
    },
  ] = pluginsAPI.useCheckIsPageTosAcceptedFbMessengerPluginMutation();
  const [
    generateAdJSONFbMessengerPlugin,
    { isLoading: isLoadingGenerateAdJson },
  ] = pluginsAPI.useGenerateAdJSONFbMessengerPluginMutation();
  const [form] = Form.useForm();
  const [accountName, setAccountName] = useState<string | null>(null);
  const [pages, setPages] = useState<IFbPage[]>([]);
  const [selectedPage, setSelectedPage] = useState<string>('');
  const [selectedPageName, setSelectedPageName] = useState<string>('');
  const [adJson, setAdJson] = useState<string>('');
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [fbAuthResponse, setFbAuthResponse] = useState<fb.AuthResponse | null>(
    null
  );
  const [fbModalIsOpen, setFbModalIsOpen] = useState<boolean>(false);
  const [fbIsTosAcceptedModalIsOpen, setFbIsTosAcceptedModalIsOpen] =
    useState<boolean>(false);
  const [fbIsTosAcceptedModalPageName, setFbIsTosAcceptedModalPageName] =
    useState<string>('');
  const [fbTosIsAccepted, setFbTosIsAccepted] = useState<boolean>(false);

  useEffect(() => {
    const noBusinessPages = pages.filter((page) => !page.isBusinessPage);
    const notHasRequiredTasks = noBusinessPages.some((page) => {
      return !requiredTasks.every((task) => page.tasks.includes(task));
    });

    const noPermissions: string[] = [];
    noBusinessPages.forEach((page) => {
      for (const task of requiredTasks) {
        if (!page.tasks.includes(task) && !noPermissions.includes(task)) {
          noPermissions.push(task);
        }
      }
    });

    if (notHasRequiredTasks) {
      messageApi.error(
        'Confirm all required permissions and try again. Required permissions: ' +
          noPermissions.join(', ') +
          '.'
      );
    } else {
      // const pluginHasPage =
      //   plugin.properties['page' as keyof typeof plugin.properties];
      // if (pages && pages.length > 0 && !pluginHasPage) {
      //   setFbModalIsOpen(true);
      // }
    }
  }, [pages, plugin]);

  useEffect(() => {
    const pageName = pages.find((page) => page.id === selectedPage)?.name;

    if (
      selectedPage &&
      accountName &&
      adJson &&
      (pageName !== (plugin.properties as IFbPluginFormDBProps).page ||
        accountName !== (plugin.properties as IFbPluginFormDBProps).account ||
        JSON.stringify(adJson) !==
          JSON.stringify((plugin.properties as IFbPluginFormDBProps).adJson))
    ) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }
  }, [selectedPage, accountName, adJson, plugin]);

  const getBusinessPages = (businessesUrl: string, businessName: string) => {
    return new Promise((resolve, reject) => {
      window.FB.api(businessesUrl, (response) => {
        if (!response || (response as { error: unknown }).error) {
          console.error("Can't get pages for business " + businessName + '.');
          reject([]);
        } else {
          const businessPage = (response as { data: IBusinessOrBusinessPage[] })
            .data;

          resolve(businessPage);
        }
      });
    });
  };

  const getAllBusinessesPages = (userID: string) => {
    const businessesUrl = '/' + userID + '/businesses';

    return new Promise((resolve, reject) => {
      window.FB.api(businessesUrl, (response) => {
        if (!response || (response as { error: unknown }).error) {
          console.error("Can't get businesses.");
          reject([] as IBusinessOrBusinessPage[]);
        } else {
          const businesses = (response as { data: IBusinessOrBusinessPage[] })
            .data;

          const allBusinessPages = [];

          for (let i = 0; i < businesses.length; i++) {
            const businessId = businesses[i].id;
            const businessName = businesses[i].name;
            const businessPagesUrl = '/' + businessId + '/client_pages';
            const businessPages = getBusinessPages(
              businessPagesUrl,
              businessName
            );
            allBusinessPages.push(businessPages);
          }

          Promise.allSettled(allBusinessPages).then((results) => {
            let allPages: IBusinessOrBusinessPage[] = [];
            for (let i = 0; i < results.length; i++) {
              if (results[i].status === 'fulfilled') {
                const businessPages = (
                  results[i] as {
                    status: string;
                    value: IBusinessOrBusinessPage[];
                  }
                ).value;
                allPages = allPages.concat(businessPages);
              }
            }
            resolve(allPages as IBusinessOrBusinessPage[]);
          });
        }
      });
    });
  };

  const login = async () => {
    window.FB.login(
      (response) => {
        const authResponse = response.authResponse;
        if (!authResponse) return;
        setFbAuthResponse(authResponse);

        let userName = '';

        const getPages = () => {
          const pagesUrl = '/' + authResponse.userID + '/accounts';
          window.FB.api(pagesUrl, (response) => {
            if (!response || (response as { error: unknown }).error) {
              messageApi.error(
                "Can't get pages. Ensure you are not running in private/incognito mode with Tracking Protection enabled."
              );
            } else {
              const pgs = (response as { data: IFbPage[] }).data.map((p) => ({
                ...p,
                isBusinessPage: false,
              }));

              getAllBusinessesPages(authResponse.userID).then(
                (businessPages) => {
                  const transformedBusinessPages: IFbPage[] = (
                    businessPages as IBusinessOrBusinessPage[]
                  ).map((businessPage) => ({
                    ...businessPage,
                    access_token: '',
                    tasks: [],
                    isBusinessPage: true,
                  }));
                  const allPages = pgs.concat(transformedBusinessPages);

                  setPages(allPages);

                  const pluginHasPage =
                    plugin.properties['page' as keyof typeof plugin.properties];
                  if (allPages && allPages.length > 0 && !pluginHasPage) {
                    if (userName) {
                      messageApi.success(
                        'You have been successfully authenticated as ' +
                          userName +
                          '.'
                      );
                    } else {
                      messageApi.success(
                        'You have been successfully authenticated.'
                      );
                    }
                    setFbModalIsOpen(true);
                  } else {
                    if (pluginHasPage) {
                      return;
                    }
                    console.error(
                      "Can't get pages. Ensure you are not running in private/incognito mode with Tracking Protection enabled."
                    );
                  }
                }
              );
            }
          });
        };

        window.FB.api('/me', (response) => {
          if (!response || (response as { error: unknown }).error) {
            messageApi.error("Can't get account info.");
          } else {
            userName = (response as { name: string; id: string }).name;
            setAccountName(userName);
            getPages();
          }
        });
      },
      {
        scope:
          'public_profile, pages_show_list, pages_manage_metadata, pages_messaging, business_management',
      }
    );
  };

  const fbLogout = () => {
    window.FB.logout(() => {
      setAccountName(null);
      setPages([]);
      setFbAuthResponse(null);
      setAdJson('');
      setSelectedPage('');
    });
  };

  // // TODO: refactor default check ?
  // const handleChangePluginSettings = (allVals: IProjectPluginProperties, id: string) => {
  //   console.log(allVals);
  // }

  useEffect(() => {
    if (fbAuthResponse) {
      const pagesUrl = '/' + fbAuthResponse.userID + '/accounts';
      const pageName =
        plugin.properties['page' as keyof typeof plugin.properties];
      if (!pageName && !selectedPage) return;

      if (!selectedPage && pageName) {
        window.FB.api(pagesUrl, (response) => {
          if (!response || (response as { error: unknown }).error) {
            messageApi.error(
              "Can't get pages. Ensure you are not running in private/incognito mode with Tracking Protection enabled."
            );
          } else {
            const pages = (response as { data: IFbPage[] }).data;
            const samePages = pages.filter((page) => page.name === pageName);
            if (samePages.length > 1) {
              messageApi.error(
                'You have more than one page with the same name. The system does not support this.'
              );
            } else {
              handleLogin(
                plugin.id,
                plugin.pluginId,
                samePages[0]?.id || '',
                pageName
              );
            }
          }
        });
      } else {
        const pName = pageName || selectedPageName;
        handleLogin(plugin.id, plugin.pluginId, selectedPage, pName);
      }
    }
  }, [selectedPage, fbAuthResponse, plugin]);

  const handleLogin = async (
    pluginId: string,
    projectPluginId: string,
    currentPageId: string,
    pageName: string
  ) => {
    if (!fbAuthResponse) return;

    setFbIsTosAcceptedModalPageName(pageName || '');

    const data = {
      userId: fbAuthResponse.userID,
      pageId: currentPageId,
      token: fbAuthResponse.accessToken,
      expiresAt: fbAuthResponse.expiresIn,
    };

    const result = await loginFbMessengerPlugin({
      projectId,
      pluginId,
      projectPluginId,
      data,
    });

    if ('error' in result) {
      showErrorMessage(messageApi, result.error);
    } else {
      messageApi.success(`Data have been submitted.`, 1);
      setAdJson(result.data);
      setFbAuthResponse(null);

      checkIsPageTosAcceptedCb();
    }
  };

  const checkIsPageTosAcceptedCb = async () => {
    const result = await checkIsPageTosAccepted({
      projectId,
      pluginId: plugin.id,
      projectPluginId: plugin.pluginId,
    });

    if ('error' in result) {
      showErrorMessage(messageApi, result.error);
    } else {
      if (result.data) {
        const isAccepted = result.data.isAccepted;

        if (!isAccepted) {
          setFbTosIsAccepted(false);
          setFbIsTosAcceptedModalIsOpen(true);
        } else {
          setFbTosIsAccepted(true);
          setFbIsTosAcceptedModalIsOpen(false);
        }
      }
    }
  };

  const handleSubmitPluginSettings = async (
    pluginId: string,
    projectPluginId: string
  ) => {
    const pageName = pages.find((page) => page.id === selectedPage)?.name;

    const updResult = await updatePluginSettings({
      projectId: projectId,
      projectPluginId: projectPluginId,
      data: {
        pluginId: pluginId,
        properties: {
          account: accountName,
          page: pageName || '',
          adJson: adJson,
        },
      },
    });
    if ('error' in updResult) {
      await showErrorMessage(messageApi, updResult.error);
      setSaveDisabled(false);
    } else {
      messageApi.success(`Plugin settings saved.`);
    }
  };

  const handleLogoutFb = async () => {
    await fbLogout();

    const account =
      plugin.properties['account' as keyof typeof plugin.properties];

    if (!account) {
      return;
    }

    const result = await logoutFbMessengerPlugin({
      projectId,
      pluginId: plugin.id,
      projectPluginId: plugin.pluginId,
    });

    if ('error' in result) {
      showErrorMessage(messageApi, result.error);
    } else {
      messageApi.success(`You have been successfully logged out.`);
      setAccountName(null);
      setPages([]);
      setAdJson('');
      setFbAuthResponse(null);
    }
  };

  const getFormValue = (
    key: string,
    prop: IPluginProperties,
    pageId: string,
    adJsonValue: string
  ) => {
    if (key === 'page') {
      return getPageFormValue(pageId, key, prop);
    } else if (key === 'adJson') {
      return getAdJsonFormValue(adJsonValue, key, prop);
    } else {
      return (
        plugin.properties[key as keyof typeof plugin.properties] ||
        prop[key].defaultValue
      );
    }
  };

  const getPageFormValue = (
    pageId: string,
    key: string,
    prop: IPluginProperties
  ) => {
    return (
      pages.find((page) => page.id === pageId)?.name ||
      plugin.properties[key as keyof typeof plugin.properties] ||
      prop[key].defaultValue
    );
  };

  const getAdJsonFormValue = (
    adJsonValue: string,
    key: string,
    prop: IPluginProperties
  ) => {
    return JSON.stringify(
      adJsonValue ||
        plugin.properties[key as keyof typeof plugin.properties] ||
        prop[key].defaultValue
    );
  };

  const getAdJson = async (type: AdJsonTypes) => {
    const result = await generateAdJSONFbMessengerPlugin({
      projectId,
      pluginId: plugin.id,
      projectPluginId: plugin.pluginId,
      type,
    });

    if ('error' in result) {
      showErrorMessage(messageApi, result.error);
    } else {
      navigator.clipboard.writeText(JSON.stringify(result.data));
      messageApi.success(`JSON has been copied to clipboard.`);
    }
  };

  const jsonButtonsContainer = () => {
    const key = 'adJson';
    const disabled = !plugin.properties[key as keyof typeof plugin.properties];

    return (
      <div
        className={'json-btns-container'}
        style={{ display: 'flex', gap: 20, marginBottom: 20, flexWrap: 'wrap' }}
        key={key}
      >
        <Button
          onClick={() => getAdJson(AdJsonTypes.BUTTONS)}
          disabled={disabled || isLoadingGenerateAdJson}
        >
          Get JSON with buttons
        </Button>
        <Button
          onClick={() => getAdJson(AdJsonTypes.QUICK_REPLIES)}
          disabled={disabled || isLoadingGenerateAdJson}
        >
          Get JSON with quick replies
        </Button>
      </div>
    );
  };

  return (
    <>
      <Form
        className={`plugin-settings-form ${
          plugin.settingsIsOpened ? '' : 'hidden'
        }`}
        requiredMark={false}
        layout={'vertical'}
        form={form}
      >
        {getAllPluginProps(plugin).map((prop, i) => {
          const key = Object.keys(prop)[0];

          if (key === 'adJson') {
            return jsonButtonsContainer();
          }

          return prop[key].type === PluginPropTypes.FACEBOOK_AUTH ? (
            <Form.Item
              label={<>{prop[key].displayName}</>}
              name={key}
              key={i}
              tooltip={prop[key].description}
            >
              <div className={'plugin-setting-read-only'}>
                <Input
                  placeholder={prop[key].placeholder ?? prop[key].displayName}
                  value={
                    accountName ||
                    plugin.properties[key as keyof typeof plugin.properties] ||
                    prop[key].defaultValue
                  }
                  disabled={true}
                />
                {!accountName &&
                !plugin.properties[
                  'account' as keyof typeof plugin.properties
                ] ? (
                  <Button onClick={login} className={'facebook-login-btn'}>
                    <img
                      src={FacebookLogo}
                      className={'facebook-login-btn-logo'}
                    />{' '}
                    Login with Facebook
                  </Button>
                ) : (
                  <Button
                    onClick={handleLogoutFb}
                    disabled={
                      !accountName &&
                      !plugin.properties[
                        'account' as keyof typeof plugin.properties
                      ]
                    }
                    className={'facebook-logout-btn'}
                  >
                    Logout
                  </Button>
                )}
              </div>
            </Form.Item>
          ) : prop[key].readOnly ? (
            <Form.Item
              label={<>{prop[key].displayName}</>}
              name={key}
              key={i}
              rules={[
                {
                  required: prop[key].isRequired,
                  message: V_REQUIRED_FIELD,
                },
              ]}
              tooltip={prop[key].description}
              initialValue={getFormValue(key, prop, selectedPage, adJson)}
            >
              <div className={'plugin-setting-read-only'}>
                <Input
                  placeholder={prop[key].placeholder || prop[key].displayName}
                  value={getFormValue(key, prop, selectedPage, adJson)}
                  disabled={true}
                />
                <Button
                  onClick={() =>
                    navigator.clipboard.writeText(
                      getFormValue(key, prop, selectedPage, adJson)
                    )
                  }
                  className="api-key-btn"
                  disabled={
                    !plugin.properties[key as keyof typeof plugin.properties]
                  }
                >
                  <CopyOutlined />
                </Button>
              </div>
            </Form.Item>
          ) : (
            <Form.Item
              label={prop[key].displayName}
              name={key}
              key={i}
              rules={[
                {
                  required: prop[key].isRequired,
                  message: V_REQUIRED_FIELD,
                },
              ]}
              tooltip={prop[key].description}
              initialValue={
                plugin.properties[key as keyof typeof plugin.properties] ??
                prop[key].defaultValue
              }
            >
              <Input
                placeholder={prop[key].placeholder ?? prop[key].displayName}
                value={
                  plugin.properties[key as keyof typeof plugin.properties] ??
                  prop[key].defaultValue
                }
              />
            </Form.Item>
          );
        })}
        {plugin.dataExposureLevel !== DataExposureLevel.SAFE &&
          plugin.dataExposureMessage && (
            <div>
              <Alert
                className={'plugin-warning'}
                message={plugin.dataExposureMessage}
                type="warning"
                showIcon
                icon={<WarningOutlined className={'plugin-warning-icon'} />}
              />
            </div>
          )}
        <div className={'plugin-setting-btn-container'}>
          {getAllPluginProps(plugin).length > 0 && (
            <Form.Item className={'save-changes-plugin-form-item'}>
              <Button
                type="primary"
                // htmlType="submit"
                disabled={
                  saveDisabled ||
                  loginFbMessengerPluginLoading ||
                  !fbTosIsAccepted
                }
                className={'btn--green'}
                onClick={() =>
                  handleSubmitPluginSettings(plugin.id, plugin.pluginId)
                }
              >
                Save Changes
              </Button>
            </Form.Item>
          )}
        </div>
      </Form>
      <FbPluginConfirmPageModal
        isOpen={fbModalIsOpen}
        setIsOpen={setFbModalIsOpen}
        pages={pages}
        setSelectedPage={setSelectedPage}
        setSelectedPageName={setSelectedPageName}
      />
      <FbPluginIsPageTosAcceptedModal
        isOpen={fbIsTosAcceptedModalIsOpen}
        setIsOpen={setFbIsTosAcceptedModalIsOpen}
        isPageTosAcceptedData={isPageTosAcceptedData}
        isPageTosAcceptedLoading={isPageTosAcceptedLoading}
        fbIsTosAcceptedModalPageName={fbIsTosAcceptedModalPageName}
        checkIsPageTosAcceptedCb={checkIsPageTosAcceptedCb}
      />
    </>
  );
};

export default FbPluginForm;
