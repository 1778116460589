import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { RouteNames } from '../../../router/router';
import { ProfileSettingsType } from '../../../shared/constants';
import { subscriptionAPI } from '../../../services/subscription-service';
import Loading from '../../loading/loading';
import { Button, Result, Table } from 'antd';
import { domainsAPI } from '../../../services/domains-service';
import { ColumnsType } from 'antd/es/table';
import IDomain from '../../../types/domains-service/IDomain';
import AddDomainModal from '../../modals/domains/add-domain-modal';
import EditDomainModal from '../../modals/domains/edit-domain-modal';

const Domains = () => {
  const [
    lazyGetUserSubscription,
    {
      data: currentUserSubscription,
      isLoading: currentUserSubscriptionLoading,
      error: currentUserSubscriptionError,
    },
  ] = subscriptionAPI.useLazyGetUserSubscriptionQuery();

  const {
    data: domains,
    isLoading: domainsLoading,
    error: domainsError,
  } = domainsAPI.useGetDomainsQuery();
  const [addDomainIsOpen, setAddDomainIsOpen] = useState(false);
  const [editDomainId, setEditDomainId] = useState<string>('');

  useEffect(() => {
    lazyGetUserSubscription(false);
  }, []);

  const getComponent = () => {
    if (currentUserSubscriptionLoading || domainsLoading) {
      return <Loading />;
    }

    if (currentUserSubscriptionError) {
      return (
        <Result
          status="error"
          title="Ooops, something went wrong."
          subTitle={"Can't get subscription info."}
        />
      );
    }

    if (domainsError) {
      return (
        <Result
          status="error"
          title="Ooops, something went wrong."
          subTitle={"Can't get domains info."}
        />
      );
    }

    if (
      currentUserSubscription &&
      !currentUserSubscription.currentPlan.features.partner_domain
    ) {
      return (
        <>
          <div className="profile-setting-title">Domains</div>
          <div className="profile-setting-description">
            {currentUserSubscription &&
            currentUserSubscription.currentPlan.features
              .partner_domain ? null : (
              <>
                <br />
                <br />
                Your plan does not have access to this option. To open it,{' '}
                <Link
                  to={`${RouteNames.PROFILE_SETTINGS_NO_PARAMS}/${ProfileSettingsType.PLAN}`}
                  className={'advanced-no-access-link'}
                >
                  upgrade your plan
                </Link>
                .
              </>
            )}
          </div>
        </>
      );
    }

    return domainsComponent();
  };

  const columns: ColumnsType<IDomain> = [
    {
      title: 'Domain',
      dataIndex: 'name',
      key: 'domain',
    },
  ];

  const domainsComponent = () => {
    return (
      <>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 20,
            gap: 20,
          }}
        >
          <div className="profile-setting-title" style={{ marginBottom: 0 }}>
            Domains
          </div>
          <Button type="primary" onClick={() => setAddDomainIsOpen(true)}>
            Add domain
          </Button>
        </div>

        <Table
          className={'org-members-table'}
          style={{ cursor: 'pointer' }}
          dataSource={(domains || []).map((d) => {
            return {
              ...d,
              key: d.id,
            };
          })}
          columns={columns}
          onRow={(record: IDomain) => ({
            onClick: () => setEditDomainId(record.id),
          })}
          pagination={{
            defaultPageSize: 10,
            hideOnSinglePage: true,
          }}
          showHeader={false}
        />

        <AddDomainModal
          isOpen={addDomainIsOpen}
          setIsOpen={setAddDomainIsOpen}
        />
        <EditDomainModal
          editDomainId={editDomainId}
          setEditDomainId={setEditDomainId}
        />
      </>
    );
  };

  return (
    <div className="profile-setting-content-container">{getComponent()}</div>
  );
};

export default Domains;
