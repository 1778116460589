import { apiService, APITagTypes } from './api-service';
import IPlugin from '../types/IPlugin';
import IProjectPlugin from '../types/IProjectPlugin';
import IProjectPluginProperties from '../types/IProjectPluginProperties';
import IFacebookPageTos from '../types/IFacebookPageTos';
import { AdJsonTypes } from '../components/project-settings/plugins/fb-plugin/fb-plugin-form';
import { X_ORG_ID_HEADER } from '../shared/constants';

export const pluginsAPI = apiService.injectEndpoints({
  endpoints: (build) => ({
    getPlugins: build.query<IPlugin[], void>({
      query: () => ({
        url: `/plugins`,
        method: 'GET',
      }),
      providesTags: () => [APITagTypes.PLUGINS],
    }),
    getProjectPlugins: build.query<
      IProjectPlugin[],
      { id: string; orgId?: string | undefined }
    >({
      query: ({ id, orgId }) => ({
        url: `/projects/${id}/plugins`,
        method: 'GET',
        headers: orgId ? { [X_ORG_ID_HEADER]: orgId } : {},
      }),
      providesTags: () => [APITagTypes.PROJECT_PLUGINS],
    }),
    requestDemoPlugin: build.mutation<
      IPlugin[],
      { projectId: string; pluginId: string }
    >({
      query: ({ projectId, pluginId }) => ({
        url: `/projects/${projectId}/plugins/${pluginId}/request`,
        method: 'POST',
      }),
      invalidatesTags: () => [APITagTypes.PROJECT_PLUGINS],
    }),
    installPlugin: build.mutation<
      IPlugin[],
      { projectId: string; pluginId: string }
    >({
      query: ({ projectId, pluginId }) => ({
        url: `/projects/${projectId}/plugins/${pluginId}/install`,
        method: 'POST',
      }),
      invalidatesTags: () => [APITagTypes.PROJECT_PLUGINS],
    }),
    uninstallPlugin: build.mutation<
      IPlugin[],
      { projectId: string; projectPluginId: string; body: { pluginId: string } }
    >({
      query: ({ projectId, projectPluginId, body }) => ({
        url: `/projects/${projectId}/plugins/${projectPluginId}/uninstall`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(body),
      }),
      invalidatesTags: () => [APITagTypes.PROJECT_PLUGINS],
    }),
    updatePluginSettings: build.mutation<
      IPlugin[],
      {
        projectId: string;
        projectPluginId: string;
        data: { pluginId: string; properties: IProjectPluginProperties };
      }
    >({
      query: ({ projectId, projectPluginId, data }) => ({
        url: `/projects/${projectId}/plugins/${projectPluginId}`,
        method: 'PUT',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(data),
      }),
      invalidatesTags: () => [APITagTypes.PROJECT_PLUGINS],
    }),
    loginGetJobberPlugin: build.mutation<
      {
        id: string;
        name: string;
      },
      {
        projectId: string;
        pluginId: string;
        projectPluginId: string;
        code: string;
      }
    >({
      query: ({ projectId, pluginId, projectPluginId, code }) => ({
        url: `/projects/${projectId}/plugins/get-jobber/${pluginId}/${projectPluginId}/login?code=${code}`,
        method: 'POST',
      }),
    }),
    logoutGetJobberPlugin: build.mutation<
      void,
      {
        projectId: string;
        pluginId: string;
        projectPluginId: string;
        userName: string;
      }
    >({
      query: ({ projectId, pluginId, projectPluginId, userName }) => ({
        url: `/projects/${projectId}/plugins/get-jobber/${pluginId}/${projectPluginId}/logout?username=${userName}`,
        method: 'POST',
      }),
    }),
    loginFbMessengerPlugin: build.mutation<
      string,
      {
        projectId: string;
        pluginId: string;
        projectPluginId: string;
        data: {
          userId: string;
          pageId: string;
          token: string;
          expiresAt: number;
        };
      }
    >({
      query: ({ projectId, pluginId, projectPluginId, data }) => ({
        url: `/projects/${projectId}/plugins/fb-messenger/${pluginId}/${projectPluginId}/login`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(data),
      }),
      invalidatesTags: () => [APITagTypes.PROJECT_PLUGINS],
    }),
    logoutFbMessengerPlugin: build.mutation<
      void,
      {
        projectId: string;
        pluginId: string;
        projectPluginId: string;
      }
    >({
      query: ({ projectId, pluginId, projectPluginId }) => ({
        url: `/projects/${projectId}/plugins/fb-messenger/${pluginId}/${projectPluginId}/logout`,
        method: 'POST',
      }),
      invalidatesTags: () => [APITagTypes.PROJECT_PLUGINS],
    }),
    checkIsPageTosAcceptedFbMessengerPlugin: build.mutation<
      IFacebookPageTos,
      {
        projectId: string;
        pluginId: string;
        projectPluginId: string;
      }
    >({
      query: ({ projectId, pluginId, projectPluginId }) => ({
        url: `/projects/${projectId}/plugins/fb-messenger/${pluginId}/${projectPluginId}/is-page-tos-accepted`,
        method: 'POST',
      }),
    }),
    generateAdJSONFbMessengerPlugin: build.mutation<
      string,
      {
        projectId: string;
        pluginId: string;
        projectPluginId: string;
        type: AdJsonTypes;
      }
    >({
      query: ({ projectId, pluginId, projectPluginId, type }) => ({
        url: `/projects/${projectId}/plugins/fb-messenger/${pluginId}/${projectPluginId}/ad-json?type=${type}`,
        method: 'POST',
      }),
    }),
    pluginGoogleLogin: build.mutation<
      {
        email: string;
        name: string;
        picture: string;
        locale: string;
      },
      {
        projectId: string;
        pluginId: string;
        projectPluginId: string;
        code: string;
        scopes: string[];
      }
    >({
      query: ({ projectId, pluginId, projectPluginId, code, scopes }) => ({
        url: `/projects/${projectId}/plugins/google/${pluginId}/${projectPluginId}/authorize`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify({
          code: code,
          scopes: scopes,
          origin: window.location.origin,
        }),
      }),
    }),
  }),
});
