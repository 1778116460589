import { EngineTypes } from './constants';

export interface IEngineOption {
  label: string;
  value: EngineTypes;
  description: string;
}

export interface IEngine {
  EngineTypes: string;
}

const fetchEngineOptions = (data: IEngine): IEngineOption[] => {
  const engineOptions: IEngineOption[] = Object.entries(data).map(
    ([key, label]) => {
      let description = '';
      switch (key) {
        case EngineTypes.GPT4:
          description =
            'GPT-4 costs approximately 14 times as many credits as GPT-3.';
          break;
        case EngineTypes.GPT4o:
          description = 'GPT-4o.';
          break;
      }
      return { label: label as string, value: key as EngineTypes, description };
    }
  );

  return engineOptions;
};

export default fetchEngineOptions;
