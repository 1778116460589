// TODO: define colors for charts
export const chartColors = [
  '#FF5733',
  '#33FF57',
  '#3357FF',
  '#F933FF',
  '#FFC300',
  '#00FFFF',
  '#FF5733',
  '#C70039',
  '#900C3F',
  '#581845',
  '#FFC0CB',
  '#808000',
  '#800000',
  '#FFFF00',
  '#00FF00',
];

export interface IChartsSettings {
  month: Date;
}

export enum BarExpenseType {
  Price = 'price',
  Cost = 'cost',
}

export type BarData = {
  name: string;
  dataKey: string;
  type: string;
  labelKey?: string;
};

export type ChartData = {
  label: string | JSX.Element;
  xAxisDataKey: string;
  bars: BarData[];
  data: any[];
};

export type UsagePerDayChartProps = {
  charts: ChartData[];
};

export const distinctByProp = (data: any[], propName: string): string[] => {
  return data.reduce((acc, item) => {
    if (!acc.includes(item[propName])) acc.push(item[propName]);
    return acc;
  }, []);
};

export const getChartLabelPostfix = (expenseType: BarExpenseType) => {
  switch (expenseType) {
    case BarExpenseType.Price:
      return ``;
    case BarExpenseType.Cost:
      return `(${expenseType})`;
  }
};
