import React, { FC, useEffect, useState } from 'react';
import { TableImportOptions } from '../../../../shared/constants';
import {
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';
import { suuid } from '../../../../utils/suuid';
import { showErrorMessage } from '../../../../shared/helpers';
import { tablesApi } from '../../../../services/tables-service';

interface IImportTableTModal {
  isOpen: boolean;
  closeModal: () => void;
  projectId: string;
  cb: () => void;
  setImportLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ImportTableTModal: FC<IImportTableTModal> = ({
  isOpen,
  closeModal,
  projectId,
  cb,
  setImportLoading,
}) => {
  const [importTableT, { isLoading: importLoading }] =
    tablesApi.useImportTableTMutation();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [showUpload, setShowUpload] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();

  useEffect(() => {
    setImportLoading(importLoading);
  }, [importLoading]);

  const handleImport = async () => {
    if (!projectId) return;
    setIsLoading(true);

    const submittedFiles: string[] = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      const formData = new FormData();

      const formValues = form.getFieldsValue();

      const schemaJson: {
        $type: string;
        hasHeader: boolean;
        separator: string;
        mergeOptions: {
          type: TableImportOptions;
        };
      } = {
        $type: 'csv',
        hasHeader: formValues.hasHeader,
        separator: formValues.separator,
        mergeOptions: {
          type: TableImportOptions.OVERWRITE,
        },
      };

      formData.append('file', file.originFileObj as Blob);
      formData.append('schemaJson', JSON.stringify(schemaJson));

      const uuid = suuid();

      const result = importTableT({
        projectId,
        importOption: TableImportOptions.OVERWRITE,
        body: formData,
      });

      submittedFiles.push(uuid);
      // cbSubmittedFiles(submittedFiles);

      result
        .unwrap()
        .then((res) => {
          messageApi.success(`File ${file.name} has been submitted.`, 1);
          cb();
        })
        .catch((err) => {
          showErrorMessage(messageApi, err);
        })
        .finally(() => {
          submittedFiles.splice(submittedFiles.indexOf(uuid), 1);
          setImportLoading(false);
        });
    }

    setFileList([]);
    setIsLoading(false);
    setImportLoading(false);
    closeModal();
  };

  useEffect(() => {
    fileList.length > 0 ? setShowUpload(false) : setShowUpload(true);
  }, [fileList]);

  const { Dragger } = Upload;

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    onChange(info) {
      setFileList(info.fileList);
    },
    onDrop(e) {
      // console.log('Dropped files', e.dataTransfer.files);
    },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: () => {
      return false;
    },
    fileList,
  };

  return (
    <Modal
      className="data-source-upload-modal"
      confirmLoading={isLoading}
      title={`Import table`}
      open={isOpen}
      onCancel={closeModal}
      cancelButtonProps={{ disabled: isLoading }}
      onOk={handleImport}
      okText="Import"
      okButtonProps={{
        style: { display: showUpload ? 'none' : 'inline-block' },
      }}
      centered={true}
      wrapClassName={'modal'}
      maskStyle={{ zIndex: 1100 }}
    >
      {contextHolder}
      <Form
        initialValues={{
          hasHeader: true,
          separator: ',',
        }}
        layout="vertical"
        form={form}
        className={'import-table-modal'}
      >
        <Form.Item name={`hasHeader`} valuePropName="checked">
          <Checkbox>Has a header</Checkbox>
        </Form.Item>
        <Form.Item name={`separator`} label={'Separator'}>
          <Input placeholder={','} />
        </Form.Item>
      </Form>
      <Dragger
        {...props}
        accept={'.csv'}
        className={`knowledge-dragger ${
          showUpload ? '' : 'data-source-upload-dragger-hidden'
        }`}
        maxCount={1}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
      </Dragger>
    </Modal>
  );
};

export default ImportTableTModal;
