import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Form, message, Modal, Select } from 'antd';
import {
  IngestQATypeOption,
  IngestQATypes,
} from '../qa-ingest-modals/qa-ingest-shared-types';
import { showErrorMessage } from '../../../shared/helpers';
import { GA_UPLOAD_QA } from '../../../shared/google-analytics';
import { V_REQUIRED_FIELD } from '../../../shared/constants';
import TextArea from 'antd/es/input/TextArea';
import CommandsHelper, {
  ICommandList,
  IGetCommandLabel,
} from '../qa-ingest-modals/commands-helper';
import { projectsAPI } from '../../../services/projects-service';

export interface IQAKnowledgeContent {
  question: string;
  answer: string;
  type: IngestQATypes;
  command: string;
}

interface ITeachModelModalWithCommandsProps {
  qaKnowledgeContent: IQAKnowledgeContent | null;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  projectId: string;
  feedbackId: string;
}

interface ITeachModelModalProps extends ITeachModelModalWithCommandsProps {
  commandList: ICommandList[];
  getCommandLabel: IGetCommandLabel;
}

const TeachModelModal: FC<ITeachModelModalProps> = ({
  qaKnowledgeContent,
  commandList,
  getCommandLabel,
  isOpen,
  setIsOpen,
  projectId,
  feedbackId,
}) => {
  const [teachModel, { isLoading: teachModelLoading }] =
    projectsAPI.useTeachModelQAMutation();
  const [teachModelFeedback, { isLoading: teachModelFeedbackLoading }] =
    projectsAPI.useTeachModelQAFeedbackMutation();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [okDisabled, setOkDisabled] = useState<boolean>(true);
  const [currentType, setCurrentType] = useState<string>(
    qaKnowledgeContent?.type || ''
  );
  const [currentCommand, setCurrentCommand] = useState<string>(
    qaKnowledgeContent?.command || ''
  );

  useEffect(() => {
    if (form) {
      form.setFieldsValue(qaKnowledgeContent);
      setCurrentType(qaKnowledgeContent?.type || '');
      setCurrentCommand(qaKnowledgeContent?.command || '');
    }
  }, [qaKnowledgeContent, form, isOpen]);

  useEffect(() => {
    handleChange();
  }, [currentCommand]);

  const handleOk = async () => {
    setOkDisabled(true);

    let values = form.getFieldsValue();
    const typeIsCommandResponds =
      values.type === IngestQATypes.COMMAND_RESPONDS;

    if (typeIsCommandResponds) {
      values = {
        ...values,
        command: currentCommand,
      };
    }

    let result = null;

    if (feedbackId) {
      result = await teachModelFeedback({
        id: projectId,
        feedbackId,
        body: values,
      });
    } else {
      result = await teachModel({
        id: projectId,
        body: values,
      });
    }

    if (result) {
      if ('error' in result) {
        showErrorMessage(messageApi, result.error);
      } else {
        GA_UPLOAD_QA(true);
        await messageApi.success(`Data has been sent.`, 1);
        form.resetFields();
        handleCancel();
      }
    }

    setOkDisabled(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsOpen(false);
    // setQaUpdateKnowledgeId('');
  };

  const handleChangeType = (value: string) => {
    form.setFieldValue('type', value);
    setCurrentType(value);
    handleChange();
  };

  const handleChangeCommand = (value: string) => {
    setCurrentCommand(value);
  };

  const handleChange = async () => {
    if (!qaKnowledgeContent) return;

    const values = form.getFieldsValue();

    const typeIsCommandResponds =
      values.type === IngestQATypes.COMMAND_RESPONDS;

    if (typeIsCommandResponds && !currentCommand) {
      setOkDisabled(true);
      return;
    }

    const commandIsChanged = qaKnowledgeContent.command !== currentCommand;

    if (commandIsChanged) {
      setOkDisabled(false);
      return;
    }

    for (const key in values) {
      if (
        values[key] === undefined ||
        values[key] === null ||
        values[key] === ''
      ) {
        setOkDisabled(true);
        return;
      }
    }

    for (const key in values) {
      if (
        values[key] !== qaKnowledgeContent[key as keyof IQAKnowledgeContent]
      ) {
        setOkDisabled(false);
        return;
      }
    }

    setOkDisabled(true);
  };

  const shouldShowCommandsList = () => {
    return (
      currentType === IngestQATypes.COMMAND_RESPONDS && commandList.length > 0
    );
  };

  return (
    <Modal
      title="Q&A Ingest"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered={true}
      okButtonProps={{
        disabled: okDisabled || teachModelLoading || teachModelFeedbackLoading,
        loading: teachModelLoading || teachModelFeedbackLoading,
      }}
      okText={'Ingest'}
      className={'text-source-modal'}
      wrapClassName={'modal'}
      maskStyle={{ zIndex: 1100 }}
    >
      {contextHolder}
      <Form
        form={form}
        onChange={handleChange}
        className="data-source-upload-modal-form"
        layout="vertical"
      >
        <div>
          <Form.Item
            name="type"
            rules={[
              {
                required: true,
                message: V_REQUIRED_FIELD,
              },
            ]}
            style={{ marginBottom: 10 }}
          >
            <>
              <span style={{ fontWeight: 500 }}>When</span>
              <Select
                style={{ width: 180, margin: '0 10px 10px' }}
                options={IngestQATypeOption.map((item) => ({
                  label: item.label,
                  value: item.value,
                  disabled:
                    item.value === IngestQATypes.COMMAND_RESPONDS
                      ? !commandList.length
                      : false,
                }))}
                dropdownStyle={{ zIndex: 2000 }}
                onChange={(value) => handleChangeType(value)}
                value={currentType}
              />
              {shouldShowCommandsList() && (
                <Select
                  style={{
                    width: '100%',
                    maxWidth: '464px',
                    minWidth: '238px',
                  }}
                  options={commandList.map((item) => ({
                    label: (
                      <span style={{ textTransform: 'capitalize' }}>
                        {getCommandLabel(item.pluginName, item.commandName)}
                      </span>
                    ),
                    value: item.command,
                  }))}
                  dropdownStyle={{ zIndex: 2000 }}
                  onChange={(value) => handleChangeCommand(value)}
                  optionLabelProp="label"
                  value={currentCommand}
                />
              )}
            </>
          </Form.Item>
        </div>
        <Form.Item
          name="question"
          rules={[
            {
              required: true,
              message: V_REQUIRED_FIELD,
            },
          ]}
          className={'text-source-modal__text'}
        >
          <TextArea
            rows={4}
            showCount
            maxLength={2000}
            placeholder={`Q: What’s the weather today?`}
          />
        </Form.Item>
        <div style={{ fontWeight: 500, marginBottom: 10 }}>Reply this:</div>
        <Form.Item
          name="answer"
          rules={[
            {
              required: true,
              message: V_REQUIRED_FIELD,
            },
          ]}
          className={'text-source-modal__text'}
        >
          <TextArea
            rows={4}
            showCount
            maxLength={2000}
            placeholder={`A: I’m not a weather forecasting system. Do you have other questions?`}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const TeachModelModalModalWithCommands: FC<
  ITeachModelModalWithCommandsProps
> = ({ qaKnowledgeContent, isOpen, setIsOpen, projectId, feedbackId }) => {
  return (
    <CommandsHelper projectId={projectId}>
      {(commandList, getCommandLabel) => {
        return (
          <TeachModelModal
            commandList={commandList}
            getCommandLabel={getCommandLabel}
            qaKnowledgeContent={qaKnowledgeContent}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            projectId={projectId}
            feedbackId={feedbackId}
          />
        );
      }}
    </CommandsHelper>
  );
};

export default TeachModelModalModalWithCommands;
