import { Progress, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import './usage-panel.scss';
import { subscriptionAPI } from '../../../services/subscription-service';
import { useAppSelector } from '../../../hooks/redux-hooks';
import IUsage from '../../../types/IUsage';
import { WarningOutlined } from '@ant-design/icons';
import { abbreviateNumber } from '../../../shared/helpers';

const USAGE_UPDATE_MS = 10000;

let currentInterval = 0;

// const prettyInt = (x: number) =>
//   Math.round(x)
//     .toString()
//     .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const UsagePanel = () => {
  const { currentOrganization } = useAppSelector(
    (state) => state.organizationsReducer
  );
  const [lazyGetUserUsage] = subscriptionAPI.useLazyGetUsageQuery();
  const [lazyGetOrgUsage] = subscriptionAPI.useLazyGetOrganizationUsageQuery();
  const [usageStats, setUsageStats] = useState<IUsage | null>(null);

  useEffect(() => {
    setUsageInterval();

    return () => {
      if (currentInterval) {
        window.clearInterval(currentInterval);
      }
    };
  }, []);

  const getUserUsage = () => {
    lazyGetUserUsage(false).then((data) => {
      if (data.status === 'fulfilled') {
        setUsageStats(data.data);
      }
    });
  };

  const getOrgUsage = (orgId: string) => {
    lazyGetOrgUsage(orgId).then((data) => {
      if (data.status === 'fulfilled') {
        setUsageStats(data.data);
      }
    });
  };

  const setUsageInterval = () => {
    if (currentInterval) {
      window.clearInterval(currentInterval);
    }
    if (!currentOrganization) {
      getUserUsage();
      currentInterval = window.setInterval(() => {
        getUserUsage();
      }, USAGE_UPDATE_MS);
    } else {
      getOrgUsage(currentOrganization.id);
      currentInterval = window.setInterval(() => {
        getOrgUsage(currentOrganization.id);
      }, USAGE_UPDATE_MS);
    }
  };

  useEffect(() => {
    setUsageInterval();
  }, [currentOrganization]);

  const getUsedInPercent = (usage: IUsage) => {
    const creditsInPercent =
      ((usage.totalTokens - usage.creditsLeft) / usage.totalTokens) * 100;

    return creditsInPercent;
  };

  const getStokeColor = (usedInPercentWithExtra: number) => {
    if (usedInPercentWithExtra < 80) {
      return '#00b2ff';
    } else {
      return '#ffbf00';
    }
    // return '#ff4d4f';
  };

  const shouldShowWarnIcon = (usedInPercent: number) => {
    return usedInPercent >= 80;
  };

  const render = () => {
    if (!usageStats) return;

    if (usageStats.totalTokens <= 0) {
      const getColor = () => {
        if (usageStats.extraCredits < 100) {
          return '#ff0000';
        }

        if (usageStats.extraCredits < 1000) {
          return '#ffbf00';
        }

        return '#ffffff';
      };

      return (
        <>
          <div className="usage-panel__partner">
            <div
              style={{
                color: getColor(),
                marginBottom: '1px',
                textAlign: 'center',
              }}
            >
              <span>Credits available</span>
            </div>
            <div
              style={{
                color: getColor(),
                fontSize: '18px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {`${usageStats.extraCredits}`}
            </div>
          </div>
        </>
      );
    }

    return (
      <div className={'usage-panel'}>
        {usageStats && (
          <div>
            <Tooltip
              placement="bottomLeft"
              overlayStyle={{
                zIndex: 1500,
              }}
              align={{ offset: [-12, 10] }}
              title={
                <>
                  {`You have used ${Math.trunc(
                    getUsedInPercent(usageStats)
                  )}% of your credits.`}
                  <br />
                  {`Your plan will renew in ${usageStats.daysUntilRenewal} days.`}
                  <br />
                  {usageStats.extraCredits > 0 && (
                    <span style={{ color: '#FFD700' }}>
                      {`Extra credits left: ${usageStats.extraCredits}`}
                    </span>
                  )}
                </>
              }
              color="#3C4257"
            >
              <div className={'usage-panel__flex'}>
                <div>
                  Usage{' '}
                  {shouldShowWarnIcon(getUsedInPercent(usageStats)) && (
                    <span
                      style={{ display: 'inline-block', position: 'relative' }}
                    >
                      <WarningOutlined
                        style={{
                          color: getStokeColor(getUsedInPercent(usageStats)),
                          position: 'absolute',
                          top: -13,
                          left: 0,
                          fontSize: 16,
                        }}
                      />
                    </span>
                  )}
                </div>
                <div>
                  {abbreviateNumber(
                    // usageStats.totalTokens - usageStats.creditsLeft
                    Math.max(0, usageStats.totalTokens - usageStats.creditsLeft)
                  )}{' '}
                  /{' '}
                  {abbreviateNumber(
                    usageStats.totalTokens + usageStats.extraCredits
                  )}{' '}
                </div>
              </div>
              <div className={'usage-panel__progress-container'}>
                <Progress
                  percent={getUsedInPercent(usageStats)}
                  size={'small'}
                  strokeColor={getStokeColor(getUsedInPercent(usageStats))}
                  showInfo={false}
                />
              </div>
            </Tooltip>
          </div>
        )}
      </div>
    );
  };

  return <>{render()}</>;
};

export default UsagePanel;
