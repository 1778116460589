import { Button } from 'antd';
import React, { useState, useEffect, FC } from 'react';

const MonthPicker: FC<{
  onDateChange: (date: Date) => void;
  minDate: Date;
  maxDate: Date;
}> = ({ onDateChange, minDate, maxDate }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handlePrevClick = () => {
    const newDate = new Date(selectedDate);
    newDate.setMonth(newDate.getMonth() - 1);

    if (!minDate || newDate >= minDate) {
      setSelectedDate(newDate);
    }
  };

  const handleNextClick = () => {
    const newDate = new Date(selectedDate);
    newDate.setMonth(newDate.getMonth() + 1);

    if (!maxDate || newDate <= maxDate) {
      setSelectedDate(newDate);
    }
  };

  useEffect(() => {
    onDateChange(selectedDate);
  }, [selectedDate]);

  const formattedDate = selectedDate.toLocaleString('en', {
    month: 'long',
    year: 'numeric',
  });

  const copyDateWithChangedMonth = (date: Date, addedMonth: number) => {
    const copyDate = new Date(date);
    copyDate.setMonth(copyDate.getMonth() + addedMonth);
    return copyDate;
  };

  return (
    <div className={'month-picker-panel'}>
      <Button
        onClick={handlePrevClick}
        disabled={
          minDate && copyDateWithChangedMonth(selectedDate, -1) <= minDate
        }
      >
        &lt;
      </Button>
      <div className={'month-picker-panel__date'}>{formattedDate}</div>
      <Button
        onClick={handleNextClick}
        disabled={
          maxDate && copyDateWithChangedMonth(selectedDate, 1) >= maxDate
        }
      >
        &gt;
      </Button>
    </div>
  );
};

export default MonthPicker;
