import { ReactComponent as DocsIcon } from '../../assets/icons/features/docs-icon.svg';
import { ReactComponent as FilesIcon } from '../../assets/icons/features/files-icon.svg';
import { ReactComponent as NLPIcon } from '../../assets/icons/features/nlp-icon.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/features/search-icon.svg';
import { ReactComponent as UIIcon } from '../../assets/icons/features/ui-icon.svg';

export const landingReviews = [
  {
    name: 'Lucas Parker',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'Impressive AI, thanks!',
  },
  {
    name: 'Zoe Anderson',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'Great solution, thank you!',
  },
  {
    name: 'Owen Foster',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'AI made our work easier!',
  },
  {
    name: 'Eva Wood',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'Incredible AI, thanks!',
  },
  {
    name: 'Noah Brooks',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'Thank you for the innovation!',
  },
  {
    name: 'Mia Jordan',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'AI changed the game, thanks!',
  },
  {
    name: 'Ethan Baker',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'Revolutionary AI, thank you!',
  },
  {
    name: 'Grace Carter',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'Thank you for the efficiency!',
  },
  {
    name: 'Liam Hayes',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'AI boosted our productivity, thanks!',
  },
  {
    name: 'Aria Knight',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'Thank you for the insight!',
  },
  {
    name: 'Luke Morgan',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'AI made a big difference, thanks!',
  },
  {
    name: 'Lila King',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'Thank you for the accuracy!',
  },
  {
    name: 'Cole Rivera',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'AI exceeded our expectations, thanks!',
  },
  {
    name: 'Maya Mitchell',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'Thank you for the competitive edge!',
  },
  {
    name: 'Owen Gallagher',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'AI transformed our business, thanks!',
  },
  {
    name: 'Carter Richardson',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'AI is game-changing, thank you so much!',
  },
  {
    name: 'Brooklyn Foster',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'Our business is thriving, thanks to your AI!',
  },
  {
    name: 'Aiden Scott',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'Thank you for the cutting-edge technology!',
  },
  {
    name: 'Harper Thompson',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'AI gave us a competitive edge, thank you!',
  },
  {
    name: 'Mason Turner',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'Our team is more efficient, thanks to your AI!',
  },
  {
    name: 'Ava Williams',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'Thank you for the exceptional AI development!',
  },
  {
    name: 'Jackson Myers',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'AI exceeded our expectations, thank you!',
  },
  {
    name: 'Lily Jenkins',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'Thank you for the transformative AI solution!',
  },
  {
    name: 'Caleb Robertson',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'AI has made a huge impact, thank you!',
  },
  {
    name: 'Maya Garcia',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'Thank you for the revolutionary AI technology!',
  },
  {
    name: 'Logan Diaz',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'AI has been a game-changer, thank you!',
  },
  {
    name: 'Addison Bailey',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'Thank you for the exceptional AI development!',
  },
  {
    name: 'Liam Parker',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'Our business is more successful, thanks to your AI!',
  },
  {
    name: 'Zoe Torres',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'AI has transformed our company, thank you!',
  },
  {
    name: 'Owen Harrison',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'Thank you for the exceptional AI innovation!',
  },
  {
    name: 'Sarah Johnson',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: "Your product is a game-changer! I've saved so much time and the quality of my work has improved drastically. Thank you!",
  },
  {
    name: 'David Williams',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: "I don't know how I managed before using your product. It's made my daily tasks so much easier and streamlined.",
  },
  {
    name: 'Emily Davis',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: "The simplicity of your product is amazing. I've been able to accomplish so much more with less effort.",
  },
  {
    name: 'Robert Thompson',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: "I've recommended your product to all my colleagues. It's improved our efficiency and accuracy immensely.",
  },
  {
    name: 'Jennifer Lee',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: "Your product has taken the tediousness out of my work. I'm able to focus on more important tasks now.",
  },
  {
    name: 'Michael Chen',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: "Since using your product, my workload has decreased and my productivity has skyrocketed. I'm grateful for the time it's given me back.",
  },
  {
    name: 'Samantha Garcia',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: "The value of your product far exceeds its cost. It's paid for itself in time saved and improved results.",
  },
  {
    name: 'Christopher Adams',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: "Your product is a true asset to my business. I've seen improvements in both efficiency and customer satisfaction.",
  },
  {
    name: 'Victoria Nguyen',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: "I can't thank you enough for creating such a helpful tool. It's revolutionized the way I work.",
  },
  {
    name: 'Daniel Kim',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: "Your product has become an essential part of my daily routine. I wouldn't know what to do without it.",
  },
  {
    name: 'Elizabeth Wilson',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: "I was hesitant to try your product, but now I can't imagine my work without it. It's made my job so much easier.",
  },
  {
    name: 'James Martin',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: "Your product has exceeded my expectations. It's eliminated errors and improved the overall quality of my work.",
  },
  {
    name: 'Ashley Perez',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: "I've noticed a significant improvement in my workflow since implementing your product. It's a game-changer!",
  },
  {
    name: 'Jonathan Rodriguez',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: "Your product has given me the ability to focus on tasks that actually matter. I'm amazed at how much time it's saved me.",
  },
  {
    name: 'Lauren Baker',
    // additionalInfo: 'SVP @Snap',
    // img: introImg,
    text: 'The positive impact your product has had on my work is immeasurable. Thank you for creating such an amazing tool.',
  },
];

export const featureList = [
  {
    title: 'Document analysis',
    content:
      "Our AI-powered platform analyzes any uploaded document, extracting key information and understanding the document's context, making it easier for users to search and ask questions.",
    icon: <DocsIcon />,
  },
  {
    title: 'Natural Language Processing',
    content:
      'Our NLP algorithms allow users to ask questions in natural language, just as they would to another person. The platform understands the nuances of language, context, and intent, and provides accurate answers quickly and efficiently.',
    icon: <NLPIcon />,
  },
  {
    title: 'Relevant search results',
    content:
      'The platform uses advanced search techniques to deliver relevant search results, ensuring that users find the information they need quickly and easily. This is particularly useful when searching through long documents or when trying to locate specific information.',
    icon: <SearchIcon />,
  },
  {
    title: 'Multiple file formats',
    content:
      'Our platform supports multiple file formats, including PDFs, Word documents, and even images. This means that users can upload any document or image and receive relevant information based on the content.',
    icon: <FilesIcon />,
  },
  {
    title: 'User-friendly interface',
    content:
      'Our platform offers a seamless and easy-to-use interface, tailored to two types of bots — Ask Bar and Cognitive Bot. Ask Bar is designed to resemble a search bar, while Cognitive Bot provides a conversational interface, both ensuring a user-friendly experience for all users.',
    icon: <UIIcon />,
  },
];
