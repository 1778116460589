import React, { FC } from 'react';
import { Divider } from 'antd';
import TextInputFormItem from './form-items/text-input-form-item';
import FormUtils from '../../../../shared/utils/form-utils';
import { PhoneLabel } from '../../../../types/contacts/contact-records/IPhoneRecord';
import LabelFormItem from './form-items/label-form-item';
import IInitialLabelContactRecordValue from '../../../../types/contacts/IInitialLabelContactRecordValue';

interface IPhoneRecordFormElementProps {
  initialLabelValue: IInitialLabelContactRecordValue;
}

const PhoneRecordFormElement: FC<IPhoneRecordFormElementProps> = ({
  initialLabelValue,
}) => {
  return (
    <>
      <Divider orientation="left" className={'contact-form-divider'}>
        Phone
      </Divider>
      <TextInputFormItem
        label={'Phone Number'}
        name={'phoneNumber'}
        placeholder={'Phone Number'}
      />
      <div style={{ display: 'flex', gap: 24 }}>
        <LabelFormItem
          label={'Label'}
          name={'label'}
          options={FormUtils.createSelectOptionsFromEnum(PhoneLabel)}
          initialValue={initialLabelValue}
        />
        {/*<SelectFormItem*/}
        {/*  label={'Label'}*/}
        {/*  name={'label'}*/}
        {/*  options={FormUtils.createSelectOptionsFromEnum(PhoneLabel)}*/}
        {/*/>*/}
        {/*<TextInputFormItem*/}
        {/*  label={'Custom Label'}*/}
        {/*  name={'customLabel'}*/}
        {/*  placeholder={'Custom Label'}*/}
        {/*/>*/}
      </div>
    </>
  );
};

export default PhoneRecordFormElement;
