import React, { FC, useEffect, useState } from 'react';
import { projectsAPI } from '../../../services/projects-service';
import Loading from '../../loading/loading';
import {
  Anchor,
  Button,
  Form,
  Input,
  message,
  Pagination,
  Result,
  Skeleton,
  Tag,
} from 'antd';
import './conversations.scss';
import {
  CommentOutlined,
  LinkOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import {
  formatDateFromISO8601,
  showErrorMessage,
} from '../../../shared/helpers';
import { useAppSelector } from '../../../hooks/redux-hooks';
import ConversationMessages from './conversation-messages';
import { IConversationItem as IConversationItemConv } from './conversation-messages';
import TeachModelModal, {
  IQAKnowledgeContent,
} from '../../modals/teach-model-modal/teach-model-modal';
import { IngestQATypes } from '../../modals/qa-ingest-modals/qa-ingest-shared-types';
import { IConversationMessageItem } from '../../../types/IConversationMessage';
import {
  ConversationChannelId,
  ConversationMessageTypes,
  ProjectSettingsType,
} from '../../../shared/constants';
import TraceModal from '../../modals/trace-modal/trace-modal';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { RouteNames } from '../../../router/router';
import { IConversationsParams } from '../../../types/IConversationsParams';
import {
  ConversationSearchParams,
  getConversationFiltersParams,
  getCurrentPageFromSearchParams,
} from './conversation-filters-helpers';
import IConversation from '../../../types/IConversation';
import ConversationMarkToggle from './conversation-mark-toggle';
import ConversationReviewToggle from './conversation-review-toggle';
import ConversationReviewStatusShortcuts from './conversation-review-status-shortcuts';
import ConversationFilter from './conversation-filter';
import { FaCommentSms } from 'react-icons/fa6';
import { projectPluginsAPI } from '../../../services/project-plugins-service';

interface IConversationItem {
  key: string;
  href: string;
  title: React.ReactNode;
  isActive: boolean;
}

interface IConversationFilterItem {
  key: string;
  href: string;
  title: React.ReactNode;
  isActive: boolean;
  children: IConversationItem[];
}

const CONVERSATIONS_PER_PAGE = 100;

let convMessagesRefetchInterval: number | null = null;

const Conversations: FC<{ projectId: string }> = ({ projectId }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { currentOrganizationLoading, currentOrganization } = useAppSelector(
    (state) => state.organizationsReducer
  );
  const [conversationParams, setConversationParams] =
    useState<IConversationsParams>(
      getConversationFiltersParams(searchParams, CONVERSATIONS_PER_PAGE)
    );
  const [skip, setSkip] = useState(true);
  const {
    data: projectConversations,
    isLoading: projectConversationsLoading,
    error: projectConversationsError,
  } = projectsAPI.useGetProjectConversationsQuery(
    { projectId, params: conversationParams },
    { skip }
  );
  const [
    lazyGetProjectConversations,
    {
      data: lazyProjectConversations,
      isLoading: lazyProjectConversationsLoading,
      error: lazyProjectConversationsError,
    },
  ] = projectsAPI.useLazyGetProjectConversationsQuery();
  const [
    lazyGetProjectConversationById,
    {
      data: conversationByIdData,
      isLoading: lazyProjectConversationByIdLoading,
    },
  ] = projectsAPI.useLazyGetProjectConversationByIdQuery();
  const [
    lazyGetConversationMessages,
    { data: conversationMessages, isLoading, error },
  ] = projectsAPI.useLazyGetConversationMessagesQuery();
  const [sendLiveMessage, { isLoading: sendLiveMessageLoading }] =
    projectPluginsAPI.useSendLiveMessageMutation();
  const [takeControl, { isLoading: takeControlLoading }] =
    projectPluginsAPI.useTakeControlMutation();
  const [releaseControl, { isLoading: releaseControlLoading }] =
    projectPluginsAPI.useReleaseControlMutation();
  const [conversationItems, setConversationItems] = useState<
    IConversationFilterItem[]
  >([]);
  const [conversationId, setConversationId] = useState<string>('');
  const [feedbackId, setFeedbackId] = useState('');
  const [qaKnowledgeContent, setQAKnowledgeContent] =
    useState<IQAKnowledgeContent | null>(null);
  const [teachModalIsOpen, setTeachModalIsOpen] = useState(false);
  const [activeConvStart, setActiveConvStart] = useState(false);
  const [conversationMessagesLoading, setConversationMessagesLoading] =
    useState(false);
  const [traceModalIsOpen, setTraceModalIsOpen] = useState(false);
  const [messageTraceId, setMessageTraceId] = useState<string>('');
  const navigate = useNavigate();
  const { orgId, id, subtab } = useParams();
  const [messageToSend, setMessageToSend] = useState<string>('');
  const [messageApi, contextHolder] = message.useMessage();
  const [lazyGetShareLink] = projectsAPI.useLazyGetShareLinkQuery();
  const [currentPage, setCurrentPage] = useState<number>(
    getCurrentPageFromSearchParams(
      searchParams.get(ConversationSearchParams.PAGE)
    )
  );
  const [itemsTotalCount, setItemsTotalCount] = useState<number>(0);
  const [filterForm] = Form.useForm();
  const [currentConversation, setCurrentConversation] =
    useState<IConversation | null>(null);
  const [showAllConvsButton, setShowAllConvsButton] = useState(false);
  const [shouldGetConvById, setShouldGetConvById] = useState(true);
  const [convByIdError, setConvByIdError] = useState(false);

  useEffect(() => {
    const page = getCurrentPageFromSearchParams(
      searchParams.get(ConversationSearchParams.PAGE)
    );
    setCurrentPage(page);

    const currentFilterParams = getConversationFiltersParams(
      searchParams,
      CONVERSATIONS_PER_PAGE
    );
    setConversationParams(currentFilterParams);

    const searchParamsLength = Array.from(searchParams).length;

    if (searchParamsLength) {
      setShouldGetConvById(false);
    }
  }, [searchParams]);

  useEffect(() => {
    const getCurrentConversation = (): IConversation | null => {
      const conv = projectConversations?.data?.find(
        (conv) => conv.id === conversationId
      );
      return conv || null;
    };

    setCurrentConversation(getCurrentConversation());
  }, [projectConversations, conversationId]);

  useEffect(() => {
    if (!currentOrganizationLoading && currentOrganization) {
      setSkip(false);
    }
  }, [currentOrganizationLoading, currentOrganization]);

  useEffect(() => {
    if (skip) return;
    lazyGetProjectConversations({ projectId, params: conversationParams });
  }, [currentOrganizationLoading, conversationParams, currentOrganization]);

  const getSearchParamsString = () => {
    let searchParamsStr = '';
    searchParams.forEach((value, key) => {
      searchParamsStr += `${key}=${value}&`;
    });
    return searchParamsStr;
  };

  const handleClick = (
    e: React.MouseEvent<HTMLElement>,
    link: {
      title: React.ReactNode;
      href: string;
    }
  ) => {
    e.preventDefault();

    const updatedConversationItems = conversationItems.map((item) => {
      if (item.href === link.href) {
        return {
          ...item,
          isActive: true,
        };
      }
      return {
        ...item,
        isActive: false,
      };
    });

    setConversationItems(updatedConversationItems);

    const searchStr = getSearchParamsString();

    navigate(
      `${RouteNames.USER_PROJECTS}/${orgId}${RouteNames.PROJECT_SETTINGS_TAB_NAME}/${id}/${ProjectSettingsType.CONVERSATIONS}/${link.href}?${searchStr}`
    );
  };

  useEffect(() => {
    if (!projectConversations || activeConvStart) return;

    setLastConvActive();
  }, [conversationItems, projectConversations, activeConvStart]);

  useEffect(() => {
    if (subtab) {
      handleChangeActiveLink(subtab);
    }
  }, [subtab]);

  const setLastConvActive = () => {
    if (
      !projectConversations ||
      conversationItems.length === 0 ||
      activeConvStart
    )
      return;

    if (conversationItems.length > 0) {
      const convItemId = subtab || conversationItems[0].href;
      const updatedConvItems = conversationItems.map((item) => {
        if (item.href === convItemId) {
          return {
            ...item,
            isActive: true,
          };
        }
        return {
          ...item,
          isActive: false,
        };
      });

      setConversationItems(updatedConvItems);
      setActiveConvStart(true);
    }
  };

  const getCurrentAnchor = () => {
    if (!conversationItems) return '';
    let active = '';
    const activeConvFilterItem =
      conversationItems.find((item) => item.isActive && item.href)?.href || '';
    if (activeConvFilterItem) {
      active = activeConvFilterItem;
    } else {
      const activeConvItem =
        conversationItems.find((item) => item.isActive && item.href)?.href ||
        '';
      if (activeConvItem) {
        active = activeConvItem;
        return active;
      }
    }

    return active;
  };

  useEffect(() => {
    if (convMessagesRefetchInterval) {
      window.clearInterval(convMessagesRefetchInterval);
    }
    if (!conversationId) return;
    if (conversationIsWhatsAppChannel()) {
      const interval = window.setInterval(() => {
        lazyGetConversationMessages({
          projectId,
          conversationId,
          orgId,
        });
      }, 5000);

      convMessagesRefetchInterval = interval;
    }
  }, [conversationId]);

  const handleChangeActiveLink = (activeLink: string) => {
    setConversationMessagesLoading(true);
    setMessageToSend('');
    setConversationId(activeLink);
    lazyGetProjectConversationById({ projectId, conversationId: activeLink });
    lazyGetConversationMessages({
      projectId,
      conversationId: activeLink,
      orgId,
    }).finally(() => {
      setConversationMessagesLoading(false);
    });
  };

  const handleTeachModel = (item: IConversationItemConv) => {
    const reactId = item.botMsgs[item.botMsgs.length - 1].reactId;

    if (reactId) {
      setFeedbackId(reactId);
    } else {
      setFeedbackId('');
    }

    const answers = item.botMsgs[0].items.map((item) => item.message || '');

    setQAKnowledgeContent({
      question: item.userMsg?.message || '',
      answer: answers.join('\n'),
      type: IngestQATypes.USER_ASKS,
      command: '',
    });
  };

  const handleTeachModelCommand = (item: IConversationMessageItem) => {
    if (item.$type === ConversationMessageTypes.COMMAND) {
      let question = '';
      if (item.result) {
        try {
          const result = JSON.parse(item.result);
          if (result.data.extra_information_to_assistant) {
            delete result.data.extra_information_to_assistant;
          }
          let message = '';
          if (result.data.message) {
            message = JSON.stringify(result.data.message);
            delete result.data.message;
          }
          const isEmpty = Object.keys(result.data).length === 0;
          const objectData = isEmpty ? '' : JSON.stringify(result.data) + '\n';
          question = objectData + message;
        } catch (e) {}
      }

      setQAKnowledgeContent({
        question: question,
        answer: '',
        type: IngestQATypes.COMMAND_RESPONDS,
        command: item.name || '',
      });
    }
  };

  useEffect(() => {
    if (qaKnowledgeContent) {
      setTeachModalIsOpen(true);
      // setQASourceModalIsOpen(true);
    }
  }, [qaKnowledgeContent]);

  function TotalCosts() {
    if (!conversationMessages) return <></>;

    const totalCosts: any = {
      costUsd: 0,
      priceUsd: 0,
      costCredits: 0,
      priceCredits: 0,
    };

    conversationMessages.forEach((message) => {
      if (message.costs != null) {
        totalCosts.costUsd += message.costs.costUsd;
        totalCosts.priceUsd += message.costs.priceUsd;
        totalCosts.costCredits += message.costs.costCredits;
        totalCosts.priceCredits += message.costs.priceCredits;
      }
    });

    if (
      totalCosts.costUsd != 0 ||
      totalCosts.priceUsd != 0 ||
      totalCosts.costCredits != 0 ||
      totalCosts.priceCredits != 0
    ) {
      let usd = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

      return (
        <>
          <div className="insight-date cost-pane">
            <table>
              <tbody>
                <tr>
                  <td>Cost</td>
                  <td className="value">💲{totalCosts.costUsd.toFixed(5)}</td>
                  <td className="value">
                    🪙{totalCosts.costCredits.toFixed(5)}
                  </td>
                </tr>
                <tr>
                  <td>Price</td>
                  <td className="value">💲{totalCosts.priceUsd.toFixed(5)}</td>
                  <td className="value">
                    🪙{totalCosts.priceCredits.toFixed(5)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  }

  const convIsUnread = (conv: IConversation) => {
    return (
      conv.threadControl?.isLiveAgentRequested && !conv.threadControl?.readAt
    );
  };

  const convIsForReview = (conv: IConversation) => {
    return !conv.threadControl?.isLiveAgentRequested && !conv.reviewedAt;
  };
  useEffect(() => {
    if (projectConversations) {
      setItemsTotalCount(projectConversations.totalCount);
    }
    updateConversationItems();
  }, [projectConversations]);

  useEffect(() => {
    const convRoute = `${RouteNames.USER_PROJECTS}/${orgId}${RouteNames.PROJECT_SETTINGS_TAB_NAME}/${id}/${ProjectSettingsType.CONVERSATIONS}`;
    const currentLocation = location.pathname;

    if (convRoute === currentLocation) {
      updateConversationItems();
      setItemsTotalCount(projectConversations?.totalCount || 0);
    }
  }, [location]);

  const isWhatsAppOutbound = (conv: IConversation) => {
    return conv.channel?.referrer?.name === 'whatsAppOutbound';
  };

  const updateConversationItems = async () => {
    setConvByIdError(false);
    let filteredConversations = projectConversations?.data || [];

    const activeItem = filteredConversations.find((i) => i.id === subtab);

    if (!activeItem && filteredConversations.length > 0) {
      let convById = false;

      const getConvById = async (conversationId: string) => {
        const conv = await lazyGetProjectConversationById({
          projectId,
          conversationId,
        });

        if (conv.data) {
          filteredConversations = [conv.data];
          setItemsTotalCount(1);
        } else if (conv.error) {
          showErrorMessage(messageApi, conv.error);
          setConvByIdError(true);
        }

        convById = true;
      };

      if (subtab && shouldGetConvById) {
        await getConvById(subtab);
      }

      setShouldGetConvById(false);

      if (convById) {
        setShowAllConvsButton(true);
      } else {
        const active = filteredConversations[0].id;

        const searchStr = getSearchParamsString();

        navigate(
          `${RouteNames.USER_PROJECTS}/${orgId}${RouteNames.PROJECT_SETTINGS_TAB_NAME}/${id}/${ProjectSettingsType.CONVERSATIONS}/${active}?${searchStr}`
        );
        setShowAllConvsButton(false);
      }
    }

    const getIsActive = (convId: string, index: number) => {
      if (activeItem && activeItem.id === convId) {
        return true;
      } else {
        return index === 0;
      }
    };

    const transformedConversations = filteredConversations.map(
      (conv, index) => {
        const isWhatsapp = isWhatsAppOutbound(conv);
        const refs = conv.channel?.referrer;
        const name =
          isWhatsapp && refs
            ? refs.properties['to'].replace('whatsapp:', '')
            : conv.name;

        const getIcon = (isWhatsapp: boolean) => {
          if (conv.channel?.id === ConversationChannelId.SMS) {
            return (
              <>
                <FaCommentSms className={'conversation-item-icon'} />{' '}
              </>
            );
          }

          if (isWhatsapp) {
            return (
              <>
                <WhatsAppOutlined
                  style={{ color: '#53c41a' }}
                  className={'conversation-item-icon'}
                />{' '}
              </>
            );
          }

          return (
            <>
              <CommentOutlined className={'conversation-item-icon'} />{' '}
            </>
          );
        };

        const getThreadControlIcon = () => {
          if (conv.threadControl?.isLiveAgentRequested) {
            return <span>📢</span>;
          }

          return null;
        };

        return {
          ...conv,
          key: conv.id,
          href: conv.id,
          title: (
            <div style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
              {getIcon(isWhatsapp)}
              <span
                style={{
                  fontWeight:
                    convIsUnread(conv) || convIsForReview(conv) ? 500 : 400,
                }}
              >
                {name}
              </span>
              {getThreadControlIcon()}
            </div>
          ),
          isActive: getIsActive(conv.id, index),
          children: [],
        };
      }
    );
    setConversationItems(transformedConversations);
  };

  const conversationInfoMessage = (message: string, title = '') => {
    return (
      <div className="insight-stats-no-rating">
        {title && <div className="insight-stats-no-rating__title">{title}</div>}
        <div className="insight-stats-no-rating__subtitle">{message}</div>
      </div>
    );
  };

  const openTrace = (messageId: string) => {
    setMessageTraceId(messageId);
  };

  useEffect(() => {
    if (messageTraceId) {
      setTraceModalIsOpen(true);
    }
  }, [messageTraceId]);

  const conversationIsWhatsAppChannel = () => {
    const currentConversation = projectConversations?.data?.find(
      (conv) => conv.id === conversationId
    );
    return currentConversation?.channel?.id === 'whatsapp';
  };

  const sendMessage = async () => {
    const res = await sendLiveMessage({
      projectId,
      conversationId,
      messageText: messageToSend,
    });

    if ('error' in res) {
      showErrorMessage(messageApi, res.error);
    } else {
      messageApi.success('Message sent.');
      setMessageToSend('');
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const renderConversationInput = () => {
    if (!conversationIsWhatsAppChannel()) return null;
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        <Input
          value={messageToSend}
          onChange={(e) => setMessageToSend(e.target.value)}
          onKeyDown={onKeyDown}
        />
        <Button
          type={'primary'}
          disabled={!messageToSend || sendLiveMessageLoading}
          onClick={sendMessage}
        >
          Send
        </Button>
      </div>
    );
  };

  const handleShowAllConvs = () => {
    setShowAllConvsButton(false);
    setShouldGetConvById(false);
    setSearchParams({});
    setConversationId('');
    navigate(
      `${RouteNames.USER_PROJECTS}/${orgId}${RouteNames.PROJECT_SETTINGS_TAB_NAME}/${id}/${ProjectSettingsType.CONVERSATIONS}`
    );
  };

  const getConvTags = () => {
    if (
      !conversationByIdData ||
      !conversationByIdData.tags ||
      conversationByIdData.tags.length === 0
    )
      return null;
    return (
      <div className={'conversation-header-item--tags'}>
        {conversationByIdData.tags.map((tag, i) => (
          <Tag key={i}>{tag.name}</Tag>
        ))}
      </div>
    );
  };

  const renderConversations = () => {
    return (
      <div className={'conversations-container'}>
        {conversationItems.length > 0 ? (
          <>
            <div>
              <Anchor
                affix={false}
                getCurrentAnchor={getCurrentAnchor}
                onClick={handleClick}
                items={conversationItems}
                className={'conversations-filter'}
                onChange={(activeLink) => handleChangeActiveLink(activeLink)}
              />
              {showAllConvsButton && (
                <Button
                  type="link"
                  className={'btn--inline'}
                  onClick={handleShowAllConvs}
                >
                  Show all conversations
                </Button>
              )}
            </div>
            {conversationId ? (
              isLoading || conversationMessagesLoading ? (
                <Skeleton active />
              ) : error ? (
                <div>Ooops, something went wrong.</div>
              ) : conversationMessages ? (
                <div className="insight conversation">
                  {conversationMessages.length > 0 && (
                    <div className="conversation-header">
                      <div
                        className={`conversation-header-item ${
                          getConvTags() ? 'conversation-header--date-tags' : ''
                        }`}
                      >
                        {getConvTags()}
                        <div className="insight-date conversation-msg-date">
                          {formatDateFromISO8601(
                            conversationMessages[0].createAt
                          ) + ' '}
                          (Messages: {conversationMessages.length})
                        </div>
                      </div>
                      <TotalCosts />
                    </div>
                  )}
                  {conversationMessages && (
                    <ConversationMessages
                      conversationMessages={conversationMessages}
                      handleTeachModel={handleTeachModel}
                      handleTeachModelCommand={handleTeachModelCommand}
                      openTrace={openTrace}
                      projectId={projectId}
                      chatId={conversationId}
                      messageApi={messageApi}
                    />
                  )}
                  {renderConversationInput()}
                  <TraceModal
                    isOpen={traceModalIsOpen}
                    setIsOpen={setTraceModalIsOpen}
                    projectId={projectId}
                    conversationId={conversationId}
                    messageTraceId={messageTraceId}
                    setMessageTraceId={setMessageTraceId}
                  />
                </div>
              ) : null
            ) : null}

            <TeachModelModal
              qaKnowledgeContent={qaKnowledgeContent}
              isOpen={teachModalIsOpen}
              setIsOpen={setTeachModalIsOpen}
              projectId={projectId}
              feedbackId={feedbackId}
            />
          </>
        ) : (
          conversationInfoMessage(
            'Looks like there were no conversations on this date. Try selecting another date or check back later for new messages.'
          )
        )}
      </div>
    );
  };

  const onChangePage = async (page: number) => {
    const newSearchParams: { [key: string]: string } = {};

    searchParams.forEach((value, key) => {
      newSearchParams[key] = value;
    });

    newSearchParams[ConversationSearchParams.PAGE] = page.toString();
    setSearchParams(newSearchParams);
  };

  // TODO: refactor, create separate component (+ check records, wa tables and blablabla)
  const paginationComponent = () => (
    <div>
      <Pagination
        defaultPageSize={CONVERSATIONS_PER_PAGE}
        hideOnSinglePage={true}
        showSizeChanger={false}
        onChange={onChangePage}
        current={currentPage}
        defaultCurrent={1}
        total={itemsTotalCount}
      />
    </div>
  );

  const shouldShowControlConversationBtns = () => {
    const currentConversation = projectConversations?.data?.find(
      (conv) => conv.id === conversationId
    );

    return !!(currentConversation && isWhatsAppOutbound(currentConversation));
  };

  const isLiveAgentRequested = () => {
    return currentConversation?.threadControl?.isLiveAgentRequested === true;
  };

  const takeControlIsDisabled = () => {
    if (currentConversation && !isLiveAgentRequested()) {
      return false;
    }

    return true;
  };

  const handleTakeControl = async () => {
    const res = await takeControl({
      projectId,
      conversationId,
    });

    if ('error' in res) {
      showErrorMessage(messageApi, res.error);
    } else {
      messageApi.success('Successfully.');
      lazyGetProjectConversations({ projectId, params: conversationParams });
      lazyGetConversationMessages({
        projectId,
        conversationId,
        orgId,
      });
    }
  };

  const handleReleaseControl = async () => {
    const res = await releaseControl({
      projectId,
      conversationId,
    });

    if ('error' in res) {
      showErrorMessage(messageApi, res.error);
    } else {
      messageApi.success('Successfully.');
      lazyGetProjectConversations({ projectId, params: conversationParams });
      lazyGetConversationMessages({
        projectId,
        conversationId,
        orgId,
      });
    }
  };

  const renderConversationsHeader = () => {
    return (
      <div className={'conversations-header'}>
        <ConversationReviewStatusShortcuts
          filterForm={filterForm}
          itemsPerPage={CONVERSATIONS_PER_PAGE}
        />
        <div
          style={{
            fontSize: 14,
            marginBottom: 20,
            display: 'flex',
            flexWrap: 'wrap',
            gap: 16,
          }}
        >
          {shouldShowControlConversationBtns() && (
            <>
              {takeControlIsDisabled() ? (
                <Button
                  onClick={handleReleaseControl}
                  disabled={!takeControlIsDisabled() || releaseControlLoading}
                >
                  Release Control
                </Button>
              ) : (
                <Button
                  onClick={handleTakeControl}
                  disabled={takeControlIsDisabled() || takeControlLoading}
                >
                  Take Control
                </Button>
              )}
            </>
          )}
          {!currentConversation ? null : isLiveAgentRequested() ? (
            <ConversationMarkToggle
              projectId={projectId}
              conversation={currentConversation}
              conversationParams={conversationParams}
              messageApi={messageApi}
            />
          ) : (
            <ConversationReviewToggle
              projectId={projectId}
              conversation={currentConversation}
              conversationParams={conversationParams}
              messageApi={messageApi}
            />
          )}
          {conversationId && (
            <Button
              icon={<LinkOutlined />}
              onClick={async () => {
                const result = await lazyGetShareLink({
                  projectId: projectId,
                  chatId: conversationId,
                });

                if (!result.data) {
                  await showErrorMessage(messageApi, {
                    data: { error: result.error },
                  });
                } else {
                  const url = result.data;

                  navigator.clipboard.writeText(url).then(async () => {
                    await messageApi.success('URL was copied successfully!');
                  });
                }
              }}
            >
              Share Link
            </Button>
          )}
          <ConversationFilter
            filterForm={filterForm}
            itemsPerPage={CONVERSATIONS_PER_PAGE}
          />
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 16,
            alignItems: 'center',
            width: '100%',
            fontSize: 14,
            flexWrap: 'wrap',
            marginBottom: 16,
          }}
        >
          <div>Conversations: {itemsTotalCount}</div>
          {paginationComponent()}
        </div>
      </div>
    );
  };

  return (
    <div className={'conversations-min-height'}>
      {contextHolder}
      {projectConversationsLoading ||
      lazyProjectConversationsLoading ||
      lazyProjectConversationByIdLoading ||
      currentOrganizationLoading ||
      (!projectConversationsLoading &&
        !projectConversations &&
        !projectConversationsError) ? (
        <Loading height={'300px'} />
      ) : projectConversationsError || lazyProjectConversationsError ? (
        <div>Ooops, something went wrong.</div>
      ) : convByIdError ? (
        <Result
          status="error"
          title="Can't get conversation."
          extra={
            <Button type="primary" onClick={handleShowAllConvs}>
              Go to all conversations
            </Button>
          }
        />
      ) : projectConversations && projectConversations.data.length > 0 ? (
        <>
          {renderConversationsHeader()}
          {renderConversations()}
        </>
      ) : projectConversations && projectConversations.data.length === 0 ? (
        <>
          {renderConversationsHeader()}
          {conversationInfoMessage(
            'Looks like there were no conversations on this date. Try selecting another date or check back later.',
            'Conversations'
          )}
        </>
      ) : null}
    </div>
  );
};

export default Conversations;
