import React, { FC, useState } from 'react';
import { Button, Form, Input, Popover, Select } from 'antd';
import { IoFilter } from 'react-icons/io5';
import {
  campaignFilterOperatorsList,
  FilterOperators,
  filterValueIsRequired,
  getFilterOperatorOptions,
} from './filters-helpers';
import ICampaignsFilter from '../../../../types/filters/ICampaignsFilter';
import { WhatsAppOutboundStatus } from '../../../../shared/constants';
import { TooltipPlacement } from 'antd/es/tooltip';

interface ICampaignColumnFilterProps {
  columnName: string;
  currentFilters: ICampaignsFilter[];
  setCurrentFilters: React.Dispatch<React.SetStateAction<ICampaignsFilter[]>>;
  placement: TooltipPlacement;
  overlayClassName: string;
}

// TODO: refactor all filters ? reuse ?
const CampaignColumnFilter: FC<ICampaignColumnFilterProps> = ({
  columnName,
  currentFilters,
  setCurrentFilters,
  placement,
  overlayClassName,
}) => {
  const [open, setOpen] = useState(false);
  const [applyIsDisabled, setApplyIsDisabled] = useState(true);
  const [form] = Form.useForm();
  const operator = Form.useWatch('operator', form);

  const hide = () => {
    setOpen(false);
  };

  const resetFilter = () => {
    const filters = currentFilters.filter((f) => f.filterName !== columnName);
    setCurrentFilters(filters);
    hide();
  };

  const applyFilter = () => {
    const filters = currentFilters.filter((f) => f.filterName !== columnName);
    setCurrentFilters([
      ...filters,
      {
        filterName: columnName,
        operatorName: `${columnName}-operator`,
        ...form.getFieldsValue(),
      },
    ]);

    hide();
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleChange = async () => {
    const values = await form.getFieldsValue();
    const { operator, value } = values;

    const needValue = filterValueIsRequired(operator);

    if ((operator && value) || !needValue) {
      setApplyIsDisabled(false);
    } else {
      setApplyIsDisabled(true);
    }
  };

  const getValueFormItemContent = () => {
    if (columnName === 'status') {
      return (
        <Select
          options={Object.values(WhatsAppOutboundStatus).map((status) => ({
            value: status,
            label: status,
          }))}
          dropdownStyle={{ zIndex: 2000 }}
          style={{
            width: 160,
          }}
          onChange={handleChange}
          getPopupContainer={(trigger) => trigger.parentElement}
        />
      );
    }

    return (
      <Input
        autoComplete={'off'}
        placeholder={filterValueIsRequired(operator) ? 'Enter a value' : ''}
        disabled={!filterValueIsRequired(operator)}
      />
    );
  };

  const filterForm = () => (
    <div className={'table-filter-form-container'}>
      <Form
        form={form}
        onChange={handleChange}
        layout={'inline'}
        requiredMark={false}
        initialValues={{
          operator: FilterOperators.IS,
        }}
      >
        <Form.Item name="operator">
          <Select
            options={getFilterOperatorOptions(campaignFilterOperatorsList)}
            dropdownStyle={{ zIndex: 2000 }}
            style={{
              width: 160,
            }}
            onChange={handleChange}
            getPopupContainer={(trigger) => trigger.parentElement}
          />
        </Form.Item>
        <Form.Item name="value" style={{ marginInlineEnd: 0 }}>
          {getValueFormItemContent()}
        </Form.Item>
      </Form>
    </div>
  );

  const filterActions = () => (
    <div className={'table-filter-actions-container'}>
      <Button onClick={resetFilter}>Reset</Button>
      <Button onClick={hide}>Cancel</Button>
      <Button type={'primary'} disabled={applyIsDisabled} onClick={applyFilter}>
        Apply
      </Button>
    </div>
  );

  const filterContent = () => (
    <div className={'table-filter-content'}>
      In this view, show records where{' '}
      <span style={{ fontWeight: 500 }}>{columnName}</span>
      {filterForm()}
      {filterActions()}
    </div>
  );

  const renderFilter = () => {
    return (
      <Popover
        content={filterContent()}
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
        placement={placement}
        overlayClassName={overlayClassName}
      >
        <Button size={'small'}>
          <IoFilter style={{ marginTop: 4 }} />
        </Button>
      </Popover>
    );
  };

  return renderFilter();
};

export default CampaignColumnFilter;
