import React, { FC } from 'react';
import { Form, Select } from 'antd';
import { EngineTypes } from '../../../shared/constants';
import IUserSubscription from '../../../types/IUserSubscription';
import { useAppSelector } from '../../../hooks/redux-hooks';

interface IEngineOptionsFormItemProps {
  modelLabel: string;
  disabled: boolean;
  currentUserSubscription: IUserSubscription | null;
  handleEngineChange: (engineValue: string) => void;
  projectId: string;
}

const EngineOptionsFormItem: FC<IEngineOptionsFormItemProps> = ({
  modelLabel,
  disabled,
  currentUserSubscription,
  handleEngineChange,
  projectId,
}) => {
  const { engineOptions } = useAppSelector((state) => state.projectReducer);
  const optionIsDisabled = (optionValue: EngineTypes) => {
    if (optionValue !== EngineTypes.GPT3) {
      return !currentUserSubscription?.currentPlan.features.gpt_4;
    }
    return false;
  };

  return (
    <Form.Item
      className={'model-item'}
      label={
        <>
          Model
          <span className={'model-label-additional-info'}>{modelLabel}</span>
        </>
      }
      name="engine"
    >
      <Select
        disabled={disabled}
        options={engineOptions.map((option) => ({
          value: option.value,
          label: option.label,
          disabled: optionIsDisabled(option.value),
        }))}
        onChange={handleEngineChange}
      />
    </Form.Item>
  );
};

export default EngineOptionsFormItem;
