import { Dispatch, FC, SetStateAction, useState } from 'react';
import { message, Modal } from 'antd';
import { showErrorMessage } from '../../../shared/helpers';
import { whatsAppOutboundApi } from '../../../services/what-app-outbound-service';

interface IArchiveCampaignModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  campaignId: string;
  projectId: string;
  onRefresh: () => void;
}

const ArchiveCampaignModal: FC<IArchiveCampaignModal> = ({
  isOpen,
  setIsOpen,
  campaignId,
  projectId,
  onRefresh,
}) => {
  const [archiveCampaign, { isLoading }] =
    whatsAppOutboundApi.useArchiveCampaignMutation();
  const [messageApi, contextHolder] = message.useMessage();
  const [archiveDisabled, setArchiveDisabled] = useState(false);

  const handleCancel = (e: any) => {
    e.stopPropagation();
    setIsOpen(false);
  };

  const handleOk = async (e: any) => {
    e.stopPropagation();
    setArchiveDisabled(true);
    const result = await archiveCampaign({ projectId, campaignId });

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
      setIsOpen(false);
    } else {
      await messageApi.success('The campaign has been archived.', 1);
      onRefresh();
      setIsOpen(false);
    }

    setIsOpen(false);
    setArchiveDisabled(false);
  };

  return (
    <Modal
      wrapClassName={'modal'}
      title="Archive campaign"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={isLoading}
      okButtonProps={{ disabled: archiveDisabled, danger: true }}
    >
      {contextHolder}
      <p>
        Please confirm the archiving of this campaign. This action cannot be
        undone.
      </p>
    </Modal>
  );
};

export default ArchiveCampaignModal;
