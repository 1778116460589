import React, { FC } from 'react';
import { Alert } from 'antd';

interface ITableSelectedRowsInfo {
  totalCount: number;
  selectedCount: number;
  clearSelection: () => void;
  handleSelectAll: () => void;
}

const TableSelectedRowsInfo: FC<ITableSelectedRowsInfo> = ({
  totalCount,
  selectedCount,
  clearSelection,
  handleSelectAll,
}) => {
  const generateSelectedInfo = (
    totalCount: number,
    selectedCount: number,
    clearSelection: () => void,
    handleSelectAll: () => void
  ) => {
    if (!totalCount) return null;
    const renderText = () => {
      if (selectedCount === totalCount) {
        return (
          <div>
            {selectedCount} existing records are selected.{' '}
            <span
              className={'records-selected-info-btn'}
              onClick={clearSelection}
            >
              Clear selection
            </span>
          </div>
        );
      } else {
        return (
          <div>
            {selectedCount} existing records are selected.{' '}
            <span
              className={'records-selected-info-btn'}
              onClick={handleSelectAll}
            >
              Select all {totalCount} existing records
            </span>
          </div>
        );
      }
    };

    return renderText();
  };

  return selectedCount > 0 ? (
    <Alert
      message={generateSelectedInfo(
        totalCount,
        selectedCount,
        clearSelection,
        handleSelectAll
      )}
      type="info"
      style={{ textAlign: 'center', marginTop: 24, marginBottom: 24 }}
    />
  ) : null;
};

export default TableSelectedRowsInfo;
