export default class FormUtils {
  public static createSelectOptionsFromEnum<T extends Record<string, string>>(
    enumObject: T
  ): { value: keyof T; label: string }[] {
    return Object.values(enumObject).map((key) => {
      return {
        value: key,
        label: key.slice(0, 1).toUpperCase() + key.slice(1),
      };
    });
  }
}
