import { Button, Form, Input, message } from 'antd';
import { FC, useState } from 'react';
import { usersAPI } from '../../../services/users-service';
import { showErrorMessage } from '../../../shared/helpers';
import { passwordRules } from '../../../shared/rules';
import { Rule } from 'antd/es/form';

interface IUpdatePasswordForm {
  current_password: string;
  new_password: string;
  confirm_new_password: string;
}

const PasswordSetting: FC = () => {
  const [updateUserPassword, {}] = usersAPI.useUpdateUserPasswordMutation();
  const [form] = Form.useForm();
  const [updateDisabled, setUpdateDisabled] = useState<boolean>(true);
  const [messageApi, contextHolder] = message.useMessage();

  const handleUpdatePassword = async (values: IUpdatePasswordForm) => {
    setUpdateDisabled(true);

    const data = {
      oldPassword: values.current_password,
      newPassword: values.new_password,
    };

    const result = await updateUserPassword(data);

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
    } else {
      await messageApi.success('Password has been updated.');
      form.resetFields();
    }
  };

  const handleChange = () => {
    const values = form.getFieldsValue() as IUpdatePasswordForm;

    let disabled = false;

    if (
      !values.current_password ||
      !values.new_password ||
      !values.confirm_new_password
    ) {
      disabled = true;
    }

    if (values.new_password !== values.confirm_new_password) {
      disabled = true;
    }

    setUpdateDisabled(disabled);
  };

  const confirmPasswordRules: Rule[] = [
    ...passwordRules,
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue('new_password') === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Password mismatch.'));
      },
    }),
  ];

  return (
    <div className="profile-setting-content-container">
      {contextHolder}
      <Form
        form={form}
        layout="vertical"
        className="profile-settings-form"
        onChange={handleChange}
        onFinish={handleUpdatePassword}
        requiredMark={false}
      >
        <Form.Item
          label="Current password"
          name="current_password"
          rules={passwordRules}
        >
          <Input.Password placeholder="Input your password" />
        </Form.Item>
        <Form.Item
          label="New password"
          name="new_password"
          rules={passwordRules}
        >
          <Input.Password placeholder="Input new password" />
        </Form.Item>
        <Form.Item
          label="Confirm new password"
          name="confirm_new_password"
          dependencies={['new_password']}
          rules={confirmPasswordRules}
        >
          <Input.Password placeholder="Confirm new password" />
        </Form.Item>
        <Form.Item className="profile-settings-form-btn-container">
          <Button type="primary" htmlType="submit" disabled={updateDisabled}>
            Update
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PasswordSetting;
