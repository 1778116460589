import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IUserSubscription from '../../types/IUserSubscription';
import { subscriptionAPI } from '../../services/subscription-service';
import IUsage from '../../types/IUsage';

interface ISubscriptionState {
  userPersonalSubscription: IUserSubscription | null; // only personal
  currentUserSubscription: IUserSubscription | null; // can be org or personal
  userUsage: IUsage | null;
  subscriptionIsActive: boolean;
  subscriptionIsLoading: boolean;
}

const initialState: ISubscriptionState = {
  userPersonalSubscription: null,
  currentUserSubscription: null,
  userUsage: null,
  subscriptionIsActive: true,
  subscriptionIsLoading: false,
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setInitialSubscriptionState: (state) => {
      state.userPersonalSubscription = null;
      state.currentUserSubscription = null;
      state.userUsage = null;
      state.subscriptionIsActive = true;
      state.subscriptionIsLoading = false;
    },
    setUserPersonalSubscription: (
      state,
      action: PayloadAction<IUserSubscription | null>
    ) => {
      state.userPersonalSubscription = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      subscriptionAPI.endpoints.getUserSubscription.matchFulfilled,
      (state, action: PayloadAction<IUserSubscription>) => {
        state.currentUserSubscription = action.payload;
        state.subscriptionIsLoading = false;
      }
    );
    builder.addMatcher(
      subscriptionAPI.endpoints.getUserSubscription.matchRejected,
      (state) => {
        state.subscriptionIsLoading = false;
      }
    );
    builder.addMatcher(
      subscriptionAPI.endpoints.getUserSubscription.matchPending,
      (state) => {
        state.subscriptionIsLoading = true;
      }
    );
    builder.addMatcher(
      subscriptionAPI.endpoints.getUsage.matchFulfilled,
      (state, action: PayloadAction<IUsage>) => {
        state.userUsage = action.payload;
      }
    );
    builder.addMatcher(
      subscriptionAPI.endpoints.getSubscriptionIsActive.matchFulfilled,
      (state, action: PayloadAction<boolean>) => {
        state.subscriptionIsActive = action.payload;
      }
    );
  },
});

export default subscriptionSlice.reducer;
