import React, { FC, useEffect, useRef, useState } from 'react';
import './widget-pro-showcase.scss';
import { useParams } from 'react-router-dom';
import { projectsAPI } from '../../services/projects-service';
import { skipToken } from '@reduxjs/toolkit/query';
import { Result } from 'antd';
import {
  connectWidgetV2Script,
  getStandardMessageFromErrorData,
} from '../../shared/helpers';
import Loading from '../../components/loading/loading';
import WidgetProSettingsPanel from '../../components/widget-pro-settings-panel/widget-pro-settings-panel';
import { delay, ON_SDK_LOADED, SDK_NAME } from '../../shared/constants';

const maxWidgetHeight = 713;
const maxWidgetWidth = 393;
const widgetProSettingsPanelId = 'widgetProSettingsPanelId';
const gapBetweenWidgetAndSettings = 50;

const WidgetProShowcase: FC = () => {
  const { id } = useParams();
  const {
    data: project,
    isLoading,
    error,
  } = projectsAPI.useGetProjectStartInfoQuery(id ?? skipToken);
  const embedWidgetV2Ref = useRef<HTMLDivElement>(null);
  const [widgetV2ScriptLoading, setWidgetV2ScriptLoading] =
    useState<boolean>(true);

  useEffect(() => {
    if (id) {
      //@ts-ignore
      window.widgetConfigs = [
        {
          id: id,
          allowFullScreenMode: true,
          closeButton: true,
          shadowEnabled: false,
          glowEnabled: true,
          width: `${maxWidgetWidth}px`,
          height: `${maxWidgetHeight}px`,
          rememberState: 'never',
          followPages: false,
        },
      ];
    }
  }, [id]);

  const showSettingPanel = async () => {
    await delay(2000);
    const panel = document.getElementById(widgetProSettingsPanelId);
    if (panel) {
      alignWidgetSettingsPanel();
      panel.classList.add('widget-pro-settings-panel--show');
    }
  };

  useEffect(() => {
    if (!widgetV2ScriptLoading) {
      showSettingPanel();
    }
  }, [widgetV2ScriptLoading]);

  const getHeightOfWidgetSettingsPanel = () => {
    const settingsPanel = document.getElementById(widgetProSettingsPanelId);
    if (!settingsPanel) return 0;
    return settingsPanel.clientHeight;
  };

  const getWidgetHeight = () => {
    const widget = document.getElementById('embed-widget');
    const defaultHeight =
      //@ts-ignore
      +window.widgetConfigs[0].height.replace('px', '') || 0;
    if (!widget) return defaultHeight;
    return widget.clientHeight || defaultHeight;
  };

  const alignWidgetSettingsPanel = () => {
    const height = document.documentElement.clientHeight;
    const width = document.documentElement.clientWidth;
    let widgetHeight = getWidgetHeight();
    const defaultBtnHeight = 52;
    const isMobileMode = width < 800;
    widgetHeight =
      widgetHeight >= height && isMobileMode ? defaultBtnHeight : widgetHeight;
    const startPositionFromBottom = height * 0.55;
    const panel = document.getElementById(widgetProSettingsPanelId);
    const panelHeight = getHeightOfWidgetSettingsPanel();
    if (!panel) return;
    const newPositionFromBottom =
      startPositionFromBottom -
      panelHeight -
      gapBetweenWidgetAndSettings -
      widgetHeight / 2;
    panel.style.setProperty('bottom', `${newPositionFromBottom}px`);
  };

  const alignWidget = () => {
    const widget = document.getElementById('embed-widget');
    if (!widget) return;
    widget.style.setProperty('transform', 'translate(50%, 50%)');
  };

  const alignNotFullScreen = async () => {
    //@ts-ignore
    if (!window[SDK_NAME].isFullScreen && window.innerWidth < 800) {
      //@ts-ignore
      window[SDK_NAME].setPosition('55%', '50%');
      await delay(50);
      alignWidget();
      alignWidgetSettingsPanel();
    }
  };

  useEffect(() => {
    const onResize = () => {
      const width = document.documentElement.clientWidth;
      const height = document.documentElement.clientHeight;

      const w = width > maxWidgetWidth ? maxWidgetWidth : width;

      const heightOfSettingsPanel = getHeightOfWidgetSettingsPanel();
      const gapsTopAndBottom = gapBetweenWidgetAndSettings * 2;
      const offset =
        gapsTopAndBottom + heightOfSettingsPanel + gapBetweenWidgetAndSettings;

      const h =
        height - offset > maxWidgetHeight ? maxWidgetHeight : height - offset;

      //@ts-ignore
      window[SDK_NAME].setSize(`${w}px`, `${h}px`);

      alignWidgetSettingsPanel();

      alignNotFullScreen();
    };

    //@ts-ignore
    window[ON_SDK_LOADED] = () => {
      onResize();
      window.addEventListener('resize', onResize);

      const f = async () => {
        //@ts-ignore
        window[SDK_NAME].setPosition('55%', '50%');
        await delay(100);
        alignWidget();
      };

      if (window.innerWidth >= 800) {
        f();
      }

      // @ts-ignore
      window[SDK_NAME].events.on('close', () => {
        alignWidget();
        alignWidgetSettingsPanel();
      });

      // @ts-ignore
      window[SDK_NAME].events.on('open', () => {
        window.setTimeout(() => {
          alignNotFullScreen();
        }, 200);
      });
    };

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    const ref = embedWidgetV2Ref.current;

    if (!ref || isLoading) return;

    connectWidgetV2Script(setWidgetV2ScriptLoading);

    return () => {
      const script = document.getElementById('widgetScript');
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [embedWidgetV2Ref, isLoading]);

  const getComponent = () => {
    if (isLoading) {
      return <Loading />;
    }
    if (error) {
      return errorComponent();
    }
    if (project) {
      return (
        <>
          {widgetV2ScriptLoading && <Loading />}
          <div id="embed-widget" ref={embedWidgetV2Ref}></div>
          <WidgetProSettingsPanel
            project={project}
            widgetProSettingsPanelId={widgetProSettingsPanelId}
          />
        </>
      );
    }
    return null;
  };

  const errorComponent = () => {
    const message = getStandardMessageFromErrorData(error);

    return (
      <Result
        className={'widget-pro-showcase__error'}
        status="error"
        title="Ooops, something went wrong."
        subTitle={message}
      />
    );
  };

  return (
    <div
      className={'widget-pro-showcase'}
      style={{ gap: gapBetweenWidgetAndSettings }}
    >
      {getComponent()}
    </div>
  );
};

export default WidgetProShowcase;
