import {
  Button,
  Form,
  Input,
  message,
  Result,
  Select,
  UploadFile,
  UploadProps,
} from 'antd';
import React, { useEffect, useState } from 'react';
import './create-project.scss';
import ICreateProjectInfo from '../../types/ICreateProjectInfo';
import { projectsAPI } from '../../services/projects-service';
import {
  BotTypes,
  CreateProjectDescriptionModalTypes,
  EngineTypes,
  ProjectSettingsType,
  V_REQUIRED_FIELD,
} from '../../shared/constants';
import {
  getAllowedKnowledgeFileTypesString,
  getRandomInt,
  resizeFileTo256PNG,
  showErrorMessage,
} from '../../shared/helpers';
import { RouteNames } from '../../router/router';
import { useNavigate, useParams } from 'react-router-dom';
import CheckAuth from '../../components/check-auth/check-auth';
import BreadcrumbNav from '../../components/breadcrumb-nav/breadcrumb-nav';
import { GA_CREATE_BOT, GA_UPLOAD_FILE } from '../../shared/google-analytics';
import { useAppSelector } from '../../hooks/redux-hooks';
import SubscriptionInactiveWarning from '../../components/subscription-inactive-warning/subscription-inactive-warning';
import useHasAccessToOrg from '../../hooks/useHasAccessToOrg';
import NoOrgAccessWarning from '../../components/no-org-access-warning/no-org-access-warning';
import { InboxOutlined, InfoCircleOutlined } from '@ant-design/icons';
import UploadProjectImg from '../../components/upload-project-img/upload-project-img';
import RolePersonalityProjectDescriptionModal from '../../components/modals/create-project-description-modal/role-personality-project-description-modal';
import Dragger from 'antd/es/upload/Dragger';
import Loading from '../../components/loading/loading';
import EngineOptionsFormItem from '../../components/project-settings/settings-form/engine-options-form-item';

const CreateProject = () => {
  const { orgId } = useParams();
  const [
    lazyGetProjectOptions,
    {
      data: projectOptionsData,
      isLoading: projectOptionsLoading,
      error: projectOptionsError,
    },
  ] = projectsAPI.useLazyGetProjectOptionsQuery();
  const {
    options: projectOptions,
    engineOptions,
    vectorStores,
  } = useAppSelector((state) => state.projectReducer);
  const [createProject, { isLoading }] = projectsAPI.useCreateProjectMutation();
  const { subscriptionIsActive, currentUserSubscription } = useAppSelector(
    (state) => state.subscriptionReducer
  );
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [image, setImage] = React.useState<UploadFile | null>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [createDisabled, setCreateDisabled] = useState(true);
  const [modelLabel, setModelLabel] = useState('');
  const [disabledAll, setDisabledAll] = useState(false);
  const noAccess = !useHasAccessToOrg(orgId);
  const [roleDescriptionModalIsOpen, setRoleDescriptionModalIsOpen] =
    useState(false);
  const [
    personalitiesDescriptionModalIsOpen,
    setPersonalitiesDescriptionModalIsOpen,
  ] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [draggerDisabled, setDraggerDisabled] = useState(false);
  const [addProjectKnowledge, { isLoading: addProjectKnowledgeLoading }] =
    projectsAPI.useAddProjectKnowledgeMutation();
  const [updateProjectImage, { isLoading: updateProjectImageLoading }] =
    projectsAPI.useUpdateProjectImageMutation();

  useEffect(() => {
    if (!projectOptions) {
      lazyGetProjectOptions();
    }
  }, [projectOptions]);

  useEffect(() => {
    if (currentUserSubscription) {
      const gpt4IsAvailable =
        currentUserSubscription.currentPlan.features.gpt_4;
      const engineDefaultValue = gpt4IsAvailable
        ? EngineTypes.GPT4
        : EngineTypes.GPT3;
      form.setFieldValue('engine', engineDefaultValue);
      handleEngineChange(engineDefaultValue);
    }
  }, [currentUserSubscription]);

  useEffect(() => {
    if (subscriptionIsActive) {
      setDisabledAll(false);
    } else {
      setDisabledAll(true);
    }
  }, [subscriptionIsActive]);

  const openSendFilesMessage = () => {
    messageApi.open({
      type: 'loading',
      content: 'Sending files...',
      duration: 0,
      onClick: () => {
        messageApi.destroy();
      },
    });
  };

  const handleCreate = async (values: ICreateProjectInfo) => {
    setCreateDisabled(true);
    setDraggerDisabled(true);

    const result = await createProject({
      ...values,
      type: BotTypes.CHATBOT,
      subTitle: '',
    });

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
    } else {
      GA_CREATE_BOT(BotTypes.CHATBOT);
      messageApi.success('Project has been created.');

      if (image) {
        const data = new FormData();
        const fileName = image.name;
        if (image.type === 'image/gif') {
          data.append('file', image as unknown as Blob, fileName);
        } else {
          const resizedImage = await resizeFileTo256PNG(
            image as unknown as Blob
          );
          data.append('file', resizedImage as Blob, fileName);
        }

        const imgResult = await updateProjectImage({
          id: result.data.id,
          body: data,
        });

        if ('error' in imgResult) {
          showErrorMessage(messageApi, imgResult.error);
        } else {
          messageApi.success('Image has been updated.');
        }

        setImage(null);
      } else {
        const getAvatarBlob = async () => {
          const avatarNum = getRandomInt(0, 15);
          try {
            const imageModule = await import(
              `../../assets/project-avatars/avatar-${avatarNum}.png`
            );
            const imageUrl = imageModule.default;
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            return blob;
          } catch (error) {
            console.error('Error fetching image:', error);
          }
        };

        const blob = await getAvatarBlob();

        if (blob) {
          const data = new FormData();
          const resizedImage = await resizeFileTo256PNG(blob);
          data.append('file', resizedImage as Blob, 'avatar.png');
          const imgResult = await updateProjectImage({
            id: result.data.id,
            body: data,
          });

          if ('error' in imgResult) {
            console.error('Error updating project image:', imgResult.error);
          }
        }
      }

      await uploadFiles(result.data.id);

      navigate(
        `${RouteNames.USER_PROJECTS}/${orgId}${RouteNames.PROJECT_SETTINGS_TAB_NAME}/${result.data.id}/${ProjectSettingsType.KNOWLEDGE}`
      );
    }
    setCreateDisabled(false);
    setDraggerDisabled(false);
  };

  const uploadFiles = async (projectId: string) => {
    let newFileList: UploadFile[] = JSON.parse(JSON.stringify(fileList));
    const allPromises = [];

    if (fileList.length > 0) {
      openSendFilesMessage();
    }

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      const formData = new FormData();
      // TODO: style ???
      // formData.append('json', JSON.stringify({ style: EmbeddingsStyle.RAW }));
      formData.append('file', file.originFileObj as Blob);
      const result = addProjectKnowledge({
        id: projectId,
        body: formData,
      });

      allPromises.push(result);

      result
        .unwrap()
        .then((res) => {
          const fileExtension = file.name.split('.').pop();
          GA_UPLOAD_FILE(fileExtension || 'N/A');
          messageApi.success(`File ${file.name} has been submitted.`, 1);
        })
        .catch((err) => {
          showErrorMessage(messageApi, err);
        })
        .finally(() => {
          newFileList = newFileList.filter((f) => f.uid !== file.uid);
          setFileList(newFileList);
          if (newFileList.length === 0) {
            setFileList([]);
            setDraggerDisabled(false);
          }
        });
    }

    await Promise.allSettled(allPromises).then((results) => {});
  };

  const handleChange = () => {
    if (!form.getFieldValue('name')) {
      setCreateDisabled(true);
    } else {
      setCreateDisabled(false);
    }
  };

  const handleEngineChange = (value: string) => {
    const description =
      engineOptions.find((option) => option.value === value)?.description || '';

    setModelLabel(description);

    handleChange();
  };

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    onChange: async (info) => {
      setFileList(info.fileList);
    },
    onDrop(e) {},
    onRemove: (file) => {},
    beforeUpload: () => {
      return false;
    },
    fileList,
  };

  useEffect(() => {
    if (draggerDisabled) {
      // uploadFiles();
    }
  }, [draggerDisabled]);

  const createProjectPage = () => (
    <div className="create-project">
      {contextHolder}
      <div className="container">
        <div className="create-project__inner">
          <BreadcrumbNav />
          {noAccess ? (
            <NoOrgAccessWarning />
          ) : projectOptions ? (
            <>
              {subscriptionIsActive ? null : <SubscriptionInactiveWarning />}
              <Form
                form={form}
                layout="vertical"
                initialValues={{
                  name: '',
                  engine: currentUserSubscription?.currentPlan.features.gpt_4
                    ? EngineTypes.GPT4Turbo
                    : EngineTypes.GPT3,
                  // file: '',
                  roleId: projectOptions.roles[1].id,
                  personalityId: projectOptions.personalities[1].id,
                  vectorStore: vectorStores[0],
                }}
                className="create-form"
                requiredMark={false}
                onFinish={handleCreate}
                onChange={handleChange}
              >
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[{ required: true, message: V_REQUIRED_FIELD }]}
                >
                  <Input
                    placeholder="Enter name"
                    autoComplete={'off'}
                    disabled={disabledAll}
                  />
                </Form.Item>
                <EngineOptionsFormItem
                  modelLabel={modelLabel}
                  currentUserSubscription={currentUserSubscription}
                  disabled={!subscriptionIsActive}
                  handleEngineChange={handleEngineChange}
                  projectId=""
                />
                <Form.Item
                  label={
                    <div>
                      Role of chatbot{' '}
                      <InfoCircleOutlined
                        className={'create-project__tooltip-icon'}
                        onClick={() => {
                          setRoleDescriptionModalIsOpen(true);
                        }}
                      />
                    </div>
                  }
                  name="roleId"
                >
                  <Select
                    disabled={disabledAll}
                    options={projectOptions.roles.map((role) => ({
                      value: role.id,
                      label: role.name,
                    }))}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <div>
                      Personality{' '}
                      <InfoCircleOutlined
                        className={'create-project__tooltip-icon'}
                        onClick={() => {
                          setPersonalitiesDescriptionModalIsOpen(true);
                        }}
                      />
                    </div>
                  }
                  name="personalityId"
                >
                  <Select
                    disabled={disabledAll}
                    options={projectOptions.personalities.map(
                      (personality) => ({
                        value: personality.id,
                        label: personality.name,
                      })
                    )}
                  />
                </Form.Item>
                <Form.Item
                  hidden={vectorStores.length <= 1}
                  label={
                    <div>
                      Vector store{' '}
                      <span className={'model-label-additional-info'}>
                        this option can't be changed later.
                      </span>
                    </div>
                  }
                  name="vectorStore"
                >
                  <Select
                    disabled={disabledAll}
                    options={vectorStores.map((store) => {
                      return {
                        value: store,
                        label: store.charAt(0).toUpperCase() + store.slice(1),
                      };
                    })}
                  />
                </Form.Item>
                <Form.Item
                  label="Bot Image (optional)"
                  // tooltip={{ title: 'Tooltip', icon: <InfoCircleOutlined /> }}
                  name="file"
                >
                  <UploadProjectImg image={image} setImage={setImage} />
                </Form.Item>
                <Form.Item>
                  <Dragger
                    {...props}
                    accept={getAllowedKnowledgeFileTypesString()}
                    disabled={draggerDisabled || !subscriptionIsActive}
                    className={
                      'knowledge-dragger create-project--knowledge-dragger'
                    }
                    // className={showUpload ? '' : 'data-source-upload-dragger-hidden'}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      Support for a single or bulk upload.
                    </p>
                  </Dragger>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="create-form-btn"
                    loading={isLoading}
                    disabled={createDisabled || disabledAll}
                  >
                    Create
                  </Button>
                </Form.Item>
              </Form>
              <RolePersonalityProjectDescriptionModal
                isOpen={roleDescriptionModalIsOpen}
                setIsOpen={setRoleDescriptionModalIsOpen}
                type={CreateProjectDescriptionModalTypes.ROLES}
              />
              <RolePersonalityProjectDescriptionModal
                isOpen={personalitiesDescriptionModalIsOpen}
                setIsOpen={setPersonalitiesDescriptionModalIsOpen}
                type={CreateProjectDescriptionModalTypes.PERSONALITIES}
              />
            </>
          ) : projectOptionsLoading ? (
            <Loading />
          ) : projectOptionsError ? (
            <Result
              status="error"
              title="Can't get project options, try again later."
            />
          ) : null}
        </div>
      </div>
    </div>
  );

  return (
    <CheckAuth
      component={createProjectPage()}
      allowedForDomainTenantMember={false}
    />
  );
};

export default CreateProject;
