import { ContactRecordType } from '../IContact';

export default interface IDateRecord {
  type: ContactRecordType.DATE;
  date: string;
  useYear: boolean;
  label: DateLabel;
  customLabel?: string;
}

export enum DateLabel {
  BIRTHDAY = 'birthday',
  ANNIVERSARY = 'anniversary',
  OTHER = 'other',
}
