import React, { FC, useEffect } from 'react';
import { Modal, Pagination, Skeleton, Typography } from 'antd';

interface IKnowledgePreviewModal {
  isOpen: boolean;
  closeModal: () => void;
  isLoading: boolean;
  previewKnowledgeContent: string[];
  setPreviewKnowledgeContent: React.Dispatch<React.SetStateAction<string[]>>;
}

const KnowledgePreviewModal: FC<IKnowledgePreviewModal> = ({
  isOpen,
  closeModal,
  isLoading,
  previewKnowledgeContent,
  setPreviewKnowledgeContent,
}) => {
  const { Paragraph } = Typography;

  const [currentContent, setCurrentContent] = React.useState<string>('');

  const handleClose = () => {
    closeModal();
    setPreviewKnowledgeContent([]);
  };

  const handleChangePage = (page: number) => {
    setCurrentContent(previewKnowledgeContent[page - 1] || '');
  };

  useEffect(() => {
    setCurrentContent(previewKnowledgeContent[0] || '');
  }, [previewKnowledgeContent]);

  return (
    <Modal
      title="Knowledge Content"
      open={isOpen}
      onOk={handleClose}
      onCancel={handleClose}
      centered={true}
      className={'text-source-modal'}
      wrapClassName={'modal'}
      maskStyle={{ zIndex: 1100 }}
      style={{ marginTop: 20, marginBottom: 20 }}
    >
      {isLoading || !previewKnowledgeContent.length ? (
        <Skeleton active />
      ) : (
        <>
          <Paragraph>
            <pre>{currentContent}</pre>
          </Paragraph>
          <Pagination
            defaultCurrent={1}
            pageSize={1}
            total={previewKnowledgeContent.length}
            hideOnSinglePage={true}
            onChange={handleChangePage}
          />
        </>
      )}
    </Modal>
  );
};

export default KnowledgePreviewModal;
