import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Form, Input, message, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import './text-source-modal.scss';
import { projectsAPI } from '../../../services/projects-service';
import { showErrorMessage } from '../../../shared/helpers';
import { GA_UPLOAD_QA } from '../../../shared/google-analytics';
import { V_REQUIRED_FIELD } from '../../../shared/constants';

interface ITextSourceModal {
  isOpen: boolean;
  closeModal: () => void;
  projectId: string;
  questionText?: string;
  setQuestionText?: Dispatch<SetStateAction<string>>;
  feedbackId?: string;
  cb?: () => void;
}

const TextSourceModal: FC<ITextSourceModal> = ({
  isOpen,
  closeModal,
  projectId,
  questionText,
  setQuestionText,
  feedbackId,
  cb,
}) => {
  const [addProjectKnowledgeText, { isLoading }] =
    projectsAPI.useAddProjectKnowledgeTextMutation();
  const [addProjectKnowledgeTeach, { isLoading: isTeachLoading }] =
    projectsAPI.useAddProjectKnowledgeTeachMutation();
  const [
    addProjectKnowledgeTeachNoFeedbackId,
    { isLoading: isTeachNoFeedbackIdLoading },
  ] = projectsAPI.useAddProjectKnowledgeTeachNoFeedbackIdMutation();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [okDisabled, setOkDisabled] = useState<boolean>(true);

  const handleOk = async () => {
    setOkDisabled(true);

    const values = form.getFieldsValue();

    let result = null;

    if (questionText && feedbackId) {
      result = await addProjectKnowledgeTeach({
        id: projectId,
        feedbackId: feedbackId,
        body: values,
      });
    } else if (questionText && !feedbackId) {
      result = await addProjectKnowledgeTeachNoFeedbackId({
        id: projectId,
        body: values,
      });
    } else {
      result = await addProjectKnowledgeText({
        id: projectId,
        body: values,
      });
    }

    if (result) {
      if ('error' in result) {
        await showErrorMessage(messageApi, result.error);
      } else {
        if (cb) {
          cb();
        }
        GA_UPLOAD_QA();
        await messageApi.success(`Text have been submitted.`, 1);
        form.resetFields();
        handleCancel();
      }
    }
  };

  const handleCancel = () => {
    if (setQuestionText) {
      setQuestionText('');
    }
    closeModal();
  };

  const handleChange = async () => {
    const name = form.getFieldValue('name');
    const text = form.getFieldValue('text');

    if (!text || !name) {
      setOkDisabled(true);
    } else {
      try {
        await form.validateFields();
        setOkDisabled(false);
      } catch (errors) {
        setOkDisabled(true);
      }
    }
  };

  useEffect(() => {
    if (questionText) {
      form.setFieldsValue({
        text: `Q: ${questionText}\nA: `,
      });
    }
  }, [questionText]);

  return (
    <Modal
      title="Add Text"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered={true}
      okButtonProps={{
        disabled: okDisabled,
        loading: isLoading || isTeachLoading || isTeachNoFeedbackIdLoading,
      }}
      className={'text-source-modal'}
      wrapClassName={'modal'}
      maskStyle={{ zIndex: 1100 }}
    >
      {contextHolder}
      <Form
        form={form}
        onChange={handleChange}
        className="data-source-upload-modal-form"
        layout="vertical"
      >
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: V_REQUIRED_FIELD,
            },
            {
              min: 4,
              message: 'Name must be at least 4 characters.',
            },
          ]}
        >
          <Input autoComplete={'off'} placeholder="Enter name" />
        </Form.Item>
        <Form.Item
          name="text"
          rules={[
            {
              required: true,
              message: V_REQUIRED_FIELD,
            },
          ]}
          className={'text-source-modal__text'}
        >
          <TextArea
            rows={15}
            showCount
            maxLength={1024 * 1024}
            placeholder={`Add your text here`}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TextSourceModal;
