import React, { FC, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import DeleteRecordModal from '../../../../modals/twilio/delete-record-modal';
import { useAppSelector } from '../../../../../hooks/redux-hooks';
import {
  ITableRecordsT,
  ITableRowT,
  ITableT,
} from '../../../../../types/tables-service/ITableT';
import TablesUtils from '../../../../shared/tables/tables-utils';
import { showErrorMessage } from '../../../../../shared/helpers';
import { tablesApi } from '../../../../../services/tables-service';

interface IDeleteTableRowsProps {
  projectId: string;
  tableData: ITableT | undefined | null;
  isReadOnly: boolean;
  tableRows: ITableRowT[];
  onRowsChange: (rows: ITableRowT[]) => void;
  selectedRows: Set<string>;
  setSelectedRows: React.Dispatch<React.SetStateAction<Set<string>>>;
  tableRecordsData: ITableRecordsT | undefined | null;
  getRecordsCb: () => Promise<ITableRecordsT | undefined | null>;
  setDeleteRowLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeleteTableRows: FC<IDeleteTableRowsProps> = ({
  projectId,
  tableData,
  isReadOnly,
  tableRows,
  onRowsChange,
  selectedRows,
  setSelectedRows,
  tableRecordsData,
  getRecordsCb,
  setDeleteRowLoading,
}) => {
  const { subscriptionIsActive } = useAppSelector(
    (state) => state.subscriptionReducer
  );
  const [deleteTableRecords, { isLoading: deleteTableRecordsLoading }] =
    tablesApi.useDeleteTableRecordsTMutation();
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const handleDeleteNewRows = (
    existingRows: ITableRowT[],
    newRows: ITableRowT[],
    newRowsKeysToDelete: string[]
  ) => {
    const rows = newRows.filter(
      (row) => !newRowsKeysToDelete.includes(row.key)
    );
    onRowsChange([...existingRows, ...rows]);
    setSelectedRows(new Set());
  };

  const handleDeleteExisting = async (existingRowsKeysToDelete: string[]) => {
    if (!tableData) return;

    const res = await deleteTableRecords({
      projectId,
      id: tableData.id,
      recordsToDelete: existingRowsKeysToDelete,
    });

    if ('error' in res) {
      await showErrorMessage(messageApi, res.error);
    } else {
      // messageApi.success('Records have been deleted.');
      const records = await getRecordsCb();
      // setSelectedRows(new Set());
      return records;
    }
  };

  const handleDelete = async (didConfirm: boolean) => {
    if (!didConfirm) {
      return;
    }

    setDeleteRowLoading(true);

    let newRows: ITableRowT[] = [];
    let existingRows: ITableRowT[] = [];
    const newRowsToDelete: string[] = [];
    const existingRowsToDelete: string[] = [];

    if (tableRecordsData && tableData) {
      existingRows = TablesUtils.getTableRows(tableRecordsData, tableData);
      const existingRowKeys = existingRows.map((row) => row.key);

      newRows = tableRows.filter((row) => !existingRowKeys.includes(row.key));

      selectedRows.forEach((id) => {
        if (existingRowKeys.includes(id)) {
          existingRowsToDelete.push(id);
        } else {
          newRowsToDelete.push(id);
        }
      });
    } else {
      newRows = JSON.parse(JSON.stringify(tableRows));

      selectedRows.forEach((id) => {
        newRowsToDelete.push(id);
      });
    }

    if (existingRowsToDelete.length) {
      const records = await handleDeleteExisting(existingRowsToDelete);
      if (records && tableData) {
        const tableRows = TablesUtils.getTableRows(records, tableData);
        handleDeleteNewRows(tableRows, newRows, newRowsToDelete);
      }
    } else {
      handleDeleteNewRows(existingRows, newRows, newRowsToDelete);
    }

    setDeleteRowLoading(false);
  };

  const deleteBtn = () => (
    <Button
      icon={<DeleteOutlined />}
      danger
      disabled={
        !subscriptionIsActive ||
        // !tableData ||
        // (!selectedRows.size && !selectedRowsToAdd.size) ||
        !selectedRows.size ||
        deleteTableRecordsLoading ||
        isReadOnly
      }
      onClick={(e) => {
        e.stopPropagation();
        setDeleteModalIsOpen(true);
      }}
    >
      Delete
    </Button>
  );

  const deleteModal = () => {
    return (
      <DeleteRecordModal
        isOpen={deleteModalIsOpen}
        setIsOpen={setDeleteModalIsOpen}
        onConfirm={handleDelete}
      />
    );
  };

  return (
    <>
      {contextHolder}
      {deleteBtn()}
      {deleteModal()}
    </>
  );
};

export default DeleteTableRows;
