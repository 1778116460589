import {
  DeleteOutlined,
  DownloadOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Empty, message, Table } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import './tables.scss';
import { ColumnsType } from 'antd/es/table';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteNames } from '../../../router/router';
import { ProjectSettingsType } from '../../../shared/constants';
import ImportTableTModal from '../../modals/project-settings/tables/import-tableT-modal';
import Loading from '../../loading/loading';
import TableById from './table-by-id';
import { tablesApi } from '../../../services/tables-service';
import usePagination from '../../../hooks/usePagination';
import { ITableT } from '../../../types/tables-service/ITableT';
import DeleteTableTModal from '../../modals/project-settings/tables/delete-tableT-modal';
import useCreateEmptyTable from '../../../hooks/table-hooks/useCreateEmptyTable';

interface ITablesProps {
  projectId: string;
}

const Tables: FC<ITablesProps> = ({ projectId }) => {
  const { orgId, subtab } = useParams();
  const navigate = useNavigate();
  const [
    paginationComponent,
    itemsPerPage,
    currentPage,
    setCurrentPage,
    itemsTotalCount,
    setItemsTotalCount,
  ] = usePagination();
  const [
    lazyGetProjectTables,
    { data: tablesData, isLoading: tablesLoading, error: tablesError },
  ] = tablesApi.useLazyGetProjectTablesTQuery();
  const [importLoading, setImportLoading] = useState(false);
  const [importModalIsOpen, setImportModalIsOpen] = useState(false);
  const [deleteTableId, setDeleteTableId] = useState('');
  const [messageApi, contextHolder] = message.useMessage();
  const [createEmptyTable, createEmptyTableLoading] = useCreateEmptyTable(
    projectId,
    messageApi
  );

  useEffect(() => {
    lazyGetProjectTables({
      projectId,
      params: {
        offset: (currentPage - 1) * itemsPerPage,
        limit: itemsPerPage,
      },
    });
  }, [itemsPerPage, currentPage, projectId, tablesData]);

  useEffect(() => {
    if (tablesData) {
      setItemsTotalCount(tablesData.totalCount);
    }

    if (tablesData && tablesData.data.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [tablesData]);

  const handleCreate = async () => {
    const res = await createEmptyTable();
    if (res) {
      navigate(
        `${RouteNames.USER_PROJECTS}/${orgId}${RouteNames.PROJECT_SETTINGS_TAB_NAME}/${projectId}/${ProjectSettingsType.TABLES}/${res}`
      );
    } else {
      messageApi.error('Failed to create table.');
    }
  };

  const createBtn = () => (
    <Button icon={<PlusOutlined />} onClick={() => handleCreate()}>
      Create
    </Button>
  );

  const importBtn = () => (
    <Button
      icon={<DownloadOutlined />}
      onClick={() => {
        setImportModalIsOpen(true);
      }}
    >
      Import
    </Button>
  );

  const noTables = () => (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      imageStyle={{ height: 60 }}
      description={<span>No tables.</span>}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 24,
        }}
      >
        {createBtn()}
        or
        {importBtn()}
      </div>
    </Empty>
  );

  const columns: ColumnsType<ITableT> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => {
        const maxNameLength = 60;
        let maxName = name.slice(0, maxNameLength);

        if (name.length > maxNameLength) {
          maxName += '...';
        }

        return maxName;
      },
    },
    {
      title: '',
      dataIndex: 'delete',
      key: 'delete',
      render: (_, record) => (
        <Button
          type={'text'}
          danger
          icon={<DeleteOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            setDeleteTableId(record.id);
          }}
          className={'danger-zone__button'}
        ></Button>
      ),
      width: 48,
    },
  ];

  const tablesComponent = () => {
    return (
      <>
        <div className="tables__actions">
          {createBtn()}
          {importBtn()}
        </div>
        <div>
          <Table
            columns={columns}
            className={`records-table records-table-hover campaigns-table`}
            dataSource={
              tablesData?.data.map((t) => {
                return {
                  ...t,
                  key: t.id,
                };
              }) || []
            }
            onRow={(record: ITableT) => ({
              onClick: () => {
                navigate(
                  `${RouteNames.USER_PROJECTS}/${orgId}${RouteNames.PROJECT_SETTINGS_TAB_NAME}/${projectId}/${ProjectSettingsType.TABLES}/${record.id}`
                );
              },
            })}
            pagination={false}
            // style={{ width: 'fit-content' }}
            scroll={{ x: true }}
          />
        </div>
      </>
    );
  };

  const tablesList = () => {
    if (tablesData?.data.length === 0) {
      return noTables();
    }

    return (
      <>
        {tablesComponent()}
        {paginationComponent}
      </>
    );
  };

  const getComponent = () => {
    if (importLoading || tablesLoading) {
      return <Loading height={'300px'} />;
    }

    if (!subtab) return tablesList();

    return <TableById projectId={projectId} tableId={subtab} />;
  };

  const modals = () => {
    return (
      <>
        <ImportTableTModal
          isOpen={importModalIsOpen}
          closeModal={() => {
            setImportModalIsOpen(false);
          }}
          projectId={projectId}
          cb={() => {}}
          setImportLoading={setImportLoading}
        />
        <DeleteTableTModal
          projectId={projectId}
          deleteTableId={deleteTableId}
          setDeleteTableId={setDeleteTableId}
        />
      </>
    );
  };

  return (
    <div className="tables">
      {contextHolder}
      <div className="project-setting-content-container">
        {getComponent()}
        {modals()}
      </div>
    </div>
  );
};

export default Tables;
