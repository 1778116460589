import { FC, useState } from 'react';
import {
  CTAButtons,
  QuickReplyButtons,
  RenderInputField,
  RenderTitleField,
} from './template-builder';
import { Form, FormInstance, Select } from 'antd';

interface ICardTemplateProps {
  form: FormInstance<any>;
}

const CardTemplate: FC<ICardTemplateProps> = ({ form }) => {
  const [type, setType] = useState<string>();

  return (
    <>
      {RenderTitleField(form)}
      {RenderInputField(
        form,
        `subtitle`,
        `Subtitle:`,
        `Type subtitle`,
        false,
        false,
        80
      )}
      {RenderInputField(
        form,
        'media',
        'Media URL',
        'Supports images, audio, documents, and video.',
        true,
        false,
        1600
      )}
      <Form.Item
        name={`type_of_card`}
        label="Button Types:"
        key={`type_of_card`}
        rules={[
          {
            required: false,
            message: 'Type of action is required',
          },
        ]}
      >
        <Select
          dropdownStyle={{ zIndex: 10050 }}
          onChange={(value) => {
            setType(value);
          }}
        >
          {cardTypes.map((option) => (
            <Select.Option key={option.key} value={option.key}>
              {option.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {type === cardTypes[0].key && <CTAButtons form={form} />}
      {type === cardTypes[1].key && <QuickReplyButtons form={form} />}
    </>
  );
};

export default CardTemplate;

const cardTypes = [
  {
    key: 'cta',
    name: 'Call to Action',
  },
  {
    key: 'quickReply',
    name: 'Quick Reply',
  },
];
