import { apiService } from './api-service';

export const aiAPI = apiService.injectEndpoints({
  endpoints: (build) => ({
    downloadAttachedFile: build.query<
      {
        downloadUrl: string;
        contentType: string;
      },
      { projectId: string; chatId: string; fileName: string }
    >({
      query: ({ projectId, chatId, fileName }) => ({
        url: `/projects/${projectId}/ai/${chatId}/download/${fileName}`,
        method: 'GET',
        responseHandler: async (response) => {
          const contentType = response.headers.get('content-type');
          return {
            downloadUrl: window.URL.createObjectURL(await response.blob()),
            contentType: contentType,
          };
        },
        cache: 'no-cache',
      }),
    }),
  }),
});
