import React, { FC, useEffect, useState } from 'react';
import './float-button.scss';
import {
  getGlowFeColorMatrices,
  getShadowGlowFilter,
} from '../../../../../../../superellipse/svg-filters';
import { IGeneratedWidgetStyle } from '../../fake-widget-helpers';
import IProjectStartInfo from '../../../../../../../../types/IProjectStartInfo';
import DefaultFloatButtonIcon from './default-float-button-icon';
import { ISuperellipseMaskImageProps } from '../../../../../../../superellipse/superellipse-mask';

interface IFloatButtonProps {
  projectStartInfo: IProjectStartInfo;
  generatedWidgetStyle: IGeneratedWidgetStyle;
  imageProps: ISuperellipseMaskImageProps;
}

const FloatButton: FC<IFloatButtonProps> = ({
  projectStartInfo,
  generatedWidgetStyle,
  imageProps,
}) => {
  const [showProjectImage, setShowProjectImage] = useState<boolean>(false);
  const glowColorMatrices = getGlowFeColorMatrices([]);
  const filter = getShadowGlowFilter(true, false, glowColorMatrices);

  useEffect(() => {
    // const floatBtnShowImage = config.floatButton.showImage;
    const floatBtnShowImage = true;
    const projectHasImage = projectStartInfo.logoFileName;

    if (floatBtnShowImage && projectHasImage) {
      setShowProjectImage(true);
    } else {
      setShowProjectImage(false);
    }
  }, [projectStartInfo, imageProps]);

  const getFloatButtonImage = () => {
    if (showProjectImage) {
      return (
        <>
          <mask id={`floatButtonMask`}>
            <circle cx="26" cy="26" r="26" fill="white" />
          </mask>

          <image
            x={imageProps.x || '0'}
            y={imageProps.y || '0'}
            width={imageProps.width || '100%'}
            height={imageProps.height || '100%'}
            xlinkHref={imageProps.src}
            mask={`url(#floatButtonMask)`}
            preserveAspectRatio={imageProps.preserveAspectRatio || 'xMidYMid'}
          ></image>
        </>
      );
    } else {
      return <circle cx="26" cy="26" r="26" fill="black" />;
    }
  };

  return (
    <div className={'float-button'}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="52"
        height="52"
        viewBox="0 0 52 52"
        fill="none"
        style={{ overflow: 'visible' }}
      >
        <g filter={filter.name ? `url(#${filter.name})` : 'none'}>
          {getFloatButtonImage()}
          <circle
            cx="26"
            cy="26"
            r="25"
            stroke="url(#paint0_linear_1504_1412)"
            strokeWidth="2"
          />
        </g>
        <defs>
          {filter.defs}

          <linearGradient
            id="paint0_linear_1504_1412"
            x1="16"
            y1="8"
            x2="43.0382"
            y2="70.7549"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={generatedWidgetStyle.secondaryColor} />
            <stop
              offset="1"
              stopColor={generatedWidgetStyle.secondaryColor}
              stopOpacity="0"
            />
          </linearGradient>
        </defs>
      </svg>

      {!showProjectImage && <DefaultFloatButtonIcon />}
    </div>
  );
};

export default FloatButton;
