import React, { FC } from 'react';
import '../message.scss';
import { IGeneratedWidgetStyle } from '../../../fake-widget-helpers';

interface IUserMessageProps {
  generatedWidgetStyle: IGeneratedWidgetStyle;
  message: string;
  userLabel: string;
}

const UserMessage: FC<IUserMessageProps> = ({
  generatedWidgetStyle,
  message,
  userLabel,
}) => {
  const senderName = userLabel || 'You';

  return (
    <div>
      <div
        className={'message-header'}
        style={{
          color: generatedWidgetStyle.messageTextColor.user,
        }}
      >
        <div
          className={`message-sender`}
          style={{
            opacity: generatedWidgetStyle.secondaryColorOpacity,
          }}
        >
          {senderName}
        </div>
        <div className={'message-info'}>
          <div
            className="msg-time"
            style={{
              opacity: generatedWidgetStyle.secondaryColorOpacity,
            }}
          >
            10:34 AM
          </div>
        </div>
      </div>
      <div
        className="message-text"
        style={{ color: generatedWidgetStyle.messageTextColor.user }}
      >
        <div>{message}</div>
      </div>
    </div>
  );
};

export default UserMessage;
