import React, { FC } from 'react';
import { Button } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { showErrorMessage } from '../../../../../shared/helpers';
import { ITableT } from '../../../../../types/tables-service/ITableT';
import { useAppSelector } from '../../../../../hooks/redux-hooks';
import { MessageInstance } from 'antd/es/message/interface';
import { ITableFilterValues } from '../../../whats-app-outbound/filters/filters-helpers';
import { tablesApi } from '../../../../../services/tables-service';

interface IExportTableProps {
  projectId: string;
  tableData: ITableT;
  messageApi: MessageInstance;
  filterValues: ITableFilterValues[];
}

const ExportTable: FC<IExportTableProps> = ({
  projectId,
  tableData,
  messageApi,
  filterValues,
}) => {
  const { subscriptionIsActive } = useAppSelector(
    (state) => state.subscriptionReducer
  );
  const [exportTable] = tablesApi.useLazyExportTableTQuery();

  const handleExport = async () => {
    const messageKey = tableData.id;

    messageApi.info({
      content: 'Downloading...',
      key: messageKey,
      duration: 0,
    });

    const res = await exportTable({
      projectId,
      tableId: tableData.id,
      filterValues,
    });

    if ('error' in res && res.error) {
      await showErrorMessage(messageApi, res.error);
    } else {
      const blobData = res.data as {
        downloadUrl: string;
        contentType: string;
      };

      const link = document.createElement('a');

      link.href = blobData.downloadUrl;
      link.download = 'table.csv';
      document.body.appendChild(link);

      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      document.body.removeChild(link);
    }

    messageApi.destroy(messageKey);
  };

  const renderExport = () => (
    <Button
      icon={<ExportOutlined />}
      disabled={!subscriptionIsActive}
      onClick={handleExport}
    >
      Export
    </Button>
  );

  return renderExport();
};

export default ExportTable;
