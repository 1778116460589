import EnumFormItem from './enum-form-item';
import IPluginItem from '../../../../types/IPluginItem';
import { PluginNames } from '../../../../shared/constants';
import JiraEnum from './plugins-input-fields/jira-enum';
import ArrayKeyValueInputField from './array-key-value-input-field';

class InputFactory {
  static getEnum(
    plugin: IPluginItem,
    commonProps: any,
    values: any[],
    projectId: string
  ): JSX.Element {
    if (plugin.name === PluginNames.JIRA && commonProps.keyBase === 'project') {
      const props: any = plugin.properties;

      if (!props || !props.apiToken || !props.domain || !props.email) {
        return <></>;
      }

      return (
        <JiraEnum
          {...commonProps}
          values={values}
          plugin={plugin}
          projectId={projectId}
        />
      );
    }

    return <EnumFormItem {...commonProps} values={values} />;
  }

  static getKeyValueArray(
    plugin: IPluginItem,
    placeholderValue: string,
    commonProps: any
  ): JSX.Element {
    return (
      <ArrayKeyValueInputField
        {...commonProps}
        placeholder={placeholderValue}
        pluginName={plugin.name}
      />
    );
  }
}

export default InputFactory;
