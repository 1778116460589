import {
  BASE_URL_LOCAL,
  BASE_URL_PROD,
  DEMO_PROJECT_ID_LANDING_PROD,
  DEMO_PROJECT_ID_LANDING_LOCAL,
  WIDGET_BASE_URL_LOCAL,
  WIDGET_BASE_URL_PROD,
  WIDGET_SCRIPT_URL_PROD,
  WIDGET_SCRIPT_URL_LOCAL,
} from './constants';

interface IConfig {
  API: string;
  widgetURL: string;
  widgetScriptURL: string;
  demoProjectId: string;
}

const prod: IConfig = {
  API: BASE_URL_PROD,
  widgetURL: WIDGET_BASE_URL_PROD,
  widgetScriptURL: WIDGET_SCRIPT_URL_PROD,
  demoProjectId: DEMO_PROJECT_ID_LANDING_PROD,
};

const dev: IConfig = {
  API: BASE_URL_LOCAL,
  widgetURL: WIDGET_BASE_URL_LOCAL,
  widgetScriptURL: WIDGET_SCRIPT_URL_LOCAL,
  demoProjectId: DEMO_PROJECT_ID_LANDING_LOCAL,
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;
