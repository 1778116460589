import React, { FC, useState } from 'react';
import { getLexicalPromptFormItem } from '../../../../lexical/lexical-helpers';
import LexicalCommandPropsModal, {
  ILexicalCommandModalStateProps,
} from '../../../../lexical/lexical-command-props-modal';
import { useAppSelector } from '../../../../../hooks/redux-hooks';
import { Link, useParams } from 'react-router-dom';
import { RouteNames } from '../../../../../router/router';
import { ProfileSettingsType } from '../../../../../shared/constants';
import { projectsAPI } from '../../../../../services/projects-service';
import { Alert, Skeleton } from 'antd';
import IProject from '../../../../../types/IProject';

interface IInstructionPromptFieldProps {
  userProject: IProject;
  handleLexicalFieldChange: (fieldName: string, value: string) => void;
  ownerIdOfCurrentOrg: string | null;
}

const InstructionPromptField: FC<IInstructionPromptFieldProps> = ({
  userProject,
  handleLexicalFieldChange,
  ownerIdOfCurrentOrg,
}) => {
  const { orgId } = useParams();
  const { subscriptionIsActive, currentUserSubscription } = useAppSelector(
    (state) => state.subscriptionReducer
  );
  const { user } = useAppSelector((state) => state.userReducer);
  const { currentOrganization } = useAppSelector(
    (state) => state.organizationsReducer
  );
  const {
    data: promptsCommandsTemplates,
    isLoading: promptsCommandsTemplatesLoading,
  } = projectsAPI.useGetProjectPromptsCommandsTemplatesQuery({
    projectId: userProject.id,
    orgId,
  });

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState<ILexicalCommandModalStateProps>({
    title: '',
    description: '',
    options: [],
    handleOkCb: () => {},
  });

  const canEditBasicPrompts = () => {
    return subscriptionIsActive && basicPromptEditingIsAvailable();
  };

  const basicPromptEditingIsAvailable = () => {
    if (!currentUserSubscription) return false;
    return currentUserSubscription.currentPlan.features.basic_prompt_editing;
  };

  const noAccessWarning = () => {
    return basicPromptEditingIsAvailable() ? null : (
      <div className="advanced-no-access" style={{ marginBottom: 10 }}>
        {currentOrganization &&
        !currentOrganization.isPersonal &&
        user &&
        ownerIdOfCurrentOrg &&
        ownerIdOfCurrentOrg !== user.id ? (
          <>
            <Alert
              message="The organization does not have access to this option. Ask your organization owner to upgrade the plan to open this option."
              type="info"
              showIcon
            />
          </>
        ) : (
          <>
            <Alert
              message={
                <>
                  Your plan does not have access to this option. To open
                  Instruction prompt editing,{' '}
                  <Link
                    to={`${RouteNames.PROFILE_SETTINGS_NO_PARAMS}/${ProfileSettingsType.PLAN}`}
                    className={'advanced-no-access-link'}
                  >
                    upgrade your plan
                  </Link>{' '}
                  to Starter or higher.
                </>
              }
              type="info"
              showIcon
            />
          </>
        )}
      </div>
    );
  };

  return promptsCommandsTemplatesLoading ? (
    <Skeleton active />
  ) : (
    <>
      {noAccessWarning()}
      {getLexicalPromptFormItem(
        'Instruction prompt',
        'instructionPrompt',
        canEditBasicPrompts(),
        userProject,
        handleLexicalFieldChange,
        modalProps,
        setModalProps,
        setModalIsOpen,
        promptsCommandsTemplates
      )}
      {modalProps && (
        <LexicalCommandPropsModal
          isOpen={modalIsOpen}
          setIsOpen={setModalIsOpen}
          {...modalProps}
        />
      )}
    </>
  );
};

export default InstructionPromptField;
