import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Alert, Checkbox, Form, Input, Modal, Select } from 'antd';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { projectsAPI } from '../../../services/projects-service';
import { showErrorMessage } from '../../../shared/helpers';
import { MessageInstance } from 'antd/es/message/interface';
import { V_REQUIRED_FIELD } from '../../../shared/constants';

interface ICloneProjectModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  projectId: string;
  projectName: string;
  messageApi: MessageInstance;
}

interface IOption {
  value: string;
  label: string;
  disabled?: boolean;
}

const CloneProjectModal: FC<ICloneProjectModal> = ({
  isOpen,
  setIsOpen,
  projectId,
  projectName,
  messageApi,
}) => {
  const { user } = useAppSelector((state) => state.userReducer);
  const { allOrganizationsInfo } = useAppSelector(
    (state) => state.organizationsReducer
  );
  const [newOwnerId, setNewOwnerId] = React.useState<string>('');
  const [ownerTypeOptions, setOwnerTypeOptions] = React.useState<IOption[]>([]);
  const [withKnowledge, setWithKnowledge] = React.useState<boolean>(false);
  const [autoTrain, setAutoTrain] = React.useState<boolean>(false);
  const [newProjectName, setNewProjectName] = useState<string>(projectName);
  const [cloneProject, { isLoading: isCloneProjectLoading }] =
    projectsAPI.useCloneProjectMutation();

  useEffect(() => {
    if (allOrganizationsInfo) {
      const organizationsOptions = allOrganizationsInfo.map((organization) => {
        const ownerEmail = organization.owner?.email
          ? ` (${organization.owner.email})`
          : '';

        return {
          value: organization.id,
          label: organization.displayName + ownerEmail,
        };
      });

      if (organizationsOptions.length > 0) {
        setOwnerTypeOptions(organizationsOptions);
      }

      const userPersonalOrg = allOrganizationsInfo.find(
        (org) => org.owner?.id === user?.id
      );
      if (userPersonalOrg) {
        setNewOwnerId(userPersonalOrg.id);
      }
    }
  }, [allOrganizationsInfo]);

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleChangeNewOwnerId = (value: string) => {
    setNewOwnerId(value);
  };

  const handleCloneProject = async () => {
    const res = await cloneProject({
      id: projectId,
      name: newProjectName,
      tenantId: newOwnerId,
      withKnowledge: withKnowledge,
      autoTrain: withKnowledge,
    });

    if ('error' in res) {
      await showErrorMessage(messageApi, res.error);
    } else {
      messageApi.success('Project cloned successfully.');
      setIsOpen(false);
    }
  };

  return (
    <Modal
      title="Clone Project"
      open={isOpen}
      onOk={handleCloneProject}
      okButtonProps={{
        disabled: isCloneProjectLoading || !newProjectName,
        loading: isCloneProjectLoading,
      }}
      onCancel={handleCancel}
      centered={true}
      className={'text-source-modal'}
      wrapClassName={'modal'}
      maskStyle={{ zIndex: 1100 }}
      style={{ marginTop: 20, marginBottom: 20, maxWidth: 600 }}
    >
      <Form
        className="data-source-upload-modal-form"
        layout="vertical"
        initialValues={{
          name: newProjectName,
          tenantId: newOwnerId,
        }}
        requiredMark={false}
      >
        <Form.Item
          name="name"
          label={'Name:'}
          rules={[{ required: true, message: V_REQUIRED_FIELD }]}
        >
          <Input
            placeholder={projectName}
            value={newProjectName}
            onChange={(e) => setNewProjectName(e.target.value)}
          />
        </Form.Item>
        <Form.Item name="tenantId" label={'Clone to:'}>
          <Select
            onChange={handleChangeNewOwnerId}
            options={ownerTypeOptions}
            dropdownStyle={{ zIndex: 2000 }}
            value={newOwnerId}
          />
        </Form.Item>
        <Form.Item name="withKnowledge">
          <Checkbox
            checked={withKnowledge}
            onChange={(e) => setWithKnowledge(e.target.checked)}
          >
            With knowledge
          </Checkbox>
        </Form.Item>
        <Form.Item name="autoTrain" style={{ display: 'none' }}>
          <Checkbox
            checked={autoTrain}
            onChange={(e) => setAutoTrain(e.target.checked)}
          >
            Train automatically
            {autoTrain && (
              <span style={{ fontSize: 12, marginLeft: 10, color: '#00b2ff' }}>
                Attention! This service will cost depending on the volume of
                data.
              </span>
            )}
          </Checkbox>
        </Form.Item>
      </Form>
      <Alert
        message="The cloned project will not save information about plugins, conversations, insights, records and usage."
        type="info"
        showIcon
      />
    </Modal>
  );
};

export default CloneProjectModal;
