import React from 'react';
import { Alert, Button } from 'antd';
import { RouteNames } from '../../router/router';
import { ProfileSettingsType } from '../../shared/constants';
import { useNavigate } from 'react-router-dom';
import './subscription-inactive-warning.scss';

const SubscriptionInactiveWarning = () => {
  const navigate = useNavigate();

  return (
    <Alert
      message={
        <span>
          Your subscription is inactive. You can't create new projects or edit
          existing ones.{' '}
          <Button
            type="link"
            onClick={() =>
              navigate(
                `${RouteNames.PROFILE_SETTINGS_NO_PARAMS}/${ProfileSettingsType.PLAN}`
              )
            }
            className={'alert-btn-link'}
          >
            Upgrade
          </Button>{' '}
          your account to continue.
        </span>
      }
      type="info"
      showIcon
      className={'subscription-inactive-warning'}
    />
  );
};

export default SubscriptionInactiveWarning;
