import React, { FC, useEffect, useState } from 'react';
import { ConversationMarkOptions } from './conversation-filters-helpers';
import { Button } from 'antd';
import { showErrorMessage } from '../../../shared/helpers';
import { projectsAPI } from '../../../services/projects-service';
import { MessageInstance } from 'antd/es/message/interface';
import { IConversationsParams } from '../../../types/IConversationsParams';
import IConversation from '../../../types/IConversation';
import { useParams } from 'react-router-dom';

interface IConversationMarkToggleProps {
  projectId: string;
  conversation: IConversation;
  conversationParams: IConversationsParams;
  messageApi: MessageInstance;
}

const ConversationMarkToggle: FC<IConversationMarkToggleProps> = ({
  projectId,
  conversation,
  conversationParams,
  messageApi,
}) => {
  const { orgId } = useParams();
  const [markValue, setMarkValue] = useState<ConversationMarkOptions>(
    conversation.threadControl?.readAt
      ? ConversationMarkOptions.READ
      : ConversationMarkOptions.UNREAD
  );
  const [markConversationAsRead, { isLoading: markConversationAsReadLoading }] =
    projectsAPI.useMarkConversationAsReadMutation();
  const [
    markConversationAsUnread,
    { isLoading: markConversationAsUnreadLoading },
  ] = projectsAPI.useMarkConversationAsUnreadMutation();
  const [lazyGetProjectConversations] =
    projectsAPI.useLazyGetProjectConversationsQuery();
  const [lazyGetUnreadConversationsCount] =
    projectsAPI.useLazyGetUnreadConversationsCountQuery();

  useEffect(() => {
    if (conversation.threadControl?.readAt) {
      setMarkValue(ConversationMarkOptions.READ);
    } else {
      setMarkValue(ConversationMarkOptions.UNREAD);
    }
  }, [conversation]);

  const markAsRead = async () => {
    const res = await markConversationAsRead({
      projectId,
      conversationId: conversation.id,
    });

    if ('error' in res) {
      showErrorMessage(messageApi, res.error);
    } else {
      messageApi.success('Conversation marked as read.');
      lazyGetProjectConversations({ projectId, params: conversationParams });
      lazyGetUnreadConversationsCount({ projectId, orgId });
    }
  };

  const markAsUnread = async () => {
    const res = await markConversationAsUnread({
      projectId,
      conversationId: conversation.id,
    });

    if ('error' in res) {
      showErrorMessage(messageApi, res.error);
    } else {
      messageApi.success('Conversation marked as unread.');
      lazyGetProjectConversations({ projectId, params: conversationParams });
      lazyGetUnreadConversationsCount({ projectId, orgId });
    }
  };

  const markAsReadButton = () => (
    <Button
      onClick={markAsRead}
      disabled={
        markConversationAsReadLoading || markConversationAsUnreadLoading
      }
    >
      Mark as Read
    </Button>
  );

  const markAsUnreadButton = () => (
    <Button
      onClick={markAsUnread}
      disabled={
        markConversationAsReadLoading || markConversationAsUnreadLoading
      }
    >
      Mark as Unread
    </Button>
  );

  return (
    <div className={'conversation-toggle'}>
      {markValue === ConversationMarkOptions.READ
        ? markAsUnreadButton()
        : markAsReadButton()}
    </div>
  );
};

export default ConversationMarkToggle;
