import React, { FC, useEffect, useState } from 'react';
import { Button, Input } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import './api-key-setting.scss';
import { usersAPI } from '../../../services/users-service';
import Loading from '../../loading/loading';
import { Link } from 'react-router-dom';
import { RouteNames } from '../../../router/router';
import { ProfileSettingsType } from '../../../shared/constants';
import { subscriptionAPI } from '../../../services/subscription-service';

const ApiKeySetting: FC = () => {
  // const { currentUserSubscription } = useAppSelector(
  //   (state) => state.subscriptionReducer
  // );
  // const { currentOrganization, currentOrganizationLoading } = useAppSelector(
  //   (state) => state.organizationsReducer
  // );
  // const { isLoading: subscriptionLoading } =
  //   subscriptionAPI.useGetUserSubscriptionQuery();
  const [
    lazyGetUserAPIKey,
    { data: apiKeyData, isLoading: apiKeyLoading, error: apiKeyError },
  ] = usersAPI.useLazyGetUserAPIKeyQuery();
  // const { apiKey } = useAppSelector((state) => state.userReducer);
  const {
    data: currentUserSubscription,
    isLoading: currentUserSubscriptionLoading,
    error: currentUserSubscriptionError,
  } = subscriptionAPI.useGetUserSubscriptionQuery(false);
  // const {
  //   data: apiKeyData,
  //   isLoading: apiKeyLoading,
  //   error: apiKeyError,
  // } = usersAPI.useGetUserAPIKeyQuery(false);
  const [showAPIKeys, setShowAPIKeys] = useState(false);
  const [apiKeyVisible, setApiKeyVisible] = useState(false);

  useEffect(() => {
    if (
      currentUserSubscription &&
      currentUserSubscription.currentPlan.features.api_access
    ) {
      setShowAPIKeys(true);
    } else {
      setShowAPIKeys(false);
    }
  }, [currentUserSubscription]);

  useEffect(() => {
    if (showAPIKeys) {
      lazyGetUserAPIKey(false);
    }
  }, [showAPIKeys]);

  return (
    <div className="profile-setting-content-container">
      {currentUserSubscriptionLoading ? (
        <Loading />
      ) : showAPIKeys ? (
        apiKeyLoading ? (
          <Loading />
        ) : apiKeyError ? (
          <>
            <div className="profile-setting-title">API key</div>
            <div className="profile-setting-description">
              {apiKeyError &&
              'data' in apiKeyError &&
              (apiKeyError.data as { message: string }) &&
              (apiKeyError.data as { message: string }).message
                ? (apiKeyError.data as { message: string }).message
                : `Can't get organization API key.`}
            </div>
          </>
        ) : apiKeyData ? (
          <>
            <div className="profile-setting-title">API key</div>
            <div className="profile-setting-description">
              Keep your API key in secret.
            </div>
            <div className="api-key-input-label">Your API key</div>
            <div className="api-key-container">
              <Input.Password
                value={apiKeyData}
                visibilityToggle={{
                  visible: apiKeyVisible,
                  onVisibleChange: setApiKeyVisible,
                }}
                className="api-key-input"
              />
              <Button
                onClick={() => navigator.clipboard.writeText(apiKeyData)}
                className="api-key-btn"
              >
                <CopyOutlined />
              </Button>
            </div>
          </>
        ) : null
      ) : (
        <>
          <div className="profile-setting-title">API key</div>
          <div className="profile-setting-description">
            {currentUserSubscription &&
            currentUserSubscription.currentPlan.features.api_access ? null : (
              <>
                <br />
                <br />
                Your plan does not have access to this option. To open it,{' '}
                <Link
                  to={`${RouteNames.PROFILE_SETTINGS_NO_PARAMS}/${ProfileSettingsType.PLAN}`}
                  className={'advanced-no-access-link'}
                >
                  upgrade your plan
                </Link>
                .
              </>
            )}
          </div>
          {/*<Button*/}
          {/*  type="primary"*/}
          {/*  onClick={() => setShowAPIKeys(true)}*/}
          {/*  disabled={*/}
          {/*    !currentUserSubscription ||*/}
          {/*    (currentUserSubscription &&*/}
          {/*      !currentUserSubscription.currentPlan.features.api_access)*/}
          {/*  }*/}
          {/*>*/}
          {/*  Create API Key*/}
          {/*</Button>*/}
        </>
      )}
    </div>
  );
};

export default ApiKeySetting;
