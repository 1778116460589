import React, { FC, useEffect, useState } from 'react';
import { Button, message, Skeleton } from 'antd';
import { useAppSelector } from '../../../hooks/redux-hooks';
import {
  BillingPlansTypes,
  SubscriptionPlanTypes,
} from '../../../shared/constants';
import './plan-setting.scss';
import PlanCard from '../../plan-card/plan-card';
import BillingPlanSwitcher from '../../billing-plan-switcher/billing-plan-switcher';
import {
  getGASubscriptionPurchaseDetails,
  showErrorMessage,
} from '../../../shared/helpers';
import { useLocation, useNavigate } from 'react-router-dom';
import { subscriptionAPI } from '../../../services/subscription-service';
import {
  GA_SUBSCRIPTION_CANCELED,
  GA_SUBSCRIPTION_PURCHASE,
} from '../../../shared/google-analytics';
import { usersAPI } from '../../../services/users-service';

let clearLocationStateBeforeUnload = false;

const PlanSetting: FC = () => {
  const { contactEmail } = useAppSelector((state) => state.domainsReducer);
  const { plans } = useAppSelector((state) => state.plansReducer);
  const [subscribe, {}] = subscriptionAPI.useSubscribeMutation();
  const [cancelSubscription, {}] =
    subscriptionAPI.useCancelSubscriptionMutation();
  const [currentBillingPlansType, setCurrentBillingPlansType] =
    useState<BillingPlansTypes>(BillingPlansTypes.MONTHLY);
  const location = useLocation();
  const navigate = useNavigate();
  const [
    lazyGetUserSubscription,
    {
      data: currentUserSubscription,
      isLoading: currentUserSubscriptionLoading,
      error: currentUserSubscriptionError,
    },
  ] = subscriptionAPI.useLazyGetUserSubscriptionQuery();
  const [
    lazyGetStripeDashboardURL,
    {
      data: lazyGetStripeDashboardURLData,
      isLoading: lazyGetStripeDashboardURLLoading,
      error: lazyGetStripeDashboardURLError,
    },
  ] = usersAPI.useLazyGetStripeDashboardURLQuery();
  const {
    data: enterprisePaymentURL,
    isLoading: enterprisePaymentURLLoading,
    error: enterprisePaymentURLError,
  } = subscriptionAPI.useGetEnterprisePaymentURLQuery();
  const [showBillingPlans, setShowBillingPlans] = useState<boolean>(
    !!(location.state && location.state.upgrade)
  );
  const [subscribeDisabled, setSubscribeDisabled] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (lazyGetStripeDashboardURLData) {
      window.open(lazyGetStripeDashboardURLData, '_blank')?.focus();
    } else if (lazyGetStripeDashboardURLError) {
      showErrorMessage(messageApi, lazyGetStripeDashboardURLError);
    }
  }, [lazyGetStripeDashboardURLData, lazyGetStripeDashboardURLError]);

  useEffect(() => {
    lazyGetUserSubscription(false);
  }, []);

  useEffect(() => {
    clearLocationStateBeforeUnload = false;

    window.addEventListener('beforeunload', clearLocationState);
    return () => {
      window.removeEventListener('beforeunload', clearLocationState);
    };
  }, []);

  useEffect(() => {
    if (clearLocationStateBeforeUnload) return;
    setShowBillingPlans(!!(location.state && location.state.upgrade));
  }, [location.state]);

  const clearLocationState = () => {
    clearLocationStateBeforeUnload = true;
    navigate('.', { replace: true });
  };

  const subscribeCb = async (type: string) => {
    setSubscribeDisabled(true);

    const result = await subscribe({
      billingType: currentBillingPlansType,
      type: type,
    });

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
    } else {
      const purchaseDetails = getGASubscriptionPurchaseDetails(
        plans,
        type,
        currentBillingPlansType
      );
      GA_SUBSCRIPTION_PURCHASE(purchaseDetails);

      // FB PIXEL
      if ((window as any).fbq != null) {
        (window as any).fbq('track', 'Subscribe'); // TODO: refactor
      }

      if (result.data) {
        window.open(result.data, '_self');
      }

      await messageApi.success('Subscribe.');
    }
    await lazyGetUserSubscription(false);

    setSubscribeDisabled(false);
  };

  const handleCancelSubscription = async () => {
    if (!currentUserSubscription) return;
    setSubscribeDisabled(true);

    const result = await cancelSubscription();

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
    } else {
      GA_SUBSCRIPTION_CANCELED(currentUserSubscription);
      await messageApi.success('Subscription canceled.');
    }
    await lazyGetUserSubscription();

    setSubscribeDisabled(false);
  };

  return (
    <div className="profile-setting-content-container">
      {contextHolder}
      {showBillingPlans ? (
        <>
          <div className="billing-plan-switcher-container">
            <BillingPlanSwitcher
              currentBillingPlansType={currentBillingPlansType}
              setCurrentBillingPlansType={setCurrentBillingPlansType}
            />
          </div>
          <div className="plans-container">
            {plans
              .filter((plan) => {
                if (
                  currentUserSubscription &&
                  currentUserSubscription?.currentPlan.type ===
                    SubscriptionPlanTypes.ENTERPRISE
                ) {
                  return plan.type === SubscriptionPlanTypes.ENTERPRISE;
                } else {
                  return (
                    plan.monthlyPrice >=
                      (currentUserSubscription?.currentPlan.monthlyPrice ||
                        0) || plan.monthlyPrice === -1
                  );
                }
              })
              .map((plan, i) => (
                <PlanCard
                  plan={plan}
                  currentBillingPlansType={currentBillingPlansType}
                  key={i}
                  index={i}
                  subscribeCb={subscribeCb}
                  subscribeDisabled={subscribeDisabled}
                />
              ))}
          </div>
        </>
      ) : currentUserSubscriptionLoading || enterprisePaymentURLLoading ? (
        <Skeleton active />
      ) : currentUserSubscriptionError ? (
        <>
          <div className="profile-setting-title">Plan</div>
          <div className="profile-setting-description">
            Can't load your plan. Please try again later.
          </div>
        </>
      ) : currentUserSubscription ? (
        <>
          <div className="profile-setting-title">
            You are on a “{currentUserSubscription?.currentPlan.name || 'Free'}”
            plan
          </div>
          <div className="profile-setting-description">
            {currentUserSubscription &&
            currentUserSubscription?.currentPlan.type ===
              SubscriptionPlanTypes.FREE ? (
              <>
                You're currently on the Free plan. Upgrading offers premium
                feature, faster support, and exclusive features like GPT4 for a
                richer experience.
              </>
            ) : null}
          </div>
          <div className="plan-settings-btns">
            {currentUserSubscription &&
            currentUserSubscription?.currentPlan.type ===
              SubscriptionPlanTypes.ENTERPRISE ? null : (
              <Button type="primary" onClick={() => setShowBillingPlans(true)}>
                Upgrade Plan
              </Button>
            )}
            {currentUserSubscription &&
            currentUserSubscription?.currentPlan.type ===
              SubscriptionPlanTypes.FREE ? null : (
              <Button
                type="primary"
                onClick={() => lazyGetStripeDashboardURL()}
                disabled={lazyGetStripeDashboardURLLoading}
              >
                Stripe Dashboard
              </Button>
            )}
          </div>
          {enterprisePaymentURL && (
            <a href={enterprisePaymentURL} target={'_blank'}>
              <Button
                type="link"
                className="cancel-subscription-btn restore-subscription-btn enterprise-payment-btn"
                disabled={subscribeDisabled}
              >
                Enterprise payment
              </Button>
            </a>
          )}
          {currentUserSubscription &&
          !currentUserSubscription.cancelationDate &&
          currentUserSubscription.currentPlan.monthlyPriceAnualBilling !== 0 ? (
            <>
              <div className="profile-setting-title cancel-title">
                Cancelation
              </div>
              <div className="profile-setting-description cancel-description">
                Account will be deactivated when you spend all the credits. You
                still can use the services.
              </div>
              <Button
                type="link"
                className="cancel-subscription-btn"
                danger={true}
                onClick={handleCancelSubscription}
                disabled={subscribeDisabled}
              >
                Cancel
              </Button>
            </>
          ) : currentUserSubscription &&
            currentUserSubscription.cancelationDate ? (
            // TODO: GA event?
            <>
              <div className="profile-setting-title cancel-title">
                Restore subscription
              </div>
              <div className="profile-setting-description cancel-description">
                You have canceled your subscription.
              </div>
              <a href={`mailto:${contactEmail}`}>
                <Button
                  type="link"
                  className="cancel-subscription-btn restore-subscription-btn"
                  disabled={subscribeDisabled}
                >
                  Contact us to restore your subscription
                </Button>
              </a>
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default PlanSetting;
