import React, { FC } from 'react';

const CloseIcon: FC = () => {
  return (
    <div className={'close-icon-container'}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        style={{ verticalAlign: 'top' }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_391_8461)">
          <path
            d="M4.36277 5.49465C4.28474 5.57268 4.2847 5.69935 4.3628 5.77746L10.5853 12L4.36277 18.2226C4.28474 18.3006 4.2847 18.4273 4.3628 18.5054L5.49418 19.6368C5.57228 19.7149 5.69895 19.7148 5.77698 19.6368L11.9996 13.4142L18.2221 19.6368C18.3002 19.7149 18.4269 19.7148 18.5049 19.6368L19.6363 18.5053C19.7144 18.4273 19.7144 18.3006 19.6363 18.2225L13.4138 12L19.6363 5.77743C19.7144 5.6994 19.7144 5.57273 19.6363 5.49462L18.5049 4.36324C18.4268 4.28514 18.3002 4.28518 18.2221 4.36321L11.9996 10.5858L5.77702 4.36324C5.69891 4.28514 5.57224 4.28518 5.49421 4.36321L4.36277 5.49465Z"
            fill="#7F7F7F"
          />
        </g>
        <defs>
          <clipPath id="clip0_391_8461">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default CloseIcon;
