import React, { FC, useEffect, useState } from 'react';
import { message, Progress, Radio } from 'antd';
import './insights.scss';
import {
  ConversationMessageTypes,
  MessageRatingTypes,
  ProjectInsightsType,
  ProjectSettingsType,
} from '../../../shared/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteNames } from '../../../router/router';
import Insight from '../../insight/insight';
import IInsight from '../../../types/IInsight';
import { projectsAPI } from '../../../services/projects-service';
import Loading from '../../loading/loading';
import {
  CheckSquareOutlined,
  DislikeOutlined,
  LikeOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { useAppSelector } from '../../../hooks/redux-hooks';
import TeachModelModal, {
  IQAKnowledgeContent,
} from '../../modals/teach-model-modal/teach-model-modal';
import { IngestQATypes } from '../../modals/qa-ingest-modals/qa-ingest-shared-types';
import { IConversationMessageItem } from '../../../types/IConversationMessage';

const Insights: FC<{ projectId: string }> = ({ projectId }) => {
  const { currentOrganizationLoading } = useAppSelector(
    (state) => state.organizationsReducer
  );
  const { orgId, subtab } = useParams();
  const navigate = useNavigate();
  const [skip, setSkip] = useState(true);
  const {
    data: projectInsights,
    isLoading: projectInsightsLoading,
    error: projectInsightsError,
  } = projectsAPI.useGetProjectInsightsQuery({ projectId, orgId }, { skip });
  const [
    lazyGetProjectsInsights,
    { isLoading: lazyGetProjectsInsightsLoading },
  ] = projectsAPI.useLazyGetProjectInsightsQuery();
  const [activeTab, setActiveTab] = useState(
    subtab ?? ProjectInsightsType.POORLY_RATED
  );
  const [insights, setInsights] = useState<IInsight[]>([]);
  const [feedbackId, setFeedbackId] = useState('');
  const [teachModalIsOpen, setTeachModalIsOpen] = useState(false);
  const [qaKnowledgeContent, setQAKnowledgeContent] =
    useState<IQAKnowledgeContent | null>(null);
  const [messageApi, contextHolder] = message.useMessage();

  const options = [
    {
      label: (
        <div className="insight-tab-label">
          <DislikeOutlined className="insight-tab-icon" /> Poorly Rated
        </div>
      ),
      value: ProjectInsightsType.POORLY_RATED,
    },
    // { label: 'No Sources Found', value: ProjectInsightsType.NO_SOURCES_FOUND },
    {
      label: (
        <div className="insight-tab-label">
          <LikeOutlined className="insight-tab-icon" /> Well Rated
        </div>
      ),
      value: ProjectInsightsType.WELL_RATED,
    },
    {
      label: (
        <div className="insight-tab-label">
          <CheckSquareOutlined className="insight-tab-icon" /> Taught Questions
        </div>
      ),
      value: ProjectInsightsType.TAUGHT_QUESTIONS,
    },
  ];

  useEffect(() => {
    if (!currentOrganizationLoading) {
      setSkip(false);
    }
  }, [currentOrganizationLoading]);

  useEffect(() => {
    if (skip) return;
    lazyGetProjectsInsights({ projectId, orgId });
  }, []);

  useEffect(() => {
    const option = options.find((option) => option.value === subtab);
    if (!subtab || !option) {
      handleChangeSubtab(ProjectInsightsType.POORLY_RATED, true);
      setActiveTab(ProjectInsightsType.POORLY_RATED);
      return;
    }
    setActiveTab(option.value);
  }, [subtab]);

  useEffect(() => {
    if (projectInsights && subtab) {
      let insights: IInsight[] = [];
      if (subtab === ProjectInsightsType.POORLY_RATED) {
        insights = projectInsights.feedback.filter(
          (insight) =>
            insight.react === MessageRatingTypes.NEGATIVE && !insight.isResolved
        );
      }
      if (subtab === ProjectInsightsType.WELL_RATED) {
        insights = projectInsights.feedback.filter(
          (insight) =>
            insight.react === MessageRatingTypes.POSITIVE && !insight.isResolved
        );
      }
      if (subtab === ProjectInsightsType.TAUGHT_QUESTIONS) {
        insights = projectInsights.feedback.filter(
          (insight) => insight.isResolved
        );
      }
      setInsights(insights);
    }
  }, [projectInsights, subtab]);

  const handleChangeSubtab = (
    value: string | number,
    replace: boolean = false
  ) => {
    navigate(
      `${RouteNames.USER_PROJECTS}/${orgId}${RouteNames.PROJECT_SETTINGS_TAB_NAME}/${projectId}/${ProjectSettingsType.INSIGHTS}/${value}`,
      { replace: replace }
    );
  };

  const handleTeachModel = (item: IQAKnowledgeContent, reactId: string) => {
    setFeedbackId(reactId);
    setQAKnowledgeContent(item);
  };

  const handleTeachModelCommand = (item: IConversationMessageItem) => {
    // TODO: feedbackId ?
    if (item.$type === ConversationMessageTypes.COMMAND) {
      let question = '';
      if (item.result) {
        try {
          const result = JSON.parse(item.result);
          if (result.data.extra_information_to_assistant) {
            delete result.data.extra_information_to_assistant;
          }
          let message = '';
          if (result.data.message) {
            message = JSON.stringify(result.data.message);
            delete result.data.message;
          }
          const isEmpty = Object.keys(result.data).length === 0;
          const objectData = isEmpty ? '' : JSON.stringify(result.data) + '\n';
          question = objectData + message;
        } catch (e) {}
      }

      setQAKnowledgeContent({
        question: question,
        answer: '',
        type: IngestQATypes.COMMAND_RESPONDS,
        command: item.name || '',
      });
    }
  };

  useEffect(() => {
    if (qaKnowledgeContent) {
      setTeachModalIsOpen(true);
    } else {
      setTeachModalIsOpen(false);
    }
  }, [qaKnowledgeContent]);

  return (
    <div className="insights">
      {contextHolder}
      {projectInsightsLoading || lazyGetProjectsInsightsLoading ? (
        <Loading height={'300px'} />
      ) : projectInsightsError ? (
        <div>Ooops, something went wrong.</div>
      ) : projectInsights &&
        projectInsights.feedback &&
        projectInsights.feedback.length > 0 ? (
        <>
          <div className="insights__inner">
            <div className="insights-data">
              <Radio.Group
                defaultValue={activeTab}
                onChange={(e) => handleChangeSubtab(e.target.value)}
                className={'insights-tabs'}
                value={activeTab}
              >
                {options.map((option) => (
                  <Radio.Button
                    value={option.value}
                    key={option.value}
                    className={'insights-tab'}
                  >
                    {option.label}
                  </Radio.Button>
                ))}
              </Radio.Group>
              {/*{getInsightsComponent()}*/}
              <div className="insights-list">
                {insights.length > 0 ? (
                  insights.map((insight) => {
                    return (
                      <Insight
                        {...insight}
                        handleTeachModel={handleTeachModel}
                        handleTeachModelCommand={handleTeachModelCommand}
                        key={insight.id}
                        messageApi={messageApi}
                      />
                    );
                  })
                ) : (
                  <div>Empty.</div>
                )}
              </div>
              <TeachModelModal
                qaKnowledgeContent={qaKnowledgeContent}
                isOpen={teachModalIsOpen}
                setIsOpen={setTeachModalIsOpen}
                projectId={projectId}
                feedbackId={feedbackId}
              />
            </div>
            <div className="insight-stats">
              <div className="insight-stats-title">User rating</div>
              <div className="insight-stats-subtitle">
                Describes average user rating
              </div>
              <div className="insight-stats-progress-container">
                <Progress
                  percent={+projectInsights.statistic.positive.toFixed(2)}
                  trailColor={'#F34751'}
                  strokeColor={'#52C41A'}
                  type="circle"
                  size={104}
                />
                <div className="insight-stats-progress-description">
                  <div>
                    <LikeOutlined />{' '}
                    {+projectInsights.statistic.positive.toFixed(2)}% of users
                    are satisfied
                  </div>
                  <div>
                    <DislikeOutlined />{' '}
                    {+projectInsights.statistic.negative.toFixed(2)}% of users
                    are not
                  </div>
                  {/*<div><FileSearchOutlined /> 100% source found</div>*/}
                  <div>
                    <QuestionCircleOutlined />{' '}
                    {projectInsights.statistic.taught}% taught questions
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : projectInsights &&
        projectInsights.feedback &&
        projectInsights.feedback.length === 0 ? (
        <div className="insight-stats-no-rating">
          <div className="insight-stats-no-rating__title">
            Message Rating Statistics
          </div>
          <div className="insight-stats-no-rating__subtitle">
            No message ratings available at the moment. As users rate messages,
            the statistics will appear here. Please check back later.
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Insights;
