import React, { useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { LogoMode } from '../../shared/constants';
import { getDomainForImage } from '../../shared/helpers';
import { useAppSelector } from '../../hooks/redux-hooks';

const ConversationAvatar = () => {
  const { globalTimestamp } = useAppSelector((state) => state.domainsReducer);
  const { filePath } = useAppSelector((state) => state.domainsReducer);
  const [additionalClassName, setAdditionalClassName] =
    useState<string>('insight-avatar--ai');

  return (
    <Avatar
      size={40}
      icon={<UserOutlined />}
      src={`https://static.${getDomainForImage()}/static/${filePath}logo-${
        LogoMode.DARK
      }.svg?timestamp=${globalTimestamp}`}
      className={`insight-avatar ${additionalClassName}`}
      onError={() => {
        setAdditionalClassName('');
        return true;
      }}
    />
  );
};

export default ConversationAvatar;
