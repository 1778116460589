import React, { FC } from 'react';
import { Divider } from 'antd';
import TextInputFormItem from './form-items/text-input-form-item';

const TagRecordFormElement: FC = () => {
  return (
    <>
      <Divider orientation="left" className={'contact-form-divider'}>
        Tag
      </Divider>
      <TextInputFormItem label={'Tag'} name={'tag'} placeholder={'Tag'} />
    </>
  );
};

export default TagRecordFormElement;
