import React, { Dispatch, FC, SetStateAction } from 'react';
import { Modal } from 'antd';
import Loading from '../../loading/loading';
import IFacebookPageTos from '../../../types/IFacebookPageTos';

interface FbPluginIsPageTosAcceptedModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isPageTosAcceptedData: IFacebookPageTos | undefined;
  isPageTosAcceptedLoading: boolean;
  fbIsTosAcceptedModalPageName: string;
  checkIsPageTosAcceptedCb: () => void;
}

const FbPluginIsPageTosAcceptedModal: FC<FbPluginIsPageTosAcceptedModal> = ({
  isOpen,
  setIsOpen,
  isPageTosAcceptedData,
  isPageTosAcceptedLoading,
  fbIsTosAcceptedModalPageName,
  checkIsPageTosAcceptedCb,
}) => {
  const handleConfirm = () => {
    checkIsPageTosAcceptedCb();
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      title="Accept the Page Contact Terms"
      open={isOpen}
      onOk={handleConfirm}
      onCancel={handleCancel}
      centered={true}
      okButtonProps={{
        disabled: !isPageTosAcceptedData || isPageTosAcceptedLoading,
        loading: isPageTosAcceptedLoading,
      }}
      okText={'Confirm'}
      wrapClassName={'modal'}
      maskStyle={{ zIndex: 1100 }}
      closable={false}
    >
      {!isPageTosAcceptedData ||
      isPageTosAcceptedLoading ||
      !fbIsTosAcceptedModalPageName ? (
        <Loading height={'100px'} />
      ) : (
        <div>
          {process.env.REACT_APP_DISPLAY_NAME} marks conversations in your
          Facebook Page Inbox with labels. In order to enable this functionality
          you must first accept the Page Contact Terms. Please navigate to{' '}
          <a
            className={'modal-link'}
            target={'_blank'}
            href={isPageTosAcceptedData.url}
          >
            this link
          </a>
          , ensure you are currently logged in as {fbIsTosAcceptedModalPageName}{' '}
          on Facebook and click "Accept".
        </div>
      )}
    </Modal>
  );
};

export default FbPluginIsPageTosAcceptedModal;
