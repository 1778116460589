import React, { FC, useEffect, useState } from 'react';
import { message, Modal } from 'antd';
import { contactsAPI } from '../../../../services/contacts-service';
import { showErrorMessage } from '../../../../shared/helpers';

interface IDeleteContactModalProps {
  projectId: string;
  deleteContactId: string;
  setDeleteContactId: React.Dispatch<React.SetStateAction<string>>;
}

const DeleteContactModal: FC<IDeleteContactModalProps> = ({
  projectId,
  deleteContactId,
  setDeleteContactId,
}) => {
  const [deleteContact] = contactsAPI.useDeleteContactMutation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [deleteDisabled, setDeleteDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (deleteContactId) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [deleteContactId]);

  const handleCancel = () => {
    setDeleteContactId('');
  };

  const handleOk = async () => {
    setDeleteDisabled(true);
    const result = await deleteContact({
      projectId,
      contactId: deleteContactId,
    });

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
    } else {
      await messageApi.success('The contact has been deleted.', 1);
      setDeleteContactId('');
    }

    setDeleteDisabled(false);
  };

  return (
    <Modal
      wrapClassName={'modal'}
      title="Delete contact"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{
        disabled: deleteDisabled,
        danger: true,
        loading: deleteDisabled,
      }}
      centered={true}
    >
      {contextHolder}
      <p>
        Please confirm the deletion of this contact. Note that this will
        permanently erase all associated information. This action cannot be
        undone.
      </p>
    </Modal>
  );
};

export default DeleteContactModal;
