import React, { FC, useEffect, useState } from 'react';
import IConversationMessage, {
  IConversationMessageItem,
} from '../../../types/IConversationMessage';
import ConversationUserMessage from './conversation-user-message';
import ConversationAiMessage from './conversation-ai-message';
import { Button } from 'antd';
import { ReadOutlined } from '@ant-design/icons';
import { MessageInstance } from 'antd/es/message/interface';

interface IConversationMessagesProps {
  conversationMessages: IConversationMessage[];
  handleTeachModel: (item: IConversationItem) => void;
  handleTeachModelCommand: (item: IConversationMessageItem) => void;
  openTrace: (messageId: string) => void;
  projectId: string;
  chatId: string;
  messageApi: MessageInstance;
}

export interface IConversationItem {
  userMsg: IConversationItemMessage | null;
  botMsgs: IConversationItemMessage[];
}

interface IConversationItemMessage extends IConversationMessage {
  index: number;
}

const ConversationMessages: FC<IConversationMessagesProps> = ({
  conversationMessages,
  handleTeachModel,
  handleTeachModelCommand,
  openTrace,
  projectId,
  chatId,
  messageApi,
}) => {
  const [conversationItems, setConversationItems] = useState<
    IConversationItem[]
  >([]);

  useEffect(() => {
    const transformedArray = [];
    let currentGroup: IConversationItem = { userMsg: null, botMsgs: [] };

    for (let i = 0; i < conversationMessages.length; i++) {
      const msg = conversationMessages[i];
      if (msg.isUserSender) {
        transformedArray.push(currentGroup);
        currentGroup = { userMsg: { ...msg, index: i }, botMsgs: [] };
      } else {
        currentGroup.botMsgs.push({ ...msg, index: i });
      }
    }

    transformedArray.push(currentGroup);

    setConversationItems(transformedArray);
  }, [conversationMessages]);

  return (
    <>
      {conversationItems.map((item, index) => (
        <div className="conversation-msg-container" key={index}>
          {item.userMsg && (
            <ConversationUserMessage
              message={item.userMsg}
              openTrace={openTrace}
              messageApi={messageApi}
              projectId={projectId}
              chatId={chatId}
              sharedConversation={false}
            />
          )}
          {
            <ConversationAiMessage
              messages={item.botMsgs}
              handleTeachModel={handleTeachModelCommand}
            />
          }
          {index > 0 ? (
            <div className="insight-btns-container conversation-btns-container">
              <Button
                icon={<ReadOutlined />}
                onClick={() => handleTeachModel(item)}
              >
                Teach Model
              </Button>
            </div>
          ) : null}
        </div>
      ))}
    </>
  );
};

export default ConversationMessages;
