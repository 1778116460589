import React, { FC, useEffect, useState } from 'react';
import { Button } from 'antd';
import { ConversationReviewOptions } from './conversation-filters-helpers';
import { showErrorMessage } from '../../../shared/helpers';
import { projectsAPI } from '../../../services/projects-service';
import IConversation from '../../../types/IConversation';
import { IConversationsParams } from '../../../types/IConversationsParams';
import { MessageInstance } from 'antd/es/message/interface';

interface IConversationReviewToggleProps {
  projectId: string;
  conversation: IConversation;
  conversationParams: IConversationsParams;
  messageApi: MessageInstance;
}

// TODO: refactor (check conversation-mark-toggle)
const ConversationReviewToggle: FC<IConversationReviewToggleProps> = ({
  projectId,
  conversation,
  conversationParams,
  messageApi,
}) => {
  const [reviewValue, setReviewValue] = useState<ConversationReviewOptions>(
    conversation.reviewedAt
      ? ConversationReviewOptions.REVIEW
      : ConversationReviewOptions.FOR_REVIEW
  );
  const [
    markConversationAsReviewed,
    { isLoading: markConversationAsReviewedLoading },
  ] = projectsAPI.useMarkConversationAsReviewedMutation();
  const [
    markConversationAsForReview,
    { isLoading: markConversationAsForReviewLoading },
  ] = projectsAPI.useMarkConversationAsForReviewMutation();
  const [lazyGetProjectConversations] =
    projectsAPI.useLazyGetProjectConversationsQuery();

  useEffect(() => {
    if (conversation.reviewedAt) {
      setReviewValue(ConversationReviewOptions.REVIEW);
    } else {
      setReviewValue(ConversationReviewOptions.FOR_REVIEW);
    }
  }, [conversation]);

  const markAsReviewed = async () => {
    const res = await markConversationAsReviewed({
      projectId,
      conversationId: conversation.id,
    });

    if ('error' in res) {
      showErrorMessage(messageApi, res.error);
    } else {
      messageApi.success('Conversation marked as "Reviewed".');
      lazyGetProjectConversations({ projectId, params: conversationParams });
    }
  };

  const markAsForReview = async () => {
    const res = await markConversationAsForReview({
      projectId,
      conversationId: conversation.id,
    });

    if ('error' in res) {
      showErrorMessage(messageApi, res.error);
    } else {
      messageApi.success('Conversation marked as "For Review".');
      lazyGetProjectConversations({ projectId, params: conversationParams });
    }
  };

  const reviewButton = () => (
    <Button
      onClick={markAsReviewed}
      disabled={
        markConversationAsReviewedLoading || markConversationAsForReviewLoading
      }
    >
      Review
    </Button>
  );

  const forReviewButton = () => (
    <Button
      onClick={markAsForReview}
      disabled={
        markConversationAsReviewedLoading || markConversationAsForReviewLoading
      }
    >
      For Review
    </Button>
  );

  return (
    <div className={'conversation-toggle'}>
      {reviewValue === ConversationReviewOptions.REVIEW
        ? forReviewButton()
        : reviewButton()}
    </div>
  );
};

export default ConversationReviewToggle;
