import React, { FC } from 'react';
import { Alert, Button } from 'antd';
import { RouteNames } from '../../router/router';
import {
  LS_USER_USAGE_INFO,
  ProfileSettingsType,
} from '../../shared/constants';
import { useNavigate } from 'react-router-dom';
import IUsageInfoLimitsClosedLS from '../../types/IUsageInfoLimitsClosedLS';

const LimitWarning: FC<{
  type: 'success' | 'info' | 'warning' | 'error' | undefined;
}> = ({ type }) => {
  const navigate = useNavigate();
  const title = 'User limit';

  const setLSUsageInfo = () => {
    const lsUsageInfo = localStorage.getItem(LS_USER_USAGE_INFO);

    let softClosed: boolean = false;
    let hardClosed: boolean = false;

    if (lsUsageInfo) {
      const lsUsageInfoClosed: IUsageInfoLimitsClosedLS =
        JSON.parse(lsUsageInfo);
      if (lsUsageInfoClosed.soft) softClosed = lsUsageInfoClosed.soft;
      if (lsUsageInfoClosed.hard) hardClosed = lsUsageInfoClosed.hard;
    }

    if (type === 'error') {
      localStorage.setItem(
        LS_USER_USAGE_INFO,
        JSON.stringify({ soft: softClosed, hard: true })
      );
    }

    if (type === 'warning') {
      localStorage.setItem(
        LS_USER_USAGE_INFO,
        JSON.stringify({ soft: true, hard: hardClosed })
      );
    }
  };

  const limitMessage: FC<{
    textBeforeBtn: string;
    btnText: string;
    textAfterBtn: string;
  }> = ({ textBeforeBtn, btnText, textAfterBtn }) => (
    <span>
      {textBeforeBtn}
      <Button
        type="link"
        onClick={() =>
          navigate(
            `${RouteNames.PROFILE_SETTINGS_NO_PARAMS}/${ProfileSettingsType.BUY_CREDITS}`
          )
        }
        className={'alert-btn-link'}
      >
        {btnText}
      </Button>
      {textAfterBtn}
    </span>
  );

  const getLimitMessage = () => {
    if (type === 'error') {
      return limitMessage({
        textBeforeBtn: 'You have used your plan tokens. ',
        btnText: 'Purchase credits',
        textAfterBtn: ' to continue.',
      });
    }
    if (type === 'warning') {
      return limitMessage({
        textBeforeBtn:
          'You have used more than half of your plan tokens. We advise you to ',
        btnText: 'purchase credits',
        textAfterBtn: ' .',
      });
    }
  };

  return (
    <Alert
      className="project-settings-alert"
      message={title}
      description={getLimitMessage()}
      type={type}
      showIcon
      closable
      onClose={setLSUsageInfo}
    />
  );
};

export default LimitWarning;
