import React, { FC } from 'react';
import { Spin } from 'antd';
import './loading.scss';
import { LoadingOutlined } from '@ant-design/icons';

const Loading: FC<{ height?: string }> = ({ height }) => {
  return (
    <div className="container loading" style={{ height }}>
      <Spin
        indicator={<LoadingOutlined spin />}
        size="large"
        tip="Loading..."
        className="loading__spin"
      />
    </div>
  );
};

export default Loading;
