import {
  WidgetAnalyticsEventsData,
  IAnalyticsEventsData,
  AnalyticsData,
} from '../../components/project-settings/analytics/analytics-data';
import React, { useEffect, useState } from 'react';
import IProjectPlugin from '../../types/IProjectPlugin';

export function useGetAnalyticsList(
  projectId: string,
  currentPluginsData: IProjectPlugin[]
): [
  IAnalyticsEventsData[],
  React.Dispatch<React.SetStateAction<IAnalyticsEventsData[]>>
] {
  const [analyticsList, setAnalyticsList] = useState<IAnalyticsEventsData[]>(
    WidgetAnalyticsEventsData
  );

  useEffect(() => {
    const dynamicsAnalyticsData = [...WidgetAnalyticsEventsData];

    for (let i = 0; i < currentPluginsData.length; i++) {
      const pluginName = currentPluginsData[i].name;

      if (AnalyticsData[pluginName]) {
        const eventsData = AnalyticsData[pluginName].eventsData;
        dynamicsAnalyticsData.push(...eventsData);
      }
    }

    setAnalyticsList(dynamicsAnalyticsData);
  }, [currentPluginsData]);

  return [analyticsList, setAnalyticsList];
}
