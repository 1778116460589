import React, { FC, useEffect, useState } from 'react';
import { Column } from 'react-data-grid';
import { ITableFilterValues } from '../../whats-app-outbound/filters/filters-helpers';
import HeaderCellMoreMenu from './header-cell-more-menu/header-cell-more-menu';
import {
  IEditorTableColumn,
  ITableColumnT,
} from '../../../../types/tables-service/ITableT';
import TablesUtils from '../../../shared/tables/tables-utils';

interface IEditableHeaderCellProps {
  isReadOnly: boolean;
  column: Column<{ [key: string]: string }>;
  onHeaderChange: (newName: string) => void;
  editingHeaderCellKey: string;
  setEditingHeaderCellKey: React.Dispatch<React.SetStateAction<string>>;
  onDeleteColumn: (columnKey: string) => void;
  setTableColumns: React.Dispatch<React.SetStateAction<IEditorTableColumn[]>>;
  setFilterValues: React.Dispatch<React.SetStateAction<ITableFilterValues[]>>;
  openHeaderCellMoreMenu: string;
  setOpenHeaderCellMoreMenu: React.Dispatch<React.SetStateAction<string>>;
}

const EditableHeaderCell: FC<IEditableHeaderCellProps> = ({
  isReadOnly,
  column,
  onHeaderChange,
  editingHeaderCellKey,
  setEditingHeaderCellKey,
  onDeleteColumn,
  setTableColumns,
  setFilterValues,
  openHeaderCellMoreMenu,
  setOpenHeaderCellMoreMenu,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [headerText, setHeaderText] = useState<string>(column.name as string);

  useEffect(() => {
    if (isReadOnly) {
      setIsEditing(false);
      return;
    }
    if (editingHeaderCellKey) {
      setIsEditing(column.key === editingHeaderCellKey);
    } else {
      setIsEditing(false);
      onHeaderChange(headerText);
    }
  }, [editingHeaderCellKey]);

  const handleDoubleClick = () => {
    if (isReadOnly) return;
    setIsEditing(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHeaderText(e.target.value);
  };

  const handleBlur = () => {
    setEditingHeaderCellKey('');
    setIsEditing(false);
    onHeaderChange(headerText);
  };

  const onChangeColumn = (columnProps: ITableColumnT) => {
    TablesUtils.handleChangeColumn(columnProps, setTableColumns);
    setHeaderText(columnProps.name);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      isEditing &&
      editingHeaderCellKey === column.key &&
      (e.key === 'ArrowLeft' || e.key === 'ArrowRight')
    ) {
      e.stopPropagation();
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 8,
        height: '100%',
      }}
      onDoubleClick={handleDoubleClick}
    >
      {isEditing ? (
        <input
          type="text"
          value={headerText}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
          style={{
            background: 'transparent',
            border: 'none',
            outline: 'none',
          }}
          onKeyDown={handleKeyDown}
        />
      ) : (
        <span>{headerText}</span>
      )}
      {
        <>
          <HeaderCellMoreMenu
            isReadOnly={isReadOnly}
            column={column}
            onDeleteColumn={onDeleteColumn}
            onChangeColumn={onChangeColumn}
            setFilterValues={setFilterValues}
            openHeaderCellMoreMenu={openHeaderCellMoreMenu}
            setOpenHeaderCellMoreMenu={setOpenHeaderCellMoreMenu}
          />
        </>
      }
    </div>
  );
};

export default EditableHeaderCell;
