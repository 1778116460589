import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import { V_REQUIRED_FIELD } from '../../../shared/constants';
import { Link } from 'react-router-dom';
import { RouteNames } from '../../../router/router';
import './forgot-password.scss';
import { usersAPI } from '../../../services/users-service';
import { isClientDomain, showErrorMessage } from '../../../shared/helpers';
import { emailRules } from '../../../shared/rules';

const RESET_TIMER_AFTER_MS = 60000;

const ForgotPassword: FC = () => {
  const [forgotPassword, { isLoading }] = usersAPI.useForgotPasswordMutation();
  const [form] = Form.useForm();
  const [forgotDisabled, setForgotDisabled] = useState<boolean>(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [timerLock, setTimerLock] = useState<boolean>(false);
  const [timeToUnlock, setTimeToUnlock] = useState<number>(0);
  const [timerInterval, setTimerInterval] = useState<number | null>(null);

  const handleForgotPassword = async () => {
    const email = form.getFieldValue('email');
    if (!email) {
      return;
    }
    setForgotDisabled(true);
    const result = await forgotPassword(email);

    if ('error' in result) {
      showErrorMessage(messageApi, result.error);
    } else {
      //TODO: ADD GA EVENT ?
      startTimer();
      await messageApi.success(
        `We have send you a link to reset your password.`
      );
    }
    setForgotDisabled(false);
  };

  const startTimer = () => {
    if (timerInterval !== null) {
      window.clearInterval(timerInterval);
    }

    setTimerLock(true);
    setTimeToUnlock(RESET_TIMER_AFTER_MS);

    const currentTime = new Date().getTime();

    const interval = window.setInterval(checkTime, 1000, currentTime);
    setTimerInterval(interval);
  };

  const checkTime = (savedTime: number) => {
    if (!savedTime) {
      return;
    }
    const timeLeft = RESET_TIMER_AFTER_MS - (new Date().getTime() - savedTime);
    setTimeToUnlock(timeLeft);
  };

  const resetTimerLock = () => {
    setTimerLock(false);
    if (timerInterval !== null) {
      window.clearInterval(timerInterval);
      setTimerInterval(null);
    }
  };

  useEffect(() => {
    if (timeToUnlock <= 0) {
      resetTimerLock();
    } else {
      if (timerInterval === null) {
        const interval = window.setInterval(checkTime, 1000);
        setTimerInterval(interval);
      }
    }
  }, [timeToUnlock]);

  const handleChange = async () => {
    try {
      await form.validateFields();
      setForgotDisabled(false);
    } catch (errors) {
      setForgotDisabled(true);
    }
  };

  const registerNow = () => {
    if (process.env.REACT_APP_SHOW_REGISTER === 'false' || isClientDomain()) {
      return null;
    }

    return (
      <div>
        Don't have an account?{' '}
        <Link to={RouteNames.REGISTER} className="login-link">
          Register now!
        </Link>
      </div>
    );
  };

  return (
    <div className="no-header-container">
      {contextHolder}
      <div className="container">
        <div className="forgot-password__inner">
          <div className="verify-email-title">Forgot password?</div>
          <div>
            Enter the email address associated with your account and we'll send
            you a link to reset your password.
          </div>
          <Form
            name="forgot-password"
            className="login-form"
            onFinish={handleForgotPassword}
            form={form}
            onChange={handleChange}
          >
            <Form.Item name="email" rules={emailRules}>
              <Input placeholder="E-mail" />
            </Form.Item>
            {timerLock && timeToUnlock > 0 && (
              <div className={'reset-timer-info'}>
                You can request the reset link again after{' '}
                {Math.ceil(timeToUnlock / 1000)}s.
              </div>
            )}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button login-btn"
                loading={isLoading}
                disabled={forgotDisabled || timerLock}
              >
                Request reset link
              </Button>
            </Form.Item>
            {registerNow()}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
