import React, { FC, useEffect, useState } from 'react';
import { Button, Dropdown, MenuProps } from 'antd';
import {
  DeleteOutlined,
  DownloadOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { MessageInstance } from 'antd/es/message/interface';
import {
  KnowledgeDataSources,
  KnowledgeStatus,
} from '../../../shared/constants';
import { useKnowledgeDownloadController } from './knowledge-download-controller';
import { showErrorMessage } from '../../../shared/helpers';
import { GA_DELETE_FILE } from '../../../shared/google-analytics';
import { projectsAPI } from '../../../services/projects-service';

interface IKnowledgeMoreMenuProps {
  projectId: string;
  knowledgeId: string;
  fileName: string;
  disabled: boolean;
  messageApi: MessageInstance;
  recordType: KnowledgeDataSources;
  recordStatus: KnowledgeStatus;
  isParent: boolean;
  cb: () => void;
}

const KnowledgeMoreMenu: FC<IKnowledgeMoreMenuProps> = ({
  projectId,
  knowledgeId,
  fileName,
  disabled,
  messageApi,
  recordType,
  recordStatus,
  isParent,
  cb,
}) => {
  const { getFile } = useKnowledgeDownloadController(
    projectId,
    knowledgeId,
    recordType,
    fileName,
    messageApi
  );
  const [deleteKnowledge] = projectsAPI.useDeleteProjectKnowledgeMutation();
  const [deleteKnowledgeDisabledList, setDeleteKnowledgeDisabledList] =
    useState<string[]>([]);

  const defaultMenuItems: MenuProps['items'] = [
    {
      key: 'Delete',
      label: (
        <a onClick={() => handleDelete(knowledgeId, fileName)}>
          <DeleteOutlined style={{ marginRight: 6 }} /> Delete
        </a>
      ),
      // disabled: recordType === KnowledgeDataSources.TABLE,
    },
  ];
  const [menuItems, setMenuItems] =
    useState<MenuProps['items']>(defaultMenuItems);

  useEffect(() => {
    if (recordStatus === KnowledgeStatus.READY && !isParent) {
      setMenuItems([
        {
          key: 'download',
          label: (
            <a onClick={getFile}>
              <DownloadOutlined style={{ marginRight: 6 }} /> Download
            </a>
          ),
          disabled: recordType === KnowledgeDataSources.TABLE,
        },
        ...defaultMenuItems,
      ]);
    } else {
      setMenuItems(defaultMenuItems);
    }
  }, [recordStatus]);

  const handleDelete = async (knowledgeId: string, knowledgeName: string) => {
    if (deleteKnowledgeDisabledList.includes(knowledgeId)) return;
    setDeleteKnowledgeDisabledList([
      JSON.parse(JSON.stringify(deleteKnowledgeDisabledList)),
      knowledgeId,
    ]);
    const result = await deleteKnowledge({ projectId, knowledgeId });

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error as { data: string });
    } else {
      const fileExtension = knowledgeName.split('.').pop();
      GA_DELETE_FILE(fileExtension || 'N/A');
      cb();
      await messageApi.success('Knowledge has been deleted.');
    }

    // shouldn't clear, async, bugs
    // const newDeleteKnowledgeDisabledList = deleteKnowledgeDisabledList.filter((id) => id !== knowledgeId);
    // setDeleteKnowledgeDisabledList(newDeleteKnowledgeDisabledList);
  };

  return (
    <div>
      <Dropdown
        menu={{ items: menuItems }}
        placement="bottomRight"
        arrow={{ pointAtCenter: true }}
        trigger={['click']}
      >
        <Button type="text" icon={<MoreOutlined />} disabled={disabled} />
      </Dropdown>
    </div>
  );
};

export default KnowledgeMoreMenu;
