import React, { FC } from 'react';
import {
  ITableRecordsT,
  ITableRowT,
  ITableT,
} from '../../../../types/tables-service/ITableT';
import DeleteTableRows from './table-actions-components/delete-table-rows';
import ExportTable from './table-actions-components/export-table';
import { ITableFilterValues } from '../../whats-app-outbound/filters/filters-helpers';
import { MessageInstance } from 'antd/es/message/interface';
import TableFilter from '../../whats-app-outbound/filters/table-filter';

interface ITableActionsProps {
  projectId: string;
  tableData: ITableT | undefined | null;
  isReadOnly: boolean;
  selectedRows: Set<string>;
  setSelectedRows: React.Dispatch<React.SetStateAction<Set<string>>>;
  tableRecordsData: ITableRecordsT | undefined | null;
  tableRows: ITableRowT[];
  onRowsChange: (rows: ITableRowT[]) => void;
  getRecordsCb: () => Promise<ITableRecordsT | undefined | null>;
  messageApi: MessageInstance;
  filterValues: ITableFilterValues[];
  setFilterValues: React.Dispatch<React.SetStateAction<ITableFilterValues[]>>;
  setDeleteRowLoading: React.Dispatch<React.SetStateAction<boolean>>;
  renderImportAction: null | (() => JSX.Element);
}

const TableActions: FC<ITableActionsProps> = ({
  projectId,
  tableData,
  isReadOnly,
  selectedRows,
  setSelectedRows,
  tableRecordsData,
  tableRows,
  onRowsChange,
  getRecordsCb,
  messageApi,
  filterValues,
  setFilterValues,
  setDeleteRowLoading,
  renderImportAction,
}) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 16,
          //justifyContent: 'flex-end',
          marginBottom: 16,
        }}
      >
        <DeleteTableRows
          projectId={projectId}
          tableData={tableData}
          isReadOnly={isReadOnly}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          tableRecordsData={tableRecordsData}
          tableRows={tableRows}
          onRowsChange={onRowsChange}
          getRecordsCb={getRecordsCb}
          setDeleteRowLoading={setDeleteRowLoading}
        />
        {renderImportAction && renderImportAction()}
        {tableData && (
          <ExportTable
            projectId={projectId}
            tableData={tableData}
            messageApi={messageApi}
            filterValues={filterValues}
          />
        )}
        {tableData && (
          <TableFilter
            tableData={tableData}
            setFilterValues={setFilterValues}
          />
        )}
      </div>
    </>
  );
};

export default TableActions;
