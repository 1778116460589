import React, { FC, useState } from 'react';
import { Skeleton } from 'antd';
import { formatNumber } from '../../../../shared/helpers';
import MonthPicker from '../../../charts/month-picker';
import UsagePerDayChart from '../../usage-setting/usage-per-day-chart';
import UsagePerProjectChart from '../../usage-setting/usage-per-project-chart';
import { subscriptionAPI } from '../../../../services/subscription-service';
import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router-dom';
import { IChartsSettings } from '../../../charts/chart-data';

const OrgUsage: FC = () => {
  const { orgId } = useParams();
  const {
    data: organizationUsage,
    isLoading: organizationUsageLoading,
    error: organizationUsageError,
  } = subscriptionAPI.useGetOrganizationUsageQuery(orgId ?? skipToken);
  const initialUsageSettings = { month: new Date(), isOrg: true };
  const [chartSettings, setChartSettings] =
    useState<IChartsSettings>(initialUsageSettings);

  const onMonthSelected = (date: Date) => {
    setChartSettings({ month: date });
  };

  const renderOrganizationUsage = () => {
    if (organizationUsageLoading) {
      return (
        <Skeleton active className={'organization-usage-skeleton-loading'} />
      );
    }

    if (organizationUsageError) {
      return (
        <>
          {/*<div className="profile-setting-title organization-usage-title">*/}
          {/*  Usage*/}
          {/*</div>*/}
          <div className="usage-setting">Can't get organization usage.</div>
        </>
      );
    }

    if (organizationUsage) {
      return (
        <>
          {/*<div className="profile-setting-title organization-usage-title">*/}
          {/*  Usage*/}
          {/*</div>*/}
          <div className="usage-setting">
            <div className="usage-setting-content profile-setting-content-container">
              <div className="usage-setting-content__info">
                <div className="usage-setting-content__info-item usage-setting-content__info-item--org">
                  <div className="usage-setting-content__info-item-title">
                    Total credits:
                  </div>
                  <div className="usage-setting-content__info-progress-stats">
                    {formatNumber(organizationUsage.totalTokens)}
                  </div>
                </div>
                <div className="usage-setting-content__info-item usage-setting-content__info-item--org">
                  <div className="usage-setting-content__info-item-title">
                    Credits left:
                  </div>
                  <div className="usage-setting-content__info-progress-stats">
                    {formatNumber(organizationUsage.creditsLeft)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: '100%', height: '600px' }}>
            <MonthPicker
              onDateChange={onMonthSelected}
              minDate={new Date('2024-01-01')}
              maxDate={new Date(Date.now())}
            />
            {orgId && (
              <>
                <UsagePerDayChart
                  settings={chartSettings}
                  organizationId={orgId}
                />
                <UsagePerProjectChart
                  settings={chartSettings}
                  organizationId={orgId}
                />
              </>
            )}
          </div>
        </>
      );
    }

    return null;
  };

  return renderOrganizationUsage();
};

export default OrgUsage;
