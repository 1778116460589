import React, { useEffect, useRef, useState } from 'react';
import Header from '../../components/header/header';
import './landing.scss';
import { featureList, landingReviews } from './landing-data';
import { BillingPlansTypes } from '../../shared/constants';
import { Link, useNavigate } from 'react-router-dom';
import { RouteNames } from '../../router/router';
import { getRandomElements } from '../../shared/helpers';
import BillingPlanSwitcher from '../../components/billing-plan-switcher/billing-plan-switcher';
import PlanCard from '../../components/plan-card/plan-card';
import { projectsAPI } from '../../services/projects-service';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { config } from '../../shared/config';
import VideoPreview from '../../assets/Zappr_AI_preview.png';
import { suuid } from '../../utils/suuid';
import Loading from '../../components/loading/loading';
import { userSlice } from '../../store/reducers/user-slice';

interface ITestimonial {
  name: string;
  img: string;
  text: string;
}

function importAll(r: any) {
  return r.keys().map(r);
}

const Landing = () => {
  const { isAuth, redirectToUserProjectsOnce } = useAppSelector(
    (state) => state.userReducer
  );
  const { plans } = useAppSelector((state) => state.plansReducer);
  const { setRedirectToUserProjectsOnce } = userSlice.actions;
  const [images, setImages] = useState<string[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [dashboardUrl, setDashboardUrl] = useState<string>(
    process.env.NODE_ENV === 'development'
      ? 'http://dashboard.localhost:3000'
      : `https://dashboard.${process.env.REACT_APP_DOMAIN}`
  );

  useEffect(() => {
    if (process.env.REACT_APP_LANDING_OFF === 'true') {
      window.location.href = dashboardUrl;
    }
  }, []);

  // FACEBOOK PIXEL
  useEffect(() => {
    if ((window as any).fbq != null) {
      (window as any).fbq('track', 'ViewContent', { content_name: 'home' });
    }
  }, []);

  // useEffect(() => {
  //   // if (!isAuth && !isDefaultDomain()) {
  //   //   navigate(RouteNames.LOGIN);
  //   // } else if (isAuth && !isDefaultDomain()) {
  //   //   navigate(RouteNames.USER_PROJECTS);
  //   // }
  //   const from = document.referrer;
  //   const fromDashboard =
  //     from.replace('dashboard.', '') === window.location.origin + '/';
  //
  //   if (!redirectToUserProjectsOnce || fromDashboard) return;
  //
  //   const reload =
  //     (
  //       window.performance.getEntriesByType(
  //         'navigation'
  //       )[0] as PerformanceNavigationTiming
  //     ).type === 'reload';
  //   if (isAuth && !reload) {
  //     dispatch(setRedirectToUserProjectsOnce(false));
  //     window.location.href = dashboardUrl;
  //     // navigate(RouteNames.USER_PROJECTS);
  //   }
  // }, [isAuth]);

  useEffect(() => {
    const imgs = importAll(
      require.context('../../assets/avatars', false, /\.(png|jpe?g|svg)$/)
    );
    setImages(imgs);
  }, []);

  const {
    data: project,
    isLoading,
    error,
  } = projectsAPI.useGetProjectStartInfoQuery(
    process.env.REACT_APP_LANDING_DEMO_PROJECT_ID as string
  );
  const [currentBillingPlansType, setCurrentBillingPlansType] =
    useState<BillingPlansTypes>(BillingPlansTypes.MONTHLY);
  const [testimonials, setTestimonials] = useState<ITestimonial[]>(
    getRandomElements(landingReviews, 8) as ITestimonial[]
  );
  // const [avatars, setAvatars] = useState<string[]>(getRandomElements(images, 8) as string[]);
  const [avatars, setAvatars] = useState<string[]>([]);
  const [widgetCustomId, setWidgetCustomId] = useState<string>(suuid());
  const widgetRef = useRef<HTMLIFrameElement>(null);
  const [widgetLoading, setWidgetLoading] = useState<boolean>(true);

  useEffect(() => {
    if (images) {
      setAvatars(getRandomElements(images, 8) as string[]);
    }
  }, [images]);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = `https://static.${process.env.REACT_APP_DOMAIN}/static/scripts/embed.min.js`;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // // Privacy Policy / Cookie Policy / Terms and Conditions script
  useEffect(() => {
    let script: HTMLScriptElement | null = null;

    if (process.env.REACT_APP_PARTNER_MODE === 'false') {
      script = document.createElement('script');
      script.type = 'text/javascript';

      script.src = 'https://cdn.iubenda.com/iubenda.js';
      document.body.appendChild(script);
    }

    // const loader = function () {
    //   script.src="https://cdn.iubenda.com/iubenda.js";
    //   document.body.appendChild(script);
    // };
    //
    // if (window.addEventListener) {
    //   window.addEventListener("load", loader, false);
    // } else {
    //   if ((window as any).attachEvent) {
    //     (window as any).attachEvent("onload", loader);
    //       } else {
    //         window.onload = loader;
    //       }
    // }

    return () => {
      // window.removeEventListener("load", loader);
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return (
    <div
      className={`landing ${
        process.env.REACT_APP_LANDING_OFF === 'true' ? 'landing-no-bg' : ''
      }`}
    >
      {process.env.REACT_APP_LANDING_OFF === 'true' ? (
        <Loading />
      ) : (
        <>
          <Header />

          <section className="intro">
            <div className="container landing-container">
              <div className="intro__inner">
                <div className="intro-content">
                  <div className="intro-title">
                    Create an AI-powered chatbot that's trained using{' '}
                    <span className="intro-title-span">your data</span>
                  </div>
                  <div className="intro-subtitle">
                    By simply uploading a PDF or providing a website link, you
                    can obtain a chatbot similar to ChatGPT that has the ability
                    to answer any questions related to your document.
                  </div>
                  <div className="intro-btn-container">
                    <div
                      className="intro-btn"
                      onClick={() =>
                        // isAuth
                        //   ? navigate(RouteNames.USER_PROJECTS)
                        //   : navigate(RouteNames.LOGIN)
                        isAuth
                          ? (window.location.href =
                              dashboardUrl + RouteNames.USER_PROJECTS)
                          : (window.location.href =
                              dashboardUrl + RouteNames.LOGIN)
                      }
                    >
                      Create Chatbot
                    </div>
                  </div>
                </div>
                {/*<div className='intro-img-container'>*/}
                {/*  <img src={introImg} alt='intro-img' className='intro-img'/>*/}
                {/*</div>*/}
              </div>
            </div>
          </section>

          {process.env.REACT_APP_PARTNER_MODE === 'true' ? null : (
            <section className="video-demonstration">
              <div className="video-bg-blur"></div>
              <div className="container landing-container">
                <div className="video-demonstration__inner">
                  <div className="title">Video demonstration</div>
                  <div className="subtitle">
                    Create an AI-powered chatbot that's trained using your data.
                  </div>
                  <div className="video-ctn">
                    <div className="iframe-container">
                      {/*<iframe*/}
                      {/*  width="100%"*/}
                      {/*  height="100%"*/}
                      {/*  src="https://drive.google.com/file/d/1pxZOI9eXwBivVBtw3muC2om2Air9HGvc/preview"*/}
                      {/*  className="iframe"*/}
                      {/*  allow="autoplay"*/}
                      {/*/>*/}
                      <video
                        autoPlay
                        muted
                        loop
                        controls
                        width="100%"
                        height="100%"
                        className="iframe"
                        poster={VideoPreview}
                      >
                        <source
                          src="https://static.zappr.ai/static/video/Zappr_AI.mp4"
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          <section className="features" id="features">
            <div className="features-bg-blur"></div>
            <div className="container landing-container">
              <div className="features__inner">
                <div className="title">Features</div>
                <div className="subtitle">
                  Create an AI-powered chatbot that's trained using your data.
                </div>
                <div className="feature-list">
                  {featureList.map((item, i) => (
                    <div className="grid-item-container" key={i}>
                      <div className="feature-list-item">
                        <div className="feature-list-item-img">{item.icon}</div>
                        <div className="feature-list-item-content">
                          <div className="feature-list-item-title">
                            {item.title}
                          </div>
                          <div className="feature-list-item-content">
                            {item.content}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>

          <section className="testimonials" id="testimonials">
            <div className="testimonials-bg-blur"></div>
            <div className="container landing-container">
              <div className="testimonials__inner">
                <div className="title">Testimonials</div>
                <div className="subtitle">
                  What people say about {process.env.REACT_APP_DISPLAY_NAME}.
                </div>
              </div>

              <div className="review-list">
                {testimonials.map((user, i) => (
                  <div className="grid-item-container" key={i}>
                    <div className="review-list-item">
                      <div className="review-list-item-text">“{user.text}”</div>
                      <div className="review-list-item-user">
                        <div className="review-list-item-user-img">
                          <img
                            src={avatars[i]}
                            alt="avatar"
                            className="user-img"
                          />
                        </div>
                        <div className="review-list-item-user-info">
                          <div className="review-list-item-user-name">
                            {user.name}
                          </div>
                          {/*<div className='review-list-item-additional-info'>{user.additionalInfo}</div>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

          <section className="pricing" id="pricing">
            <div
              className="pricing-bg-blur"
              style={{ top: !project ? '-1000px' : '-200px' }}
            ></div>
            <div className="container landing-container">
              <div className="pricing__inner">
                <div className="title">Plans</div>
                <div className="subtitle">
                  14 day money back guarantee on all paid plans
                </div>

                <div className="billing-plan-switcher-container landing-billing">
                  <BillingPlanSwitcher
                    currentBillingPlansType={currentBillingPlansType}
                    setCurrentBillingPlansType={setCurrentBillingPlansType}
                  />
                </div>

                <div className="pricing-list">
                  {plans.map((plan, i) => (
                    <div
                      className="grid-item-container pricing-list-item-container"
                      key={i}
                    >
                      <PlanCard
                        plan={plan}
                        key={i}
                        currentBillingPlansType={currentBillingPlansType}
                        index={i}
                        landing={true}
                      />
                    </div>
                  ))}
                </div>
                <div className="credits-info">
                  *On the {process.env.REACT_APP_DISPLAY_NAME} platform, we
                  provide you with a certain amount of credits. These credits
                  can be used to avail of different services such as sending
                  messages or uploading custom data in the form of A4 pages.
                  Please be mindful that your total credits are distributed
                  across these services. If you allocate all your credits to one
                  service, you may not have enough left for the other. Plan your
                  usage wisely!
                </div>
              </div>
            </div>
          </section>

          <section className="questions" id="demo">
            <div className="questions-bg-blur"></div>
            <div className="container landing-container">
              <div className="questions__inner">
                <div className="title">Got questions?</div>
                <div className="subtitle">Feel free to ask any questions.</div>

                {project && (
                  <>
                    <iframe
                      ref={widgetRef}
                      style={{
                        display: 'none',
                      }}
                      onLoad={(e) => setWidgetLoading(false)}
                      id={`default`}
                      src={`${config.widgetURL}/?id=${project.id}&embed=true`}
                    />
                    {widgetLoading ? <Loading height={'400px'} /> : null}
                  </>
                )}
              </div>
            </div>
          </section>

          <footer className="footer">
            <div className="container landing-container">
              <div className="footer__inner">
                <div
                  className={`footer-content ${
                    process.env.REACT_APP_PARTNER_MODE === 'true'
                      ? 'partner-footer'
                      : null
                  }`}
                >
                  <div className="grid-item-container footer-item">
                    <div className="footer-column">
                      <div className="footer-title">
                        {process.env.REACT_APP_DISPLAY_NAME}{' '}
                        {/*{process.env.REACT_APP_BETA_OFF === 'true' ? null : (*/}
                        {/*  <sup className="beta">BETA</sup>*/}
                        {/*)}*/}
                      </div>
                      <div className="footer-info">
                        <div className="footer-info-item">
                          Unlock the knowledge in your documents with our
                          AI-powered search bar and chatbot. Find answers
                          quickly and easily with our intuitive and intelligent
                          platform.
                        </div>
                      </div>

                      {process.env.REACT_APP_PARTNER_MODE === 'true' && (
                        <div className="footer-info-item footer-info-item--partner-email">
                          <a
                            href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}
                          >
                            {process.env.REACT_APP_CONTACT_EMAIL}
                          </a>
                        </div>
                      )}
                    </div>
                  </div>

                  {process.env.REACT_APP_PARTNER_MODE !== 'true' && (
                    <>
                      <div className="grid-item-container footer-item">
                        <div className="footer-column">
                          <div className="footer-title">Connect & Support</div>
                          <div className="footer-info">
                            <div className="footer-info-item">
                              <a
                                href="https://discord.gg/qMeDqJub"
                                target="_blank"
                              >
                                Join On Discord
                              </a>
                            </div>
                            <div className="footer-info-item">
                              <a
                                href="https://www.twitter.com/@ZapprAI"
                                target="_blank"
                              >
                                Twitter
                              </a>
                            </div>
                            {/*<div className='footer-info-item'>*/}
                            {/*  Facebook*/}
                            {/*</div>*/}
                            <div className="footer-info-item">
                              <a
                                href="https://www.linkedin.com/company/zappr-ai/"
                                target="_blank"
                              >
                                Linked In
                              </a>
                            </div>
                            <div className="footer-info-item">
                              <a href="mailto:hello@zappr.ai">hello@zappr.ai</a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="grid-item-container footer-item">
                        <div className="footer-column">
                          <div className="footer-title">Information</div>
                          <div className="footer-info">
                            {/*<div className="footer-info-item">FAQ</div>*/}
                            <div className="footer-info-item">
                              <Link
                                to={RouteNames.PRIVACY_POLICY}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Privacy Policy
                              </Link>
                            </div>
                            <div className="footer-info-item">
                              <Link
                                to={RouteNames.COOKIE_POLICY}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Cookie Policy
                              </Link>
                            </div>
                            <div className="footer-info-item">
                              <Link
                                to={RouteNames.TERMS_AND_CONDITIONS}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Terms and Conditions
                              </Link>
                            </div>
                            {/*<div className="footer-info-item">*/}
                            {/*  <a*/}
                            {/*    href="https://www.iubenda.com/privacy-policy/96106497"*/}
                            {/*    className="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe "*/}
                            {/*    title="Privacy Policy "*/}
                            {/*  >*/}
                            {/*    Privacy Policy*/}
                            {/*  </a>*/}
                            {/*</div>*/}
                            {/*<div>*/}
                            {/*  <a*/}
                            {/*    href="https://www.iubenda.com/privacy-policy/96106497/cookie-policy"*/}
                            {/*    className="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe "*/}
                            {/*    title="Cookie Policy "*/}
                            {/*  >*/}
                            {/*    Cookie Policy*/}
                            {/*  </a>*/}
                            {/*</div>*/}
                            {/*<div>*/}
                            {/*  <a*/}
                            {/*    href="https://www.iubenda.com/terms-and-conditions/96106497"*/}
                            {/*    className="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe "*/}
                            {/*    title="Terms and Conditions "*/}
                            {/*  >*/}
                            {/*    Terms and Conditions*/}
                            {/*  </a>*/}
                            {/*</div>*/}
                            <div className="footer-info-item footer-info-item--betalist">
                              <a
                                target="_blank"
                                href="https://betalist.com/startups/zappr-ai?utm_campaign=badge-zappr-ai&amp;utm_medium=badge&amp;utm_source=badge-featured"
                              >
                                <img
                                  alt="Zappr.AI - AI chatbot for smart document analysis &amp; intuitive search | BetaList"
                                  width="156"
                                  height="54"
                                  style={{ width: 156, height: 54 }}
                                  src="https://betalist.com/badges/featured?id=95290&amp;theme=dark"
                                />
                              </a>
                            </div>
                            {/*<div className="footer-info-item">*/}
                            {/*  <a*/}
                            {/*    href="https://www.iubenda.com/privacy-policy/96106497"*/}
                            {/*    className="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe "*/}
                            {/*    title="Privacy Policy "*/}
                            {/*  >*/}
                            {/*    Privacy Policy*/}
                            {/*  </a>*/}
                            {/*</div>*/}
                            {/*<div>*/}
                            {/*  <a*/}
                            {/*    href="https://www.iubenda.com/privacy-policy/96106497/cookie-policy"*/}
                            {/*    className="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe "*/}
                            {/*    title="Cookie Policy "*/}
                            {/*  >*/}
                            {/*    Cookie Policy*/}
                            {/*  </a>*/}
                            {/*</div>*/}
                            {/*<div>*/}
                            {/*  <a*/}
                            {/*    href="https://www.iubenda.com/terms-and-conditions/96106497"*/}
                            {/*    className="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe "*/}
                            {/*    title="Terms and Conditions "*/}
                            {/*  >*/}
                            {/*    Terms and Conditions*/}
                            {/*  </a>*/}
                            {/*</div>*/}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </footer>
        </>
      )}
    </div>
  );
};

export default Landing;
