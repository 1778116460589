import { apiService, APITagTypes } from './api-service';
import IPlan from '../types/IPlan';

export const plansAPI = apiService.injectEndpoints({
  endpoints: (build) => ({
    getPlans: build.query<IPlan[], void>({
      query: () => ({
        url: `/plans/get`,
        method: 'GET',
      }),
      providesTags: () => [APITagTypes.PLANS],
    }),
  }),
});
