import React, { CSSProperties, FC, useEffect, useState } from 'react';
import '../header/header.scss';
import './dashboard-header.scss';
import {
  CheckOutlined,
  CloseOutlined,
  LogoutOutlined,
  MenuOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Dropdown, MenuProps } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { RouteNames } from '../../router/router';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import {
  LogoMode,
  LS_CURRENT_ORGANIZATION_ID,
  LS_TOKEN_KEY,
  ProfileSettingsType,
  SubscriptionPlanTypes,
} from '../../shared/constants';
import { userSlice } from '../../store/reducers/user-slice';
import { projectsSlice } from '../../store/reducers/projects-slice';
import { organizationsSlice } from '../../store/reducers/organizations-slice';
import { subscriptionSlice } from '../../store/reducers/subscription-slice';
import { pluginsSlice } from '../../store/reducers/plugins-slice';
import { subscriptionAPI } from '../../services/subscription-service';
import UsagePanel from '../shared/usage-panel/usage-panel';
import LogoIcon from '../logo/logo-icon';
import LogoText from '../logo/logo-text';
import { projectsAPI } from '../../services/projects-service';
import { getDomain, getDomainForImage } from '../../shared/helpers';
import { RiVerifiedBadgeFill } from 'react-icons/ri';
import { IoCheckmarkCircle } from 'react-icons/io5';
import StorageUtils from '../../shared/utils/storage-utils';

const DashboardHeader: FC = () => {
  const { user, isAuth, isDomainTenantMember } = useAppSelector(
    (state) => state.userReducer
  );
  const { userPersonalSubscription } = useAppSelector(
    (state) => state.subscriptionReducer
  );
  const { currentOrganization, allOrganizationsInfo } = useAppSelector(
    (state) => state.organizationsReducer
  );
  const [lazyGetUserSubscription] =
    subscriptionAPI.useLazyGetUserSubscriptionQuery();
  const [lazyGetSubscriptionIsActive] =
    subscriptionAPI.useLazyGetSubscriptionIsActiveQuery();
  const { setIsAuth, setUser, setIsDomainTenantMember, setUserRole } =
    userSlice.actions;
  const { setUserProjects } = projectsSlice.actions;
  const { setCurrentOrganization, setInitialOrganizationsState } =
    organizationsSlice.actions;
  const { setInitialSubscriptionState } = subscriptionSlice.actions;
  const { setPlugins } = pluginsSlice.actions;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [burgerMenuIsOpen, setBurgerMenuIsOpen] = useState<boolean>(false);
  const [lazyGetUserProjects] = projectsAPI.useLazyGetUserProjectsQuery();

  const logout = () => {
    localStorage.removeItem(LS_TOKEN_KEY);
    localStorage.removeItem(LS_CURRENT_ORGANIZATION_ID);
    dispatch(setIsAuth(false));
    dispatch(setUser(null));
    dispatch(setUserRole(null));
    dispatch(setIsDomainTenantMember(false));
    dispatch(setUserProjects([]));
    dispatch(setPlugins([]));
    dispatch(setInitialOrganizationsState());
    dispatch(setInitialSubscriptionState());
    navigate(RouteNames.LANDING);
  };

  const changeOrganization = (organizationId: string) => {
    if (!organizationId || !allOrganizationsInfo) return;

    dispatch(setUserProjects([]));

    const currentOrgInfo = allOrganizationsInfo.find(
      (org) => org.id === organizationId
    );
    if (currentOrgInfo) {
      dispatch(setCurrentOrganization(currentOrgInfo));
      navigate(`${RouteNames.USER_PROJECTS}/${organizationId}`);
      lazyGetUserProjects({ orgId: organizationId });
    }
  };

  useEffect(() => {
    if (user) {
      lazyGetUserSubscription();
      lazyGetSubscriptionIsActive();
    }
  }, [currentOrganization, user]);

  useEffect(() => {
    const personal: MenuProps['items'] = [];
    const personalOrgs: MenuProps['items'] = [];
    const sharedOrgs: MenuProps['items'] = [];

    for (let i = 0; i < allOrganizationsInfo.length; i++) {
      const organizationInfo = allOrganizationsInfo[i];

      const item = {
        key: organizationInfo.id,
        label: (
          <div
            className={'header-menu-item-wrapper'}
            onClick={() => changeOrganization(organizationInfo.id)}
          >
            <div
              className={`organization-item-dropdown-header
                ${
                  organizationInfo.id === currentOrganization?.id
                    ? 'organization-active'
                    : 'organization-not-active'
                }`}
            >
              <div>
                {organizationInfo.id === currentOrganization?.id ? (
                  <CheckOutlined className="profile-dropdown-menu-icon" />
                ) : null}{' '}
                {organizationInfo.displayName}
              </div>
              <span className={'organization-owner-email'}>
                ({organizationInfo.owner?.email})
              </span>
            </div>
          </div>
        ),
      };

      if (
        organizationInfo.isPersonal &&
        organizationInfo.owner?.id === user?.id
      ) {
        personal.push(item);
      } else if (organizationInfo.owner?.id === user?.id) {
        personalOrgs.push(item);
      } else {
        sharedOrgs.push(item);
      }
    }

    const dividerTitle = (text: string) => (
      <div className="divider-title-orgs--label">{text}</div>
    );

    const dividerTitleElement = (key: string, title: string) => {
      return {
        key: key,
        label: dividerTitle(title),
        disabled: true,
        style: { cursor: 'default' },
        className: 'divider-title-orgs',
      };
    };

    const personalItems: MenuProps['items'] =
      personal.length > 0
        ? [
            { type: 'divider', style: { marginTop: 10 } },
            dividerTitleElement('personalOrgs', 'Personal'),
            ...personal,
          ]
        : [];

    const personalOrgsItems: MenuProps['items'] =
      personalOrgs.length > 0
        ? [
            {
              type: 'divider',
              style: { marginTop: personalItems.length ? 14 : 10 },
            },
            dividerTitleElement('userOrgs', 'My Organizations'),
            ...personalOrgs,
          ]
        : [];

    const sharedOrgsItems: MenuProps['items'] =
      sharedOrgs.length > 0
        ? [
            {
              type: 'divider',
              style: { marginTop: personalOrgsItems.length ? 14 : 10 },
            },
            dividerTitleElement('sharedOrgs', 'Shared With Me'),
            ...sharedOrgs,
          ]
        : [];

    setItems([
      settingItem,
      ...personalItems,
      ...personalOrgsItems,
      ...sharedOrgsItems,
      { type: 'divider' },
      logoutItem,
    ]);
  }, [user, allOrganizationsInfo, currentOrganization]);

  const settingItem = {
    key: 'settingItem',
    label: (
      <div
        className={'header-menu-item-wrapper'}
        onClick={() => {
          navigate(
            `${RouteNames.PROFILE_SETTINGS_NO_PARAMS}/${ProfileSettingsType.ACCOUNT}`
          );
        }}
      >
        <SettingOutlined className="profile-dropdown-menu-icon" /> Settings
      </div>
    ),
  };

  const logoutItem = {
    key: '2',
    label: (
      <div className={'header-menu-item-wrapper'} onClick={logout}>
        <span>
          <LogoutOutlined className="profile-dropdown-menu-icon" /> Logout
        </span>
      </div>
    ),
  };

  const [items, setItems] = useState<MenuProps['items']>([
    settingItem,
    { type: 'divider' },
    logoutItem,
  ]);

  const purchaseCreditsBtn = () => (
    <Link
      to={`${RouteNames.PROFILE_SETTINGS_NO_PARAMS}/${ProfileSettingsType.BUY_CREDITS}`}
    >
      <div className="dashboard-header-menu-item">
        <Button
          type="primary"
          className="header-upgrade-btn"
          onClick={() => setBurgerMenuIsOpen(false)}
        >
          Purchase Credits
        </Button>
      </div>
    </Link>
  );

  const burgerMenu = () => (
    <>
      <div className="dashboard-header-burger-menu-item">
        <MenuOutlined onClick={() => setBurgerMenuIsOpen(true)} />
      </div>
      <div
        className={`burger-menu-overlay ${burgerMenuIsOpen ? 'visible' : ''}`}
        onClick={() => setBurgerMenuIsOpen(false)}
      ></div>
      <div className={`burger-menu ${burgerMenuIsOpen ? 'visible' : ''}`}>
        <ul className="burger-menu__list">
          <CloseOutlined
            className="burger-menu__icon-close"
            onClick={() => setBurgerMenuIsOpen(false)}
          />
          <div className={`header-menu-item burger-menu__item burger-item`}>
            <Link
              to={RouteNames.USER_PROJECTS}
              className="header-menu-link burger-menu__link"
              onClick={() => setBurgerMenuIsOpen(false)}
            >
              Dashboard
            </Link>
          </div>
          {user && !isDomainTenantMember && (
            <>
              <Link
                to={`${RouteNames.PROFILE_SETTINGS_NO_PARAMS}/${ProfileSettingsType.USAGE}`}
                onClick={() => setBurgerMenuIsOpen(false)}
              >
                <UsagePanel />
              </Link>
              <div className={`burger-menu__purchase-btn`}>
                {purchaseCreditsBtn()}
              </div>
            </>
          )}
        </ul>
      </div>
    </>
  );

  const getOrgDisplayName = () => {
    if (!currentOrganization) return '';
    if (
      currentOrganization.owner?.id !== user?.id &&
      currentOrganization.isPersonal
    ) {
      return `${currentOrganization.displayName} (${currentOrganization.owner?.email})`;
    }

    return currentOrganization.displayName;
  };

  const getBadge = () => {
    if (!user || !userPersonalSubscription) return null;

    const badgeStyle: CSSProperties = {
      position: 'absolute',
      color: '#1DA1F2',
      right: 3,
      bottom: 3,
      fontSize: '24px',
      transform: 'translate(50%, 50%)',
    };

    if (userPersonalSubscription.badge?.toLocaleLowerCase() === 'blueflower') {
      return <RiVerifiedBadgeFill style={badgeStyle} />;
    }

    if (
      userPersonalSubscription?.currentPlan.name.toLowerCase() ===
      SubscriptionPlanTypes.ENTERPRISE
    ) {
      return <IoCheckmarkCircle style={badgeStyle} />;
    }

    return null;
  };

  return (
    <div className={WrapperClassName}>
      <div className="container">
        <div className="dashboard-header__inner">
          <div className="dashboard-header__left">
            {/*{currentOrganizationLoading ? null : (*/}
            {
              <>
                <a href={'https://' + getDomain()}>
                  <div className="logo dashboard-header-logo">
                    <LogoIcon mode={LogoMode.LIGHT} />
                    <LogoText mode={LogoMode.LIGHT} />
                  </div>
                </a>
                {/*<Link to={RouteNames.LANDING}>*/}
                {/*  <div className="logo dashboard-header-logo">*/}
                {/*    <LogoIcon className="logo-icon" />*/}
                {/*    <div className="logo-text">*/}
                {/*      Zappr.AI <sup className="beta">BETA</sup>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</Link>*/}
                {/*TODO: refactor this pls*/}
                {isAuth && (
                  <div className="dashboard-header-menu-list">
                    {burgerMenu()}

                    <Link to={RouteNames.USER_PROJECTS}>
                      <div className="dashboard-header-menu-item dashboard-header-menu-item--text-fix dashboard-header-menu-item--dashboard">
                        Dashboard
                      </div>
                    </Link>
                    {user && !isDomainTenantMember && (
                      <>
                        <Link
                          to={`${RouteNames.PROFILE_SETTINGS_NO_PARAMS}/${ProfileSettingsType.USAGE}`}
                        >
                          <div className={'dashboard-header-menu-item'}>
                            <UsagePanel />
                          </div>
                        </Link>
                        {purchaseCreditsBtn()}
                      </>
                    )}
                  </div>
                )}
              </>
            }
          </div>

          {isAuth ? (
            <div className="user-panel-container">
              <div className="user-panel">
                <div className={'user-name-org-container'}>
                  <div className="user-name">{user?.email}</div>
                  {user && allOrganizationsInfo.length > 0 ? (
                    <div className="user-organization">
                      {getOrgDisplayName()}
                    </div>
                  ) : null}
                </div>
                <Dropdown
                  menu={{ items }}
                  placement="bottomRight"
                  trigger={['click']}
                  overlayClassName={'user-menu-dropdown'}
                >
                  <div
                    style={{ position: 'relative', display: 'inline-block' }}
                  >
                    <Avatar
                      size={32}
                      className="user-icon"
                      icon={<UserOutlined />}
                      src={
                        user && user.hasAvatar ? (
                          StorageUtils.getAvatar(user.id)
                        ) : (
                          <UserOutlined />
                        )
                      }
                    />
                    {getBadge()}
                  </div>
                </Dropdown>
              </div>

              {/*<Button*/}
              {/*  type="link"*/}
              {/*  icon={<LogoutOutlined />}*/}
              {/*  shape="circle"*/}
              {/*  className="logout-btn"*/}
              {/*  onClick={logout}*/}
              {/*/>*/}
            </div>
          ) : (
            <Button
              type={'primary'}
              onClick={() => {
                navigate(RouteNames.LOGIN);
              }}
            >
              Log in
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export const WrapperClassName = 'dashboard-header';

export default DashboardHeader;
