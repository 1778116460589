import React, { FC, useEffect, useState } from 'react';
import { Form, FormInstance, InputNumber } from 'antd';
import { IWidgetSpeechBubbleItem } from '../../../../../../types/IWidgetSettings';

interface IBubbleTimeProps {
  name: string;
  label: string;
  tooltip: string;
  placeholder: string;
}

interface IBubbleTimeFormItemProps {
  restField: { fieldKey?: number | undefined };
  name: number;
  index: number;
  props: IBubbleTimeProps;
  bubbleSettingsForm: FormInstance;
  calculateMinDelaysTrigger: number;
  setCalculateMinDelaysTrigger: React.Dispatch<React.SetStateAction<number>>;
}

const BubbleTimeFormItem: FC<IBubbleTimeFormItemProps> = ({
  restField,
  name,
  index,
  props,
  bubbleSettingsForm,
  calculateMinDelaysTrigger,
  setCalculateMinDelaysTrigger,
}) => {
  const [minDelayValue, setMinDelayValue] = useState<number>(0);

  useEffect(() => {
    if (index !== 0 && props.name === 'startDelay') {
      const currentValues = bubbleSettingsForm.getFieldsValue().items;
      const previousDelay = currentValues[index - 1].startDelay;
      const previousDuration = currentValues[index - 1].duration;
      const newMinDelay = +previousDelay + +previousDuration + 1;
      setMinDelayValue(newMinDelay);
      const newSpeechBubbleValues = currentValues.map(
        (speechBubble: IWidgetSpeechBubbleItem, i: number) => {
          if (i !== index) {
            return speechBubble;
          }

          const speechBubbleStartVals = {
            text: '',
            duration: 3,
            startDelay: 0,
          };

          if (speechBubble !== undefined) {
            speechBubbleStartVals.text = speechBubble.text || '';
            speechBubbleStartVals.duration = +speechBubble.duration || 0;
            speechBubbleStartVals.startDelay = +speechBubble.startDelay || 0;
          }

          return {
            ...speechBubbleStartVals,
            startDelay:
              +speechBubbleStartVals.startDelay < newMinDelay
                ? newMinDelay
                : +speechBubbleStartVals.startDelay,
          };
        }
      );
      bubbleSettingsForm.setFieldsValue({
        items: newSpeechBubbleValues,
      });
    }
  }, [index, calculateMinDelaysTrigger, props.name]);

  const handleChange = () => {
    setCalculateMinDelaysTrigger(Date.now());
  };

  const handleFormatter = (value: string | number | undefined) => {
    if (value === '') {
      return value;
    }
    if (value === null || value === undefined) {
      return minDelayValue.toString();
    }
    const floatValue = parseFloat(value.toString());
    if (!isNaN(floatValue)) {
      return Math.ceil(floatValue).toString();
    }
    return minDelayValue.toString();
  };

  const handleParser = (value: string | undefined) => {
    if (!value) {
      return minDelayValue;
    }
    const parsedValue = parseFloat(value.replace(/[^\d.]/g, ''));
    if (!isNaN(parsedValue)) {
      return Math.max(parsedValue, minDelayValue);
    }
    return minDelayValue;
  };

  return (
    <Form.Item
      {...restField}
      name={[name, props.name]}
      style={{ width: '90px', marginBottom: 10 }}
      label={index === 0 ? props.label : undefined}
      tooltip={props.tooltip}
      key={index + props.name}
    >
      <InputNumber
        min={minDelayValue}
        placeholder={
          props.name === 'startDelay' && index !== 0
            ? minDelayValue.toString()
            : props.placeholder
        }
        step={1}
        precision={0}
        onStep={() => handleChange()}
        formatter={handleFormatter}
        parser={handleParser}
        onChange={() => handleChange()}
      />
    </Form.Item>
  );
};

export default BubbleTimeFormItem;
