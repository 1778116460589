import { FC, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import IAcceptInvitation from '../../types/IAcceptInvitation';
import { useAppSelector } from '../../hooks/redux-hooks';
import { RouteNames } from '../../router/router';
import { Button, Form, Input, Spin, message } from 'antd';
import {
  LockOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { emailRules, passwordRules } from '../../shared/rules';
import { usersAPI } from '../../services/users-service';
import { getDomain, showErrorMessage } from '../../shared/helpers';
import { GA_LOGIN, GA_REGISTER_USER } from '../../shared/google-analytics';
import IAcceptRegistration from '../../types/IAcceptRegistration';
import LogoIcon from '../../components/logo/logo-icon';
import { LogoMode } from '../../shared/constants';
import LogoText from '../../components/logo/logo-text';

const NewUserInvitation: FC = () => {
  const [form] = Form.useForm();
  const { user } = useAppSelector((state) => state.userReducer);
  const [searchParams, setSearchParams] = useSearchParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [registerDisabled, setRegisterDisabled] = useState(true);
  const [formIsTouched, setFormIsTouched] = useState(false);
  const [acceptRegistration, { isLoading }] =
    usersAPI.useAcceptRegistrationMutation();
  const [login, {}] = usersAPI.useAuthMutation();
  const [getUserInfo, {}] = usersAPI.useLazyGetUserInfoQuery();
  const [userData, setUserData] = useState<IAcceptInvitation | null>();
  const navigate = useNavigate();

  useEffect(() => {
    const f = async () => {
      const email = searchParams.get('email');
      const token = searchParams.get('token');
      const orgId = searchParams.get('org-id');

      let currentUser = user;
      const userInfo = await getUserInfo(token || undefined);

      if ('data' in userInfo && userInfo.data) {
        currentUser = userInfo.data;
      }

      if (
        orgId &&
        currentUser?.email === email &&
        currentUser.emailConfirmed === true
      ) {
        navigate(
          `${RouteNames.ACCEPT_INVITATION}/?org-id=${orgId}&email=${email}&token=${token}`
        );
        return;
      }

      if (email && token) {
        const emailReplace = email.replaceAll(' ', '+');
        const tokenReplace = token.replaceAll(' ', '+');
        const orgIdReplace = orgId ? orgId.replaceAll(' ', '+') : '';
        setUserData({
          email: emailReplace,
          token: tokenReplace,
          tenantId: orgIdReplace,
        });
      } else {
        setUserData(null);
      }
    };

    f();
  }, [searchParams]);

  const renderHeader = () => {
    return (
      <>
        <div
          className="login__logo"
          onClick={() => (window.location.href = 'https://' + getDomain())}
        >
          <LogoIcon mode={LogoMode.DARK} />
          <LogoText mode={LogoMode.DARK} />
        </div>
        <div className={'auth-subtitle'}>Create your account</div>
      </>
    );
  };

  const handleRegister = async (formData: IAcceptRegistration) => {
    if (!userData) {
      return;
    }

    setRegisterDisabled(true);

    formData.token = userData.token;
    formData.tenantId = userData.tenantId == '' ? null : userData.tenantId;

    const result = await acceptRegistration(formData);

    if ('error' in result) {
      showErrorMessage(messageApi, result.error);
    } else {
      GA_REGISTER_USER();

      const result = await login(formData);

      if ('error' in result) {
        showErrorMessage(messageApi, result.error);
      } else {
        GA_LOGIN();
        navigate(`${RouteNames.USER_PROJECTS}/${userData?.tenantId}`);
      }
    }

    setRegisterDisabled(false);
  };

  const handleChange = async () => {
    try {
      await form.validateFields();
      setRegisterDisabled(false);
    } catch (errors) {
      setRegisterDisabled(true);
    }
  };

  const renderForm = () => {
    if (!userData) {
      return <Spin />;
    }

    const email = userData.email;
    return (
      <div>
        <Form
          form={form}
          layout={'vertical'}
          className="login-form"
          initialValues={{ email: `${email}` }}
          onFinish={handleRegister}
          onChange={handleChange}
        >
          <Form.Item label="First name" name="firstName">
            <Input
              prefix={<UserSwitchOutlined className="site-form-item-icon" />}
              placeholder="Enter first name"
            />
          </Form.Item>
          <Form.Item label="Last name" name="lastName">
            <Input
              prefix={<UserSwitchOutlined className="site-form-item-icon" />}
              placeholder="Enter last name"
            />
          </Form.Item>
          <Form.Item label="E-mail" name="email" rules={emailRules}>
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              disabled
            />
          </Form.Item>
          <Form.Item label={'Password'} name="password" rules={passwordRules}>
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type={formIsTouched ? 'password' : 'text'}
              placeholder="Password"
              onChange={() => setFormIsTouched(true)}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button login-btn register-btn"
              loading={isLoading}
              disabled={registerDisabled}
            >
              Register
            </Button>
          </Form.Item>

          <div style={{ marginTop: 10 }}>
            Or{' '}
            <Link to={RouteNames.LOGIN} className="login-link">
              Log in now!
            </Link>
          </div>
        </Form>
      </div>
    );
  };

  return (
    <>
      {contextHolder}
      <div className="register">
        <div className="container">
          <div className="login__inner">
            {renderHeader()}
            {renderForm()}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewUserInvitation;
