import { ContactRecordType } from '../IContact';

export default interface IAddressRecord {
  type: ContactRecordType.ADDRESS;
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
  label: AddressLabel;
  customLabel?: string;
}

export enum AddressLabel {
  HOME = 'home',
  WORK = 'work',
  OTHER = 'other',
}
