import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IPlugin from '../../types/IPlugin';
import { pluginsAPI } from '../../services/plugins-service';

interface IPluginState {
  plugins: IPlugin[];
}

const initialState: IPluginState = {
  plugins: [],
};

export const pluginsSlice = createSlice({
  name: 'plugins',
  initialState,
  reducers: {
    setPlugins(state, action: PayloadAction<IPlugin[]>) {
      state.plugins = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      pluginsAPI.endpoints.getPlugins.matchFulfilled,
      (state, action: PayloadAction<IPlugin[]>) => {
        state.plugins = action.payload;
        // const p = action.payload.find((p) => p.id === 'Nq-E1TbEFEmtNf9sbZ5Huw');
        // if (!p) return;
        // p.isDemoPlugin = false;
        // const allExceptP = action.payload.filter((p) => p.id !== 'Nq-E1TbEFEmtNf9sbZ5Huw');
        // state.plugins = [p, ...allExceptP];
      }
    );
  },
});

export default pluginsSlice.reducer;
