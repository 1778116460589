import React, { FC, useState } from 'react';
import { Button, Form, Input, Popover, Select } from 'antd';
import { IoFilter } from 'react-icons/io5';
import {
  campaignFilterOperatorsList,
  FilterOperators,
  filterValueIsRequired,
  getFilterOperatorOptions,
} from './filters-helpers';
import ICampaignsFilter from '../../../../types/filters/ICampaignsFilter';
import { WhatsAppOutboundStatus } from '../../../../shared/constants';
import './campaign-filter.scss';

interface ICampaignFilterProps {
  setCurrentFilters: React.Dispatch<React.SetStateAction<ICampaignsFilter[]>>;
}

const CampaignFilter: FC<ICampaignFilterProps> = ({ setCurrentFilters }) => {
  const [open, setOpen] = useState(false);
  const [applyIsDisabled, setApplyIsDisabled] = useState(true);
  const [form] = Form.useForm();
  const nameOperator = Form.useWatch('nameOperator', form);
  const statusOperator = Form.useWatch('statusOperator', form);

  const hide = () => {
    setOpen(false);
  };

  const resetFilter = () => {
    form.setFieldsValue({
      nameOperator: FilterOperators.IS,
      nameValue: undefined,
      statusOperator: FilterOperators.IS,
      statusValue: undefined,
    });
    handleChange();
    setCurrentFilters([]);
    hide();
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const applyFilter = () => {
    const values = form.getFieldsValue();
    setCurrentFilters([
      {
        filterName: 'name',
        operatorName: 'name-operator',
        operator: values.nameOperator,
        value: values.nameValue,
      },
      {
        filterName: 'status',
        operatorName: 'status-operator',
        operator: values.statusOperator,
        value: values.statusValue,
      },
    ]);

    hide();
  };

  const handleChange = async () => {
    const values = await form.getFieldsValue();
    const { nameValue, statusValue } = values;

    const needNameValue = filterValueIsRequired(nameOperator);
    const needStatusValue = filterValueIsRequired(statusOperator);

    if (
      (nameOperator && nameValue && statusOperator && statusValue) ||
      (!needNameValue && !needStatusValue)
    ) {
      setApplyIsDisabled(false);
    } else {
      setApplyIsDisabled(true);
    }
  };

  const getValueFormItemContent = (columnName: string) => {
    if (columnName === 'status') {
      return (
        <Select
          options={Object.values(WhatsAppOutboundStatus).map((status) => ({
            value: status,
            label: status,
          }))}
          dropdownStyle={{ zIndex: 2000 }}
          style={{
            width: 160,
          }}
          onChange={handleChange}
          getPopupContainer={(trigger) =>
            trigger.parentElement || document.body
          }
        />
      );
    }

    return (
      <Input
        autoComplete={'off'}
        placeholder={filterValueIsRequired(nameOperator) ? 'Enter a value' : ''}
        disabled={!filterValueIsRequired(nameOperator)}
      />
    );
  };

  const filterForm = () => (
    <div className={'table-filter-form-container '}>
      <Form
        form={form}
        onChange={handleChange}
        layout={'inline'}
        requiredMark={false}
        initialValues={{
          nameOperator: FilterOperators.IS,
          statusOperator: FilterOperators.IS,
        }}
        className={'campaign-filter-form'}
      >
        <div className={'campaign-filter-form-row'}>
          <div className={'campaign-filter-form-row-info'}>
            Where <span style={{ fontWeight: 500 }}>Campaign</span>
          </div>
          <div className={'campaign-filter-form-row-items'}>
            <Form.Item
              name="nameOperator"
              className={'campaign-filter-form-item'}
            >
              <Select
                options={getFilterOperatorOptions(campaignFilterOperatorsList)}
                dropdownStyle={{ zIndex: 2000 }}
                style={{
                  width: 160,
                }}
                onChange={handleChange}
                getPopupContainer={(trigger) =>
                  trigger.parentElement || document.body
                }
              />
            </Form.Item>
            <Form.Item
              name="nameValue"
              style={{ marginInlineEnd: 0 }}
              className={'campaign-filter-form-item'}
            >
              {getValueFormItemContent('name')}
            </Form.Item>
          </div>
        </div>

        <div className={'campaign-filter-form-row'}>
          <div className={'campaign-filter-form-row-info'}>
            AND <span style={{ fontWeight: 500 }}>Status</span>
          </div>
          <div className={'campaign-filter-form-row-items'}>
            <Form.Item
              name="statusOperator"
              className={'campaign-filter-form-item'}
            >
              <Select
                options={getFilterOperatorOptions(campaignFilterOperatorsList)}
                dropdownStyle={{ zIndex: 2000 }}
                style={{
                  width: 160,
                }}
                onChange={handleChange}
                getPopupContainer={(trigger) =>
                  trigger.parentElement || document.body
                }
              />
            </Form.Item>
            <Form.Item
              name="statusValue"
              style={{ marginInlineEnd: 0 }}
              className={'campaign-filter-form-item'}
            >
              {getValueFormItemContent('status')}
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );

  const filterActions = () => (
    <div className={'table-filter-actions-container'}>
      <Button onClick={resetFilter}>Reset</Button>
      <Button onClick={hide}>Cancel</Button>
      <Button type={'primary'} disabled={applyIsDisabled} onClick={applyFilter}>
        Apply
      </Button>
    </div>
  );

  const filterContent = () => (
    <div className={'table-filter-content'}>
      In this view, show records
      {filterForm()}
      {filterActions()}
    </div>
  );
  const renderFilter = () => {
    return (
      <Popover
        content={filterContent()}
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
        placement={'bottomRight'}
      >
        <Button className={'whats-app-outbound-button'}>
          <IoFilter /> Filter
        </Button>
      </Popover>
    );
  };

  return renderFilter();
};

export default CampaignFilter;
