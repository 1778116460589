import React, { FC, useEffect, useState } from 'react';
import { Button, Form, FormInstance, Input, InputNumber } from 'antd';
import { ITraceCall } from '../../../types/IMessageTraceInfo';
import {
  IHandleChangeTraceCallFormValues,
  IHandleSubmitTraceCallForm,
} from './trace-modal';
import JsonView from '@uiw/react-json-view';
import ReactJson from '@microlink/react-json-view';

interface ITraceCallFormProps {
  call: ITraceCall;
  defaultValues: any;
  // handleChangeFormValues: IHandleChangeTraceCallFormValues;
  handleSubmit: IHandleSubmitTraceCallForm;
}

const TraceCallForm: FC<ITraceCallFormProps> = ({
  call,
  defaultValues,
  // handleChangeFormValues,
  handleSubmit,
}) => {
  const [form] = Form.useForm();
  const [functionsInfo, setFunctionsInfo] = useState<any[]>(
    call.request.functions || []
  );
  const [traceResult, setTraceResult] = useState<any | null>(null);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  // useEffect(() => {
  //   handleChangeFormValues(form, functionsInfo, setSubmitDisabled);
  // }, [functionsInfo]);

  const getLabel = (labelText: string) => {
    return <div className={'trace-modal-label'}>{labelText}</div>;
  };

  useEffect(() => {
    form.setFieldsValue(defaultValues);
    setFunctionsInfo(call.request.functions || []);
    // handleChangeFormValues(form, functionsInfo, setSubmitDisabled);
  }, [defaultValues]);

  return (
    <Form
      initialValues={defaultValues}
      form={form}
      // onChange={() =>
      //   handleChangeFormValues(form, functionsInfo, setSubmitDisabled)
      // }
    >
      <Form.Item name={`model`} label={getLabel('model')}>
        <Input />
      </Form.Item>
      <Form.Item name={`temperature`} label={getLabel('Temperature')}>
        <InputNumber
          min={0}
          max={1}
          step={0.1}
          // onStep={() => {
          //   handleChangeFormValues(form, functionsInfo, setSubmitDisabled);
          // }}
        />
      </Form.Item>
      {call.request.messages.map((message, i) => {
        return (
          <Form.Item
            name={`message-${i}-${message.role}`}
            key={i}
            label={getLabel(message.role)}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        );
      })}
      <div
        className={'trace-modal-label'}
        style={{ width: '100%', marginBottom: 10 }}
      >
        Functions
      </div>
      <ReactJson
        style={{ fontSize: 13 }}
        src={call.request.functions}
        onEdit={(edit) => {
          setFunctionsInfo(edit.updated_src as any[]);
        }}
        collapsed={true}
      />
      {traceResult && (
        <div>
          <div
            className={'trace-modal-label'}
            style={{ width: '100%', marginTop: 20, marginBottom: 10 }}
          >
            Result
          </div>
          <JsonView value={traceResult} displayDataTypes={true} />
        </div>
      )}
      <div style={{ textAlign: 'right' }}>
        <Button
          type="primary"
          onClick={() =>
            handleSubmit(form, functionsInfo, setSubmitDisabled, setTraceResult)
          }
          disabled={submitDisabled}
        >
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default TraceCallForm;
