import { FC } from 'react';
import IDefaultPluginFieldProps from './IDefaultPluginField';
import { Button, Form, Input, message } from 'antd';
import { PluginNames, V_REQUIRED_FIELD } from '../../../../shared/constants';
import { CopyOutlined } from '@ant-design/icons';
import { projectPluginsAPI } from '../../../../services/project-plugins-service';
import { showErrorMessage } from '../../../../shared/helpers';

interface IDefaultFormItem extends IDefaultPluginFieldProps {
  isReadonly: boolean;
  propertyValue: string;
  pluginName: string;
  projectId: string;
}

const DefaultFormItem: FC<IDefaultFormItem> = ({
  displayName,
  keyBase,
  index,
  isRequired,
  description,
  convertValue,
  placeholder,
  isReadonly,
  propertyValue,
  pluginName,
  projectId,
}) => {
  const [setTwilioWebhook, { isLoading: setTwilioWebhookLoading }] =
    projectPluginsAPI.useSetTwilioWebhookMutation();
  const [messageApi, contextHolder] = message.useMessage();

  if (isReadonly) {
    const isDisabled = !propertyValue;

    const addSpecialFeature = () => {
      switch (pluginName) {
        case PluginNames.TWILIO:
          if (keyBase.toLowerCase() === 'webhook') {
            return (
              <Button
                onClick={async () => {
                  const result = await setTwilioWebhook({
                    projectId: projectId,
                  });

                  if ('error' in result) {
                    await showErrorMessage(messageApi, result.error, 6);
                  } else {
                    messageApi.success(`Webhook was set up.`);
                  }
                }}
                className="api-key-btn"
                disabled={isDisabled || setTwilioWebhookLoading}
              >
                Set Webhook
              </Button>
            );
          }

          return <></>;
        default:
          return <></>;
      }
    };

    return (
      <>
        {contextHolder}
        <Form.Item
          label={displayName}
          name={keyBase}
          key={index}
          rules={[
            {
              required: isRequired,
              message: V_REQUIRED_FIELD,
            },
          ]}
          tooltip={description}
          initialValue={convertValue}
        >
          <div className="plugin-setting-read-only">
            <Input
              placeholder={placeholder}
              value={convertValue}
              disabled={true}
            />
            <Button
              onClick={() => navigator.clipboard.writeText(propertyValue)}
              className="api-key-btn"
              disabled={isDisabled}
            >
              <CopyOutlined />
            </Button>
            {addSpecialFeature()}
          </div>
        </Form.Item>
      </>
    );
  }

  return (
    <>
      {contextHolder}
      <Form.Item
        label={displayName}
        name={keyBase}
        key={index}
        rules={[
          {
            required: isRequired,
            message: V_REQUIRED_FIELD,
          },
        ]}
        tooltip={description}
        initialValue={convertValue}
      >
        <Input placeholder={placeholder ?? displayName} value={convertValue} />
      </Form.Item>
    </>
  );
};

export default DefaultFormItem;
