import { FC } from 'react';
import IDefaultPluginFieldProps from './IDefaultPluginField';
import { Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { V_REQUIRED_FIELD } from '../../../../shared/constants';

const MultilineFormItem: FC<IDefaultPluginFieldProps> = ({
  displayName,
  keyBase,
  index,
  isRequired,
  description,
  convertValue,
  placeholder,
}) => {
  return (
    <Form.Item
      label={displayName}
      name={keyBase}
      key={index}
      rules={[
        {
          required: isRequired,
          message: V_REQUIRED_FIELD,
        },
      ]}
      tooltip={description}
      initialValue={convertValue}
    >
      <TextArea
        placeholder={placeholder ?? displayName}
        value={convertValue}
        rows={15}
      />
    </Form.Item>
  );
};

export default MultilineFormItem;
