import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Modal, Select } from 'antd';
import { IFbPage } from '../../project-settings/plugins/fb-plugin/fb-plugin-form';

interface IFbPluginConfirmPageModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  pages: IFbPage[];
  setSelectedPage: Dispatch<React.SetStateAction<string>>;
  setSelectedPageName: Dispatch<React.SetStateAction<string>>;
}

const FbPluginConfirmPageModal: FC<IFbPluginConfirmPageModal> = ({
  isOpen,
  setIsOpen,
  pages,
  setSelectedPage,
  setSelectedPageName,
}) => {
  const [selectValue, setSelectValue] = useState<string>('');

  const handleOk = () => {
    if (!selectValue) return;
    const page = pages.find((page) => page.id === selectValue);
    if (page) {
      setSelectedPage(page.id);
      setSelectedPageName(page.name);
    }
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      title="Select page"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered={true}
      okButtonProps={{ disabled: !selectValue }}
      wrapClassName={'modal'}
      maskStyle={{ zIndex: 1100 }}
      closable={false}
    >
      Select the required page. You won't be able to change it anymore.
      <Select
        style={{ width: '100%', marginBottom: '20px', marginTop: '20px' }}
        placeholder="Select page"
        popupClassName={'popup-select'}
        className={'popup-select-item'}
        onChange={(value: string) => setSelectValue(value)}
        options={pages.map((page) => ({ label: page.name, value: page.id }))}
        value={selectValue || undefined}
      />
    </Modal>
  );
};

export default FbPluginConfirmPageModal;
