import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import {
  OrganizationSettingsType,
  ProfileSettingsType,
} from '../../../../shared/constants';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PieChartOutlined,
  TeamOutlined,
  SettingOutlined,
  ApiOutlined,
  ReconciliationOutlined,
} from '@ant-design/icons';
import { RouteNames } from '../../../../router/router';
import { subscriptionAPI } from '../../../../services/subscription-service';

const defaultOptions = [
  {
    label: 'Members',
    value: OrganizationSettingsType.MEMBERS,
    key: OrganizationSettingsType.MEMBERS,
    icon: TeamOutlined,
  },
  {
    label: 'Usage',
    value: OrganizationSettingsType.USAGE,
    key: OrganizationSettingsType.USAGE,
    icon: PieChartOutlined,
  },
  {
    label: 'Settings',
    value: OrganizationSettingsType.SETTINGS,
    key: OrganizationSettingsType.SETTINGS,
    icon: SettingOutlined,
  },
  {
    label: 'Integrations',
    value: OrganizationSettingsType.INTEGRATIONS,
    key: OrganizationSettingsType.INTEGRATIONS,
    icon: ApiOutlined,
  },
  {
    label: 'Branding',
    value: OrganizationSettingsType.BRANDING,
    key: OrganizationSettingsType.BRANDING,
    icon: ReconciliationOutlined,
  },
];

const OrgTabs = () => {
  const { orgId, subtab } = useParams();
  const navigate = useNavigate();
  const { data: userSubscription } =
    subscriptionAPI.useGetUserSubscriptionQuery();

  useEffect(() => {
    setActiveTab(subtab ?? OrganizationSettingsType.MEMBERS);
  }, [subtab]);

  const [activeTab, setActiveTab] = useState(
    subtab ?? OrganizationSettingsType.MEMBERS
  );

  const handleTabClick = (activeKey: string) => {
    navigate(
      `${RouteNames.PROFILE_SETTINGS_NO_PARAMS}/${ProfileSettingsType.ORGANIZATION}/${orgId}/${activeKey}`
    );
  };

  const brandingDisabled = () => {
    if (!userSubscription || !userSubscription.currentPlan) return true;
    return !userSubscription.currentPlan.features.remove_branding;
  };

  return (
    <Tabs
      activeKey={activeTab}
      items={defaultOptions.map((option, i) => {
        return {
          label: (
            <span>
              <option.icon className="project-settings__tabs-icon" />{' '}
              <span className="project-settings__tabs-label-text">
                {option.label}
              </span>
            </span>
          ),
          key: option.key,
          value: option.value,
          disabled:
            option.value === OrganizationSettingsType.BRANDING &&
            brandingDisabled(),
        };
      })}
      className="project-settings__tabs org-tabs"
      onTabClick={handleTabClick}
      style={{ marginBottom: '10px' }}
    />
  );
};

export default OrgTabs;
