import React from 'react';
import './float-button.scss';

const DefaultFloatButtonIcon = () => {
  return (
    <svg
      className={'float-button__default-icon'}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.198738 14.9006C0.198738 23.13 6.86998 29.8013 15.0994 29.8013C17.7582 29.8013 20.2544 29.1049 22.4155 27.8845L27.5021 29.9091C28.4934 30.3037 29.4879 29.3468 29.1318 28.341L27.3681 23.3592C29.0279 20.9563 30 18.042 30 14.9006C30 6.67124 23.3288 0 15.0994 0C6.86998 0 0.198738 6.67124 0.198738 14.9006ZM15.0993 26.0762C17.2912 26.0762 19.3357 25.4452 21.0611 24.3549L25.079 25.9541L23.6945 22.0436C25.3058 20.1068 26.2748 17.617 26.2748 14.9007C26.2748 8.7287 21.2714 3.72527 15.0993 3.72527C8.92729 3.72527 3.92386 8.7287 3.92386 14.9007C3.92386 21.0728 8.92729 26.0762 15.0993 26.0762ZM9 17C10.1046 17 11 16.1046 11 15C11 13.8954 10.1046 13 9 13C7.89543 13 7 13.8954 7 15C7 16.1046 7.89543 17 9 17ZM17 15C17 16.1046 16.1046 17 15 17C13.8954 17 13 16.1046 13 15C13 13.8954 13.8954 13 15 13C16.1046 13 17 13.8954 17 15ZM21 17C22.1046 17 23 16.1046 23 15C23 13.8954 22.1046 13 21 13C19.8954 13 19 13.8954 19 15C19 16.1046 19.8954 17 21 17Z"
        fill="white"
      />
    </svg>
  );
};

export default DefaultFloatButtonIcon;
