import React, { FC, useEffect, useState } from 'react';
import { Button } from 'antd';
import { IMemberData } from './organization';
import { showErrorMessage } from '../../../../shared/helpers';
import { organizationsAPI } from '../../../../services/organizations-service';
import { MessageInstance } from 'antd/es/message/interface';

interface IOrgMemberInviteElementProps {
  record: IMemberData;
  currentOrgId: string;
  messageApi: MessageInstance;
}

const RESET_TIMER_AFTER_MS = 60000 * 5;

const OrgMemberInviteElement: FC<IOrgMemberInviteElementProps> = ({
  record,
  currentOrgId,
  messageApi,
}) => {
  const [resendInvitation, { isLoading }] =
    organizationsAPI.useResendInvitationMutation();
  const [timerLock, setTimerLock] = useState<boolean>(false);
  const [timeToUnlock, setTimeToUnlock] =
    useState<number>(RESET_TIMER_AFTER_MS);
  const [timerInterval, setTimerInterval] = useState<number | null>(null);
  const [resendDisabled, setResendDisabled] = useState(false);

  const startTimer = () => {
    if (timerInterval !== null) {
      window.clearInterval(timerInterval);
    }

    setTimerLock(true);
    setTimeToUnlock(RESET_TIMER_AFTER_MS);

    const currentTime = new Date().getTime();

    const interval = window.setInterval(checkTime, 1000, currentTime);
    setTimerInterval(interval);
  };

  const checkTime = (savedTime: number) => {
    if (!savedTime) {
      return;
    }
    const timeLeft = RESET_TIMER_AFTER_MS - (new Date().getTime() - savedTime);
    setTimeToUnlock(timeLeft);
  };

  const resetTimerLock = () => {
    setTimerLock(false);
    setResendDisabled(false);
    if (timerInterval !== null) {
      window.clearInterval(timerInterval);
      setTimerInterval(null);
    }
  };

  useEffect(() => {
    if (timeToUnlock <= 0) {
      resetTimerLock();
    } else {
      if (timerInterval === null) {
        const interval = window.setInterval(checkTime, 1000);
        setTimerInterval(interval);
      }
    }
  }, [timeToUnlock]);

  const handleResendInvitation = async (memberId: string) => {
    const res = await resendInvitation({ orgId: currentOrgId, memberId });
    if ('error' in res) {
      await showErrorMessage(messageApi, res.error);
    } else {
      messageApi.success(`Invitation has been sent.`, 1);
      startTimer();
    }
  };

  return (
    <div>
      {record.isAccepted ? null : (
        <div style={{ textAlign: 'center', fontSize: 12 }}>
          <div>Invitation sent</div>
          <Button
            type="primary"
            onClick={() => handleResendInvitation(record.id)}
            loading={isLoading}
            disabled={resendDisabled || timerLock || isLoading}
            style={{ margin: '2px 0' }}
          >
            Resend
          </Button>
          {timerLock && timeToUnlock > 0 && (
            <div>
              You can resend invite again after {Math.ceil(timeToUnlock / 1000)}
              s.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default OrgMemberInviteElement;
