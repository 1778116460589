import { DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';
import { FC, useState } from 'react';
import DeleteTemplateModal from '../../../modals/twilio/delete-template-modal';

interface ITemplateMoreMenuProps {
  templateId: string;
  projectId: string;
  status: string;
  refreshParent: () => void;
}

const TemplateMoreMenu: FC<ITemplateMoreMenuProps> = ({
  templateId,
  projectId,
  status,
  refreshParent,
}) => {
  const [selectForDeleting, setSelectForDeleting] = useState<string>('');
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const defaultMenuItems: MenuProps['items'] = [
    {
      key: 'Delete',
      label: (
        <a
          onClick={(e) => {
            e.stopPropagation();
            setSelectForDeleting(templateId);
            setDeleteModalIsOpen(true);
          }}
        >
          <DeleteOutlined style={{ marginRight: 6 }} />
          Delete
        </a>
      ),
    },
  ];

  return (
    <>
      <Dropdown
        menu={{ items: defaultMenuItems }}
        placement="bottomRight"
        arrow={{ pointAtCenter: true }}
        trigger={['click']}
      >
        <Button
          type="text"
          icon={<MoreOutlined />}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Dropdown>

      <DeleteTemplateModal
        isOpen={deleteModalIsOpen}
        setIsOpen={setDeleteModalIsOpen}
        onRefresh={refreshParent}
        templateId={templateId}
        projectId={projectId}
      />
    </>
  );
};

export default TemplateMoreMenu;
