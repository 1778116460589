import React, { FC, useState } from 'react';
import { Avatar, Button } from 'antd';
import { EyeOutlined, UserOutlined } from '@ant-design/icons';
import IConversationMessage, {
  IConversationMessageItem,
} from '../../../types/IConversationMessage';
import {
  getConversationMessageItemText,
  getFormattedMessageDate,
} from './conversation-helpers';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { ConversationMessageTypes, UserRoles } from '../../../shared/constants';
import { aiAPI } from '../../../services/ai-service';
import { showErrorMessage } from '../../../shared/helpers';
import { MessageInstance } from 'antd/es/message/interface';
import ConversationFilePreviewModal from './conversation-file-preview-modal';

interface IConversationUserMessageProps {
  message: IConversationMessage;
  openTrace: ((messageId: string) => void) | null;
  messageApi: MessageInstance;
  projectId: string;
  chatId: string;
  sharedConversation: boolean;
}

const PREVIEW_FILE_EXTENSIONS = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'webp',
  'bmp',
  'svg',
];

const ConversationUserMessage: FC<IConversationUserMessageProps> = ({
  message,
  openTrace,
  messageApi,
  projectId,
  chatId,
  sharedConversation,
}) => {
  const { userRole } = useAppSelector((state) => state.userReducer);
  const [downloadFile, { data: fileData }] =
    aiAPI.useLazyDownloadAttachedFileQuery();
  const [previewFileName, setPreviewFileName] = useState<string>('');

  const handleFileDownload = async (messageItem: IConversationMessageItem) => {
    if (
      chatId &&
      projectId &&
      messageItem.$type === ConversationMessageTypes.FILE &&
      messageItem.fileName
    ) {
      console.log('Downloading file with parameters:', {
        projectId,
        chatId,
        fileName: messageItem.fileName,
      });
      const res = await downloadFile({
        projectId,
        chatId,
        fileName: messageItem.fileName,
      });
      if ('error' in res && res.error) {
        showErrorMessage(messageApi, res.error);
      } else if ('data' in res && res.data) {
        const downloadURL = res.data.downloadUrl;
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = messageItem.fileName.slice(24);
        link.click();
        window.URL.revokeObjectURL(downloadURL);
      }
    }
  };

  const getItemContent = (
    item: IConversationMessageItem,
    fileExtension: string
  ) => {
    if (sharedConversation) {
      return (
        <span style={{ display: 'inline-block' }}>
          {getConversationMessageItemText(item)}
        </span>
      );
    }

    return (
      <>
        <span
          style={{ display: 'inline-block' }}
          className={
            item.$type === ConversationMessageTypes.FILE
              ? 'download-attached-file'
              : ''
          }
          onClick={() => handleFileDownload(item)}
        >
          {getConversationMessageItemText(item)}
        </span>
        {item.$type === ConversationMessageTypes.FILE &&
          PREVIEW_FILE_EXTENSIONS.includes(fileExtension) && (
            <Button
              type={'text'}
              icon={<EyeOutlined />}
              onClick={() => setPreviewFileName(item?.fileName || '')}
            ></Button>
          )}
      </>
    );
  };

  return (
    <div className="insight-user-message-container">
      <div
        className={'conversation-message-container'}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 16 }}>
          {openTrace && userRole && userRole === UserRoles.ADMIN && (
            <Button onClick={() => openTrace(message.id)}>Trace</Button>
          )}
          <div
            className="insight-user-message conversation-message-text"
            // onClick={handleFileDownload}
          >
            {message.items.map((item, index) => {
              const fileExtension =
                (item.$type === ConversationMessageTypes.FILE &&
                  item.fileName?.split('.').pop()) ||
                '';
              return (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 10,
                    marginBottom: index === message.items.length - 1 ? 0 : 8,
                  }}
                >
                  {getItemContent(item, fileExtension)}
                </div>
              );
            })}
          </div>
        </div>
        <div
          className={
            'insight-date conversation-message-date-time conversation-message-date-time--user'
          }
        >
          {getFormattedMessageDate(message.createAt)}
        </div>
      </div>
      <Avatar size={40} icon={<UserOutlined />} className={'insight-avatar'} />
      {!sharedConversation && (
        <ConversationFilePreviewModal
          projectId={projectId}
          chatId={chatId}
          fileName={previewFileName}
          setFileName={setPreviewFileName}
        />
      )}
    </div>
  );
};

export default ConversationUserMessage;
