import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Form, Input, message, Modal, Select } from 'antd';
import { OrganizationMemberRole } from '../../../shared/constants';
import { IRoleOption } from '../../profile-settings/organizations/organization/organization';
import IOrganization from '../../../types/IOrganization';
import { useAppSelector } from '../../../hooks/redux-hooks';
import './invite-organization-member-modal.scss';
import { showErrorMessage } from '../../../shared/helpers';
import { organizationsAPI } from '../../../services/organizations-service';
import { emailRules } from '../../../shared/rules';

interface InviteOrganizationMemberModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  currentOrgInfo: IOrganization | null;
  currentUserRoleOption: IRoleOption | null;
  allRoleOptions: IRoleOption[];
  getOrgMembersInfo: () => void;
}

const InviteOrganizationMemberModal: FC<InviteOrganizationMemberModal> = ({
  isOpen,
  setIsOpen,
  currentOrgInfo,
  currentUserRoleOption,
  allRoleOptions,
  getOrgMembersInfo,
}) => {
  const { user } = useAppSelector((state) => state.userReducer);
  const [addOrganizationMember, { isLoading }] =
    organizationsAPI.useAddOrganizationMemberMutation();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [options, setOptions] = useState<IRoleOption[]>(
    allRoleOptions.filter((r) => r.numValue !== 0)
  );
  const modalContentRef = React.useRef<HTMLDivElement>(null);
  const [okDisabled, setOkDisabled] = useState<boolean>(true);

  useEffect(() => {
    const rolesNoOwner = allRoleOptions.filter((r) => r.numValue !== 0);

    if (currentOrgInfo && user && currentUserRoleOption) {
      const allowedRoleOptions = rolesNoOwner.filter(
        (r) => r.numValue >= currentUserRoleOption.numValue
      );
      setOptions(allowedRoleOptions);
    }
  }, [currentOrgInfo, user, allRoleOptions, currentUserRoleOption]);

  const handleInviteMember = async () => {
    if (!currentOrgInfo) return;
    setOkDisabled(true);
    const values = form.getFieldsValue();

    const result = await addOrganizationMember({
      orgId: currentOrgInfo.id,
      body: values,
    });

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
      setOkDisabled(false);
    } else {
      await messageApi.success(`User has been added.`, 1);
      getOrgMembersInfo();
      handleCancel();
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setOkDisabled(true);
    setIsOpen(false);
  };

  const handleChange = async () => {
    const values = form.getFieldsValue();
    if (!values.email || !values.role) {
      setOkDisabled(true);
    } else {
      try {
        await form.validateFields();
        setOkDisabled(false);
      } catch (errors) {
        setOkDisabled(true);
      }
    }
  };

  return (
    <Modal
      title="Invite Member"
      open={isOpen}
      onCancel={handleCancel}
      centered={true}
      className={'invite-organization-member-modal'}
      okButtonProps={{ disabled: okDisabled, loading: isLoading }}
      okText={'Invite'}
      onOk={handleInviteMember}
    >
      <div ref={modalContentRef}>
        {contextHolder}
        <Form
          form={form}
          onChange={handleChange}
          className="data-source-upload-modal-form"
          layout="vertical"
          initialValues={{ role: OrganizationMemberRole.MEMBER }}
        >
          <Form.Item name="email" rules={emailRules}>
            <Input placeholder="Enter email" />
          </Form.Item>
          <Form.Item name="role">
            <Select
              popupClassName={'org-member-role-select-popup'}
              className={
                'org-member-role-select org-member-role-select--invite-modal'
              }
              options={options}
              getPopupContainer={() => modalContentRef.current || document.body}
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default InviteOrganizationMemberModal;
