import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { FormInstance, Tabs, TabsProps, UploadFile } from 'antd';
import {
  ProjectSettingsType,
  SettingsTabsTypes,
} from '../../../shared/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteNames } from '../../../router/router';
import BasicSettings from './tabs-components/basic-settings';
import AppearanceSettings from './tabs-components/appearance-settings/appearance-settings';
import IProject from '../../../types/IProject';
import { MessageInstance } from 'antd/es/message/interface';
import IProjectStartInfo from '../../../types/IProjectStartInfo';
import AdvancedSettings from './tabs-components/advanced-settings';
import AdvancedLlmSettings from './tabs-components/advanced-llm-settings/advanced-llm-settings';

interface IProjectSettingsTabsProps {
  userProject: IProject;
  projectStartInfo: IProjectStartInfo;
  messageApi: MessageInstance;
  formInstances: {
    basicSettingsForm: FormInstance;
    appearanceSettingsForm: FormInstance;
    bubbleSettingsForm: FormInstance;
    advancedLlmSettingsForm: FormInstance;
  };
  handleFormsChange: () => void;
  image: UploadFile | null;
  setImage: Dispatch<SetStateAction<UploadFile | null>>;
  ownerIdOfCurrentOrg: string | null;
}

const SettingsTabs: FC<IProjectSettingsTabsProps> = ({
  userProject,
  projectStartInfo,
  messageApi,
  formInstances,
  handleFormsChange,
  image,
  setImage,
  ownerIdOfCurrentOrg,
}) => {
  const { orgId, subtab } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(subtab ?? SettingsTabsTypes.BASIC);

  const onChange = (key: string) => {
    navigate(
      `${RouteNames.USER_PROJECTS}/${orgId}${RouteNames.PROJECT_SETTINGS_TAB_NAME}/${userProject.id}/${ProjectSettingsType.SETTINGS}/${key}`
    );
  };

  const items: TabsProps['items'] = [
    {
      key: SettingsTabsTypes.BASIC,
      label: 'Basic',
      children: (
        <BasicSettings
          basicSettingsForm={formInstances.basicSettingsForm}
          handleChange={handleFormsChange}
        />
      ),
    },
    {
      key: SettingsTabsTypes.APPEARANCE,
      label: 'Appearance',
      children: (
        <AppearanceSettings
          userProject={userProject}
          projectStartInfo={projectStartInfo}
          appearanceSettingsForm={formInstances.appearanceSettingsForm}
          bubbleSettingsForm={formInstances.bubbleSettingsForm}
          handleChange={handleFormsChange}
          messageApi={messageApi}
          image={image}
          setImage={setImage}
        />
      ),
    },
    {
      key: SettingsTabsTypes.ADVANCED,
      label: 'Advanced',
      children: (
        <AdvancedSettings userProject={userProject} messageApi={messageApi} />
      ),
    },
    {
      key: SettingsTabsTypes.ADVANCED_LLM_SETTINGS,
      label: 'Advanced LLM Settings',
      children: (
        <AdvancedLlmSettings
          userProject={userProject}
          advancedLlmSettingsForm={formInstances.advancedLlmSettingsForm}
          handleChange={handleFormsChange}
          messageApi={messageApi}
          ownerIdOfCurrentOrg={ownerIdOfCurrentOrg}
        />
      ),
    },
  ];

  useEffect(() => {
    const option = items.find((option) => option.key === subtab);
    if (!subtab || !option) {
      onChange(SettingsTabsTypes.BASIC);
      setActiveTab(SettingsTabsTypes.BASIC);
      return;
    }
    setActiveTab(option.key);
  }, [subtab]);

  return (
    <div>
      <Tabs activeKey={activeTab} items={items} onChange={onChange} />
    </div>
  );
};

export default SettingsTabs;
