import React, { FC, useEffect } from 'react';
import { Button } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

interface INotificationSaveChangesDescription {
  saveChangesHandler: () => void;
  saveLoading: boolean;
  saveDisabled: boolean;
  cb?: () => void;
}

const NotificationSaveChangesDescription: FC<
  INotificationSaveChangesDescription
> = ({ saveChangesHandler, saveLoading, saveDisabled, cb }) => {
  useEffect(() => {
    if (cb) {
      cb();
    }
  }, [cb]);

  return (
    <div className="notification-save-changes-description">
      You have made changes to the project{' '}
      <Button
        type="primary"
        icon={<SaveOutlined />}
        onClick={() => saveChangesHandler()}
        loading={saveLoading}
        disabled={saveDisabled}
        className={'notification-save-changes-button'}
      >
        Save Changes
      </Button>
    </div>
  );
};

export default NotificationSaveChangesDescription;
