import '../twilio-template.scss';
import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Input, Select, Tooltip } from 'antd';
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/es/form';
import TextArea from 'antd/es/input/TextArea';
import { LiaExternalLinkAltSolid } from 'react-icons/lia';

interface RenderFieldProps {
  form: FormInstance<any>;
  keyName: string;
  label: string;
  placeholder?: string;
  isTextArea?: boolean;
  withTooltip?: boolean;
  isRequired?: boolean;
  limit?: number;
}

interface IButtonsProps {
  form: FormInstance<any>;
}

const InputComponent = (
  isTextArea: boolean,
  placeholder: string,
  handleCursorPosition: (
    e:
      | React.MouseEvent<HTMLTextAreaElement | HTMLInputElement>
      | React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void,
  setValueBody: (arg0: string) => any,
  limit: number
) => {
  const inputProps = {
    placeholder: placeholder,
    onClick: handleCursorPosition,
    onKeyUp: handleCursorPosition,
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      setValueBody(e.target.value),
    maxLength: limit,
    showCount: true,
  };

  return isTextArea ? (
    <TextArea rows={6} {...inputProps} />
  ) : (
    <Input {...inputProps} />
  );
};

const RenderField: React.FC<RenderFieldProps> = ({
  form,
  keyName,
  label,
  placeholder = '',
  isTextArea = false,
  withTooltip = true,
  isRequired = true,
  limit = 20,
}) => {
  const [valueBody, setValueBody] = useState<string>('');
  const [cursorPosition, setCursorPosition] = useState(0);

  const incrementValue = () => {
    const count = () => {
      const regex = /\{\{(\d+)\}\}/g;
      let match;
      const numbersInString: number[] = [];

      while ((match = regex.exec(valueBody)) !== null) {
        numbersInString.push(parseInt(match[1], 10));
      }

      for (let i = 1; i <= 1000; i++) {
        if (!numbersInString.includes(i)) {
          return i;
        }
      }

      return null;
    };

    const newText = `${valueBody.slice(
      0,
      cursorPosition
    )}{{${count()}}}${valueBody.slice(cursorPosition)}`;

    setValueBody(newText);
    form.setFieldValue(keyName, newText);
  };

  const handleCursorPosition = (
    e:
      | React.MouseEvent<HTMLTextAreaElement | HTMLInputElement>
      | React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const selectionStart = e.currentTarget.selectionStart;
    if (selectionStart !== null) {
      setCursorPosition(selectionStart);
    }
  };

  return (
    <>
      <Form.Item
        label={label}
        key={keyName}
        name={keyName}
        className="textarea-wrapper"
        rules={[
          {
            required: isRequired,
            message: 'This field is required',
          },
        ]}
      >
        {InputComponent(
          isTextArea,
          placeholder,
          handleCursorPosition,
          setValueBody,
          limit
        )}
      </Form.Item>
      {withTooltip && (
        <TooltipComponent keyName={keyName} incrementValue={incrementValue} />
      )}
    </>
  );
};

interface ITooltipComponentProps {
  keyName: string;
  incrementValue: () => void;
}

const TooltipComponent: FC<ITooltipComponentProps> = ({
  keyName,
  incrementValue,
}) => (
  <div
    className={
      keyName.includes('item_') ||
      keyName.includes('cta_') ||
      keyName.includes('button_')
        ? 'add-variable-group-button-wrapper'
        : 'add-variable-button-wrapper'
    }
  >
    <Tooltip
      className="add-variable-button"
      title={
        "Click here to add content variables to this field. Variables allow you to personalize messages. With variables, you can customize a URL or input a customer's name at time of send."
      }
      overlayStyle={{
        zIndex: 2500,
      }}
    >
      <span
        style={{
          marginTop: 3,
          marginLeft: 0,
          color: 'rgba(60, 66, 87, 0.45)',
          cursor: 'help',
        }}
      >
        <QuestionCircleOutlined />
      </span>
    </Tooltip>
    <button
      key={`${keyName}_add_variable`}
      className="add-variable-button"
      onClick={incrementValue}
    >
      + Add variable
    </button>
  </div>
);

export const RenderBodyField = (
  form: FormInstance<any>,
  limit: number = 1600
) => (
  <RenderField
    form={form}
    keyName="body"
    label="Template body:"
    placeholder="Type body"
    isTextArea
    limit={limit}
  />
);

export const RenderTitleField = (form: FormInstance<any>) => (
  <RenderField
    form={form}
    keyName="title"
    label="Title:"
    placeholder="Type title"
    isTextArea
    limit={1600}
  />
);

export const RenderInputField = (
  form: FormInstance<any>,
  key: string,
  label: string,
  placeholder: string = '',
  withTooltip: boolean = true,
  isRequired: boolean = true,
  limit: number = 20
) => (
  <RenderField
    form={form}
    keyName={key}
    label={label}
    placeholder={placeholder}
    isTextArea={false}
    withTooltip={withTooltip}
    isRequired={isRequired}
    limit={limit}
  />
);

export const CTAButtons: FC<IButtonsProps> = ({ form }) => {
  const [buttons, setButtons] = useState<number[]>([0]);
  const [isInit, setIsinit] = useState<boolean>(true);

  const CTATypes = [
    {
      key: 'URL',
      name: 'Visit website',
    },
    {
      key: 'PHONE_NUMBER',
      name: 'Phone number',
    },
  ];

  const resetField = (index: number) => {
    form.resetFields([`cta_type_of_action_${index}`]);
    form.resetFields([`cta_${index}`]);
    form.resetFields([`cta_url_${index}`]);
    form.resetFields([`cta_phone_${index}`]);
  };

  useEffect(() => {
    if (isInit) {
      buttons.forEach((btnIndex) => {
        resetField(btnIndex);
      });

      setIsinit(false);
    }
  });

  const handleAddButton = () => {
    const index = buttons[0] === 0 ? 1 : 0;
    resetField(index);
    setButtons([buttons[0], index]);
  };

  const handleRemoveButton = (index: number) => {
    setButtons((prevButtons) =>
      prevButtons.filter((button) => button !== index)
    );
  };

  const getOptions = (index: number) => {
    if (buttons.length === 1) return CTATypes;
    return [CTATypes[index]];
  };

  return (
    <>
      {buttons.map((index) => {
        const data = CTATypes[index];
        const options = getOptions(index);

        return (
          <div className="item-group" key={`group_item_${index}`}>
            <b>Button:</b>
            <React.Fragment key={`cta_${index}_fragment`}>
              {buttons.length > 1 && (
                <Button
                  key={`cta_${index}_delete`}
                  type="text"
                  icon={<DeleteOutlined />}
                  className="template-delete-group-button"
                  onClick={() => handleRemoveButton(index)}
                />
              )}
              <Form.Item
                name={`cta_type_of_action_${index}`}
                label="Type of action:"
                key={`cta_type_of_action_${index}`}
                rules={[
                  {
                    required: true,
                    message: 'Type of action is required',
                  },
                ]}
                initialValue={data.key}
              >
                <Select
                  placeholder={data.name}
                  dropdownStyle={{ zIndex: 10050 }}
                  onChange={(value) => {
                    if (buttons.length === 2) return;
                    const chosenIndex = value === CTATypes[0].key ? 0 : 1;
                    resetField(chosenIndex);
                    setButtons([chosenIndex]);
                  }}
                >
                  {options.map((option) => (
                    <Select.Option key={option.key} value={option.key}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {RenderInputField(
                form,
                `cta_${index}`,
                'Button text:',
                `Type button text`,
                false
              )}
              {data.key === 'URL' ? (
                RenderInputField(
                  form,
                  `cta_url_${index}`,
                  'Website URL',
                  `https://www...`,
                  true,
                  true,
                  1600
                )
              ) : (
                <>
                  {RenderInputField(
                    form,
                    `cta_phone_${index}`,
                    'Phone number:',
                    `+15551234567`,
                    false
                  )}
                  <div style={{ marginTop: '-20px', color: '#808080' }}>
                    A valid number in{' '}
                    <a
                      href="https://www.twilio.com/docs/glossary/what-e164?_gl=1*1t2rs3s*_gcl_aw*R0NMLjE3MjI0MjMzNzQuQ2owS0NRand3YWUxQmhDX0FSSXNBSzRKZnJ4dmZBNHM4cUVBREw3T1BGN05vajEwejdONmU0eVd6dk82QkxaUm9FeGQwSmdiUW9FX0FYVWFBc0pnRUFMd193Y0I.*_gcl_au*MTM4OTQzODEyNC4xNzE5OTA1NDU0*_ga*MTg5NTIyNjY2OC4xNzE3NzcwNTI3*_ga_RRP8K4M4F3*MTcyMjQzODEyNi41OS4xLjE3MjI0MzgxNDkuMC4wLjA."
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'blue', textDecoration: 'none' }}
                    >
                      E.164 format <LiaExternalLinkAltSolid />
                    </a>{' '}
                    is required. Please include a +Country Code as a prefix.
                  </div>
                </>
              )}
            </React.Fragment>
          </div>
        );
      })}
      <Button
        key={'addNewButton'}
        onClick={handleAddButton}
        disabled={buttons.length === 2}
      >
        Add button
      </Button>
    </>
  );
};

export const QuickReplyButtons: FC<IButtonsProps> = ({ form }) => {
  const [buttons, setButtons] = useState<number[]>([0]);

  const handleAddButton = () => {
    setButtons((prevButtons) => [...prevButtons, prevButtons.length]);
  };

  const handleRemoveButton = (index: number) => {
    if (buttons.length > 1) {
      setButtons((prevButtons) =>
        prevButtons.filter((button) => button !== index)
      );
    }
  };

  return (
    <>
      {buttons.map((index) => {
        return (
          <div className="item-group" key={`group_item_${index}`}>
            <React.Fragment key={`button_${index}_fragment`}>
              {buttons.length > 1 && (
                <Button
                  key={`button_${index}_delete`}
                  type="text"
                  icon={<DeleteOutlined />}
                  className="template-delete-group-button"
                  onClick={() => {
                    handleRemoveButton(index);
                  }}
                />
              )}
              {RenderInputField(
                form,
                `button_${index}`,
                'Button:',
                `Type button text`
              )}
            </React.Fragment>
          </div>
        );
      })}
      <Button
        key={'addNewButton'}
        onClick={handleAddButton}
        disabled={buttons.length === 3}
      >
        Add button
      </Button>
    </>
  );
};
