import React, { FC } from 'react';
import { Divider } from 'antd';
import IRecordViewProps from '../IRecordViewProps';
import TagRecordItem from './tag-record-item';

const TagRecordsView: FC<IRecordViewProps> = ({
  records,
  handleEditRecord,
  handleDeleteRecord,
}) => {
  return (
    <div>
      <Divider orientation="left" className={'contact-form-divider'}>
        Tags
      </Divider>
      {records.map((record, index) => (
        <div key={index}>
          <TagRecordItem
            record={record}
            handleEditRecord={handleEditRecord}
            handleDeleteRecord={handleDeleteRecord}
          />
          {index !== records.length - 1 && (
            <Divider
              orientation="left"
              className={'contact-form-divider'}
              dashed={true}
            ></Divider>
          )}
        </div>
      ))}
    </div>
  );
};

export default TagRecordsView;
