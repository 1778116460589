import { Button, Checkbox, Form, Input } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import './header-cell-props.scss';
import {
  IEditorTableColumn,
  ITableColumnT,
} from '../../../../../../types/tables-service/ITableT';
import { IoFilter } from 'react-icons/io5';
import { TableColumnTypes } from '../../../../../../shared/constants';

interface IHeaderCellProps {
  isReadOnly: boolean;
  column: IEditorTableColumn;
  onChangeColumn: (columnProps: ITableColumnT) => void;
  onFilterClick: () => void;
  setOpenHeaderCellMoreMenu: React.Dispatch<React.SetStateAction<string>>;
}

const HeaderCellProps: FC<IHeaderCellProps> = ({
  isReadOnly,
  column,
  onChangeColumn,
  onFilterClick,
  setOpenHeaderCellMoreMenu,
}) => {
  const [form] = Form.useForm();
  const [applyDisabled, setApplyDisabled] = useState(true);

  useEffect(() => {
    form.setFieldsValue(column);
    handleChange();
  }, [column]);

  const applyChanges = () => {
    const values = form.getFieldsValue();
    onChangeColumn({
      ...values,
      type: TableColumnTypes.STRING,
      key: column.key,
    });
    setOpenHeaderCellMoreMenu('');
  };

  const handleChange = () => {
    const values = form.getFieldsValue();

    for (let key in values) {
      if (values[key] !== column[key as keyof IEditorTableColumn]) {
        setApplyDisabled(false);
        return;
      }
    }

    setApplyDisabled(true);
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <div className={'header-cell-props__title'}>
        Edit Column
        <Button size={'small'} type="text" onClick={onFilterClick}>
          <IoFilter style={{ marginTop: 4 }} />
        </Button>
      </div>
      <Form
        form={form}
        onChange={handleChange}
        initialValues={column}
        layout="vertical"
      >
        <Form.Item
          label={'Name'}
          name={`name`}
          className={`header-cell-props-form-item header-cell-props-form-item--input ${
            isReadOnly ? 'header-cell-props-form-item--disabled' : ''
          }`}
        >
          <Input placeholder="Enter name" disabled={isReadOnly} />
        </Form.Item>
        {/*<Form.Item*/}
        {/*  label={'Type'}*/}
        {/*  name={`type`}*/}
        {/*  className={*/}
        {/*    'header-cell-props-form-item header-cell-props-form-item--input'*/}
        {/*  }*/}
        {/*>*/}
        {/*  <Select*/}
        {/*    options={columnTypeOptions}*/}
        {/*    dropdownStyle={{ zIndex: 2000 }}*/}
        {/*    onChange={handleChange}*/}
        {/*  />*/}
        {/*</Form.Item>*/}
        <Form.Item
          name={`isSearchable`}
          valuePropName="checked"
          className={'header-cell-props-form-item'}
        >
          <Checkbox disabled={isReadOnly}>Enable search</Checkbox>
        </Form.Item>
        <Form.Item
          name={`isUnique`}
          valuePropName="checked"
          className={'header-cell-props-form-item'}
        >
          <Checkbox disabled={isReadOnly}>Is unique</Checkbox>
        </Form.Item>
      </Form>
      <Button
        type={'primary'}
        onClick={applyChanges}
        style={{ width: '100%', marginTop: 10 }}
        disabled={applyDisabled || isReadOnly}
      >
        Apply
      </Button>
    </div>
  );
};

export default HeaderCellProps;
