import { FC } from 'react';
import { RenderBodyField } from './template-builder';
import { FormInstance } from 'antd';

interface ITextTemplateProps {
  form: FormInstance<any>;
}

const TextTemplate: FC<ITextTemplateProps> = ({ form }) => {
  return <>{RenderBodyField(form)}</>;
};

export default TextTemplate;
