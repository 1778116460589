import React, { FC, useEffect, useState } from 'react';
import { useAppSelector } from '../../hooks/redux-hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { RouteNames } from '../../router/router';
import { usersAPI } from '../../services/users-service';
import Loading from '../loading/loading';
import { LS_TOKEN_KEY } from '../../shared/constants';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

interface ApiError {
  message: string;
}

const CheckAuth: FC<{
  component: JSX.Element | JSX.Element[];
  allowedForDomainTenantMember: boolean;
}> = ({ component, allowedForDomainTenantMember }) => {
  const [skip, setSkip] = useState<boolean>(true);
  const [
    lazyGetUserInfo,
    { data: userData, isLoading: userLoading, error: userError },
  ] = usersAPI.useLazyGetUserInfoQuery();
  // const {
  //   data: userData,
  //   isLoading: userLoading,
  //   error: userError,
  // } = usersAPI.useGetUserInfoQuery(undefined, { skip });
  const { isAuth, user, isDomainTenantMember } = useAppSelector(
    (state) => state.userReducer
  );
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    lazyGetUserInfo();
  }, [isAuth]);

  useEffect(() => {
    if (userError && 'status' in userError) {
      const error = userError as FetchBaseQueryError;
      const data = error.data as ApiError;

      if (error.status === 403) {
        switch (data.message) {
          case 'Enterprise is blocked':
            navigate(RouteNames.SUBSCRIPTION_LOCK);
            break;

          case 'User is blocked':
            navigate(RouteNames.USER_BLOCKED);
        }
      }
    }
  }, [userError]);

  useEffect(() => {
    if (localStorage.getItem(LS_TOKEN_KEY)) {
      setSkip(false);
      lazyGetUserInfo();
    } else {
      navigate(RouteNames.LOGIN, { state: { from: location } });
      return;
    }
  }, []);

  useEffect(() => {
    if (userLoading || skip) return;
    if (!isAuth) {
      navigate(RouteNames.LOGIN, { state: { from: location } });
      return;
    }
    if (user && !user.emailConfirmed) {
      navigate(RouteNames.VERIFY_EMAIL, { state: { email: user.email } });
      return;
    }

    if (!allowedForDomainTenantMember && isDomainTenantMember) {
      navigate(RouteNames.PAGE_NOT_EXIST);
      return;
    }
  }, [isAuth, userLoading, user, skip]);

  return (
    <div>
      {userLoading ? (
        <Loading />
      ) : user && user.emailConfirmed ? (
        component
      ) : null}
    </div>
  );
};

export default CheckAuth;
