import { FC } from 'react';
import { CTAButtons, RenderBodyField } from './template-builder';
import { FormInstance } from 'antd';

interface ICTATemplateProps {
  form: FormInstance<any>;
}

const CTATemplate: FC<ICTATemplateProps> = ({ form }) => {
  return (
    <>
      {RenderBodyField(form, 640)}
      <CTAButtons form={form} />
    </>
  );
};

export default CTATemplate;
