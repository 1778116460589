import { apiService } from './api-service';

export const projectPluginsAPI = apiService.injectEndpoints({
  endpoints: (build) => ({
    sendLiveMessage: build.mutation<
      void,
      { projectId: string; conversationId: string; messageText: string }
    >({
      query: ({ projectId, conversationId, messageText }) => ({
        url: `/api/plugins/twilio/projects/${projectId}/conversations/${conversationId}/live-agent/messages`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify({ text: messageText }),
      }),
    }),
    takeControl: build.mutation<
      void,
      { projectId: string; conversationId: string }
    >({
      query: ({ projectId, conversationId }) => ({
        url: `/api/plugins/twilio/projects/${projectId}/conversations/${conversationId}/live-agent/take-control`,
        method: 'POST',
      }),
    }),
    releaseControl: build.mutation<
      void,
      { projectId: string; conversationId: string }
    >({
      query: ({ projectId, conversationId }) => ({
        url: `/api/plugins/twilio/projects/${projectId}/conversations/${conversationId}/live-agent/release-control`,
        method: 'POST',
      }),
    }),
    setTwilioWebhook: build.mutation<void, { projectId: string }>({
      query: ({ projectId }) => ({
        url: `/api/plugins/twilio/projects/${projectId}/set-webhook`,
        method: 'POST',
      }),
    }),
    getJiraProjects: build.query<
      any[],
      { projectId: string; pluginId: string; projectPluginId: string }
    >({
      query: ({ projectId, pluginId, projectPluginId }) => ({
        url: `/projects/${projectId}/plugins/jira/${pluginId}/${projectPluginId}/projects`,
        method: 'GET',
      }),
    }),
  }),
});
