import { useState, useEffect, FC } from 'react';
import { Bar, LabelList, Legend } from 'recharts';
import { usageAPI } from '../../../services/usage-service';
import {
  chartColors,
  distinctByProp,
  getChartLabelPostfix,
  IChartsSettings,
  UsagePerDayChartProps,
} from '../../charts/chart-data';
import { Skeleton } from 'antd';
import { projectsAPI } from '../../../services/projects-service';
import AnalyticsUtils from '../../shared/analytics/analytics-utils';
import { ChartContainer } from '../../shared/analytics/analytics-container-component';

const UsagePerDayChart: FC<{
  settings: IChartsSettings;
  organizationId: string;
}> = ({ settings, organizationId }) => {
  const initialChartsData: UsagePerDayChartProps = { charts: [] };
  const [chartsData, setChartsData] = useState(initialChartsData);

  const [lazyGetData, { data: queryResult, isLoading }] =
    usageAPI.useLazyGetUsagePerDayQuery();

  const { data: userProjects } = projectsAPI.useGetUserProjectsQuery({
    orgId: organizationId,
  });
  // const { userProjects } = useAppSelector((state) => state.projectReducer);

  // useEffect(() => {
  //   const now = new Date();
  //   const nowString = now.toISOString();
  //   lazyGetData({ date: nowString, organizationId });
  // }, [lazyGetData]);

  useEffect(() => {
    if (settings) {
      const dateString = settings.month.toISOString();
      lazyGetData({ date: dateString, organizationId });
    }
  }, [settings]);

  useEffect(() => {
    if (queryResult) {
      const tableData = AnalyticsUtils.getTableData(queryResult);
      const expenses = AnalyticsUtils.getExpenses(tableData);
      const chartsData: UsagePerDayChartProps = { charts: [] };

      for (const expenseType of expenses) {
        const filterName = 'projectId';
        const uniqueProjects = distinctByProp(tableData, filterName);
        const barsData = AnalyticsUtils.generateBarsDataByProject(
          uniqueProjects,
          userProjects,
          expenseType
        );

        const newChartData: any[] = [];
        AnalyticsUtils.handleUsageDataByBarsData(
          tableData,
          barsData,
          queryResult,
          expenseType,
          newChartData,
          filterName
        );

        const data = {
          bars: barsData,
          data: newChartData,
          xAxisDataKey: 'day',
          label: (
            <div className={'usage__chart-title'}>
              Usage in credits{getChartLabelPostfix(expenseType)}
            </div>
          ),
        };
        chartsData.charts.push(data);
      }

      setChartsData(chartsData);
    }
  }, [queryResult, userProjects]);

  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          {chartsData.charts.map((chartData, iChart) => (
            <ChartContainer
              key={'bar' + iChart}
              chartData={chartData.data}
              xAxisKey={chartData.xAxisDataKey}
              headerContent={chartData.label}
            >
              <Legend />
              {chartData.bars.map((barData, iBar) => (
                <Bar
                  key={'bar' + iBar}
                  dataKey={barData.dataKey}
                  name={barData.name}
                  stackId={barData.type}
                  fill={chartColors[iBar % chartColors.length]}
                >
                  {barData.labelKey ? (
                    <LabelList
                      dataKey={barData.labelKey}
                      position="top"
                      formatter={(label: string) =>
                        label == '0' ? null : label
                      }
                    />
                  ) : null}
                </Bar>
              ))}
            </ChartContainer>
          ))}
        </>
      )}
    </>
  );
};

export default UsagePerDayChart;
