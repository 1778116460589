import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Form, Input, message, Modal } from 'antd';
import { domainsAPI } from '../../../services/domains-service';
import { showErrorMessage } from '../../../shared/helpers';
import { V_REQUIRED_FIELD } from '../../../shared/constants';

interface IAddDomainModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const AddDomainModal: FC<IAddDomainModal> = ({ isOpen, setIsOpen }) => {
  const [addDomain, { isLoading }] = domainsAPI.useAddDomainMutation();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [okDisabled, setOkDisabled] = useState(true);

  const handleOk = async () => {
    const values = form.getFieldsValue();
    const result = await addDomain({ body: values });

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
    } else {
      await messageApi.success('The domain has been added.', 1);
      form.resetFields();
      setOkDisabled(true);
      setIsOpen(false);
    }
  };

  const handleChange = async () => {
    try {
      await form.validateFields();
      setOkDisabled(false);
    } catch (errors) {
      setOkDisabled(true);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setOkDisabled(true);
    setIsOpen(false);
  };

  const inputFormItem = (
    name: string,
    label: string,
    placeholder: string,
    tooltip?: string
  ) => (
    <Form.Item
      name={name}
      label={label}
      tooltip={tooltip}
      rules={[
        {
          required: true,
          message: V_REQUIRED_FIELD,
        },
      ]}
    >
      <Input placeholder={placeholder} />
    </Form.Item>
  );

  return (
    <Modal
      wrapClassName={'modal'}
      title="Add domain"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={isLoading}
      okButtonProps={{ disabled: isLoading || okDisabled }}
      okText={'Add'}
    >
      {contextHolder}
      <Form
        className="data-source-upload-modal-form"
        layout="vertical"
        form={form}
        onChange={handleChange}
        requiredMark={false}
      >
        {inputFormItem('domain', 'Domain:', 'domain')}
        {inputFormItem('brandName', 'Brand name:', 'Brand Name')}
        {inputFormItem(
          'email',
          'Email:',
          'noreply@domain.com',
          'Email address to send notifications to your users, e.g. noreply@domain.com.'
        )}
        {inputFormItem(
          'emailApiKey',
          'SendGrid API Key:',
          'API Key',
          'API key associated with your SendGrid service.'
        )}
      </Form>
    </Modal>
  );
};

export default AddDomainModal;
