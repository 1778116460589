import React, { FC } from 'react';
import { Divider } from 'antd';
import TextInputFormItem from './form-items/text-input-form-item';

const LanguageRecordFormElement: FC = () => {
  return (
    <>
      <Divider orientation="left" className={'contact-form-divider'}>
        Language
      </Divider>
      <div style={{ display: 'flex', gap: 24 }}>
        <TextInputFormItem
          label={'Language'}
          name={'language'}
          placeholder={'Language'}
        />
        <TextInputFormItem
          label={'Level'}
          name={'level'}
          placeholder={'Level'}
        />
      </div>
    </>
  );
};

export default LanguageRecordFormElement;
