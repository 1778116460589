import { Button, Collapse } from 'antd';
import './twilio-template.scss';
import { IoListSharp } from 'react-icons/io5';
import { FiExternalLink } from 'react-icons/fi';
import { IoCallOutline } from 'react-icons/io5';

const { Panel } = Collapse;

class TwilioTemplateHelper {
  static getMedia(url: string) {
    const extension = url.split('.').pop()?.toLowerCase();

    if (['jpg', 'jpeg', 'png'].includes(extension!)) {
      return (
        <img key={url} src={url} alt="" className="template-preview-media" />
      );
    } else if (extension === 'mp4') {
      return (
        <video key={url} controls className="template-preview-media">
          <source src={url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    } else if (extension === 'ogg') {
      return (
        <audio key={url} controls className="template-preview-media">
          <source src={url} type="audio/ogg" />
          Your browser does not support the audio element.
        </audio>
      );
    } else if (['pdf', 'doc', 'docx', 'pptx', 'xlsx'].includes(extension!)) {
      return (
        <a
          key={url}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="template-preview-media"
        >
          {url.split('/').pop()}
        </a>
      );
    } else {
      return null;
    }
  }

  static getButton(title: string, index: number, iconType?: string) {
    const getIcon = () => {
      if (!iconType) return null;

      if (iconType.toLowerCase() === 'url') {
        return <FiExternalLink className="icon-list" />;
      }

      if (iconType.toLowerCase() === 'phone_number')
        return <IoCallOutline className="icon-list" />;
    };

    return (
      <Button
        key={`action-${index}`}
        className="template-preview-button"
        icon={getIcon()}
      >
        {title}
      </Button>
    );
  }

  static getCollapsedList(title: string, items: any) {
    const actions = JSON.parse(items);

    return (
      <Collapse
        expandIcon={() => null}
        className="template-preview-list-button"
      >
        <Panel
          header={
            <div className="custom-panel-header">
              <IoListSharp className="icon-list" />
              <span>{title}</span>
            </div>
          }
          key="button-header"
        >
          {actions.map((item: any) => (
            <li key={item.id} className="list-item">
              <strong>{item.item}</strong>
              <p>{item.description}</p>
            </li>
          ))}
        </Panel>
      </Collapse>
    );
  }

  static getText(text: string | undefined) {
    if (!text) return null;

    const formattedBody = text.replace(/\n/g, '<br/>');

    return <div dangerouslySetInnerHTML={{ __html: formattedBody }} />;
  }

  static getVariablesFromBody(body: string) {
    const regex = /\{\{(\d+)\}\}/g;
    let match;
    const result: { [key: string]: string } = {};

    while ((match = regex.exec(body)) !== null) {
      const key = match[1];
      result[key] = `variable ${key}`;
    }

    return result;
  }

  static getFilteredData(values: any): any {
    const result: any = {};

    if (values['media']) result['media'] = [values['media']];
    if (values['button']) result['button'] = values['button'];
    if (values['title']) result['title'] = values['title'];
    if (values['subtitle']) result['subtitle'] = values['subtitle'];

    const actions = getButtonActions(values);
    if (actions.length > 0) result.actions = actions;

    const ctaActions = getCTAActions(values);
    if (ctaActions.length > 0) result.actions = ctaActions;

    const items = getItems(values);
    if (items.length > 0) result.items = items;

    return result;
  }
}

export default TwilioTemplateHelper;

const extractNumber = (str: string) => {
  const match = str.match(/\d+$/);
  return match ? parseInt(match[0], 10) : 0;
};

const getButtonActions = (values: any) => {
  return Object.entries(values)
    .filter(([key, value]) => key.includes('button_') && value)
    .map(([, value], index) => {
      return { title: value, id: `${value}_${index}`, type: 'QUICK_REPLY' };
    });
};

const processValues = (
  values: any,
  prefix: string,
  mappings: { [key: string]: string }
) => {
  const items: any[] = [];

  Object.entries(values)
    .filter(([key, value]) => key.includes(prefix) && value)
    .forEach(([key, value]) => {
      const index = extractNumber(key);
      if (!items[index]) items[index] = {};

      for (const [pattern, prop] of Object.entries(mappings)) {
        if (key.includes(pattern)) {
          items[index][prop] = value;
          break;
        }
      }
    });

  return items;
};

const getCTAActions = (values: any) => {
  return processValues(values, 'cta_', {
    _type_of_action_: 'type',
    _phone_: 'phone',
    _url_: 'url',
    '': 'title',
  });
};

const getItems = (values: any) => {
  return processValues(values, 'item_', {
    _id_: 'id',
    _description_: 'description',
    '': 'item',
  });
};
