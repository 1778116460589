import React, { FC, useEffect } from 'react';
import { Alert, Button, Form, Input } from 'antd';
import { ArrowRightOutlined, DeleteOutlined } from '@ant-design/icons';
import IOrganization from '../../../../types/IOrganization';
import { IRoleOption } from './organization';
import { subscriptionAPI } from '../../../../services/subscription-service';
import { organizationsAPI } from '../../../../services/organizations-service';
import { getDomain, showErrorMessage } from '../../../../shared/helpers';
import { MessageInstance } from 'antd/es/message/interface';
import {
  DEFAULT_ZAPPR_DOMAIN,
  V_REQUIRED_FIELD,
} from '../../../../shared/constants';
import TransferOrganizationModal from '../../../modals/transfer-organization-modal/transfer-organization-modal';

interface IOrgSettingsProps {
  currentOrgInfo: IOrganization | null;
  currentUserRoleOption: IRoleOption | null;
  setDeleteModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  messageApi: MessageInstance;
}

const OrgSettings: FC<IOrgSettingsProps> = ({
  currentOrgInfo,
  currentUserRoleOption,
  setDeleteModalIsOpen,
  messageApi,
}) => {
  const { data: userSubscription } =
    subscriptionAPI.useGetUserSubscriptionQuery();
  const [form] = Form.useForm();
  const [saveDisabled, setSaveDisabled] = React.useState(true);
  const [updateOrganization, { isLoading }] =
    organizationsAPI.useUpdateOrganizationMutation();
  const [transferModalIsOpen, setTransferModalIsOpen] = React.useState(false);

  const isOwner = () => {
    return currentUserRoleOption && currentUserRoleOption.numValue === 0;
  };

  const getAlertMessage = () => {
    if (!userSubscription || !userSubscription.currentPlan)
      return "Can't get subscription info.";
    if (!isOwner())
      return 'Domain editing is available only to the owner of the organization.';
    if (!userSubscription.currentPlan.features.remove_branding)
      return 'Domain editing is not available for your subscription plan.';
    return null;
  };

  useEffect(() => {
    if (currentOrgInfo) {
      form.setFieldsValue({
        domain: currentOrgInfo.domain,
        displayName: currentOrgInfo.displayName,
      });
    }
  }, [currentOrgInfo]);

  const handleChange = () => {
    const currentValues = form.getFieldsValue();
    const isChanged =
      currentValues.domain !== currentOrgInfo?.domain ||
      currentValues.displayName !== currentOrgInfo?.displayName;
    if (isChanged) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }
  };

  const onFinish = async () => {
    if (!currentOrgInfo) return;
    const formValues = form.getFieldsValue();

    const result = await updateOrganization({
      orgId: currentOrgInfo.id,
      body: formValues,
    });

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
    } else {
      messageApi.success(`Organization has been updated.`, 1);
    }
  };

  const renderUpdateDomain = () => {
    const alertMessage = getAlertMessage();

    const validateForbiddenName = (rule: any, value: string) => {
      if (value && value.toLowerCase() === 'personal') {
        return Promise.reject('The name "Personal" is reserved.');
      }
      return Promise.resolve();
    };

    return (
      <>
        {alertMessage && (
          <Alert
            message={alertMessage}
            type="info"
            showIcon
            style={{ marginBottom: 20 }}
            className={'org-settings-adaptive'}
          />
        )}
        <Form
          form={form}
          layout={'vertical'}
          onFinish={onFinish}
          disabled={alertMessage !== null}
          style={{ marginBottom: 20 }}
          onChange={handleChange}
          className={'org-settings-adaptive'}
        >
          <Form.Item label={`Custom domain`} name={`domain`}>
            <Input
              className="organization-branding org-settings-adaptive"
              placeholder={`${getDomain()}`}
              disabled={alertMessage !== null}
            />
          </Form.Item>

          <Form.Item
            label={`Name`}
            name={`displayName`}
            rules={[
              {
                required: true,
                message: V_REQUIRED_FIELD,
              },
              {
                min: 3,
                message: 'Name must be at least 3 characters.',
              },
              {
                max: 32,
                message: 'Max length of display name is 32 characters.',
              },
              { validator: validateForbiddenName },
            ]}
          >
            <Input
              className="organization-branding org-settings-adaptive"
              placeholder={`${getDomain()}`}
              disabled={alertMessage !== null}
            />
          </Form.Item>
          <div className="plugin-setting-btn-container">
            <Form.Item className="save-changes-plugin-form-item">
              <Button
                type="primary"
                htmlType="submit"
                disabled={saveDisabled || isLoading}
                className="btn--green"
              >
                Save Changes
              </Button>
            </Form.Item>
          </div>
        </Form>
      </>
    );
  };

  const renderDangerZone = () => {
    if (currentOrgInfo === null || currentUserRoleOption === null) {
      return <div style={{ fontSize: 14 }}>Can't get organization info.</div>;
    }

    return (
      <>
        {!isOwner() && (
          <Alert
            message="Deleting is available only to the owner of the organization."
            type="info"
            showIcon
            style={{ marginBottom: 20 }}
            className={'org-settings-adaptive'}
          />
        )}
        <div className={'danger-zone danger-zone--org'}>
          <div className={'danger-zone__title'}>Danger Zone</div>
          <div className={'danger-zone__content'}>
            <div>Careful, these actions are not reversible!</div>
            <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
              <Button
                danger
                icon={<DeleteOutlined />}
                onClick={() => setDeleteModalIsOpen(true)}
                className={'danger-zone__button'}
                disabled={!isOwner()}
              >
                Delete Organization
              </Button>
              {currentOrgInfo &&
              !currentOrgInfo.isPersonal &&
              process.env.REACT_APP_DOMAIN === DEFAULT_ZAPPR_DOMAIN ? (
                <Button
                  icon={<ArrowRightOutlined />}
                  onClick={() => setTransferModalIsOpen(true)}
                  className={'danger-zone__button'}
                  disabled={!isOwner()}
                >
                  Transfer
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {renderUpdateDomain()}
      {renderDangerZone()}
      <TransferOrganizationModal
        organizationId={currentOrgInfo?.id || ''}
        isOpen={transferModalIsOpen}
        setIsOpen={setTransferModalIsOpen}
      />
    </>
  );
};

export default OrgSettings;
