import { IConversationsParams } from '../../../types/IConversationsParams';

export enum ConversationActionParamOptions {
  ALL = 'all',
  REPLIED_BY_USER = 'repliedByUser',
  UNREPLIED_BY_USER = 'unRepliedByUser',
}

export enum ConversationFilterParamKeys {
  FROM = 'from',
  TO = 'to',
  ACTION = 'action',
  THREAD_CONTROL = 'thread-control',
  REVIEW_STATUS = 'review-status',
  OFFSET = 'offset',
  LIMIT = 'limit',
}

export enum ConversationSearchParams {
  FROM = 'from',
  TO = 'to',
  ACTION = 'action',
  THREAD_CONTROL = 'thread-control',
  REVIEW_STATUS = 'review-status',
  PAGE = 'page',
}

export enum ConversationThreadControlParamOptions {
  ALL = 'all',
  BOT = 'bot',
  LIVE_AGENT = 'liveAgent',
}

export enum ConversationReviewStatusParamOptions {
  ALL = 'all',
  READ = 'read',
  UNREAD = 'unRead',
  REVIEWED = 'reviewed',
  FOR_REVIEW = 'forReview',
}

export enum ConversationMarkOptions {
  READ = 'read',
  UNREAD = 'unread',
}

export enum ConversationReviewOptions {
  REVIEW = 'review',
  FOR_REVIEW = 'forReview',
}

export const convsFilterActionOptions = [
  {
    value: ConversationActionParamOptions.ALL,
    label: 'all',
  },
  {
    value: ConversationActionParamOptions.REPLIED_BY_USER,
    label: 'replied by user',
  },
  {
    value: ConversationActionParamOptions.UNREPLIED_BY_USER,
    label: 'unreplied by user',
  },
];

export const convsFilterThreadControlOptions = [
  {
    value: ConversationThreadControlParamOptions.ALL,
    label: 'all',
  },
  {
    value: ConversationThreadControlParamOptions.BOT,
    label: 'AI',
  },
  {
    value: ConversationThreadControlParamOptions.LIVE_AGENT,
    label: 'human',
  },
];

export const convsFilterReviewStatusOptions = [
  {
    value: ConversationReviewStatusParamOptions.ALL,
    label: 'all',
  },
  {
    value: ConversationReviewStatusParamOptions.READ,
    label: 'read',
  },
  {
    value: ConversationReviewStatusParamOptions.UNREAD,
    label: 'unread',
  },
  {
    value: ConversationReviewStatusParamOptions.REVIEWED,
    label: 'reviewed',
  },
  {
    value: ConversationReviewStatusParamOptions.FOR_REVIEW,
    label: 'for review',
  },
];

export const getCurrentPageFromSearchParams = (
  currentPage: string | null
): number => {
  if (!currentPage) return 1;
  const page = parseInt(currentPage);
  return isNaN(page) ? 1 : page;
};

export const getConversationFiltersParams = (
  searchParams: URLSearchParams,
  itemsPerPage: number
): IConversationsParams => {
  const fromValue = searchParams.get(ConversationSearchParams.FROM) || null;
  const toValue = searchParams.get(ConversationSearchParams.TO) || null;
  const actionValue =
    (searchParams.get(
      ConversationSearchParams.ACTION
    ) as ConversationActionParamOptions) || null;
  const threadControlValue =
    (searchParams.get(
      ConversationSearchParams.THREAD_CONTROL
    ) as ConversationThreadControlParamOptions) || null;
  const reviewStatusValue =
    (searchParams.get(
      ConversationSearchParams.REVIEW_STATUS
    ) as ConversationReviewStatusParamOptions) ||
    ConversationReviewStatusParamOptions.ALL;
  const currentPage = getCurrentPageFromSearchParams(
    searchParams.get(ConversationSearchParams.PAGE)
  );
  const offsetValue = (currentPage - 1) * itemsPerPage;

  return {
    from: fromValue
      ? {
          key: ConversationFilterParamKeys.FROM,
          value: fromValue,
        }
      : null,
    to: toValue
      ? {
          key: ConversationFilterParamKeys.TO,
          value: toValue,
        }
      : null,
    action: actionValue
      ? {
          key: ConversationFilterParamKeys.ACTION,
          value: actionValue,
        }
      : null,
    threadControl: threadControlValue
      ? {
          key: ConversationFilterParamKeys.THREAD_CONTROL,
          value: threadControlValue,
        }
      : null,
    reviewStatus: {
      key: ConversationFilterParamKeys.REVIEW_STATUS,
      value: reviewStatusValue,
    },
    offset: { key: ConversationFilterParamKeys.OFFSET, value: offsetValue },
    limit: { key: ConversationFilterParamKeys.LIMIT, value: itemsPerPage },
  };
};

export const getThreadControlByReviewStatus = (
  value: ConversationReviewStatusParamOptions,
  currentThreadControlValue: ConversationThreadControlParamOptions
) => {
  const threadControl: {
    key: ConversationFilterParamKeys.THREAD_CONTROL;
    value: ConversationThreadControlParamOptions;
  } = {
    key: ConversationFilterParamKeys.THREAD_CONTROL,
    value: currentThreadControlValue,
  };

  switch (value) {
    case ConversationReviewStatusParamOptions.READ:
    case ConversationReviewStatusParamOptions.UNREAD:
      threadControl.value = ConversationThreadControlParamOptions.LIVE_AGENT;
      return threadControl;
    case ConversationReviewStatusParamOptions.REVIEWED:
    case ConversationReviewStatusParamOptions.FOR_REVIEW:
      threadControl.value = ConversationThreadControlParamOptions.BOT;
      return threadControl;
    default:
      return threadControl;
  }
};

export const getReviewStatusByThreadControl = (
  value: ConversationThreadControlParamOptions,
  currentReviewStatusValue: ConversationReviewStatusParamOptions
) => {
  const reviewStatus: {
    key: ConversationFilterParamKeys.REVIEW_STATUS;
    value: ConversationReviewStatusParamOptions;
  } = {
    key: ConversationFilterParamKeys.REVIEW_STATUS,
    value: currentReviewStatusValue,
  };

  switch (value) {
    case ConversationThreadControlParamOptions.BOT:
      reviewStatus.value = ConversationReviewStatusParamOptions.FOR_REVIEW;
      return reviewStatus;
    case ConversationThreadControlParamOptions.LIVE_AGENT:
      reviewStatus.value = ConversationReviewStatusParamOptions.UNREAD;
      return reviewStatus;
    default:
      return reviewStatus;
  }
};
