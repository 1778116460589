import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Input, Spin } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RouteNames } from '../../../router/router';
import IConfirmEmail from '../../../types/IConfirmEmail';
import PageNotExist from '../../../components/page-not-exist/page-not-exist';
import Loading from '../../../components/loading/loading';
import '../verify-confirm-email.scss';
import './reset-password.scss';
import { usersAPI } from '../../../services/users-service';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { passwordRules } from '../../../shared/rules';
import { Rule } from 'antd/es/form';

interface IResetPasswordForm {
  newPassword: string;
  confirmNewPassword: string;
}

// TODO: refactor one day. maybe
let isResetPasswordComponent = false;

const ResetPassword: FC = () => {
  const [resetPassword, { isLoading, error }] =
    usersAPI.useResetPasswordMutation();
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [resetDisabled, setResetDisabled] = useState<boolean>(true);
  const [userData, setUserData] = useState<IConfirmEmail | null>({
    email: '',
    token: '',
  });
  const [form] = Form.useForm();
  // const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    const email = searchParams.get('email');
    const token = searchParams.get('token');

    if (email && token) {
      const emailReplace = email.replaceAll(' ', '+');
      const tokenReplace = token.replaceAll(' ', '+');
      setUserData({ email: emailReplace, token: tokenReplace });
    } else {
      setUserData(null);
      navigate(RouteNames.PAGE_NOT_EXIST);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!userData) return;
    if (!userData.email || !userData.token) return;
    setComponent(resetPasswordComponent);
    isResetPasswordComponent = true;
  }, [userData]);

  // useEffect(() => {
  //   if (
  //     error &&
  //     'data' in error &&
  //     error.data &&
  //     'message' in (error.data as { message: string })
  //   ) {
  //     setErrorMessage((error.data as { message: string }).message);
  //   }
  // }, [error]);

  // useEffect(() => {
  //   if (error) {
  //     let errorMsg = '';
  //     if (
  //       error &&
  //       'data' in error &&
  //       error.data &&
  //       'message' in (error.data as { message: string })) {
  //       errorMsg = (error.data as { message: string }).message;
  //     }
  //     setComponent(resetPasswordError(errorMsg));
  //     isResetPasswordComponent = false;
  //   }
  // }, [error]);

  const handleChange = () => {
    const values = form.getFieldsValue() as IResetPasswordForm;

    let disabled = false;

    if (!values.newPassword || !values.confirmNewPassword) {
      disabled = true;
    }

    if (values.newPassword !== values.confirmNewPassword) {
      disabled = true;
    }

    setResetDisabled(disabled);
  };

  const handleResetPassword = async () => {
    if (!userData) return;
    if (!userData.email || !userData.token) return;

    setResetDisabled(true);
    const result = await resetPassword({
      email: userData.email,
      token: userData.token,
      newPassword: form.getFieldValue('newPassword'),
    });

    if ('error' in result) {
      let errorMsg = '';
      if (
        result.error &&
        'data' in result.error &&
        result.error.data &&
        'message' in (result.error.data as { message: string })
      ) {
        errorMsg = (result.error.data as { message: string }).message;
      }
      setComponent(resetPasswordError(errorMsg));
      isResetPasswordComponent = false;
    } else {
      // TODO: add GA EVENT?
      setComponent(resetPasswordSuccess);
      isResetPasswordComponent = false;
    }
    // setForgotDisabled(false);
  };

  const loadingComponent = () => <Spin size="large" tip="Loading..." />;
  const [component, setComponent] = useState<JSX.Element>(loadingComponent);

  const confirmPasswordRules: Rule[] = [
    ...passwordRules,
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue('newPassword') === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Password mismatch.'));
      },
    }),
  ];

  const resetPasswordComponent = () => (
    <>
      <div className="verify-email-title reset-password-title">
        Reset password
      </div>
      {/*<div>*/}
      {/*  Enter new password.*/}
      {/*</div>*/}
      <Form
        name="forgot-password"
        className="login-form reset-password-form"
        onFinish={handleResetPassword}
        form={form}
        onChange={handleChange}
        layout={'vertical'}
        requiredMark={false}
      >
        <Form.Item
          label="New password"
          name="newPassword"
          rules={passwordRules}
          hasFeedback
        >
          <Input.Password placeholder="Input new password" />
        </Form.Item>
        <Form.Item
          label="Confirm new password"
          name="confirmNewPassword"
          dependencies={['newPassword']}
          rules={confirmPasswordRules}
        >
          <Input.Password placeholder="Confirm new password" />
        </Form.Item>
      </Form>
    </>
  );

  const resetPasswordSuccess = () => (
    <div className={'reset-password-result'}>
      <CheckCircleFilled className="verify-email-icon confirm-email-icon confirm-email-icon--success" />
      <div className="verify-email-title">Password updated</div>
      <div>
        Your password has been updated.
        <br />
        You can now login to the application.
      </div>

      {/*<Button type='primary' onClick={resendEmail} loading={isLoading} disabled={resendDisabled}>*/}
      <Button type="primary" onClick={() => navigate(RouteNames.LOGIN)}>
        Go to login
      </Button>
    </div>
  );

  const resetPasswordError = (error: string) => (
    <div className={'reset-password-result'}>
      <CloseCircleFilled className="verify-email-icon confirm-email-icon confirm-email-icon--error" />
      <div className="verify-email-title">
        Oh no!
        <br />
        Something went wrong.
      </div>
      <div>{error}</div>
      <Button type="primary" onClick={() => navigate(RouteNames.LOGIN)}>
        Go to login
      </Button>
    </div>
  );

  return (
    <div className="no-header-container">
      {/*{contextHolder}*/}
      <div className="container">
        <div className="forgot-password__inner reset-password__inner">
          {userData ? (
            component
          ) : !searchParams.get('email') || searchParams.get('token') ? (
            <PageNotExist />
          ) : (
            <Loading />
          )}
          {isResetPasswordComponent && (
            <Button
              type="primary"
              loading={isLoading}
              className="login-form-button login-btn reset-btn"
              disabled={resetDisabled}
              onClick={handleResetPassword}
            >
              Reset password
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
