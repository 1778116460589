import React, { FC } from 'react';
import { Divider } from 'antd';
import TextInputFormItem from './form-items/text-input-form-item';
import { EmailLabel } from '../../../../types/contacts/contact-records/IEmailRecord';
import FormUtils from '../../../../shared/utils/form-utils';
import LabelFormItem from './form-items/label-form-item';
import IInitialLabelContactRecordValue from '../../../../types/contacts/IInitialLabelContactRecordValue';

interface IEmailRecordFormElementProps {
  initialLabelValue: IInitialLabelContactRecordValue;
}

const EmailRecordFormElement: FC<IEmailRecordFormElementProps> = ({
  initialLabelValue,
}) => {
  return (
    <>
      <Divider orientation="left" className={'contact-form-divider'}>
        Email
      </Divider>
      <TextInputFormItem
        label={'Email Address'}
        name={'emailAddress'}
        placeholder={'Email Address'}
      />
      <div style={{ display: 'flex', gap: 24 }}>
        <LabelFormItem
          label={'Label'}
          name={'label'}
          options={FormUtils.createSelectOptionsFromEnum(EmailLabel)}
          initialValue={initialLabelValue}
        />
      </div>
    </>
  );
};

export default EmailRecordFormElement;
