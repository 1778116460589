import React, { useEffect, useState } from 'react';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Spin } from 'antd';
import PageNotExist from '../../components/page-not-exist/page-not-exist';
import Loading from '../../components/loading/loading';
import { Link, useSearchParams } from 'react-router-dom';
import { RouteNames } from '../../router/router';
import ICustomErrorResponse from '../../types/ICustomErrorResponse';
import { usersAPI } from '../../services/users-service';

const UnsubscribeEmails = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [unsubscribeEmails, { data, isLoading, error }] =
    usersAPI.useUnsubscribeEmailsMutation();
  const [userData, setUserData] = useState<{ email: string } | null>({
    email: '',
  });
  const [errorMessage, setErrorMessage] = useState<string>(
    'Please try again later.'
  );

  useEffect(() => {
    const email = searchParams.get('email');

    if (email) {
      const emailReplace = email.replaceAll(' ', '+');
      setUserData({ email: emailReplace });
    } else {
      setUserData(null);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!userData) return;
    if (!userData.email) return;
    unsubscribeEmailsHandler();
  }, [userData]);

  useEffect(() => {
    if (
      error &&
      'data' in error &&
      error.data &&
      'message' in (error.data as { message: string })
    ) {
      setErrorMessage((error.data as { message: string }).message);
    }
  }, [error]);

  useEffect(() => {
    if (error) {
      setComponent(unsubscribeError);
    }
  }, [errorMessage]);

  const unsubscribeEmailsHandler = async () => {
    if (!userData) return;

    const result = await unsubscribeEmails(userData);

    if ('error' in result) {
      setComponent(unsubscribeError);
    } else {
      setComponent(unsubscribeSuccess);
    }
  };

  const loadingComponent = () => <Spin size="large" tip="Loading..." />;
  const [component, setComponent] = useState<JSX.Element>(loadingComponent);

  const unsubscribeSuccess = () => {
    return (
      <>
        <CheckCircleFilled className="verify-email-icon confirm-email-icon confirm-email-icon--success" />
        <div className="verify-email-title">Unsubscribed</div>
        <div>You have successfully unsubscribed from our emails. :(</div>
        <Link to={RouteNames.LANDING} className="verify-link">
          Go to main page
        </Link>
      </>
    );
  };

  const unsubscribeError = () => {
    return (
      <>
        <CloseCircleFilled className="verify-email-icon confirm-email-icon confirm-email-icon--error" />
        <div className="verify-email-title">Unsubscribe error</div>
        <div>
          Oh no!
          <br />
          Something went wrong.
          <br />
          {errorMessage}
        </div>
        <Link to={RouteNames.LANDING} className="verify-link">
          Go to main page
        </Link>
      </>
    );
  };

  return (
    <div className="no-header-container">
      <div className="container">
        <div className="verify-email">
          {userData ? (
            component
          ) : !searchParams.get('email') ? (
            <PageNotExist />
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </div>
  );
};

export default UnsubscribeEmails;
