import { apiService, APITagTypes } from './api-service';
import IProject from '../types/IProject';
import IWhatsAppOutboundSettings from '../types/IWhatsAppOutboundSettings';
import ICampaignsFilter from '../types/filters/ICampaignsFilter';
import { ITableT } from '../types/tables-service/ITableT';
import { TableImportOptions, X_ORG_ID_HEADER } from '../shared/constants';
import ICampaignsOptions, {
  ICampaign,
} from '../types/whats-app-outbound-service/ICampaignsOptions';
import IWATemplateData from '../types/whats-app-outbound-service/IWATemplateData';
import ICreateCampaign from '../types/whats-app-outbound-service/ICreateCampaign';

const getCampaignFilterParamsString = (filters: ICampaignsFilter[]) => {
  if (!filters.length) {
    return '';
  }

  let result = '';

  for (const filter of filters) {
    result += `${filter.filterName}=${filter.value}&${filter.operatorName}=${filter.operator}&`;
  }

  return result;
};

export const whatsAppOutboundApi = apiService.injectEndpoints({
  endpoints: (build) => ({
    createCampaign: build.mutation<
      IWhatsAppOutboundSettings,
      { projectId: string; body: ICreateCampaign }
    >({
      query: ({ projectId, body }) => ({
        url: `/projects/${projectId}/outbound/twilio`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(body),
      }),
      invalidatesTags: () => [APITagTypes.WHATS_APP_OUTBOUND_CAMPAIGNS],
    }),
    getCampaigns: build.query<
      ICampaign,
      {
        projectId: string;
        orgId: string | undefined;
        params: {
          offset: number;
          limit: number;
          filterValues: ICampaignsFilter[];
        };
      }
    >({
      query: ({ projectId, orgId, params }) => ({
        url:
          `/projects/${projectId}/outbound/twilio?offset=${params.offset}&limit=${params.limit}&` +
          getCampaignFilterParamsString(params.filterValues),
        method: 'GET',
        headers: orgId ? { [X_ORG_ID_HEADER]: orgId } : {},
      }),
      providesTags: () => [APITagTypes.WHATS_APP_OUTBOUND_CAMPAIGNS],
    }),
    getCampaignsSetUpOptions: build.query<
      ICampaignsOptions,
      { projectId: string }
    >({
      query: ({ projectId }) => ({
        url: `/projects/${projectId}/outbound/twilio/options`,
        method: 'GET',
      }),
    }),
    getCampaignsTemplatePreview: build.query<
      IWATemplateData,
      {
        projectId: string;
        templateId: string | undefined;
      }
    >({
      query: ({ projectId, templateId }) => ({
        url: `/projects/${projectId}/outbound/twilio/template/${templateId}/preview`,
        method: 'GET',
      }),
    }),
    getCampaignInfo: build.query<
      IWhatsAppOutboundSettings,
      { projectId: string; orgId: string | undefined; campaignId: string }
    >({
      query: ({ projectId, orgId, campaignId }) => ({
        url: `/projects/${projectId}/outbound/twilio/${campaignId}`,
        method: 'GET',
        headers: orgId ? { [X_ORG_ID_HEADER]: orgId } : {},
      }),
    }),
    createTwilioTemplate: build.mutation<
      IProject,
      {
        projectId: string;
        body: any;
      }
    >({
      query: ({ projectId, body }) => ({
        url: `/projects/${projectId}/outbound/twilio/template`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(body),
      }),
    }),
    deleteTemplate: build.mutation<
      IWhatsAppOutboundSettings,
      { projectId: string; templateId: string }
    >({
      query: ({ projectId, templateId }) => ({
        url: `/projects/${projectId}/outbound/twilio/template/${templateId}`,
        method: 'DELETE',
      }),
    }),
    importTableW: build.mutation<
      ITableT,
      {
        projectId: string;
        campaignId: string;
        importOption: TableImportOptions;
        body: FormData;
      }
    >({
      query: ({ projectId, campaignId, importOption, body }) => ({
        url: `/projects/${projectId}/outbound/twilio/${campaignId}/import?import-options=${importOption}`,
        method: 'POST',
        headers: {
          'content-type': undefined,
        },
        body: body,
      }),
    }),
    updateCampaignInfo: build.mutation<
      IWhatsAppOutboundSettings,
      { projectId: string; campaignId: string; body: IWhatsAppOutboundSettings }
    >({
      query: ({ projectId, campaignId, body }) => ({
        url: `/projects/${projectId}/outbound/twilio/${campaignId}`,
        method: 'PUT',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(body),
      }),
      invalidatesTags: () => [APITagTypes.WHATS_APP_OUTBOUND_SETTINGS],
    }),
    validateW: build.mutation<
      IProject,
      { projectId: string; campaignId: string }
    >({
      query: ({ projectId, campaignId }) => ({
        url: `/projects/${projectId}/outbound/twilio/${campaignId}/validate`,
        method: 'POST',
      }),
      invalidatesTags: () => [APITagTypes.WHATS_APP_OUTBOUND_SETTINGS],
    }),
    runW: build.mutation<IProject, { projectId: string; campaignId: string }>({
      query: ({ projectId, campaignId }) => ({
        url: `/projects/${projectId}/outbound/twilio/${campaignId}/run`,
        method: 'POST',
      }),
      invalidatesTags: () => [APITagTypes.WHATS_APP_OUTBOUND_SETTINGS],
    }),
    stopW: build.mutation<IProject, { projectId: string; campaignId: string }>({
      query: ({ projectId, campaignId }) => ({
        url: `/projects/${projectId}/outbound/twilio/${campaignId}/stop`,
        method: 'POST',
      }),
      invalidatesTags: () => [APITagTypes.WHATS_APP_OUTBOUND_SETTINGS],
    }),
    deleteCampaign: build.mutation<
      IWhatsAppOutboundSettings,
      { projectId: string; campaignId: string }
    >({
      query: ({ projectId, campaignId }) => ({
        url: `/projects/${projectId}/outbound/twilio/${campaignId}`,
        method: 'DELETE',
      }),
    }),
    archiveCampaign: build.mutation<
      IWhatsAppOutboundSettings,
      { projectId: string; campaignId: string }
    >({
      query: ({ projectId, campaignId }) => ({
        url: `/projects/${projectId}/outbound/twilio/${campaignId}/archive`,
        method: 'POST',
      }),
    }),
  }),
});
