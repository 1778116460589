import { FC, useEffect, useState } from 'react';
import { Button, Result } from 'antd';
import { subscriptionAPI } from '../../services/subscription-service';
import { useAppSelector } from '../../hooks/redux-hooks';

const UserBlocked: FC = () => {
  const { contactEmail } = useAppSelector((state) => state.domainsReducer);

  const {
    data: enterprisePaymentURL,
    isLoading: enterprisePaymentURLLoading,
    error: enterprisePaymentURLError,
  } = subscriptionAPI.useGetEnterprisePaymentURLQuery();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleClick = () => {
    setIsButtonDisabled(true);

    if (enterprisePaymentURL) {
      window.location.href = enterprisePaymentURL;
    }
  };

  return (
    <div className="no-header-container">
      <Result
        status="404"
        title={
          <div style={{ fontSize: '16px' }}>
            Unfortunately, your account has been blocked.
            <br />
            For more information, please contact us by email.
          </div>
        }
        subTitle=""
        extra={
          <div>
            <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
          </div>
        }
      />
    </div>
  );
};

export default UserBlocked;
