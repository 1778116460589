import { FC, useEffect, useState } from 'react';
import { chartColors } from '../../charts/chart-data';
import AnalyticsUtils from '../../shared/analytics/analytics-utils';
import { Skeleton } from 'antd';
import { Line } from 'recharts';
import { LineChartContainer } from '../../shared/analytics/analytics-container-line-component';
import { IAnalyticsEventsData } from './analytics-data';
import { IInsightsQueryResult } from '../../../services/usage-service';

const PerDayLineChart: FC<{
  data: IAnalyticsEventsData[];
  dynamicAnalyticsList: IAnalyticsEventsData[];
  queryResult: IInsightsQueryResult | null;
  isLoading: boolean;
}> = ({ data, dynamicAnalyticsList, queryResult, isLoading }) => {
  const initialChartsData: any[] = [];
  const [chartsData, setChartsData] = useState(initialChartsData);

  useEffect(() => {
    if (queryResult) {
      const today = new Date();
      const todayUTC = new Date(
        Date.UTC(
          today.getUTCFullYear(),
          today.getUTCMonth(),
          today.getUTCDate()
        )
      );
      const tableData = AnalyticsUtils.getTableData(queryResult);
      const fromDate = new Date(queryResult.from);
      const toDate = new Date(queryResult.to);
      const newChartData: any[] = [];
      let currentDate = fromDate;

      while (currentDate <= toDate) {
        const day = currentDate.getUTCDate().toString();
        const entry: any = { name: day };

        data.forEach((e) => {
          const record = tableData.find((x) => {
            let segment = '';
            let segmentedEventName = '';

            if (x.segment) {
              segment = x.segment;
              segmentedEventName = x.name + `_(${segment})`;
            }
            const utcDate = new Date(
              Date.UTC(
                currentDate.getUTCFullYear(),
                currentDate.getUTCMonth(),
                currentDate.getUTCDate()
              )
            );

            if (segment) {
              return (
                new Date(x['timestamp']).getTime() == utcDate.getTime() &&
                segmentedEventName === e.eventName
              );
            }

            return (
              new Date(x['timestamp']).getTime() == utcDate.getTime() &&
              x.name === e.eventName
            );
          });

          if (record) {
            entry[e.eventName] = record['count'];
          } else {
            const utcDate = new Date(
              Date.UTC(
                currentDate.getUTCFullYear(),
                currentDate.getUTCMonth(),
                currentDate.getUTCDate()
              )
            );

            if (utcDate.getTime() <= todayUTC.getTime()) {
              entry[e.eventName] = 0;
            }

            //entry[e.eventName] = 0;
          }
        });

        newChartData.push(entry);
        currentDate.setDate(currentDate.getDate() + 1);
      }

      setChartsData(newChartData);
    }
  }, [queryResult, data]);

  const getColor = (eventName: string) => {
    const index = dynamicAnalyticsList.findIndex(
      (data) => data.eventName === eventName
    );
    return chartColors[index];
  };

  const renderLines = () => {
    return data.map((e, i) => (
      <Line
        key={e.eventName}
        name={e.chartName}
        type="monotone"
        dataKey={e.eventName}
        stroke={getColor(e.eventName)}
        strokeWidth={2}
        activeDot={{ r: 8 }}
      />
    ));
  };

  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          {' '}
          <LineChartContainer
            chartData={chartsData}
            xAxisKey="name"
            headerContent={
              <div className={'analytics__chart-title'}>Widget analytics</div>
            }
          >
            {renderLines()}
          </LineChartContainer>
        </>
      )}
    </>
  );
};

export default PerDayLineChart;
