import React, { Dispatch, FC, SetStateAction } from 'react';
import { Modal } from 'antd';
import IProject from '../../../types/IProject';

interface ILeavePageConfirmModal {
  isOpen: boolean;
  onCancel: () => void;
  onOk: () => void;
}

const LeavePageConfirmModal: FC<ILeavePageConfirmModal> = ({
  isOpen,
  onOk,
  onCancel,
}) => {
  return (
    <Modal
      title="Confirm leaving page"
      open={isOpen}
      onOk={onOk}
      onCancel={onCancel}
      wrapClassName={'modal'}
      centered={true}
    >
      <p>
        You have unsaved data. Are you sure you want to leave this page? Your
        changes will be lost.
      </p>
    </Modal>
  );
};

export default LeavePageConfirmModal;
