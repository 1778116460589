import React, { FC } from 'react';
import { Button, Result } from 'antd';
import { RouteNames } from '../../router/router';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../hooks/redux-hooks';

const PageNotExist: FC = () => {
  const { isAuth } = useAppSelector((state) => state.userReducer);
  const navigate = useNavigate();

  return (
    <div className="no-header-container">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button
            type="primary"
            onClick={() =>
              isAuth
                ? navigate(RouteNames.USER_PROJECTS)
                : navigate(RouteNames.LOGIN)
            }
          >
            Back Home
          </Button>
        }
      />
    </div>
  );
};

export default PageNotExist;
