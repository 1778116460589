import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, message } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import '../auth.scss';
import { usersAPI } from '../../../services/users-service';
import IAuthForm from '../../../types/IAuthForm';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { RouteNames } from '../../../router/router';
import {
  getDomain,
  isClientDomain,
  showErrorMessage,
} from '../../../shared/helpers';
import { useAppSelector } from '../../../hooks/redux-hooks';
import {
  ErrorCodes,
  LogoMode,
  V_REQUIRED_FIELD,
} from '../../../shared/constants';
import { GA_LOGIN } from '../../../shared/google-analytics';
import ICustomErrorResponse from '../../../types/ICustomErrorResponse';
import { GoogleLogin } from '@react-oauth/google';
import IAuthResponse from '../../../types/IAuthResponse';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';
import LogoIcon from '../../../components/logo/logo-icon';
import LogoText from '../../../components/logo/logo-text';

interface ILoginResponse {
  data?: IAuthResponse;
  error?: FetchBaseQueryError | SerializedError;
}

const Login = () => {
  const { isAuth } = useAppSelector((state) => state.userReducer);
  const [login, { isLoading }] = usersAPI.useAuthMutation();
  const [googleLogin] = usersAPI.useGoogleAuthMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [loginDisabled, setLoginDisabled] = useState(true);
  const [formIsTouched, setFormIsTouched] = useState(false);

  useEffect(() => {
    if (isAuth) {
      redirect();
    }
  }, [isAuth]);

  const redirect = () => {
    navigate(
      (location && location.state && location.state.from) ||
        RouteNames.USER_PROJECTS
    );
  };

  const handleChange = async () => {
    try {
      await form.validateFields();
      setLoginDisabled(false);
    } catch (errors) {
      setLoginDisabled(true);
    }
  };

  const handleGoogleLogin = async (credentialResponse: any) => {
    const result = await googleLogin({ token: credentialResponse.credential });
    let email = '';

    try {
      const decoded: { email: string } = jwtDecode(
        credentialResponse.credential
      );
      email = decoded.email;
    } catch (e) {}

    handleLoginResponse(result, email);
  };

  const handleGoogleFail = async () => {
    messageApi.error('Google Login Failed.');
  };

  const handleLoginResponse = (response: ILoginResponse, email: string) => {
    if (response && 'error' in response && response.error) {
      if (
        'data' in response.error &&
        'code' in (response.error.data as ICustomErrorResponse) &&
        (response.error.data as ICustomErrorResponse).code ===
          ErrorCodes.EMAIL_NOT_CONFIRMED
      ) {
        navigate(RouteNames.VERIFY_EMAIL, { state: { email: email } });
      } else {
        showErrorMessage(messageApi, response.error, 10);
      }
    } else {
      GA_LOGIN();
      redirect();
    }
  };

  const handleLogin = async (formData: IAuthForm) => {
    setLoginDisabled(true);
    const result = await login({
      email: formData.email,
      password: formData.password,
    });

    handleLoginResponse(result, formData.email);

    setLoginDisabled(false);
  };

  const logInWithGoogleAndRegister = () => {
    if (process.env.REACT_APP_SHOW_REGISTER === 'false' || isClientDomain()) {
      return null;
    }

    return (
      <>
        <div>
          <GoogleLogin
            context={'signin'}
            onSuccess={handleGoogleLogin}
            onError={handleGoogleFail}
            size={'medium'}
            locale={'en_GB'}
            width={315}
          />
        </div>

        <div style={{ marginTop: 10 }}>
          Or{' '}
          <Link to={RouteNames.REGISTER} className="login-link">
            Register now!
          </Link>
        </div>
      </>
    );
  };

  return (
    <div className="login">
      {contextHolder}
      <div className="container">
        <div className="login__inner">
          <div
            className="login__logo"
            onClick={() => {
              window.location.href = 'https://' + getDomain();
            }}
          >
            <LogoIcon mode={LogoMode.DARK} />
            <LogoText mode={LogoMode.DARK} />
          </div>
          <div className={'auth-subtitle'}>Log in into your account</div>

          <Form
            name="login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={handleLogin}
            form={form}
            onChange={handleChange}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'Enter a valid E-mail.',
                },
                {
                  required: true,
                  message: V_REQUIRED_FIELD,
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="E-mail"
                onChange={() => setFormIsTouched(true)}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: V_REQUIRED_FIELD,
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type={formIsTouched ? 'password' : 'text'}
                placeholder="Password"
                onChange={() => setFormIsTouched(true)}
              />
            </Form.Item>
            <div className="remember-me">
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <div>
                <Link
                  to={RouteNames.FORGOT_PASSWORD}
                  className="login-form-forgot login-link"
                >
                  Forgot password
                </Link>
              </div>
            </div>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button login-btn"
                loading={isLoading}
                disabled={loginDisabled}
              >
                Log in
              </Button>
            </Form.Item>

            {logInWithGoogleAndRegister()}
          </Form>

          {/*<div className='register-now'>*/}
          {/*  Don’t have an account-setting yet? <a href='' className='login-link'>Sign Up!</a>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

export default Login;
