import React, { FC, useRef } from 'react';
import { LogoMode } from '../../shared/constants';
import { getDomainForImage } from '../../shared/helpers';
import { useAppSelector } from '../../hooks/redux-hooks';

const LogoIcon: FC<{ mode: LogoMode }> = ({ mode }) => {
  const { globalTimestamp } = useAppSelector((state) => state.domainsReducer);
  const { filePath } = useAppSelector((state) => state.domainsReducer);
  const logoRef = useRef<HTMLDivElement>(null);

  return filePath ? (
    <div className="custom-logo" ref={logoRef}>
      <img
        className={'custom-logo-icon'}
        src={`https://static.${getDomainForImage()}/static/${filePath}logo-${mode}.svg?timestamp=${globalTimestamp}`}
        onError={() => {
          if (logoRef && logoRef.current) {
            logoRef.current.style.display = 'none';
          }
        }}
      />
    </div>
  ) : null;
};

export default LogoIcon;
