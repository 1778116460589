import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { plansAPI } from '../../services/plans-service';
import IPlan from '../../types/IPlan';

interface IPlanState {
  plans: IPlan[];
}

const initialState: IPlanState = {
  plans: [],
};

export const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      plansAPI.endpoints.getPlans.matchFulfilled,
      (state, action: PayloadAction<IPlan[]>) => {
        state.plans = action.payload;
      }
    );
  },
});

export default plansSlice.reducer;
