import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'antd';
import { IContactRecord } from '../../../../types/contacts/IContact';

interface IDeleteContactRecordModalProps {
  deleteContactRecord: IContactRecord | null;
  setDeleteContactRecord: React.Dispatch<
    React.SetStateAction<IContactRecord | null>
  >;
  onDeleteConfirm: () => Promise<void>;
}

const DeleteContactRecordModal: FC<IDeleteContactRecordModalProps> = ({
  deleteContactRecord,
  setDeleteContactRecord,
  onDeleteConfirm,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [deleteDisabled, setDeleteDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (deleteContactRecord) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [deleteContactRecord]);

  const handleCancel = () => {
    setDeleteContactRecord(null);
  };

  const handleOk = async () => {
    setDeleteDisabled(true);
    await onDeleteConfirm();
    setDeleteDisabled(false);
  };

  return (
    <Modal
      wrapClassName={'modal'}
      title="Delete contact record"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{
        disabled: deleteDisabled,
        danger: true,
        loading: deleteDisabled,
      }}
      centered={true}
    >
      <p>
        Please confirm the deletion of this contact record. Note that this will
        permanently erase all associated information. This action cannot be
        undone.
      </p>
    </Modal>
  );
};

export default DeleteContactRecordModal;
