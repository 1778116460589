import { Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import LexicalField from './lexical-field';
import React from 'react';
import IProject from '../../types/IProject';
import {
  ILexicalCommandModalFormItemProps,
  ISetLexicalCommandModalProps,
} from './lexical-command-props-modal';
import IProjectPromptsCommandsTemplates from '../../types/IProjectPromptsCommandsTemplates';
import { CHIPS_KEYWORDS } from './lexical-keywords-plugin';

export const convertCamelCaseToSnakeCase = (text: string) => {
  return text.replace(/\{\{(\w+?)\}\}/g, (match, p1) => {
    const snakeCase = p1.replace(/([A-Z])/g, '_$1').toLowerCase();
    const isExists = CHIPS_KEYWORDS.includes(snakeCase);

    if (!isExists) {
      return `{{${p1}}}`;
    }

    return `{{${snakeCase}}}`;
  });
};

export const convertBracesToCamelCase = (text: string) => {
  return text.replace(/\{\{(\w+?)\}\}/g, (match, p1) => {
    const isExists = CHIPS_KEYWORDS.includes(p1.toLowerCase());

    if (!isExists) {
      return `{{${p1}}}`;
    }

    return (
      '{{' +
      p1
        .split('_')
        .map((word: string, index: number) => {
          if (index === 0) {
            return word.toLowerCase();
          } else {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          }
        })
        .join('') +
      '}}'
    );
  });
};

export const getLexicalPromptFormItem = (
  label: string,
  fieldName: string,
  editable: boolean,
  userProject: IProject | undefined,
  handleLexicalFieldChange: (fieldName: string, value: string) => void,
  modalProps: { title: string; options: ILexicalCommandModalFormItemProps[] },
  setModalProps: ISetLexicalCommandModalProps,
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  promptsCommandsTemplates: IProjectPromptsCommandsTemplates | undefined
) => {
  const initValue =
    userProject && userProject[fieldName as keyof typeof userProject];
  let initialText = initValue && typeof initValue === 'string' ? initValue : '';

  initialText = convertCamelCaseToSnakeCase(initialText);

  return (
    <div className={'advanced-form-item-container'}>
      <Form.Item label={label} name={fieldName}>
        <TextArea
          rows={10}
          disabled={!editable}
          className={'advanced-form-item--hidden-element'}
        />
      </Form.Item>
      <LexicalField
        initText={initialText}
        editable={editable}
        fieldName={fieldName}
        handleLexicalFieldChange={handleLexicalFieldChange}
        modalProps={modalProps}
        setModalProps={setModalProps}
        setModalIsOpen={setModalIsOpen}
        promptsCommandsTemplates={promptsCommandsTemplates}
      />
    </div>
  );
};
