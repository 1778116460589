import React, { FC } from 'react';
import { Form, Input } from 'antd';
import { Rule } from 'antd/es/form';

interface ITextInputFormItemProps {
  label: string;
  name: string;
  placeholder: string;
  rules?: Rule[] | undefined;
}

const TextInputFormItem: FC<ITextInputFormItemProps> = ({
  label,
  name,
  placeholder,
  rules,
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={rules}
      style={{ width: '100%' }}
    >
      <Input placeholder={placeholder} />
    </Form.Item>
  );
};

export default TextInputFormItem;
