import React, { FC } from 'react';
import { Checkbox, UploadFile } from 'antd';

import { ReactComponent as PaperClipIcon } from '../../../assets/icons/paper-clip-icon.svg';
import { ReactComponent as DeleteBasketIcon } from '../../../assets/icons/delete-basket.svg';

export interface CustomUploadFile extends UploadFile {
  checked?: boolean;
}

const UploadFileItem: FC<{
  file: CustomUploadFile;
  actions: {
    download: () => void;
    preview: () => void;
    remove: () => void;
  };
}> = ({ file, actions }) => {
  return (
    <div className="ant-upload-list-item ant-upload-list-item-undefined">
      <div className="ant-upload-icon">
        <span
          role="img"
          aria-label="paper-clip"
          className="anticon anticon-paper-clip"
        >
          <PaperClipIcon />
        </span>
      </div>
      <span className="ant-upload-list-item-name" title={file.name}>
        {file.name}
      </span>
      {file.type === 'text/csv' && (
        <Checkbox
          style={{ flex: '0 0 auto' }}
          onChange={(e) => {
            file.checked = e.target.checked;
          }}
        >
          Has a header
        </Checkbox>
      )}
      <span className="ant-upload-list-item-actions">
        <button
          title="Remove file"
          type="button"
          className="ant-btn css-dev-only-do-not-override-14y8qbt ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-action"
        >
          <span
            role="img"
            aria-label="delete"
            tabIndex={-1}
            className="anticon anticon-delete"
            onClick={actions.remove}
          >
            <DeleteBasketIcon />
          </span>
        </button>
      </span>
    </div>
  );
};

export default UploadFileItem;
