import { Dispatch, FC, SetStateAction, useState } from 'react';
import { message, Modal } from 'antd';
import { showErrorMessage } from '../../../shared/helpers';
import { whatsAppOutboundApi } from '../../../services/what-app-outbound-service';

interface IDeleteTemplateModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onRefresh: () => void;
  templateId: string;
  projectId: string;
}

const DeleteTemplateModal: FC<IDeleteTemplateModal> = ({
  isOpen,
  setIsOpen,
  onRefresh,
  templateId,
  projectId,
}) => {
  const [deleteTemplate, { isLoading }] =
    whatsAppOutboundApi.useDeleteTemplateMutation();
  const [messageApi, contextHolder] = message.useMessage();
  const [deleteDisabled, setDeleteDisabled] = useState(false);

  const handleCancel = (e: any) => {
    e.stopPropagation();
    setIsOpen(false);
  };

  const handleOk = async (e: any) => {
    e.stopPropagation();
    setDeleteDisabled(true);
    const result = await deleteTemplate({ projectId, templateId });

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
      setIsOpen(false);
    } else {
      await messageApi.success('The template has been deleted.', 1);
      onRefresh();
      setIsOpen(false);
    }

    setIsOpen(false);
    setDeleteDisabled(false);
  };

  return (
    <Modal
      wrapClassName={'modal'}
      title="Delete Messaging Template"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={isLoading}
      okButtonProps={{ disabled: deleteDisabled, danger: true }}
    >
      {contextHolder}
      <p>
        Please confirm the deletion of this template. Note that this will
        permanently erase all associated information. This action cannot be
        undone.
      </p>
    </Modal>
  );
};

export default DeleteTemplateModal;
