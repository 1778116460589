import React, { FC, useEffect, useState } from 'react';
import { Form, Modal } from 'antd';
import {
  ContactRecordType,
  IPostContactRecord,
} from '../../../../types/contacts/IContact';
import ContactsHelpers from '../contacts-helpers';

interface IAddContactModalProps {
  addRecordType: ContactRecordType | null;
  setAddRecordType: React.Dispatch<
    React.SetStateAction<ContactRecordType | null>
  >;
  onAddContactRecord: (record: IPostContactRecord) => Promise<void>;
}

const AddRecordModal: FC<IAddContactModalProps> = ({
  addRecordType,
  setAddRecordType,
  onAddContactRecord,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [okDisabled, setOkDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (addRecordType) {
      setIsOpen(true);
      form.setFieldsValue(
        ContactsHelpers.getNewRecordFormInitialValues(addRecordType)
      );
    } else {
      setIsOpen(false);
    }
  }, [addRecordType]);

  const handleCancel = () => {
    setAddRecordType(null);
  };

  const handleOk = async () => {
    if (!addRecordType) return;
    setOkDisabled(true);
    const values = form.getFieldsValue();
    await onAddContactRecord({ type: addRecordType, record: values });
    setOkDisabled(false);
    setAddRecordType(null);
    form.resetFields();
  };

  const getComponent = (addRecordType: ContactRecordType | null) => {
    if (!addRecordType) return null;

    return ContactsHelpers.getFormElement(addRecordType);
  };

  return (
    <Modal
      wrapClassName={'modal'}
      title="Add record"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered={true}
      okButtonProps={{ disabled: okDisabled, loading: okDisabled }}
      width={600}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={ContactsHelpers.getNewRecordFormInitialValues(
          addRecordType
        )}
      >
        {getComponent(addRecordType)}
      </Form>
    </Modal>
  );
};

export default AddRecordModal;
