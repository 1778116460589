import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { tablesApi } from '../../../../services/tables-service';
import { message, Modal } from 'antd';
import { showErrorMessage } from '../../../../shared/helpers';
import { Column } from 'react-data-grid';
import { ITableRowT, ITableT } from '../../../../types/tables-service/ITableT';

interface IDeleteTableTModal {
  projectId: string;
  tableData: ITableT | undefined | null;
  deleteColumnKey: string;
  setDeleteColumnKey: Dispatch<SetStateAction<string>>;
  setTableColumns: React.Dispatch<
    React.SetStateAction<Column<{ [key: string]: string }>[]>
  >;
  setTableRows: React.Dispatch<React.SetStateAction<ITableRowT[]>>;
  setDeleteColumnLoading: Dispatch<SetStateAction<boolean>>;
  deleteColumnCb: () => void;
}

const DeleteColumnTModal: FC<IDeleteTableTModal> = ({
  projectId,
  tableData,
  deleteColumnKey,
  setDeleteColumnKey,
  setTableColumns,
  setTableRows,
  setDeleteColumnLoading,
  deleteColumnCb,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [deleteColumnT] = tablesApi.useDeleteColumnTMutation();
  const [messageApi, contextHolder] = message.useMessage();
  const [deleteDisabled, setDeleteDisabled] = useState(false);

  useEffect(() => {
    if (deleteColumnKey) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [deleteColumnKey]);

  const handleCancel = () => {
    setDeleteColumnKey('');
  };

  const columnExists = () => {
    if (!tableData) return false;
    const tableExistingColumns = tableData.columns;
    return tableExistingColumns.some((col) => col.key === deleteColumnKey);
  };

  const handleOk = async () => {
    setDeleteColumnLoading(true);
    setDeleteDisabled(true);

    if (!tableData) {
      await deleteNewColumn();
    } else {
      if (columnExists()) {
        await deleteExistingColumn();
      } else {
        await deleteNewColumn();
      }
    }

    setDeleteColumnLoading(false);
  };

  const deleteNewColumn = async () => {
    setTableColumns((prev) => {
      const newColumns = prev.filter((col) => col.key !== deleteColumnKey);
      return newColumns;
    });
    setTableRows((prev) => {
      const newRows = prev.map((row) => {
        const newRow = { ...row };
        delete newRow[deleteColumnKey];
        return newRow;
      });
      return newRows;
    });
    await messageApi.success('The column has been deleted.', 1);
    setDeleteColumnKey('');
    setDeleteDisabled(false);
  };

  const deleteExistingColumn = async () => {
    if (!tableData) return;
    const res = await deleteColumnT({
      projectId,
      tableId: tableData.id,
      columnKey: deleteColumnKey,
    });

    if ('error' in res) {
      await showErrorMessage(messageApi, res.error);
    } else {
      await messageApi.success('The column has been deleted.', 1);
      deleteColumnCb();
      setDeleteColumnKey('');
      setDeleteDisabled(false);
    }
  };

  return (
    <Modal
      wrapClassName={'modal'}
      title="Delete column"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Delete"
      okButtonProps={{ disabled: deleteDisabled, danger: true }}
      centered={true}
    >
      {contextHolder}
      <p>
        Please confirm the deletion of this column. Note that this will
        permanently erase all associated information. This action cannot be
        undone.
      </p>
    </Modal>
  );
};

export default DeleteColumnTModal;
