import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import { CreditCardOutlined } from '@ant-design/icons';
import { subscriptionAPI } from '../../../services/subscription-service';
import IExtraCredits from '../../../types/IExtraCredits';
import { showErrorMessage } from '../../../shared/helpers';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import LogSlider, { sliderCurve } from '../../shared/log-slider';
import { useAppSelector } from '../../../hooks/redux-hooks';

const BuyExtraTokens: FC = () => {
  const { userPersonalSubscription } = useAppSelector(
    (state) => state.subscriptionReducer
  );
  const [extraCredits, setExtraCredits] = useState(
    getDefaultExtraCredits().toString()
  );
  const { data } = subscriptionAPI.useCalculateExtraPriceQuery(
    parseInt(extraCredits, 10) || 0
  );
  const [buyExtraTokens] = subscriptionAPI.useBuyExtraCreditsMutation();
  const [messageApi] = message.useMessage();
  const [cost, setCost] = useState(0);
  const [regularCost, setRegularCost] = useState(0);
  const [volumeDiscount, setVolumeDiscount] = useState<number>(0);
  const [partnerDiscount, setDiscount] = useState<number>(0);
  const [purchaseError, setPurchaseError] = useState<
    FetchBaseQueryError | SerializedError | null
  >(null);
  const [validationMessage, setValidationMessage] = useState('');
  const [discountMessage, setDiscountMessage] = useState('');

  const [form] = Form.useForm();

  function getDefaultExtraCredits() {
    let creditsInPlan = 0;
    if (userPersonalSubscription) {
      creditsInPlan = userPersonalSubscription.currentPlan.credits;
    }

    const extraCreditsByPlan = Math.round(creditsInPlan * 0.5);

    return Math.max(1000, extraCreditsByPlan);
  }

  useEffect(() => {
    if (userPersonalSubscription) {
      setExtraCredits(getDefaultExtraCredits().toString());
    }
  }, [userPersonalSubscription]);

  const handleCreditsChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let credits = e.target.value.replace(/[^0-9]/g, '');
    setExtraCredits(credits);

    const numericCredits = parseInt(credits, 10);

    if (numericCredits < 1000) {
      setValidationMessage('min. 1,000 credits');
      setDiscountMessage('');
    } else if (numericCredits > 100000) {
      setValidationMessage('max. 100,000 credits');
      setDiscountMessage('');
    } else {
      setValidationMessage('');
    }
  };

  useEffect(() => {
    if (data && userPersonalSubscription) {
      const totalPrice = parseFloat(data.totalPrice.toFixed(2));

      if (+extraCredits < 1000 || +extraCredits > 100000) {
        return;
      }

      setCost(totalPrice);
      setRegularCost(parseFloat(data.baseCost.toFixed(2)));
      setVolumeDiscount(Math.round(data.discount * 10000) / 100);

      if (userPersonalSubscription.discount)
        setDiscount(userPersonalSubscription.discount);

      setDiscountMessage('');
    }
  }, [data, validationMessage]);

  const handlePurchase = async () => {
    const creditsData: IExtraCredits = {
      amountTokens: +extraCredits,
    };

    setExtraCredits('');
    const result = await buyExtraTokens(creditsData);

    if (result) {
      if ('data' in result) {
        var url = result.data;
        window.location.href = url;
      } else if ('error' in result) {
        setPurchaseError(result.error);
      }
    }
  };

  useEffect(() => {
    if (purchaseError) {
      console.error(purchaseError);
      showErrorMessage(messageApi, purchaseError).then(() => {
        setPurchaseError(null);
      });
    }
  }, [messageApi, purchaseError]);

  const onSliderChange = (value: number) => {
    const roundedValue = Math.round(sliderCurve(value || 0));
    setExtraCredits(roundedValue.toString());
  };

  const renderDiscount = () => {
    if (!userPersonalSubscription?.discount) return null;

    return (
      <Form.Item label="Discount" style={{ marginBottom: '0px' }}>
        <div style={{ width: '100px', float: 'right', textAlign: 'right' }}>
          {partnerDiscount + '%'}
        </div>
      </Form.Item>
    );
  };

  return (
    <div className={'extra-credits-zone__container'}>
      <div className={'extra-credits-zone__title'}>Purchase Extra Credits</div>
      <div className={'extra-credits-zone__content'}>
        <Form form={form}>
          <Form.Item label="Extra credits" style={{ marginBottom: 30 }}>
            {validationMessage && (
              <div className={'extra-credits-zone__validation-message'}>
                {validationMessage}
              </div>
            )}

            {discountMessage && (
              <div
                className={'extra-credits-zone__validation-message'}
                style={{ color: 'green' }}
              >
                {discountMessage}
              </div>
            )}
            <Input
              style={{ width: '100px', float: 'right', textAlign: 'right' }}
              placeholder="1k - 100k"
              value={extraCredits}
              onChange={handleCreditsChange}
            />
          </Form.Item>
          <LogSlider
            value={+extraCredits}
            min={1000}
            max={100000}
            marks={[1000, 2000, 5000, 10000, 20000, 50000, 100000]}
            stepsInRange={100}
            onChange={onSliderChange}
          />
          <Form.Item label="Regular Price" style={{ marginBottom: '0px' }}>
            <div style={{ width: '100px', float: 'right', textAlign: 'right' }}>
              {'$' + regularCost}
            </div>
          </Form.Item>
          <Form.Item label="Volume Discount" style={{ marginBottom: '0px' }}>
            <div style={{ width: '100px', float: 'right', textAlign: 'right' }}>
              {volumeDiscount + '%'}
            </div>
          </Form.Item>
          {renderDiscount()}
          <Form.Item label="Saving" style={{ marginBottom: '0px' }}>
            <div style={{ width: '100px', float: 'right', textAlign: 'right' }}>
              {'$' + parseFloat((regularCost - cost).toFixed(2))}
            </div>
          </Form.Item>
          <Form.Item label="Final Price">
            <div style={{ width: '100px', float: 'right', textAlign: 'right' }}>
              {'$' + cost}
            </div>
          </Form.Item>
          <Button
            type="primary"
            icon={<CreditCardOutlined />}
            disabled={+extraCredits < 1000 || +extraCredits > 100000}
            onClick={handlePurchase}
            className={'extra-credits-zone__button'}
          >
            Purchase
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default BuyExtraTokens;
