import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Tooltip } from 'antd';
import { useState } from 'react';
import {
  KnowledgeDataSourcesFileTypes,
  PluginNames,
  PluginValidators,
  V_REQUIRED_FIELD,
} from '../../../../shared/constants';
import IDefaultPluginFieldProps from './IDefaultPluginField';

interface ArrayInputFieldProps extends IDefaultPluginFieldProps {
  pluginName: string;
}

interface ValueValidationResult {
  value: string;
  isValid: boolean;
  message: string;
}

const ArrayInputField: React.FC<ArrayInputFieldProps> = ({
  displayName,
  keyBase,
  index,
  isRequired,
  description,
  convertValue,
  placeholder,
  pluginName,
}) => {
  const [inputError, setInputError] = useState([false]);

  const getValidators = () => {
    switch (pluginName) {
      case PluginNames.HTTP_PLUGIN:
        return [PluginValidators.URL, PluginValidators.FILE];

      case PluginNames.RECORDS:
        return [PluginValidators.EMAIL];

      default:
        return [];
    }
  };

  const validators = getValidators();

  function isValidEmail(value: string) {
    const pattern = new RegExp(
      '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'
    );
    return pattern.test(value);
  }

  function isValidUrl(value: string) {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' +
        '((([a-z\\d$]([a-z\\d$-]*[a-z\\d$])*)\\.)+[a-z]{2,}|' +
        '((\\d{1,3}\\.){3}\\d{1,3}))' +
        '(\\:\\d+)?(\\/[-a-z\\d$%_.~+]*)*' +
        '(\\?[;&a-z\\d$%_.~+=-]*)?' +
        '(\\#[-a-z\\d$_]*)?$',
      'i'
    );
    return pattern.test(value);
  }

  function isValidFileType(url: string): boolean {
    // Extract the file extension from the URL
    const extensionMatch = url.match(/\.([0-9a-z]+)(?:[\?#]|$)/i);

    if (extensionMatch && extensionMatch[1]) {
      const fileExtension = extensionMatch[1].toLowerCase();
      return Object.values(KnowledgeDataSourcesFileTypes as any).includes(
        fileExtension
      );
    }

    return false;
  }

  const validateValue = (value: string) => {
    const result: ValueValidationResult = {
      value,
      isValid: true,
      message: '',
    };

    if (isRequired && (!value || value.length === 0)) {
      result.isValid = false;
      result.message = V_REQUIRED_FIELD;
    }

    if (validators) {
      for (let validator of validators) {
        if (validator === PluginValidators.URL) {
          if (!isValidUrl(value)) {
            result.isValid = false;
            result.message = 'Invalid URL';
          }
        }
        if (validator === PluginValidators.EMAIL) {
          if (!isValidEmail(value)) {
            result.isValid = false;
            result.message = 'Invalid Email';
          }
        }
        if (validator === PluginValidators.FILE) {
          if (!isValidFileType(value)) {
            result.isValid = false;
            result.message = 'Non-supported file format';
          }
        }
      }
    }

    return result;
  };

  const handleBlur = (e: any, index: number) => {
    checkInputError(e.target.value, index);
  };

  const handleChange = (e: any, index: number) => {
    checkInputError(e.target.value, index);
  };

  const checkInputError = (value: any, index: number) => {
    const isValid = validateValue(value).isValid;
    inputError[index] = !isValid;
    setInputError(inputError.concat());
  };

  const removeInputError = (index: number) => {
    inputError.splice(index, 1);
    setInputError(inputError.concat());
  };

  const initialValues = convertValue.length > 0 ? convertValue : [placeholder];

  return (
    <>
      {displayName && (
        <Tooltip title={description}>
          <label>{displayName}</label>
        </Tooltip>
      )}
      <Form.List name={keyBase} initialValue={initialValues} key={index}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                {...field}
                name={field.name}
                key={field.key}
                rules={[
                  {
                    required: isRequired,
                    message: V_REQUIRED_FIELD,
                  },
                  {
                    validator: async (_, value) => {
                      if (value) {
                        const result = validateValue(value);
                        if (!result.isValid) {
                          return Promise.reject(result.message);
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                initialValue={initialValues[index]}
                style={{ marginBottom: 10 }}
              >
                <div style={{ display: 'flex' }}>
                  <Input
                    placeholder={placeholder}
                    defaultValue={convertValue[index]}
                    className={inputError[index] ? 'input-error' : ''}
                    onBlur={(e) => handleBlur(e, index)}
                    onChange={(e) => handleChange(e, index)}
                    style={{
                      boxShadow: inputError[index]
                        ? '0 0 5px rgba(255, 0, 0, 0.8)'
                        : '',
                    }}
                  />
                  <Button
                    type="text"
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      remove(field.name);
                      removeInputError(index);
                    }}
                  />
                </div>
              </Form.Item>
            ))}
            <Form.Item>
              <Button onClick={() => add()} block icon={<PlusOutlined />}>
                Add
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};

export default ArrayInputField;
