import {
  Alert,
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Popconfirm,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import React, { FC, useEffect, useState } from 'react';
import IUpdateBranding from '../../../../types/IUpdateBranding';
import { organizationsAPI } from '../../../../services/organizations-service';
import IOrganization from '../../../../types/IOrganization';
import { showErrorMessage } from '../../../../shared/helpers';
import { DeleteOutlined } from '@ant-design/icons';
import { IRoleOption } from './organization';

const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

const RemovingBrandForm: FC<{
  currentOrgInfo: IOrganization;
  orgId: string;
  currentUserRoleOption: IRoleOption | null;
}> = ({ currentOrgInfo, orgId, currentUserRoleOption }) => {
  const [email, setEmail] = useState(currentOrgInfo.emailForPlugin);
  const [APIKey, setAPIKey] = useState(currentOrgInfo.emailSenderAPIKey);
  const [isValidEmail, setIsValidEmail] = useState(
    emailRegex.test(currentOrgInfo.emailForPlugin)
  );
  const [messageApi, contextHolder] = message.useMessage();
  const [isDisabledButton, setDisabledButton] = useState(true);
  const [setRemovingBranding] =
    organizationsAPI.useSetRemovingBrandingMutation();
  const [uploadBrandLogo] = organizationsAPI.useUploadBrandLogoMutation();
  const [deleteBrandLogo, { isLoading: deleteLoading }] =
    organizationsAPI.useDeleteBrandLogoMutation();
  const [removeBranding, setRemoveBranding] = useState(
    currentOrgInfo.customBranding
  );
  const [loadedValues, setLoadedValues] = useState({ ...currentOrgInfo });
  const [image, setImage] = useState<UploadFile | null>(null);
  const [lazyGetAllOrganizations] =
    organizationsAPI.useLazyGetAllOrganizationsQuery();

  const handleEmailChange = (e: any) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsValidEmail(emailRegex.test(newEmail));
  };

  const handleCheckboxChange = (e: any) => {
    setRemoveBranding(e.target.checked);
  };

  const handleAPIKey = (e: any) => {
    setAPIKey(e.target.value);
  };

  const confirmDeleteLogo = async () => {
    const result = await deleteBrandLogo(orgId);

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
    } else {
      messageApi.success('Brand Logo has been deleted.');
      lazyGetAllOrganizations();
    }
  };

  useEffect(() => {
    let isChanged = false;

    if (removeBranding !== loadedValues.customBranding) {
      isChanged = true;
    }

    if (email && email !== loadedValues.emailForPlugin) {
      if (emailRegex.test(email) || email === '') {
        isChanged = true;
      }
    }

    if (APIKey && APIKey !== loadedValues.emailSenderAPIKey) {
      isChanged = true;
    }

    if (image) {
      isChanged = true;
    }

    setDisabledButton(!isChanged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [APIKey, email, removeBranding, image]);

  const onFinish = async () => {
    const data: IUpdateBranding = {
      removeBranding: removeBranding,
      customEmail: email,
      emailSenderAPIKey: APIKey,
    };

    setDisabledButton(true);
    const result = await setRemovingBranding({ data, orgId });

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
    } else {
      await messageApi.success('The organization was updated successfully.', 1);
    }

    setLoadedValues((prevValues) => ({
      ...prevValues,
      emailForPlugin: email,
      emailSenderAPIKey: APIKey,
      customBranding: removeBranding,
    }));

    if (image) {
      const formData = new FormData();
      formData.append('file', image as unknown as Blob);

      const res = await uploadBrandLogo({ orgId, body: formData });

      if ('error' in res) {
        await showErrorMessage(messageApi, res.error);
        setDisabledButton(false);
      } else {
        messageApi.success('Brand Logo has been updated.');
        setImage(null);
      }
    }

    lazyGetAllOrganizations();
  };

  const props: UploadProps = {
    onRemove: (file) => {
      setImage(null);
    },
    beforeUpload: (file) => {
      setImage(file);

      return false;
    },
    accept: 'image/svg+xml',
    fileList: image ? [image] : [],
  };

  const isOwner = () => {
    return currentUserRoleOption && currentUserRoleOption.numValue === 0;
  };

  return (
    <>
      {contextHolder}
      {!isOwner() && (
        <Alert
          message="Editing branding is available only to the owner of the organization."
          type="info"
          showIcon
          style={{ marginBottom: 20 }}
        />
      )}
      <Form layout={'vertical'} onFinish={onFinish} disabled={!isOwner()}>
        <Form.Item>
          <Checkbox
            className="organization-branding"
            onChange={handleCheckboxChange}
            checked={removeBranding}
          >
            Remove branding
          </Checkbox>
        </Form.Item>
        <Form.Item
          label="Brand Logo"
          name="file"
          tooltip={
            'Enable Custom Branding to use a custom branding image for the widget.'
          }
        >
          <div style={{ display: 'flex', gap: 10 }}>
            {/*<Upload {...props} onChange={onChange}>*/}
            <Upload {...props}>
              <Button disabled={!removeBranding}>Choose File</Button>
            </Upload>
            {currentOrgInfo.hasLogo && (
              <Popconfirm
                title="Delete the logo"
                description="Are you sure you want to delete the brand logo?"
                onConfirm={confirmDeleteLogo}
                okText="Yes"
                cancelText="No"
                disabled={!removeBranding}
              >
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  disabled={!removeBranding || deleteLoading}
                >
                  Remove Logo
                </Button>
              </Popconfirm>
            )}
          </div>
        </Form.Item>
        <Form.Item
          label={`SendGrid's API Key`}
          name={`APIKey`}
          tooltip={`Set up SendGrid's API Key.`}
          initialValue={`xxx-xxxx-xxx`}
          // hidden={!isValidEmail}
        >
          <div>
            <Input.Password
              className="organization-branding"
              placeholder={`xxx-xxxx-xxx`}
              value={APIKey}
              onChange={handleAPIKey}
            />
          </div>
        </Form.Item>
        <Form.Item
          label={`Email for plugins`}
          name={`customEmail`}
          tooltip={`Configure your email for the plugin's email-sending feature here. Note: The email must be approved on SendGrid.`}
          initialValue={`DoNotReply@zappr.ai`}
          rules={[
            {
              pattern: emailRegex,
              message: 'Invalid email format',
            },
          ]}
        >
          <div>
            <Input
              className="organization-branding"
              placeholder={`DoNotReply@zappr.ai`}
              value={email}
              onChange={handleEmailChange}
            />
          </div>
        </Form.Item>
        <div className="plugin-setting-btn-container">
          <Form.Item className="save-changes-plugin-form-item">
            <Button
              type="primary"
              htmlType="submit"
              disabled={isDisabledButton}
              className="btn--green"
            >
              Save Changes
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default RemovingBrandForm;
