import React, { FC, useEffect } from 'react';
import Loading from '../../components/loading/loading';
import { useNavigate } from 'react-router-dom';
import { RouteNames } from '../../router/router';
import { LS_CURRENT_ORGANIZATION_ID } from '../../shared/constants';

const DashboardHome: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const lsCurrentOrgValue =
      localStorage.getItem(LS_CURRENT_ORGANIZATION_ID) || '';

    navigate(`${RouteNames.USER_PROJECTS}/${lsCurrentOrgValue}`);
  }, []);

  return (
    <div>
      <Loading />
    </div>
  );
};

export default DashboardHome;
