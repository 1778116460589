import React, { FC } from 'react';
import { SubscriptionPlanTypes } from '../../../../shared/constants';
import RemovingBrandForm from './removing-brand-form';
import { useParams } from 'react-router-dom';
import { subscriptionAPI } from '../../../../services/subscription-service';
import IOrganization from '../../../../types/IOrganization';
import { IRoleOption } from './organization';

interface IOrgBrandingProps {
  currentOrgInfo: IOrganization | null;
  currentUserRoleOption: IRoleOption | null;
}

const OrgBranding: FC<IOrgBrandingProps> = ({
  currentOrgInfo,
  currentUserRoleOption,
}) => {
  const { orgId } = useParams();
  const { data: userSubscription } =
    subscriptionAPI.useGetUserSubscriptionQuery();
  const renderRemovingBranding = () => {
    if (!userSubscription || !orgId || currentOrgInfo === null) {
      return null;
    }

    const { type, features } = userSubscription.currentPlan;

    if (type === SubscriptionPlanTypes.ENTERPRISE || features.remove_branding) {
      return (
        <RemovingBrandForm
          currentOrgInfo={currentOrgInfo}
          orgId={orgId}
          currentUserRoleOption={currentUserRoleOption}
        />
      );
    }

    return null;
  };

  return <div>{renderRemovingBranding()}</div>;
};

export default OrgBranding;
