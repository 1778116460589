import React, { useEffect, useState } from 'react';
import './header.scss';
import {
  CloseOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import { RouteNames } from '../../router/router';
import { useAppSelector } from '../../hooks/redux-hooks';

interface IMenuItem {
  title: string;
  href: string;
  className?: string;
}

const menuItemsDefault = [
  {
    title: 'Features',
    href: '#features',
  },
  {
    title: 'Testimonials',
    href: '#testimonials',
  },
  {
    title: 'Pricing',
    href: '#pricing',
  },
  {
    title: 'Try',
    href: '#demo',
  },
];

const Header = () => {
  const { isAuth } = useAppSelector((state) => state.userReducer);
  const [burgerMenuIsOpen, setBurgerMenuIsOpen] = useState<boolean>(false);
  const [menuItems, setMenuItems] = useState<IMenuItem[]>(menuItemsDefault);
  const [dashboardUrl, setDashboardUrl] = useState<string>(
    process.env.NODE_ENV === 'development'
      ? 'http://dashboard.localhost:3000'
      : `https://dashboard.${process.env.REACT_APP_DOMAIN}`
  );

  useEffect(() => {
    if (isAuth) {
      setMenuItems([
        {
          title: 'Dashboard',
          href: dashboardUrl + RouteNames.USER_PROJECTS,
          className: 'landing-dashboard-menu-item',
        },
        ...menuItemsDefault,
      ]);
    } else {
      setMenuItems(menuItemsDefault);
    }
  }, [isAuth]);

  return (
    <header className="header">
      <div className="container landing-container">
        <div className="header__inner">
          <div className="header-content-left">
            <a href="#">
              {/*<Link to={RouteNames.LANDING}>*/}
              <div className="logo logo-landing">
                <div className="custom-logo">
                  <img
                    className={'custom-logo-icon'}
                    src={`https://static.${process.env.REACT_APP_DOMAIN}/static/globals/logo.svg`}
                  />
                </div>
                <div className="logo-text">
                  {process.env.REACT_APP_DISPLAY_NAME}{' '}
                  {/*{process.env.REACT_APP_BETA_OFF === 'true' ? null : (*/}
                  {/*  <sup className="beta">BETA</sup>*/}
                  {/*)}*/}
                </div>
              </div>
            </a>
            {/*</Link>*/}
            {isAuth ? (
              <div className="dashboard-header-menu-list">
                <a href={dashboardUrl + RouteNames.USER_PROJECTS}>
                  <div className="dashboard-header-menu-item dashboard-header-menu-item__text dashboard-header-menu-item--text-fix">
                    <div>Dashboard</div>
                    <MenuUnfoldOutlined className="dashboard-header-menu-item__icon" />
                  </div>
                </a>
              </div>
            ) : null}
          </div>
          <div className="header-content">
            <div className="header-menu">
              <div className="header-burger-menu-item">
                <MenuOutlined onClick={() => setBurgerMenuIsOpen(true)} />
              </div>
              <div
                className={`burger-menu-overlay ${
                  burgerMenuIsOpen ? 'visible' : ''
                }`}
                onClick={() => setBurgerMenuIsOpen(false)}
              ></div>
              <div
                className={`burger-menu ${burgerMenuIsOpen ? 'visible' : ''}`}
              >
                <ul className="burger-menu__list">
                  <CloseOutlined
                    className="burger-menu__icon-close"
                    onClick={() => setBurgerMenuIsOpen(false)}
                  />
                  {menuItems.map((item, i) => (
                    <div
                      className={`header-menu-item burger-menu__item burger-item ${
                        item.className ? item.className : ''
                      }`}
                      key={i}
                    >
                      <a
                        href={item.href}
                        className="header-menu-link burger-menu__link"
                        onClick={() => setBurgerMenuIsOpen(false)}
                      >
                        {item.title}
                      </a>
                    </div>
                  ))}
                  {isAuth ? null : (
                    <div
                      className={`header-menu-item burger-menu__item burger-item`}
                    >
                      <a
                        className="header-menu-link burger-menu__link"
                        href={dashboardUrl + RouteNames.LOGIN}
                        onClick={() => setBurgerMenuIsOpen(false)}
                      >
                        <div className="sign-in sign-in-burger-menu">
                          Sign In
                        </div>
                      </a>
                    </div>
                  )}
                </ul>
              </div>
              {menuItems.map((item, i) => (
                <div
                  className={`header-menu-item ${
                    item.className ? item.className : ''
                  }`}
                  key={i}
                >
                  <a href={item.href} className="header-menu-link">
                    {item.title}
                  </a>
                </div>
              ))}
            </div>
            {isAuth ? null : (
              <>
                <a href={dashboardUrl + RouteNames.LOGIN}>
                  {/*<Link to={RouteNames.LOGIN}>*/}
                  <div className="sign-in">Sign In</div>
                </a>
                {/*</Link>*/}
                <a href={dashboardUrl + RouteNames.REGISTER}>
                  <div className="sign-up">Sign Up</div>
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
