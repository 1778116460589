import React, { FC } from 'react';
import { Button, Result } from 'antd';
import { RouteNames } from '../../router/router';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../hooks/redux-hooks';

const NoOrgAccessWarning: FC = () => {
  const navigate = useNavigate();

  const { allOrganizationsInfo } = useAppSelector(
    (state) => state.organizationsReducer
  );

  const navigateToPersonalProjects = () => {
    const personalOrg = allOrganizationsInfo.find((org) => org.isPersonal);

    if (personalOrg) {
      navigate(`${RouteNames.USER_PROJECTS}/${personalOrg.id}`);
    } else {
      navigate(`${RouteNames.USER_PROJECTS}`);
    }
  };

  return (
    <div>
      <Result
        status="warning"
        title="Looks like you don't have access or the organization doesn't exist."
        extra={
          <Button
            type="primary"
            key="console"
            onClick={navigateToPersonalProjects}
          >
            Go To My Projects
          </Button>
        }
      />
    </div>
  );
};

export default NoOrgAccessWarning;
