import { FC, useState } from 'react';
import { DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { FiArchive } from 'react-icons/fi';
import DeleteCampaignModal from '../../../modals/twilio/delete-campaign-modal';
import ArchiveCampaignModal from '../../../modals/twilio/archive-campaign-modal';
import { Button, Dropdown, MenuProps } from 'antd';

interface ICampaignMoreMenuProps {
  campaignId: string;
  projectId: string;
  status: string;
  refreshParent: () => void;
}

const CampaignMoreMenu: FC<ICampaignMoreMenuProps> = ({
  campaignId,
  projectId,
  status,
  refreshParent,
}) => {
  const [selectForDeleting, setSelectForDeleting] = useState<string>('');
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [archiveModalIsOpen, setArchiveModalIsOpen] = useState(false);

  const isDeleteDisabled = status === 'ready' || status === 'running';

  const defaultMenuItems: MenuProps['items'] = [
    {
      key: 'Delete',
      disabled: !isDeleteDisabled,
      label: (
        <a
          onClick={(e) => {
            e.stopPropagation();
            setSelectForDeleting(campaignId);
            setDeleteModalIsOpen(true);
          }}
        >
          <DeleteOutlined style={{ marginRight: 6 }} /> Delete
        </a>
      ),
    },
    {
      key: 'Archive',
      label: (
        <a
          onClick={(e) => {
            e.stopPropagation();
            setSelectForDeleting(campaignId);
            setArchiveModalIsOpen(true);
          }}
        >
          <FiArchive style={{ marginRight: 6 }} /> Archive
        </a>
      ),
    },
  ];

  const [menuItems, setMenuItems] =
    useState<MenuProps['items']>(defaultMenuItems);

  return (
    <>
      <div>
        <Dropdown
          menu={{ items: menuItems }}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
          trigger={['click']}
        >
          <Button
            type="text"
            icon={<MoreOutlined />}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </Dropdown>

        <DeleteCampaignModal
          isOpen={deleteModalIsOpen}
          setIsOpen={setDeleteModalIsOpen}
          campaignId={selectForDeleting}
          projectId={projectId}
          onRefresh={refreshParent}
        />

        <ArchiveCampaignModal
          isOpen={archiveModalIsOpen}
          setIsOpen={setArchiveModalIsOpen}
          campaignId={selectForDeleting}
          projectId={projectId}
          onRefresh={refreshParent}
        />
      </div>
    </>
  );
};

export default CampaignMoreMenu;
