import { FC } from 'react';
import IDefaultPluginFieldProps from './IDefaultPluginField';
import { Form, InputNumber } from 'antd';
import { V_REQUIRED_FIELD } from '../../../../shared/constants';

const NumberFormItem: FC<IDefaultPluginFieldProps> = ({
  displayName,
  keyBase,
  index,
  isRequired,
  description,
  convertValue,
  placeholder,
}) => {
  return (
    <Form.Item
      label={displayName}
      name={keyBase}
      key={index}
      rules={[
        {
          required: isRequired,
          message: V_REQUIRED_FIELD,
        },
        {
          type: 'number',
          message: 'Please enter a valid number',
        },
      ]}
      tooltip={description}
      initialValue={convertValue}
    >
      <InputNumber
        placeholder={placeholder ?? displayName}
        value={convertValue}
      />
    </Form.Item>
  );
};

export default NumberFormItem;
