import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Button, Modal, Slider, Upload, UploadFile, UploadProps } from 'antd';
import { useAppSelector } from '../../hooks/redux-hooks';
import Cropper from 'react-easy-crop';
import './upload-project-img.scss';
//@ts-ignore
import gifsicle from 'gifsicle-wasm-browser';
import { getRotatedImage } from './canvas-helpers';

const minZoom = 1;
const maxZoom = 3;
const zoomStep = 0.1;
const minRotation = -180;
const maxRotation = 180;
const rotationStep = 1;

interface ICroppedArea {
  width: number;
  height: number;
  x: number;
  y: number;
}

const UploadProjectImg: FC<{
  image: UploadFile | null;
  setImage: Dispatch<SetStateAction<UploadFile | null>>;
  handleChange?: () => void;
}> = ({ image, setImage, handleChange }) => {
  const { subscriptionIsActive } = useAppSelector(
    (state) => state.subscriptionReducer
  );
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [currentUploadImage, setCurrentUploadImage] =
    useState<UploadFile | null>(null);
  const [currentBase64, setCurrentBase64] = useState<string>('');
  const [cropModalIsOpen, setCropModalIsOpen] = useState(false);
  const [imgCroppedAreaPixels, setImgCroppedAreaPixels] =
    useState<ICroppedArea | null>(null);
  const [originalImageSize, setOriginalImageSize] = useState<{
    width: number;
    height: number;
  } | null>(null);

  useEffect(() => {
    setCropModalIsOpen(!!currentBase64);
  }, [currentBase64]);

  const props: UploadProps = {
    onRemove: (file) => {
      setCurrentUploadImage(null);
    },
    beforeUpload: (file) => {
      setCurrentUploadImage(file);
      const reader = new FileReader();

      reader.onload = (e) => {
        if (e.target) {
          setCurrentBase64(e.target.result as string);

          const img = new Image();
          img.onload = () => {
            setOriginalImageSize({ width: img.width, height: img.height });
          };
          img.src = e.target.result as string;
        }
      };
      reader.readAsDataURL(file);

      return false;
    },
    fileList: image ? [image] : [],
  };

  const onChange = () => {
    if (handleChange) {
      handleChange();
    }
  };

  useEffect(() => {
    handleChange && handleChange();
  }, [image]);

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setImgCroppedAreaPixels(croppedAreaPixels);
  };

  const handleReset = () => {
    setZoom(1);
    setRotation(0);
  };

  const handleCancel = () => {
    handleReset();
    setCurrentUploadImage(null);
    setImgCroppedAreaPixels(null);
    setCurrentBase64('');
  };

  const handleGif = () => {
    if (!imgCroppedAreaPixels) return;
    const { width, height, x, y } = imgCroppedAreaPixels;
    const currentImageWidth = originalImageSize?.width || 0;
    const currentImageHeight = originalImageSize?.height || 0;

    let xValue = x;
    let yValue = y;
    let widthValue = width;
    let heightValue = height;

    let rotationCommand = '';

    switch (rotation) {
      case 90:
        rotationCommand = '--rotate-90';
        xValue = y;
        yValue = currentImageHeight - x - width;
        break;
      case -90:
        rotationCommand = '--rotate-270';
        xValue = currentImageWidth - y - height;
        yValue = x;
        break;
      case 180:
      case -180:
        rotationCommand = '--rotate-180';
        xValue = currentImageWidth - x - width;
        yValue = currentImageHeight - y - height;
        break;
    }

    gifsicle
      .run({
        input: [
          {
            file: currentBase64,
            name: '1.gif',
          },
        ],
        command: [
          `${rotationCommand} --crop ${xValue},${yValue}+${widthValue}x${heightValue} --resize 256x256 1.gif -o /out/out.gif`,
        ],
      })
      .then(async (d: any) => {
        const file = new File(d, currentUploadImage?.name || 'image', {
          type: currentUploadImage?.type,
        });
        setImage(file as unknown as UploadFile);
      });
  };

  const handleOk = async () => {
    if (currentUploadImage?.type === 'image/gif') {
      handleGif();
    } else {
      const res = await getRotatedImage(
        currentBase64,
        rotation,
        zoom,
        imgCroppedAreaPixels
      );
      if (res) {
        const file = new File([res], currentUploadImage?.name || 'image', {
          type: currentUploadImage?.type,
        });
        setImage(file as unknown as UploadFile);
      }
    }

    handleCancel();
  };

  return (
    <>
      <Upload
        {...props}
        onChange={onChange}
        disabled={!subscriptionIsActive}
        accept={'.png,.gif'}
        className={'upload-project-img__upload'}
        onRemove={() => setImage(null)}
      >
        <Button disabled={!subscriptionIsActive}>Choose File</Button>
      </Upload>
      <Modal
        wrapClassName={'modal'}
        title="Edit image"
        open={cropModalIsOpen}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              key="reset"
              onClick={handleReset}
              disabled={zoom === minZoom && rotation === 0}
            >
              Reset
            </Button>
            <div>
              <Button key="cancel" onClick={handleCancel}>
                Cancel
              </Button>
              <Button key="ok" onClick={handleOk} type={'primary'}>
                Ok
              </Button>
            </div>
          </div>,
        ]}
        onCancel={handleCancel}
      >
        <div style={{ position: 'relative', height: 396 }}>
          <Cropper
            image={currentBase64}
            crop={crop}
            zoom={zoom}
            minZoom={minZoom}
            maxZoom={maxZoom}
            rotation={rotation}
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            onRotationChange={setRotation}
            cropShape={'round'}
            showGrid={false}
          />
        </div>
        <div className={'upload-project-img__actions-container'}>
          <div className={'upload-project-img__slider'}>
            <Button
              type="link"
              className={'upload-project-img__slider-btn'}
              disabled={zoom === minZoom}
              onClick={() => setZoom((z) => +(z - zoomStep).toFixed(1))}
            >
              -
            </Button>
            <Slider
              style={{ flex: 1 }}
              onChange={setZoom}
              value={zoom}
              min={minZoom}
              max={maxZoom}
              step={zoomStep}
            />
            <Button
              type="link"
              className={'upload-project-img__slider-btn'}
              disabled={zoom === maxZoom}
              onClick={() => setZoom((z) => +(z + zoomStep).toFixed(1))}
            >
              +
            </Button>
          </div>
          <div className={'upload-project-img__slider'}>
            <Button
              type="link"
              className={'upload-project-img__slider-btn'}
              disabled={zoom === minRotation}
              onClick={() => setRotation((r) => r - rotationStep)}
            >
              ↺
            </Button>
            <Slider
              style={{ flex: 1 }}
              onChange={setRotation}
              value={rotation}
              min={minRotation}
              max={maxRotation}
              step={
                currentUploadImage && currentUploadImage.type === 'image/gif'
                  ? 90
                  : rotationStep
              }
            />
            <Button
              type="link"
              className={'upload-project-img__slider-btn'}
              disabled={zoom === maxRotation}
              onClick={() => setRotation((r) => r + rotationStep)}
            >
              ↻
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UploadProjectImg;
