import TableSelectedRowsInfo from '../../components/shared/tables/table-selected-rows-info';
import React, { useState } from 'react';
import { tablesApi } from '../../services/tables-service';
import { ITableFilterValues } from '../../components/project-settings/whats-app-outbound/filters/filters-helpers';

export default function useSelectedRowsInfo(
  projectId: string,
  tableId: string,
  filterValues: ITableFilterValues[],
  itemsTotalCount: number,
  tableRows: { [key: string]: string }[]
): [
  JSX.Element,
  Set<string>,
  React.Dispatch<React.SetStateAction<Set<string>>>
] {
  const [getAllTableRecordsIdsT] =
    tablesApi.useLazyGetAllTableRecordsIdsTQuery();
  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());

  const selectAllTableRecords = async () => {
    if (!tableId) {
      setSelectedRows(new Set(tableRows.map((row) => row.key)));
      return;
    }

    const res = await getAllTableRecordsIdsT({
      projectId: projectId,
      id: tableId,
      filterValues,
    });
    if ('data' in res) {
      setSelectedRows(new Set(res.data));
    }
  };

  const component = () => (
    <TableSelectedRowsInfo
      totalCount={itemsTotalCount}
      selectedCount={selectedRows.size}
      clearSelection={() => setSelectedRows(new Set())}
      handleSelectAll={selectAllTableRecords}
    />
  );

  return [component(), selectedRows, setSelectedRows];
}
