import React, { FC, useState } from 'react';
import { Form, Input, Modal, Select } from 'antd';
import {
  ProjectSettingsType,
  V_REQUIRED_FIELD,
} from '../../../shared/constants';
import { whatsAppOutboundApi } from '../../../services/what-app-outbound-service';
import { showErrorMessage } from '../../../shared/helpers';
import { MessageInstance } from 'antd/es/message/interface';
import { RouteNames } from '../../../router/router';
import { useNavigate } from 'react-router-dom';
import { CampaignTypes } from '../../../types/whats-app-outbound-service/ICreateCampaign';

interface ICreateCampaignModalProps {
  projectId: string;
  orgId: string | undefined;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  messageApi: MessageInstance;
}

const CreateCampaignModal: FC<ICreateCampaignModalProps> = ({
  projectId,
  orgId,
  isOpen,
  setIsOpen,
  messageApi,
}) => {
  const [createCampaign, { isLoading }] =
    whatsAppOutboundApi.useCreateCampaignMutation();
  const [form] = Form.useForm();
  const [okDisabled, setOkDisabled] = useState<boolean>(true);
  const navigate = useNavigate();

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleCreateCampaign = async () => {
    const name = form.getFieldValue('name');
    const type = form.getFieldValue('type');

    const res = await createCampaign({
      projectId,
      body: {
        name: name,
        type: type,
      },
    });

    if ('error' in res) {
      await showErrorMessage(messageApi, res.error);
    } else {
      setIsOpen(false);
      const navigateTo = `${RouteNames.USER_PROJECTS}/${orgId}${RouteNames.PROJECT_SETTINGS_TAB_NAME}/${projectId}/${ProjectSettingsType.TWILIO_OUTBOUND}/${res.data.id}`;
      navigate(navigateTo);
    }
  };

  const handleChange = async () => {
    try {
      await form.validateFields();
      setOkDisabled(false);
    } catch (errors) {
      setOkDisabled(true);
    }
  };

  const capitalizeFirstLetter = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const renderComponent = () => {
    return (
      <Form
        className="data-source-upload-modal-form"
        layout="vertical"
        form={form}
        onChange={handleChange}
        requiredMark={false}
      >
        <Form.Item
          name="name"
          label={'Campaign name:'}
          rules={[
            {
              required: true,
              message: V_REQUIRED_FIELD,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="type"
          label="Campaign type:"
          rules={[
            {
              required: false,
            },
          ]}
          initialValue={CampaignTypes.FIXED}
        >
          <Select
            dropdownStyle={{ zIndex: 10050 }}
            options={Object.keys(CampaignTypes).map((key) => {
              const value = CampaignTypes[key as keyof typeof CampaignTypes];

              return {
                value: value,
                label: capitalizeFirstLetter(value),
              };
            })}
          />
        </Form.Item>
      </Form>
    );
  };

  return (
    <Modal
      title="Create Campaign"
      open={isOpen}
      onOk={handleCreateCampaign}
      okButtonProps={{
        disabled: okDisabled || isLoading,
        loading: isLoading,
      }}
      onCancel={handleCancel}
      centered={true}
      className={'text-source-modal'}
      wrapClassName={'modal'}
      maskStyle={{ zIndex: 1100 }}
      style={{ marginTop: 20, marginBottom: 20, maxWidth: 600 }}
    >
      {renderComponent()}
    </Modal>
  );
};

export default CreateCampaignModal;
