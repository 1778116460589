import { FC, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import IAcceptInvitation from '../../types/IAcceptInvitation';
import { organizationsAPI } from '../../services/organizations-service';
import { Button, Spin, message } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { RouteNames } from '../../router/router';
import {
  delay,
  LS_CURRENT_ORGANIZATION_ID,
  LS_TOKEN_KEY,
} from '../../shared/constants';
import PageNotExist from '../../components/page-not-exist/page-not-exist';
import Loading from '../../components/loading/loading';
import { getDomain } from '../../shared/helpers';

const AcceptInvitation: FC = () => {
  const [acceptInvitation, { data, isLoading: confirmLoading, error }] =
    organizationsAPI.useAcceptInvitationMutation();
  let [searchParams, setSearchParams] = useSearchParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [userData, setUserData] = useState<IAcceptInvitation | null>({
    email: '',
    token: '',
    tenantId: '',
  });
  const navigate = useNavigate();
  const loadingComponent = () => <Spin size="large" tip="Loading..." />;
  const [component, setComponent] = useState<JSX.Element>(loadingComponent);

  useEffect(() => {
    const email = searchParams.get('email');
    const token = searchParams.get('token');
    const orgId = searchParams.get('org-id');

    //console.log(email);
    if (email && token && orgId) {
      const emailReplace = email.replaceAll(' ', '+');
      const tokenReplace = token.replaceAll(' ', '+');
      const orgIdReplace = orgId.replaceAll(' ', '+');
      setUserData({
        email: emailReplace,
        token: tokenReplace,
        tenantId: orgIdReplace,
      });
    } else {
      setUserData(null);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!userData) return;
    if (!userData.email || !userData.token || !userData.token) return;
    acceptInvitationHandler();
  }, [userData]);

  const acceptInvitationHandler = async () => {
    if (!userData) return;

    const result = await acceptInvitation(userData);

    if ('error' in result) {
      setComponent(confirmEmailError);
    } else {
      localStorage.setItem(LS_TOKEN_KEY, userData.token);
      localStorage.setItem(LS_CURRENT_ORGANIZATION_ID, userData.tenantId);
      setComponent(confirmEmailSuccess);
      await delay(5000);
      navigate(`${RouteNames.USER_PROJECTS}/${userData?.tenantId}`);
    }
  };

  const confirmEmailSuccess = () => (
    <>
      <CheckCircleFilled className="verify-email-icon confirm-email-icon confirm-email-icon--success" />
      <div className="verify-email-title">Invitation is accepted!</div>
      <div>
        You've accepted the invitation to join the organization.
        <br />
        You can now use the organization's Dashboard and features.
      </div>

      <Button
        type="primary"
        onClick={() =>
          navigate(`${RouteNames.USER_PROJECTS}/${userData?.tenantId}`)
        }
      >
        Go to the organization's Dashboard
      </Button>
    </>
  );

  const confirmEmailError = () => (
    <>
      {contextHolder}
      <CloseCircleFilled className="verify-email-icon confirm-email-icon confirm-email-icon--error" />
      <div className="verify-email-title">
        Oh no!
        <br />
        Something went wrong.
      </div>
      <Button
        onClick={() => {
          window.location.href =
            'https://' + window.location.hostname + RouteNames.LOGIN;
        }}
      >
        Go to login
      </Button>
    </>
  );

  return (
    <div className="verify-confirm__inner">
      <div className="verify-email">
        {userData ? (
          component
        ) : !searchParams.get('email') ||
          searchParams.get('token') ||
          searchParams.get('org-id') ? (
          <PageNotExist />
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default AcceptInvitation;
