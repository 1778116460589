import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Dropdown,
  MenuProps,
  message,
  Result,
  Skeleton,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  UploadFile,
  UploadProps,
} from 'antd';
import {
  CaretDownOutlined,
  EditOutlined,
  EyeOutlined,
  InboxOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import './knowledge.scss';
import { ColumnsType } from 'antd/es/table';
import { projectsAPI } from '../../../services/projects-service';
import {
  KnowledgeDataSources,
  KnowledgeDataSourcesFileTypes,
  KnowledgeStatus,
  UserRoles,
} from '../../../shared/constants';
import DataSourceUploadModal from '../../modals/data-source-upload-modal/data-source-upload-modal';
import {
  formatDateFromISO8601,
  getAllowedKnowledgeFileTypesString,
  showErrorMessage,
} from '../../../shared/helpers';
import Loading from '../../loading/loading';
import { GA_UPLOAD_FILE } from '../../../shared/google-analytics';
import TextSourceModal from '../../modals/text-source-modal/text-source-modal';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import LimitsExceededModal from '../../modals/limits-exceeded-modal/limits-exceeded-modal';
import Dragger from 'antd/es/upload/Dragger';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import TextUpdateModal from '../../modals/text-update-modal/text-update-modal';
import GithubSourceModal from '../../modals/github-source-modal/github-source-modal';
import { projectsSlice } from '../../../store/reducers/projects-slice';
import SitemapSourceModal from '../../modals/sitemap-source-modal/sitemap-source-modal';
import YoutubeSourceModal from '../../modals/youtube-source-modal/youtube-source-modal';
import { useParams } from 'react-router-dom';
import WebsiteSourceModal from '../../modals/site-source-modal/sitemap-source-modal';
import QaIngestSourceModal from '../../modals/qa-ingest-modals/qa-ingest-source-modal';
import QaIngestUpdateModal from '../../modals/qa-ingest-modals/qa-ingest-update-modal';
import KnowledgeMoreMenu from './knowledge-more-menu';
import KnowledgePreviewModal from './knowledge-preview-modal';
import TableSourceModal from '../../modals/table-source-modal/table-source-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import TableUpdateModal from '../../modals/table-update-modal/table-update-modal';
import TableCreateModal from '../../modals/table-create-modal/table-create-modal';

export interface IKnowledgeDataType {
  key: string;
  name: string;
  type: string;
  isFolder: boolean;
  isFolderUp: boolean;
  usage: string;
  status: string;
  lastError: string;
  progress: number;
  updatedAt: string;
}

const RECORDS_PER_PAGE = 10;

const getTagColor = (status: string) => {
  switch (status) {
    case KnowledgeStatus.UNSAVED:
      return 'magenta';
    case KnowledgeStatus.PENDING:
      return 'yellow';
    case KnowledgeStatus.PROCESSING:
      return 'orange';
    case KnowledgeStatus.READY:
      return 'green';
    case KnowledgeStatus.ERROR:
      return 'red';
    case KnowledgeStatus.SKIPPED:
      return 'blue';
    default:
      return 'geekblue';
  }
};

interface IKnowledge {
  projectId: string;
  knowledgeList: IKnowledgeDataType[];
  setKnowledgeList: Dispatch<SetStateAction<IKnowledgeDataType[]>>;
  error: FetchBaseQueryError | SerializedError | undefined;
}

enum KnowledgeModalTypes {
  DATA_SOURCE_UPLOAD = 'DATA_SOURCE_UPLOAD',
  TEXT_SOURCE = 'TEXT_SOURCE',
  TEXT_UPDATE = 'TEXT_UPDATE',
  QA_INGEST_SOURCE = 'QA_INGEST_SOURCE',
  QA_INGEST_UPDATE = 'QA_INGEST_UPDATE',
  GITHUB_SOURCE = 'GITHUB_SOURCE',
  SITEMAP_SOURCE = 'SITEMAP_SOURCE',
  WEBSITE_SOURCE = 'WEBSITE_SOURCE',
  YOUTUBE_SOURCE = 'YOUTUBE_SOURCE',
  TABLE_SOURCE = 'TABLE_SOURCE',
  TABLE_UPDATE = 'TABLE_UPDATE',

  KNOWLEDGE_PREVIEW = 'KNOWLEDGE_PREVIEW',
  CREATE_TABLE = 'CREATE_TABLE',
}

interface IAddKnowledgeMenuItem {
  btnText: string;
  modalType: KnowledgeModalTypes;
  key: string;
}

const AddKnowledgeMenuItems: IAddKnowledgeMenuItem[] = [
  {
    btnText: 'Add File',
    modalType: KnowledgeModalTypes.DATA_SOURCE_UPLOAD,
    key: 'file',
  },
  {
    btnText: 'Add Text',
    modalType: KnowledgeModalTypes.TEXT_SOURCE,
    key: 'text',
  },
  {
    btnText: 'Add Q&A',
    modalType: KnowledgeModalTypes.QA_INGEST_SOURCE,
    key: 'qa',
  },
  {
    btnText: 'Add GitHub',
    modalType: KnowledgeModalTypes.GITHUB_SOURCE,
    key: 'github',
  },
  // Temporary commented
  // {
  //   btnText: 'Add Sitemap',
  //   modalType: KnowledgeModalTypes.SITEMAP_SOURCE,
  //   key: 'sitemap',
  // },
  {
    btnText: 'Add Website',
    modalType: KnowledgeModalTypes.WEBSITE_SOURCE,
    key: 'site',
  },
  {
    btnText: 'Add Youtube',
    modalType: KnowledgeModalTypes.YOUTUBE_SOURCE,
    key: 'youtube',
  },
  {
    btnText: 'Add Table',
    modalType: KnowledgeModalTypes.TABLE_SOURCE,
    key: 'table',
  },
];

const Knowledge: FC<IKnowledge> = ({
  projectId,
  knowledgeList,
  setKnowledgeList,
  error,
}) => {
  const { orgId } = useParams();
  const [getProjectKnowledgeInfo, { isLoading: projectKnowledgeInfoLoading }] =
    projectsAPI.useLazyGetProjectKnowledgeInfoQuery();
  const {
    data: userProject,
    isLoading: projectLoading,
    error: projectError,
  } = projectsAPI.useGetUserProjectByIdQuery({ id: projectId, orgId });
  const [lazyGetProjectKnowledge, lazyGetProjectKnowledgeResult] =
    projectsAPI.useLazyGetProjectKnowledgeQuery();
  const [
    getProjectKnowledgeChildren,
    {
      data: projectKnowledgeChildren,
      isLoading: isProjectKnowledgeChildrenLoading,
      isError: isProjectKnowledgeChildrenError,
    },
  ] = projectsAPI.useGetProjectKnowledgeChildrenMutation();
  const [
    getProjectKnowledgeChildrenPaged,
    {
      data: projectKnowledgeChildrenPaged,
      isLoading: isProjectKnowledgeChildrenPagedLoading,
      isError: isProjectKnowledgeChildrenPagedError,
    },
  ] = projectsAPI.useGetProjectKnowledgeChildrenPagedMutation();
  const { subscriptionIsActive } = useAppSelector(
    (state) => state.subscriptionReducer
  );
  const { knowledgeParentId } = useAppSelector((state) => state.projectReducer);
  const { userRole } = useAppSelector((state) => state.userReducer);
  const { setKnowledgeParentId } = projectsSlice.actions;
  const dispatch = useAppDispatch();
  const [textUpdateKnowledgeId, setTextUpdateKnowledgeId] = useState('');
  const [qaUpdateKnowledgeId, setQaUpdateKnowledgeId] = useState('');
  const [tableUpdateKnowledgeId, setTableUpdateKnowledgeId] = useState('');
  const [limitsExceededModalIsOpen, setLimitsExceededModalIsOpen] =
    useState(false);
  const [selectedDataSourceFiles, setSelectedDataSourceFiles] =
    useState<string>('');
  const [messageApi, contextHolder] = message.useMessage();
  const [draggerDisabled, setDraggerDisabled] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [addProjectKnowledge, { isLoading: addProjectKnowledgeLoading }] =
    projectsAPI.useAddProjectKnowledgeMutation();
  const [projectKnowledgeUploading, setProjectKnowledgeUploading] =
    useState(false);
  const [filesIsSubmitted, setFilesIsSubmitted] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(RECORDS_PER_PAGE);
  const [itemsTotalCount, setItemsTotalCount] = useState<number>(
    knowledgeList.length
  );
  const [totalCountChildren, setTotalCountChildren] = useState<number>(0);
  const [knowledgeLoading, setKnowledgeLoading] = useState<boolean>(false);
  const [currentOpenedModal, setCurrentOpenedModal] =
    useState<KnowledgeModalTypes | null>(null);
  const [previewKnowledgeContent, setPreviewKnowledgeContent] = useState<
    string[]
  >([]);

  let blockOnPageChangeRequest = false;

  useEffect(() => {
    return () => {
      dispatch(setKnowledgeParentId(''));
    };
  }, []);

  useEffect(() => {
    setSelectedDataSourceFiles(getAllowedKnowledgeFileTypesString());
  }, []);

  const checkUploading = () => {
    if (addProjectKnowledgeLoading || filesIsSubmitted) {
      setProjectKnowledgeUploading(true);
    } else {
      setProjectKnowledgeUploading(false);
    }
  };

  useEffect(() => {
    checkUploading();
  }, [addProjectKnowledgeLoading, filesIsSubmitted]);

  useEffect(() => {
    if (
      lazyGetProjectKnowledgeResult.status === 'fulfilled' &&
      lazyGetProjectKnowledgeResult.data
    ) {
      if (knowledgeParentId) {
        handleGetProjectKnowledgeChildren(knowledgeParentId, currentPage);
        return;
      }

      const knowledgeData = lazyGetProjectKnowledgeResult.data.map(
        (knowledge) => ({
          key: knowledge.id,
          name: knowledge.name,
          type: knowledge.type,
          isFolder: false,
          isFolderUp: false,
          //TODO: usage
          usage: '',
          status: knowledge.status,
          lastError: knowledge.lastError,
          progress: knowledge.progress,
          updatedAt: knowledge.updatedAt,
        })
      );
      setKnowledgeList(knowledgeData);
      setItemsTotalCount(knowledgeData.length);
    }

    if (lazyGetProjectKnowledgeResult.status !== 'pending') {
      setKnowledgeLoading(false);
    }
  }, [lazyGetProjectKnowledgeResult]);

  useEffect(() => {
    if (draggerDisabled) {
      uploadFiles();
    }
  }, [draggerDisabled]);

  useEffect(() => {
    if (textUpdateKnowledgeId) {
      setCurrentOpenedModal(KnowledgeModalTypes.TEXT_UPDATE);
    } else if (currentOpenedModal === KnowledgeModalTypes.TEXT_UPDATE) {
      setCurrentOpenedModal(null);
    }
  }, [textUpdateKnowledgeId]);

  useEffect(() => {
    if (qaUpdateKnowledgeId) {
      setCurrentOpenedModal(KnowledgeModalTypes.QA_INGEST_UPDATE);
    } else if (currentOpenedModal === KnowledgeModalTypes.QA_INGEST_UPDATE) {
      setCurrentOpenedModal(null);
    }
  }, [qaUpdateKnowledgeId]);

  useEffect(() => {
    if (tableUpdateKnowledgeId) {
      setCurrentOpenedModal(KnowledgeModalTypes.TABLE_UPDATE);
    } else if (currentOpenedModal === KnowledgeModalTypes.TABLE_UPDATE) {
      setCurrentOpenedModal(null);
    }
  }, [tableUpdateKnowledgeId]);

  const uploadFiles = async () => {
    let newFileList: UploadFile[] = JSON.parse(JSON.stringify(fileList));

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      const formData = new FormData();
      // TODO: style ???
      // formData.append('json', JSON.stringify({ style: EmbeddingsStyle.RAW }));
      formData.append('file', file.originFileObj as Blob);
      const result = addProjectKnowledge({
        id: projectId,
        body: formData,
      });

      result
        .unwrap()
        .then(() => {
          const fileExtension = file.name.split('.').pop();
          GA_UPLOAD_FILE(fileExtension || 'N/A');
          messageApi.success(`File ${file.name} has been submitted.`, 1);
          lazyGetProjectKnowledge({ projectId });
        })
        .catch((err) => {
          showErrorMessage(messageApi, err);
        })
        .finally(() => {
          newFileList = newFileList.filter((f) => f.uid !== file.uid);
          setFileList(newFileList);
          if (newFileList.length === 0) {
            setFileList([]);
            setDraggerDisabled(false);
          }
        });
    }
  };

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    onChange: async (info) => {
      // console.log(info.fileList);
      setFileList(info.fileList);
      setDraggerDisabled(true);
      // setFileList(info.fileList);
      // uploadFiles(info.fileList);
    },
    onDrop(e) {
      // console.log('Dropped files', e.dataTransfer.files);
    },
    onRemove: (file) => {
      // const index = fileList.indexOf(file);
      // const newFileList = fileList.slice();
      // newFileList.splice(index, 1);
      // setFileList(newFileList);
    },
    beforeUpload: () => {
      return false;
    },
    fileList,
  };

  const cbSubmittedFiles = (submFiles: string[]) => {
    if (submFiles.length > 0 && !filesIsSubmitted) {
      setFilesIsSubmitted(true);
    } else {
      setFilesIsSubmitted(false);
    }
  };

  const getRecordIcon = (
    recordType: KnowledgeDataSources,
    fileName: string
  ) => {
    const fileExtension = (
      fileName.split('.').pop() || ''
    ).toLowerCase() as KnowledgeDataSourcesFileTypes;

    if (recordType === KnowledgeDataSources.FILE) {
      switch (fileExtension) {
        case KnowledgeDataSourcesFileTypes.PDF:
          return <FontAwesomeIcon icon={'fal fa-file-pdf' as IconProp} />;
        case KnowledgeDataSourcesFileTypes.CSV:
          return <FontAwesomeIcon icon={'fal fa-file-csv' as IconProp} />;
      }
    }

    switch (recordType) {
      case KnowledgeDataSources.GITHUB:
        return <FontAwesomeIcon icon={'fab fa-github' as IconProp} />;
      case KnowledgeDataSources.SITE:
        return <FontAwesomeIcon icon={'fal fa-sitemap' as IconProp} />;
      case KnowledgeDataSources.YOUTUBE:
        return <FontAwesomeIcon icon={'fab fa-youtube' as IconProp} />;
      case KnowledgeDataSources.TABLE:
        return <FontAwesomeIcon icon={'fal fa-table' as IconProp} />;
      case KnowledgeDataSources.TEXT:
        return <FontAwesomeIcon icon={'fal fa-scroll' as IconProp} />;
      case KnowledgeDataSources.QA:
        return <FontAwesomeIcon icon={'fal fa-comments' as IconProp} />;
    }

    return <FontAwesomeIcon icon={'fal fa-file' as IconProp} />;
  };

  const handleEditKnowledge = (
    knowledgeId: string,
    type: KnowledgeDataSources
  ) => {
    if (type === KnowledgeDataSources.TEXT) {
      setTextUpdateKnowledgeId(knowledgeId);
    }
    if (type === KnowledgeDataSources.TABLE) {
      setTableUpdateKnowledgeId(knowledgeId);
    } else if (type === KnowledgeDataSources.QA) {
      setQaUpdateKnowledgeId(knowledgeId);
    }
  };

  const handleGetProjectKnowledgeChildren = async (
    parentId: string,
    page: number,
    iPerPage?: number
  ) => {
    const perPage = iPerPage || itemsPerPage;

    const result = await getProjectKnowledgeChildrenPaged({
      projectId,
      body: { parentId: parentId },
      offset: (perPage - 1) * (page - 1),
      limit: perPage - 1,
    });
    // const result = await getProjectKnowledgeChildren({
    //   projectId,
    //   body: { parentId: parentId },
    // });
    if ('error' in result) {
      // await showErrorMessage(messageApi, result.error);
    } else {
      const knowledgeData = result.data.data.map((knowledge) => ({
        key: knowledge.id,
        name: knowledge.name,
        type: knowledge.type,
        isFolder: false,
        isFolderUp: false,
        //TODO: usage
        usage: '',
        status: knowledge.status,
        lastError: knowledge.lastError,
        progress: knowledge.progress,
        updatedAt: knowledge.updatedAt,
      }));

      knowledgeData.push({
        key: knowledgeParentId,
        name: '..',
        type: KnowledgeDataSources.GITHUB,
        isFolder: true,
        isFolderUp: true,
        usage: '',
        status: '',
        lastError: '',
        progress: 0,
        updatedAt: '',
      });

      setKnowledgeList(knowledgeData);
      const pages = Math.ceil(result.data.totalCount / (perPage - 1));
      setItemsTotalCount(result.data.totalCount + pages);
      setTotalCountChildren(result.data.totalCount);
    }

    setKnowledgeLoading(false);
    blockOnPageChangeRequest = false;
  };

  const handleOpenFolder = async (id: string, isFolderUp: boolean) => {
    setKnowledgeLoading(true);
    if (isFolderUp) {
      handleOpenFolderUp();
      return;
    }
    dispatch(setKnowledgeParentId(id));
    handleGetProjectKnowledgeChildren(id, 1);
    setCurrentPage(1);
  };

  const handleOpenFolderUp = () => {
    dispatch(setKnowledgeParentId(''));
    //setCurrentPage(1);
    lazyGetProjectKnowledge({ projectId })
      .unwrap()
      .then(() => {})
      .finally(() => {
        setKnowledgeLoading(false);
      });
  };

  const onChangePage = async (page: number) => {
    if (knowledgeParentId && !blockOnPageChangeRequest) {
      setKnowledgeLoading(true);
      handleGetProjectKnowledgeChildren(knowledgeParentId, page);
    }

    setCurrentPage(page);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [knowledgeParentId]);

  const onShowSizeChange = async (current: number, pageSize: number) => {
    blockOnPageChangeRequest = true;
    if (knowledgeParentId) {
      setKnowledgeLoading(true);
    }

    setItemsPerPage(pageSize);
    setCurrentPage(current);

    if (knowledgeParentId) {
      const pages = Math.ceil(totalCountChildren / (pageSize - 1));
      const currPage = current > pages ? pages : current;
      handleGetProjectKnowledgeChildren(knowledgeParentId, currPage, pageSize);
    }
  };

  const handlePreviewFile = async (knowledgeId: string) => {
    setCurrentOpenedModal(KnowledgeModalTypes.KNOWLEDGE_PREVIEW);
    const res = await getProjectKnowledgeInfo({ projectId, knowledgeId });
    if ('error' in res && res.error) {
      await showErrorMessage(messageApi, res.error);
      setPreviewKnowledgeContent(["Error: can't get knowledge content."]);
    } else {
      const knowledge = res.data;
      let pages: string[] = [];
      try {
        const content = knowledge?.content || '';
        const contentJson = JSON.parse(content);
        pages = contentJson['Pages'] || [];
        setPreviewKnowledgeContent(pages);
      } catch (e) {
        setPreviewKnowledgeContent(["Error: can't parse knowledge content."]);
        console.error(e);
      }
    }
  };

  const getEditBtn = (recordType: KnowledgeDataSources, recordKey: string) => {
    if (
      recordType === KnowledgeDataSources.TEXT ||
      recordType === KnowledgeDataSources.QA ||
      // TODO: tmp, preview-edit knowledge
      (recordType === KnowledgeDataSources.TABLE &&
        userRole === UserRoles.ADMIN)
    ) {
      return (
        <Button
          type="text"
          icon={<EditOutlined />}
          disabled={!subscriptionIsActive}
          onClick={() => handleEditKnowledge(recordKey, recordType)}
        />
      );
    }

    return null;
  };

  const getPreviewBtn = (
    recordType: KnowledgeDataSources,
    recordKey: string,
    recordStatus: KnowledgeStatus
  ) => {
    if (
      recordType === KnowledgeDataSources.TEXT ||
      recordType === KnowledgeDataSources.QA ||
      recordType === KnowledgeDataSources.TABLE
    ) {
      return null;
    }

    if (recordStatus === KnowledgeStatus.READY) {
      return (
        <Button
          type="text"
          icon={<EyeOutlined />}
          disabled={!subscriptionIsActive}
          onClick={() => handlePreviewFile(recordKey)}
        />
      );
    }

    return null;
  };

  const isFolder = (record: IKnowledgeDataType) => {
    return (
      record.type === KnowledgeDataSources.GITHUB ||
      record.type === KnowledgeDataSources.ZENDESK ||
      record.type === KnowledgeDataSources.FAMA ||
      record.type === KnowledgeDataSources.SITEMAP ||
      record.type === KnowledgeDataSources.SITE ||
      record.isFolder
    );
  };

  const renderKnowledge = (maxName: any, record: any) => {
    if (isFolder(record)) {
      return (
        <a
          onClick={() => handleOpenFolder(record.key, record.isFolderUp)}
          style={{ display: 'flex', alignItems: 'center', gap: 8 }}
        >
          <div style={{ minWidth: 20 }}>
            <FontAwesomeIcon
              icon={'fal fa-folder' as IconProp}
              className="knowledge-folder-icon"
            />
          </div>
          {maxName}
        </a>
      );
    }

    return (
      <a style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        <div style={{ minWidth: 20 }}>
          {getRecordIcon(record.type, record.name)}
        </div>{' '}
        {maxName}
      </a>
    );
  };

  // ColumnsType<IKnowledgeDataType> => TypeScript error ¯\_(ツ)_/¯
  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => {
        const maxNameLength = 60;
        let maxName = name.slice(0, maxNameLength);

        if (name.length > maxNameLength) {
          maxName += '...';
        }

        return renderKnowledge(maxName, record);
      },
    },
    {
      title: 'updatedAt',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt) => (
        <div style={{ textAlign: 'center' }} className={'knowledge-updated-at'}>
          {updatedAt ? formatDateFromISO8601(updatedAt) : ''}
        </div>
      ),
      width: 130,
    },
    // {
    //   title: '',
    //   dataIndex: 'usage',
    //   key: 'usage',
    //   render: (usage) => (
    //     <span>
    //       {usage}
    //       {/*TODO: usage*/}
    //       {/*{Number(usage) > 0 ? formatNumber(usage) : ''}{' '}*/}
    //       {/*{Number(usage) > 0 ? 'characters' : ''}*/}
    //     </span>
    //   ),
    //   width: 130,
    // },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) =>
        status !== KnowledgeStatus.UNSAVED && isFolder(record) ? null : (
          <Tag
            color={getTagColor(status)}
            style={{ textTransform: 'capitalize' }}
          >
            {(status === KnowledgeStatus.ERROR ||
              status === KnowledgeStatus.SKIPPED) &&
            record.lastError ? (
              <Tooltip title={record.lastError}>{status}</Tooltip>
            ) : status === KnowledgeStatus.PROCESSING ? (
              <Tooltip
                title={`Progress: ${Math.round(record.progress * 100)} %`}
              >
                {status}
              </Tooltip>
            ) : (
              status
            )}
          </Tag>
        ),
      width: 110,
    },
    {
      title: 'Preview',
      dataIndex: 'preview',
      key: 'preview',
      render: (_, record) => {
        return (
          <div style={{ textAlign: 'center' }}>
            {isFolder(record)
              ? null
              : getPreviewBtn(record.type, record.key, record.status)}
          </div>
        );
      },
      width: 48,
    },
    {
      title: 'Edit',
      dataIndex: 'edit',
      key: 'edit',
      render: (_, record) => (
        <div style={{ textAlign: 'center' }}>
          {getEditBtn(record.type, record.key)}
        </div>
      ),
      width: 48,
    },
    {
      title: 'More',
      dataIndex: 'more',
      key: 'more',
      render: (_, record) => {
        // record.type === KnowledgeDataSources.FILE ? (
        return !record.isFolder ? (
          <div style={{ textAlign: 'center' }}>
            <div style={{ textAlign: 'center' }}>
              <KnowledgeMoreMenu
                projectId={projectId}
                knowledgeId={record.key}
                fileName={record.name}
                disabled={!subscriptionIsActive}
                messageApi={messageApi}
                recordType={record.type}
                recordStatus={record.status}
                isParent={isFolder(record)}
                cb={() => lazyGetProjectKnowledge({ projectId })}
              />
            </div>
          </div>
        ) : null;
      },
      width: 48,
    },
  ];

  const loadingColumns = [
    {
      title: 'loading',
      dataIndex: 'loading',
      key: 'loading',
      render: () => {
        return <Skeleton active />;
      },
      width: '100%',
    },
  ];

  const loadingColumnsData = [
    {
      key: 'loading',
      name: 'loading',
      type: '',
      isFolder: false,
      isFolderUp: false,
      usage: '',
      status: '',
      lastError: '',
      progress: 0,
    },
  ];

  const getActionMenuItem = (itemData: IAddKnowledgeMenuItem) => {
    return {
      label: (
        <a
          onClick={(e) => {
            e.preventDefault();
            setCurrentOpenedModal(itemData.modalType);
          }}
        >
          {itemData.btnText}
        </a>
      ),
      key: itemData.key,
    };
  };

  const actionMenuItems: MenuProps['items'] =
    AddKnowledgeMenuItems.map(getActionMenuItem);

  const headerTitle = () => (
    <div className="add-knowledge">
      <div className="add-knowledge__text">
        Enter knowledge for “{userProject && userProject.name}”
      </div>
      <div className="add-knowledge__btns">
        <Dropdown
          menu={{ items: actionMenuItems }}
          trigger={['click']}
          disabled={!subscriptionIsActive}
        >
          <Button>
            <Space>
              <PlusOutlined /> Add Knowledge
              <CaretDownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </div>
    </div>
  );

  const closeCurrentOpenedModal = () => {
    setCurrentOpenedModal(null);
  };

  const handleCreateTable = (e: React.MouseEvent) => {
    e.stopPropagation();
    setCurrentOpenedModal(KnowledgeModalTypes.CREATE_TABLE);
  };

  // TODO: FOR TEST ONLY ======================
  // const testSubmitInputRef = useRef<HTMLInputElement>(null);
  //
  // const testSubmit = (e: ChangeEvent<HTMLInputElement>) => {
  //   const files = e.target.files;
  //   if (!files) return;
  //
  //   for (let i = 0; i < files.length; i++) {
  //     const file = files[i];
  //     const formData = new FormData();
  //     formData.append('file', file);
  //     const result = addProjectKnowledge({
  //       id: projectId,
  //       body: formData,
  //     });
  //
  //     result
  //       .unwrap()
  //       .then((res) => {
  //         const fileExtension = file.name.split('.').pop();
  //         GA_UPLOAD_FILE(fileExtension || 'N/A');
  //         messageApi.success(`File ${file.name} has been submitted.`, 1);
  //         lazyGetProjectKnowledge(projectId);
  //       })
  //       .catch((err) => {
  //         showErrorMessage(messageApi, err);
  //       })
  //       .finally(() => {
  //         if (testSubmitInputRef.current) {
  //           testSubmitInputRef.current.value = '';
  //         }
  //       });
  //   }
  // }
  // TODO: FOR TEST ONLY ======================

  return (
    <div className="knowledge">
      {contextHolder}
      <div className="project-setting-content-container">
        <div className="knowledge-content">
          {projectLoading ? (
            <Loading height={'30vh'} />
          ) : error &&
            'data' in error &&
            error.data &&
            'error' in (error.data as { error: string }) ? (
            <Result
              status="error"
              title="Ooops, something went wrong."
              subTitle={(error.data as { error: string })['error']}
            />
          ) : projectError &&
            'data' in projectError &&
            projectError.data &&
            'error' in (projectError.data as { error: string }) ? (
            <Result
              status="error"
              title="Ooops, something went wrong."
              subTitle={(projectError.data as { error: string })['error']}
            />
          ) : error ||
            projectError ||
            isProjectKnowledgeChildrenError ||
            isProjectKnowledgeChildrenPagedError ? (
            <div className="knowledge-general-error-msg">
              Ooops, something went wrong.
            </div>
          ) : (
            <>
              {headerTitle()}
              {projectKnowledgeUploading ? (
                <div className="knowledge-uploading-indicator">
                  <Spin
                    size="large"
                    tip={
                      <>
                        Uploading files...
                        <br />
                        Please do not close this page.
                      </>
                    }
                    className="loading__spin"
                  />
                </div>
              ) : null}
              <Table
                columns={knowledgeLoading ? loadingColumns : columns}
                className={`knowledge-table ${
                  knowledgeList.length === 0 ? 'knowledge-table-empty' : ''
                }`}
                dataSource={
                  knowledgeLoading
                    ? loadingColumnsData
                    : knowledgeList.reverse()
                }
                showHeader={false}
                pagination={{
                  defaultPageSize: itemsPerPage,
                  hideOnSinglePage: true,
                  onChange: (page) => onChangePage(page),
                  current: currentPage,
                  total: itemsTotalCount,
                  showSizeChanger: true,
                  onShowSizeChange: (current, size) =>
                    onShowSizeChange(current, size),
                  pageSizeOptions: [
                    RECORDS_PER_PAGE.toString(),
                    '20',
                    '50',
                    '100',
                  ],
                }}
                // caption={headerTitle()}
                scroll={{ x: true }}
              />
            </>
          )}
          <Dragger
            {...props}
            accept={selectedDataSourceFiles}
            disabled={draggerDisabled || !subscriptionIsActive}
            className={'knowledge-dragger'}
            // className={showUpload ? '' : 'data-source-upload-dragger-hidden'}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload.
            </p>
          </Dragger>
          {/*<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} imageStyle={{height: 120}} className='knowledge-no-data'/>*/}

          {/*/!*TODO: FOR TEST ONLY ======================*!/*/}
          {/*<div style={{marginTop: 50}}>*/}
          {/*  <input ref={testSubmitInputRef} onChange={(e) => testSubmit(e)} type="file" id="file" name='files[]' accept={selectedDataSourceFiles} multiple/>*/}
          {/*</div>*/}
          {/*/!*TODO: FOR TEST ONLY ======================*!/*/}
        </div>
      </div>
      <DataSourceUploadModal
        isOpen={currentOpenedModal === KnowledgeModalTypes.DATA_SOURCE_UPLOAD}
        closeModal={closeCurrentOpenedModal}
        dataSourceType={selectedDataSourceFiles}
        projectId={projectId}
        cb={() => lazyGetProjectKnowledge({ projectId })}
        cbSubmittedFiles={(submittedFiles) => cbSubmittedFiles(submittedFiles)}
      />
      <TextSourceModal
        isOpen={currentOpenedModal === KnowledgeModalTypes.TEXT_SOURCE}
        closeModal={closeCurrentOpenedModal}
        projectId={projectId}
        cb={() => lazyGetProjectKnowledge({ projectId })}
      />
      <TextUpdateModal
        isOpen={currentOpenedModal === KnowledgeModalTypes.TEXT_UPDATE}
        closeModal={closeCurrentOpenedModal}
        projectId={projectId}
        knowledgeId={textUpdateKnowledgeId}
        setTextUpdateKnowledgeId={setTextUpdateKnowledgeId}
        cb={() => lazyGetProjectKnowledge({ projectId })}
      />
      <QaIngestSourceModal
        isOpen={currentOpenedModal === KnowledgeModalTypes.QA_INGEST_SOURCE}
        closeModal={closeCurrentOpenedModal}
        projectId={projectId}
        cb={() => lazyGetProjectKnowledge({ projectId })}
      />
      <QaIngestUpdateModal
        isOpen={currentOpenedModal === KnowledgeModalTypes.QA_INGEST_UPDATE}
        closeModal={closeCurrentOpenedModal}
        projectId={projectId}
        knowledgeId={qaUpdateKnowledgeId}
        setQaUpdateKnowledgeId={setQaUpdateKnowledgeId}
        cb={() => lazyGetProjectKnowledge({ projectId })}
      />
      <GithubSourceModal
        isOpen={currentOpenedModal === KnowledgeModalTypes.GITHUB_SOURCE}
        closeModal={closeCurrentOpenedModal}
        projectId={projectId}
        cb={() => lazyGetProjectKnowledge({ projectId })}
      />
      <SitemapSourceModal
        isOpen={currentOpenedModal === KnowledgeModalTypes.SITEMAP_SOURCE}
        closeModal={closeCurrentOpenedModal}
        projectId={projectId}
        cb={() => lazyGetProjectKnowledge({ projectId })}
      />
      <WebsiteSourceModal
        isOpen={currentOpenedModal === KnowledgeModalTypes.WEBSITE_SOURCE}
        closeModal={closeCurrentOpenedModal}
        projectId={projectId}
        cb={() => lazyGetProjectKnowledge({ projectId })}
      />
      <YoutubeSourceModal
        isOpen={currentOpenedModal === KnowledgeModalTypes.YOUTUBE_SOURCE}
        closeModal={closeCurrentOpenedModal}
        projectId={projectId}
        cb={() => lazyGetProjectKnowledge({ projectId })}
      />
      <TableSourceModal
        isOpen={currentOpenedModal === KnowledgeModalTypes.TABLE_SOURCE}
        closeModal={closeCurrentOpenedModal}
        projectId={projectId}
        cb={() => lazyGetProjectKnowledge({ projectId })}
        cbSubmittedFiles={(submittedFiles) => cbSubmittedFiles(submittedFiles)}
        handleCreateTable={handleCreateTable}
      />
      <TableCreateModal
        isOpen={currentOpenedModal === KnowledgeModalTypes.CREATE_TABLE}
        closeModal={closeCurrentOpenedModal}
        projectId={projectId}
      />
      <TableUpdateModal
        isOpen={currentOpenedModal === KnowledgeModalTypes.TABLE_UPDATE}
        closeModal={closeCurrentOpenedModal}
        projectId={projectId}
        knowledgeId={tableUpdateKnowledgeId}
        setTableUpdateKnowledgeId={setTableUpdateKnowledgeId}
        cb={() => lazyGetProjectKnowledge({ projectId })}
      />
      <KnowledgePreviewModal
        isOpen={currentOpenedModal === KnowledgeModalTypes.KNOWLEDGE_PREVIEW}
        closeModal={closeCurrentOpenedModal}
        isLoading={projectKnowledgeInfoLoading}
        previewKnowledgeContent={previewKnowledgeContent}
        setPreviewKnowledgeContent={setPreviewKnowledgeContent}
      />
      <LimitsExceededModal
        isOpen={limitsExceededModalIsOpen}
        setIsOpen={setLimitsExceededModalIsOpen}
      />
    </div>
  );
};

export default Knowledge;
