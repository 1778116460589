import React, { FC, useEffect, useState } from 'react';
import IProject from '../../../../../types/IProject';
import { Form, FormInstance, InputNumber } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import { Link, useParams } from 'react-router-dom';
import { RouteNames } from '../../../../../router/router';
import { ProfileSettingsType } from '../../../../../shared/constants';
import { getLexicalPromptFormItem } from '../../../../lexical/lexical-helpers';
import LexicalCommandPropsModal, {
  ILexicalCommandModalStateProps,
} from '../../../../lexical/lexical-command-props-modal';
import { useAppSelector } from '../../../../../hooks/redux-hooks';
import { projectsAPI } from '../../../../../services/projects-service';
import EngineOptionsFormItem from '../../../settings-form/engine-options-form-item';
import './advanced-llm-settings.scss';

interface IAdvancedLlmSettingsProps {
  userProject: IProject;
  advancedLlmSettingsForm: FormInstance;
  handleChange: () => void;
  messageApi: MessageInstance;
  ownerIdOfCurrentOrg: string | null;
}

const prompts = [
  {
    label: 'System prompt',
    fieldName: 'systemPrompt',
  },
  {
    label: 'Examples',
    fieldName: 'examples',
  },
  {
    label: 'Memory summary prompt',
    fieldName: 'memorySummaryPrompt',
  },
  {
    label: 'Knowledge prompt',
    fieldName: 'knowledgePrompt',
  },
  {
    label: 'Q&A knowledge prompt',
    fieldName: 'qAndAKnowledgePrompt',
  },
  {
    label: 'Recent prompt',
    fieldName: 'recentPrompt',
  },
];

const promptsSizes = [
  {
    label: 'Knowledge Max Chars',
    name: 'knowledgeMaxChars',
    placeholder: '20000',
  },
  {
    label: 'Embedding Chunk Chars',
    name: 'embeddingChunkChars',
    placeholder: '4000',
  },
  {
    label: 'Recent History Max Chars',
    name: 'recentHistoryMaxChars',
    placeholder: '1000',
  },
  {
    label: 'Recent History Min Messages',
    name: 'recentHistoryMinMessages',
    placeholder: '10',
  },
  {
    label: 'Summarize Messages',
    name: 'summarizeMessages',
    placeholder: '50',
  },
];

const AdvancedLlmSettings: FC<IAdvancedLlmSettingsProps> = ({
  userProject,
  advancedLlmSettingsForm,
  handleChange,
  messageApi,
  ownerIdOfCurrentOrg,
}) => {
  const { orgId } = useParams();
  const { currentUserSubscription, subscriptionIsActive } = useAppSelector(
    (state) => state.subscriptionReducer
  );
  const { engineOptions } = useAppSelector((state) => state.projectReducer);
  const { user } = useAppSelector((state) => state.userReducer);
  const { currentOrganization } = useAppSelector(
    (state) => state.organizationsReducer
  );
  const {
    data: promptsCommandsTemplates,
    isLoading: promptsCommandsTemplatesLoading,
  } = projectsAPI.useGetProjectPromptsCommandsTemplatesQuery({
    projectId: userProject.id,
    orgId,
  });
  const [modelLabel, setModelLabel] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState<ILexicalCommandModalStateProps>({
    title: '',
    description: '',
    options: [],
    handleOkCb: () => {},
  });

  useEffect(() => {
    handleEngineChange(userProject.engine);
  }, [userProject]);

  const handleLexicalFieldChange = (fieldName: string, value: string) => {
    if (!advancedLlmSettingsForm) return;
    advancedLlmSettingsForm.setFieldValue(fieldName, value);
    handleChange();
  };

  const handleEngineChange = (value: string) => {
    const description =
      engineOptions.find((option) => option.value === value)?.description || '';

    setModelLabel(description);

    handleChange();
  };

  const canEditAdvancedPrompts = () => {
    return subscriptionIsActive && advancedPromptEditingIsAvailable();
  };

  const advancedPromptEditingIsAvailable = () => {
    if (!currentUserSubscription) return false;
    return currentUserSubscription.currentPlan.features.advanced_prompt_editing;
  };

  const promptSizeFormItem = (
    label: string,
    name: string,
    placeholder: string
  ) => {
    return (
      <Form.Item label={label} name={name} key={name}>
        <InputNumber
          placeholder={placeholder}
          disabled={!canEditAdvancedPrompts()}
          onChange={handleChange}
        />
      </Form.Item>
    );
  };

  const getAdvancedLlmSettingsForm = () => {
    return (
      <Form
        form={advancedLlmSettingsForm}
        layout="vertical"
        onChange={handleChange}
        requiredMark={false}
      >
        <EngineOptionsFormItem
          modelLabel={modelLabel}
          currentUserSubscription={currentUserSubscription}
          disabled={!subscriptionIsActive}
          handleEngineChange={handleEngineChange}
          projectId={userProject.id}
        />
        {advancedPromptEditingIsAvailable() ? null : (
          <div className="advanced-no-access advanced-no-access--advanced">
            {currentOrganization &&
            user &&
            ownerIdOfCurrentOrg &&
            ownerIdOfCurrentOrg !== user.id ? (
              <>
                The organization does not have access to these options. Ask your
                organization owner to upgrade the plan to open these options.
              </>
            ) : (
              <>
                Your plan does not have access to these options. To open these
                prompts editing,{' '}
                <Link
                  to={`${RouteNames.PROFILE_SETTINGS_NO_PARAMS}/${ProfileSettingsType.PLAN}`}
                  className={'advanced-no-access-link'}
                >
                  upgrade your plan
                </Link>{' '}
                to Premium or higher.
              </>
            )}
          </div>
        )}
        {prompts.map((prompt, index) => {
          return (
            <div key={index}>
              {getLexicalPromptFormItem(
                prompt.label,
                prompt.fieldName,
                canEditAdvancedPrompts(),
                userProject,
                handleLexicalFieldChange,
                modalProps,
                setModalProps,
                setModalIsOpen,
                promptsCommandsTemplates
              )}
            </div>
          );
        })}
        {modalProps && (
          <LexicalCommandPropsModal
            isOpen={modalIsOpen}
            setIsOpen={setModalIsOpen}
            {...modalProps}
          />
        )}
        {promptsSizes.map((promptSize, index) => {
          return promptSizeFormItem(
            promptSize.label,
            promptSize.name,
            promptSize.placeholder
          );
        })}
        <Form.Item label="Creativity" name="creativity">
          <InputNumber
            min={0}
            max={1}
            step={0.1}
            placeholder={'0'}
            disabled={!canEditAdvancedPrompts()}
            onChange={handleChange}
          />
        </Form.Item>
      </Form>
    );
  };

  return <>{getAdvancedLlmSettingsForm()}</>;
};

export default AdvancedLlmSettings;
