import React from 'react';
import { hexToFeColorMatrix } from '../../shared/color-helpers';

export enum FilterNames {
  GLOW = 'combinedGlowFilter',
  SHADOW = 'combinedBoxShadow',
  SHADOW_AND_GLOW = 'combinedFilters',
}

export interface IGlowColorMatrices {
  color1Matrix: string;
  color2Matrix: string;
}

interface IBodyFilter {
  defs: JSX.Element;
  name: string;
}

const defaultFeColorMatrix1 = `0 0 0 0 0.4,
0 0 0 0 0,
  0 0 0 0 0.9,
  0 0 0 1 0`;
// or
// values="0 0 0 0 0.2,
// 0 0 0 0 0,
// 0 0 0 0 0.9,
// 0 0 0 1 0";

const defaultFeColorMatrix2 = `0 0 0 0 0,
            0 0 0 0 0.1,
            0 0 0 0 1,
            0 0 0 1 0`;
// or
// values="0 0 0 0 0,
// 0 0 0 0 0.2,
// 0 0 0 0 1,
// 0 0 0 1 0"

export const getGlowFeColorMatrices = (
  glowColors: string[]
): IGlowColorMatrices => {
  const colorMatrixValues = {
    color1Matrix: defaultFeColorMatrix1,
    color2Matrix: defaultFeColorMatrix2,
  };

  if (glowColors.length === 1) {
    colorMatrixValues.color1Matrix = hexToFeColorMatrix(glowColors[0]);
    colorMatrixValues.color2Matrix = hexToFeColorMatrix(glowColors[0]);
  } else if (glowColors.length >= 2) {
    colorMatrixValues.color1Matrix = hexToFeColorMatrix(glowColors[0]);
    colorMatrixValues.color2Matrix = hexToFeColorMatrix(glowColors[1]);
  }

  return colorMatrixValues;
};

export function getShadowGlowFilter(
  shadowEnabled: boolean,
  glowEnabled: boolean,
  glowColorMatrices: IGlowColorMatrices
): IBodyFilter {
  if (!shadowEnabled && !glowEnabled) {
    return {
      defs: <></>,
      name: '',
    };
  } else if (shadowEnabled && !glowEnabled) {
    return {
      defs: getCombinedShadowFilter(),
      name: FilterNames.SHADOW,
    };
  } else if (!shadowEnabled && glowEnabled) {
    return {
      defs: getCombinedGlowFilter(
        glowColorMatrices.color1Matrix,
        glowColorMatrices.color2Matrix
      ),
      name: FilterNames.GLOW,
    };
  } else {
    return {
      defs: getCombinedShadowAndGlowFilter(
        glowColorMatrices.color1Matrix,
        glowColorMatrices.color2Matrix
      ),
      name: FilterNames.SHADOW_AND_GLOW,
    };
  }
}

export function getCombinedGlowFilter(
  glowColorMatrix1: string,
  glowColorMatrix2: string
) {
  return (
    <filter
      id={FilterNames.GLOW}
      x="-100%"
      y="-100%"
      width="300%"
      height="300%"
    >
      {getGlowFilter1(glowColorMatrix1)}
      {getGlowFilter2(glowColorMatrix2)}

      <feMerge>
        <feMergeNode in="coloredBlur1" />
        <feMergeNode in="coloredBlur2" />
        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>
  );
}

export function getCombinedShadowFilter() {
  return (
    <filter
      id={FilterNames.SHADOW}
      x="-100%"
      y="-100%"
      width="300%"
      height="300%"
    >
      {getBoxShadowFilter1()}
      {getBoxShadowFilter2()}

      <feMerge>
        <feMergeNode in="offsetblur3" />
        <feMergeNode in="offsetblur4" />
        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>
  );
}

export function getCombinedShadowAndGlowFilter(
  glowColorMatrix1: string,
  glowColorMatrix2: string
) {
  return (
    <filter
      id={FilterNames.SHADOW_AND_GLOW}
      x="-100%"
      y="-100%"
      width="300%"
      height="300%"
    >
      {getGlowFilter1(glowColorMatrix1)}
      {getGlowFilter2(glowColorMatrix2)}
      {getBoxShadowFilter1()}
      {getBoxShadowFilter2()}

      <feMerge>
        <feMergeNode in="coloredBlur1" />
        <feMergeNode in="coloredBlur2" />
        <feMergeNode in="offsetblur3" />
        <feMergeNode in="offsetblur4" />
        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>
  );
}

function getGlowFilter1(glowColorMatrix: string) {
  return (
    <>
      <feComponentTransfer in="SourceAlpha">
        <feFuncA type="table" tableValues="0 0.5"></feFuncA>
      </feComponentTransfer>
      <feGaussianBlur stdDeviation="100" />
      <feOffset dx="0" dy="4" result="offsetblur1" />
      <feColorMatrix
        in="offsetblur1"
        type="matrix"
        values={glowColorMatrix}
        result="coloredBlur1"
        colorInterpolationFilters="sRGB"
      />
    </>
  );
}

function getGlowFilter2(glowColorMatrix: string) {
  return (
    <>
      <feComponentTransfer in="SourceAlpha">
        <feFuncA type="table" tableValues="0 0.25"></feFuncA>
      </feComponentTransfer>
      <feGaussianBlur stdDeviation="25" />
      <feOffset dx="0" dy="21" result="offsetblur2" />
      <feColorMatrix
        in="offsetblur2"
        type="matrix"
        values={glowColorMatrix}
        result="coloredBlur2"
        colorInterpolationFilters="sRGB"
      />
    </>
  );
}

function getBoxShadowFilter1() {
  return (
    <>
      <feComponentTransfer in="SourceAlpha">
        <feFuncA type="table" tableValues="0 0.25"></feFuncA>
      </feComponentTransfer>
      <feGaussianBlur stdDeviation="16" />
      <feOffset dx="0" dy="8" result="offsetblur3" />
    </>
  );
}

function getBoxShadowFilter2() {
  return (
    <>
      <feComponentTransfer in="SourceAlpha">
        <feFuncA type="table" tableValues="0 0.25"></feFuncA>
      </feComponentTransfer>
      <feGaussianBlur stdDeviation="8" />
      <feOffset dx="0" dy="2" result="offsetblur4" />
    </>
  );
}
