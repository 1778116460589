import { Rule } from 'antd/es/form';
import {
  V_PASSWORD_LOWER_UPPER_DIGIT,
  V_PASSWORD_MIN_LENGTH,
  V_REQUIRED_FIELD,
  regexpLowerUpperDigit,
} from './constants';

export const passwordRules = [
  {
    required: true,
    message: V_REQUIRED_FIELD,
  },
  {
    min: 6,
    message: V_PASSWORD_MIN_LENGTH,
  },
  {
    pattern: regexpLowerUpperDigit,
    message: V_PASSWORD_LOWER_UPPER_DIGIT,
  },
];

export const emailRules: Rule[] = [
  {
    type: 'email',
    message: 'Enter a valid E-mail.',
  },
  {
    required: true,
    message: V_REQUIRED_FIELD,
  },
];
