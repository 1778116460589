import {
  AnalyticsData,
  IAnalyticsEventsData,
  WIDGET_ANALYTICS_DATA_KEY,
} from '../../components/project-settings/analytics/analytics-data';
import { IInsightsQueryResult } from '../../services/usage-service';
import { useEffect, useState } from 'react';
import IProjectPlugin from '../../types/IProjectPlugin';
import { useParams } from 'react-router-dom';

export function useGetAnalyticsData(
  projectId: string,
  date: string,
  analyticsList: IAnalyticsEventsData[],
  currentPluginsData: IProjectPlugin[]
): [IInsightsQueryResult | null, boolean] {
  const { orgId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [combinedQueryResult, setCombinedQueryResult] =
    useState<IInsightsQueryResult | null>(null);

  const fetchers: {
    [key: string]: (params: {
      projectId: string;
      orgId: string | undefined;
      date: string;
    }) => Promise<any>;
  } = {};

  for (let key in AnalyticsData) {
    const [dataFetcher] = AnalyticsData[key].getData();
    fetchers[key] = dataFetcher;
  }

  const fetchData = async () => {
    let queryResults: IInsightsQueryResult[] = [];

    const widgetPerDay = await fetchers[WIDGET_ANALYTICS_DATA_KEY]({
      projectId,
      orgId,
      date,
    });

    if (widgetPerDay.data) {
      queryResults.push(widgetPerDay.data);
    }

    const pluginsNames = currentPluginsData.map((p) => p.name);

    for (let i = 0; i < pluginsNames.length; i++) {
      const pluginName = pluginsNames[i];
      if (fetchers[pluginName]) {
        const res = await fetchers[pluginName]({ projectId, orgId, date });
        if (res.data) {
          queryResults.push(res.data);
        }
      }
    }

    let combinedResult: IInsightsQueryResult | null = null;

    if (queryResults.length > 1) {
      try {
        const qRes = queryResults.map((res) => res.queryResponse);
        const combinedResponse = combineQueryResponses(...qRes);
        combinedResult = {
          ...queryResults[0],
          queryResponse: JSON.stringify(combinedResponse),
        };
      } catch (e) {
        combinedResult = queryResults[0];
        console.error(e);
      }
    } else if (queryResults.length > 0) {
      combinedResult = queryResults[0];
    }

    setCombinedQueryResult(combinedResult);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [projectId, date, currentPluginsData]);

  return [combinedQueryResult, isLoading];
}

interface IColumn {
  name: string;
  type: string;
}

interface ITable {
  name: string;
  columns: IColumn[];
  rows: any[][];
}

interface IQueryResponse {
  tables: ITable[];
}

function combineQueryResponses(...queryResponses: string[]): IQueryResponse {
  const combinedResponse: IQueryResponse = { tables: [] };

  const transformedResponses =
    transformAllResponsesToUnifiedFormat(queryResponses);

  transformedResponses.forEach((responseStr) => {
    const response: IQueryResponse = JSON.parse(responseStr);
    response.tables.forEach((table) => {
      const existingTable = combinedResponse.tables.find(
        (t) => t.name === table.name
      );
      if (existingTable) {
        existingTable.rows.push(...table.rows);
      } else {
        combinedResponse.tables.push({ ...table });
      }
    });
  });

  return combinedResponse;
}

function transformAllResponsesToUnifiedFormat(responses: string[]): string[] {
  const transformedResponses: string[] = [];

  const parsedResponses = responses.map((responseStr) =>
    JSON.parse(responseStr)
  );
  const maxColumns = Math.max(
    ...parsedResponses.map((r) => r.tables[0].columns.length)
  );
  const resultTableCols = parsedResponses.find(
    (r) => r.tables[0].columns.length === maxColumns
  )?.tables[0].columns;

  if (!resultTableCols) {
    return responses;
  }

  parsedResponses.forEach((response) => {
    if (response.tables[0].columns.length === maxColumns) {
      transformedResponses.push(JSON.stringify(response));
      return;
    }
    const currentTableCols = response.tables[0].columns;
    const transformedResponse = {
      tables: [
        {
          ...response.tables[0],
          columns: resultTableCols,
          rows: response.tables[0].rows.map((row: any) => {
            const newRow = [];
            for (let i = 0; i < resultTableCols.length; i++) {
              const colName = resultTableCols[i].name;
              const currentColIndex = currentTableCols.findIndex(
                (col: IColumn) => col.name === colName
              );
              if (currentColIndex !== -1) {
                newRow.push(row[currentColIndex]);
              } else {
                newRow.push('');
              }
            }
            return newRow;
          }),
        },
      ],
    };
    transformedResponses.push(JSON.stringify(transformedResponse));
  });

  return transformedResponses;
}
