import * as i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

//use REACT_APP_SDK_NAME for the language

export const i18nKeys = {
  TRY_IT_OUT: 'tryItOut',
};

i18n
  // // detect user language
  // // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'zappr',
    resources: {
      zappr: {
        translation: {
          [i18nKeys.TRY_IT_OUT]: 'Try it out',
        },
      },
      enigx: {
        translation: {
          [i18nKeys.TRY_IT_OUT]: 'Live test',
        },
      },
    },
  });

export default i18n;
