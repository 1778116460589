import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { projectsAPI } from '../../../services/projects-service';
import { Form, message, Modal, Select, Skeleton } from 'antd';
import { V_REQUIRED_FIELD } from '../../../shared/constants';
import { showErrorMessage } from '../../../shared/helpers';
import TextArea from 'antd/es/input/TextArea';
import { GA_UPLOAD_QA } from '../../../shared/google-analytics';
import { IngestQATypes, IngestQATypeOption } from './qa-ingest-shared-types';
import CommandsHelper, {
  ICommandList,
  IGetCommandLabel,
} from './commands-helper';
import IIngestQAContent from '../../../types/IIngestQAContent';

interface IQAIngestUpdateModalWithCommandsProps {
  isOpen: boolean;
  closeModal: () => void;
  projectId: string;
  knowledgeId: string;
  setQaUpdateKnowledgeId: Dispatch<SetStateAction<string>>;
  cb: () => void;
}

interface IQaIngestUpdateModalProps
  extends IQAIngestUpdateModalWithCommandsProps {
  commandList: ICommandList[];
  getCommandLabel: IGetCommandLabel;
}

const QaIngestUpdateModal: FC<IQaIngestUpdateModalProps> = ({
  commandList,
  getCommandLabel,
  isOpen,
  closeModal,
  projectId,
  knowledgeId,
  setQaUpdateKnowledgeId,
  cb,
}) => {
  const {
    data: qaKnowledgeContent,
    isLoading: qaKnowledgeContentLoading,
    error: qaKnowledgeContentError,
  } = projectsAPI.useGetProjectKnowledgeIngestQAQuery(
    { id: projectId, knowledgeId },
    { skip: !knowledgeId }
  );
  const [editProjectKnowledgeIngestQA, { isLoading }] =
    projectsAPI.useEditProjectKnowledgeIngestQAMutation();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [okDisabled, setOkDisabled] = useState<boolean>(true);
  const [currentType, setCurrentType] = useState<string>(
    IngestQATypes.USER_ASKS
  );
  const [currentCommand, setCurrentCommand] = useState<string>('');

  useEffect(() => {
    if (form) {
      form.setFieldsValue({
        ...qaKnowledgeContent,
        question: qaKnowledgeContent?.content,
      });

      setCurrentType(qaKnowledgeContent?.type || '');
      setCurrentCommand(qaKnowledgeContent?.command || '');
    }
  }, [qaKnowledgeContent, form, isOpen]);

  useEffect(() => {
    handleChange();
  }, [currentCommand]);

  const handleOk = async () => {
    setOkDisabled(true);

    let values = form.getFieldsValue();
    const typeIsCommandResponds =
      values.type === IngestQATypes.COMMAND_RESPONDS;

    if (typeIsCommandResponds) {
      values = {
        ...values,
        command: currentCommand,
      };
    }

    const result = await editProjectKnowledgeIngestQA({
      id: projectId,
      knowledgeId,
      body: values,
    });

    if (result) {
      if ('error' in result) {
        showErrorMessage(messageApi, result.error);
      } else {
        if (cb) {
          cb();
        }
        GA_UPLOAD_QA(true);
        await messageApi.success(`Q&A have been updated.`, 1);
        form.resetFields();
        handleCancel();
      }
    }

    setOkDisabled(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setCurrentType(IngestQATypes.USER_ASKS);
    setCurrentCommand('');
    closeModal();
    setQaUpdateKnowledgeId('');
  };

  const handleChangeType = (value: string) => {
    form.setFieldValue('type', value);
    setCurrentType(value);
    handleChange();
  };

  const handleChangeCommand = (value: string) => {
    setCurrentCommand(value);
  };

  const handleChange = async () => {
    if (!qaKnowledgeContent) return;

    const values = form.getFieldsValue();

    const typeIsCommandResponds =
      values.type === IngestQATypes.COMMAND_RESPONDS;

    if (typeIsCommandResponds && !currentCommand) {
      setOkDisabled(true);
      return;
    }

    const commandIsChanged = qaKnowledgeContent.command !== currentCommand;

    if (commandIsChanged) {
      setOkDisabled(false);
      return;
    }

    for (const key in values) {
      if (
        values[key] === undefined ||
        values[key] === null ||
        values[key] === ''
      ) {
        setOkDisabled(true);
        return;
      }
    }

    for (const key in values) {
      if (key === 'question') {
        if (values[key] !== qaKnowledgeContent.content) {
          setOkDisabled(false);
          return true;
        }
      } else if (
        values[key] !== qaKnowledgeContent[key as keyof IIngestQAContent]
      ) {
        setOkDisabled(false);
        return;
      }
    }

    setOkDisabled(true);
  };

  const shouldShowCommandsList = () => {
    return (
      currentType === IngestQATypes.COMMAND_RESPONDS && commandList.length > 0
    );
  };

  const getModalContent = () => {
    if (qaKnowledgeContentLoading) {
      return <Skeleton active />;
    }
    if (qaKnowledgeContentError) {
      return <div>Failed to load knowledge content.</div>;
    }
    if (qaKnowledgeContent) {
      return formElement();
    }
  };

  const formElement = () => (
    <Form
      form={form}
      onChange={handleChange}
      className="data-source-upload-modal-form"
      layout="vertical"
    >
      <div>
        <Form.Item
          name="type"
          rules={[
            {
              required: true,
              message: V_REQUIRED_FIELD,
            },
          ]}
          style={{ marginBottom: 10 }}
        >
          <>
            <span style={{ fontWeight: 500 }}>When</span>
            <Select
              style={{ width: 180, margin: '0 10px 10px' }}
              options={IngestQATypeOption.map((item) => ({
                label: item.label,
                value: item.value,
                disabled:
                  item.value === IngestQATypes.COMMAND_RESPONDS
                    ? !commandList.length
                    : false,
              }))}
              dropdownStyle={{ zIndex: 2000 }}
              onChange={(value) => handleChangeType(value)}
              value={currentType}
            />
            {shouldShowCommandsList() && (
              <Select
                style={{ width: '100%', maxWidth: '464px', minWidth: '238px' }}
                options={commandList.map((item) => ({
                  label: (
                    <span style={{ textTransform: 'capitalize' }}>
                      {getCommandLabel(item.pluginName, item.commandName)}
                    </span>
                  ),
                  value: item.command,
                }))}
                dropdownStyle={{ zIndex: 2000 }}
                onChange={(value) => handleChangeCommand(value)}
                optionLabelProp="label"
                value={currentCommand}
              />
            )}
          </>
        </Form.Item>
      </div>
      <Form.Item
        name="question"
        rules={[
          {
            required: true,
            message: V_REQUIRED_FIELD,
          },
        ]}
        className={'text-source-modal__text'}
      >
        <TextArea
          rows={4}
          showCount
          maxLength={2000}
          placeholder={`Q: What’s the weather today?`}
        />
      </Form.Item>
      <div style={{ fontWeight: 500, marginBottom: 10 }}>Reply this:</div>
      <Form.Item
        name="answer"
        rules={[
          {
            required: true,
            message: V_REQUIRED_FIELD,
          },
        ]}
        className={'text-source-modal__text'}
      >
        <TextArea
          rows={4}
          showCount
          maxLength={2000}
          placeholder={`A: I’m not a weather forecasting system. Do you have other questions?`}
        />
      </Form.Item>
    </Form>
  );

  return (
    <Modal
      title="Q&A Ingest"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered={true}
      okButtonProps={{
        disabled: okDisabled || isLoading,
        loading: isLoading,
      }}
      okText={'Ingest'}
      className={'text-source-modal'}
      wrapClassName={'modal'}
      maskStyle={{ zIndex: 1100 }}
    >
      {contextHolder}
      {getModalContent()}
    </Modal>
  );
};

const QAIngestUpdateModalWithCommands: FC<
  IQAIngestUpdateModalWithCommandsProps
> = ({
  isOpen,
  closeModal,
  projectId,
  knowledgeId,
  setQaUpdateKnowledgeId,
  cb,
}) => {
  return (
    <CommandsHelper projectId={projectId}>
      {(commandList, getCommandLabel) => {
        return (
          <QaIngestUpdateModal
            commandList={commandList}
            getCommandLabel={getCommandLabel}
            isOpen={isOpen}
            closeModal={closeModal}
            projectId={projectId}
            knowledgeId={knowledgeId}
            setQaUpdateKnowledgeId={setQaUpdateKnowledgeId}
            cb={cb}
          />
        );
      }}
    </CommandsHelper>
  );
};

export default QAIngestUpdateModalWithCommands;
