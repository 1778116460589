import React, { FC } from 'react';
import IRecordItemProps from '../IRecordViewProps';
import { Divider } from 'antd';
import AddressRecordItem from './address-record-item';

const AddressRecordsView: FC<IRecordItemProps> = ({
  records,
  handleEditRecord,
  handleDeleteRecord,
}) => {
  return (
    <div>
      <Divider orientation="left" className={'contact-form-divider'}>
        Addresses
      </Divider>
      {records.map((record, index) => (
        <div key={index}>
          <AddressRecordItem
            record={record}
            handleEditRecord={handleEditRecord}
            handleDeleteRecord={handleDeleteRecord}
          />
          {index !== records.length - 1 && (
            <Divider
              orientation="left"
              className={'contact-form-divider'}
              dashed={true}
            ></Divider>
          )}
        </div>
      ))}
    </div>
  );
};

export default AddressRecordsView;
