import {
  ContactRecordType,
  IContactRecord,
  TServerContactRecord,
} from '../../../types/contacts/IContact';
import { EmailLabel } from '../../../types/contacts/contact-records/IEmailRecord';
import { suuid } from '../../../utils/suuid';
import { PhoneLabel } from '../../../types/contacts/contact-records/IPhoneRecord';
import EmailRecordsView from './records-views/email/email-records-view';
import PhoneRecordsView from './records-views/phone/phone-records-view';
import React from 'react';
import SocialRecordsView from './records-views/social/social-records-view';
import EmailRecordFormElement from './forms/email-record-form-element';
import PhoneRecordFormElement from './forms/phone-record-form-element';
import SocialRecordFormElement from './forms/social-record-form-element';
import NoteRecordFormElement from './forms/note-record-form-element';
import NoteRecordsView from './records-views/note/note-records-view';
import AddressRecordFormElement from './forms/address-record-form-element';
import AddressRecordsView from './records-views/address/address-records-view';
import { AddressLabel } from '../../../types/contacts/contact-records/IAddressRecord';
import CompanyRecordsView from './records-views/company/company-records-view';
import CompanyRecordFormElement from './forms/company-record-form-element';
import GeolocationRecordFormItem from './forms/geolocation-record-form-item';
import GeolocationRecordsView from './records-views/geolocation/geolocation-records-view';
import SystemInfoRecordFormElement from './forms/system-info-record-form-element';
import SystemInfoRecordsView from './records-views/system-info/system-info-records-view';
import CustomFieldRecordFormElement from './forms/custom-field-record-form-element';
import LinkedContactsView from './records-views/linked-contact/linked-contacts-view';
import LinkedContactRecordFormElement from './forms/linked-contact-record-form-element';
import DateRecordFormElement from './forms/date-record-form-element';
import DateRecordsView from './records-views/date/date-records-view';
import TagRecordsView from './records-views/tag/tag-records-view';
import TagRecordFormElement from './forms/tag-record-form-element';
import LanguageRecordFormElement from './forms/language-record-form-element';
import LanguageRecordsView from './records-views/language/language-records-view';
import { DateLabel } from '../../../types/contacts/contact-records/IDateRecord';
import CustomFieldRecordsView from './records-views/custom-field/custom-field-records-view';

const NEW_RECORD_ID_PREFIX = 'new-contact-record';

export default class ContactsHelpers {
  public static getNewRecordFormInitialValues(
    recordType: ContactRecordType | null
  ) {
    switch (recordType) {
      case ContactRecordType.EMAIL:
        return {
          label: EmailLabel.PERSONAL,
        };
      case ContactRecordType.PHONE:
        return {
          label: PhoneLabel.MOBILE,
        };
      case ContactRecordType.ADDRESS: {
        return {
          label: AddressLabel.HOME,
        };
      }
      case ContactRecordType.DATE: {
        return {
          label: DateLabel.BIRTHDAY,
          useYear: true,
        };
      }
      default:
        return {};
    }
  }

  public static generateNewRecordId() {
    return `${NEW_RECORD_ID_PREFIX}-${suuid()}`;
  }

  public static isRecordNew(recordId: string) {
    return recordId.startsWith(NEW_RECORD_ID_PREFIX);
  }

  public static transformTServerContactRecordsToIContactRecords(
    records: TServerContactRecord[]
  ): IContactRecord[] {
    return records.map((record) => {
      const newRecord = JSON.parse(JSON.stringify(record));
      delete newRecord.id;
      delete newRecord.contactId;
      delete newRecord.type;

      return {
        id: record.id,
        contactId: record.contactId,
        type: record.type,
        record: newRecord,
      };
    });
  }

  public static getRecordView(
    records: IContactRecord[],
    type: ContactRecordType,
    handleEditRecord: (record: IContactRecord) => void,
    handleDeleteRecord: (record: IContactRecord) => void
  ) {
    const recordsOfType = records.filter((record) => record.type === type);

    if (recordsOfType.length === 0) {
      return null;
    }

    switch (type) {
      case ContactRecordType.EMAIL:
        return (
          <EmailRecordsView
            key={suuid()}
            records={recordsOfType}
            handleEditRecord={handleEditRecord}
            handleDeleteRecord={handleDeleteRecord}
          />
        );
      case ContactRecordType.PHONE:
        return (
          <PhoneRecordsView
            key={suuid()}
            records={recordsOfType}
            handleEditRecord={handleEditRecord}
            handleDeleteRecord={handleDeleteRecord}
          />
        );
      case ContactRecordType.SOCIAL:
        return (
          <SocialRecordsView
            key={suuid()}
            records={recordsOfType}
            handleEditRecord={handleEditRecord}
            handleDeleteRecord={handleDeleteRecord}
          />
        );
      case ContactRecordType.NOTE:
        return (
          <NoteRecordsView
            key={suuid()}
            records={recordsOfType}
            handleEditRecord={handleEditRecord}
            handleDeleteRecord={handleDeleteRecord}
          />
        );
      case ContactRecordType.ADDRESS:
        return (
          <AddressRecordsView
            key={suuid()}
            records={recordsOfType}
            handleEditRecord={handleEditRecord}
            handleDeleteRecord={handleDeleteRecord}
          />
        );
      case ContactRecordType.COMPANY:
        return (
          <CompanyRecordsView
            key={suuid()}
            records={recordsOfType}
            handleEditRecord={handleEditRecord}
            handleDeleteRecord={handleDeleteRecord}
          />
        );
      case ContactRecordType.GEOLOCATION:
        return (
          <GeolocationRecordsView
            key={suuid()}
            records={recordsOfType}
            handleEditRecord={handleEditRecord}
            handleDeleteRecord={handleDeleteRecord}
          />
        );
      // case ContactRecordType.SYSTEM_INFO:
      //   return (
      //     <SystemInfoRecordsView
      //       key={suuid()}
      //       records={recordsOfType}
      //       handleEditRecord={handleEditRecord}
      //       handleDeleteRecord={handleDeleteRecord}
      //     />
      //   );
      case ContactRecordType.CUSTOM_FIELD:
        return (
          <CustomFieldRecordsView
            key={suuid()}
            records={recordsOfType}
            handleEditRecord={handleEditRecord}
            handleDeleteRecord={handleDeleteRecord}
          />
        );
      // case ContactRecordType.LINKED_CONTACT:
      //   return (
      //     <LinkedContactsView
      //       key={suuid()}
      //       records={recordsOfType}
      //       handleEditRecord={handleEditRecord}
      //       handleDeleteRecord={handleDeleteRecord}
      //     />
      //   );
      case ContactRecordType.DATE:
        return (
          <DateRecordsView
            key={suuid()}
            records={recordsOfType}
            handleEditRecord={handleEditRecord}
            handleDeleteRecord={handleDeleteRecord}
          />
        );
      case ContactRecordType.TAG:
        return (
          <TagRecordsView
            key={suuid()}
            records={recordsOfType}
            handleEditRecord={handleEditRecord}
            handleDeleteRecord={handleDeleteRecord}
          />
        );
      case ContactRecordType.LANGUAGE:
        return (
          <LanguageRecordsView
            key={suuid()}
            records={recordsOfType}
            handleEditRecord={handleEditRecord}
            handleDeleteRecord={handleDeleteRecord}
          />
        );
      default:
        return null;
    }
  }

  public static getFormElement(
    recordType: ContactRecordType,
    initialLabelValue: { label: string; customLabel: string } = {
      label: '',
      customLabel: '',
    },
    useYearInitialValue: boolean = true
  ) {
    switch (recordType) {
      case ContactRecordType.EMAIL:
        return <EmailRecordFormElement initialLabelValue={initialLabelValue} />;
      case ContactRecordType.PHONE:
        return <PhoneRecordFormElement initialLabelValue={initialLabelValue} />;
      case ContactRecordType.SOCIAL:
        return <SocialRecordFormElement />;
      case ContactRecordType.NOTE:
        return <NoteRecordFormElement />;
      case ContactRecordType.ADDRESS:
        return (
          <AddressRecordFormElement initialLabelValue={initialLabelValue} />
        );
      case ContactRecordType.COMPANY:
        return <CompanyRecordFormElement />;
      case ContactRecordType.GEOLOCATION:
        return <GeolocationRecordFormItem />;
      // case ContactRecordType.SYSTEM_INFO:
      //   return <SystemInfoRecordFormElement />;
      case ContactRecordType.CUSTOM_FIELD:
        return <CustomFieldRecordFormElement />;
      // case ContactRecordType.LINKED_CONTACT:
      //   return <LinkedContactRecordFormElement />;
      case ContactRecordType.DATE:
        return (
          <DateRecordFormElement
            initialLabelValue={initialLabelValue}
            useYearInitialValue={useYearInitialValue}
          />
        );
      case ContactRecordType.TAG:
        return <TagRecordFormElement />;
      case ContactRecordType.LANGUAGE:
        return <LanguageRecordFormElement />;
      default:
        return null;
    }
  }
}
