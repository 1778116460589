import React, { useEffect, useState } from 'react';
import './share-conversation.scss';
import { useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { projectsAPI } from '../../services/projects-service';
import { Result, Skeleton } from 'antd';
import ShareConversationMessages from '../../components/share-conversation-messages/share-conversation-messages';
import { WrapperClassName } from '../../components/dashboard-header/dashboard-header';

const ShareConversation = () => {
  const { token } = useParams();
  const [lazyGetSharedConversation, { data: data, isLoading, error }] =
    projectsAPI.useLazyGetSharedConversationQuery();
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [conversationInfo, setConversationInfo] = useState<{
    projectId: string;
    chatId: string;
  } | null>(null);

  useEffect(() => {
    if (!token) return;

    let projectId = '';

    try {
      const decoded: { projectId: string; chatId: string } = jwtDecode(token);
      setConversationInfo(decoded);

      if (!decoded.projectId) {
        setErrorMessage("Can't get the message history.");
      } else {
        setErrorMessage('');
        projectId = decoded.projectId;
      }
    } catch (e) {
      setErrorMessage("Can't get the message history.");
    }

    if (projectId && token) {
      lazyGetSharedConversation({ projectId, token });
    }
  }, [token]);

  const getComponent = () => {
    if (!token) {
      return errorComponent('Invalid token.');
    }

    if (errorMessage || error) {
      return errorComponent(errorMessage);
    }

    if (isLoading) {
      return <Skeleton active />;
    }

    if (data && conversationInfo) {
      const { messages } = data;

      return (
        <ShareConversationMessages
          conversationMessages={messages}
          projectId={conversationInfo.projectId}
          chatId={conversationInfo.chatId}
        />
      );
    }
  };

  const errorComponent = (errorMessage: string) => {
    return (
      <Result
        status="error"
        title="Ooops, something went wrong."
        subTitle={errorMessage}
      />
    );
  };

  useEffect(() => {
    const header = document.getElementsByClassName(
      WrapperClassName
    )[0] as HTMLElement;

    if (!header) {
      return;
    }

    header.style.display = 'none';

    if (data) {
      const { isCustomBranding } = data;

      if (!isCustomBranding) {
        header.style.display = '';
      }
    }
  });

  return (
    <div className={'share-conversation'}>
      <div className="container">
        <div className={'share-conversation__inner'}>
          <div className="insight conversation conversation--share">
            {getComponent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareConversation;
