import React, { FC } from 'react';
import { Button, Form, Input, Tooltip } from 'antd';
import {
  QuestionCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  PluginNames,
  PluginValidators,
  V_REQUIRED_FIELD,
} from '../../../../shared/constants';
import { isValidEmail } from '../../../../shared/helpers';
import IDefaultPluginFieldProps from './IDefaultPluginField';

interface IArrayKeyValueInputFieldProps extends IDefaultPluginFieldProps {
  pluginName: string;
}

interface ValueValidationResult {
  value: string;
  isValid: boolean;
  message: string;
}

function validateFewEmails(value: string) {
  const emails = value.split(/[,\s]+/); // Split by comma and/or space
  for (const email of emails) {
    if (!isValidEmail(email)) {
      return false;
    }
  }
  return true;
}

const ArrayKeyValueInputField: FC<IArrayKeyValueInputFieldProps> = ({
  displayName,
  keyBase,
  index,
  isRequired,
  description,
  convertValue,
  pluginName,
}) => {
  const validators: {
    keyValidators: PluginValidators[];
    valueValidators: PluginValidators[];
  } = {
    keyValidators: [],
    valueValidators: [],
  };

  const placeholders = { key: 'Key', value: 'Value' };

  if (pluginName === PluginNames.ADF_PLUGIN) {
    placeholders.key = 'Department';
    placeholders.value = 'Email';

    validators.valueValidators = [PluginValidators.FEW_EMAILS];
  }

  const initialValues = convertValue.length > 0 ? convertValue : [];

  const validateValue = (value: string, isKey: boolean) => {
    const result: ValueValidationResult = {
      value,
      isValid: true,
      message: '',
    };

    if (isRequired && (!value || value.length === 0)) {
      result.isValid = false;
      result.message = V_REQUIRED_FIELD;
    }

    const currentValidators = isKey
      ? validators.keyValidators
      : validators.valueValidators;

    if (currentValidators.length > 0) {
      for (let validator of currentValidators) {
        if (validator === PluginValidators.FEW_EMAILS) {
          if (!validateFewEmails(value)) {
            result.isValid = false;
            result.message = 'Invalid Emails';
          }
        }
        // if (validator === PluginValidators.EMAIL) {
        //   if (!isValidEmail(value)) {
        //     result.isValid = false;
        //     result.message = 'Invalid Email';
        //   }
        // }
      }
    }

    return result;
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 5,
          marginBottom: 8,
        }}
      >
        {displayName}
        {description && (
          <Tooltip title={description}>
            <span
              style={{
                marginTop: 2,
                color: 'rgba(60, 66, 87, 0.45)',
                cursor: 'help',
              }}
            >
              <QuestionCircleOutlined />
            </span>
          </Tooltip>
        )}
      </div>
      <Form.List name={keyBase} initialValue={initialValues} key={index}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div
                style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                key={key}
              >
                <Form.Item
                  {...restField}
                  name={[name, 'key']}
                  style={{ width: '100%' }}
                  rules={[
                    {
                      required: isRequired,
                      message: V_REQUIRED_FIELD,
                    },
                    {
                      validator: async (_, value) => {
                        if (value) {
                          const result = validateValue(value, true);
                          if (!result.isValid) {
                            return Promise.reject(result.message);
                          }
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input placeholder={placeholders.key} />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'value']}
                  style={{ width: '100%' }}
                  rules={[
                    {
                      required: isRequired,
                      message: V_REQUIRED_FIELD,
                    },
                    {
                      validator: async (_, value) => {
                        if (value) {
                          const result = validateValue(value, false);
                          if (!result.isValid) {
                            return Promise.reject(result.message);
                          }
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input placeholder={placeholders.value} />
                </Form.Item>
                <MinusCircleOutlined
                  onClick={() => remove(name)}
                  style={{ marginBottom: 24 }}
                />
              </div>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add field
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};

export default ArrayKeyValueInputField;
