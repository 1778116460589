import React, { FC, useEffect, useRef, useState } from 'react';
import { redirect, useParams } from 'react-router-dom';
import { RouteNames } from '../../router/router';
import './widget-pro-demo.scss';
import { projectsAPI } from '../../services/projects-service';
import { skipToken } from '@reduxjs/toolkit/query';
import Loading from '../../components/loading/loading';
import { Result } from 'antd';
import { connectWidgetV2Script } from '../../shared/helpers';
import { delay, ON_SDK_LOADED, SDK_NAME } from '../../shared/constants';

const WidgetProDemo: FC = () => {
  const { id } = useParams();
  const embedWidgetV2Ref = useRef<HTMLDivElement>(null);
  const {
    data: project,
    isLoading,
    error,
  } = projectsAPI.useGetProjectStartInfoQuery(id ?? skipToken);
  const [widgetV2ScriptLoading, setWidgetV2ScriptLoading] =
    useState<boolean>(false);

  useEffect(() => {
    if (!id) {
      redirect(RouteNames.USER_PROJECTS);
      return;
    } else {
      //@ts-ignore
      window.widgetConfigs = [
        {
          id: id,
          allowFullScreenMode: true,
          closeButton: true,
          rememberState: 'never',
          followPages: false,
        },
      ];
    }

    //@ts-ignore
    window[ON_SDK_LOADED] = () => {
      const f = async () => {
        //@ts-ignore
        window[SDK_NAME].setPosition('50%', '50%');
        await delay(100);
        alignWidget();
      };

      if (window.innerWidth >= 800) {
        f();
      }

      //@ts-ignore
      window[SDK_NAME].events.on('close', () => {
        alignWidget();
      });
    };
  }, [id]);

  useEffect(() => {
    const ref = embedWidgetV2Ref.current;

    if (!ref || isLoading) return;

    connectWidgetV2Script(setWidgetV2ScriptLoading);

    return () => {
      const script = document.getElementById('widgetScript');
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [embedWidgetV2Ref, isLoading]);

  const alignWidget = () => {
    const widget = document.getElementById('embed-widget');
    if (!widget) return;
    widget.style.setProperty('transform', 'translate(50%, 50%)');
  };

  return (
    <div className={'widget-pro-demo'}>
      {isLoading ? (
        <Loading />
      ) : error &&
        'data' in error &&
        error.data &&
        'message' in (error.data as { message: string }) ? (
        <Result
          status="error"
          title="Ooops, something went wrong."
          subTitle={(error.data as { message: string })['message']}
        />
      ) : (
        <>
          {widgetV2ScriptLoading && <Loading />}
          <div id="embed-widget" ref={embedWidgetV2Ref}></div>
        </>
      )}
    </div>
  );
};

export default WidgetProDemo;
