import {
  IEditorTableColumn,
  ITableColumnT,
  ITableDataT,
  ITableRecordsT,
  ITableRowT,
  ITableT,
} from '../../../types/tables-service/ITableT';
import { Column } from 'react-data-grid';
import { suuid } from '../../../utils/suuid';
import React from 'react';
import { SELECT_ROW_KEY } from '../../../shared/constants';

export default class TablesUtils {
  public static getTableHeight(
    rows: unknown[],
    rowHeight: number = 35,
    border: number = 1
  ): number {
    return rows.length * rowHeight + rowHeight * 2 + border * 2;
  }

  public static rowKeyGetter(
    row: { [key: string]: string },
    key: string = 'key'
  ): string {
    return row[key];
  }

  // tableDataName === tableData.name
  public static nameIsChanged(
    tableDataName: string,
    tableName: string
  ): boolean {
    let tName = tableDataName;

    if (TablesUtils.checkHasCSVExtension(tName)) {
      tName = TablesUtils.removeCSVExtension(tName);
    }

    return tName !== tableName;
  }

  public static getTableRows(
    tableRecordsData: ITableRecordsT,
    tableData: ITableT
  ): ITableRowT[] {
    if (tableRecordsData && tableData) {
      const data = tableRecordsData.data.map((item) => ({
        ...item.items,
        key: item.id,
      }));

      return data as unknown as { [key: string]: string }[];
    }

    return [];
  }

  public static generateTableDataFromRows(
    rows: { [key: string]: string }[],
    isNewRows: boolean
  ): ITableDataT[] {
    return rows.map((row) => ({
      items: row,
      id: isNewRows ? '' : row.key,
    }));
  }

  public static getChangedRows(rows: ITableRowT[], initialRows: ITableRowT[]) {
    const changedRows: ITableRowT[] = [];
    rows.forEach((newRow, index) => {
      const initialRow = initialRows[index];
      if (JSON.stringify(newRow) !== JSON.stringify(initialRow)) {
        changedRows.push(newRow);
      }
    });
    return changedRows;
  }

  public static getChangedColumns(
    columns: IEditorTableColumn[],
    initialColumns: IEditorTableColumn[]
  ) {
    const changedColumns: IEditorTableColumn[] = [];
    columns.forEach((newColumn) => {
      if (newColumn.key !== 'select-row') {
        const initialColumn = initialColumns.find(
          (col) => col.key === newColumn.key
        );

        if (!initialColumn) {
          changedColumns.push(newColumn);
        } else {
          for (let key in initialColumn) {
            if (
              newColumn[key as keyof IEditorTableColumn] !==
              initialColumn[key as keyof IEditorTableColumn]
            ) {
              changedColumns.push(newColumn);
              break;
            }
          }
        }
      }
    });
    return changedColumns;
  }

  public static transformEditorColumnsToPOST(
    columns: IEditorTableColumn[]
  ): ITableColumnT[] {
    const cols: ITableColumnT[] = columns
      .map((col) => {
        const c = col as unknown as ITableColumnT;

        let key = c.key.includes('new-col') ? '' : c.key;

        return {
          key: key,
          name: c.name,
          type: c.type,
          isSearchable: c.isSearchable,
          isUnique: c.isUnique,
        };
      })
      .filter((col) => col.key !== SELECT_ROW_KEY) as ITableColumnT[];

    return cols;
  }

  public static rowIsEmpty(row: ITableRowT) {
    for (let key in row) {
      if (row[key] && key !== 'key') {
        return false;
      }
    }
    return true;
  }

  public static hasNonEmptyRowsToAdd(rowsToAdd: ITableRowT[]) {
    let hasNonEmpty = false;
    for (let i = 0; i < rowsToAdd.length; i++) {
      const row = rowsToAdd[i];
      if (!TablesUtils.rowIsEmpty(row)) {
        hasNonEmpty = true;
        break;
      }
    }

    return hasNonEmpty;
  }

  public static createEmptyRow(columns: IEditorTableColumn[]) {
    const id = suuid();
    const emptyRow = Object.fromEntries(columns.map((col) => [col.key, '']));
    emptyRow.key = `new-row-${id}`;
    return emptyRow;
  }

  public static checkHasCSVExtension(name: string) {
    return name.includes('.') && name.split('.').pop() === 'csv';
  }

  public static removeCSVExtension(name: string) {
    return name.replace('.csv', '');
  }

  public static handleChangeColumn = (
    columnProps: ITableColumnT,
    setTableColumns: React.Dispatch<React.SetStateAction<IEditorTableColumn[]>>
  ) => {
    setTableColumns((prev) =>
      prev.map((col) =>
        col.key === columnProps.key ? { ...col, ...columnProps } : col
      )
    );
  };

  public static generateFormFieldsValuesFromColumns(columns: ITableColumnT[]) {
    return columns.reduce((acc, column) => {
      acc[`name-${column.key}`] = column.name;
      acc[`type-${column.key}`] = column.type;
      acc[`isSearchable-${column.key}`] = column.isSearchable;
      acc[`isUnique-${column.key}`] = column.isUnique;
      return acc;
    }, {} as { [key: string]: string | boolean | null });
  }

  public static getColWidth(
    col: ITableColumnT,
    tableRecordsData: ITableRecordsT
  ): number | string {
    const colLengths: { [key: string]: number } = {};

    let defaultColumnLength = (col.name || '').length;
    if (!colLengths[col.key]) {
      colLengths[col.key] = defaultColumnLength;
    }
    if (tableRecordsData.data) {
      tableRecordsData.data.forEach((record) => {
        const recordItems = record.items;
        for (const key in recordItems) {
          if (
            recordItems[col.key] &&
            recordItems[col.key].length > colLengths[col.key]
          ) {
            colLengths[col.key] = recordItems[col.key].length;
          }
        }
      });
    }

    const getWidth = (colKey: string) => {
      const colLength = colLengths[colKey] || 0;
      const filterWidth = 40;
      const paddingWidth = 8 * 2;
      const width = colLength * 10 + filterWidth + paddingWidth;
      const maxWidth = 350;
      return width < maxWidth ? 'auto' : maxWidth;
    };

    return getWidth(col.key);
  }
}
