import {
  getContrastYIQ,
  hexToRgb,
  mixColors,
  rgbaToHex,
} from '../../../../../../shared/color-helpers';

export enum WidgetThemeModes {
  DARK = 'dark',
  LIGHT = 'light',
}

export enum WidgetModes {
  FULL = 'full',
  MINI_CHAT = 'mini-chat',
  FLOAT_BUTTON = 'float-button',
}

function generateMessageTextColor(
  theme: IWidgetTheme,
  secondaryColorOpacity: number
) {
  const { mode, color } = theme;
  let themeColorRGB = hexToRgb(color);
  // let resultRGBThemeColor = themeColorRGB;
  let resultHexThemeColor = color;
  const bgColor =
    mode === WidgetThemeModes.DARK ? hexToRgb('#000000') : hexToRgb('#ffffff');
  if (secondaryColorOpacity < 1) {
    const mixedColor = mixColors(
      [bgColor.r, bgColor.g, bgColor.b, 1],
      [themeColorRGB.r, themeColorRGB.g, themeColorRGB.b, secondaryColorOpacity]
    );
    resultHexThemeColor = rgbaToHex(mixedColor);
    // resultRGBThemeColor = hexToRgb(resultHexThemeColor);
  }

  // const contrastWithBlack = contrast(
  //   [0, 0, 0],
  //   [resultRGBThemeColor.r, resultRGBThemeColor.g, resultRGBThemeColor.b]
  // );
  // const contrastWithWhite = contrast(
  //   [255, 255, 255],
  //   [resultRGBThemeColor.r, resultRGBThemeColor.g, resultRGBThemeColor.b]
  // );

  // const resultColor =
  //   contrastWithBlack > contrastWithWhite ? '#000000' : '#ffffff';

  const resultColor = getContrastYIQ(resultHexThemeColor);

  return resultColor;
}

export function generateWidgetStyleByTheme(
  theme: IWidgetTheme,
  secondaryColorOpacity: number
) {
  const { mode, color } = theme;

  const generatedStyle: IGeneratedWidgetStyle = {
    theme,
    secondaryColorOpacity,
    header: {},
    messageTextColor: {},
    inputPanel: {},
    buttonGeneralStyles: { hover: {}, active: {} },
  } as IGeneratedWidgetStyle;

  const botMessageTextColor = generateMessageTextColor(
    theme,
    secondaryColorOpacity
  );
  const userMessageTextColor = generateMessageTextColor(theme, 0);

  const setDarkMode = () => {
    generatedStyle.primaryColor = '#000000';
    generatedStyle.header.titleColor = '#ffffff';
    generatedStyle.header.subtitleColor = '#979797';
    // generatedStyle.messageTextColor.user = '#ffffff';
    // generatedStyle.messageTextColor.bot = '#ffffff';
    generatedStyle.inputPanel.textColor = '#D7D7D7';
    generatedStyle.inputPanel.borderColor = '#3A3A3C';
    generatedStyle.inputPanel.bgColor = '#060606';
    generatedStyle.inputPanel.bgOpacity = 0.5;
  };

  const setLightMode = () => {
    generatedStyle.primaryColor = '#FDFDFD';
    generatedStyle.header.titleColor = '#222222';
    generatedStyle.header.subtitleColor = '#787878';
    // generatedStyle.messageTextColor.user = '#222222';
    // generatedStyle.messageTextColor.bot = '#222222';
    generatedStyle.inputPanel.textColor = '#222222';
    generatedStyle.inputPanel.borderColor = '#BDBCB8';
    generatedStyle.inputPanel.bgColor = '#060606';
    generatedStyle.inputPanel.bgOpacity = 0.03;
  };

  switch (mode) {
    case WidgetThemeModes.LIGHT:
      setLightMode();
      break;
    default:
      setDarkMode();
      break;
  }

  generatedStyle.secondaryColor = color;
  generatedStyle.messageTextColor.user = userMessageTextColor;
  generatedStyle.messageTextColor.bot = botMessageTextColor;

  const secondaryColorRGBColor = hexToRgb(color);
  const secondaryColorRGBAColor = [
    secondaryColorRGBColor.r,
    secondaryColorRGBColor.g,
    secondaryColorRGBColor.b,
    1,
  ];

  const hoverOverlayRGBColor = hexToRgb('#ffffff');
  const hoverOverlayRGBAColor = [
    hoverOverlayRGBColor.r,
    hoverOverlayRGBColor.g,
    hoverOverlayRGBColor.b,
    0.2,
  ];

  const activeOverlayRGBColor = hexToRgb('#000000');
  const activeOverlayRGBAColor = [
    activeOverlayRGBColor.r,
    activeOverlayRGBColor.g,
    activeOverlayRGBColor.b,
    0.2,
  ];

  const hoverColorRGBA = mixColors(
    secondaryColorRGBAColor,
    hoverOverlayRGBAColor
  );
  const activeColorRGBA = mixColors(
    secondaryColorRGBAColor,
    activeOverlayRGBAColor
  );

  const hoverColorHEX = rgbaToHex(hoverColorRGBA);
  const activeColorHEX = rgbaToHex(activeColorRGBA);

  generatedStyle.buttonGeneralStyles.textColor = color;
  generatedStyle.buttonGeneralStyles.borderColor = color;
  generatedStyle.buttonGeneralStyles.hover.textColor = hoverColorHEX;
  generatedStyle.buttonGeneralStyles.hover.borderColor = hoverColorHEX;
  generatedStyle.buttonGeneralStyles.active.textColor = activeColorHEX;
  generatedStyle.buttonGeneralStyles.active.borderColor = activeColorHEX;

  // const glow = GLOW_COLOR_BY_THEME_COLOR[color] || GLOW_COLORS.purpleBlue;
  const glow = ['#8F00FF', '#0085FF'];
  generatedStyle.glowColors = glow;

  return generatedStyle;
}

interface IWidgetTheme {
  mode: WidgetThemeModes;
  color: string;
}

export interface IGeneratedWidgetStyle {
  theme: IWidgetTheme;
  primaryColor: string;
  secondaryColor: string;
  secondaryColorOpacity: number;
  header: {
    titleColor: string;
    subtitleColor: string;
  };
  messageTextColor: {
    user: string;
    bot: string;
  };
  inputPanel: {
    textColor: string;
    borderColor: string;
    bgColor: string;
    bgOpacity: number;
  };
  buttonGeneralStyles: {
    textColor: string;
    borderColor: string;
    hover: {
      textColor: string;
      borderColor: string;
    };
    active: {
      textColor: string;
      borderColor: string;
    };
  };
  glowColors: string[];
}
