import { ContactRecordType } from '../IContact';

export default interface IEmailRecord {
  type: ContactRecordType.EMAIL;
  emailAddress: string;
  label: EmailLabel;
  customLabel?: string;
}

export enum EmailLabel {
  PERSONAL = 'personal',
  WORK = 'work',
  OTHER = 'other',
}
