import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Form, Input, message, Modal, Select, Skeleton } from 'antd';
import { organizationsAPI } from '../../../services/organizations-service';
import { domainsAPI } from '../../../services/domains-service';
import { V_REQUIRED_FIELD } from '../../../shared/constants';
import { showErrorMessage } from '../../../shared/helpers';

interface ITransferOrganizationModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  organizationId: string;
}

const TransferOrganizationModal: FC<ITransferOrganizationModal> = ({
  isOpen,
  setIsOpen,
  organizationId,
}) => {
  const [transferOrganization, { isLoading }] =
    organizationsAPI.useTransferOrganizationMutation();
  const {
    data: domains,
    isLoading: domainsLoading,
    error: domainsError,
  } = domainsAPI.useGetDomainsQuery();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [okDisabled, setOkDisabled] = useState(true);

  const handleCancel = () => {
    form.resetFields();
    setOkDisabled(true);
    setIsOpen(false);
  };

  const handleOk = async () => {
    setOkDisabled(true);
    const values = form.getFieldsValue();
    const result = await transferOrganization({
      orgId: organizationId,
      ...values,
    });

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
    } else {
      await messageApi.success(
        'Transfer is in progress. It may take up several minutes.',
        1
      );
      handleCancel();
    }

    setOkDisabled(false);
  };

  const handleChange = async () => {
    try {
      await form.validateFields();
      setOkDisabled(false);
    } catch (errors) {
      setOkDisabled(true);
    }
  };

  const domainFormItem = () => (
    <Form.Item
      label={'Domain'}
      name="domainId"
      rules={[
        {
          required: true,
          message: V_REQUIRED_FIELD,
        },
      ]}
    >
      <Select
        style={{
          width: '100%',
        }}
        options={domains?.map((domain) => ({
          label: domain.name,
          value: domain.id,
        }))}
        dropdownStyle={{ zIndex: 2000 }}
      />
    </Form.Item>
  );

  const emailFormItem = () => (
    <Form.Item
      label={'Email'}
      name="email"
      rules={[
        {
          type: 'email',
          message: 'Enter a valid E-mail.',
        },
        {
          required: true,
          message: V_REQUIRED_FIELD,
        },
      ]}
    >
      <Input placeholder={'Email'} />
    </Form.Item>
  );

  const getComponent = () => {
    if (domainsLoading) {
      return <Skeleton active />;
    }

    if (domainsError) {
      return <div>Failed to load domains</div>;
    }

    return (
      <Form
        className="data-source-upload-modal-form"
        layout="vertical"
        form={form}
        onChange={handleChange}
        requiredMark={false}
      >
        {domainFormItem()}
        {emailFormItem()}
      </Form>
    );
  };

  return (
    <Modal
      wrapClassName={'modal'}
      title="Transfer organization"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={isLoading}
      okButtonProps={{ disabled: okDisabled }}
    >
      {contextHolder}
      {getComponent()}
    </Modal>
  );
};

export default TransferOrganizationModal;
