import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Modal, Typography } from 'antd';
import IProjectStartInfo from '../../../types/IProjectStartInfo';
import './embed-modal.scss';
import { useAppSelector } from '../../../hooks/redux-hooks';
import Loading from '../../loading/loading';
import { WidgetVersions } from '../../../shared/constants';
import { getDomain, isClientDomain } from '../../../shared/helpers';

interface IEmbedModal {
  version: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  project: IProjectStartInfo;
}

const EmbedModal: FC<IEmbedModal> = ({
  version,
  isOpen,
  setIsOpen,
  project,
}) => {
  const SDK_NAME = process.env.REACT_APP_SDK_NAME || '';
  const { currentOrganization, currentOrganizationLoading } = useAppSelector(
    (state) => state.organizationsReducer
  );
  const { Paragraph, Text } = Typography;
  const [customDomain, setCustomDomain] = useState<boolean>(false);

  useEffect(() => {
    if (
      currentOrganization &&
      currentOrganization.customBranding &&
      currentOrganization.customDomain &&
      currentOrganization.domain
    ) {
      setCustomDomain(true);
    } else {
      setCustomDomain(false);
    }
  }, [currentOrganization, currentOrganizationLoading]);

  const handleCancel = () => {
    setIsOpen(false);
  };

  const getEmbedContent = (version: string) => {
    if (version === WidgetVersions.V1) {
      return embedV1();
    } else {
      return embedV2();
    }
  };

  const embedV1 = () => (
    <>
      <div className={'embed-modal-subtitle'}>Chatbot</div>
      <Paragraph>
        To integrate the bot onto your website, simply incorporate the provided
        iframe into your HTML code.
        <pre>
          <Text
            copyable={{
              text: `<iframe id="default" style="display: none" src="${
                currentOrganization && customDomain
                  ? `https://widget.${currentOrganization.domain}/${project.id}?embed=true&customDomain=${currentOrganization.domain}`
                  : `https://widget.${getDomain()}/${project.id}?embed=true`
              }"></iframe>\n<script type="text/javascript" defer src="${
                currentOrganization && customDomain
                  ? `https://static.${currentOrganization.domain}/static/scripts/embed.min.js`
                  : `https://static.${getDomain()}/static/scripts/embed.min.js`
              }"></script>`,
            }}
          >
            {`<iframe id="default"`} style="display: none" src="
            {currentOrganization && customDomain
              ? `https://widget.${currentOrganization.domain}/${project.id}?embed=true&customDomain=${currentOrganization.domain}`
              : `https://widget.${getDomain()}/${project.id}?embed=true`}
            {`"></iframe>\n<script type="text/javascript" defer src="${
              currentOrganization && customDomain
                ? `https://static.${currentOrganization.domain}/static/scripts/embed.min.js`
                : `https://static.${getDomain()}/static/scripts/embed.min.js`
            }"></script>`}
          </Text>
        </pre>
      </Paragraph>
      <Paragraph>
        To set custom width/height, add <b>width</b> and/or <b>height</b> param
        to iframe src after <b>?embed=true</b> param.
        <pre>?embed=true&width=400&height=600</pre>
        The value must be a number (in <b>px</b>).
      </Paragraph>
      <div className={'embed-modal-subtitle embed-modal-subtitle--mt'}>
        Floating button
      </div>
      <Paragraph>
        Incorporate this iframe into your HTML code to insert a widget with a
        floating button positioned at the bottom right.
        <pre>
          <Text
            copyable={{
              text: `<script>\nwindow.zapprConfigs = [{\n    projectId: "${
                project.id
              }",\n    float: true,\n    btnText: "Ask Me Anything",\n ${
                currentOrganization && customDomain
                  ? `   customDomain: "${currentOrganization.domain}"\n`
                  : ''
              }}];\n</script>\n<script type="text/javascript" defer src="${
                currentOrganization && customDomain
                  ? `https://static.${currentOrganization.domain}/static/scripts/embed.min.js`
                  : `https://static.${getDomain()}/static/scripts/embed.min.js`
              }"></script>`,
            }}
          >
            {`<script>\nwindow.zapprConfigs = [{\n    projectId: "${
              project.id
            }",\n    float: true,\n    btnText: "Ask Me Anything",\n ${
              currentOrganization && customDomain
                ? `   customDomain: "${currentOrganization.domain}"\n`
                : ''
            }}];\n</script>\n<script type='text/javascript' defer src="${
              currentOrganization && customDomain
                ? `https://static.${currentOrganization.domain}/static/scripts/embed.min.js`
                : `https://static.${getDomain()}/static/scripts/embed.min.js`
            }"></script>`}
          </Text>
        </pre>
      </Paragraph>
      <Paragraph>
        To open the widget by default, add <b>openDefault</b> param to config.
        <pre>openDefault: true</pre>
        The widget will be open by default for the first time. Remembers the
        last state. Then the widget will be open if the setting is enabled and
        the last state was "open".
      </Paragraph>
      <Paragraph>
        To set custom width/height, add <b>width</b> and/or <b>height</b> param
        to config.
        <pre>width: 400</pre>
        <pre>height: 600</pre>
        The value must be a number (in <b>px</b>).
      </Paragraph>
      {currentOrganization && currentOrganization.customBranding ? (
        <>
          <div className={'embed-modal-subtitle embed-modal-subtitle--mt'}>
            Custom Branding
          </div>
          <Paragraph>
            To use your own logo on a floating button add <b>logoUrl</b> param
            to config. Use value <b>null</b> to remove logo.
            <pre>
              <Text>
                {`window.zapprConfigs = [{\n    projectId: "${
                  project.id
                }",\n    float: true,\n    btnText: "Ask Me Anything",\n ${
                  currentOrganization && customDomain
                    ? `   customDomain: "${currentOrganization.domain}",\n `
                    : ''
                }   logoUrl: null or "YOUR_URL"\n}];`}
              </Text>
            </pre>
          </Paragraph>
        </>
      ) : null}
    </>
  );

  const embedDomain = (spaces: number = 4) => {
    const spacesString = ' '.repeat(spaces);
    if (currentOrganization && customDomain) {
      return `,\n${spacesString}customDomain: "${currentOrganization.domain}"`;
    }

    if (isClientDomain()) {
      return `,\n${spacesString}customDomain: "${getDomain()}"`;
    }

    return '';
  };

  const embedV2 = () => (
    <>
      <div className={'embed-modal-subtitle'}>Chatbot</div>
      <Paragraph>
        To integrate the bot onto your website, simply incorporate the provided
        code into your HTML code.
        <pre>
          <Text
            copyable={{
              text: `<script>
 window.${SDK_NAME}Loaded = function() {
   window.${SDK_NAME}.setPosition('bottom-right');
 }\n
 window.widgetConfigs = [{
  id: "${project.id}",
  allowFullScreenMode: true,
  closeButton: true,
  miniChat: {
    enabled: true,
  }${embedDomain(2)}
 }];
</script>\n<script defer src="${
                currentOrganization && customDomain
                  ? `https://static.${currentOrganization.domain}/static/widget-pro/embed-v1.0.0.min.js`
                  : `https://static.${getDomain()}/static/widget-pro/embed-v1.0.0.min.js`
              }"></script>`,
            }}
          >
            {`<script>
  window.${SDK_NAME}Loaded = function() {
    window.${SDK_NAME}.setPosition('bottom-right');
  }\n
  window.widgetConfigs = [{
    id: "${project.id}",
    allowFullScreenMode: true,
    closeButton: true,
    miniChat: {
      enabled: true,
    }${embedDomain()}
  }];
</script>
<script defer src="${
              currentOrganization && customDomain
                ? `https://static.${currentOrganization.domain}/static/widget-pro/embed-v1.0.0.min.js`
                : `https://static.${getDomain()}/static/widget-pro/embed-v1.0.0.min.js`
            }"></script>`}
          </Text>
        </pre>
      </Paragraph>
    </>
  );

  return (
    <Modal
      title="Embed on website"
      open={isOpen}
      onCancel={handleCancel}
      centered={true}
      wrapClassName={'modal'}
      maskStyle={{ zIndex: 1100 }}
      footer={null}
    >
      {currentOrganizationLoading ? (
        <Loading height={'100px'} />
      ) : (
        getEmbedContent(version)
      )}
    </Modal>
  );
};

export default EmbedModal;
