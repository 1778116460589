import { ITableT } from '../types/tables-service/ITableT';

export default class TableHelper {
  public static GetKeyByName(table: ITableT, name: string) {
    let key = null;

    table.columns.forEach((col) => {
      if (col.name === name) {
        key = col.key;
      }
    });

    return key;
  }
}
