import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouteObject,
  RouterProvider,
  Routes,
} from 'react-router-dom';
import App from './app';
import { Provider } from 'react-redux';
import { store } from './store/store';
import {
  RouteNames,
  landingRoutes,
  dashboardRoutes,
  landingRoutesLegal,
} from './router/router';
import RootErrorBoundary from './components/root-error-boundary/root-error-boundary';
import { Subdomains } from './shared/constants';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />} errorElement={<RootErrorBoundary />}>
      {window.location.hostname.split('.')[0] === Subdomains.DASHBOARD
        ? dashboardRoutes.map((route) => {
            return (
              <Route
                path={route.path.slice(1)}
                element={<route.element />}
                key={route.path}
              />
            );
          })
        : process.env.REACT_APP_PARTNER_MODE === 'false'
        ? dashboardRoutes.map((route) => {
            return (
              <Route
                path={route.path.slice(1)}
                element={<route.element />}
                key={route.path}
              />
            );
          })
        : landingRoutes.map((route) => {
            return (
              <Route
                path={route.path.slice(1)}
                element={<route.element />}
                key={route.path}
              />
            );
          })}
      {/*<Route path="*" element={<PageNotExist />} />*/}
      {window.location.hostname.split('.')[0] === Subdomains.DASHBOARD ? (
        <Route path="*" element={<Navigate to={RouteNames.PAGE_NOT_EXIST} />} />
      ) : (
        <Route path="*" element={<Navigate to={RouteNames.LANDING} />} />
      )}
    </Route>
  )
);

root.render(
  <GoogleOAuthProvider clientId="2671877163-a4fn6mt6d4of6pgfdkrgtr1dluolum9c.apps.googleusercontent.com">
    <Provider store={store}>
      <RouterProvider router={router} />
      {/*<BrowserRouter>*/}
      {/*  <App />*/}
      {/*</BrowserRouter>*/}
    </Provider>
  </GoogleOAuthProvider>
);
