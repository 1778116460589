import { FC, useEffect, useState } from 'react';
import { chartColors } from '../../charts/chart-data';
import AnalyticsUtils from '../../shared/analytics/analytics-utils';
import { Skeleton } from 'antd';
import { ChartContainer } from '../../shared/analytics/analytics-container-component';
import { Bar, LabelList } from 'recharts';
import { IAnalyticsEventsData } from './analytics-data';
import { IInsightsQueryResult } from '../../../services/usage-service';

const PerDayBarChart: FC<{
  data: IAnalyticsEventsData;
  queryResult: IInsightsQueryResult | null;
  isLoading: boolean;
}> = ({ data, queryResult, isLoading }) => {
  const initialChartsData: any[] = [];
  const [chartsData, setChartsData] = useState(initialChartsData);

  useEffect(() => {
    if (queryResult) {
      const today = new Date();
      const todayUTC = new Date(
        Date.UTC(
          today.getUTCFullYear(),
          today.getUTCMonth(),
          today.getUTCDate()
        )
      );
      const tableData = AnalyticsUtils.getTableData(queryResult);
      const filteredData = tableData.filter((t) => {
        let eventName = t.name;
        if (t.segment) {
          eventName = t.name + `_(${t.segment})`;
        }
        return eventName === data.eventName;
      });
      const fromDate = new Date(queryResult.from);
      const toDate = new Date(queryResult.to);
      const newChartData: any[] = [];
      let currentDate = fromDate;

      while (currentDate <= toDate) {
        const day = currentDate.getUTCDate().toString();
        const entry: any = { name: day };

        const record = filteredData.find((x) => {
          const utcDate = new Date(
            Date.UTC(
              currentDate.getUTCFullYear(),
              currentDate.getUTCMonth(),
              currentDate.getUTCDate()
            )
          );

          return new Date(x['timestamp']).getTime() == utcDate.getTime();
        });

        if (record) {
          entry[data.chartName] = record['count'];
        } else {
          const utcDate = new Date(
            Date.UTC(
              currentDate.getUTCFullYear(),
              currentDate.getUTCMonth(),
              currentDate.getUTCDate()
            )
          );

          if (utcDate.getTime() <= todayUTC.getTime()) {
            entry[data.chartName] = 0;
          }

          // entry[data.chartName] = 0;
        }

        newChartData.push(entry);
        currentDate.setDate(currentDate.getDate() + 1);
      }

      setChartsData(newChartData);
    }
  }, [queryResult, data]);

  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <ChartContainer
          chartData={chartsData}
          xAxisKey="name"
          headerContent={
            <div className={'analytics__chart-title'}>{data.title}</div>
          }
        >
          <Bar
            key={`bar_${data.chartName}`}
            dataKey={data.chartName}
            fill={chartColors[0]}
          >
            <LabelList
              dataKey={data.chartName}
              position="top"
              formatter={(label: string) => (label == '0' ? null : label)}
            />
          </Bar>
        </ChartContainer>
      )}
    </>
  );
};

export default PerDayBarChart;
