import React, { FC, useState } from 'react';
import { Checkbox, Form, FormInstance, Input, Select } from 'antd';
import {
  BotTypes,
  CreateProjectDescriptionModalTypes,
  V_REQUIRED_FIELD,
  WidgetStreamModes,
} from '../../../../shared/constants';
import { InfoCircleOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { useAppSelector } from '../../../../hooks/redux-hooks';
import { Rule } from 'antd/es/form';
import RolePersonalityProjectDescriptionModal from '../../../modals/create-project-description-modal/role-personality-project-description-modal';
import { allTimezones, useTimezoneSelect } from 'react-timezone-select';

interface IBasicSettingsProps {
  basicSettingsForm: FormInstance;
  handleChange: () => void;
}

const labelStyle = 'original';
const timezones = {
  ...allTimezones,
};

const BasicSettings: FC<IBasicSettingsProps> = ({
  basicSettingsForm,
  handleChange,
}) => {
  const { options: projectOptions } = useAppSelector(
    (state) => state.projectReducer
  );
  const { subscriptionIsActive } = useAppSelector(
    (state) => state.subscriptionReducer
  );
  // TODO: maybe should refactor (+create project)
  const [roleDescriptionModalIsOpen, setRoleDescriptionModalIsOpen] =
    useState(false);
  const [
    personalitiesDescriptionModalIsOpen,
    setPersonalitiesDescriptionModalIsOpen,
  ] = useState(false);
  const { options: timezoneOptions } = useTimezoneSelect({
    labelStyle,
    timezones,
  });

  // const botTypeFormItem = () => {
  //   return (
  //     <Form.Item label="Type" name="type">
  //       <Radio.Group disabled={!subscriptionIsActive}>
  //         {widgetTypeOptions.map((option, i) => (
  //           <Radio.Button value={option.value} key={i}>
  //             {option.label}
  //           </Radio.Button>
  //         ))}
  //       </Radio.Group>
  //     </Form.Item>
  //   );
  // };

  const textInputFormItem = (
    label: string,
    name: string,
    placeholder: string,
    rules?: Rule[] | undefined
  ) => {
    return (
      <Form.Item label={label} name={name} rules={rules}>
        <Input placeholder={placeholder} disabled={!subscriptionIsActive} />
      </Form.Item>
    );
  };

  const textAreaFormItem = (
    label: string,
    name: string,
    placeholder: string
  ) => {
    return (
      <Form.Item label={label} name={name}>
        <TextArea
          rows={5}
          placeholder={placeholder}
          disabled={!subscriptionIsActive}
        />
      </Form.Item>
    );
  };

  const capitalizeFirstLetter = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const getRoleFormItemParams = () => {
    const label = () => (
      <div>
        Role of chatbot{' '}
        <InfoCircleOutlined
          className={'create-project__tooltip-icon'}
          onClick={() => {
            setRoleDescriptionModalIsOpen(true);
          }}
        />
      </div>
    );

    const name = 'roleId';

    const options =
      projectOptions?.roles.map((role) => ({
        value: role.id,
        label: role.name,
      })) || [];

    return {
      label,
      name,
      options,
    };
  };

  const getPersonalityFormItemParams = () => {
    const label = () => (
      <div>
        Personality{' '}
        <InfoCircleOutlined
          className={'create-project__tooltip-icon'}
          onClick={() => {
            setPersonalitiesDescriptionModalIsOpen(true);
          }}
        />
      </div>
    );

    const name = 'personalityId';

    const options =
      projectOptions?.personalities.map((personality) => ({
        value: personality.id,
        label: personality.name,
      })) || [];

    return {
      label,
      name,
      options,
    };
  };

  const getStreamModeItemParams = () => {
    const label = () => <div>Stream mode</div>;

    const name = 'streamMode';

    const options = Object.keys(WidgetStreamModes).map((key) => ({
      value: WidgetStreamModes[key as keyof typeof WidgetStreamModes],
      label: capitalizeFirstLetter(
        WidgetStreamModes[key as keyof typeof WidgetStreamModes]
      ),
    }));

    return {
      label,
      name,
      options,
    };
  };

  const selectFormItem = (params: {
    label: () => JSX.Element;
    name: string;
    options: { value: string; label: string }[];
  }) => {
    const { label, name, options } = params;

    return (
      <Form.Item label={label()} name={name}>
        <Select
          disabled={!subscriptionIsActive}
          options={options}
          onChange={handleChange}
        />
      </Form.Item>
    );
  };

  const checkboxFormItem = (label: string, name: string) => {
    return (
      <Form.Item name={name} valuePropName="checked">
        <Checkbox>{label}</Checkbox>
      </Form.Item>
    );
  };

  const followPagesFormItem = () => {
    return (
      <Form.Item name={'followPages'} valuePropName="checked">
        <Checkbox>Follow pages</Checkbox>
      </Form.Item>
    );
  };

  const timeZoneSelector = () => {
    return (
      <Form.Item label="Timezone: " name={'timeZone'}>
        <Select
          disabled={!subscriptionIsActive}
          options={timezoneOptions}
          onChange={handleChange}
        />
      </Form.Item>
    );
  };
  const getBasicSettingsForm = () => {
    return (
      <Form
        form={basicSettingsForm}
        layout="vertical"
        initialValues={{ type: BotTypes.CHATBOT }}
        className="settings-form"
        onChange={handleChange}
        requiredMark={false}
        style={{ rowGap: 0 }}
      >
        <div className="settings-form-part">
          {/*{botTypeFormItem()}*/}
          {textInputFormItem('Project name', 'name', 'Enter name', [
            { required: true, message: V_REQUIRED_FIELD },
          ])}
          {selectFormItem(getRoleFormItemParams())}
          {selectFormItem(getPersonalityFormItemParams())}
          {timeZoneSelector()}
          {checkboxFormItem('Follow pages', 'followPages')}
          {selectFormItem(getStreamModeItemParams())}
        </div>
        <div className="settings-form-part">
          {textAreaFormItem(
            'Initial message',
            'startMessage',
            'Enter initial message'
          )}
          {textAreaFormItem(
            'Suggested questions',
            'suggestedQuestions',
            'What features do you have?\nWhat services do you provide?'
          )}
          {checkboxFormItem('References', 'enableReferences')}
        </div>
      </Form>
    );
  };

  const modals = () => {
    return (
      <>
        <RolePersonalityProjectDescriptionModal
          isOpen={roleDescriptionModalIsOpen}
          setIsOpen={setRoleDescriptionModalIsOpen}
          type={CreateProjectDescriptionModalTypes.ROLES}
        />
        <RolePersonalityProjectDescriptionModal
          isOpen={personalitiesDescriptionModalIsOpen}
          setIsOpen={setPersonalitiesDescriptionModalIsOpen}
          type={CreateProjectDescriptionModalTypes.PERSONALITIES}
        />
      </>
    );
  };

  return (
    <div>
      {getBasicSettingsForm()}
      {modals()}
    </div>
  );
};

export default BasicSettings;
