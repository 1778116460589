import React, { FC } from 'react';
import { Divider } from 'antd';
import TextInputFormItem from './form-items/text-input-form-item';

const CustomFieldRecordFormElement: FC = () => {
  return (
    <>
      <Divider orientation="left" className={'contact-form-divider'}>
        Custom Field
      </Divider>
      <TextInputFormItem label={'Name'} name={'name'} placeholder={'Name'} />
      <TextInputFormItem label={'Value'} name={'value'} placeholder={'Value'} />
    </>
  );
};

export default CustomFieldRecordFormElement;
