export interface ITableFilterValues {
  field: string;
  operator: string;
  value: string;
}

export enum FilterOperators {
  IS = 'is',
  IS_NOT = 'isNot',
  CONTAINS = 'contains',
  DOES_NOT_CONTAIN = 'doesNotContain',
  IS_EMPTY = 'isEmpty',
  IS_NOT_EMPTY = 'isNotEmpty',
}

export const filterOperatorOptions = [
  {
    value: FilterOperators.IS,
    label: 'is',
  },
  {
    value: FilterOperators.IS_NOT,
    label: 'is not',
  },
  {
    value: FilterOperators.CONTAINS,
    label: 'contains',
  },
  {
    value: FilterOperators.DOES_NOT_CONTAIN,
    label: 'does not contain',
  },
  {
    value: FilterOperators.IS_EMPTY,
    label: 'is empty',
  },
  {
    value: FilterOperators.IS_NOT_EMPTY,
    label: 'is not empty',
  },
];

export const filterValueIsRequired = (operator: FilterOperators) => {
  return [
    FilterOperators.IS,
    FilterOperators.IS_NOT,
    FilterOperators.CONTAINS,
    FilterOperators.DOES_NOT_CONTAIN,
  ].includes(operator);
};

export const campaignFilterOperatorsList = [
  FilterOperators.IS,
  FilterOperators.IS_NOT,
  FilterOperators.CONTAINS,
  FilterOperators.DOES_NOT_CONTAIN,
];

export const getFilterOperatorOptions = (
  filterOperators: FilterOperators[]
) => {
  return filterOperatorOptions.filter((option) =>
    filterOperators.includes(option.value)
  );
};
