import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { RouteNames } from '../../../../router/router';
import {
  ProfileSettingsType,
  regexpOrganizationName,
  SubscriptionPlanTypes,
  V_REQUIRED_FIELD,
} from '../../../../shared/constants';
import { useAppSelector } from '../../../../hooks/redux-hooks';
import { Button, Form, Input, message, Skeleton } from 'antd';
import './add-new-organization.scss';
import { showErrorMessage } from '../../../../shared/helpers';
import { organizationsAPI } from '../../../../services/organizations-service';

interface ICreateOrganizationForm {
  name: string;
  displayName: string;
}

const AddNewOrganization = () => {
  const { currentUserSubscription, subscriptionIsLoading } = useAppSelector(
    (state) => state.subscriptionReducer
  );
  const [createOrganization, { isLoading }] =
    organizationsAPI.useCreateOrganizationMutation();
  const [createDisabled, setCreateDisabled] = useState<boolean>(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleCreateOrganization = async (values: ICreateOrganizationForm) => {
    setCreateDisabled(true);

    const data = {
      ...values,
    };

    const result = await createOrganization(data);

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
      handleChange();
    } else {
      // await messageApi.success('Organization has been created.');
      // form.resetFields();
      navigate(
        `${RouteNames.PROFILE_SETTINGS_NO_PARAMS}/${ProfileSettingsType.ORGANIZATION}/${result.data.id}`
      );
    }
  };

  const handleChange = () => {
    const values = form.getFieldsValue() as ICreateOrganizationForm;

    let disabled = false;

    if (!values.name) {
      disabled = true;
    }

    form.setFieldsValue({
      displayName: values.name,
    });

    setCreateDisabled(disabled);
  };

  const validateForbiddenName = (rule: any, value: string) => {
    if (value && value.toLowerCase() === 'personal') {
      return Promise.reject('The name "Personal" is reserved.');
    }
    return Promise.resolve();
  };

  return (
    <div className="profile-setting-content-container">
      {contextHolder}
      {subscriptionIsLoading ? (
        <Skeleton active />
      ) : (
        <>
          <div className="profile-setting-title">Create your organization</div>
          {currentUserSubscription &&
          currentUserSubscription.currentPlan.type !==
            SubscriptionPlanTypes.FREE ? (
            <Form
              form={form}
              layout="vertical"
              className="profile-settings-form profile-settings-form--create-org"
              onFinish={handleCreateOrganization}
              onChange={handleChange}
              requiredMark={false}
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: V_REQUIRED_FIELD,
                  },
                  {
                    min: 3,
                    message: 'Name must be at least 3 characters.',
                  },
                  {
                    max: 32,
                    message: 'Max length of name is 32 characters.',
                  },
                  {
                    pattern: regexpOrganizationName,
                    message:
                      'Name contains invalid characters. Allowed characters: letters (a-z, A-Z), digits (0-9), hyphen (-), underscore (_), and space.',
                  },
                  {
                    validator: validateForbiddenName,
                  },
                ]}
              >
                <Input placeholder="Enter name" />
              </Form.Item>
              <Form.Item
                label="Name"
                name="displayName"
                hidden
                rules={[
                  {
                    required: true,
                    message: V_REQUIRED_FIELD,
                  },
                  {
                    min: 3,
                    message: 'Name must be at least 3 characters.',
                  },
                  {
                    max: 32,
                    message: 'Max length of display name is 32 characters.',
                  },
                ]}
              >
                <Input placeholder="Enter display name" />
              </Form.Item>
              <Form.Item className="profile-settings-form-btn-container">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  disabled={createDisabled}
                >
                  Create
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <div className={'profile-setting-description'}>
              Your plan does not have access to this option. To open it,{' '}
              <Link
                to={`${RouteNames.PROFILE_SETTINGS_NO_PARAMS}/${ProfileSettingsType.PLAN}`}
                className={'advanced-no-access-link'}
              >
                upgrade your plan
              </Link>{' '}
              to Starter or higher.
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AddNewOrganization;
