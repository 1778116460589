import React, { FC, useState } from 'react';
import { Button, Form, Input, Select } from 'antd';
import {
  filterOperatorOptions,
  FilterOperators,
  filterValueIsRequired,
  ITableFilterValues,
} from '../../../../whats-app-outbound/filters/filters-helpers';
import { ArrowLeftOutlined } from '@ant-design/icons';
import './header-cell-filter.scss';

interface IHeaderCellFilterProps {
  setFilterValues: React.Dispatch<React.SetStateAction<ITableFilterValues[]>>;
  columnName: string;
  columnKey: string;
  onBackClick: () => void;
  setOpenHeaderCellMoreMenu: React.Dispatch<React.SetStateAction<string>>;
}

// TODO: delete column-filter.tsx in the future
const HeaderCellFilter: FC<IHeaderCellFilterProps> = ({
  setFilterValues,
  columnName,
  columnKey,
  onBackClick,
  setOpenHeaderCellMoreMenu,
}) => {
  const [form] = Form.useForm();
  const [applyIsDisabled, setApplyIsDisabled] = useState(true);
  const operator = Form.useWatch('operator', form);

  const resetFilter = () => {
    setFilterValues([]);
    setOpenHeaderCellMoreMenu('');
  };

  const applyFilter = () => {
    setFilterValues([
      {
        ...form.getFieldsValue(),
        field: columnKey,
      },
    ]);
    setOpenHeaderCellMoreMenu('');
  };

  const handleChange = async () => {
    const values = await form.getFieldsValue();
    const { operator, value } = values;

    const needValue = filterValueIsRequired(operator);

    if ((operator && value) || !needValue) {
      setApplyIsDisabled(false);
    } else {
      setApplyIsDisabled(true);
    }
  };

  const filterForm = () => (
    <div>
      <Form
        form={form}
        onChange={handleChange}
        layout={'inline'}
        requiredMark={false}
        initialValues={{
          operator: FilterOperators.IS,
        }}
        className={'header-cell-filter__form'}
      >
        <Form.Item name="operator" style={{ marginInlineEnd: 0 }}>
          <Select
            options={filterOperatorOptions}
            dropdownStyle={{ zIndex: 2000 }}
            style={{
              width: '100%',
            }}
            onChange={handleChange}
            getPopupContainer={(trigger) => trigger.parentElement}
          />
        </Form.Item>
        <Form.Item name="value" style={{ marginInlineEnd: 0 }}>
          <Input
            autoComplete={'off'}
            placeholder={filterValueIsRequired(operator) ? 'Enter a value' : ''}
            disabled={!filterValueIsRequired(operator)}
          />
        </Form.Item>
      </Form>
    </div>
  );

  const filterActions = () => (
    <div>
      <Button
        type={'primary'}
        disabled={applyIsDisabled}
        onClick={applyFilter}
        style={{ width: '100%' }}
      >
        Apply
      </Button>
      <div className={'header-cell-filter__actions--secondary-btns-container'}>
        <Button onClick={resetFilter} style={{ width: '100%' }}>
          Reset
        </Button>
        <Button
          onClick={() => setOpenHeaderCellMoreMenu('')}
          style={{ width: '100%' }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );

  const filterContent = () => (
    <div>
      In this view, show records where{' '}
      <span style={{ fontWeight: 500 }}>{columnName || columnKey}</span>
      {filterForm()}
      {filterActions()}
    </div>
  );

  const renderFilter = () => {
    return (
      <>
        <div onClick={(e) => e.stopPropagation()}>
          <div className={'header-cell-props__title'}>
            Filter
            <Button size={'small'} type="text" onClick={onBackClick}>
              <ArrowLeftOutlined style={{ marginTop: 4 }} />
            </Button>
          </div>
          {filterContent()}
        </div>
      </>
    );
  };

  return renderFilter();
};

export default HeaderCellFilter;
