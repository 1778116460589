import React, { FC } from 'react';
import {
  PluginValidators,
  widgetThemeOptions,
} from '../../../../shared/constants';
import { Button, Form, Input, Select, Tooltip } from 'antd';
import {
  MinusCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { isValidEmail } from '../../../../shared/helpers';
import IWATemplateData from '../../../../types/whats-app-outbound-service/IWATemplateData';

interface IArrayKeyInputValueSelectFieldProps {
  selectOptions: { value: string; label: string }[];
  label: string;
  tooltip: string;
  name: string;
  placeholders: { key: string; value: string };
  V_REQUIRED_FIELD: string;
  isRequired: boolean;
  validators: {
    keyValidators: PluginValidators[];
    valueValidators: PluginValidators[];
  };
  onChange: () => Promise<void>;
  templatePreview: IWATemplateData | undefined;
}

export interface ValueValidationResult {
  value: string;
  isValid: boolean;
  message: string;
}

function validateFewEmails(value: string) {
  const emails = value.split(/[,\s]+/); // Split by comma and/or space
  for (const email of emails) {
    if (!isValidEmail(email)) {
      return false;
    }
  }
  return true;
}

// TODO: check ArrayKeyValueInputField and refactor?
const ArrayKeyInputValueSelectField: FC<
  IArrayKeyInputValueSelectFieldProps
> = ({
  selectOptions,
  label,
  tooltip,
  name,
  placeholders,
  V_REQUIRED_FIELD,
  isRequired,
  validators,
  onChange,
  templatePreview,
}) => {
  const validateValue = (value: string, isKey: boolean) => {
    const result: ValueValidationResult = {
      value,
      isValid: true,
      message: '',
    };

    if (isRequired && (!value || value.length === 0)) {
      result.isValid = false;
      result.message = V_REQUIRED_FIELD;
    }

    const currentValidators = isKey
      ? validators.keyValidators
      : validators.valueValidators;

    if (currentValidators.length > 0) {
      for (let validator of currentValidators) {
        if (validator === PluginValidators.FEW_EMAILS) {
          if (!validateFewEmails(value)) {
            result.isValid = false;
            result.message = 'Invalid Emails';
          }
        }
        // if (validator === PluginValidators.EMAIL) {
        //   if (!isValidEmail(value)) {
        //     result.isValid = false;
        //     result.message = 'Invalid Email';
        //   }
        // }
      }
    }

    return result;
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 5,
          marginBottom: 8,
        }}
      >
        {label}
        {tooltip && (
          <Tooltip title={tooltip}>
            <span
              style={{
                marginTop: 2,
                color: 'rgba(60, 66, 87, 0.45)',
                cursor: 'help',
              }}
            >
              <QuestionCircleOutlined />
            </span>
          </Tooltip>
        )}
      </div>
      <Form.List name={name}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => {
              let isHidden = false;

              return (
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                  key={key}
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'key']}
                    style={{ width: '100%' }}
                    rules={[
                      {
                        required: isRequired,
                        message: V_REQUIRED_FIELD,
                      },
                      {
                        validator: async (_, value) => {
                          if (value) {
                            const result = validateValue(value, true);
                            if (!result.isValid) {
                              return Promise.reject(result.message);
                            }
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input placeholder={placeholders.key} onChange={onChange} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'value']}
                    style={{ width: '100%' }}
                    rules={[
                      {
                        required: isRequired,
                        message: V_REQUIRED_FIELD,
                      },
                      {
                        validator: async (_, value) => {
                          if (value) {
                            const result = validateValue(value, false);
                            if (!result.isValid) {
                              return Promise.reject(result.message);
                            }
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Select
                      placeholder={placeholders.value}
                      options={selectOptions}
                      dropdownStyle={{ zIndex: 2000 }}
                      onChange={onChange}
                    />
                  </Form.Item>
                  <MinusCircleOutlined
                    onClick={() => {
                      remove(name);
                      onChange();
                    }}
                    style={{ marginBottom: 24 }}
                    hidden={isHidden}
                  />
                </div>
              );
            })}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => {
                  add();
                }}
                block
                icon={<PlusOutlined />}
              >
                Add field
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};

export default ArrayKeyInputValueSelectField;
