import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Popover } from 'antd';
import { IoFilter } from 'react-icons/io5';
import './table-filter.scss';
import { ITableT } from '../../../../types/tables-service/ITableT';
import {
  FilterOperators,
  filterValueIsRequired,
  ITableFilterValues,
} from './filters-helpers';
import TableFilterField from './table-filter-field';

interface ITableFilterProps {
  tableData: ITableT;
  setFilterValues: React.Dispatch<React.SetStateAction<ITableFilterValues[]>>;
}

const TableFilter: FC<ITableFilterProps> = ({ tableData, setFilterValues }) => {
  const [open, setOpen] = useState(false);
  const [fieldOptions, setFieldOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [form] = Form.useForm();
  const [applyIsDisabled, setApplyIsDisabled] = useState(true);
  const [formFilterValues, setFormFilterValues] = useState<
    ITableFilterValues[]
  >([{ field: '', operator: FilterOperators.IS, value: '' }]);

  const transformFormFilterValues = (values: ITableFilterValues[]) => {
    let fValues: { [key: string]: string | FilterOperators } = {};
    for (let i = 0; i < values.length; i++) {
      const filterValue = values[i];
      for (const key in filterValue) {
        fValues[`${key}-${i}`] = filterValue[key as keyof ITableFilterValues];
      }
    }

    return fValues;
  };

  const resetFilter = () => {
    form.setFieldsValue({
      filters: [{ field: '', operator: FilterOperators.IS, value: '' }],
    });
    handleChange();
    setFilterValues([]);
    hide();
  };

  const applyFilter = () => {
    const formValues = form.getFieldsValue();
    const filters = formValues.filters || [];
    setFilterValues(filters);
    hide();
  };

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  useEffect(() => {
    if (tableData) {
      setFieldOptions(
        tableData.columns.map((col) => ({
          value: col.key,
          label: col.name || col.key,
        }))
      );
    }
  }, [tableData]);

  const handleChange = async () => {
    try {
      await form.validateFields();
    } catch (error) {
      const err = error as { errorFields: unknown[] };
      if (err && err.errorFields && err.errorFields.length > 0) {
        setApplyIsDisabled(true);
        return;
      }
    }

    const values = await form.getFieldsValue();
    if (!values) return;
    const filters = values.filters as ITableFilterValues[];
    if (!filters) return;

    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i];
      if (!filter) {
        setApplyIsDisabled(true);
        return;
      }

      const { field, operator, value } = filter;
      const needValue = filterValueIsRequired(operator as FilterOperators);

      if ((field && operator && value) || (!needValue && field)) {
        setApplyIsDisabled(false);
      } else {
        setApplyIsDisabled(true);
        return;
      }
    }
  };

  const filterForm = () => (
    <div className={'table-filter-form-container'}>
      <Form
        form={form}
        onChange={handleChange}
        layout={'vertical'}
        requiredMark={false}
        initialValues={{
          ...transformFormFilterValues(formFilterValues),
        }}
      >
        <div>
          <TableFilterField
            form={form}
            fieldOptions={fieldOptions}
            handleChange={handleChange}
          />
        </div>
      </Form>
    </div>
  );

  const filterActions = () => (
    <div className={'table-filter-actions-container'}>
      <Button onClick={resetFilter}>Reset</Button>
      <Button onClick={hide}>Cancel</Button>
      <Button type={'primary'} disabled={applyIsDisabled} onClick={applyFilter}>
        Apply
      </Button>
    </div>
  );

  const filterContent = () => (
    <div className={'table-filter-content'}>
      In this view, show records
      {filterForm()}
      {filterActions()}
    </div>
  );

  const renderFilter = () => {
    return (
      <Popover
        content={filterContent()}
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
        placement={'bottomRight'}
        overlayStyle={{ maxWidth: '90%', zIndex: 2000 }}
      >
        <Button className={'whats-app-outbound-button'}>
          <IoFilter /> Filter
        </Button>
      </Popover>
    );
  };

  return renderFilter();
};

export default TableFilter;
