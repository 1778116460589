import React, { FC } from 'react';
import INoteRecord from '../../../../../types/contacts/contact-records/INoteRecord';
import IRecordItemProps from '../IRecordItemProps';
import { Button } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const NoteRecordItem: FC<IRecordItemProps> = ({
  record,
  handleEditRecord,
  handleDeleteRecord,
}) => {
  const getComponent = () => {
    const recordData = record.record as INoteRecord;

    return (
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 10,
          }}
        >
          <div style={{ display: 'flex' }}>{recordData.note}</div>
          <div style={{ display: 'flex', gap: 5 }}>
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={() => handleEditRecord(record)}
              size={'small'}
            ></Button>
            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={() => handleDeleteRecord(record)}
              danger
              size={'small'}
            ></Button>
          </div>
        </div>
      </div>
    );
  };

  return getComponent();
};

export default NoteRecordItem;
