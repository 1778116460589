import { MessageInstance } from 'antd/es/message/interface';
import { projectsAPI } from '../../../services/projects-service';
import { KnowledgeDataSources } from '../../../shared/constants';
import { showErrorMessage } from '../../../shared/helpers';

interface IHandleDownload {
  (
    projectId: string,
    knowledgeId: string,
    fileName: string,
    messageApi: MessageInstance
  ): Promise<void>;
}

export const useKnowledgeDownloadController = (
  projectId: string,
  knowledgeId: string,
  recordType: KnowledgeDataSources,
  fileName: string,
  messageApi: MessageInstance
) => {
  const [downloadFile, {}] = projectsAPI.useLazyDownloadKnowledgeFileQuery();
  const [getQAKnowledgeContent, {}] =
    projectsAPI.useLazyGetProjectKnowledgeIngestQAQuery();

  const handleDownload: IHandleDownload = async (
    projectId,
    knowledgeId,
    fileName,
    messageApi
  ) => {
    const res = await downloadFile({ projectId, knowledgeId });

    if ('error' in res && res.error) {
      await showErrorMessage(messageApi, res.error);
    } else {
      const blobData = res.data as {
        downloadUrl: string;
        contentType: string;
      };

      download(blobData.downloadUrl, fileName, blobData.contentType);
    }
  };

  const handleQADownload: IHandleDownload = async (
    projectId,
    knowledgeId,
    fileName,
    messageApi
  ) => {
    const res = await getQAKnowledgeContent({ id: projectId, knowledgeId });

    if ('error' in res && res.error) {
      await showErrorMessage(messageApi, res.error);
    } else {
      const jsonBlob = new Blob([JSON.stringify(res.data)], {
        type: 'application/json',
      });
      const downloadUrl = window.URL.createObjectURL(jsonBlob);

      download(downloadUrl, fileName, 'application/json');
    }
  };

  const download = (
    downloadUrl: string,
    fileName: string,
    contentType: string
  ) => {
    const link = document.createElement('a');

    link.href = downloadUrl;
    link.download = link.download =
      contentType === 'application/json' ? fileName + '.json' : fileName;
    document.body.appendChild(link);

    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    document.body.removeChild(link);
  };

  const getFile = async () => {
    messageApi.info({
      content: 'Downloading...',
      key: knowledgeId,
      duration: 0,
    });

    if (recordType === KnowledgeDataSources.QA) {
      await handleQADownload(projectId, knowledgeId, fileName, messageApi);
    } else {
      await handleDownload(projectId, knowledgeId, fileName, messageApi);
    }

    messageApi.destroy(knowledgeId);
  };

  return {
    getFile,
  };
};
