import React, { FC } from 'react';
import { Button, Form, FormInstance, Input, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  filterOperatorOptions,
  FilterOperators,
  filterValueIsRequired,
  ITableFilterValues,
} from './filters-helpers';
import { ValueValidationResult } from '../campaigns/array-key-input-value-select-field';

interface ITableFilterFieldProps {
  form: FormInstance;
  fieldOptions: { value: string; label: string }[];
  handleChange: () => void;
}

const TableFilterField: FC<ITableFilterFieldProps> = ({
  form,
  fieldOptions,
  handleChange,
}) => {
  const initialValues = [
    { field: '', operator: FilterOperators.IS, value: '' },
  ];
  const values = Form.useWatch([], form);

  const validateFieldValue = (value: string) => {
    const result: ValueValidationResult = {
      value,
      isValid: true,
      message: '',
    };

    const formValues = form.getFieldsValue();
    if (!formValues) return result;
    const filters = formValues.filters as ITableFilterValues[];

    if (filters) {
      let existing = 0;
      for (let filter of filters) {
        if (filter) {
          if (filter.field === value) {
            existing++;
          }
        }
      }

      if (existing > 1) {
        result.isValid = false;
        result.message = 'Multiple filters for the same field are not allowed.';
      }
    }

    return result;
  };

  const getOperatorByIndex = (index: number) => {
    if (!values) return FilterOperators.IS;
    const formFilters = values.filters as ITableFilterValues[];
    if (!formFilters) return FilterOperators.IS;
    if (formFilters.length < index) return FilterOperators.IS;
    return (
      (formFilters[index]?.operator as FilterOperators) || FilterOperators.IS
    );
  };

  return (
    <>
      <Form.List name={'filters'} initialValue={initialValues}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => {
              return (
                <div
                  style={{ display: 'flex', gap: 8, width: '100%' }}
                  key={key}
                  className={'table-filter-field'}
                >
                  {index === 0 ? (
                    <div
                      style={{
                        minWidth: 42,
                        marginTop: 5,
                      }}
                    >
                      Where
                    </div>
                  ) : (
                    <div
                      style={{
                        minWidth: 42,
                        marginTop: 5,
                      }}
                    >
                      AND
                    </div>
                  )}
                  <Form.Item
                    {...restField}
                    name={[name, 'field']}
                    style={{ width: '100%', marginBottom: 28 }}
                    rules={[
                      {
                        validator: async (_, value) => {
                          if (value) {
                            const result = validateFieldValue(value);
                            if (!result.isValid) {
                              return Promise.reject(result.message);
                            }
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                    className={'table-filter-field__item'}
                  >
                    <Select
                      options={fieldOptions}
                      dropdownStyle={{ zIndex: 2000 }}
                      onChange={handleChange}
                      className={'table-filter-field__item'}
                      getPopupContainer={(trigger) => trigger.parentElement}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'operator']}
                    style={{ width: '100%' }}
                    initialValue={FilterOperators.IS}
                    className={'table-filter-field__item--small'}
                  >
                    <Select
                      options={filterOperatorOptions}
                      dropdownStyle={{ zIndex: 2000 }}
                      onChange={handleChange}
                      className={'table-filter-field__item--small'}
                      getPopupContainer={(trigger) => trigger.parentElement}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'value']}
                    style={{ width: '100%' }}
                    className={'table-filter-field__item'}
                  >
                    <Input
                      onChange={handleChange}
                      placeholder={
                        filterValueIsRequired(getOperatorByIndex(index))
                          ? 'Enter a value'
                          : ''
                      }
                      disabled={
                        !filterValueIsRequired(getOperatorByIndex(index))
                      }
                      className={'table-filter-field__item'}
                    />
                  </Form.Item>
                  {fields.length > 1 && (
                    <MinusCircleOutlined
                      onClick={(e) => {
                        e.stopPropagation();
                        remove(name);
                        handleChange();
                      }}
                      style={{
                        alignItems: 'flex-start',
                        marginTop: 9,
                      }}
                      className={'table-filter-field-delete'}
                    />
                  )}
                </div>
              );
            })}
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                type="dashed"
                onClick={() => {
                  add();
                  handleChange();
                }}
                block
                icon={<PlusOutlined />}
              >
                Add filter
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};

export default TableFilterField;
