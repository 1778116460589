import React, { FC, useEffect, useState } from 'react';
import {
  OrganizationSettingsType,
  ProfileSettingsType,
} from '../../shared/constants';
import {
  FlagFilled,
  FlagOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { useAppSelector } from '../../hooks/redux-hooks';
import { useNavigate } from 'react-router-dom';
import { RouteNames } from '../../router/router';
import './profile-settings-organizations-menu.scss';

interface IMenuItem {
  icon: JSX.Element | null;
  title: string | JSX.Element;
  type: ProfileSettingsType;
  isInteractive: boolean;
  classList: string;
  orgId: string | null;
}

const organizationMenuItems: IMenuItem[] = [
  {
    icon: null,
    title: 'Organizations',
    type: ProfileSettingsType.ORGANIZATION_TITLE,
    isInteractive: false,
    classList: 'organization-submenu-title',
    orgId: null,
  },
  {
    icon: <PlusCircleOutlined />,
    title: 'Add new',
    type: ProfileSettingsType.ADD_NEW_ORGANIZATION,
    isInteractive: true,
    classList: '',
    orgId: null,
  },
];

const ProfileSettingsOrganizationsMenu: FC<{
  settingsType: ProfileSettingsType;
  organizationId: string | null;
}> = ({ settingsType, organizationId }) => {
  const { allOrganizationsInfo } = useAppSelector(
    (state) => state.organizationsReducer
  );
  const navigate = useNavigate();
  const [menuItems, setMenuItems] = useState<IMenuItem[]>(
    organizationMenuItems
  );

  useEffect(() => {
    const orgs = allOrganizationsInfo.map((org) => ({
      icon: <FlagOutlined />,
      title: org.displayName,
      type: ProfileSettingsType.ORGANIZATION,
      isInteractive: true,
      classList: '',
      orgId: org.id,
    }));

    const allMenuItems = [
      organizationMenuItems[0],
      ...orgs,
      organizationMenuItems[1],
    ];
    setMenuItems(allMenuItems);
  }, [allOrganizationsInfo]);

  return (
    <div className="profile-settings-menu profile-settings-menu--organizations">
      {menuItems.map(
        ({ icon, title, type, isInteractive, classList, orgId }, i) => (
          <div
            className={`profile-settings-menu__item ${
              (settingsType === type &&
                settingsType !== ProfileSettingsType.ORGANIZATION) ||
              (settingsType === ProfileSettingsType.ORGANIZATION &&
                orgId === organizationId)
                ? 'profile-settings-menu__item--active'
                : ''
            } ${
              isInteractive
                ? ''
                : 'profile-settings-menu__item--not-interactive'
            } ${classList}`}
            key={i}
            onClick={() => {
              if (isInteractive) {
                if (type === ProfileSettingsType.ORGANIZATION && orgId) {
                  navigate(
                    `${RouteNames.PROFILE_SETTINGS_NO_PARAMS}/${type}/${orgId}/${OrganizationSettingsType.MEMBERS}`
                  );
                } else {
                  navigate(`${RouteNames.PROFILE_SETTINGS_NO_PARAMS}/${type}`);
                }
              }
            }}
          >
            {icon ? (
              <div className="profile-settings-menu-icon">
                {settingsType === ProfileSettingsType.ORGANIZATION &&
                orgId === organizationId ? (
                  <FlagFilled />
                ) : (
                  icon
                )}
              </div>
            ) : null}
            <div className="profile-settings-menu-title">{title}</div>
          </div>
        )
      )}
    </div>
  );
};

export default ProfileSettingsOrganizationsMenu;
