import { Radio, RadioChangeEvent } from 'antd';
import React, { FC, useState } from 'react';
import IProjectStartInfo from '../../types/IProjectStartInfo';
import {
  widgetColorOptions,
  widgetMessageStyleOptions,
  WidgetMessageStyles,
  widgetThemeOptions,
} from '../../shared/constants';
import './widget-pro-settings-panel.scss';

interface IWidgetProSettingsPanelProps {
  project: IProjectStartInfo;
  widgetProSettingsPanelId: string;
}

const SDK_NAME = process.env.REACT_APP_SDK_NAME || '';

const WidgetProSettingsPanel: FC<IWidgetProSettingsPanelProps> = ({
  project,
  widgetProSettingsPanelId,
}) => {
  const [themeMode, setThemeMode] = useState(project.widgetSettings.theme);
  const [themeColor, setThemeColor] = useState(project.widgetSettings.color);
  // TODO: change messageStyle with value from project.widgetSettings (when it will be added on server side)
  const [messageStyle, setMessageStyle] = useState(WidgetMessageStyles.BRIGHT);

  const onThemeModeChange = ({ target: { value } }: RadioChangeEvent) => {
    setThemeMode(value);
    // @ts-ignore
    window[SDK_NAME].setTheme(themeColor, value);
  };

  const onMessageStyleChange = ({ target: { value } }: RadioChangeEvent) => {
    setMessageStyle(value);
    // @ts-ignore
    window[SDK_NAME].setMessageStyle(value);
  };

  const onThemeColorChange = (color: string) => {
    setThemeColor(color);
    // @ts-ignore
    window[SDK_NAME].setTheme(color, themeMode);
  };

  return (
    <div className={'widget-pro-settings-panel'} id={widgetProSettingsPanelId}>
      <div className={'widget-pro-settings-panel__radio-groups-container'}>
        <Radio.Group
          className={'widget-pro-settings-panel__radio-group'}
          options={widgetThemeOptions}
          onChange={onThemeModeChange}
          value={themeMode}
          optionType="button"
          size={'small'}
        />

        <Radio.Group
          className={
            'widget-pro-settings-panel__radio-group widget-pro-settings-panel__radio-group--msgs-style'
          }
          options={widgetMessageStyleOptions}
          onChange={onMessageStyleChange}
          value={messageStyle}
          optionType="button"
          size={'small'}
        />
      </div>

      <div className={'widget-pro-settings-panel__theme-colors-container'}>
        {widgetColorOptions.map((color) => {
          const activeClass =
            themeColor === color.value
              ? 'widget-pro-settings-panel__theme-color--active'
              : '';
          return (
            <div
              key={color.value}
              style={{ backgroundColor: color.value }}
              className={`widget-pro-settings-panel__theme-color ${activeClass}`}
              onClick={() => onThemeColorChange(color.value)}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

export default WidgetProSettingsPanel;
