import React, { FC, useEffect, useState } from 'react';
import { Form, message, notification, Result, UploadFile } from 'antd';
import {
  SettingsTabsTypes,
  WidgetStreamModes,
} from '../../../shared/constants';
import './settings.scss';
import IUpdateProjectSettings from '../../../types/IUpdateProjectSettings';
import { projectsAPI } from '../../../services/projects-service';
import { resizeFileTo256PNG, showErrorMessage } from '../../../shared/helpers';
import { useAppSelector } from '../../../hooks/redux-hooks';
import IProject from '../../../types/IProject';
import { useParams } from 'react-router-dom';
import Loading from '../../loading/loading';
import LeavePageConfirmModal from '../../modals/leave-page-confirm-modal/leave-page-confirm-modal';
import ReactRouterPrompt from 'react-router-prompt';
import {
  openSaveChanges,
  useScrollSaveChangesSubscription,
} from '../../shared/save-changes/save-changes';
import IWidgetSettings, {
  IWidgetSpeechBubbleItem,
  IWidgetSpeechBubbles,
} from '../../../types/IWidgetSettings';
import SettingsTabs from './settings-tabs';
import { BUBBLE_DEFAULT_MESSAGE } from './tabs-components/appearance-settings/fake-widget/fake-widget-components/bubble/bubble';
import { allTimezones, useTimezoneSelect } from 'react-timezone-select';
import IPromptSize from '../../../types/IPromptSize';
import { convertBracesToCamelCase } from '../../lexical/lexical-helpers';
import SettingsHelper from './settings-helper';

const SAVE_SETTINGS_CHANGES_NOTIFICATION_KEY =
  'saveSettingsChangesNotificationKey';

const SETTINGS_SAVE_CHANGES = 'notification-save-changes--settings';
const DEFAULT_TIMEZONE = 'Etc/GMT';
const labelStyle = 'original';
const timezones = {
  ...allTimezones,
};

const getSpeechBubbleInitialValues = (userProject: IProject | undefined) => {
  const defValue = {
    isEnabled: true,
    items: [
      {
        text: BUBBLE_DEFAULT_MESSAGE,
        startDelay: 12,
        duration: 3,
      },
    ],
  };

  if (!userProject) {
    return defValue;
  }

  const speechBubble = userProject.widgetSettings.speechBubble;
  if (speechBubble) {
    defValue.isEnabled = speechBubble.isEnabled;
  }
  if (speechBubble && speechBubble.items && speechBubble.items.length > 0) {
    defValue.items = speechBubble.items;
  }

  return defValue;
};

interface ISettingsProps {
  projectId: string;
  ownerIdOfCurrentOrg: string | null;
}

const Settings: FC<ISettingsProps> = ({ projectId, ownerIdOfCurrentOrg }) => {
  const { orgId, subtab } = useParams();
  const [
    lazyGetProjectOptions,
    {
      data: projectOptionsData,
      isLoading: projectOptionsLoading,
      error: projectOptionsError,
    },
  ] = projectsAPI.useLazyGetProjectOptionsQuery();
  const { options: projectOptions } = useAppSelector(
    (state) => state.projectReducer
  );
  const {
    data: userProject,
    isLoading: projectLoading,
    error,
  } = projectsAPI.useGetUserProjectByIdQuery({ id: projectId, orgId });
  const {
    data: projectStartInfo,
    isLoading: projectStartInfoLoading,
    error: projectStartInfoError,
    refetch: refetchStartInfo,
  } = projectsAPI.useGetProjectStartInfoQuery(projectId);
  const [lazyGetUserProjectById] = projectsAPI.useLazyGetUserProjectByIdQuery();
  const [updateProject, { isLoading }] = projectsAPI.useUpdateProjectMutation();
  const [updateProjectImage, { isLoading: updateProjectImageLoading }] =
    projectsAPI.useUpdateProjectImageMutation();
  const { userProjects } = useAppSelector((state) => state.projectReducer);
  const { subscriptionIsActive } = useAppSelector(
    (state) => state.subscriptionReducer
  );
  const [image, setImage] = useState<UploadFile | null>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [notificationIsOpen, setNotificationIsOpen] = useState(false);
  const [updatingSettings, setUpdatingSettings] = useState(false);
  const [basicSettingsForm] = Form.useForm();
  const [appearanceSettingsForm] = Form.useForm();
  const [bubbleSettingsForm] = Form.useForm();
  const [advancedLlmSettingsForm] = Form.useForm();
  const { parseTimezone } = useTimezoneSelect({ labelStyle, timezones });
  const [notificationAPI, notificationContextHolder] =
    notification.useNotification();

  useEffect(() => {
    refetchStartInfo();
  }, [userProject]);

  useEffect(() => {
    if (!projectOptions) {
      lazyGetProjectOptions();
    }
  }, [projectOptions]);

  const openNotification = () => {
    if (notificationIsOpen) return;
    setNotificationIsOpen(true);
  };

  useScrollSaveChangesSubscription(notificationIsOpen, SETTINGS_SAVE_CHANGES);

  useEffect(() => {
    if (notificationIsOpen) {
      const saveIsLoading = isLoading || updateProjectImageLoading;
      const saveIsDisabled = saveDisabled || !subscriptionIsActive;
      openSaveChanges(
        notificationAPI,
        SAVE_SETTINGS_CHANGES_NOTIFICATION_KEY,
        () => handleUpdateSettings(getAllValues()),
        saveIsLoading,
        saveIsDisabled,
        SETTINGS_SAVE_CHANGES,
        setNotificationIsOpen
      );
    }
  }, [notificationIsOpen]);

  useEffect(() => {
    if (saveDisabled) {
      setNotificationIsOpen(false);
      notificationAPI.destroy(SAVE_SETTINGS_CHANGES_NOTIFICATION_KEY);
    }
  }, [saveDisabled]);

  useEffect(() => {
    const suggestedQuestions = userProject?.suggestedQuestions?.join('\n');
    const timeZone = userProject?.regionalSettings?.timeZone?.name
      ? userProject.regionalSettings.timeZone.name
      : DEFAULT_TIMEZONE;

    if (userProject) {
      const projectValues = {
        ...userProject,
        suggestedQuestions,
        ...userProject.widgetSettings,
        timeZone,
        ...userProject.promptSize,
      };

      basicSettingsForm.setFieldsValue(projectValues);
      appearanceSettingsForm.setFieldsValue(projectValues);
      bubbleSettingsForm.setFieldsValue(
        getSpeechBubbleInitialValues(userProject)
      );
      advancedLlmSettingsForm.setFieldsValue(projectValues);
    }

    handleChange();
  }, [userProject, subtab]);

  useEffect(() => {
    lazyGetUserProjectById({ id: projectId, orgId });
  }, [userProjects]);

  const handleUpdateSettings = async (values: any) => {
    setSaveDisabled(true);
    setUpdatingSettings(true);
    let error = false;

    if (checkProjectDataChanged()) {
      const suggestedQuestions = suggestedQuestionsValuesToString(
        values.suggestedQuestions
      )?.split('\n');

      const widgetSettings = {
        agentName: values.agentName,
        userLabel: values.userLabel,
        placeHolder: values.placeHolder,
        followPages: values.followPages,
        theme: values.theme,
        color: values.color,
        speechBubble: values.widgetSettings.speechBubble,
        hasBackgroundImage: false,
        enableMicrophone: values.enableMicrophone,
        enableAttachments: false,
        enableReferences: values.enableReferences,
        streamMode: values.streamMode || WidgetStreamModes.DEFAULT,
        title: values.title,
        fileAttachmentEnabled: values.fileAttachmentEnabled,
      };

      let timezoneOffset: number = 0;
      let timezoneName = DEFAULT_TIMEZONE;

      try {
        if (values.timeZone) {
          if (values.timeZone.name) {
            timezoneName = values.timeZone.name;
            timezoneOffset = parseTimezone(values.timeZone.name).offset || 0;
          } else {
            timezoneName = values.timeZone;
            timezoneOffset = parseTimezone(values.timeZone).offset || 0;
          }
        }
      } catch (e) {
        console.error(e);
      }

      const regionalSettings = {
        timeZone: {
          name: timezoneName,
          offset: timezoneOffset.toString(),
        },
      };

      const promptSize: IPromptSize = {
        knowledgeMaxChars:
          values.knowledgeMaxChars || values.promptSize.knowledgeMaxChars,
        embeddingChunkChars:
          values.embeddingChunkChars || values.promptSize.embeddingChunkChars,
        recentHistoryMaxChars:
          values.recentHistoryMaxChars ||
          values.promptSize.recentHistoryMaxChars,
        recentHistoryMinMessages:
          values.recentHistoryMinMessages ||
          values.promptSize.recentHistoryMinMessages,
        summarizeMessages:
          values.summarizeMessages || values.promptSize.summarizeMessages,
      };

      for (const key in values) {
        if (Object.prototype.hasOwnProperty.call(values, key)) {
          if (typeof values[key] === 'string')
            values[key] = convertBracesToCamelCase(values[key]);
        }
      }

      const result = await updateProject({
        id: projectId,
        body: {
          ...userProject,
          ...values,
          suggestedQuestions: suggestedQuestions,
          widgetSettings,
          regionalSettings,
          promptSize,
        },
      });

      if ('error' in result) {
        await showErrorMessage(messageApi, result.error);
        error = true;
      } else {
        messageApi.success('Settings has been updated.');
      }
    }

    if (image) {
      const data = new FormData();
      const fileName = image.name;
      if (image.type === 'image/gif') {
        data.append('file', image as unknown as Blob, fileName);
      } else {
        const resizedImage = await resizeFileTo256PNG(image as unknown as Blob);
        data.append('file', resizedImage as Blob, fileName);
      }

      const result = await updateProjectImage({
        id: projectId,
        body: data,
      });

      if ('error' in result) {
        await showErrorMessage(messageApi, result.error);
      } else {
        messageApi.success('Image has been updated.');
      }

      setImage(null);
    }

    await lazyGetUserProjectById({ id: projectId, orgId });
    setUpdatingSettings(false);

    if (error) {
      setSaveDisabled(false);
      setNotificationIsOpen(true);
    }
  };

  const suggestedQuestionsValuesToString = (values: string | string[]) => {
    if (Array.isArray(values)) {
      return values.join('\n');
    }

    return values;
  };

  const checkProjectDataChanged = () => {
    if (!userProject) return;

    const allValues = getAllValues() as IUpdateProjectSettings;
    const widgetSettings = userProject.widgetSettings;

    let valuesChanged = false;
    const formWidgetSettings = SettingsHelper.getFormWidgetSettings(
      allValues,
      getSpeechBubbleInitialValues(userProject)
    );

    const isWidgetSettingsKey = (key: string) => {
      return Object.keys(formWidgetSettings).includes(key);
    };

    const checkWidgetSettingsChanged = (key: string) => {
      const formValue = formWidgetSettings[key as keyof IWidgetSettings];
      const widgetValue = widgetSettings[key as keyof IWidgetSettings];

      if (formValue !== widgetValue) {
        if (formValue === '' && widgetValue === null) return;
        if (key === 'speechBubble') {
          const speechBubbleFormValue = formValue as IWidgetSpeechBubbles;

          if (
            widgetValue === null &&
            JSON.stringify(speechBubbleFormValue) ===
              JSON.stringify(getSpeechBubbleInitialValues(userProject))
          ) {
            return;
          }
          const speechBubbleIsEmptyArray =
            speechBubbleFormValue &&
            Array.isArray(speechBubbleFormValue.items) &&
            speechBubbleFormValue.items.length === 0;
          const availableItemsValueIfNullOnServer =
            speechBubbleIsEmptyArray ||
            speechBubbleFormValue.items === null ||
            speechBubbleFormValue.items === undefined;
          if (
            availableItemsValueIfNullOnServer &&
            widgetValue === null &&
            speechBubbleFormValue.isEnabled
          )
            return;
          if (JSON.stringify(formValue) === JSON.stringify(widgetValue)) return;

          if (formValue && widgetValue) {
            const changed = SettingsHelper.checkSpeechBubbleItemsChanged(
              speechBubbleFormValue,
              widgetValue as IWidgetSpeechBubbles
            );
            if (changed) {
              valuesChanged = true;
              return;
            }
            return;
          }
        }

        valuesChanged = true;
      }
    };

    const checkSuggestedQuestionsChanged = () => {
      const suggestedQuestionValue = suggestedQuestionsValuesToString(
        allValues.suggestedQuestions
      );

      if (
        (suggestedQuestionValue as unknown as string)?.split('\n').join('') !==
        (userProject.suggestedQuestions as string[])?.join('')
      ) {
        valuesChanged = true;
      }
    };

    const checkTimezoneChanged = () => {
      const projectTimezone =
        userProject?.regionalSettings?.timeZone?.name || DEFAULT_TIMEZONE;
      const timezoneStringValue =
        typeof allValues.timeZone === 'string' ? allValues.timeZone : '';
      const formTimezone =
        allValues.timeZone?.name || timezoneStringValue || DEFAULT_TIMEZONE;
      if (formTimezone !== projectTimezone) {
        valuesChanged = true;
      }
    };

    for (let key in allValues) {
      if (key === 'file' || key === 'delete' || key === 'widgetSettings')
        continue;
      if (key === 'suggestedQuestions') {
        checkSuggestedQuestionsChanged();
      } else if (isWidgetSettingsKey(key)) {
        checkWidgetSettingsChanged(key);
      } else if (key === 'timeZone') {
        checkTimezoneChanged();
      } else {
        let formValue = allValues[key as keyof IUpdateProjectSettings];
        const projectValue = userProject[key as keyof IProject];
        const promptSizeProjectValue =
          userProject.promptSize[key as keyof IPromptSize];

        if (typeof projectValue === 'string' && formValue !== undefined) {
          formValue = convertBracesToCamelCase(formValue as string);
        }

        if (
          (projectValue && formValue !== projectValue) ||
          (!projectValue && formValue && !promptSizeProjectValue)
        ) {
          valuesChanged = true;
        } else if (
          promptSizeProjectValue &&
          formValue !== promptSizeProjectValue
        ) {
          valuesChanged = true;
        }
      }
    }

    return valuesChanged;
  };

  const getAllValues = () => {
    let dynamicValues = {};
    let bubbleSettings = null;

    switch (subtab) {
      case SettingsTabsTypes.BASIC:
        dynamicValues = basicSettingsForm.getFieldsValue();
        break;
      case SettingsTabsTypes.APPEARANCE:
        dynamicValues = appearanceSettingsForm.getFieldsValue();
        bubbleSettings = bubbleSettingsForm.getFieldsValue();
        break;
      case SettingsTabsTypes.ADVANCED_LLM_SETTINGS:
        dynamicValues = advancedLlmSettingsForm.getFieldsValue();
        break;
      default:
        dynamicValues = basicSettingsForm.getFieldsValue();
    }

    let returnedValues = {
      ...userProject,
      ...userProject?.widgetSettings,
      ...userProject?.regionalSettings,
      ...dynamicValues,
    };

    if (bubbleSettings && returnedValues.widgetSettings) {
      if (JSON.stringify(bubbleSettings) === '{}') {
        bubbleSettings = getSpeechBubbleInitialValues(userProject);
      }
      returnedValues = {
        ...returnedValues,
        widgetSettings: {
          ...returnedValues.widgetSettings,
          speechBubble: bubbleSettings,
        },
      };
    }

    return returnedValues;
  };

  const handleChange = () => {
    if (!userProject || updatingSettings) return;

    const allValues = getAllValues();

    if (!allValues.name) {
      setSaveDisabled(true);
      return;
    }

    const bubbles = allValues.widgetSettings?.speechBubble?.items;

    if (bubbles && bubbles.length > 0) {
      const bubblesWithEmptyText = bubbles.filter(
        (bubble: IWidgetSpeechBubbleItem) =>
          !bubble || (bubble && bubble.text === '')
      );
      if (bubblesWithEmptyText.length > 0) {
        setSaveDisabled(true);
        return;
      }
    }

    for (let key in allValues) {
      if (key === 'file') continue;
      if (checkProjectDataChanged() || image) {
        setSaveDisabled(false);
        openNotification();
        return;
      }
    }

    setSaveDisabled(true);
    notificationAPI.destroy(SAVE_SETTINGS_CHANGES_NOTIFICATION_KEY);
  };

  return (
    <div className="settings">
      {notificationContextHolder}
      {contextHolder}
      <div className="project-setting-content-container">
        {projectLoading || projectOptionsLoading || projectStartInfoLoading ? (
          <Loading />
        ) : error &&
          'data' in error &&
          error.data &&
          'error' in (error.data as { error: string }) ? (
          <Result
            status="error"
            title="Ooops, something went wrong."
            subTitle={(error.data as { error: string })['error']}
          />
        ) : error ? (
          <div>Ooops, something went wrong.</div>
        ) : projectOptionsError ? (
          <div>Can't get project options, try again later.</div>
        ) : projectOptions && userProject && projectStartInfo ? (
          <>
            <SettingsTabs
              userProject={userProject}
              projectStartInfo={projectStartInfo}
              messageApi={messageApi}
              formInstances={{
                basicSettingsForm,
                appearanceSettingsForm,
                bubbleSettingsForm,
                advancedLlmSettingsForm,
              }}
              handleFormsChange={handleChange}
              image={image}
              setImage={setImage}
              ownerIdOfCurrentOrg={ownerIdOfCurrentOrg}
            />
          </>
        ) : (
          "Can't get project info, try again later."
        )}
      </div>
      <ReactRouterPrompt when={!saveDisabled}>
        {({ isActive, onConfirm, onCancel }) => (
          <LeavePageConfirmModal
            isOpen={isActive}
            onCancel={() => {
              setNotificationIsOpen(true);
              onCancel();
            }}
            onOk={onConfirm}
          />
        )}
      </ReactRouterPrompt>
    </div>
  );
};

export default Settings;
