import React, { FC, SetStateAction, useEffect } from 'react';
import { Checkbox, Form, Input, Modal, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import './lexical-command-props-modal.scss';
import { InfoCircleOutlined, InfoCircleTwoTone } from '@ant-design/icons';

export interface ILexicalCommandModalFormItemProps {
  title: string;
  fieldName: string;
  tooltipInfo: string;
  handleChange?: () => void;
}

const FormItem: FC<ILexicalCommandModalFormItemProps> = ({
  title,
  fieldName,
  tooltipInfo,
  handleChange,
}) => {
  const [inputIsDisabled, setInputIsDisabled] = React.useState(false);

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    setInputIsDisabled(e.target.checked);
  };

  useEffect(() => {
    if (handleChange) {
      handleChange();
    }
  }, [inputIsDisabled]);

  return (
    <div className={'lexical-command-props-item'}>
      <div className={'lexical-command-props-item__title'}>
        {title}
        <Tooltip
          placement="top"
          title={tooltipInfo}
          color={'#f3f3f3'}
          overlayInnerStyle={{ color: '#3c4257' }}
          overlayStyle={{ zIndex: 3000 }}
        >
          <span>
            <InfoCircleOutlined style={{ marginTop: 4 }} />
          </span>
        </Tooltip>
      </div>
      <Checkbox
        onChange={handleCheckboxChange}
        className={'lexical-command-props-item__checkbox'}
      ></Checkbox>
      {inputIsDisabled ? (
        <div className={'lexical-command-props-item__input'}></div>
      ) : (
        <Form.Item
          style={{ marginBottom: 0 }}
          className={'lexical-command-props-item__input'}
          name={fieldName}
        >
          <Input />
        </Form.Item>
      )}
    </div>
  );
};

export interface ILexicalCommandModalStateProps {
  title: string;
  description: string;
  options: ILexicalCommandModalFormItemProps[];
  handleOkCb: (values: any) => void;
}

export interface ISetLexicalCommandModalProps {
  (state: SetStateAction<ILexicalCommandModalStateProps>): void;
}

export interface ILexicalCommandModalProps
  extends ILexicalCommandModalStateProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const LexicalCommandPropsModal: FC<ILexicalCommandModalProps> = ({
  isOpen,
  setIsOpen,
  title,
  options,
  description,
  handleOkCb,
}) => {
  const [form] = Form.useForm();
  const [okDisabled, setOkDisabled] = React.useState(true);

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleOk = () => {
    const values = form.getFieldsValue();
    handleOkCb(values);
    setIsOpen(false);
  };

  const handleChange = () => {
    const values = form.getFieldsValue();
    const keys = Object.keys(values);
    let isDisabled = false;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (values[key] === undefined || values[key] === '') {
        isDisabled = true;
        break;
      }
    }
    setOkDisabled(isDisabled);
  };

  return (
    <Modal
      title={title}
      open={isOpen}
      centered={true}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ disabled: okDisabled }}
    >
      <div style={{ marginBottom: 10 }}>{description}</div>
      <div className={'lexical-command-props-item'}>
        <div className={'lexical-command-props-item__title'}></div>
        <div
          className={
            'lexical-command-props-item__checkbox lexical-command-props-item__title-collect'
          }
        >
          Collect
        </div>
        <div className={'lexical-command-props-item__input'}></div>
      </div>
      <Form form={form} onChange={handleChange}>
        {options.map((option, index) => {
          const title = option.title.replaceAll('_', ' ');
          return (
            <FormItem
              title={title}
              fieldName={option.fieldName}
              tooltipInfo={option.tooltipInfo}
              key={index}
              handleChange={handleChange}
            />
          );
        })}
        <div className={'lexical-command-props-info'}>
          <InfoCircleTwoTone className={'lexical-command-props-info__icon'} />
          The bot will automatically collect empty fields from the user.
        </div>
      </Form>
    </Modal>
  );
};

export default LexicalCommandPropsModal;
