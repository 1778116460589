import { FC, useEffect, useState } from 'react';
import { Form, message, notification, Result } from 'antd';
import InstructionPromptField from '../settings/tabs-components/prompt-settings/instruction-prompt-field';
import { projectsAPI } from '../../../services/projects-service';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../hooks/redux-hooks';
import Loading from '../../loading/loading';
import {
  openSaveChanges,
  useScrollSaveChangesSubscription,
} from '../../shared/save-changes/save-changes';
import { showErrorMessage } from '../../../shared/helpers';
import './prompt.scss';
import {
  convertBracesToCamelCase,
  convertCamelCaseToSnakeCase,
} from '../../lexical/lexical-helpers';

interface IPromptProps {
  projectId: string;
  ownerIdOfCurrentOrg: string | null;
}

const SAVE_PROMPT_CHANGES_NOTIFICATION_KEY =
  'saveSettingsChangesNotificationKey';

const PROMPT_SAVE_CHANGES = 'notification-save-changes--prompt';

const Prompt: FC<IPromptProps> = ({ projectId, ownerIdOfCurrentOrg }) => {
  const { orgId } = useParams();
  const { subscriptionIsActive } = useAppSelector(
    (state) => state.subscriptionReducer
  );
  const [
    lazyGetProjectOptions,
    {
      data: projectOptionsData,
      isLoading: projectOptionsLoading,
      error: projectOptionsError,
    },
  ] = projectsAPI.useLazyGetProjectOptionsQuery();
  const {
    data: userProject,
    isLoading: projectLoading,
    error,
  } = projectsAPI.useGetUserProjectByIdQuery({ id: projectId, orgId });
  const [lazyGetUserProjectById] = projectsAPI.useLazyGetUserProjectByIdQuery();
  const [updateProject, { isLoading }] = projectsAPI.useUpdateProjectMutation();
  const { options: projectOptions } = useAppSelector(
    (state) => state.projectReducer
  );
  const [form] = Form.useForm();
  const [notificationIsOpen, setNotificationIsOpen] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [notificationAPI, notificationContextHolder] =
    notification.useNotification();

  const openNotification = () => {
    if (notificationIsOpen) return;
    setNotificationIsOpen(true);
  };

  useScrollSaveChangesSubscription(notificationIsOpen, PROMPT_SAVE_CHANGES);

  useEffect(() => {
    if (notificationIsOpen) {
      const saveIsDisabled = saveDisabled || !subscriptionIsActive;
      openSaveChanges(
        notificationAPI,
        SAVE_PROMPT_CHANGES_NOTIFICATION_KEY,
        () => form.submit(),
        isLoading,
        saveIsDisabled,
        PROMPT_SAVE_CHANGES,
        setNotificationIsOpen
      );
    }
  }, [notificationIsOpen]);

  useEffect(() => {
    if (!projectOptions) {
      lazyGetProjectOptions();
    }
  }, [projectOptions]);

  const handleChange = () => {
    if (!userProject) return;

    const allValues = form.getFieldsValue();

    const instructionPromptDB = convertCamelCaseToSnakeCase(
      userProject.instructionPrompt
    );

    if (instructionPromptDB !== allValues['instructionPrompt']) {
      setSaveDisabled(false);
      openNotification();
      return;
    }

    setSaveDisabled(true);
    notificationAPI.destroy(SAVE_PROMPT_CHANGES_NOTIFICATION_KEY);
  };

  const handleLexicalFieldChange = (fieldName: string, value: string) => {
    if (!form) return;
    form.setFieldValue(fieldName, value);
    handleChange();
  };

  const handleUpdatePromptSettings = async (values: any) => {
    setSaveDisabled(true);

    for (const key in values) {
      if (Object.prototype.hasOwnProperty.call(values, key)) {
        values[key] = convertBracesToCamelCase(values[key]);
      }
    }

    const result = await updateProject({
      id: projectId,
      body: {
        ...userProject,
        ...values,
      },
    });

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
      setSaveDisabled(false);
      setNotificationIsOpen(true);
    } else {
      lazyGetUserProjectById({ id: projectId, orgId });
      await messageApi.success('Prompt settings has been updated.');
    }
  };

  const getPromptSettingsForm = () => {
    if (!userProject) return null;

    return (
      <Form
        form={form}
        layout="vertical"
        className="settings-form"
        onFinish={handleUpdatePromptSettings}
        onChange={handleChange}
        requiredMark={false}
        style={{ flexWrap: 'wrap' }}
      >
        <div style={{ width: '100%' }} className={'instruction-prompt'}>
          <InstructionPromptField
            userProject={userProject}
            handleLexicalFieldChange={handleLexicalFieldChange}
            ownerIdOfCurrentOrg={ownerIdOfCurrentOrg}
          />
        </div>
      </Form>
    );
  };

  const getComponent = () => {
    if (projectLoading || projectOptionsLoading) {
      return <Loading />;
    }

    if (error) {
      if (
        'data' in error &&
        error.data &&
        'error' in (error.data as { error: string })
      ) {
        return (
          <Result
            status="error"
            title="Ooops, something went wrong."
            subTitle={(error.data as { error: string })['error']}
          />
        );
      }

      return <Result status="error" title="Ooops, something went wrong." />;
    } else if (projectOptionsError) {
      return (
        <Result
          status="error"
          title="Can't get project options, try again later."
        />
      );
    }

    return getPromptSettingsForm();
  };

  return (
    <div>
      {contextHolder}
      {notificationContextHolder}
      <div className="project-setting-content-container">{getComponent()}</div>
    </div>
  );
};

export default Prompt;
