import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  Form,
  Input,
  message,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import './account-setting.scss';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { usersAPI } from '../../../services/users-service';
import { resizeFileTo256PNG, showErrorMessage } from '../../../shared/helpers';
import ImgCrop from 'antd-img-crop';

interface IUpdateUserInfoForm {
  firstName: string;
  lastName: string;
  email: string;
}

const AccountSetting: FC = () => {
  const [updateUserInfo, { isLoading }] = usersAPI.useUpdateUserInfoMutation();
  const [updateUserAvatar, { isLoading: updateAvatarLoading }] =
    usersAPI.useUpdateUserAvatarMutation();
  const { user } = useAppSelector((state) => state.userReducer);
  const [form] = Form.useForm();
  const [image, setImage] = useState<UploadFile | null>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [updateDisabled, setUpdateDisabled] = useState<boolean>(true);

  useEffect(() => {
    form.setFieldsValue({ ...user });
  }, [user]);

  useEffect(() => {
    handleChange && handleChange();
  }, [image]);

  const props: UploadProps = {
    onRemove: (file) => {
      setImage(null);
    },
    beforeUpload: (file) => {
      setImage(file);

      return false;
    },
    fileList: image ? [image] : [],
  };

  const handleUpdate = async (values: IUpdateUserInfoForm) => {
    setUpdateDisabled(true);

    if (checkUserDataChanged()) {
      const data = {
        userName: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
      };

      const result = await updateUserInfo(data);

      if ('error' in result) {
        await showErrorMessage(messageApi, result.error);
      } else {
        await messageApi.success('Account data has been updated.');
      }
    }

    if (image) {
      const avatarData = new FormData();
      const resizedImage = await resizeFileTo256PNG(image as unknown as Blob);
      avatarData.append('avatarImage', resizedImage as Blob);

      const result = await updateUserAvatar(avatarData);

      if ('error' in result) {
        await showErrorMessage(messageApi, result.error);
      } else {
        await messageApi.success('Avatar has been updated.');
      }
    }

    setImage(null);
  };

  const checkUserDataChanged = () => {
    if (!user) return;
    const values = form.getFieldsValue();

    if (
      values.lastName !== user.lastName ||
      values.firstName !== user.firstName
    ) {
      return true;
    }

    return false;
  };

  const handleChange = () => {
    if (!user) return;

    if (checkUserDataChanged() === false && !image) {
      setUpdateDisabled(true);
    } else {
      setUpdateDisabled(false);
    }
  };

  return (
    <div className="profile-setting-content-container">
      {contextHolder}
      <Form
        form={form}
        layout="vertical"
        className="profile-settings-form"
        onFinish={handleUpdate}
        onChange={handleChange}
      >
        <Form.Item label="First name" name="firstName">
          <Input placeholder="Enter first name" />
        </Form.Item>
        <Form.Item label="Last name" name="lastName">
          <Input placeholder="Enter last name" />
        </Form.Item>
        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              type: 'email',
              message: 'Enter a valid E-mail.',
            },
          ]}
        >
          <Input placeholder="Enter E-mail" disabled />
        </Form.Item>
        <Form.Item
          label="Avatar Image"
          // tooltip={{ title: 'Tooltip', icon: <InfoCircleOutlined /> }}
          name="file"
        >
          <ImgCrop rotationSlider showReset cropShape={'round'}>
            <Upload {...props} onChange={handleChange}>
              <Button>Choose File</Button>
            </Upload>
          </ImgCrop>
        </Form.Item>
        <Form.Item className="profile-settings-form-btn-container">
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading || updateAvatarLoading}
            disabled={updateDisabled}
          >
            Update
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AccountSetting;
