import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IProject from '../../types/IProject';
import { projectsAPI } from '../../services/projects-service';
import IInsight from '../../types/IInsight';
import IProjectInsightInfo from '../../types/IProjectInsightInfo';
import IProjectOptions from '../../types/IProjectOptions';
import fetchEngineOptions, {
  IEngine,
  IEngineOption,
} from '../../shared/engine-options';

interface IProjectsState {
  userProjects: IProject[];
  currentProjectInsights: IInsight[];
  knowledgeParentId: string;
  options: IProjectOptions | null;
  projectsLoading: boolean;
  engineOptions: IEngineOption[] | [];
  vectorStores: string[];
}

const initialState: IProjectsState = {
  userProjects: [],
  currentProjectInsights: [],
  knowledgeParentId: '',
  options: null,
  projectsLoading: false,
  engineOptions: [],
  vectorStores: [],
};

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setUserProjects(state, action: PayloadAction<IProject[]>) {
      state.userProjects = action.payload;
    },
    setKnowledgeParentId(state, action: PayloadAction<string>) {
      state.knowledgeParentId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      projectsAPI.endpoints.getUserProjects.matchFulfilled,
      (state, action: PayloadAction<IProject[]>) => {
        state.userProjects = action.payload;
        state.projectsLoading = false;
      }
    );
    builder.addMatcher(
      projectsAPI.endpoints.getUserProjects.matchPending,
      (state) => {
        state.projectsLoading = true;
      }
    );
    builder.addMatcher(
      projectsAPI.endpoints.getUserProjects.matchRejected,
      (state) => {
        state.projectsLoading = false;
      }
    );
    builder.addMatcher(
      projectsAPI.endpoints.getProjectInsights.matchFulfilled,
      (state, action: PayloadAction<IProjectInsightInfo>) => {
        state.currentProjectInsights = action.payload.feedback;
      }
    );
    builder.addMatcher(
      projectsAPI.endpoints.getProjectOptions.matchFulfilled,
      (state, action: PayloadAction<IProjectOptions>) => {
        state.options = action.payload;
      }
    );
    builder.addMatcher(
      projectsAPI.endpoints.getEngineOptions.matchFulfilled,
      (state, action: PayloadAction<IEngine>) => {
        state.engineOptions = fetchEngineOptions(action.payload);
      }
    );
    builder.addMatcher(
      projectsAPI.endpoints.getVectorStoresOptions.matchFulfilled,
      (state, action: PayloadAction<string[]>) => {
        state.vectorStores = action.payload;
      }
    );
  },
});

export default projectsSlice.reducer;
