import React, { useEffect, useState } from 'react';
import './user-projects.scss';
import Search from 'antd/es/input/Search';
import { Button, Card, Result } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { projectsAPI } from '../../services/projects-service';
import UserProjectCard from '../../components/user-project-card/user-project-card';
import { RouteNames } from '../../router/router';
import { Link, useNavigate, useParams } from 'react-router-dom';
import IProject from '../../types/IProject';
import CheckAuth from '../../components/check-auth/check-auth';
import { useAppSelector } from '../../hooks/redux-hooks';
import Loading from '../../components/loading/loading';
import { LS_CURRENT_ORGANIZATION_ID } from '../../shared/constants';
import useHasAccessToOrg from '../../hooks/useHasAccessToOrg';
import NoOrgAccessWarning from '../../components/no-org-access-warning/no-org-access-warning';

const CLONING_REFETCH_INTERVAL = 5000;

const UserProjects = () => {
  const { orgId } = useParams();
  const {
    currentOrganization,
    currentOrganizationLoading,
    allOrganizationsInfo,
  } = useAppSelector((state) => state.organizationsReducer);

  const { userProjects, projectsLoading } = useAppSelector(
    (state) => state.projectReducer
  );
  const { user } = useAppSelector((state) => state.userReducer);
  const navigate = useNavigate();
  const [lazyGetUserProjects, { error, isLoading }] =
    projectsAPI.useLazyGetUserProjectsQuery();
  const [projectList, setProjectList] = useState<IProject[]>([]);
  const noAccess = !useHasAccessToOrg(orgId);
  const [cloningLoading, setCloningLoading] = useState(false);
  const [userProjectsLoading, setUserProjectsLoading] = useState(true);
  const [lastOrgId, setLastOrgId] = useState<string>('');

  useEffect(() => {
    if (!projectList) return;
    let cloning = false;

    for (let i = 0; i < projectList.length; i++) {
      if (!projectList[i].isActive) {
        cloning = true;
        break;
      }
    }

    if (cloning) {
      window.setTimeout(() => {
        setCloningLoading(true);
      }, CLONING_REFETCH_INTERVAL);
    }
  }, [projectList]);

  useEffect(() => {
    if (cloningLoading && orgId) {
      lazyGetUserProjects({ orgId })
        .unwrap()
        .finally(() => {
          setCloningLoading(false);
          setUserProjectsLoading(false);
        });
    }
  }, [cloningLoading, orgId]);

  useEffect(() => {
    if (!orgId && allOrganizationsInfo) {
      const personalOrg = allOrganizationsInfo.find((org) => org.isPersonal);

      const savedOrgId = localStorage.getItem(LS_CURRENT_ORGANIZATION_ID);

      const userHasAccessToSavedOrg = allOrganizationsInfo.some((org) => {
        return org.id === savedOrgId;
      });

      const currentOrgValue = userHasAccessToSavedOrg
        ? savedOrgId
        : personalOrg?.id;

      if (currentOrgValue) {
        navigate(`${RouteNames.USER_PROJECTS}/${currentOrgValue}`);
      }
    }
  }, [orgId, user, allOrganizationsInfo]);

  useEffect(() => {
    if (!orgId || !user || currentOrganizationLoading) return;
    if (currentOrganization && currentOrganization.id !== lastOrgId) {
      setLastOrgId(currentOrganization.id);
      lazyGetUserProjects({ orgId: currentOrganization.id }).finally(() => {
        setUserProjectsLoading(false);
      });
    }
  }, [currentOrganization]);

  useEffect(() => {
    if (!userProjects) return;
    setProjectList(userProjects);
  }, [userProjects]);

  const onSearch = (value: string) => {
    if (!value) {
      setProjectList(userProjects || []);
      return;
    }
    const filteredProjects = userProjects?.filter((project) =>
      project.name.toLowerCase().startsWith(value.toLowerCase())
    );
    setProjectList(filteredProjects || []);
  };

  const userProjectPage = () => (
    <div className="user-projects">
      <div className="container">
        <div className="user-projects__inner">
          {/*{currentOrganizationLoading ? null : currentOrganization &&*/}
          {/*{currentOrganization && currentOrganization.isWhiteLabeled ? null : (*/}
          {/*{process.env.REACT_APP_BETA_OFF === 'true' ? null : (*/}
          {/*  <div className="beta-message">*/}
          {/*    Our service is presently in beta mode, and we kindly ask that you*/}
          {/*    report any errors to our support team at{' '}*/}
          {/*    <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}>*/}
          {/*      {process.env.REACT_APP_CONTACT_EMAIL}*/}
          {/*    </a>*/}
          {/*    .*/}
          {/*  </div>*/}
          {/*)}*/}
          {/*)}*/}
          <div className="user-projects__header">
            <div className="user-projects__title">Projects</div>
            <div className="user-projects__header-content">
              <Search
                placeholder="Search..."
                allowClear
                onSearch={onSearch}
                className="projects-search"
              />
              <Link
                to={`${RouteNames.USER_PROJECTS}/${orgId}${RouteNames.CREATE_PROJECT_TAB_NAME}`}
                className={'user-project-create-btn'}
              >
                <Button type="primary" disabled={noAccess}>
                  <PlusOutlined /> Create
                </Button>
              </Link>
            </div>
          </div>
          {
            /*userProjects && userProjects.length === 0 ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              imageStyle={{ height: 120 }}
              className="knowledge-no-data"
            />
          ) :*/ (isLoading && !cloningLoading) ||
            currentOrganizationLoading ||
            userProjectsLoading ||
            (projectsLoading && userProjectsLoading && !cloningLoading) ? (
              <Loading />
            ) : error &&
              'data' in error &&
              error.data &&
              'error' in (error.data as { error: string }) ? (
              <Result
                status="error"
                title="Ooops, something went wrong."
                subTitle={(error.data as { error: string })['error']}
              />
            ) : error ? (
              <div>Ooops, something went wrong.</div>
            ) : noAccess ? (
              <NoOrgAccessWarning />
            ) : (
              <div className="projects-list">
                {projectList.map((project, i) => (
                  <UserProjectCard
                    key={project.id + i.toString()}
                    project={project}
                  />
                ))}
                <div className="grid-item-container">
                  <Card
                    className="user-project-card add-project-card"
                    onClick={() =>
                      navigate(
                        `${RouteNames.USER_PROJECTS}/${orgId}${RouteNames.CREATE_PROJECT_TAB_NAME}`
                      )
                    }
                  >
                    <PlusOutlined />
                  </Card>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );

  return (
    <CheckAuth
      component={userProjectPage()}
      allowedForDomainTenantMember={true}
    />
  );
};

export default UserProjects;
