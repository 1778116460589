import React, { FC, useState } from 'react';
import { Form, Select } from 'antd';
import TextInputFormItem from './text-input-form-item';
import IInitialLabelContactRecordValue from '../../../../../types/contacts/IInitialLabelContactRecordValue';

interface ILabelFormItemProps {
  label: string;
  name: string;
  options: { value: string; label: string }[];
  initialValue: IInitialLabelContactRecordValue;
}

const LabelFormItem: FC<ILabelFormItemProps> = ({
  label,
  name,
  options,
  initialValue,
}) => {
  const [currentLabelValue, setCurrentLabelValue] = useState<string>(
    initialValue.label
  );

  return (
    <>
      <Form.Item label={label} name={name} style={{ width: '100%' }}>
        <Select
          options={options}
          popupClassName={'popup-select'}
          style={{ width: '100%' }}
          onChange={(value) => setCurrentLabelValue(value)}
        />
      </Form.Item>
      {currentLabelValue === 'other' && (
        <TextInputFormItem
          label={'Custom Label'}
          name={'customLabel'}
          placeholder={'Custom Label'}
        />
      )}
    </>
  );
};

export default LabelFormItem;
