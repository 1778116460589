import React, { FC, useEffect, useRef, useState } from 'react';
import Loading from '../../components/loading/loading';

const CookiePolicy: FC = () => {
  const cookiePolicyRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (process.env.REACT_APP_PARTNER_MODE === 'false') {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://cdn.iubenda.com/iubenda.js';
      script.onload = () => {
        setLoading(false);
      };

      if (cookiePolicyRef.current) {
        cookiePolicyRef.current.appendChild(script);
      }
    }
  }, [cookiePolicyRef, cookiePolicyRef.current]);

  return (
    <div ref={cookiePolicyRef}>
      {loading ? (
        <Loading />
      ) : process.env.REACT_APP_PARTNER_MODE === 'false' ? (
        <a
          href="https://www.iubenda.com/privacy-policy/96106497/cookie-policy"
          className="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed"
          title="Cookie Policy"
        >
          Cookie Policy
        </a>
      ) : null}
    </div>
  );
};

export default CookiePolicy;
