import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { FormInstance, message, Modal, Skeleton } from 'antd';
import { projectsAPI } from '../../../services/projects-service';
import JsonView from '@uiw/react-json-view';
import './trace-modal.scss';
import IPostTrace from '../../../types/IPostTrace';
import { MessageRoles } from '../../../shared/constants';
import { showErrorMessage } from '../../../shared/helpers';
import TraceCallForm from './trace-call-form';

interface ITraceModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  projectId: string;
  conversationId: string;
  messageTraceId: string;
  setMessageTraceId: Dispatch<SetStateAction<string>>;
}

export interface IHandleChangeTraceCallFormValues {
  (
    form: FormInstance,
    functionsInfo: any,
    setSubmitDisabled: Dispatch<SetStateAction<boolean>>
  ): void;
}

export interface IHandleSubmitTraceCallForm {
  (
    form: FormInstance,
    functionsInfo: any,
    setSubmitDisabled: Dispatch<SetStateAction<boolean>>,
    setTraceResult: Dispatch<SetStateAction<any>>
  ): void;
}

const TraceModal: FC<ITraceModalProps> = ({
  isOpen,
  setIsOpen,
  projectId,
  conversationId,
  messageTraceId,
  setMessageTraceId,
}) => {
  const [lazyGetMessageTrace, { isLoading, data, error }] =
    projectsAPI.useLazyGetTraceByMessageIdQuery();
  const [postTrace, { isLoading: isPostLoading }] =
    projectsAPI.usePostTraceMutation();
  const [messageApi, contextHolder] = message.useMessage();
  const [defaultValues, setDefaultValues] = React.useState<{}[]>([]);

  useEffect(() => {
    if (isOpen && projectId && conversationId && messageTraceId) {
      lazyGetMessageTrace({
        projectId: projectId,
        conversationId: conversationId,
        messageId: messageTraceId,
      });
    }
  }, [isOpen, projectId, conversationId, messageTraceId]);

  useEffect(() => {
    if (data) {
      const defaultValues = data.calls.map((call, index) => {
        return getDefaultValues(index);
      });
      setDefaultValues(defaultValues);
    }
  }, [data]);

  const handleCancel = () => {
    setIsOpen(false);
    setMessageTraceId('');
  };

  const getDefaultValues = (callIndex: number) => {
    if (data) {
      const messagesData = {};

      const call = data.calls[callIndex];
      if (!call) return {};

      const messages = call.request.messages.map((message, index) => {
        return {
          [`message-${index}-${message.role}`]: message.content,
        };
      });

      const additional = {
        model: call.request.model,
        temperature: call.request.temperature,
      };

      Object.assign(messagesData, ...messages, additional);

      return messagesData;
    }
    return {};
  };

  const handleSubmit: IHandleSubmitTraceCallForm = async (
    form,
    functionsInfo,
    setSubmitDisabled,
    setTraceResult
  ) => {
    setSubmitDisabled(true);
    const formValues = form.getFieldsValue();

    const postTraceData: IPostTrace = {
      model: formValues.model || '',
      temperature: formValues.temperature,
    } as IPostTrace;

    for (const key in formValues) {
      if (key.includes('message')) {
        postTraceData.messages = postTraceData.messages || [];
        const role = key.split('-')[2] as MessageRoles;
        const message: { role: MessageRoles; content: string; name?: string } =
          {
            role: role,
            content: formValues[key],
          };

        if (role === MessageRoles.FUNCTION) {
          message.name = JSON.parse(formValues[key]).name.replaceAll('.', '__');
        }

        postTraceData.messages.push(message);
      }
    }

    postTraceData.functions = functionsInfo;

    const result = await postTrace({
      projectId: projectId,
      trace: postTraceData,
    });
    if ('error' in result) {
      showErrorMessage(messageApi, result.error);
    } else {
      messageApi.success('Data has been sent.');
      setTraceResult(result.data);
    }

    setSubmitDisabled(false);

    // handleChangeFormValues(form, functionsInfo, setSubmitDisabled);
  };

  // const handleChangeFormValues: IHandleChangeTraceCallFormValues = (
  //   form,
  //   functionsInfo,
  //   setSubmitDisabled
  // ) => {
  //   const formValues = form.getFieldsValue();
  //   let isDisabled = true;
  //   const defaultValues = getDefaultValues(0);
  //
  //   for (const key in defaultValues) {
  //     if (
  //       formValues[key] !== defaultValues[key as keyof typeof defaultValues]
  //     ) {
  //       isDisabled = false;
  //       break;
  //     }
  //   }
  //
  //   if (data) {
  //     const functions = data.calls[0].request.functions || [];
  //     if (JSON.stringify(functionsInfo) !== JSON.stringify(functions)) {
  //       isDisabled = false;
  //     }
  //   }
  //
  //   for (const key in formValues) {
  //     if (key.includes('message')) {
  //       if (!formValues[key]) {
  //         isDisabled = true;
  //         break;
  //       }
  //     }
  //   }
  //
  //   setSubmitDisabled(isDisabled);
  // };

  const getContent = () => {
    if (isLoading) {
      return <Skeleton active />;
    } else if (error) {
      return (
        <div>
          Error:{' '}
          {((error as any).data && (error as any).data.title) ||
            "can't get trace info."}
        </div>
      );
    } else if (data) {
      return (
        <>
          <div style={{ fontWeight: 500, margin: '10px 0' }}>
            Show full info:
          </div>
          <JsonView value={data} displayDataTypes={false} collapsed={true} />
          <div style={{ marginTop: 20 }}>
            {data.calls.map((call, index) => {
              //  const defaultValues = getDefaultValues(index);

              return (
                <div key={index}>
                  <hr style={{ margin: '40px 0' }} />
                  <TraceCallForm
                    call={call}
                    defaultValues={defaultValues[index]}
                    // handleChangeFormValues={handleChangeFormValues}
                    handleSubmit={handleSubmit}
                  />
                </div>
              );
            })}
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <Modal
      title="Trace"
      open={isOpen}
      onCancel={handleCancel}
      centered={true}
      wrapClassName={'modal trace-modal'}
      maskStyle={{ zIndex: 1100 }}
      footer={null}
    >
      {contextHolder}
      {getContent()}
    </Modal>
  );
};

export default TraceModal;
