import React, { FC, useState } from 'react';
import { LogoMode } from '../../shared/constants';
import { getDomainForImage } from '../../shared/helpers';
import { useAppSelector } from '../../hooks/redux-hooks';

const LogoText: FC<{ mode: LogoMode }> = ({ mode }) => {
  const { globalTimestamp } = useAppSelector((state) => state.domainsReducer);
  const { name, filePath } = useAppSelector((state) => state.domainsReducer);
  const [logoTextSvgError, setLogoTextSvgError] = useState(false);

  return filePath ? (
    <div className="logo-text">
      {logoTextSvgError ? (
        <>{name}</>
      ) : (
        <img
          className={'custom-logo-text'}
          src={`https://static.${getDomainForImage()}/static/${filePath}logo-text-${mode}.svg?timestamp=${globalTimestamp}`}
          onError={() => setLogoTextSvgError(true)}
        />
      )}
    </div>
  ) : null;
};

export default LogoText;
