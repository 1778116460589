import React, { FC } from 'react';
import { Divider } from 'antd';
import TextInputFormItem from './form-items/text-input-form-item';

const SocialRecordFormElement: FC = () => {
  return (
    <>
      <Divider orientation="left" className={'contact-form-divider'}>
        Social
      </Divider>
      <TextInputFormItem
        label={'Platform'}
        name={'platform'}
        placeholder={'Platform'}
      />
      <div style={{ display: 'flex', gap: 24 }}>
        <TextInputFormItem
          label={'Social Username'}
          name={'socialUsername'}
          placeholder={'Social Username'}
        />
        <TextInputFormItem
          label={'Social ID'}
          name={'socialId'}
          placeholder={'Social ID'}
        />
      </div>
      <TextInputFormItem
        label={'Social URL'}
        name={'socialUrl'}
        placeholder={'Social URL'}
      />
    </>
  );
};

export default SocialRecordFormElement;
