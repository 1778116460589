import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  LS_CURRENT_ORGANIZATION_ID,
  LS_TOKEN_KEY,
} from '../../../shared/constants';
import { RouteNames } from '../../../router/router';

const LoginByToken: FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      localStorage.removeItem(LS_CURRENT_ORGANIZATION_ID);
      localStorage.setItem(LS_TOKEN_KEY, token);
      navigate(RouteNames.USER_PROJECTS);
      window.location.reload();
    } else {
      navigate(RouteNames.LOGIN);
    }
  });

  return <div></div>;
};

export default LoginByToken;
