import { FC, useEffect, useState } from 'react';
import IDefaultPluginFieldProps from './IDefaultPluginField';
import { Form, TimePicker } from 'antd';
import { V_REQUIRED_FIELD } from '../../../../shared/constants';
import dayjs, { Dayjs } from 'dayjs';

const TimeFormItem: FC<IDefaultPluginFieldProps> = ({
  displayName,
  keyBase,
  index,
  isRequired,
  description,
  convertValue,
}) => {
  const [value, setValue] = useState<string>('now');

  useEffect(() => {
    if (convertValue) {
      const utcDate = new Date(convertValue);
      const localDayjs = dayjs(utcDate).format('HH:mm:ss');
      setValue(localDayjs);
    }
  }, [convertValue]);

  return (
    <Form.Item
      label={displayName}
      name={keyBase}
      key={index}
      rules={[
        {
          required: isRequired,
          message: V_REQUIRED_FIELD,
        },
      ]}
      tooltip={description}
    >
      <TimePicker
        className="custom-time-picker"
        style={{ width: '100%' }}
        popupStyle={{ zIndex: 2000 }}
        showNow={false}
        secondStep={5}
        placeholder={value}
      />
    </Form.Item>
  );
};

export default TimeFormItem;
