import React, { FC, useEffect, useState } from 'react';
import { projectsAPI } from '../../../services/projects-service';
import UploadFileItem, {
  CustomUploadFile,
} from '../data-source-upload-modal/upload-file-item';
import { Button, message, Modal, Upload, UploadProps } from 'antd';
import { suuid } from '../../../utils/suuid';
import { GA_UPLOAD_FILE } from '../../../shared/google-analytics';
import { showErrorMessage } from '../../../shared/helpers';
import { InboxOutlined } from '@ant-design/icons';
import TableCreateModal from '../table-create-modal/table-create-modal';

interface ITableSourceModal {
  isOpen: boolean;
  closeModal: () => void;
  projectId: string;
  cb: () => void;
  cbSubmittedFiles: (submittedFiles: string[]) => void;
  handleCreateTable: (e: React.MouseEvent) => void;
}

const TableSourceModal: FC<ITableSourceModal> = ({
  isOpen,
  closeModal,
  projectId,
  cb,
  cbSubmittedFiles,
  handleCreateTable,
}) => {
  const [
    addProjectKnowledgeTable,
    { isLoading: addProjectKnowledgeLoadingTable },
  ] = projectsAPI.useAddProjectKnowledgeTableMutation();
  const [fileList, setFileList] = useState<CustomUploadFile[]>([]);
  const [showUpload, setShowUpload] = useState<boolean>(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    fileList.length > 0 ? setShowUpload(false) : setShowUpload(true);
  }, [fileList]);

  const { Dragger } = Upload;

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    onChange(info) {
      setFileList(info.fileList);
    },
    onDrop(e) {
      // console.log('Dropped files', e.dataTransfer.files);
    },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: () => {
      return false;
    },
    fileList,
    itemRender: (originNode, file, fileList, actions) => {
      return <UploadFileItem file={file} actions={actions} />;
    },
  };

  const handleSubmitFiles = async () => {
    if (!projectId) return;
    setIsLoading(true);

    const submittedFiles: string[] = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      const formData = new FormData();

      const fileOptions = {
        $type: 'csv',
        HasHeader: file.checked,
      };

      formData.append('file', file.originFileObj as Blob);
      formData.append('schemaJson', JSON.stringify(fileOptions));

      const uuid = suuid();

      const result = addProjectKnowledgeTable({
        id: projectId,
        body: formData,
      });

      submittedFiles.push(uuid);
      cbSubmittedFiles(submittedFiles);

      result
        .unwrap()
        .then((res) => {
          const fileExtension = file.name.split('.').pop();
          GA_UPLOAD_FILE(fileExtension || 'N/A');
          messageApi.success(`File ${file.name} has been submitted.`, 1);
          cb();
        })
        .catch((err) => {
          showErrorMessage(messageApi, err);
        })
        .finally(() => {
          submittedFiles.splice(submittedFiles.indexOf(uuid), 1);
          cbSubmittedFiles(submittedFiles);
        });
    }

    setFileList([]);
    setIsLoading(false);
    closeModal();
  };

  return (
    <Modal
      className="data-source-upload-modal"
      confirmLoading={isLoading}
      title={`Add knowledge from .CSV table`}
      open={isOpen}
      onCancel={closeModal}
      cancelButtonProps={{ disabled: isLoading }}
      onOk={handleSubmitFiles}
      okText="Add"
      okButtonProps={{
        style: { display: showUpload ? 'none' : 'inline-block' },
      }}
      centered={true}
      wrapClassName={'modal'}
      maskStyle={{ zIndex: 1100 }}
    >
      {contextHolder}
      <Dragger
        {...props}
        accept={'.csv'}
        className={`knowledge-dragger ${
          showUpload ? '' : 'data-source-upload-dragger-hidden'
        }`}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">Support for a single or bulk upload.</p>
        {/*TODO: connect table to knowledge*/}
        {/*<p className="ant-upload-hint">or</p>*/}
        {/*<Button onClick={handleCreateTable}>Create table</Button>*/}
      </Dragger>
    </Modal>
  );
};

export default TableSourceModal;
