import React, { FC, useEffect } from 'react';
import './table-editable.scss';
import DataGrid, { DataGridHandle } from 'react-data-grid';
import TablesUtils from '../../../shared/tables/tables-utils';
import useSelectedRowsInfo from '../../../../hooks/table-hooks/useSelectedRowsInfo';
import { ITableFilterValues } from '../../whats-app-outbound/filters/filters-helpers';
import TableActions from './table-actions';
import {
  IEditorTableColumn,
  ITableRecordsT,
  ITableRowT,
  ITableT,
} from '../../../../types/tables-service/ITableT';
import { Input, Spin } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import { CloudUploadOutlined, LoadingOutlined } from '@ant-design/icons';

interface ITableEditableProps {
  projectId: string;
  tableName: string | null;
  setTableName: React.Dispatch<React.SetStateAction<string | null>>;
  tableData: ITableT | undefined | null;
  isReadOnly: boolean;
  filterValues: ITableFilterValues[];
  setFilterValues: React.Dispatch<React.SetStateAction<ITableFilterValues[]>>;
  itemsTotalCount: number;
  tableColumns: IEditorTableColumn[];
  onAddColumn: () => void;
  addColumnLoading: boolean;
  tableRows: ITableRowT[];
  onAddRow: () => void;
  addRowLoading: boolean;
  onRowsChange: (rows: ITableRowT[]) => void;
  tableRecordsData: ITableRecordsT | undefined | null;
  getRecordsCb: () => Promise<ITableRecordsT | undefined | null>;
  messageApi: MessageInstance;
  editingHeaderCellKey: string;
  setEditingHeaderCellKey: React.Dispatch<React.SetStateAction<string>>;
  gridRef: React.Ref<DataGridHandle>;
  savingData: boolean;
  setDeleteRowLoading: React.Dispatch<React.SetStateAction<boolean>>;
  renderImportAction: null | (() => JSX.Element);
}

const TableEditable: FC<ITableEditableProps> = ({
  projectId,
  tableName,
  setTableName,
  tableData,
  isReadOnly,
  filterValues,
  setFilterValues,
  itemsTotalCount,
  tableColumns,
  onAddColumn,
  addColumnLoading,
  tableRows,
  onAddRow,
  addRowLoading,
  onRowsChange,
  tableRecordsData,
  getRecordsCb,
  messageApi,
  editingHeaderCellKey,
  setEditingHeaderCellKey,
  gridRef,
  savingData,
  setDeleteRowLoading,
  renderImportAction,
}) => {
  const [selectedRowsComponent, selectedRows, setSelectedRows] =
    useSelectedRowsInfo(
      projectId,
      tableData?.id || '',
      filterValues,
      itemsTotalCount,
      tableRows
    );

  useEffect(() => {
    if (tableData) {
      let savedTableName = tableData.name;

      if (savedTableName.includes('.')) {
        if (TablesUtils.checkHasCSVExtension(savedTableName)) {
          savedTableName = savedTableName.replace('.csv', '');
        }
      }

      if (tableName !== null && tableName !== savedTableName) {
        return;
      }

      setTableName(savedTableName);
    }
  }, [tableData]);

  const addRowAndColumn = () => {
    onAddRow();
    onAddColumn();
  };

  const addBtnLoadingElement = () => (
    <Spin
      indicator={<LoadingOutlined spin style={{ fontSize: 10 }} />}
      size="small"
    />
  );

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      if (editingHeaderCellKey) {
        setEditingHeaderCellKey('');
        return;
      }
      const target = e.target as HTMLElement;
      const columnKey = target.className
        .split(' ')
        .find((c) => c.includes('colKey--'));
      if (columnKey) {
        const key = columnKey.replace('colKey--', '');
        setEditingHeaderCellKey(key);
      }
    }

    if (e.key === 'Escape') {
      setEditingHeaderCellKey('');
    }
  };

  return (
    <>
      <div className={'table-editable-header'}>
        <div
          style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}
        >
          <Input
            value={tableName || ''}
            onChange={(e) => setTableName(e.target.value)}
            placeholder="Table Name"
            className={`table-editable-header__table-name ${
              isReadOnly ? 'table-editable-header__table-name--readonly' : ''
            }`}
            style={{ fontWeight: 500, maxWidth: 250 }}
            disabled={isReadOnly}
          />
          {savingData ? (
            <CloudUploadOutlined
              style={{ padding: '0 10px' }}
              className={'accent-color-icon'}
            />
          ) : null}
        </div>
        <TableActions
          projectId={projectId}
          tableData={tableData}
          isReadOnly={isReadOnly}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          tableRecordsData={tableRecordsData}
          tableRows={tableRows}
          onRowsChange={onRowsChange}
          getRecordsCb={getRecordsCb}
          messageApi={messageApi}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          setDeleteRowLoading={setDeleteRowLoading}
          renderImportAction={renderImportAction}
        />
      </div>
      {selectedRowsComponent}
      <div className={'table-editable-container'}>
        <div style={{ display: 'flex', gap: 10 }} onKeyDown={handleKeyDown}>
          <DataGrid
            ref={gridRef}
            className={'rdg-light'}
            rowKeyGetter={TablesUtils.rowKeyGetter}
            columns={tableColumns}
            rows={tableRows}
            onRowsChange={(rows) => {
              onRowsChange(rows);
            }}
            style={{
              width: '100%',
              height: '100%',
              borderBottom: 0,
              borderRight: 0,
            }}
            selectedRows={selectedRows}
            onSelectedRowsChange={setSelectedRows}
          />
          <div
            className={`add-new-btn add-new-btn--column ${
              addColumnLoading ? 'add-new-btn--loading' : ''
            } ${isReadOnly ? 'hidden' : ''}`}
            onClick={onAddColumn}
          >
            {addColumnLoading ? addBtnLoadingElement() : '+'}
          </div>
        </div>
        <div
          style={{ display: 'flex', gap: 10 }}
          className={`${isReadOnly ? 'hidden' : ''}`}
        >
          <div
            className={`add-new-btn add-new-btn--row ${
              addRowLoading ? 'add-new-btn--loading' : ''
            }`}
            onClick={onAddRow}
          >
            {addRowLoading ? addBtnLoadingElement() : '+'}
          </div>
          <div
            className={`add-new-btn add-new-btn--column-row ${
              addRowLoading && addColumnLoading ? 'add-new-btn--loading' : ''
            }`}
            onClick={addRowAndColumn}
          >
            {addColumnLoading && addRowLoading ? addBtnLoadingElement() : '+'}
          </div>
        </div>
      </div>
    </>
  );
};

export default TableEditable;
