import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/user-slice';
import projectReducer from './reducers/projects-slice';
import plansReducer from './reducers/plans-slice';
import subscriptionReducer from './reducers/subscription-slice';
import pluginsReducer from './reducers/plugins-slice';
import organizationsReducer from './reducers/organizations-slice';
import domainsReducer from './reducers/domains-slice';
import { apiService } from '../services/api-service';

const rootReducer = combineReducers({
  userReducer,
  projectReducer,
  plansReducer,
  subscriptionReducer,
  pluginsReducer,
  organizationsReducer,
  domainsReducer,
  [apiService.reducerPath]: apiService.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiService.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
