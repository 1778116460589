import { Form, Select } from 'antd';
import { V_REQUIRED_FIELD } from '../../../../shared/constants';

const dropdownStyle = { zIndex: 2000 };
const rules = [
  {
    required: true,
    message: V_REQUIRED_FIELD,
  },
];

const FormSelect = (
  name: string,
  label: string,
  options: any[],
  onChange: () => Promise<void>,
  valueKey = 'id',
  labelKey = 'name'
) => {
  return (
    <Form.Item name={name} label={label} rules={rules}>
      <Select
        options={options.map((option) => {
          return {
            value: valueKey.length > 0 ? option[valueKey] : option,
            label: labelKey.length > 0 ? option[labelKey] : option,
          };
        })}
        dropdownStyle={dropdownStyle}
        onChange={onChange}
      />
    </Form.Item>
  );
};

export default FormSelect;
