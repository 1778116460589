import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { message, Modal } from 'antd';
import { tablesApi } from '../../../../services/tables-service';
import { showErrorMessage } from '../../../../shared/helpers';

interface IDeleteTableTModal {
  projectId: string;
  deleteTableId: string;
  setDeleteTableId: Dispatch<SetStateAction<string>>;
}

const DeleteTableTModal: FC<IDeleteTableTModal> = ({
  projectId,
  deleteTableId,
  setDeleteTableId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [deleteTableT] = tablesApi.useDeleteTableTMutation();
  const [messageApi, contextHolder] = message.useMessage();
  const [deleteDisabled, setDeleteDisabled] = useState(false);

  useEffect(() => {
    if (deleteTableId) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [deleteTableId]);

  const handleCancel = () => {
    setDeleteTableId('');
  };

  const handleOk = async () => {
    setDeleteDisabled(true);

    const res = await deleteTableT({ projectId, id: deleteTableId });

    if ('error' in res) {
      await showErrorMessage(messageApi, res.error);
    } else {
      await messageApi.success('The table has been deleted.', 1);
      setDeleteTableId('');
      setDeleteDisabled(false);
    }
  };

  return (
    <Modal
      wrapClassName={'modal'}
      title="Delete table"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ disabled: deleteDisabled, danger: true }}
      centered={true}
    >
      {contextHolder}
      <p>
        Please confirm the deletion of this table. Note that this will
        permanently erase all associated information. This action cannot be
        undone.
      </p>
    </Modal>
  );
};

export default DeleteTableTModal;
