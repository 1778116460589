import React, { FC, useEffect, useState } from 'react';
import { ISuperellipseMaskImageProps } from '../../../../../../../superellipse/superellipse-mask';
import IProjectStartInfo from '../../../../../../../../types/IProjectStartInfo';
import { UploadFile } from 'antd';
import { useAppSelector } from '../../../../../../../../hooks/redux-hooks';
import StorageUtils from '../../../../../../../../shared/utils/storage-utils';

const logoImageProps = {
  x: '8%',
  y: '8%',
  width: '84%',
  height: '84%',
};

interface IImageDataPropsBasic {
  src: string;
}

interface IImageDataPropsExtended {
  src: string;
  x: string;
  y: string;
  width: string;
  height: string;
}

type ImageDataProps = IImageDataPropsBasic | IImageDataPropsExtended;

interface IImagePropsComponentProps {
  projectStartInfo: IProjectStartInfo;
  image: UploadFile<any> | null;
  children: (imageProps: ISuperellipseMaskImageProps) => React.ReactElement;
}

const ImageProps: FC<IImagePropsComponentProps> = ({
  projectStartInfo,
  image,
  children,
}) => {
  const { globalTimestamp } = useAppSelector((state) => state.domainsReducer);
  const { filePath } = useAppSelector((state) => state.domainsReducer);
  const imageURL = StorageUtils.getProjectLogo(
    projectStartInfo.id,
    projectStartInfo.logoFileName,
    filePath,
    globalTimestamp
  );

  const getDefaultImageProps = () => {
    return projectStartInfo.logoFileName
      ? { src: imageURL }
      : { ...logoImageProps, src: imageURL };
  };

  const [imageProps, setImageProps] = useState<ImageDataProps>(
    getDefaultImageProps()
  );

  useEffect(() => {
    if (image) {
      const blob = image as unknown as Blob;
      const url = window.URL.createObjectURL(blob);
      setImageProps({ src: url });
    } else {
      setImageProps(getDefaultImageProps());
    }
  }, [image, projectStartInfo]);

  return children(imageProps);
};

export default ImageProps;
