// return black if color is not valid
export function hexToRgb(hex: string) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : {
        r: 0,
        g: 0,
        b: 0,
      };
}

export function hexToFeColorMatrix(hex: string): string {
  const { r, g, b } = hexToRgb(hex);

  return rgbToFeColorMatrix(r, g, b);
}

function rgbToFeColorMatrix(red: number, green: number, blue: number): string {
  const normalizedRed = red / 255;
  const normalizedGreen = green / 255;
  const normalizedBlue = blue / 255;

  // const matrix = [
  //   normalizedRed, 0, 0, 0, 0,
  //   0, normalizedGreen, 0, 0, 0,
  //   0, 0, normalizedBlue, 0, 0,
  //   0, 0, 0, 1, 0
  // ];

  const customMatrix = [
    0,
    0,
    0,
    0,
    normalizedRed,
    0,
    0,
    0,
    0,
    normalizedGreen,
    0,
    0,
    0,
    0,
    normalizedBlue,
    0,
    0,
    0,
    1,
    0,
  ];

  // const matrixString = matrix.map((value, index) => (index % 5 === 4) ? value + ',' : value).join(' ');
  const matrixString = customMatrix.join(' ');

  return matrixString;
}

export function getContrastYIQ(hexcolor: string) {
  hexcolor = hexcolor.replace('#', '');
  const r = parseInt(hexcolor.substring(0, 2), 16);
  const g = parseInt(hexcolor.substring(2, 4), 16);
  const b = parseInt(hexcolor.substring(4, 6), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? '#000000' : '#ffffff';
}

export function rgbaToHex(rgbaColor: string): string {
  const rgbaValues = colorRGBAStringToRGBAArray(rgbaColor);
  const hexValues = rgbaValues.slice(0, 3).map((value) => {
    const hex = value.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  });

  return `#${hexValues.join('')}`;
}

// input => 'rgba(255, 255, 255, 0.2)'
// output => [255, 255, 255, 0.2]
// return black if color is not valid
function colorRGBAStringToRGBAArray(color: string): number[] {
  const match = color.match(/(\d+(\.\d+)?)/g);

  if (match && match.length >= 3) {
    const alpha = match[3] ? parseFloat(match[3]) : 1.0;

    return [...match.slice(0, 3).map((value) => parseFloat(value)), alpha];
  }

  return [0, 0, 0, 1];
}

export function mixColors(rgba1: number[], rgba2: number[]): string {
  const alpha = rgba2[3];

  const mixedColor = rgba1.map((component, index) =>
    Math.round(component * (1 - alpha) + rgba2[index] * alpha)
  );

  const resultColor = `rgba(${mixedColor.join(',')})`;

  return resultColor;
}
