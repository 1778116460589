import React, { FC, useState } from 'react';
import './brand-label.scss';
import IProjectStartInfo from '../../../../../../../../types/IProjectStartInfo';
import { DEFAULT_ZAPPR_DOMAIN } from '../../../../../../../../shared/constants';
import { zapprFullLogoSVG } from './zappr-svg-logo';
import {
  getDomain,
  getDomainForImage,
} from '../../../../../../../../shared/helpers';
import StorageUtils from '../../../../../../../../shared/utils/storage-utils';

interface IBrandLabelProps {
  projectStartInfo: IProjectStartInfo;
}

const BrandLabel: FC<IBrandLabelProps> = ({ projectStartInfo }) => {
  const brandName =
    projectStartInfo.customBranding && projectStartInfo.brandName
      ? projectStartInfo.brandName
      : process.env.REACT_APP_DISPLAY_NAME;

  const domain = getDomainForImage();

  const openBrandLink = () => {
    const url = 'https://' + getDomain();
    window.open(url, '_blank');
  };

  const getCustomLogo = () => {
    return (
      <div className={'brand-label brand-label--custom'}>
        <img
          className={'brand-label__image--custom'}
          src={StorageUtils.getBrandLogo(projectStartInfo.organizationId)}
          alt={brandName}
        />
      </div>
    );
  };

  const getFullLogo = () => {
    if (
      projectStartInfo.customBranding &&
      projectStartInfo.hasBrandImage &&
      projectStartInfo.organizationId
    ) {
      return getCustomLogo();
    }

    if (projectStartInfo.customBranding) {
      return null;
    }

    if (domain === DEFAULT_ZAPPR_DOMAIN) {
      return (
        <div className={'brand-label'} onClick={openBrandLink}>
          {zapprFullLogoSVG()}
        </div>
      );
    } else {
      return null;
    }
  };

  return getFullLogo();
};

export default BrandLabel;
