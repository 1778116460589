import React, { FC, useEffect, useState } from 'react';
import { projectsAPI } from '../../../services/projects-service';
import { Form, message, Modal, Select } from 'antd';
import { showErrorMessage } from '../../../shared/helpers';
import { GA_UPLOAD_QA } from '../../../shared/google-analytics';
import { V_REQUIRED_FIELD } from '../../../shared/constants';
import TextArea from 'antd/es/input/TextArea';
import CommandsHelper, {
  ICommandList,
  IGetCommandLabel,
} from './commands-helper';
import { IngestQATypes, IngestQATypeOption } from './qa-ingest-shared-types';

interface IQAIngestModalWithCommandsProps {
  isOpen: boolean;
  closeModal: () => void;
  projectId: string;
  cb?: () => void;
}

interface IQaIngestSourceModalProps extends IQAIngestModalWithCommandsProps {
  commandList: ICommandList[];
  getCommandLabel: IGetCommandLabel;
}

const QaIngestSourceModal: FC<IQaIngestSourceModalProps> = ({
  commandList,
  getCommandLabel,
  isOpen,
  closeModal,
  projectId,
  cb,
}) => {
  const [addProjectKnowledgeIngestQA, { isLoading }] =
    projectsAPI.useAddProjectKnowledgeIngestQAMutation();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [okDisabled, setOkDisabled] = useState<boolean>(true);
  const [currentType, setCurrentType] = useState<string>(
    IngestQATypes.USER_ASKS
  );
  const [currentCommand, setCurrentCommand] = useState<string>('');

  useEffect(() => {
    handleChange();
  }, [currentCommand, isOpen]);

  const handleOk = async () => {
    setOkDisabled(true);

    let values = form.getFieldsValue();
    const typeIsCommandResponds =
      values.type === IngestQATypes.COMMAND_RESPONDS;

    if (typeIsCommandResponds) {
      values = {
        ...values,
        command: currentCommand,
      };
    }

    const result = await addProjectKnowledgeIngestQA({
      id: projectId,
      body: values,
    });

    if (result) {
      if ('error' in result) {
        showErrorMessage(messageApi, result.error);
      } else {
        if (cb) {
          cb();
        }
        GA_UPLOAD_QA(true);
        await messageApi.success(`Q&A have been submitted.`, 1);
        form.resetFields();
        handleCancel();
      }
    }

    setOkDisabled(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setCurrentType(IngestQATypes.USER_ASKS);
    setCurrentCommand('');
    closeModal();
  };

  const handleChangeType = (value: string) => {
    form.setFieldValue('type', value);
    setCurrentType(value);
    handleChange();
  };

  const handleChangeCommand = (value: string) => {
    setCurrentCommand(value);
  };

  const handleChange = () => {
    const values = form.getFieldsValue();

    const typeIsCommandResponds =
      values.type === IngestQATypes.COMMAND_RESPONDS;

    if (typeIsCommandResponds && !currentCommand) {
      setOkDisabled(true);
      return;
    }

    for (const key in values) {
      if (
        values[key] === undefined ||
        values[key] === null ||
        values[key] === ''
      ) {
        setOkDisabled(true);
        return;
      }
    }

    setOkDisabled(false);
  };

  const shouldShowCommandsList = () => {
    return (
      currentType === IngestQATypes.COMMAND_RESPONDS && commandList.length > 0
    );
  };

  return (
    <Modal
      title="Q&A Ingest"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered={true}
      okButtonProps={{
        disabled: okDisabled || isLoading,
        loading: isLoading,
      }}
      okText={'Ingest'}
      className={'text-source-modal'}
      wrapClassName={'modal'}
      maskStyle={{ zIndex: 1100 }}
    >
      {contextHolder}
      <Form
        form={form}
        onChange={handleChange}
        className="data-source-upload-modal-form"
        layout="vertical"
        initialValues={{
          type: IngestQATypes.USER_ASKS,
          input: '',
          output: '',
        }}
      >
        <Form.Item
          name="type"
          rules={[
            {
              required: true,
              message: V_REQUIRED_FIELD,
            },
          ]}
          style={{ marginBottom: 10 }}
        >
          <>
            <span style={{ fontWeight: 500 }}>When</span>
            <Select
              style={{ width: 180, margin: '0 10px 10px' }}
              defaultValue={IngestQATypes.USER_ASKS}
              options={IngestQATypeOption.map((item) => ({
                label: item.label,
                value: item.value,
                disabled:
                  item.value === IngestQATypes.COMMAND_RESPONDS
                    ? !commandList.length
                    : false,
              }))}
              dropdownStyle={{ zIndex: 2000 }}
              onChange={(value) => handleChangeType(value)}
            />
            {shouldShowCommandsList() && (
              <Select
                style={{ width: '100%', maxWidth: '464px', minWidth: '238px' }}
                options={commandList.map((item) => ({
                  label: (
                    <span style={{ textTransform: 'capitalize' }}>
                      {getCommandLabel(item.pluginName, item.commandName)}
                    </span>
                  ),
                  value: item.command,
                }))}
                dropdownStyle={{ zIndex: 2000 }}
                onChange={(value) => handleChangeCommand(value)}
                optionLabelProp="label"
              />
            )}
          </>
        </Form.Item>
        <Form.Item
          name="question"
          rules={[
            {
              required: true,
              message: V_REQUIRED_FIELD,
            },
          ]}
          className={'text-source-modal__text'}
        >
          <TextArea
            rows={4}
            showCount
            maxLength={2000}
            placeholder={`Q: What’s the weather today?`}
          />
        </Form.Item>
        <div style={{ fontWeight: 500, marginBottom: 10 }}>Reply this:</div>
        <Form.Item
          name="answer"
          rules={[
            {
              required: true,
              message: V_REQUIRED_FIELD,
            },
          ]}
          className={'text-source-modal__text'}
        >
          <TextArea
            rows={4}
            showCount
            maxLength={2000}
            placeholder={`A: I’m not a weather forecasting system. Do you have other questions?`}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const QAIngestModalWithCommands: FC<IQAIngestModalWithCommandsProps> = ({
  isOpen,
  closeModal,
  projectId,
  cb,
}) => {
  return (
    <CommandsHelper projectId={projectId}>
      {(commandList, getCommandLabel) => {
        return (
          <QaIngestSourceModal
            commandList={commandList}
            getCommandLabel={getCommandLabel}
            isOpen={isOpen}
            closeModal={closeModal}
            projectId={projectId}
            cb={cb}
          />
        );
      }}
    </CommandsHelper>
  );
};

export default QAIngestModalWithCommands;
