import React, { FC } from 'react';
import SuperellipseMask, {
  ISuperellipseMaskImageProps,
} from '../../../../../../../superellipse/superellipse-mask';
import './main-bot-image.scss';
import { IGeneratedWidgetStyle, WidgetModes } from '../../fake-widget-helpers';

interface IMainBotImageProps {
  generatedWidgetStyle: IGeneratedWidgetStyle;
  widgetMode: WidgetModes;
  imageProps: ISuperellipseMaskImageProps;
}

const MainBotImage: FC<IMainBotImageProps> = ({
  generatedWidgetStyle,
  widgetMode,
  imageProps,
}) => {
  return (
    <div>
      <div
        className={`avatar-container`}
        style={{
          marginBottom: widgetMode === WidgetModes.MINI_CHAT ? '28px' : '8px',
        }}
      >
        <SuperellipseMask
          maskId={'avatar'}
          width={128}
          height={128}
          bgColor={generatedWidgetStyle.secondaryColor}
          borderColor={generatedWidgetStyle.secondaryColor}
          radius={40}
          smoothing={1}
          imageProps={imageProps}
        />
      </div>
    </div>
  );
};

export default MainBotImage;
