import { ReactNode, FC } from 'react';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';

interface ChartContainerProps {
  chartData: any[];
  xAxisKey: string;
  children: ReactNode;
  headerContent: ReactNode;
}

export const ChartContainer: FC<ChartContainerProps> = ({
  chartData,
  xAxisKey,
  children,
  headerContent,
}) => {
  return (
    <>
      <div className="usage__chart-container">
        {headerContent}
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={chartData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid horizontal={false} strokeDasharray="3 3" />
            <XAxis dataKey={xAxisKey} />
            <YAxis />
            <Tooltip />
            {children}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};
