import React, { FC, useState } from 'react';
import { Form, Input, message, Modal } from 'antd';
import { V_REQUIRED_FIELD } from '../../../shared/constants';
import { projectsAPI } from '../../../services/projects-service';
import { showErrorMessage } from '../../../shared/helpers';

interface IGithubSourceModal {
  isOpen: boolean;
  closeModal: () => void;
  projectId: string;
  cb: () => void;
}

const GithubSourceModal: FC<IGithubSourceModal> = ({
  isOpen,
  closeModal,
  projectId,
  cb,
}) => {
  const [addProjectKnowledgeGithub, { isLoading }] =
    projectsAPI.useAddProjectKnowledgeGithubMutation();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [okDisabled, setOkDisabled] = useState<boolean>(true);

  const handleOk = async () => {
    setOkDisabled(true);

    const values = form.getFieldsValue();

    const result = await addProjectKnowledgeGithub({
      projectId: projectId,
      body: values,
    });

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
    } else {
      //TODO: ADD GA_EVENT
      cb();
      await messageApi.success(`GitHub data have been submitted.`, 1);
      form.resetFields();
      closeModal();
    }
  };

  const handleCancel = () => {
    form.resetFields();
    closeModal();
  };

  const handleChange = async () => {
    const url = form.getFieldValue('url');
    const branch = form.getFieldValue('branch');

    if (!url || !branch) {
      setOkDisabled(true);
    } else {
      try {
        await form.validateFields();
        setOkDisabled(false);
      } catch (errors) {
        setOkDisabled(true);
      }
    }
  };

  return (
    <Modal
      title="Enhance wisdom from GitHub"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered={true}
      okButtonProps={{ disabled: okDisabled, loading: isLoading }}
      wrapClassName={'modal'}
      maskStyle={{ zIndex: 1100 }}
    >
      {contextHolder}
      <Form
        form={form}
        onChange={handleChange}
        className="data-source-upload-modal-form"
        layout="vertical"
        requiredMark={false}
      >
        <Form.Item
          label={'Repository name'}
          name="url"
          rules={[
            {
              required: true,
              message: V_REQUIRED_FIELD,
            },
            {
              min: 19,
              message: 'Repository name must be at least 19 characters.',
            },
            {
              max: 56,
              message: 'Maximum repository name length 56 characters.',
            },
          ]}
        >
          <Input autoComplete={'off'} placeholder="https://github.com/..." />
        </Form.Item>
        <Form.Item
          label={'Branch name'}
          name="branch"
          rules={[
            {
              required: true,
              message: V_REQUIRED_FIELD,
            },
            {
              max: 244,
              message: 'Maximum branch name length 244 characters.',
            },
          ]}
        >
          <Input autoComplete={'off'} placeholder="main" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default GithubSourceModal;
