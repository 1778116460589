import React, { FC } from 'react';
import './input-panel.scss';
import { hexToRgb } from '../../../../../../../../shared/color-helpers';
import { IGeneratedWidgetStyle } from '../../fake-widget-helpers';
import IProjectStartInfo from '../../../../../../../../types/IProjectStartInfo';
import MicrophoneIconOutline from '../microphone/microphone-icon-outline';
import AttachButton from './attach-button';

interface IInputPanelProps {
  projectStartInfo: IProjectStartInfo;
  generatedWidgetStyle: IGeneratedWidgetStyle;
  placeholder: string;
  allowMicrophone: boolean;
  allowFileAttachments: boolean;
}

const InputPanel: FC<IInputPanelProps> = ({
  generatedWidgetStyle,
  placeholder,
  allowMicrophone,
  allowFileAttachments,
}) => {
  const bgColorRGB = hexToRgb(generatedWidgetStyle.inputPanel.bgColor);
  const bgColor = `rgba(${bgColorRGB.r}, ${bgColorRGB.g}, ${bgColorRGB.b}, ${generatedWidgetStyle.inputPanel.bgOpacity})`;

  const defaultPanel = () => (
    <textarea
      style={{
        backgroundColor: bgColor,
        borderColor: generatedWidgetStyle.inputPanel.borderColor,
        borderRadius: '20px',
        color: '#636366',
      }}
      autoComplete={'off'}
      rows={1}
      className={`input-panel__msg-input`}
      placeholder={placeholder || 'Ask me...'}
      value={''}
      disabled={true}
    />
  );

  const getButton = () => {
    if (!allowMicrophone) {
      return null;
    }
    return (
      <div className={'microphone-btn-container'}>
        <div className={'microphone-icon-outline'}>
          <MicrophoneIconOutline />
        </div>
      </div>
    );
  };

  const getBtnContainerClassNames = () => {
    return allowMicrophone ? '' : 'hidden';
  };

  const attachFilesButton = () => {
    return (
      <div style={{ cursor: 'pointer' }}>
        <AttachButton />
      </div>
    );
  };

  return (
    <form className="input-panel">
      {allowFileAttachments && attachFilesButton()}
      {defaultPanel()}
      <div className={getBtnContainerClassNames()}>{getButton()}</div>
    </form>
  );
};

export default InputPanel;
