import { useState, useEffect, FC } from 'react';
import { Bar, LabelList, Legend } from 'recharts';
import { usageAPI } from '../../../services/usage-service';
import {
  BarData,
  chartColors,
  distinctByProp,
  getChartLabelPostfix,
  IChartsSettings,
  UsagePerDayChartProps,
} from '../../charts/chart-data';
import { Skeleton } from 'antd';
import AnalyticsUtils from '../../shared/analytics/analytics-utils';
import { ChartContainer } from '../../shared/analytics/analytics-container-component';
import { useParams } from 'react-router-dom';

const ProjectUsagePerDayChart: FC<{
  projectId: string;
  settings: IChartsSettings;
}> = ({ projectId, settings }) => {
  const { orgId } = useParams();
  const initialChartsData: UsagePerDayChartProps = { charts: [] };
  const [chartsData, setChartsData] = useState(initialChartsData);

  const [lazyGetData, { data: queryResult, isLoading }] =
    usageAPI.useLazyGetProjectUsagePerDayQuery();

  useEffect(() => {
    const now = new Date();
    const nowString = now.toISOString();
    lazyGetData({ projectId, orgId, date: nowString });
  }, [lazyGetData]);

  useEffect(() => {
    if (settings) {
      const dateString = settings.month.toISOString();
      lazyGetData({ projectId, orgId, date: dateString });
    }
  }, [settings]);

  useEffect(() => {
    if (queryResult) {
      const tableData = AnalyticsUtils.getTableData(queryResult);
      const expenses = AnalyticsUtils.getExpenses(tableData);
      const chartsData: UsagePerDayChartProps = { charts: [] };

      for (const expenseType of expenses) {
        const filterName = 'source';
        const uniqueSources = distinctByProp(tableData, filterName);
        const barsData: BarData[] = AnalyticsUtils.generateBarsDataBySource(
          uniqueSources,
          expenseType
        );
        const newChartData: any[] = [];

        AnalyticsUtils.handleUsageDataByBarsData(
          tableData,
          barsData,
          queryResult,
          expenseType,
          newChartData,
          filterName
        );

        const data = {
          bars: barsData,
          data: newChartData,
          xAxisDataKey: 'day',
          label: (
            <div className={'usage__chart-title'}>
              Usage in credits {getChartLabelPostfix(expenseType)}
            </div>
          ),
        };
        chartsData.charts.push(data);
      }

      setChartsData(chartsData);
    }
  }, [queryResult]);

  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          {chartsData.charts.map((chartData, iChart) => (
            <ChartContainer
              key={'bar' + iChart}
              chartData={chartData.data}
              xAxisKey={chartData.xAxisDataKey}
              headerContent={chartData.label}
            >
              <Legend />
              {chartData.bars.map((barData, iBar) => (
                <Bar
                  key={'bar' + iBar}
                  dataKey={barData.name}
                  stackId={barData.type}
                  fill={chartColors[(iBar + 1) % chartColors.length]}
                >
                  {barData.labelKey ? (
                    <LabelList
                      dataKey={barData.labelKey}
                      position="top"
                      formatter={(label: string) =>
                        label == '0' ? null : label
                      }
                    />
                  ) : null}
                </Bar>
              ))}
            </ChartContainer>
          ))}
        </>
      )}
    </>
  );
};

export default ProjectUsagePerDayChart;
