import React, { FC, useState } from 'react';
import { Form, message, Modal } from 'antd';
import {
  ContactRecordType,
  ContactType,
  IContactRecord,
  IPostContactRecord,
  TContactRecord,
} from '../../../../types/contacts/IContact';
import '../contacts.scss';
import BasicInfoFormElement from '../forms/basic-info-form-element';
import AddRecordModal from '../add-record-modal/add-record-modal';
import DeleteContactRecordModal from '../delete-contact-record-modal/delete-contact-record-modal';
import EditContactRecordModal from '../edit-contact-record-modal/edit-contact-record-modal';
import { contactsAPI } from '../../../../services/contacts-service';
import { showErrorMessage } from '../../../../shared/helpers';
import AddRecordTypeElement from '../forms/add-record-type-element';
import ContactsHelpers from '../contacts-helpers';

interface IAddContactModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  projectId: string;
}

const AddContactModal: FC<IAddContactModalProps> = ({
  isOpen,
  setIsOpen,
  projectId,
}) => {
  const [postContact, { isLoading }] = contactsAPI.usePostContactMutation();
  const [addRecordType, setAddRecordType] = useState<ContactRecordType | null>(
    null
  );
  const [records, setRecords] = useState<IContactRecord[]>([]);
  const [deleteContactRecord, setDeleteContactRecord] =
    useState<IContactRecord | null>(null);
  const [editContactRecord, setEditContactRecord] =
    useState<IContactRecord | null>(null);
  const [okDisabled, setOkDisabled] = useState<boolean>(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [basicInfoForm] = Form.useForm();

  const handleCancel = () => {
    basicInfoForm.resetFields();
    setRecords([]);
    setIsOpen(false);
  };

  const handleEditRecord = (record: IContactRecord) => {
    setEditContactRecord(record);
  };

  const handleDeleteRecord = (record: IContactRecord) => {
    setDeleteContactRecord(record);
  };

  const onDeleteConfirm = async () => {
    setRecords((prevRecords) =>
      prevRecords.filter((record) => record.id !== deleteContactRecord?.id)
    );
    setDeleteContactRecord(null);
  };

  const onEditConfirm = async (newValues: TContactRecord) => {
    const newRecord = editContactRecord
      ? { ...editContactRecord, record: newValues }
      : null;
    if (newRecord) {
      setRecords((prevRecords) =>
        prevRecords.map((record) =>
          record.id === newRecord.id ? newRecord : record
        )
      );
    }
    setEditContactRecord(null);
  };

  const onAddContactRecord = async (record: IPostContactRecord) => {
    const emailRecord: IContactRecord = {
      id: ContactsHelpers.generateNewRecordId(),
      contactId: '',
      type: record.type,
      record: record.record,
    };
    setRecords((prevRecords) => [...prevRecords, emailRecord]);
  };

  const handleOk = async () => {
    setOkDisabled(true);

    const basicInfoFormValues = basicInfoForm.getFieldsValue();
    const recordsToPost = records.map((record) => {
      const { id, ...rest } = record;
      return rest;
    });

    const newContact = {
      type: ContactType.USER,
      source: '',
      basicInfo: basicInfoFormValues,
      records: recordsToPost,
    };

    const res = await postContact({ projectId, contact: newContact });

    if ('error' in res && res.error) {
      showErrorMessage(messageApi, res.error);
    } else {
      messageApi.success('Contact added successfully.');
      basicInfoForm.resetFields();
      setRecords([]);
      setIsOpen(false);
    }

    setOkDisabled(false);
  };

  const handleBasicInfoChange = async () => {
    try {
      await basicInfoForm.validateFields();
      setOkDisabled(false);
    } catch (e) {
      setOkDisabled(true);
    }
  };

  return (
    <>
      <Modal
        wrapClassName={'modal'}
        title="Add contact"
        open={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: okDisabled, loading: isLoading }}
        centered={true}
      >
        {contextHolder}
        <Form
          form={basicInfoForm}
          layout="vertical"
          requiredMark={false}
          onChange={handleBasicInfoChange}
        >
          <BasicInfoFormElement />
        </Form>
        {Object.values(ContactRecordType).map((type) =>
          ContactsHelpers.getRecordView(
            records,
            type,
            handleEditRecord,
            handleDeleteRecord
          )
        )}
        <AddRecordTypeElement setAddRecordType={setAddRecordType} />
      </Modal>
      <AddRecordModal
        addRecordType={addRecordType}
        setAddRecordType={setAddRecordType}
        onAddContactRecord={onAddContactRecord}
      />
      <DeleteContactRecordModal
        deleteContactRecord={deleteContactRecord}
        setDeleteContactRecord={setDeleteContactRecord}
        onDeleteConfirm={onDeleteConfirm}
      />
      <EditContactRecordModal
        editRecord={editContactRecord}
        setEditRecord={setEditContactRecord}
        onEditConfirm={onEditConfirm}
      />
    </>
  );
};

export default AddContactModal;
