import React, { FC, useEffect, useState } from 'react';
import { Form, message, Modal } from 'antd';
import BasicInfoFormElement from '../forms/basic-info-form-element';
import IContact, {
  IContactBasicInfo,
} from '../../../../types/contacts/IContact';
import { contactsAPI } from '../../../../services/contacts-service';
import { showErrorMessage } from '../../../../shared/helpers';
import { MessageInstance } from 'antd/es/message/interface';

interface IEditBasicInfoModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  projectId: string;
  contactData: IContact;
  updateCb: () => void;
  messageApi: MessageInstance;
}

const EditBasicInfoModal: FC<IEditBasicInfoModalProps> = ({
  isOpen,
  setIsOpen,
  projectId,
  contactData,
  updateCb,
  messageApi,
}) => {
  const [updateContactBasicInfo, { isLoading: updateLoading }] =
    contactsAPI.useUpdateContactBasicInfoMutation();
  const [form] = Form.useForm();
  const [okDisabled, setOkDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue(contactData.basicInfo);
      onValuesChange();
    } else {
      setIsOpen(false);
      form.resetFields();
      setOkDisabled(true);
    }
  }, [isOpen]);

  const handleCancel = () => {
    setIsOpen(false);
  };

  const onValuesChange = async () => {
    try {
      await form.validateFields();
    } catch (e) {
      const err = e as { outOfDate: boolean; errorFields: [] };
      if (err.outOfDate && err.errorFields.length === 0) {
        console.log('E: out of date (ignored)', e);
      } else {
        setOkDisabled(true);
        return;
      }
    }

    let changed = false;
    const formValues = form.getFieldsValue();
    for (let key in formValues) {
      const formValue = formValues[key];
      const initialValue =
        contactData.basicInfo[key as keyof IContactBasicInfo];
      let formValueToCompare = formValue;
      let initialValueToCompare = initialValue;
      if (formValue) {
        formValueToCompare =
          typeof formValue === 'string' ? formValue : JSON.stringify(formValue);
      }
      if (initialValue) {
        initialValueToCompare =
          typeof initialValue === 'string'
            ? initialValue
            : JSON.stringify(initialValue);
      }

      if (formValueToCompare !== initialValueToCompare) {
        changed = true;
        break;
      }
    }
    setOkDisabled(!changed);
  };

  const handleOk = async () => {
    setOkDisabled(true);
    const formValues = form.getFieldsValue();
    const res = await updateContactBasicInfo({
      projectId,
      contactId: contactData.id,
      basicInfo: formValues,
    });
    if ('error' in res && res.error) {
      showErrorMessage(messageApi, res.error);
      setOkDisabled(false);
    } else {
      messageApi.success('Contact basic info updated successfully.');
      updateCb();
      setIsOpen(false);
    }
  };

  return (
    <Modal
      title="Edit contact basic info"
      open={isOpen}
      onCancel={handleCancel}
      centered={true}
      wrapClassName={'modal'}
      maskStyle={{ zIndex: 1100 }}
      okButtonProps={{ disabled: okDisabled, loading: updateLoading }}
      onOk={handleOk}
      width={600}
    >
      <Form
        form={form}
        layout="vertical"
        onValuesChange={() => onValuesChange()}
        requiredMark={false}
        initialValues={{
          ...contactData.basicInfo,
        }}
      >
        <BasicInfoFormElement />
      </Form>
    </Modal>
  );
};

export default EditBasicInfoModal;
