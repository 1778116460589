import { apiService, APITagTypes } from './api-service';
import IContact, {
  IContactBasicInfo,
  IPostContact,
  IPostContactRecord,
  TContactRecord,
} from '../types/contacts/IContact';
import IContacts from '../types/contacts/IContacts';
import IPaginationSendParams from '../types/pagination/IPaginationSendParams';

export const contactsAPI = apiService.injectEndpoints({
  endpoints: (build) => ({
    getContacts: build.query<
      IContacts,
      { projectId: string; params: IPaginationSendParams }
    >({
      query: ({ projectId, params }) => ({
        url: `/projects/${projectId}/contacts?offset=${params.offset}&limit=${params.limit}`,
        method: 'GET',
      }),
      providesTags: () => [APITagTypes.CONTACTS],
    }),
    getContactById: build.query<
      IContact,
      { projectId: string; contactId: string }
    >({
      query: ({ projectId, contactId }) => ({
        url: `/projects/${projectId}/contacts/${contactId}`,
        method: 'GET',
      }),
    }),
    postContact: build.mutation<
      IContact[],
      { projectId: string; contact: IPostContact }
    >({
      query: ({ projectId, contact }) => ({
        url: `/projects/${projectId}/contacts`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(contact),
      }),
      invalidatesTags: [APITagTypes.CONTACTS],
    }),
    postContactRecords: build.mutation<
      IContact[],
      { projectId: string; contactId: string; records: IPostContactRecord[] }
    >({
      query: ({ projectId, contactId, records }) => ({
        url: `/projects/${projectId}/contacts/${contactId}/records/batch`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify({ records }),
      }),
      invalidatesTags: [APITagTypes.CONTACTS],
    }),
    deleteContact: build.mutation<
      void,
      { projectId: string; contactId: string }
    >({
      query: ({ projectId, contactId }) => ({
        url: `/projects/${projectId}/contacts/${contactId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [APITagTypes.CONTACTS],
    }),
    deleteContactRecord: build.mutation<
      void,
      { projectId: string; contactId: string; recordId: string }
    >({
      query: ({ projectId, contactId, recordId }) => ({
        url: `/projects/${projectId}/contacts/${contactId}/records/${recordId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [APITagTypes.CONTACTS],
    }),
    updateContactBasicInfo: build.mutation<
      void,
      { projectId: string; contactId: string; basicInfo: IContactBasicInfo }
    >({
      query: ({ projectId, contactId, basicInfo }) => ({
        url: `/projects/${projectId}/contacts/${contactId}`,
        method: 'PUT',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify({ basicInfo }),
      }),
      invalidatesTags: [APITagTypes.CONTACTS],
    }),
    updateContactRecord: build.mutation<
      void,
      {
        projectId: string;
        contactId: string;
        recordId: string;
        record: TContactRecord;
      }
    >({
      query: ({ projectId, contactId, recordId, record }) => ({
        url: `/projects/${projectId}/contacts/${contactId}/records/${recordId}`,
        method: 'PUT',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify({ ...record }),
      }),
      invalidatesTags: [APITagTypes.CONTACTS],
    }),
  }),
});
