import React, { FC } from 'react';
import CloseIcon from './close-icon';
import './chat-header.scss';
import { IGeneratedWidgetStyle } from '../../fake-widget-helpers';
import IProjectStartInfo from '../../../../../../../../types/IProjectStartInfo';

interface IChatHeaderProps {
  projectStartInfo: IProjectStartInfo;
  generatedWidgetStyle: IGeneratedWidgetStyle;
  title: string;
  subTitle: string;
}

const ChatHeader: FC<IChatHeaderProps> = ({
  projectStartInfo,
  generatedWidgetStyle,
  title,
  subTitle,
}) => {
  return (
    <div className={`chat-header`}>
      <div
        className={'chat-header__text-container'}
        style={{ margin: '0 45px' }}
      >
        <div
          className={`chat-header__text-title ${
            subTitle ? '' : 'chat-header__text-title--no-subtitle'
          }`}
          style={{ color: generatedWidgetStyle.header.titleColor }}
        >
          {title}
        </div>
        {subTitle && (
          <div
            className={'chat-header__text-subtitle'}
            style={{ color: generatedWidgetStyle.header.subtitleColor }}
          >
            {subTitle}
          </div>
        )}
      </div>
      <div>
        <CloseIcon />
      </div>
    </div>
  );
};

export default ChatHeader;
