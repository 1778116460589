import { FC, useEffect, useState } from 'react';
import ProfileSettingsMenu from '../../components/profile-settings-menu/profile-settings-menu';
import './profile-settings.scss';
import AccountSetting from '../../components/profile-settings/account-setting/account-setting';
import PlanSetting from '../../components/profile-settings/plan-setting/plan-setting';
import ApiKeySetting from '../../components/profile-settings/api-key-setting/api-key-setting';
import PasswordSetting from '../../components/profile-settings/password-setting/password-setting';
import CheckAuth from '../../components/check-auth/check-auth';
import { Breadcrumb } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import UsageSetting from '../../components/profile-settings/usage-setting/usage-setting';
import {
  DEFAULT_ZAPPR_DOMAIN,
  ProfileSettingsType,
} from '../../shared/constants';
import AddNewOrganization from '../../components/profile-settings/organizations/add-new-organization/add-new-organization';
import ProfileSettingsOrganizationsMenu from '../../components/profile-settings-organizations-menu/profile-settings-organizations-menu';
import Organization from '../../components/profile-settings/organizations/organization/organization';
import { useAppSelector } from '../../hooks/redux-hooks';
import BuyCreditsSetting from '../../components/profile-settings/buy-credits-setting/buy-credits-setting';
import Domains from '../../components/profile-settings/domains/domains';

const breadcrumbTitles = {
  [ProfileSettingsType.ACCOUNT]: 'Account',
  [ProfileSettingsType.PASSWORD]: 'Password',
  [ProfileSettingsType.USAGE]: 'Usage',
  [ProfileSettingsType.BUY_CREDITS]: 'Purchase Credits',
  [ProfileSettingsType.PLAN]: 'Plan',
  [ProfileSettingsType.API_KEY]: 'API Key',
  [ProfileSettingsType.ORGANIZATION_TITLE]: '',
  [ProfileSettingsType.ORGANIZATION]: '',
  [ProfileSettingsType.ADD_NEW_ORGANIZATION]: 'Add new',
  [ProfileSettingsType.DOMAINS]: 'Domains',
};

const ProfileSettings: FC = () => {
  const { tab, orgId } = useParams();
  const { isDomainTenantMember } = useAppSelector((state) => state.userReducer);
  const { allOrganizationsInfo } = useAppSelector(
    (state) => state.organizationsReducer
  );
  //TODO: tmp
  const location = useLocation();
  const [settingsType, setSettingsType] = useState(
    location.state && location.state.upgrade
      ? ProfileSettingsType.PLAN
      : ProfileSettingsType.ACCOUNT
  );
  const [organizationId, setOrganizationId] = useState<string | null>(
    orgId || null
  );
  const [breadcrumbOrgTitle, setBreadcrumbOrgTitle] = useState<string>('');
  // const [settingsType, setSettingsType] = useState(ProfileSettingsType.Account);

  useEffect(() => {
    if (!orgId) return;
    const orgInfo = allOrganizationsInfo.find((org) => org.id === orgId);
    if (orgInfo) {
      setBreadcrumbOrgTitle(orgInfo.displayName);
    }
  }, [allOrganizationsInfo, organizationId]);

  useEffect(() => {
    if (!tab) return;
    setSettingsType(tab as ProfileSettingsType);
    if (tab === ProfileSettingsType.ORGANIZATION && orgId) {
      setOrganizationId(orgId);
    } else {
      setOrganizationId(null);
    }
  }, [tab, orgId]);

  const getSettingsComponent = () => {
    if (isDomainTenantMember) {
      switch (settingsType) {
        case ProfileSettingsType.ACCOUNT:
          return <AccountSetting />;
        case ProfileSettingsType.PASSWORD:
          return <PasswordSetting />;
        default:
          return <AccountSetting />;
      }
    }

    switch (settingsType) {
      case ProfileSettingsType.ACCOUNT:
        return <AccountSetting />;
      case ProfileSettingsType.PASSWORD:
        return <PasswordSetting />;
      case ProfileSettingsType.USAGE:
        return <UsageSetting setSettingsType={setSettingsType} />;
      case ProfileSettingsType.BUY_CREDITS:
        return <BuyCreditsSetting />;
      case ProfileSettingsType.PLAN:
        return <PlanSetting />;
      case ProfileSettingsType.API_KEY:
        return <ApiKeySetting />;
      case ProfileSettingsType.ADD_NEW_ORGANIZATION:
        return <AddNewOrganization />;
      case ProfileSettingsType.ORGANIZATION:
        return <Organization />;
      case ProfileSettingsType.DOMAINS:
        return process.env.REACT_APP_DOMAIN === DEFAULT_ZAPPR_DOMAIN ? (
          <Domains />
        ) : (
          <AccountSetting />
        );
      default:
        return <AccountSetting />;
    }
  };

  const profileSettingsPage = () => (
    <div className="profile-settings">
      <div className="container">
        <div className="profile-settings__inner">
          <Breadcrumb
            items={[
              {
                title: 'Profile Settings',
              },
              {
                title: breadcrumbTitles[settingsType] || breadcrumbOrgTitle,
              },
            ]}
            className="breadcrumb"
          />
          <div className="profile-settings__content-container">
            <div className="profile-settings__menu">
              <ProfileSettingsMenu settingsType={settingsType} />
              {!isDomainTenantMember && (
                <ProfileSettingsOrganizationsMenu
                  settingsType={settingsType}
                  organizationId={organizationId}
                />
              )}
            </div>
            <div className="profile-settings__content">
              {getSettingsComponent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <CheckAuth
      component={profileSettingsPage()}
      allowedForDomainTenantMember={true}
    />
  );
};

export default ProfileSettings;
